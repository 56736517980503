import React from "react";

export default function ForecastMetricsFilterList() {
  return (<></>);
  // return (
  //   <DataFilterList>
  //     <DataFilterItem className={activatedFilter === "期間比較(重要指標)" ? "active" : ""}>
  //       <DataFilterItemButton onClick={dateFilterItemButtonMetricsClicked}>期間比較</DataFilterItemButton>
  //     </DataFilterItem>

  //     {resultSpan === "monthly" && (
  //       <>
  //         <DataFilterItem>
  //           <DataFilterItemSelect name="forecast_type" onChange={handleMetricsForecastTypeSwitch}>
  //             {metricsForecastFilter && metrics_forecast_filter_status === "12" && (
  //               <>
  //                 <option value="reset_forecast">予測基準</option>
  //                 <option value="12" selected>
  //                   12ヶ月
  //                 </option>
  //                 <option value="24">24ヶ月</option>
  //                 <option value="36">36ヶ月</option>
  //                 <option value="custom">指定期間</option>
  //               </>
  //             )}
  //             {metricsForecastFilter && metrics_forecast_filter_status === "24" && (
  //               <>
  //                 <option value="reset_forecast">予測基準</option>
  //                 <option value="12">12ヶ月</option>
  //                 <option value="24" selected>
  //                   24ヶ月
  //                 </option>
  //                 <option value="36">36ヶ月</option>
  //                 <option value="custom">指定期間</option>
  //               </>
  //             )}
  //             {metricsForecastFilter && metrics_forecast_filter_status === "36" && (
  //               <>
  //                 <option value="reset_forecast">予測基準</option>
  //                 <option value="12">12ヶ月</option>
  //                 <option value="24">24ヶ月</option>
  //                 <option value="36" selected>
  //                   36ヶ月
  //                 </option>
  //                 <option value="custom">指定期間</option>
  //               </>
  //             )}
  //             {metricsForecastFilter && metrics_forecast_filter_status === "custom" && (
  //               <>
  //                 <option value="reset_forecast">予測基準</option>
  //                 <option value="12">12ヶ月</option>
  //                 <option value="24">24ヶ月</option>
  //                 <option value="36">36ヶ月</option>
  //                 <option value="custom" selected>
  //                   指定期間
  //                 </option>
  //               </>
  //             )}
  //             {!metricsForecastFilter && (
  //               <>
  //                 <option value="reset_forecast" selected>
  //                   予測基準
  //                 </option>
  //                 <option value="12">12ヶ月</option>
  //                 <option value="24">24ヶ月</option>
  //                 <option value="36">36ヶ月</option>
  //                 <option value="custom">指定期間</option>
  //               </>
  //             )}
  //           </DataFilterItemSelect>
  //           <PlanArrow viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
  //             <path d="M10.59 0.000155449L6 4.58016L1.41 0.000155449L0 1.41016L6 7.41016L12 1.41016L10.59 0.000155449Z" fill="#789097" />
  //           </PlanArrow>
  //         </DataFilterItem>
  //         {metricsForecastFilter && metrics_forecast_filter_status === "custom" && (
  //           <>
  //             <ForecastSpanText>指定期間</ForecastSpanText>
  //             <ForecastSpanInput type="number" onChange={changeBaseSpan} placeholder={metrics_base_span} />
  //             <ForecastSpanText>ヶ月</ForecastSpanText>
  //           </>
  //         )}
  //       </>
  //     )}
  //   </DataFilterList>
  // );
}
