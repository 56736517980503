import { GET_REVENUE_DETAIL_REQUEST, GET_RESULTS_REQUEST_FOR_NEW_FORECAST, UPDATE_NEW_FORECAST_SIMULATED_RESULT, SET_FORECAST_FILTER_STATUS, SET_FORECAST_FILTER, RESET_FORECAST_FILTER, GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_FORECASTS, GET_RESULTS_REQUEST_FOR_NEW_FORECAST_FOR_CUSTOM } from './types';
import { SET_ARPU } from '../metrics/arpu/types';

export const getRevenueDetailRequest = (resultSpan: string, from: any, to: any, company_id: string) => {
  return {
    type: GET_REVENUE_DETAIL_REQUEST,
    resultSpan,
    from,
    to,
    company_id,
  };
};

export const getResultsRequestForNewForecast = (
  company_id: string,
  forecast_base_span: number,
  selected_data_config_data_span: string,
  custom_span_start_time: any,
  custom_span_end_time: any
) => {
  return {
    type: GET_RESULTS_REQUEST_FOR_NEW_FORECAST,
    company_id,
    forecast_base_span,
    selected_data_config_data_span,
    custom_span_start_time,
    custom_span_end_time
  };
};

export const getResultsRequestForNewForecastForCustom = (
  company_id: string,
  forecast_base_span: number,
  selected_data_config_data_span: string,
  custom_span_start_time: any,
  custom_span_end_time: any,
  forecast_custom_span_start_time: number,
  forecast_custom_span_end_time: number,
) => {
  return {
    type: GET_RESULTS_REQUEST_FOR_NEW_FORECAST_FOR_CUSTOM,
    company_id,
    forecast_base_span,
    selected_data_config_data_span,
    custom_span_start_time,
    custom_span_end_time,
    forecast_custom_span_start_time,
    forecast_custom_span_end_time
  };
};

export const updateSimulatedNewForecastResult = (updated_object: any, resultSpan: string, updated_item_index: number) => {
  return {
    type: UPDATE_NEW_FORECAST_SIMULATED_RESULT,
    updated_object,
    resultSpan,
    updated_item_index,
  };
};
export const setForecastFilter = (forecastFilter: boolean) => {
  return {
    type: SET_FORECAST_FILTER,
    forecastFilter,
  };
};
export const resetForecastFilter = (forecastFilter: boolean) => {
  return {
    type: RESET_FORECAST_FILTER,
    forecastFilter,
  };
};
export const setForecastFilterStatus = (
  forecast_filter_status: string
) => {
  return {
    type: SET_FORECAST_FILTER_STATUS,
    forecast_filter_status
  };
};

export const getResultsRequestForSpanComparisonForecasts = (resultSpan: string, company_id: string, startDate: any, endDate: any, spanOrder: string) => {
  return {
    type: GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_FORECASTS,
    resultSpan,
    company_id,
    startDate,
    endDate,
    spanOrder,
  };
};
