const SET_COMPANY = "SET_COMPANY";
const SET_COMPANY_FROM_TO = "SET_COMPANY_FROM_TO";

const UPDATE_COMPANY_FROM_TO_REQUESTED = "UPDATE_COMPANY_FROM_TO_REQUESTED";
const UPDATE_COMPANY_FROM_TO_SUCCEEDED = "UPDATE_COMPANY_FROM_TO_SUCCEEDED";
const UPDATE_COMPANY_FROM_TO_FAILED = "UPDATE_COMPANY_FROM_TO_FAILED";

const UPDATE_MOVING_AVERAGE_REQUESTED = "UPDATE_MOVING_AVERAGE_REQUESTED";
const UPDATE_MOVING_AVERAGE_SUCCEEDED = "UPDATE_MOVING_AVERAGE_SUCCEEDED";
const UPDATE_MOVING_AVERAGE_FAILED = "UPDATE_MOVING_AVERAGE_FAILED";

const UPDATE_WEEK_START_DATE_REQUESTED = "UPDATE_WEEK_START_DATE_REQUESTED";
const UPDATE_WEEK_START_DATE_SUCCEEDED = "UPDATE_WEEK_START_DATE_SUCCEEDED";
const UPDATE_WEEK_START_DATE_FAILED = "UPDATE_WEEK_START_DATE_FAILED";

const UPDATE_CLOSING_MONTH_REQUESTED = "UPDATE_CLOSED_MONTH_REQUESTED";
const UPDATE_CLOSING_MONTH_SUCCEEDED = "UPDATE_CLOSED_MONTH_SUCCEEDED";
const UPDATE_CLOSING_MONTH_FAILED = "UPDATE_CLOSED_MONTH_FAILED";

const UPDATE_SPREADSHEET_SYNC = "UPDATE_SPREADSHEET_SYNC";

export {
  SET_COMPANY,
  SET_COMPANY_FROM_TO,
  UPDATE_COMPANY_FROM_TO_REQUESTED,
  UPDATE_COMPANY_FROM_TO_SUCCEEDED,
  UPDATE_COMPANY_FROM_TO_FAILED,
  UPDATE_MOVING_AVERAGE_REQUESTED,
  UPDATE_MOVING_AVERAGE_SUCCEEDED,
  UPDATE_MOVING_AVERAGE_FAILED,
  UPDATE_WEEK_START_DATE_REQUESTED,
  UPDATE_WEEK_START_DATE_SUCCEEDED,
  UPDATE_WEEK_START_DATE_FAILED,
  UPDATE_CLOSING_MONTH_REQUESTED ,
  UPDATE_CLOSING_MONTH_SUCCEEDED ,
  UPDATE_CLOSING_MONTH_FAILED ,
  UPDATE_SPREADSHEET_SYNC,
};

// const GET_MOVING_AVERAGE_SUCCEEDED = "GET_MOVING_AVERAGE_SUCCEEDED";
// const GET_MOVING_AVERAGE_FAILED = "GET_MOVING_AVERAGE_FAILED";

// const GET_MOVING_AVERAGE_REQUESTED = "GET_MOVING_AVERAGE_REQUESTED";
// const GET_MOVING_AVERAGE_SUCCEEDED = "GET_MOVING_AVERAGE_SUCCEEDED";
// const GET_MOVING_AVERAGE_FAILED = "GET_MOVING_AVERAGE_FAILED";
// const UPDATE_MOVING_AVERAGE_REQUESTED = "UPDATE_MOVING_AVERAGE_REQUESTED";
// const UPDATE_MOVING_AVERAGE_SUCCEEDED = "UPDATE_MOVING_AVERAGE_SUCCEEDED";
// const UPDATE_MOVING_AVERAGE_FAILED = "UPDATE_MOVING_AVERAGE_FAILED";
// const GET_WEEK_START_DATE_REQUESTED = "GET_WEEK_START_DATE_REQUESTED";
// const GET_WEEK_START_DATE_SUCCEEDED = "GET_WEEK_START_DATE_SUCCEEDED";
// const GET_WEEK_START_DATE_FAILED = "GET_WEEK_START_DATE_FAILED";
// const UPDATE_WEEK_START_DATE_REQUESTED = "UPDATE_WEEK_START_DATE_REQUESTED";
// const UPDATE_WEEK_START_DATE_SUCCEEDED = "UPDATE_WEEK_START_DATE_SUCCEEDED";
// const UPDATE_WEEK_START_DATE_FAILED = "UPDATE_WEEK_START_DATE_FAILED";

// export { GET_MOVING_AVERAGE_REQUESTED, GET_MOVING_AVERAGE_SUCCEEDED, GET_MOVING_AVERAGE_FAILED, UPDATE_MOVING_AVERAGE_REQUESTED, UPDATE_MOVING_AVERAGE_SUCCEEDED, UPDATE_MOVING_AVERAGE_FAILED, GET_WEEK_START_DATE_REQUESTED, UPDATE_WEEK_START_DATE_REQUESTED, GET_WEEK_START_DATE_SUCCEEDED,  GET_WEEK_START_DATE_FAILED, UPDATE_WEEK_START_DATE_SUCCEEDED, UPDATE_WEEK_START_DATE_FAILED };
