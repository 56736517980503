import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import GlobalNav from '../../Layout/GlobalNav';
import PageHeaderRaw from '../../Layout/PageHeader';
import ConfigNavCard from '../ConfigNavCard';

import { updateMemberRequest } from '../../../reducks/members/actions';
import { useLocation } from "react-router-dom";


const EditMember: React.FC = (props: any) => {
  const location = useLocation();
	const pathname = location.pathname;
  // GA計測タグ
	useEffect(() => {
		document.title = 'メンバー編集'; // ページタイトルを変更
		window.gtag('config', 'G-YXXD65BEJ0', {
			'page_path': pathname // 任意のパス（あるいはprops.location.pathnameなど）
		});
	});

  const classes = useStyles();
  let history = useHistory();

  const dispatch = useDispatch();
  console.log('props.params.id: ', props.match.params.id);
  const members = useSelector((state: any) => state.members.members);
  const post_status = useSelector((state: any) => state.members.member_to_edit.post_status);
  const urlDocId = props.match.params.id;
  const memberEdited = members.find((member: any) => member.docId === urlDocId);
  const company_id = useSelector((state: any) => state.user.company_id)
  const [lastName, setLastName] = useState(memberEdited.lastName);
  const [firstName, setFirstName] = useState(memberEdited.firstName);
  const [email, setEmail] = useState(memberEdited.email);
  const { register, errors, handleSubmit } = useForm()

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  }
  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  }
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }

  const handleUpdateMember = () => {
    dispatch(updateMemberRequest(urlDocId, firstName, lastName, email, company_id));
  }

  return(
    <>
      { post_status ?
      history.push("/config/member-setting")
      :
      <Container>
      <GlobalNav />

      <main className={classes.content}>
        <PageHeaderRaw page_title="管理" />

        <div className={classes.configCard}>
          <ConfigNavCard />

          <MemberContent>
            <MemberTitle>名前・メールアドレス編集</MemberTitle>

            <Form onSubmit={ handleSubmit(handleUpdateMember) }>
              <Line>
                <label htmlFor="lastName"><span>【必須】</span>姓</label>
                <input 
                  type="text" 
                  name="lastName"
                  value={lastName} 
                  onChange={handleLastNameChange}
                  ref={register( {required:true} )}
                />
                {errors.lastName && <ErrorText>必須項目です</ErrorText>}
              </Line>

              <Line>
                <label htmlFor="firstName"><span>【必須】</span>名</label>
                <input 
                  type="text"
                  name="firstName"
                  value={firstName} 
                  onChange={handleFirstNameChange}
                  ref={register( {required:true} )}
                />
                {errors.firstName && <ErrorText>必須項目です</ErrorText>}
              </Line>

              <Line>
                <label htmlFor="email"><span>【必須】</span>メールアドレス</label>
                <input 
                  type="email" 
                  name="email"
                  value={email} 
                  onChange={handleEmailChange}
                  ref={register( {required:true} )}
                />
                {errors.email && <ErrorText>必須項目です</ErrorText>}
              </Line>

              <ButtonBlock>
                <Button className="cancel">
                  <Link to='/config/member-setting'>キャンセル</Link>
                </Button>
                <Button className="send" type="submit">更新</Button>
              </ButtonBlock>

            </Form>
          </MemberContent>
        </div>
      </main>
    </Container>
      }
    </>
  );
}

export default EditMember;


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      backgroundColor: '#F4F4F4',
    },
    configCard: {
      display: 'flex',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  }),
);

// Container
const Container = styled.div`
    display: flex;
`;

// MemberContent
const MemberContent = styled.div`
  width: 100%;
  height: 100vh;
  padding: 33px 40px;
  box-sizing: border-box;
  background-color: #fff;
`;

// MemberTitle
const MemberTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  line-height: 27px;
  margin: 0 0 28px 0;
`;

// Form
const Form = styled.form`
  max-width: 570px;
  width: 100%;
`;
// Line
const Line = styled.div`
  margin: 0 0 24px 0;
  padding-left: 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  label {
    color: #435878;
    margin-right: 34px;
    display: inline-block;

    span {
      font-size: 14px;
      font-weight: bold;
      color: #AF002A;
    }
  }
  input {
    font-size: 14px;
    line-height: 21px;
    color: #283F46;
    width: 100%;
    max-width: 296px;
    border: 1px solid #C3CEDF;
    border-radius: 4px;
    padding: 12px 21px;
    box-sizing: border-box;
  }
`;
// ButtonBlock
const ButtonBlock = styled.div`
  max-width: 570px;
  width: 100%;
  padding-top: 30px;
  display: flex;
  justify-content: flex-end;
`;
// Button
const Button = styled.button`
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
  color: #fff;
  text-align: center;
  max-width: 152px;
  width: 100%;
  height: 36px;
  padding: 9px 10px 10px 10px;
  display: inline-block;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  appearance: none;
  outline: none;
  a {
    color: #435878;
    text-decoration: none;
    display: block;
  }
  &.send {
    background-color: #0066FF;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
  }
  &:hover {
    opacity: .6;
  }
`;

const ErrorText = styled.p`
  font-size: 12px;
  color: #AF002A;
  position: absolute;
  top: 50px;
  left: 288px;
`
