import React from "react";
import { useDispatch } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { setFilter } from "../../reducks/filter/actions";
import { clearTargetDataForSpanComparison } from "../../reducks/targetData/actions";

const SubMenuWrapper = styled.div({
  paddingTop: "20px"
});

const SubMenuList = styled.ul({
  backgroundColor: "#F4F4F4",
  padding: "0 0 20px 0",
  listStyle: "none",
  minWidth: "120px",
  maxWidth: "130px"
});

const SubMenuItem = styled.li({
  margin: "0"
});

const SubMenuLink = styled(NavLink)({
  display: "block",
  color: "#0066FF",
  fontSize: "12px",
  padding: "6px 9px",
  "&:hover": {
    backgroundColor: "#0066FF",
    fontWeight: "bold",
    color: "#FFFFFF"
  },
  "&.current": {
    backgroundColor: "#0066FF",
    fontWeight: "bold",
    color: "#FFFFFF"
  }
});

const SubMenuLabel = styled.dt`
  color: #566970;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 10px;
  &.churn,
  &.customer {
    padding-top: 16px;
  }
`;

const FunnelsSubMenu = () => {
  const location = useLocation();
  const pathName = location.pathname;
  const funnels = useSelector((state: any) => state.funnels.funnel_master_list);
  const dispatch = useDispatch();

  const handleClickSubMenu = () => {
    dispatch(setFilter(""));
    dispatch(clearTargetDataForSpanComparison());
  };

  return (
    <SubMenuWrapper>
      <SubMenuList>
        <SubMenuItem>
          <SubMenuLink
            to="/funnels/summary"
            className={pathName.match("/funnels/summary") ? "current" : ""}
          >
            Summary
          </SubMenuLink>
        </SubMenuItem>
        {funnels.map((funnel: any, i: number) => {
          const funnel_name = funnel.name;
          return (
            <SubMenuItem key={i}>
              <SubMenuLink
                to={"/funnels/" + funnel_name}
                className={
                  pathName.match(`/funnels/${funnel_name}`) ? "current" : ""
                }
              >
                {funnel.name}
              </SubMenuLink>
            </SubMenuItem>
          );
        })}

        {/*-*-*-*-* churn *-*-*-*-*/}
        <SubMenuLabel className="churn">Churn</SubMenuLabel>
        <SubMenuItem onClick={handleClickSubMenu}>
          <SubMenuLink
            to="/funnels/client-churned"
            className={
              pathName.match("/funnels/client-churned") ? "current" : ""
            }
          >
            Account Churned
          </SubMenuLink>
        </SubMenuItem>
        <SubMenuItem onClick={handleClickSubMenu}>
          <SubMenuLink
            to="/funnels/account-churned"
            className={
              pathName.match("/funnels/account-churned") ? "current" : ""
            }
          >
            User Churned
          </SubMenuLink>
        </SubMenuItem>
        <SubMenuItem onClick={handleClickSubMenu}>
          <SubMenuLink
            to="/funnels/revenue-churned"
            className={
              pathName.match("/funnels/revenue-churned") ? "current" : ""
            }
          >
            MRR Churned
          </SubMenuLink>
        </SubMenuItem>

        {/*-*-*-*-* Customer *-*-*-*-*/}
        <SubMenuLabel className="customer">Customer</SubMenuLabel>
        <SubMenuItem onClick={handleClickSubMenu}>
          <SubMenuLink
            to="/funnels/new-customer"
            className={pathName.match("/funnels/new-customer") ? "current" : ""}
          >
            New Customer
          </SubMenuLink>
        </SubMenuItem>
        <SubMenuItem onClick={handleClickSubMenu}>
          <SubMenuLink
            to="/funnels/active-customer"
            className={
              pathName.match("/funnels/active-customer") ? "current" : ""
            }
          >
            Active Customer
          </SubMenuLink>
        </SubMenuItem>
      </SubMenuList>
    </SubMenuWrapper>
  );
};

export default FunnelsSubMenu;
