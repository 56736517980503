import axios from "axios";

function authApiPost(salesforce_api_key, salesforce_secret_key) {
  console.log("salesforce_api_key", salesforce_api_key);
  console.log("salesforce_secret_key", salesforce_secret_key);
  return axios.post(process.env.REACT_APP_AUTH_ENDPOINT, {
    api_key: salesforce_api_key,
    secret_key: salesforce_secret_key
  });
}

export default authApiPost;
