import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import GlobalNav from '../../Layout/GlobalNav';
import PageHeaderRaw from '../../Layout/PageHeader';
import ConfigNavCard from '../ConfigNavCard';
import MemberList from './MemberList';

import { getAuthReq } from '../../../reducks/members/actions'
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      backgroundColor: '#F4F4F4',
    },
    configCard: {
      display: 'flex',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  }),
);


// Container
const Container = styled.div`
    display: flex;
`;

const MemberSetting: React.FC = () => {
  const location = useLocation();
	const pathname = location.pathname;
  // GA計測タグ
	useEffect(() => {
		document.title = 'メンバー管理'; // ページタイトルを変更
		window.gtag('config', 'G-YXXD65BEJ0', {
			'page_path': pathname // 任意のパス（あるいはprops.location.pathnameなど）
		});
	});

  const classes = useStyles();

  const dispatch = useDispatch();

  return(
    <Container>
      <GlobalNav />

      <main className={classes.content}>
        <PageHeaderRaw page_title="管理" />

        <div className={classes.configCard}>

          {/* <button onClick={() => dispatch(getAuthReq())}>GET AUTH</button> */}

          <ConfigNavCard />

          <MemberList />

        </div>
      </main>
    </Container>
  );
}

export default MemberSetting;