import rsf from "../rsf"
import firebase from 'firebase'
import { call, put, takeLatest } from "redux-saga/effects"
import { ADD_MEMBER_REQUESTED, ADD_MEMBER_SUCCEEDED, ADD_MEMBER_FAILED, GET_MEMBERS_REQUESTED, GET_MEMBERS_SUCCEEDED, GET_MEMBERS_FAILED, FETCH_MEMBER_REQUESTED, FETCH_MEMBER_SUCCEEDED, FETCH_MEMBER_FAILED, UPDATE_MEMBER_REQUESTED, UPDATE_MEMBER_SUCCEEDED, UPDATE_MEMBER_FAILED, DELETE_MEMBER_REQUESTED, DELETE_MEMBER_SUCCEEDED, DELETE_MEMBER_FAILED, GET_AUTH_REQUESTED, GET_AUTH_SUCCEEDED, GET_AUTH_FAILED, TRANSFER_OWNERSHIP_REQUESTED, TRANSFER_OWNERSHIP_SUCCEEDED, TRANSFER_OWNERSHIP_FAILED } from "../reducks/members/types"

const db = firebase.firestore();
const auth = firebase.auth();

function* addMember(action: any) {
  try {
    console.log(action.email)
    console.log(action.password)
    const data = yield call(rsf.auth.createUserWithEmailAndPassword, action.email, action.password)

    db.collection('users').doc(`${data.user.uid}`).set({
      id: data.user.uid,
      company_id: action.company_id,
      firstName: action.firstName,
      lastName: action.lastName,
      email: action.email,
      password: action.password,
      updated_at: new Date()
    })

    yield put({ type: ADD_MEMBER_SUCCEEDED, firstName: action.firstName, lastName: action.lastName, email: action.email, password: action.password, docId: data.user.uid, company_id: action.company_id })
  } catch (e) {
    yield put({ type: ADD_MEMBER_FAILED, message: e.message })
  }
}

function* fetchMember(action: any) {
  try {
    const snapshot = yield call(rsf.firestore.getDocument, `users/${action.payload}`)
    const member = snapshot.data()
    console.log("action.payload: ", action.payload)
    yield put({ type: FETCH_MEMBER_SUCCEEDED, member: member })
  } catch (e) {
    yield put({ type: FETCH_MEMBER_FAILED, message: e.message })
  }
}

function* updateMember(action: any) {
  try {
    const doc = yield call(rsf.firestore.updateDocument, `users/${action.id}`, {
      firstName: action.firstName,
      lastName: action.lastName,
      email: action.email,
      updated_at: new Date()
    })

    console.log("UPDATED USER: ", doc)

    const colRef: any = firebase.firestore().collection("users").where("company_id", "==", action.company_id).orderBy("updated_at")
    const snapshot = yield call(rsf.firestore.getCollection, colRef)
    let members: Object[] = []
    let docIds: string[] = []
    snapshot.forEach((member: any) => {
      members = [...members, { ...member.data(), docId: member.id }]
      docIds = [...docIds, member.id] // ドキュメントIDの配列
    })
    // console.log('UPDATED MEMBERS: ', members);
    yield put({ type: UPDATE_MEMBER_SUCCEEDED, members: members })
  } catch (e) {
    yield put({ type: UPDATE_MEMBER_FAILED, message: e.message })
  }
}

// メンバー削除
function* deleteMember(action: any) {

  try {
    yield call(rsf.firestore.deleteDocument, `users/${action.docId}`)

    const colRef: any = firebase.firestore().collection("users").where("company_id", "==", action.company_id).orderBy("updated_at")
    const snapshot = yield call(rsf.firestore.getCollection, colRef)
    let members: Object[] = []
    let docIds: string[] = []
    snapshot.forEach((member: any) => {
      members = [...members, { ...member.data(), docId: member.id }]
      docIds = [...docIds, member.id] // ドキュメントIDの配列
    })
    yield put({ type: DELETE_MEMBER_SUCCEEDED, members: members })
  } catch (e) {
    yield put({ type: DELETE_MEMBER_FAILED, message: e.message })
  }
}

// メンバーリストを取得
function* getMembers(action: any) {
  console.log("getting memgers", action.company_id)
  try {
    console.log("Fetching members")
    const colRef: any = firebase.firestore().collection("users").where("company_id", "==", action.company_id).orderBy("updated_at")
    const snapshot = yield call(rsf.firestore.getCollection, colRef)
    let members: Object[] = []
    let docIds: string[] = []
    snapshot.forEach((member: any) => {
      members = [...members, { ...member.data(), docId: member.id }]
      docIds = [...docIds, member.id] // ドキュメントIDの配列
    })
    console.log(members)
    console.log(docIds)
    yield put({ type: GET_MEMBERS_SUCCEEDED, members: members })
  } catch (e) {
    yield put({ type: GET_MEMBERS_FAILED, message: e.message })
  }
}

function* getAuth() {
  try {
    const user = auth.currentUser;
    if (user) {
      console.log('auth current user email', user.email);
      console.log('auth current user emailVerified', user.emailVerified);
    }
    // console.log('auth', auth);
    // console.log('auth user', auth.currentUser);

    yield put({ type: "GET_AUTH_SUCCEEDED" })
  } catch (e) {
    console.log('auth', auth);
    yield put({ type: "GET_AUTH_FAILED" })
  }
}

function* transferOwnership(action: any) {
  try {
    yield call(rsf.firestore.updateDocument, `users/${action.old_owner.docId}`, {
      firstName: action.old_owner.firstName,
      lastName: action.old_owner.lastName,
      email: action.old_owner.email,
      company_id: action.old_owner.company_id,
      owner: false
    })

    yield call(rsf.firestore.updateDocument, `users/${action.new_owner.docId}`, {
      firstName: action.new_owner.firstName,
      lastName: action.new_owner.lastName,
      email: action.new_owner.email,
      company_id: action.new_owner.company_id,
      owner: true
    })

    // const snapshot = yield call(rsf.firestore.getCollection, "members");
    // let members: Object[] = [];
    // snapshot.forEach((member: any) => {
    //   members = [...members, member.data()]
    // });
    // console.log('UPDATED MEMBERS: ', members);
    yield put({ type: TRANSFER_OWNERSHIP_SUCCEEDED })
    action.history.push("/config/member-setting")
  } catch (e) {
    yield put({ type: TRANSFER_OWNERSHIP_FAILED, message: e.message })
  }
}

const membersSagas = [takeLatest(ADD_MEMBER_REQUESTED, addMember), takeLatest(UPDATE_MEMBER_REQUESTED, updateMember), takeLatest(GET_MEMBERS_REQUESTED, getMembers), takeLatest(FETCH_MEMBER_REQUESTED, fetchMember), takeLatest(DELETE_MEMBER_REQUESTED, deleteMember), takeLatest(GET_AUTH_REQUESTED, getAuth), takeLatest(TRANSFER_OWNERSHIP_REQUESTED, transferOwnership)]

export default membersSagas
