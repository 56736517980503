import rsf from "../rsf"
import { all, call, put, takeLatest, takeEvery } from "redux-saga/effects"
import firebase from "firebase"
import { GET_RESULTS_REQUESTED_FOR_MARKETING_FUNNEL_FORECAST, GET_RESULTS_REQUESTED_FOR_MARKETING_FUNNEL_FORECAST_SUCCEEDED, GET_RESULTS_REQUESTED_FOR_MARKETING_FUNNEL_FORECAST_FAILED } from "../reducks/marketingFunnelForecast/types"

const db = firebase.firestore()

function* getResultsForForecast(action) {
  console.log("ya")
  try {
    const result_span = action.result_span
    let snapshotForecast = []
    let snapshotReal = []
    console.log("results Sagas span action", action)

    const realStartYear = action.start.getFullYear() //実測値を求める範囲の開始年
    const realStartMonth = action.start.getMonth() //実測値を求める範囲の開始月
    const realStart = new Date(realStartYear, realStartMonth, 1) //実測値を求める範囲の開始年月日
    const startMonth = new Date(action.today.getFullYear() - 1, action.today.getMonth() + 1, 1) //昨年の次の月
    const endMonth = new Date(action.today.getFullYear(), action.today.getMonth(), 2) //今年の来月
    console.log("startMonth", startMonth)
    console.log("endMonth", endMonth)
    console.log("realStart", realStart)

    if (result_span === "daily") {
      console.log("cannot forecast with daily data")
    } else if (result_span === "weekly") {
      console.log("cannot forecast with weekly data")
    } else {
      console.log("monthly data")
      // 過去データを取得
      snapshotForecast = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_monthly`).where("result_at", ">=", startMonth).where("result_at", "<=", endMonth))
      // カレンダーの期間内実測値データを取得
      snapshotReal = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_monthly`).where("result_at", ">=", realStart).where("result_at", "<=", action.today))
    }
    let resultsForecast = []
    let resultsReal = []

    snapshotForecast.forEach((result) => {
      resultsForecast = [...resultsForecast, { ...result.data() }]
    })
    snapshotReal.forEach((result) => {
      resultsReal = [...resultsReal, { ...result.data() }]
    })
    console.log("results", resultsForecast)
    console.log("results", resultsReal)
    yield put({ type: GET_RESULTS_REQUESTED_FOR_MARKETING_FUNNEL_FORECAST_SUCCEEDED, resultsForecast: resultsForecast, resultsReal: resultsReal, result_span: result_span })
  } catch (e) {
    yield put({ type: GET_RESULTS_REQUESTED_FOR_MARKETING_FUNNEL_FORECAST_FAILED, message: e.message })
  }
}

const forecastSagas = [takeLatest(GET_RESULTS_REQUESTED_FOR_MARKETING_FUNNEL_FORECAST, getResultsForForecast)]

export default forecastSagas
