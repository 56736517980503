// import update from "immutability-helper";
import { Link, useHistory } from "react-router-dom"
import { ADD_MEMBER_SUCCEEDED, ADD_MEMBER_FAILED, UPDATE_MEMBER_SUCCEEDED, UPDATE_MEMBER_FAILED, FETCH_MEMBER_SUCCEEDED, FETCH_MEMBER_FAILED, GET_MEMBERS_SUCCEEDED, GET_MEMBERS_FAILED, DELETE_MEMBER_SUCCEEDED, DELETE_MEMBER_FAILED, GET_AUTH_SUCCEEDED, GET_AUTH_FAILED, TRANSFER_OWNERSHIP_SUCCEEDED, TRANSFER_OWNERSHIP_FAILED, TOGGLE_MEMBER_TO_EDIT_POST_STATUS, TOGGLE_MEMBER_TO_ADD_POST_STATUS, TOGGLE_MEMBER_TO_DELETE_POST_STATUS } from "./types"
import {RESET_ALL} from "../common/types";

const initialState = {
  member_to_edit: {
    lastName: '',
    firstName: '',
    email: '',
    password: '',
    updated_at: '',
    post_status: false,
    error: '',
  },
  member_to_add: {
    lastName: '',
    firstName: '',
    email: '',
    password: '',
    post_status: false,
    updated_at: '',
    error: '',
  },
  member_to_delete: {
    lastName: '',
    firstName: '',
    email: '',
    password: '',
    post_status: false,
    updated_at: '',
    error: '',
  },
  members: [],
}

const membersReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_ALL:
      return initialState;

    case ADD_MEMBER_SUCCEEDED:
      // return state;
      console.log(action.firstName, action.lastName, action.email)
      return {
        ...state,
        member_to_add: {
          lastName: '',
          firstName: '',
          email: '',
          password: '',
          post_status: true,
        },
        members: [...state.members, { firstName: action.firstName, lastName: action.lastName, email: action.email }]
      }

    case ADD_MEMBER_FAILED:
      console.log('error', action.message);
      return {
        ...state,
        member_to_add: {
          ...state.member_to_add,
          error: action.message
        }
      }

    case UPDATE_MEMBER_SUCCEEDED:
      return {
        ...state,
        member_to_edit: {
          ...state.member_to_edit,
          post_status: true,
        },
        members: action.members
      }

    case UPDATE_MEMBER_FAILED:
      console.log('action.message', action.message);
      return state

    case DELETE_MEMBER_SUCCEEDED:
      return {
        ...state,
        member_to_delete: {
          ...state.member_to_delete,
          post_status: true,
        },
        members: action.members
      }

    case DELETE_MEMBER_FAILED:
      console.log('error', action.message);
      return state;

    case GET_MEMBERS_SUCCEEDED:
      return {
        ...state,
        members: action.members
      }

    case GET_MEMBERS_FAILED:
      console.log('error', action.message);
      return state;

    case FETCH_MEMBER_SUCCEEDED:
      return {
        ...state,
        member: action.member,
      }

    case FETCH_MEMBER_FAILED:
      console.log('error', action.message);
      return state;

    case GET_AUTH_SUCCEEDED:
      return state;

    case GET_AUTH_FAILED:
      console.log('error', action.message);
      return state;

    case TRANSFER_OWNERSHIP_SUCCEEDED:
      return state;

    case TRANSFER_OWNERSHIP_FAILED:
      console.log('error', action.message);
      return state;

    case TOGGLE_MEMBER_TO_EDIT_POST_STATUS:
      return {
        ...state,
        member_to_edit: {
          ...state.member_to_edit,
          post_status: action.post_status
        }
      };

    case TOGGLE_MEMBER_TO_ADD_POST_STATUS:
      return {
        ...state,
        member_to_add: {
          ...state.member_to_add,
          post_status: action.post_status
        }
      };

    case TOGGLE_MEMBER_TO_DELETE_POST_STATUS:
      return {
        ...state,
        member_to_delete: {
          ...state.member_to_delete,
          post_status: action.post_status
        }
      };


    default:
      return state
  }
}

export default membersReducer
