import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { toggleMovingAverageGraphSwitch } from "../../../reducks/sessions/actions";

//  lib components
import { TableBody, TableHead, TableRow } from "@material-ui/core";

//  styled components
import {
  StyledTable,
  StyledCell,
  StickyCell,
  StickerHeadCell,
  StickyRow,
  StyledTableRow,
  Wrapper,
  CellText,
  CellContainer
} from "./VariantFilterStyles";

//  modules
// import { generatedata } from "./PlanComparisonModules";

//  types
import { TargetData, Prefix } from "./types";

//  assest
import UpArrow from "../../../images/icons/reached_arrow.svg";
import DownArrow from "../../../images/icons/unreached_icon.svg";
import styled from "styled-components";
import { dateFormatter } from "../../Util/DateFormatter";
import { unitSwitcher } from "../../Util/unitSwitcher";

interface DraftTableProps {
  data: any;
  planNameArray: any;
  result_span: string;
  target_data_name: string;
  targetDataType: string;
}
export const DraftTable: React.FC<DraftTableProps> = props => {
  const { data, planNameArray, result_span, target_data_name, targetDataType } = props;

  console.log("data rendered", data);

  //  hooks
  const dispatch = useDispatch();

  //  states
  const [open, setOpen] = React.useState(false);
  const [openMovingAverage, setOpenMovingAverage] = React.useState(false);
  const [breakdownOpen, setBreakdownOpen] = React.useState(false);
  const [averageBreakdownOpen, setAverageBreakdownOpen] = React.useState(false);

  //  hendlers
  const clickedMovingAverageButton = () => {
    dispatch(toggleMovingAverageGraphSwitch(movingAverageGraphSwitch));
  };

  //  modules
  // const data = generatedata({
  //   data
  // });
  const movingAverageGraphSwitch = useSelector(
    (state: any) => state.session.moving_average_graph_switch
  );
  console.log("datadatadatadatadata", data);

  return (
    <React.Fragment>
      <Wrapper>
        {/* 【 実績 】 目標・実績・達成率 */}
        <StyledTable>
          {/* Head */}
          <TableHead>
            <TableRow>
              <StickerHeadCell style={{ zIndex: 1 }} />
              {data.map((val: any, i: number) => (
                <StickerHeadCell key={`i_${i}`}>
                  {dateFormatter(result_span, val.result_at.seconds * 1000)}
                </StickerHeadCell>
              ))}
            </TableRow>
          </TableHead>

          {/* Body */}
          <TableBody>
            {/* label */}
            <StickyRow>
              {/* <PlanName color={data.color}>{data.name}</PlanName> */}
            </StickyRow>

            {/* 実績 */}
            {planNameArray.map((val: any, i: any) => {
              return (
                !val.deactivated && (
                  <StyledTableRow>
                    <StickyCell style={{ fontWeight: "bolder" }}>
                      <CellContainer>
                        <CellText style={{ fontWeight: "bold" }}>
                          <SegmentIcon color={val.color} />
                          {val.nameList}
                        </CellText>
                      </CellContainer>
                    </StickyCell>
                    {data.map((d: any, ind: number) => (
                      <StyledCell key={i} style={{ fontWeight: "bolder" }}>
                        {
                          targetDataType === "sales" ?
                            unitSwitcher(
                              d[`${val.dataKeyNameTotal}`],
                              target_data_name
                            )
                            :
                            unitSwitcher(
                              d[`${val.dataKeyNameTotalAccount}`],
                              "active_customer"
                            )
                        }
                      </StyledCell>
                    ))}
                  </StyledTableRow>
                )
              );
            })}
          </TableBody>
        </StyledTable>
      </Wrapper>
    </React.Fragment>
  );
};

const PlanName = styled.h4`
  position: relative;
  margin-top: 10px;
  margin-left: 10px;
  &::before {
    content: "";
    position: absolute;
    top: 5px;
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${(props: any) => props.color};
  }
`;

const SegmentIcon = styled.span`
  display: inline-block;
  margin-right: 5px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${(props: any) => props.color};
`;
