import {RESET_ALL} from "../common/types";

const initialState: object[] = [];

const usersReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_ALL:
      return initialState;

    case "USERS_FETCH_SUCCEEDED":
      // オブジェクトは中身が一緒でも異なるオブジェクトとして考えられる
      // return [...state, ...action.payload]
      return [...action.payload];

    case "USERS_FETCH_FAILED":
      return {
        ...state,
        message: action.message
      };

    case "USER_ADD_SUCCEEDED":
      return [...action.payload];

    case "USER_ADD_FAILED":
      return {
        ...state,
        message: action.message
      };

    default:
      return state;
  }
};

export default usersReducer;
