import React, { useCallback, useRef } from "react";
import { exportComponentAsPNG } from 'react-component-export-image';
import {
  updateDownloadGraphStatus,
} from "../../reducks/download/actions";
import { useSelector, useDispatch } from "react-redux";
import GraphDefault from "../Graph/GraphDefault";
import GraphSwitcher from "../Graph/GraphSwitcher";
// import GraphProductFiltered from "../Graph/GraphProductFiltered";
import GraphSpanFilter from "../Graph/GraphSpanFilter";
import GraphVariantFilter from "../Graph/GraphVariantFilter";
import GraphVariantProductFilter from "../Graph/GraphVariantProductFilter";
// import GraphFiltered from "../Graph/GraphFiltered";
import SpanComparisonGraph from "../Metrics/SpanComparison/SpanComparisonGraph";

function DataGraph(props: any) {
  const dispatch = useDispatch();
  const { result_data_type } = props;
  const activatedFilter = useSelector((state: any) => state.filter);
  const resultSpan = useSelector((state: any) => state.target_data.resultSpan);
  const selected_span = useSelector(
    (state: any) => state.raw_data.selected_data_config.data_span
  );

  // グラフの表示切り替え
  const this_time_graph_switch = useSelector(
    (state: any) => state.session.this_time_graph_switch
  );
  const moving_average_graph_switch = useSelector(
    (state: any) => state.session.moving_average_graph_switch
  );
  const periodic_average_graph = useSelector(
    (state: any) => state.session.periodic_average_graph
  ); //periodic_average_graph

  // グラフで必要なデータキー名
  const target_data_name = useSelector(
    (state: any) => state.target_data.target_data_name
  );
  const key_total = target_data_name + "_total";
  const key_objective = target_data_name + "_objective";
  const key_moving_average = target_data_name + "_moving_average_total";
  const key_moving_average_objective =
    target_data_name + "_moving_average_objective";
  const target_data = useSelector(
    (state: any) => state.target_data.target_data
  );

  let sum_target_data_total = 0;

  target_data.forEach((td: any) => {
    if (
      td[key_total] === undefined ||
      td[key_total] === null ||
      isNaN(td[key_total])
    ) {
      return (td[key_total] = 0);
    }

    sum_target_data_total += td[key_total];
  });
  // wait for target_data is loaded and set in redux store
  const period_average_sum =
    target_data && sum_target_data_total / target_data.length;

  let spanComparisonData;
  const target_data_first_span = useSelector(
    (state: any) => state.target_data.target_data_first_span
  );
  const target_data_second_span = useSelector(
    (state: any) => state.target_data.target_data_second_span
  );

  spanComparisonData = target_data_first_span.map(
    (data: any, index: number) => {
      return { ...data, ...target_data_second_span[index] };
    }
  );

  const line_color_array = [
    "#E53E66",
    "#EAA800",
    "#B7D20D",
    "#0066FF",
    "#009479",
    "#109CBB",
    "#9E00FF"
  ];

  const filtered_data = useSelector(
    (state: any) => state.raw_data.filtered_data
  );
  const labels_name_array = useSelector(
    (state: any) => state.raw_data.labels_name_array
  );

  const opacity_object = useSelector(
    (state: any) => state.raw_data.opacity_object
  );

  const comments = useSelector((state: any) => state.comments.comments);

  const displayType = useSelector((state: any) => state.graph.displayType);

  // Graph Download Section
  const areaRef = useRef<HTMLDivElement>(null);
  const downloadGraphStatus = useSelector((state: any) => state.download.downloadGraphStatus);
  const handleAreaDownload = useCallback(async () => {
    dispatch(
      updateDownloadGraphStatus(0)
    )
    exportComponentAsPNG(areaRef);
  }, [areaRef, dispatch]);

  if (downloadGraphStatus === 1) {
    handleAreaDownload();
  }

  return (
    <div ref={areaRef}>
      {/* 移動平均、当月データグラフ表示切り替えスイッチ */}
      <GraphSwitcher />

      {activatedFilter === "" && (
        <GraphDefault
          activatedFilter={activatedFilter}
          this_time_graph_switch={this_time_graph_switch}
          moving_average_graph_switch={moving_average_graph_switch}
          periodic_average_graph={periodic_average_graph} //periodic_average_graph
          period_average_sum={period_average_sum} //Period Average 期間平均
          target_data_name={target_data_name}
          key_total={key_total}
          key_objective={key_objective}
          key_moving_average={key_moving_average}
          key_moving_average_objective={key_moving_average_objective}
          target_data={target_data}
          resultSpan={resultSpan}
          selected_span={selected_span}
          comments={comments}
          displayType={displayType}
        />
      )}
      {activatedFilter === "期間比較" && (
        <SpanComparisonGraph
          target_data_name={target_data_name}
          data={spanComparisonData}
          resultSpan={resultSpan}
        />
      )}

      {/* {activatedFilter !== "" &&
        activatedFilter === "product" &&
        activatedFilter !== "期間比較" && (
          <GraphVariantProductFilter
            areaRef={areaRef}
            activatedFilter={activatedFilter}
            target_data={target_data}
            target_data_name={target_data_name}
            filtered_data={filtered_data}
            labels_name_array={labels_name_array}
            line_color_array={line_color_array}
            resultSpan={resultSpan}
            opacity_object={opacity_object}
          />
        )} */}
      {/* {activatedFilter !== "" &&
        activatedFilter !== "product" &&
        activatedFilter !== "期間比較" && (
          <GraphVariantFilter
            areaRef={areaRef}
            activatedFilter={activatedFilter}
            target_data={target_data}
            target_data_name={target_data_name}
            filtered_data={filtered_data}
            labels_name_array={labels_name_array}
            line_color_array={line_color_array}
            resultSpan={resultSpan}
            opacity_object={opacity_object}
          />
        )} */}
    </div>
  );
}

export default DataGraph;
