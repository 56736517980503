import rsf from "../../rsf";
import firebase from "firebase";
import { call } from "redux-saga/effects";
const db = firebase.firestore();

// upload history情報を取得
function* fetchUploadHistory(company_id) {
  try {
    const snapshot = yield call(
      rsf.firestore.getCollection,
      db.collection(`companies/${company_id}/upload_histories`).orderBy("updated_at", "desc")
    );

    let upload_histories_list = [];

    snapshot.forEach(history => {
      upload_histories_list = [...upload_histories_list, { ...history.data() }];
    });

    return upload_histories_list;
  } catch (e) {
    console.log("Upload history fetch failed", e.message);
  }
}

export default fetchUploadHistory;
