import React, { Component } from "react";
import styled from "styled-components";
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";

import { yAxisFormatter } from "../../Util/yAxisFormatter";
import { decimalPointChecker } from "../../Util/unitSwitcher";
interface Props {
  areaRef: any;
  funnel_summary_array: any;
  resultSpan: string;
  moving_average_graph_switch: any;
}

const CustomTooltipBar = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    console.log("active", active);
    console.log("payload", payload);
    console.log("label", label);
    return (
      <BarGraphTooltip>
        <BarGraphTooltipHeading>{label}</BarGraphTooltipHeading>
        <BarGraphTooltipLabel>実績</BarGraphTooltipLabel>
        <BarGraphTooltipData>
          {`${decimalPointChecker(payload[0].value)}`}
        </BarGraphTooltipData>
        <BarGraphTooltipHR />
        <BarGraphTooltipLabel>目標</BarGraphTooltipLabel>
        <BarGraphTooltipData>
          {`${decimalPointChecker(payload[1].value)}`}
        </BarGraphTooltipData>
      </BarGraphTooltip>
    );
  }

  return null;
};

export default class FunnelSummaryGraphDefault extends Component<Props> {
  area: any;
  tooltip: HTMLElement | null;
  point: any;

  constructor(props: any) {
    super(props);

    this.area = null;
    this.tooltip = null;
    this.point = null;
  }

  render() {
    return (
      <>
        {this.props.funnel_summary_array.length > 0 ? (
          <Graph>
            <ResponsiveContainer>
              <ComposedChart
                width={730}
                height={250}
                data={this.props.funnel_summary_array}
                ref={this.props.areaRef}
              >
                <XAxis
                  dataKey="funnel_name"
                  tickLine={false}
                  tickSize={10}
                  minTickGap={20}
                  tick={{ fontSize: 10 }}
                />

                <YAxis
                  type="number"
                  tickCount={10}
                  interval={0}
                  tickMargin={10}
                  tickLine={false}
                  yAxisId={0}
                  tickFormatter={tick => tick.toLocaleString()}
                />
                <YAxis
                  type="number"
                  orientation="right"
                  tickCount={10}
                  interval={0}
                  tickMargin={10}
                  tickLine={false}
                  yAxisId={1}
                  tickFormatter={tick =>
                    yAxisFormatter(tick, "transition_rate")
                  }
                />

                <Legend
                  verticalAlign={"top"}
                  align={"left"}
                  width={432}
                  height={32}
                  margin={{ right: 100 }}
                  iconSize={12}
                  wrapperStyle={{ fontSize: "12px", color: "#283f46" }}
                />

                <CartesianGrid stroke="#f5f5f5" />

                <Bar
                  name="実績"
                  dataKey="lead_new_count_total"
                  barSize={32}
                  fill="#0066ff"
                  yAxisId={0}
                />
                <Bar
                  name="目標"
                  dataKey="lead_new_count_objective"
                  barSize={32}
                  fill="#99C2FF"
                  yAxisId={0}
                />
                <Tooltip content={<CustomTooltipBar />} />
              </ComposedChart>
            </ResponsiveContainer>
          </Graph>
        ) : (
          <NoData>グラフを表示できません</NoData>
        )}
      </>
    );
  }
}

// Graph
const Graph = styled.div`
  width: 100%;
  height: 272px;
  padding: 0 10px;
  box-sizing: border-box;

  .recharts-legend-wrapper {
    top: 1px !important;
    left: 10px !important;
  }
  .recharts-surface {
    position: relative;
    top: -2px;
    overflow: visible;
  }
  .recharts-cartesian-axis-tick {
    width: 100px !important;
    .recharts-cartesian-axis-tick-value {
      font-size: 10px;
      color: #435878;
    }
  }
`;

const UiChartTooltip = styled.div`
  opacity: 0;
  position: absolute;
  z-index: -1;
  min-width: 115px;
  width: auto;
  min-height: 48px;
  height: auto;
  background: white;
  border: 1px solid #e2e9f3;
  border-radius: 2px;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.25);
  padding: 8px 9px;
  .tooltip-date {
    font-size: 10px;
    font-weight: bold;
    color: #7591bc;
    margin-bottom: 5px;
  }
  .toltip-databox {
    display: flex;
    justify-content: space-between;
  }
  .tooltip-data-key {
    font-size: 10px;
    color: #435878;
    width: 50%;
    white-space: nowrap;
  }
  .tooltip-data-value {
    font-size: 10px;
    font-weight: bold;
    color: #435878;
  }
`;

const NoData = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #435878;
  margin-bottom: 8px;
  &.main {
    margin-bottom: 8px;
  }
`;

const BarGraphTooltip = styled.div`
  background: #fff;
  padding: 8px 9px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid #e2e9f3;
  border-radius: 4px;
`;

// 日付
const BarGraphTooltipHeading = styled.h3`
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  color: #435878;
  margin-bottom: 5px;
`;

// 当月 | 目標
const BarGraphTooltipLabel = styled.p`
  width: 30%;
  font-size: 10px;
  color: #435878;
  margin-bottom: 3px;
`;
// データ
const BarGraphTooltipData = styled.p`
  width: 70%;
  text-align: right;
  font-size: 10px;
  font-weight: bold;
  color: #435878;
  margin-bottom: 3px;
`;
// 仕切り線
const BarGraphTooltipHR = styled.hr`
  width: 100%;
  height: 1px;
  color: lightgray;
  margin-bottom: 3px;
`;
