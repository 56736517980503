export const colorSwitcherRate = (target_data_name: string, result: number) => {
  switch (target_data_name) {
    case "revenue":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result > 1
        ? "reached"
        : result < 1
        ? "unreached"
        : "equal";
    case "Revenue":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result > 1
        ? "reached"
        : result < 1
        ? "unreached"
        : "equal";
    case "mrr_total_value":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result > 1
        ? "reached"
        : result < 1
        ? "unreached"
        : "equal";
    case "MRR":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result > 1
        ? "reached"
        : result < 1
        ? "unreached"
        : "equal";
    case "unit_economics":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result > 1
        ? "reached"
        : result < 1
        ? "unreached"
        : "equal";
    case "UnitEconomics":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result > 1
        ? "reached"
        : result < 1
        ? "unreached"
        : "equal";
    case "cac":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result < 1
        ? "reached"
        : result > 1
        ? "unreached"
        : "equal";
    case "payback_period":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result < 1
        ? "reached"
        : result > 1
        ? "unreached"
        : "equal";
    case "PaybackPeriod":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result < 1
        ? "reached"
        : result > 1
        ? "unreached"
        : "equal";
    case "ltv":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result > 1
        ? "reached"
        : result < 1
        ? "unreached"
        : "equal";
    case "LTV":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result > 1
        ? "reached"
        : result < 1
        ? "unreached"
        : "equal";
    case "arpu":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result > 1
        ? "reached"
        : result < 1
        ? "unreached"
        : "equal";
    case "churn_rate":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result < 1
        ? "reached"
        : result > 1
        ? "unreached"
        : "equal";
    case "mrr_churn_rate":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result < 1
        ? "reached"
        : result > 1
        ? "unreached"
        : "equal";
    case "churnRate":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result < 1
        ? "reached"
        : result > 1
        ? "unreached"
        : "equal";
    case "nrr":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result > 1
        ? "reached"
        : result < 1
        ? "unreached"
        : "equal";
    case "NRR":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result > 1
        ? "reached"
        : result < 1
        ? "unreached"
        : "equal";
    case "grr":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result > 1
        ? "reached"
        : result < 1
        ? "unreached"
        : "equal";
    case "GRR":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result > 1
        ? "reached"
        : result < 1
        ? "unreached"
        : "equal";
    case "quick_ratio":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result > 1
        ? "reached"
        : result < 1
        ? "unreached"
        : "equal";
    case "QuickRatio":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result > 1
        ? "reached"
        : result < 1
        ? "unreached"
        : "equal";
    case "acv_cumulative":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result > 1
        ? "reached"
        : result < 1
        ? "unreached"
        : "equal";
    case "ACVCumulative":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result > 1
        ? "reached"
        : result < 1
        ? "unreached"
        : "equal";
    case "acv_new":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result > 1
        ? "reached"
        : result < 1
        ? "unreached"
        : "equal";
    case "ACVNew":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result > 1
        ? "reached"
        : result < 1
        ? "unreached"
        : "equal";
    case "cpa":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result < 1
        ? "reached"
        : result > 1
        ? "unreached"
        : "equal";
    case "conversion_rate":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result > 1
        ? "reached"
        : result < 1
        ? "unreached"
        : "equal";
    case "ConversionRate":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result > 1
        ? "reached"
        : result < 1
        ? "unreached"
        : "equal";
    case "client_churned":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result < 1
        ? "reached"
        : result > 1
        ? "unreached"
        : "equal";
    case "account_churned":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result < 1
        ? "reached"
        : result > 1
        ? "unreached"
        : "equal";
    case "revenue_churned":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result < 1
        ? "reached"
        : result > 1
        ? "unreached"
        : "equal";
    case "new_customer":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result > 1
        ? "reached"
        : result < 1
        ? "unreached"
        : "equal";
    case "active_customer":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result > 1
        ? "reached"
        : result < 1
        ? "unreached"
        : "equal";
    case "profit":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result > 1
        ? "reached"
        : result < 1
        ? "unreached"
        : "equal";
    case "cost":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result < 1
        ? "reached"
        : result > 1
        ? "unreached"
        : "equal";
    case "lead_count":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result > 1
        ? "reached"
        : result < 1
        ? "unreached"
        : "equal";
    case "lead_transition_rate":
      return result === 1 ||
        result === undefined ||
        result === 0 ||
        result === Infinity
        ? "equal"
        : result > 1
        ? "reached"
        : result < 1
        ? "unreached"
        : "equal";
    default:
  }
};

export const colorSwitcherDif = (target_data_name: string, result: number) => {
  switch (target_data_name) {
    case "revenue":
      return result === 0
        ? "equal"
        : result > 0
        ? "reached"
        : result < 0
        ? "unreached"
        : "";
    case "mrr_total_value":
      return result === 0
        ? "equal"
        : result > 0
        ? "reached"
        : result < 0
        ? "unreached"
        : "";
    case "unit_economics":
      return result === 0
        ? "equal"
        : result > 0
        ? "reached"
        : result < 0
        ? "unreached"
        : "";
    case "cac":
      return result === 0
        ? "equal"
        : result < 0
        ? "reached"
        : result > 0
        ? "unreached"
        : "";
    case "payback_period":
      return result === 0
        ? "equal"
        : result < 0
        ? "reached"
        : result > 0
        ? "unreached"
        : "";
    case "ltv":
      return result === 0
        ? "equal"
        : result > 0
        ? "reached"
        : result < 0
        ? "unreached"
        : "";
    case "arpu":
      return result === 0
        ? "equal"
        : result > 0
        ? "reached"
        : result < 0
        ? "unreached"
        : "";
    case "churn_rate":
      return result === 0
        ? "equal"
        : result < 0
        ? "reached"
        : result > 0
        ? "unreached"
        : "";
    case "mrr_churn_rate":
      return result === 0
        ? "equal"
        : result < 0
        ? "reached"
        : result > 0
        ? "unreached"
        : "";    
    case "nrr":
      return result === 0
        ? "equal"
        : result > 0
        ? "reached"
        : result < 0
        ? "unreached"
        : "";
    case "grr":
      return result === 0
        ? "equal"
        : result > 0
        ? "reached"
        : result < 0
        ? "unreached"
        : "";
    case "quick_ratio":
      return result === 0
        ? "equal"
        : result > 0
        ? "reached"
        : result < 0
        ? "unreached"
        : "";
    case "acv_cumulative":
      return result === 0
        ? "equal"
        : result > 0
        ? "reached"
        : result < 0
        ? "unreached"
        : "";
    case "acv_new":
      return result === 0
        ? "equal"
        : result > 0
        ? "reached"
        : result < 0
        ? "unreached"
        : "";
    case "cpa":
      return result === 0
        ? "equal"
        : result < 0
        ? "reached"
        : result > 0
        ? "unreached"
        : "";
    case "conversion_rate":
      return result === 0
        ? "equal"
        : result > 0
        ? "reached"
        : result < 0
        ? "unreached"
        : "";
    case "client_churned":
      return result === 0
        ? "equal"
        : result < 0
        ? "reached"
        : result > 0
        ? "unreached"
        : "";
    case "account_churned":
      return result === 0
        ? "equal"
        : result < 0
        ? "reached"
        : result > 0
        ? "unreached"
        : "";
    case "revenue_churned":
      return result === 0
        ? "equal"
        : result < 0
        ? "reached"
        : result > 0
        ? "unreached"
        : "";
    case "new_customer":
      return result === 0
        ? "equal"
        : result > 0
        ? "reached"
        : result < 0
        ? "unreached"
        : "";
    case "active_customer":
      return result === 0
        ? "equal"
        : result > 0
        ? "reached"
        : result < 0
        ? "unreached"
        : "";
    case "profit":
      return result === 0
        ? "equal"
        : result > 0
        ? "reached"
        : result < 0
        ? "unreached"
        : "";
    case "cost":
      return result === 0
        ? "equal"
        : result < 0
        ? "reached"
        : result > 0
        ? "unreached"
        : "";
    case "lead_count":
      return result === 0
        ? "equal"
        : result > 0
        ? "reached"
        : result < 0
        ? "unreached"
        : "";
    case "lead_transition_rate":
      return result === 0
        ? "equal"
        : result > 0
        ? "reached"
        : result < 0
        ? "unreached"
        : "";
    default:
      return "equal";
  }
};

export const funnelColorSwitcherDif = (result: number) => {
  return result === 0
    ? "null"
    : result === null
    ? "equal"
    : result > 0
    ? "reached"
    : result < 0
    ? "unreached"
    : "";
};

export const funnelColorSwitcherRate = (result: number) => {
  return result === 1 ||
    result === undefined ||
    result === 0 ||
    result === Infinity
    ? "equal"
    : result > 1
    ? "reached"
    : result < 1
    ? "unreached"
    : "equal";
};

export const colorSwitcherObjTotal = (
  data_objective: number | null | undefined,
  data_total: number | null | undefined
) => {
  if (data_objective && data_total) {
    if (data_objective < data_total) {
      return "reached";
    } else if (data_objective > data_total) {
      return "unreached";
    } else {
      return "equal";
    }
  } else {
    return "equal";
  }
};
