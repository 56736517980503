import rsf from "../../rsf"

import firebase from "firebase"

const db = firebase.firestore()



// 詳細データの取得
const targetCollectionGroup = (collection_name, custom_span_start_time, custom_span_end_time, company_id, selected_data_config_data_span, selected_data_config_data_type)=> {
  // 期間設定
  const start_time = new Date(custom_span_start_time.seconds * 1000);
  const end_time = new Date(custom_span_end_time.seconds * 1000);

  // 当月
  const thisMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  const month = thisMonth.getMonth();

  // 昨月
  const lastMonth = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 1));

  // 翌月
  const nextMonth = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month + 1));

  // 一昨月
  const lastLastMonth = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 2));

  // 6ヶ月前
  const last6Months = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 5));

  // 12ヶ月前
  const last12Months = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 11));



  if (selected_data_config_data_span === 'custom') {
    return db
      .collectionGroup(collection_name)
      .where('company_id', '==', company_id)
      .where('result_at', '>=', start_time)
      .where('result_at', '<=', end_time)
      .where('resultSpan', '==', selected_data_config_data_type)
      .get();
  } else if (selected_data_config_data_span === 'this_month') {
    console.log('selected_data_config_data_span', selected_data_config_data_span);
    console.log('selected_data_config_data_type', selected_data_config_data_type);
    console.log('collection_name', collection_name);
    console.log('start_time', lastMonth);
    console.log('end_time', nextMonth);


    return db
      .collectionGroup(collection_name)
      .where('company_id', '==', company_id)
      .where('result_at', '>=', lastMonth)
      .where('result_at', '<=', nextMonth)
      .where('resultSpan', '==', selected_data_config_data_type)
      .get();
  } else if (selected_data_config_data_span === 'last_month') {
    return db
      .collectionGroup(collection_name)
      .where('company_id', '==', company_id)
      .where('result_at', '>=', lastLastMonth)
      .where('result_at', '<=', thisMonth)
      .where('resultSpan', '==', selected_data_config_data_type)
      .get();
  } else if (selected_data_config_data_span === 'last_6_months') {
    return db
      .collectionGroup(collection_name)
      .where('company_id', '==', company_id)
      .where('result_at', '>=', last6Months)
      .where('result_at', '<=', thisMonth)
      .where('resultSpan', '==', selected_data_config_data_type)
      .get();
  } else if (selected_data_config_data_span === 'last_12_months') {
    return db
      .collectionGroup(collection_name)
      .where('company_id', '==', company_id)
      .where('result_at', '>=', last12Months)
      .where('result_at', '<=', thisMonth)
      .where('resultSpan', '==', selected_data_config_data_type)
      .get();
  } else {
    return db
      .collectionGroup(collection_name)
      .where('company_id', '==', company_id)
      .where('result_at', '>=', start_time)
      .where('result_at', '<=', end_time)
      .where('resultSpan', '==', selected_data_config_data_type)
      .get();
  }
};

export default targetCollectionGroup;