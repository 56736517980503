import { all } from "redux-saga/effects";
import usersSagas from "./usersSagas";
import sessionsSagas from "./sessionsSagas.js";
import membersSagas from "./membersSagas";
import billingsSagas from "./billingsSagas";
import billingAccountSagas from "./billingAccountSagas";
import resultsSagas from "./resultsSagas.js";
import gridSagas from "./gridSaga";
import dataSagas from "./dataSagas";
import spanComparisonSaga from "./spanComparisonSaga";
import commentsSagas from "./commentsSagas.js";
import companySagas from "./companySagas.js";
import targetForecastSagas from "./targetForecastSagas.js";
import marketingFunnelForecastSagas from "./marketingFunnelForecastSagas.js";
import newForecastSagas from "./newForecastSagas.js";
import newMetricsForecastSagas from "./newMetricsForecastSagas.js";
import newFunnelForecastSagas from "./newFunnelForecastSagas.js";
import targetDataSaga from "./targetDataSaga";
import rawDataSagas from "./rawDataSagas";
import inquirySagas from "./inquirySagas";
import cohortSaga from "./cohortSaga";


export default function* rootSaga() {
  yield all([
    ...usersSagas,
    ...sessionsSagas,
    ...membersSagas,
    ...billingsSagas,
    ...billingAccountSagas,
    ...gridSagas,
    ...resultsSagas,
    ...dataSagas,
    ...spanComparisonSaga,
    ...commentsSagas,
    ...companySagas,
    ...targetForecastSagas,
    ...marketingFunnelForecastSagas,
    ...newForecastSagas,
    ...newMetricsForecastSagas,
    ...newFunnelForecastSagas,
    ...targetDataSaga,
    ...rawDataSagas,
    ...inquirySagas,
    ...cohortSaga,
  ]);
}
