import { GET_UPLOAD_HISTORY_LIST_SUCCEEDED } from "./types";
import { RESET_ALL } from "../common/types";

const initialState: object[] = [];

const uploadHistoryReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_ALL:
      return initialState;

    case GET_UPLOAD_HISTORY_LIST_SUCCEEDED:
      return action.upload_histories;
    default:
      return state;
  }
};

export default uploadHistoryReducer;
