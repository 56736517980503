import React from "react";
import styled from "styled-components";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import GlobalNav from "../Layout/GlobalNav";
import PageHeaderRaw from "../Layout/PageHeaderNoCarendar";
import UploadNavCard from "./UploadNavCard";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      backgroundColor: "#F4F4F4",
    },
    configCard: {
      display: "flex",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  })
);

// Container
const Container = styled.div`
  display: flex;
  min-width: 1000px;
`;

// DataContent
const DataContent = styled.div`
  width: 100%;
  height: 100vh;
  padding: 33px 40px;
  box-sizing: border-box;
  background-color: #fff;
  color: #35455d;
`;

// DataTitle
const DataTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  line-height: 27px;
  margin: 0 0 28px 0;
`;

const DataUpload: React.FC = () => {
  const classes = useStyles();

  return (
    <Container>
      <GlobalNav />

      <main className={classes.content}>
        <PageHeaderRaw page_title="データ" />

        <div className={classes.configCard}>
          <UploadNavCard />
          <DataContent>
            <DataTitle>データを追加する</DataTitle>
            <p>追加するデータを左メニューから選択してください</p>
          </DataContent>
        </div>
      </main>
    </Container>
  );
};

export default DataUpload;
