import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { toggleMovingAverageGraphSwitch } from "../../reducks/sessions/actions";
import checked from "../../images/icons/checked.svg";
import unckecked from "../../images/icons/unckecked.svg";

function ForecastPLGraphSwitcher() {
  const dispatch = useDispatch();
  const resultSpan = useSelector(
    (state: any) => state.raw_data.selected_data_config.data_type
  );
  const activatedFilter = useSelector((state: any) => state.filter);

  // グラフの表示切り替え
  const this_time_graph_switch = useSelector(
    (state: any) => state.session.this_time_graph_switch
  );
  const moving_average_graph_switch = useSelector(
    (state: any) => state.session.moving_average_graph_switch
  );
  const periodic_average_graph = useSelector(
    (state: any) => state.session.periodic_average_graph
  );

  return (
    <GraphSwitchButtons>
      {activatedFilter === "" && (
        <MovingAverageGraphSwitchButton
          onClick={() =>
            dispatch(
              toggleMovingAverageGraphSwitch(moving_average_graph_switch)
            )
          }
        >
          <MovingAverageGraphSwitchButtonCheckBox
            className={moving_average_graph_switch && "activated"}
          />
          <MovingAverageGraphSwitchButtonWord>
            目標
          </MovingAverageGraphSwitchButtonWord>
        </MovingAverageGraphSwitchButton>
      )}

      {/* { activatedFilter === "" &&
      <ThisTimeGraphSwitchButton onClick={() => dispatch(toggleThisTimeGraphSwitch(this_time_graph_switch))}>
        <ThisTimeGraphSwitchButtonCheckBox className={this_time_graph_switch && "activated"} />
        {resultSpan === "monthly" && <ThisTimeGraphSwitchButtonWord>当月</ThisTimeGraphSwitchButtonWord>}
        {resultSpan === "weekly" && <ThisTimeGraphSwitchButtonWord>当週</ThisTimeGraphSwitchButtonWord>}
        {resultSpan === "daily" && <ThisTimeGraphSwitchButtonWord>当日</ThisTimeGraphSwitchButtonWord>}
      </ThisTimeGraphSwitchButton>
      } */}
    </GraphSwitchButtons>
  );
}

export default ForecastPLGraphSwitcher;

// 移動平均・当月グラフの切り替えスイッチ
const GraphSwitchButtons = styled.div`
  position: absolute;
  top: 14px;
  right: 20px;
  z-index: 100;
`;
const MovingAverageGraphSwitchButton = styled.button`
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  width: 82px;
  height: 30px;
  border-radius: 4px;
  margin-right: 10px;
  padding: 0 18px 2px;
`;
const MovingAverageGraphSwitchButtonCheckBox = styled.span`
  display: inline-block;
  position: relative;
  width: 12px;
  height: 12px;
  margin-right: 6px;
  vertical-align: middle;
  &::after {
    position: absolute;
    content: "";
    background-image: url(${unckecked});
    background-size: contain;
    background-position: center;
    width: 13px;
    height: 12px;
  }
  &.activated {
    &::after {
      background-image: url(${checked});
    }
  }
`;
const MovingAverageGraphSwitchButtonWord = styled.span`
  display: inline-block;
  font-size: 10px;
`;
