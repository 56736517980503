import { unitSwitcher } from "../../Util/unitSwitcher";
import { FunnelData, TargetData, Prefix, GraphData } from "./types";
import { Label } from 'recharts';

export const generateTableData = (params: {
  dataType: "metrics" | "funnel" ;
  prefix?: Prefix;
  targetData?: TargetData[];
  funnelData?: FunnelData[];
  graphData?: GraphData[];
}): {
	head: { value: string }[];
	body: {
		target: { value: number; label: string }[];
		mrrRate: { value: number; label: string }[];
		totalMrr: { value: number; label: string }[];
		totalOthers: { value: number; label: string }[];
    breakdown?: {
			name: string;
			data: { value: number; label: string }[];
		}[];

	};
} => {
	const { dataType, prefix, targetData, funnelData, graphData } = params;

  //  重要指標の場合
  if (dataType === "metrics" && targetData && prefix) {
    const tableHead =
      targetData.map((val) => {
        const year = new Date(val.result_at.seconds * 1000).getFullYear();
        const month = new Date(val.result_at.seconds * 1000).getMonth() + 1;
        return {
          value: `${year}/${month}`,
        };
      }) || [];

    const target =
      targetData.map((val) => {
        const key = `${prefix}_objective` as keyof TargetData;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value, prefix),
        };
      }) || [];

    const mrrRate =
      targetData.map((val) => {
        const key = `${prefix}_mrr_rate` as keyof TargetData;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value, prefix,{ isPercent: true }),
        };
      }) || [];

    const totalMrr =
      targetData.map((val) => {
        const key = `${prefix}_total_mrr` as keyof TargetData;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value, prefix),
        };
      }) || [];

    const totalOthers =
      targetData.map((val) => {
        const key = `${prefix}_total_other` as keyof TargetData;

        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value, prefix),
        };
      }) || [];
      const breakdown = () => {
        if (prefix === "revenue") {
          return [
            {
              name: "MRR",
              data: targetData.map((val) => {
                // const key: keyof TargetData = "total_mrr";
                const key = `${prefix}_total_mrr` as keyof TargetData;
                const value = val[key] as number;
                return {
                  value,
                  label: getLabelWithUnit(value, "revenue"),
                };
              }),
            },
            {
              name: "Total Others",
              data: targetData.map((val) => {
                // const key: keyof TargetData = "total_other";
                const key = `${prefix}_total_other` as keyof TargetData;
                const value = val[key] as number;
                return {
                  value,
                  label: getLabelWithUnit(value, "revenue"),
                };
              }),
            },
            {
              name: "MRR Rtae",
              data: targetData.map((val) => {
                // const key: keyof TargetData = "mrr_rate";
                const key = `${prefix}_mrr_rate` as keyof TargetData;
                const value = val[key] as number;
                return {
                  value,
                  label: getLabelWithUnit(value, "revenue"),
                };
              }),
            },

          ];
        } 
 else {
          return undefined;
        }
      };



		return {
			head: tableHead,
			body: {
				target,
				mrrRate,
				totalMrr,
        totalOthers,
        breakdown: breakdown(),


			},
		};
	}


  else {
    return {
      head: [],
      body: {
        target: [],
        mrrRate: [],
        totalMrr: [],
        totalOthers: [],

        
      },
    };
  }
};

const getLabelWithUnit = (
  val: number,
  prefix?: Prefix,
  options?: { isPercent?: boolean }
): string => {
  const isPercent = options?.isPercent;
  if (isPercent) return (val * 100)?.toFixed(1) + "%";

  switch (prefix) {
    case "revenue":
    case "mrr_total_value":
    case "cac":
    case "ltv":
    case "arpu":
    case "acv_cumulative":
    case "acv_new":
    case "cpa":
    case "revenue_churned":
      return unitSwitcher(val, "revenue_churned");
    case "unit_economics":
    case "quick_ratio":
      return unitSwitcher(val, "quick_ratio");
    case "payback_period":
      return unitSwitcher(val, "payback_period");
    case "churn_rate":
    case "grr":
    case "nrr":
      return unitSwitcher(val, "rate");
    case "client_churned":
    case "new_customer":
    case "active_customer":
      return unitSwitcher(val, "active_customer");

    default:
      return unitSwitcher(val, "");
  }
};
