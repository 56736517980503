import React from "react";
//  lib components
import { Collapse, TableBody, TableHead, TableRow } from "@material-ui/core";

//  styled components
import {
  StyledTable,
  StyledCell,
  StickyCell,
  StickerHeadCell,
  StickyRow,
  StyledTableRow,
  Wrapper,
  CaretSvgImage,
  StyledButton,
  CellText,
  CellContainer
} from "./styles";

import { FunnelSummaryGraphTransitionRateLeadTimeProps } from "../../Funnels/FunnelSummary/FunnelSummaryGraphTransitionRateLeadTime";
import styled from "styled-components";
import { unitSwitcher } from "../../Util/unitSwitcher";

export const FunnelSummaryTransitionRateLeadTimeTable: React.FC<FunnelSummaryGraphTransitionRateLeadTimeProps> =
  ({ transition_rate_lead_time_array }) => {
    //  states
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <Wrapper>
          {/* 【 実績 】 目標・実績・達成率 */}
          <StyledTable>
            {/* Head */}
            <TableHead>
              <TableRow>
                <StickerHeadCell style={{ zIndex: 1 }} />
                {transition_rate_lead_time_array.map((val, i) => (
                  <StickerHeadCell key={i}>{val.funnel_name}</StickerHeadCell>
                ))}
              </TableRow>
            </TableHead>

            {/* Body */}
            <TableBody>
              <StickyRow>　</StickyRow>
              {/* 実績 */}
              <StyledTableRow>
                <StickyCell style={{ fontWeight: "bolder" }}>
                  <CellContainer>
                    <CellText style={{ fontWeight: "bold" }}>実績</CellText>
                  </CellContainer>
                </StickyCell>
                {transition_rate_lead_time_array.map((val, i) => {
                  return (
                    <StyledCell key={i} style={{ fontWeight: "bolder" }}>
                      {unitSwitcher(val.lead_transition_rate_total, "rate")}
                    </StyledCell>
                  );
                })}
              </StyledTableRow>
            </TableBody>
          </StyledTable>

          {/* 【 実績 】　目標・達成率 */}
          <StyledTable>
            <TableBody>
              {/* 目標 */}
              <StyledTableRow>
                <StickyCell>
                  <CellText>目標</CellText>
                </StickyCell>
                {transition_rate_lead_time_array.map((val, i) => {
                  return (
                    <StyledCell key={i}>
                      {unitSwitcher(val.lead_transition_rate_objective, "rate")}
                    </StyledCell>
                  );
                })}
              </StyledTableRow>

              {/* 達成率 */}
              <StyledTableRow>
                <StickyCell>
                  <CellText>達成率</CellText>
                </StickyCell>
                {transition_rate_lead_time_array.map((val, i) => {
                  return (
                    <StyledCell key={i}>
                      {unitSwitcher(val.lead_transition_rate_rate, "rate")}
                    </StyledCell>
                  );
                })}
              </StyledTableRow>
            </TableBody>
          </StyledTable>

          {/* 【 実績 】 差異・昨対比 */}
          <Collapse in={open}>
            <StyledTable>
              <TableBody>
                {/* 差異 */}
                <StyledTableRow>
                  <StickyCell style={{ borderTop: "none" }}>
                    <CellText>差異</CellText>
                  </StickyCell>

                  {transition_rate_lead_time_array.map((val, i) => {
                    const up =
                      val.lead_transition_rate_difference >= 0 ? true : false;
                    return (
                      <StyledCell key={i} style={{ borderTop: "none" }} up={up}>
                        {unitSwitcher(
                          val.lead_transition_rate_difference,
                          "rate"
                        )}
                      </StyledCell>
                    );
                  })}
                </StyledTableRow>

                {/* 昨対比 */}
                <StyledTableRow>
                  <StickyCell>
                    <CellText>昨対比</CellText>
                  </StickyCell>
                  {transition_rate_lead_time_array.map((val, i) => {
                    const up =
                      val.lead_transition_rate_last_year_comparison_rate >= 0
                        ? true
                        : false;
                    return (
                      <StyledCell key={i} up={up}>
                        {unitSwitcher(
                          val.lead_transition_rate_last_year_comparison_rate,
                          "rate"
                        )}
                      </StyledCell>
                    );
                  })}
                </StyledTableRow>
              </TableBody>
            </StyledTable>
          </Collapse>

          {/* 【 実績 】 詳細の開閉ボタン */}
          <StyledTable>
            {/* Head */}
            <TableHead>
              <TableRow>
                <StickerHeadCell style={{ zIndex: 1 }} />
                {transition_rate_lead_time_array.map((val, i) => (
                  <StickerHeadCell key={i}></StickerHeadCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {/* label */}
              <StickyRow>
                <StyledButton
                  onClick={() => setOpen(!open)}
                  style={{
                    justifyContent: "start",
                    minWidth: "unset"
                  }}
                >
                  詳細
                  <CaretSvgImage open={open} />
                </StyledButton>
              </StickyRow>
            </TableBody>
          </StyledTable>

          {/* リードタイム */}
          <StyledTableSubHeading>リードタイム</StyledTableSubHeading>
          <StyledTable>
            <TableBody>
              <StyledTableRow>
                <StickyCell style={{ fontWeight: "bolder" }}>
                  <CellContainer>
                    <CellText style={{ fontWeight: "bold" }}>実績</CellText>
                  </CellContainer>
                </StickyCell>
                {transition_rate_lead_time_array.map((val, i) => {
                  return (
                    <StyledCell key={i} style={{ fontWeight: "bolder" }}>
                      {unitSwitcher(val.lead_time_total, "lead_time")}
                    </StyledCell>
                  );
                })}
              </StyledTableRow>
            </TableBody>
          </StyledTable>
        </Wrapper>
      </React.Fragment>
    );
  };

const StyledTableSubHeading = styled.h3`
  font-size: 12px;
  color: #435878;
  margin-top: 21px;
  margin-bottom: 9px;
  font-weight: bold;
`;
