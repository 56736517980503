import { START_TARGET_DATA_SEQUENCE, UPDATE_TARGET_DATA, SELECT_SPAN, GET_TARGET_DATA_FOR_SPAN_COMPARISON_REQUEST, CLEAR_TARGET_DATA_FOR_SPAN_COMPARISON } from "./types";

export const startTargetDataSequence = (location: string, results: any, resultSpan: string) => ({
  type: START_TARGET_DATA_SEQUENCE,
  location,
  results,
  resultSpan
});

export const updateTargetData = (location: string) => ({
  type: UPDATE_TARGET_DATA,
  location
});

export const selectSpan = (selected_span: string) => ({
  type: SELECT_SPAN,
  selected_span
});

export const getTargetDataForSpanComparison = (
  company_id: string,
  selected_data_config_data_type: string,
  pathname: string,
  first_span_start: number,
  first_span_end: number,
  second_span_start: number,
  second_span_end: number,
) => ({
  type: GET_TARGET_DATA_FOR_SPAN_COMPARISON_REQUEST,
  company_id,
  selected_data_config_data_type,
  pathname,
  first_span_start,
  first_span_end,
  second_span_start,
  second_span_end
});

export const clearTargetDataForSpanComparison = () => ({
  type: CLEAR_TARGET_DATA_FOR_SPAN_COMPARISON
})