import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import color from "../../style/color";
import { unitSwitcher } from "../../Util/unitSwitcher";
import { colorSwitcherRate, colorSwitcherDif } from "../../Util/colorSwitcher";
import { forecastRevenue, forecastRevenueDetail, forecastUnitCost, forecastUnitCostDetail, forecastGrossProfit, forecastSvgCost, forecastSvgCostDetail, forecastOperatingProfit } from "../../../reducks/sessions/actions";
import { updateForecastPLData, updateRevenueDetailData, updateRevenueTotalData, setPLGrossProfitTotalForecasts, setPLOperatingProfitTotalForecasts, updateRevenueTotalForecastData, setPLGrossProfitTotal, setPLOperatingProfitTotal, handleUpdateCostCat2, handleUpdateCostCat3} from "../../../reducks/targetForecast/actions";
import reached_arrow from "../../../images/icons/reached_arrow.svg";
import unreached_arrow from "../../../images/icons/unreached_icon.svg";
import open_arrow from "../../../images/icons/arrow_down.svg";
import close_arrow from "../../../images/icons/arrow_up.svg";

import data_divider from "../../Util/data_divider";
import handlePLSimulation from "../../Util/handlePLSimulation";
import simulationValidation from "../../Util/simulationValidation";
import {handlePLForecastCostCat2Simulation} from "../../Util/handleCostCat2Simulation"
import {handlePLForecastCostCat3Simulation} from "../../Util/handleCostCat3Simulation"

function TableDefault(props: any) {
  //ディテイル情報開閉の状態管理
  const dispatch = useDispatch();
  const product_name_list = useSelector((state: any) => state.target_forecast.product_name_list)
  const unit_cost_name_list = useSelector((state: any) => state.target_forecast.unit_cost_name_list)
  const integrated_array = useSelector((state: any) => state.target_forecast.integrated_array)
  const svg_cost_name_list = useSelector((state: any) => state.target_forecast.svg_cost_name_list)
  const svg_cost_name_big_list = useSelector((state: any) => state.target_forecast.svg_cost_name_big_list)

  const forecastRevenueOpened = useSelector((state: any) => state.session.forecastRevenueOpened);
  const forecastRevenueDetailOpened = useSelector((state: any) => state.session.forecastRevenueDetailOpened);
  const forecastUnitCostOpened = useSelector((state: any) => state.session.forecastUnitCostOpened);
  const forecastUnitCostDetailOpened = useSelector((state: any) => state.session.forecastUnitCostDetailOpened);
  const forecastGrossProfitOpened = useSelector((state: any) => state.session.forecastGrossProfitOpened);
  const forecastSvgCostOpened = useSelector((state: any) => state.session.forecastSvgCostOpened);
  const forecastSvgCostDetailOpened = useSelector((state: any) => state.session.forecastSvgCostDetailOpened);
  const forecastOperatingProfitOpened = useSelector((state: any) => state.session.forecastOperatingProfitOpened);

  const [PLContentClicked, setPLContentClicked] = useState(false);

  const resultSpan = useSelector((state: any) => state.target_data.resultSpan);
  const innerWidth = useSelector((state: any) => state.session.inner_width)


  const table_width = innerWidth - 630;
  const table_body_width = table_width - 200;
  const cell_quantity = Math.floor(table_body_width / 200);

  const divided_data = data_divider(integrated_array, cell_quantity);
  console.log('divided_data', divided_data)


  return (
    <div className="ForecastPLTableDefault" style={{ display: "flex", flexDirection: "column" }}>
      {divided_data.map((results: [], dindex: number) => {
        return (
          <DataTableSC id="table_width">
            <DataHeader>
              <p style={{ width: "150px" }}>&nbsp;</p>
              {
                // dailyかweeklyだったら一日単位で表示
                resultSpan !== "monthly" &&
                  results &&
                  results.length > 0 &&
                  results.map((result: any) => (
                    <p>
                      {result.result_at.seconds == null ? "データ不足" : `${new Date(result.result_at.seconds * 1000).getFullYear()}`}/{`${new Date(result.result_at.seconds * 1000).getMonth() + 1}`}/{`${new Date(result.result_at.seconds * 1000).getDate()}`}
                    </p>
                  ))
              }
              {
                // monthlyだったら月まで表示
                resultSpan === "monthly" &&
                  results &&
                  results.length > 0 &&
                  results.map((result: any) => (
                    <p>
                      {result.result_at.seconds == null ? "データ不足" : `${new Date(result.result_at.seconds * 1000).getFullYear()}`}/{`${new Date(result.result_at.seconds * 1000).getMonth() + 1}`}
                    </p>
                  ))
              }
            </DataHeader>
            <DataBody style={{ display: "flex" }}>
              {/* DataTitle(ul) */}
              <DataTitleSC>
                {/* 売上 */}
                <TitleItemSC>
                  <label htmlFor="revenue_total">
                    <h4 className={forecastRevenueOpened ? "opened" : "closed"}>売上</h4>
                    <input type="checkbox" id="revenue_total" checked={forecastRevenueOpened} onChange={() => dispatch(forecastRevenue(forecastRevenueOpened))} />
                  </label>
                  {forecastRevenueOpened && (
                    <SummaryListSC>
                      <SummaryItemSC className="label">目標</SummaryItemSC>
                        <FoldableSummaryItemSC className="total_detail">
                            <label htmlFor="revenue_detail">
                              <h4 className={forecastRevenueDetailOpened ? "opened" :"closed"}>売上合計</h4>
                              <input type="checkbox" id="revenue_detail" checked={forecastRevenueDetailOpened} onChange={() => dispatch(forecastRevenueDetail(forecastRevenueDetailOpened))} />
                            </label>
                            {
                              forecastRevenueDetailOpened && (
                                <DetailListSC>
                                  {
                                    product_name_list.map((item: any) => {
                                      return (
                                        <DetailItemSC className="label">{item.product_name}</DetailItemSC>
                                      )
                                    })
                                  }
                                </DetailListSC>
                              )
                            }
                          </FoldableSummaryItemSC>
                      <SummaryItemSC className="label">差異</SummaryItemSC>
                      <SummaryItemSC className="label">昨年対比</SummaryItemSC>
                    </SummaryListSC>
                  )}
                </TitleItemSC>

                {/* 原価 */}
                <TitleItemSC>
                  <label htmlFor="unit_cost">
                    <h4 className={forecastUnitCostOpened ? "opened" : "closed"}>原価</h4>
                    <input type="checkbox" id="unit_cost" checked={forecastUnitCostOpened} onChange={() => dispatch(forecastUnitCost(forecastUnitCostOpened))} />
                  </label>
                  {forecastUnitCostOpened && (
                    <SummaryListSC>
                      <SummaryItemSC className="label">目標</SummaryItemSC>
                      <FoldableSummaryItemSC className="total_detail">
                            <label htmlFor="unit_cost_detail">
                              <h4 className={forecastUnitCostDetailOpened ? "opened" :"closed"}>原価合計</h4>
                              <input
                                type="checkbox" id="unit_cost_detail" 
                                checked={forecastUnitCostDetailOpened} 
                                onChange={() => dispatch(forecastUnitCostDetail(forecastUnitCostDetailOpened))}
                              />
                            </label>
                            {
                                forecastUnitCostDetailOpened && (
                                <DetailListSC>
                                {
                                  unit_cost_name_list.map((name: any) => {
                                    return (
                                      <>
                                        <DetailItemSC className="label">{name.category_2}</DetailItemSC>
                                        {
                                          name.category_3 &&
                                          unit_cost_name_list.map((item: any) => {
                                            return name.category_2 === item.category_2 && item.category_3 !== "" && <DetailItemSC className="label">　{item.category_3}</DetailItemSC>
                                          })
                                        }
                                      </>
                                    )
                                  })
                                }
                                </DetailListSC>
                              )
                            }
                          </FoldableSummaryItemSC>
                      <SummaryItemSC className="label">差異</SummaryItemSC>
                      <SummaryItemSC className="label">昨年対比</SummaryItemSC>
                    </SummaryListSC>
                  )}
                </TitleItemSC>

                {/* 売上総利益 */}
                <TitleItemSC>
                  <label htmlFor="gross_profit">
                    <h4 className={forecastGrossProfitOpened ? "opened" : "closed"}>売上総利益</h4>
                    <input type="checkbox" id="gross_profit" checked={forecastGrossProfitOpened} onChange={() => dispatch(forecastGrossProfit(forecastGrossProfitOpened))} />
                  </label>
                  {forecastGrossProfitOpened && (
                    <SummaryListSC>
                      <SummaryItemSC className="label">目標</SummaryItemSC>
                      <SummaryItemSC className="label">売上総利益</SummaryItemSC>
                      <SummaryItemSC className="label">差異</SummaryItemSC>
                      <SummaryItemSC className="label">昨年対比</SummaryItemSC>
                    </SummaryListSC>
                  )}
                </TitleItemSC>

                {/* 販売管理費 */}
                <TitleItemSC>
                  <label htmlFor="svg_cost">
                    <h4 className={forecastSvgCostOpened ? "opened" : "closed"}>販売管理費</h4>
                    <input type="checkbox" id="svg_cost" checked={forecastSvgCostOpened} onChange={() => dispatch(forecastSvgCost(forecastSvgCostOpened))} />
                  </label>
                  {forecastSvgCostOpened && (
                    <SummaryListSC>
                      <SummaryItemSC className="label">目標</SummaryItemSC>
                      <FoldableSummaryItemSC className="total_detail">
                            <label htmlFor="svg_cost_detail">
                              <h4 className={forecastSvgCostDetailOpened ? "opened" :"closed"}>販売管理費合計</h4>
                              <input 
                                type="checkbox" id="svg_cost_detail" 
                                checked={forecastSvgCostDetailOpened} 
                                onChange={() => dispatch(forecastSvgCostDetail(forecastSvgCostDetailOpened))}
                              />
                            </label>
                            {
                              forecastSvgCostDetailOpened && (
                                <DetailListSC>
                                {
                                  svg_cost_name_big_list.map((name: string) => {
                                    return (
                                      <>
                                        <DetailItemSC>{name}</DetailItemSC>
                                        {
                                          svg_cost_name_list.map((item: any) => {
                                            return name === item.category_2 && item.category_3 !== "" && <DetailItemSC className="label">　{item.category_3}</DetailItemSC>
                                          })
                                        }
                                      </>
                                    )
                                  })
                                }
                                </DetailListSC>
                              )
                            }
                          </FoldableSummaryItemSC>
                      <SummaryItemSC className="label">差異</SummaryItemSC>
                      <SummaryItemSC className="label">昨年対比</SummaryItemSC>
                    </SummaryListSC>
                  )}
                </TitleItemSC>

                {/* 営業利益 */}
                <TitleItemSC>
                  <label htmlFor="operating_profit">
                    <h4 className={forecastOperatingProfitOpened ? "opened" : "closed"}>営業利益</h4>
                    <input type="checkbox" id="operating_profit" checked={forecastOperatingProfitOpened} onChange={() => dispatch(forecastOperatingProfit(forecastOperatingProfitOpened))} />
                  </label>
                  {forecastOperatingProfitOpened && (
                    <SummaryListSC>
                      <SummaryItemSC className="label">目標</SummaryItemSC>
                      <SummaryItemSC className="label">実績</SummaryItemSC>
                      <SummaryItemSC className="label">差異</SummaryItemSC>
                      <SummaryItemSC className="label">昨年対比</SummaryItemSC>
                    </SummaryListSC>
                  )}
                </TitleItemSC>
              </DataTitleSC>

              <DataContents style={{ display: "flex" }}>
                {results.map((result: any, rindex: any) => {
                  return (
                    <DataContent style={{ height: "auto" }} className="DataContent">
                      {/* 売上 */}
                      <li>
                        {/* 売上：達成率 */}
                        <ContentItem className={colorSwitcherRate('revenue', (result.revenue_total_forecasted / result.revenue_objective_forecasted))}>
                          {
                            result.real_data ? (
                              <>
                                {simulationValidation(result, result.revenue_total, result.revenue_objective_forecasted)}
                              </>
                            ):(
                              <>
                                ({simulationValidation(result, result.revenue_total_forecasted, result.revenue_objective_forecasted)})
                              </>
                            )
                          }
                        </ContentItem>
                        {forecastRevenueOpened && (
                          <SummaryListSC>
                            {/* 売上：目標 */}
                            {
                              PLContentClicked && !result.real_data ? (
                                result && 
                                <SummaryItemSC onBlur={() => setPLContentClicked(false)}>

                                  <input
                                    type="text"
                                    onChange={
                                      (e) =>
                                        dispatch(
                                          updateForecastPLData(
                                            handlePLSimulation(e.target.value, result.result_at,  'revenue_objective_forecasted', integrated_array)
                                          )
                                        )
                                    }
                                    value={unitSwitcher(result.revenue_objective_forecasted, 'revenue')}
                                  >
                                  </input>

                                </SummaryItemSC>
                              ) : (
                                <SummaryItemSC onClick={() => setPLContentClicked(true)}>
                                  {!result.real_data && '('}
                                  {
                                    result && unitSwitcher(result.revenue_objective_forecasted, 'revenue')
                                  }
                                  {!result.real_data && ')'}

                                </SummaryItemSC>
                              )
                            }

                            {/* 売上：実績 */}
                            <FoldableSummaryItemSC>

                              <h4 className="data">
                                {result.real_data ? (
                                  <>
                                    {result && unitSwitcher(result.revenue_total, 'revenue')}
                                  </>
                                ):(
                                  <>
                                    ({result &&  unitSwitcher(result.revenue_total_forecasted, 'revenue')})
                                  </>
                                )}
                              </h4>

                              {
                                result.revenue_detail &&
                                forecastRevenueDetailOpened &&
                                (
                                  <DetailListSC>
                                    {
                                      result.revenue_detail.map((detail:any) => {
                                        return (
                                          PLContentClicked ? (
                                          <DetailItemSC className="data" onBlur={() => setPLContentClicked(false)}>
                                            <input type="text"
                                              value={unitSwitcher(detail.revenue_total, 'revenue')}
                                              onChange={(e) => {
                                                if(result.real_data || result.boundary) {
                                                  const result_at_seconds = result.result_at.seconds
                                                  //ターゲット以外の残りの合計値
                                                  const remaining_total = result.revenue_total - detail.revenue_total
                                                  //input_valueから','を取り除く
                                                  const input_value_out_of_comma = e.target.value.replace(/,/g, '');
                                                  //input_valueから¥を取り除く
                                                  const input_value_out_of_yen = input_value_out_of_comma.replace(/￥/g, '');
                                                  const input_target_value = Number(input_value_out_of_yen)
                                                  // 新しい売上合計
                                                  const new_revenue_total =  input_target_value + remaining_total
                                                  console.log('new_revenue_total', new_revenue_total)
                                                  dispatch(updateRevenueDetailData(input_target_value, result_at_seconds, rindex, detail.name))
                                                  dispatch(updateRevenueTotalData(new_revenue_total, rindex, result_at_seconds))
                                                  dispatch(updateRevenueTotalForecastData(new_revenue_total, rindex, result_at_seconds))
                                                  dispatch(setPLGrossProfitTotal((new_revenue_total - result.unit_cost_cat_1_total),result_at_seconds))
                                                  dispatch(setPLGrossProfitTotalForecasts((new_revenue_total - result.unit_cost_cat_1_total),result_at_seconds))
                                                  dispatch(setPLOperatingProfitTotal((new_revenue_total - result.unit_cost_cat_1_total - result.svg_cost_cat_1_total),result_at_seconds))
                                                  dispatch(setPLOperatingProfitTotalForecasts((new_revenue_total - result.unit_cost_cat_1_total - result.svg_cost_cat_1_total),result_at_seconds))
                                                } else {
                                                  const result_at_seconds = result.result_at.seconds
                                                  //ターゲット以外の残りの合計値
                                                  const remaining_total = result.revenue_total_forecasted - detail.revenue_total
                                                  //input_valueから','を取り除く
                                                  const input_value_out_of_comma = e.target.value.replace(/,/g, '');
                                                  //input_valueから¥を取り除く
                                                  const input_value_out_of_yen = input_value_out_of_comma.replace(/￥/g, '');
                                                  const input_target_value = Number(input_value_out_of_yen)
                                                  // 新しい売上合計
                                                  const new_revenue_total =  input_target_value + remaining_total
                                                  console.log('new_revenue_total', new_revenue_total)
                                                  dispatch(updateRevenueDetailData(input_target_value, result_at_seconds, rindex, detail.name))
                                                  dispatch(updateRevenueTotalForecastData(new_revenue_total, rindex, result_at_seconds))
                                                  dispatch(setPLGrossProfitTotalForecasts((new_revenue_total - result.unit_cost_cat_1_total),result_at_seconds))
                                                  dispatch(setPLOperatingProfitTotalForecasts((new_revenue_total - result.unit_cost_cat_1_total - result.svg_cost_cat_1_total),result_at_seconds))
                                                }
                                              }} 
                                            />
                                          </DetailItemSC>
                                          ):(
                                            <DetailItemSC className="data" onClick={() => setPLContentClicked(true)}>
                                              {!result.real_data && '('}
                                              {detail && unitSwitcher(detail.revenue_total, 'revenue')}
                                              {!result.real_data && ')'}
                                            </DetailItemSC>
                                          )
                                        )
                                      })
                                    }
                                  </DetailListSC>
                                )
                              }
                            </FoldableSummaryItemSC>

                            {/* 売上：差異 */}
                            <SummaryItemSC className={colorSwitcherDif('revenue', (result.revenue_total_forecasted - result.revenue_objective_forecasted))}>
                              {
                                result.real_data ? (
                                  <>
                                    {
                                      result && unitSwitcher((result.revenue_total - result.revenue_objective_forecasted), 'revenue')
                                    }
                                  </>
                                ):(
                                  <>
                                  ({result && unitSwitcher((result.revenue_total_forecasted - result.revenue_objective_forecasted), 'revenue')})
                                  </>
                                )
                              }
                            </SummaryItemSC>

                            {/* 売上：昨年対比 */}
                            <SummaryItemSC className={colorSwitcherRate('revenue', result.revenue_last_year_comparison_rate_forecasted)}>
                              {!result.real_data && '('}
                              {
                                result 
                                && 
                                result.revenue_last_year_comparison_rate_forecasted 
                                ? unitSwitcher(result.revenue_last_year_comparison_rate_forecasted, 'rate')
                                : result.revenue_last_year_comparison_rate_forecasted === 0
                                ? unitSwitcher(result.revenue_last_year_comparison_rate_forecasted, 'rate')
                                : "NoData"
                              }
                              {!result.real_data && ')'}
                            </SummaryItemSC>
                          </SummaryListSC>
                        )}
                      </li>

                      {/* 原価 */}
                      <li>
                        {/* 原価：達成率 */}
                        <ContentItem className={colorSwitcherRate('cost', (result.unit_cost_cat_1_total / result.cost_unit_cost_objective_forecasted))}>
                          {!result.real_data && '('}
                          {simulationValidation(result, result.unit_cost_cat_1_total, result.cost_unit_cost_objective_forecasted)}
                          {!result.real_data && ')'}
                        </ContentItem>
                        {forecastUnitCostOpened && (
                          <SummaryListSC>
                            {/* 原価：目標 */}
                            {
                              PLContentClicked ? (
                                result && 
                                <SummaryItemSC onBlur={() => setPLContentClicked(false)}>
                                  <input
                                    type="text"
                                    onChange={
                                      (e) =>
                                        dispatch(
                                          updateForecastPLData(
                                            handlePLSimulation(e.target.value, result.result_at,  'cost_unit_cost_objective_forecasted', integrated_array)
                                          )
                                        )
                                    }
                                    value={unitSwitcher(result.cost_unit_cost_objective_forecasted, 'cost_unit_cost')}
                                  >
                                  </input>
                                </SummaryItemSC>
                              ) : (
                                <SummaryItemSC onClick={() => setPLContentClicked(true)}>
                                  {!result.real_data && '('}
                                  {result && unitSwitcher(result.cost_unit_cost_objective_forecasted, 'revenue')}
                                  {!result.real_data && ')'}
                                </SummaryItemSC>
                              )
                            }

                            {/* 原価：実績 */}
                            <FoldableSummaryItemSC>

                              <h4 className="data">
                                {!result.real_data && '('}
                                {result && result.unit_cost_cat_1_total !== undefined && unitSwitcher(result.unit_cost_cat_1_total, 'cost')}
                                {!result.real_data && ')'}
                              </h4>

                              {
                                result.unit_cost_cat_2_array &&
                                forecastUnitCostDetailOpened && (
                                  <DetailListSC>
                                    {
                                      result 
                                      &&
                                      result.unit_cost_cat_2_array
                                      &&
                                      result.unit_cost_cat_2_array.map((cat_2_item: any, cat_2_index:number) => {
                                        return(
                                          <>
                                          {
                                            //もしカテゴリー３の名前がついてなかったら編集可能なカテゴリー2を表示する
                                            cat_2_item.unit_cost_cat_3_array[0].unit_cost_cat_3_name === "" ? 
                                            (
                                              PLContentClicked ? (
                                                <DetailItemSC className="data" onBlur={() => setPLContentClicked(false)}>
                                                  <input type="text"
                                                    value={cat_2_item && unitSwitcher(cat_2_item.unit_cost_cat_2_total, 'cost')}
                                                    onChange={(e) => {
                                                      dispatch(handleUpdateCostCat2(
                                                        handlePLForecastCostCat2Simulation(
                                                          e.target.value, 
                                                          result.result_at.seconds, 
                                                          integrated_array, 
                                                          "unit_cost",
                                                          cat_2_item.unit_cost_cat_2_name,
                                                          result.real_data,
                                                          result.boundary)
                                                      ))
                                                    }}
                                                    />
                                                </DetailItemSC>
                                              ):(
                                                <DetailItemSC className="data" onClick={() => setPLContentClicked(true)}>
                                                  {!result.real_data && '('}
                                                  {
                                                    cat_2_item && unitSwitcher(cat_2_item.unit_cost_cat_2_total, 'cost')
                                                  }
                                                  {!result.real_data && ')'}    
                                                </DetailItemSC>
                                              )
                                            ):(
                                              //カテゴリー３が存在する場合は編集不可（データ表示のみ）
                                              <DetailItemSC className="data" onClick={() => setPLContentClicked(true)}>
                                                {!result.real_data && '('}
                                                {
                                                  cat_2_item.unit_cost_cat_2_total && unitSwitcher(cat_2_item.unit_cost_cat_2_total, 'cost')
                                                }
                                                {!result.real_data && ')'}
                                              </DetailItemSC>
                                            )
                                          }
                                          {
                                            cat_2_item.unit_cost_cat_3_array.map((cat_3_item: any, cat_3_index: number) => {
                                              //カテゴリー名のvalueが名前が空じゃない場合のみ値を表示する
                                              if (cat_3_item.unit_cost_cat_3_name !== "") {
                                                return(
                                                  PLContentClicked ? (
                                                    <DetailItemSC className="data" onBlur={()=> setPLContentClicked(false)}>
                                                      <input type="text"
                                                        value={
                                                          cat_3_item && unitSwitcher(cat_3_item.unit_cost_cat_3_total, 'cost')
                                                        }
                                                        onChange={(e) => {
                                                          dispatch(handleUpdateCostCat3(
                                                            handlePLForecastCostCat3Simulation(
                                                              e.target.value,
                                                              result.result_at.seconds,
                                                              integrated_array,
                                                              'unit_cost',
                                                              result.real_data,
                                                              result.boundary,
                                                              cat_3_item.unit_cost_cat_3_name,
                                                              cat_2_item.unit_cost_cat_2_name
                                                            )
                                                          ))
                                                        }}
                                                      />
                                                    </DetailItemSC>
                                                  ):(
                                                    <DetailItemSC className="data" onClick={()=> setPLContentClicked(true)}>
                                                      {!result.real_data && '('}
                                                      {
                                                        cat_3_item && unitSwitcher(cat_3_item.unit_cost_cat_3_total, 'cost')
                                                      }
                                                      {!result.real_data && ')'}
                                                    </DetailItemSC>
                                                  )
                                                )
                                              }
                                                return ""
                                              })
                                            }
                                          </>
                                        )
                                      })
                                    }
                                  </DetailListSC>
                                )
                              }
                            </FoldableSummaryItemSC>

                            {/* 原価：差異 */}
                            <SummaryItemSC 
                              className={
                                result.real_data 
                                ? colorSwitcherDif('cost', (result.unit_cost_cat_1_total - result.cost_unit_cost_objective))
                                : colorSwitcherDif('cost', (result.unit_cost_cat_1_total - result.cost_unit_cost_objective_forecasted))
                              }
                            >
                              {!result.real_data && '('}
                              {
                                result &&  unitSwitcher((result.unit_cost_cat_1_total - result.cost_unit_cost_objective_forecasted), 'cost')
                              }
                              {!result.real_data && ')'}
                            </SummaryItemSC>

                            {/* 原価：昨年対比 */}
                            <SummaryItemSC 
                              className={
                                result.real_data
                                ? colorSwitcherRate('cost', result.cost_unit_cost_last_year_comparison_rate)
                                : colorSwitcherRate('cost', result.cost_unit_cost_last_year_comparison_rate_forecasted)
                              }
                            >
                              {!result.real_data && '('}
                              {
                                result &&  unitSwitcher(result.lcost_unit_cost_last_year_comparison_rate_forecasted, 'rate')
                              }
                              {!result.real_data && ')'}
                            </SummaryItemSC>
                          </SummaryListSC>
                        )}
                      </li>

                      {/* 売上純利益 */}
                      <li>
                        {/* 売上純利益：達成率 */}
                        {/* {
                          result.real_data ? (

                          ):(

                          )
                        } */}
                        <ContentItem className={colorSwitcherRate('profit', (result.profit_gross_profit_total_forecasted / result.profit_gross_profit_objective_forecasted))}>
                          {!result.real_data && '('}
                          {simulationValidation(result, result.profit_gross_profit_total_forecasted, result.profit_gross_profit_objective_forecasted)}

                          {!result.real_data && ')'}
                        </ContentItem>
                        {forecastGrossProfitOpened && (
                          <SummaryListSC>
                            {/* 売上純利益：目標 */}
                            {
                              PLContentClicked ? (
                                result && 
                                <SummaryItemSC onBlur={() => setPLContentClicked(false)}>
                                  <input
                                    type="text"
                                    onChange={
                                      (e) =>
                                        dispatch(
                                          updateForecastPLData(
                                            handlePLSimulation(e.target.value, result.result_at,  'profit_gross_profit_objective_forecasted', integrated_array)
                                          )
                                        )
                                    }
                                    value={unitSwitcher(result.profit_gross_profit_objective_forecasted, 'profit_gross_profit')}
                                  >
                                  </input>
                                </SummaryItemSC>
                              ) : (
                                <SummaryItemSC onClick={() => setPLContentClicked(true)}>
                                  {!result.real_data && '('}
                                  {result && unitSwitcher(result.profit_gross_profit_objective_forecasted, 'revenue')}
                                  {!result.real_data && ')'}
                                </SummaryItemSC>
                              )
                            }

                            {/* 売上純利益：実績 */}
                            <SummaryItemSC>
                              {!result.real_data && '('}
                              {
                                result && unitSwitcher(result.profit_gross_profit_total_forecasted, 'profit')
                              }
                              {!result.real_data && ')'}
                            </SummaryItemSC>

                            {/* 売上純利益：差異 */}
                            <SummaryItemSC className={colorSwitcherDif('profit', result.profit_gross_profit_total_forecasted - result.profit_gross_profit_objective)}>
                              {!result.real_data && '('}
                              {
                                result && unitSwitcher(result.profit_gross_profit_total_forecasted - result.profit_gross_profit_objective_forecasted, 'profit')
                              }
                              {!result.real_data && ')'}
                            </SummaryItemSC>

                            {/* 売上純利益：昨年対比 */}
                            <SummaryItemSC className={colorSwitcherRate('profit', result.profit_gross_profit_last_year_comparison_rate_forecasted)}>
                              {!result.real_data && '('}
                              {
                                result 
                                && 
                                result.profit_gross_profit_last_year_comparison_rate_forecasted 
                                ? unitSwitcher(result.profit_gross_profit_last_year_comparison_rate_forecasted, 'rate')
                                : result.profit_gross_profit_last_year_comparison_rate_forecasted === 0
                                ? unitSwitcher(result.profit_gross_profit_last_year_comparison_rate_forecasted, 'rate')
                                : "NoData"
                              }
                              {!result.real_data && ')'}
                            </SummaryItemSC>
                          </SummaryListSC>
                        )}
                      </li>

                      {/* 販売管理費 */}
                      <li>
                        {/* 販売管理費：達成率 */}
                        <ContentItem className={colorSwitcherRate('cost', (result.svg_cost_cat_1_total / result.cost_svg_cost_objective_forecasted))}>
                          {!result.real_data && '('}
                          {simulationValidation(result, result.svg_cost_cat_1_total, result.cost_svg_cost_objective_forecasted)}
                          {!result.real_data && ')'}
                        </ContentItem>
                        {forecastSvgCostOpened && (
                          <SummaryListSC>
                            {/* 販売管理費：目標 */}

                            {
                              PLContentClicked ? (
                                result && 
                                <SummaryItemSC onBlur={() => setPLContentClicked(false)}>
                                  <input
                                    type="text"
                                    onChange={
                                      (e) =>
                                        dispatch(
                                          updateForecastPLData(
                                            handlePLSimulation(e.target.value, result.result_at,  'cost_svg_cost_objective', integrated_array)
                                          )
                                        )
                                    }
                                    value={unitSwitcher(result.cost_svg_cost_objective_forecasted, 'cost_svg_cost')}
                                  >
                                  </input>
                                </SummaryItemSC>
                              ) : (
                                <SummaryItemSC onClick={() => setPLContentClicked(true)}>
                                  {!result.real_data && '('}
                                  {
                                    result && unitSwitcher(result.cost_svg_cost_objective_forecasted, 'revenue')
                                  }
                                  {!result.real_data && ')'}
                                </SummaryItemSC>
                              )
                            }

                            {/* 販売管理費：実績 */}
                            <FoldableSummaryItemSC>
                              <h4 className="data">
                                {!result.real_data && '('}
                                {
                                  result && unitSwitcher(result.svg_cost_cat_1_total, 'cost')
                                }
                                {!result.real_data && ')'}
                              </h4>
                              { 
                                result.svg_cost_cat_2_array &&
                                forecastSvgCostDetailOpened && (
                                  <DetailListSC>
                                    {
                                      result 
                                      &&
                                      result.svg_cost_cat_2_array
                                      &&
                                      result.svg_cost_cat_2_array.map((cat_2_item: any, cat_2_index:number) => {
                                        return(
                                          <>
                                          {
                                            //もしカテゴリー３の名前がついてなかったら編集可能なカテゴリー2を表示する
                                            cat_2_item.svg_cost_cat_3_array[0].svg_cost_cat_3_name === "" ? 
                                            (
                                              PLContentClicked ? (
                                                <DetailItemSC className="data" onBlur={() => setPLContentClicked(false)}>
                                                  <input type="text"
                                                    value={
                                                      cat_2_item.svg_cost_cat_2_total 
                                                      ? unitSwitcher(cat_2_item.svg_cost_cat_2_total, 'cost')
                                                      : cat_2_item.svg_cost_cat_2_total === 0
                                                      ? unitSwitcher(cat_2_item.svg_cost_cat_2_total, 'cost')
                                                      : "NoData"
                                                    }
                                                    onChange={(e) => {
                                                      dispatch(handleUpdateCostCat2(
                                                        handlePLForecastCostCat2Simulation(
                                                          e.target.value, 
                                                          result.result_at.seconds, 
                                                          integrated_array, 
                                                          "svg_cost", 
                                                          cat_2_item.svg_cost_cat_2_name,
                                                          result.real_data,
                                                          result.boundary)
                                                      ))
                                                    }}
                                                    />
                                                </DetailItemSC>
                                              ):(
                                                <DetailItemSC className="data" onClick={() => setPLContentClicked(true)}>
                                                  {!result.real_data && '('}
                                                  {
                                                    cat_2_item && unitSwitcher(cat_2_item.svg_cost_cat_2_total, 'cost')
                                                  }
                                                  {!result.real_data && ')'}
                                                </DetailItemSC>
                                              )
                                            ):(
                                              //カテゴリー３が存在する場合は編集不可（データ表示のみ）
                                              <DetailItemSC className="data" onClick={() => setPLContentClicked(true)}>
                                                {!result.real_data && '('}
                                                {
                                                  cat_2_item && unitSwitcher(cat_2_item.svg_cost_cat_2_total, 'cost')
                                                }
                                                {!result.real_data && ')'}
                                              </DetailItemSC>
                                            )
                                          }
                                          {
                                            cat_2_item.svg_cost_cat_3_array.map((cat_3_item: any, cat_3_index: number) => {
                                              //カテゴリー名のvalueが名前が空じゃない場合のみ値を表示する
                                              if (cat_3_item.svg_cost_cat_3_name !== "") {
                                                return(
                                                  PLContentClicked ? (
                                                    <DetailItemSC className="data" onBlur={()=> setPLContentClicked(false)}>
                                                      <input type="text"
                                                        value={
                                                          cat_3_item && unitSwitcher(cat_3_item.svg_cost_cat_3_total, 'cost')
                                                        }
                                                        onChange={(e) => {
                                                          dispatch(handleUpdateCostCat3(
                                                            handlePLForecastCostCat3Simulation(
                                                              e.target.value,
                                                              result.result_at.seconds,
                                                              integrated_array,
                                                              'svg_cost',
                                                              result.real_data,
                                                              result.boundary,
                                                              cat_3_item.svg_cost_cat_3_name,
                                                              cat_2_item.svg_cost_cat_2_name
                                                            )
                                                          ))
                                                        }}
                                                      />
                                                    </DetailItemSC>
                                                  ):(
                                                    <DetailItemSC className="data" onClick={()=> setPLContentClicked(true)}>
                                                      {!result.real_data && '('}
                                                      {
                                                        cat_3_item && unitSwitcher(cat_3_item.svg_cost_cat_3_total, 'cost')
                                                      }
                                                      {!result.real_data && ')'}
                                                    </DetailItemSC>
                                                  )
                                                )
                                              }
                                                return ""
                                              })
                                            }
                                          </>
                                        )
                                      })
                                    }
                                  </DetailListSC>
                                )
                              }
                            </FoldableSummaryItemSC>

                            {/* 販売管理費：差異 */}
                            <SummaryItemSC className={colorSwitcherDif('cost', (result.svg_cost_cat_1_total - result.cost_svg_cost_objective_forecasted))}>
                              {!result.real_data && '('}
                              {
                                result && unitSwitcher((result.svg_cost_cat_1_total - result.cost_svg_cost_objective_forecasted), 'cost')
                              }
                              {!result.real_data && ')'}
                            </SummaryItemSC>

                            {/* 販売管理費：昨年対比 */}
                            <SummaryItemSC className={colorSwitcherRate('cost', result.cost_svg_cost_last_year_comparison_rate_forecasted)}>
                              {!result.real_data && '('}
                              {
                                result 
                                && 
                                result.cost_svg_cost_last_year_comparison_rate_forecasted 
                                ? unitSwitcher(result.cost_svg_cost_last_year_comparison_rate_forecasted, 'rate')
                                : result.cost_svg_cost_last_year_comparison_rate_forecasted === 0
                                ? unitSwitcher(result.cost_svg_cost_last_year_comparison_rate_forecasted, 'rate')
                                : "NoData"
                              }
                              {!result.real_data && ')'}
                            </SummaryItemSC>
                          </SummaryListSC>
                        )}
                      </li>

                      {/* 営業利益 */}
                      <li>
                        {/* 営業利益：達成率 */}
                        <ContentItem className={colorSwitcherRate('profit', (result.profit_operating_profit_total_forecasted / result.profit_operating_profit_objective_forecasted))}>
                          {!result.real_data && '('}
                          {simulationValidation(result, result.profit_operating_profit_total_forecasted, result.profit_operating_profit_objective_forecasted)}
                          {!result.real_data && ')'}
                        </ContentItem>
                        {forecastOperatingProfitOpened && (
                          <SummaryListSC>
                            {/* 営業利益：目標 */}
                            {
                              PLContentClicked ? (
                                result && 
                                <SummaryItemSC onBlur={() => setPLContentClicked(false)}>
                                  <input
                                    type="text"
                                    onChange={
                                      (e) =>
                                        dispatch(
                                          updateForecastPLData(
                                            handlePLSimulation(e.target.value, result.result_at,  'profit_operating_profit_objective_forecasted', integrated_array)
                                          )
                                        )
                                    }
                                    value={unitSwitcher(result.profit_operating_profit_objective_forecasted, 'profit_operating_profit')}
                                  >
                                  </input>
                                </SummaryItemSC>
                              ) : (
                                <SummaryItemSC onClick={() => setPLContentClicked(true)}>
                                  {!result.real_data && '('}
                                  {
                                    result && unitSwitcher(result.profit_operating_profit_objective_forecasted, 'revenue')
                                  }
                                  {!result.real_data && ')'}
                                </SummaryItemSC>
                              )
                            }

                            {/* 営業利益：実績 */}
                            <SummaryItemSC>
                              {!result.real_data && '('}
                              {
                                result && unitSwitcher(result.profit_operating_profit_total_forecasted, 'profit')
                              }
                              {!result.real_data && ')'}
                            </SummaryItemSC>

                            {/* 営業利益：差異 */}
                            <SummaryItemSC className={colorSwitcherDif('profit', (result.profit_operating_profit_total_forecasted - result.profit_operating_profit_objective_forecasted))}>
                              {!result.real_data && '('}
                              {
                                result && unitSwitcher((result.profit_operating_profit_total_forecasted - result.profit_operating_profit_objective_forecasted), 'cost')
                              }
                              {!result.real_data && ')'}
                            </SummaryItemSC>

                            {/* 営業利益：昨年対比 */}
                            <SummaryItemSC className={colorSwitcherRate('profit', result.profit_operating_profit_last_year_comparison_rate_forecasted)}>
                              {!result.real_data && '('}
                              {
                                result 
                                && 
                                result.profit_operating_profit_last_year_comparison_rate_forecasted 
                                ? unitSwitcher(result.profit_operating_profit_last_year_comparison_rate_forecasted, 'rate') 
                                : result.profit_operating_profit_last_year_comparison_rate_forecasted === 0
                                ? unitSwitcher(result.profit_operating_profit_last_year_comparison_rate_forecasted, 'rate') 
                                : "NoData"
                              }
                              {!result.real_data && ')'}
                            </SummaryItemSC>
                          </SummaryListSC>
                        )}
                      </li>
                    </DataContent>
                  );
                })}
              </DataContents>
            </DataBody>
          </DataTableSC>
        );
      })}
    </div>
  );
}

export default TableDefault;

// DataTable
const DataTableSC = styled.div`
  margin-bottom: 27px;
  border-bottom: 1px solid #e2e9f3;
`;
// DataHeader
const DataHeader = styled.div`
  width: 100%;
  box-sizing: border-box;
  color: #435878;
  display: flex;
  font-size: 10px;
  line-height: 15px;

  p {
    min-width: 200px;
    width: 100%;
    text-align: center;
    padding: 5px 0;
    border-right: 1px solid #e2e9f3;
    background-color: #f3f6fa;
    color: #555;
    font-size: 10px;
    &:first-child {
      width: 200px;
      position: absolute;
      background-color: #f3f6fa;
      //left: 0;
    }
    &:nth-child(2) {
      margin-left: 200px;
    }
    &:last-child {
      border-right: none;
    }
  }
`;
// DataBody
const DataBody = styled.div`
  width: 100%;
  align-items: flex-start;
`;
// DataTitle
const DataTitleSC = styled.ul`
  color: #435878;
  min-width: 200px;
  position: absolute;
  //left: 0;
  border-right: 1px solid #e2e9f3;
  background-color: #fff;
`;

const TitleItemSC = styled.li`
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  width: 100%;
  input {
    display: none;
  }
  h4 {
    height: 40px;
    padding: 12px 0 12px 20px;
    border-bottom: 1px solid #e2e9f3;
    &.opened {
      &::after {
        content: "";
        display: inline-block;
        height: 13px;
        width: 18px;
        position: absolute;
        right: 10px;
        background-image: url(${close_arrow});
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    &.closed {
      &::after {
        content: "";
        display: inline-block;
        height: 13px;
        width: 18px;
        position: absolute;
        right: 10px;
        background-image: url(${open_arrow});
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    label {
      width: 100%;
    }
  }
`;

const SummaryListSC = styled.ul``;

const SummaryItemSC = styled.li`
  height: 40px;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  border-bottom: 1px solid #e2e9f3;
  padding: 12px 10px 12px;
  width: 100%;
  text-align: right;
  &.label {
    text-align: left;
    padding: 12px 10px 12px 20px;
  }
  &.reached {
    color: #028cab;
  }
  &.unreached {
    color: #af002a;
  }
  &.equal {
    color: #35455d;
  }
`;

const FoldableSummaryItemSC = styled.li`
  height: auto;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  width: 100%;
  text-align: left;
  padding: 0;
  border-bottom: none; 
  input {
    display: none;
  }
  h4 {
    height: 40px;
    padding: 12px 10px 12px 20px;
    border-bottom: 1px solid #e2e9f3;
    &.data {
      text-align: right;
    } 
    &.opened {
      &::after {
        content: "";
        display: inline-block;
        height: 13px;
        width: 18px;
        position: absolute;
        right: 10px;
        background-image: url(${close_arrow});
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    &.closed {
      &::after  {
        content: "";
        display: inline-block;
        height: 13px;
        width: 18px;
        position: absolute;
        right: 10px;
        background-image: url(${open_arrow});
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
  label {
    width: 100%;
  }
`

const DetailListSC = styled.ul``
const DetailItemSC = styled(SummaryItemSC)`
  text-align: left;
  padding: 12px 10px 12px 32px;
  &.data{
    text-align: right;
    color: ${color.equal};
    input {
      display:inline-block
    }
  }
  &.churn{
    color: ${color.unreached}
  }
  &.contraction {
    color: ${color.unreached}
  }
  &.label {
    padding: 12px 10px 0px 32px;
    overflow-x: scroll;
    white-space: nowrap;
  }
`

// DataContents
const DataContents = styled.div`
  width: calc(100% - 200px);
  margin-left: 200px;
`;
// DataContent
const DataContent = styled.ul`
  font-size: 12px;
  min-width: 150px;
  width: 100%;
  border-right: 1px solid #e2e9f3;
  &:last-child {
    border-right: none;
  }
  &:hover,
  &.activeDotShown {
    background-color: rgba(0, 102, 255, 0.05);
  }
`;

const ContentItem = styled.h4`
  width: 100%;
  height: 40px;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  padding: 7px 10px 12px;
  text-align: right;
  border-bottom: 1px solid #e2e9f3;
  position: relative;
  &.reached {
    color: #028cab;
    &::before {
      content: "";
      display: inline-block;
      height: 18px;
      width: 24px;
      position: relative;
      top: 4px;
      background-image: url(${reached_arrow});
      background-repeat: no-repeat;
    }
  }
  &.unreached {
    color: #af002a;
    &::before {
      content: "";
      display: inline-block;
      height: 18px;
      width: 24px;
      position: relative;
      top: 4px;
      background-image: url(${unreached_arrow});
      background-repeat: no-repeat;
    }
  }
  &.equal {
    color: #35455d;
    &::before {
      content: "";
      display: inline-block;
      height: 18px;
      width: 20px;
    }
  }
  input {
    width: 100%;
  }
  p {
    width: 100%;
  }
`;


