import * as React from "react";
import { useSelector , useDispatch  } from "react-redux";
import axios from "axios";
import { useLocation } from "react-router-dom";
import {
  updateDownloadGraphStatus,
} from "../../reducks/download/actions";
import { styled } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ImageIcon from "@material-ui/icons/Image";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { Button, createStyles, makeStyles } from "@material-ui/core";
import download_graph_icon from "../../images/icons/download_graph_three_dots.svg";

const StyledMenu = styled(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0"
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      }
    }
  }
}));

interface GraphDataDownloadProps {
  GraphImageDownload?: string;
}

const GraphDataDownload: React.FC<GraphDataDownloadProps> = props => {

  const dispatch = useDispatch();
  const location = useLocation();

  const salesforce_api_key = useSelector(
    (state: any) => state.session.salesforce_api_key
  );
  const salesforce_secret_key = useSelector(
    (state: any) => state.session.salesforce_secret_key
  );
  const access_token = useSelector(
    (state: any) => state.session.access_token
  );

  const updateDownloadCsvTarget = useSelector(
    (state: any) => state.download.updateDownloadCsvTarget
  );

  const raw_data_array = useSelector(
    (state: any) => state.raw_data.raw_data_array
  );

  const activatedFilter = useSelector(
    (state: any) => state.filter
  );
  
  const target_data_first_span = useSelector(
    (state: any) => state.target_data.target_data_first_span
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (downloadType: string) => {
    if(downloadType==='graph'){
      dispatch(
        updateDownloadGraphStatus(1)
      )
    }else if(downloadType==='csv'){
      let target = '';
      if(updateDownloadCsvTarget==='/'){
        target=location.pathname;
      }else{
        target = location.pathname+updateDownloadCsvTarget;
      }
      console.log("target ", target);
      let from_date="";
      let to_date="";
      if (activatedFilter === "期間比較" && updateDownloadCsvTarget==='/期間比較') {
        from_date=target_data_first_span.length > 0 ? new Date(target_data_first_span[0].first_result_at.seconds * 1000).toISOString().slice(0, 8)+'01':'';
        to_date=target_data_first_span.length > 0 ? new Date(target_data_first_span[target_data_first_span.length-1].first_result_at.seconds * 1000).toISOString().slice(0, 10):'';
      } else {
        if (raw_data_array.length > 0) {
          const from_date_tmp = new Date(raw_data_array[0].result_at.seconds * 1000).toLocaleString("ja-JP");
          const from_date_array = from_date_tmp.split('/');
          from_date = from_date_array[0] + '-' + ('00' + from_date_array[1]).slice( -2 ) + '-01';

          const to_date_tmp = new Date(raw_data_array[raw_data_array.length-1].result_at.seconds * 1000);
          const to_date_tmp2 = new Date(to_date_tmp.getFullYear(), to_date_tmp.getMonth() + 1, 0).toLocaleString("ja-JP")
          const to_date_array = to_date_tmp2.split('/');
          const to_date_day_array = to_date_array[2].split(' ');
          to_date = to_date_array[0] + '-' + ('00' + to_date_array[1]).slice( -2 ) + '-' + ('00' + to_date_day_array[0]).slice( -2 );
        } else {
          from_date = '';
          to_date = '';
        }
      }
      axios.post(
        process.env.REACT_APP_API_HOST + `/download/csv`,
        {
          salesforce_api_key: salesforce_api_key,
          salesforce_api_secret: salesforce_secret_key,
          target,
          from_date,
          to_date
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`
          }
        }
      ).then(response => {
        if(response.status===200){
          const element = document.createElement("a");
          const file = new Blob([response.data], {type: 'text/plain'});
          element.href = URL.createObjectURL(file);
          let currDate = new Date();
          element.download = (location.pathname+updateDownloadCsvTarget).substring(1)+"_"+currDate.getFullYear()+"-"+currDate.getMonth()+"-"+currDate.getDate()+"-"+currDate.getHours()+"-"+currDate.getMinutes()+"-"+currDate.getSeconds()+".csv";
          document.body.appendChild(element); // Required for this to work in FireFox
          element.click();
        }
      })
      .catch(err => {
        console.error(err);
      });
    }
    setAnchorEl(null);
  };
  const classes = makeStyles(theme =>
    createStyles({
			btn: {
				padding: theme.spacing(1, 2),
				margin: theme.spacing(0, 1),
			},
		})
  )();

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls="demo-customized-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="outlined"
        size="small"
        className={classes.btn}
        disableElevation
        onClick={handleClick}
      >
        <img src={download_graph_icon} alt="" />
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button"
        }}
        anchorEl={anchorEl}
        open={open}
        onClick={() => handleClose('')}
      >
        <MenuItem onClick={() => handleClose('csv')} disableRipple>
          <ReceiptIcon />
          CSV Export
        </MenuItem>
        { props.GraphImageDownload !== 'hide' && 
          <MenuItem onClick={() => handleClose('graph')} disableRipple>
            <ImageIcon />
            グラフ Export
          </MenuItem>
        }
        
      </StyledMenu>
    </div>
  );
};

export default GraphDataDownload;
