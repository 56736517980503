import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import styled from "styled-components";
import close from "../../images/icons/close-large.svg";
import { Link } from "react-router-dom";
import { getUploadHistoryListRequest } from "../../reducks/uploadHistory/actions";
import Pagination from "./Pagination/Pagination";

//defining pagination page size
let PageSize = 20;

const TableUploadHistory = (props: any) => {
  const upload_histories = useSelector((state: any) => state.upload_histories);
  const [uploadHistoryisOpen, setUploadHistoryisOpen] = useState(false);
  const [errorLog, setErrorLog] = useState();
  const handleNoteClick = (errorLog: any) => {
    setErrorLog(errorLog);
    setUploadHistoryisOpen(true);
  };

  const [currentPage, setCurrentPage] = useState(1);

  const currentUploadHistoryTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return upload_histories.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  const setStatusColor = (status: string) => {
    if (status === "取込失敗") {
      return "#DA1948";
    } else if (status === "成功") {
      return "#10B6DB";
    } else if (status === "取込待ち") {
      return "#fca503";
    } else {
      return "";
    }
  };

  const dispatch = useDispatch();

  const comapny_id = useSelector((state: any) => state.user.company_id);

  React.useEffect(() => {
    dispatch(getUploadHistoryListRequest(comapny_id));
  }, [dispatch, comapny_id]);

  return (
    <Container>
      <DataTableSC>
        <DataHeader>
          <DataContents>
            <DataContent>
              <HeadContentList>
                <HeadContentItem>更新日時</HeadContentItem>
                <HeadContentItemFileName>データ種別</HeadContentItemFileName>
                <HeadContentItem>取込元</HeadContentItem>
                <HeadContentItem>更新者</HeadContentItem>
                <HeadContentItemStatus>状態</HeadContentItemStatus>
                <HeadContentItem>備考</HeadContentItem>
              </HeadContentList>
            </DataContent>
          </DataContents>
        </DataHeader>
        <br />
        <DataBody>
          <DataContents>
            {currentUploadHistoryTableData.map((history: any, i: number) => {
              return (
                <DataContent>
                  <li>
                    <BodyContentItem>{history.updated_at}</BodyContentItem>
                    <BodyContentItemFileName>
                      {history.file_name}
                    </BodyContentItemFileName>
                    <BodyContentItem>{history.type_name}</BodyContentItem>
                    <BodyContentItem>{history.username}</BodyContentItem>
                    <BodyContentItemStatus
                      style={{
                        color: setStatusColor(history.status)
                      }}
                    >
                      {history.status}
                    </BodyContentItemStatus>
                    <BodyContentItem
                      style={{ textDecoration: "underline black" }}
                    >
                      <Link
                        to="#"
                        className={uploadHistoryisOpen ? "current" : ""}
                        onClick={() => handleNoteClick(history.error_log)}
                      >
                        {history.note}
                      </Link>
                    </BodyContentItem>
                  </li>
                </DataContent>
              );
            })}
          </DataContents>
        </DataBody>
        <br />
        <Pagination
          currentPage={currentPage}
          totalCount={upload_histories.length}
          pageSize={PageSize}
          onPageChange={(page: any) => setCurrentPage(page)}
        />
      </DataTableSC>

      {uploadHistoryisOpen ? (
        <>
          <ModalBackGround
            onClick={() => setUploadHistoryisOpen(false)}
          ></ModalBackGround>
          <ModalContainer>
            <ErrorLogContent>
              <ErrorLogText>{errorLog} </ErrorLogText>
              <ModalClose onClick={() => setUploadHistoryisOpen(false)} />
            </ErrorLogContent>
          </ModalContainer>
        </>
      ) : (
        ""
      )}
    </Container>
  );
};

export default TableUploadHistory;

// DataTable
const DataTableSC = styled.div({
  marginBottom: "27px",
  width: "100%",
  wordBreak: "break-all"
});

const HeadContentList = styled.li({
  backgroundColor: "#f3f6fa",
  color: "#555"
});

const ErrorLogContent = styled.div({
  margin: "auto",
  width: "74%",
  padding: "2px"
});

const ErrorLogText = styled.div({
  color: "#435878",
  marginBottom: "16px"
});

// DataHeader
const DataHeader = styled.div``;

// DataBody
const DataBody = styled.div``;

// DataContents
const DataContents = styled.div`
  display: "table";
`;
// DataContent
const DataContent = styled.ul`
  display: "table-row";

  &:last-child {
    border-right: none;
    border-bottom: 1px solid #e2e9f3;
  }
`;

const HeadContentItem = styled.h4`
  display: table-cell;
  padding: 3px 10px;
  border-top: 1px solid #e2e9f3;
  &:last-child {
    border-right: none;
  }
  &:first-child {
    border-left: none;
  }
  width: 5%;
  min-width: 140px;
  font-size: 12px;
  line-height: 18px;
  padding: -1px 10px 12px;
  color: #435878;
  p {
    width: 100%;
  }
`;

const HeadContentItemFileName = styled.h4`
  display: table-cell;
  padding: 3px 10px;
  border-top: 1px solid #e2e9f3;
  width: 7.5%;
  min-width: 210px;
  font-size: 12px;
  line-height: 18px;
  padding: -1px 10px 12px;
  color: #435878;
  p {
    width: 100%;
  }
`;

const HeadContentItemStatus = styled.h4`
  display: table-cell;
  padding: 3px 10px;
  border-top: 1px solid #e2e9f3;
  width: 2.5%;
  min-width: 70px;
  font-size: 12px;
  line-height: 18px;
  padding: -1px 10px 12px;
  color: #435878;
  p {
    width: 100%;
  }
`;

const BodyContentItem = styled.h4`
  display: table-cell;
  padding: 3px 10px;
  border-top: 1px solid #e2e9f3;
  border-right: 1px solid #e2e9f3;
  &:last-child {
    border-right: none;
  }
  &:first-child {
    border-left: none;
  }
  width: 5%;
  min-width: 140px;
  font-size: 12px;
  line-height: 18px;
  padding: 7px 10px 12px;
  color: #435878;
  p {
    width: 100%;
  }
`;

const BodyContentItemFileName = styled.h4`
  display: table-cell;
  padding: 3px 10px;
  border-top: 1px solid #e2e9f3;
  border-right: 1px solid #e2e9f3;
  width: 7.5%;
  min-width: 210px;
  font-size: 12px;
  line-height: 18px;
  padding: 7px 10px 12px;
  color: #435878;
  p {
    width: 100%;
  }
`;

const BodyContentItemStatus = styled.h4`
  display: table-cell;
  padding: 3px 10px;
  border-top: 1px solid #e2e9f3;
  border-right: 1px solid #e2e9f3;
  width: 2.5%;
  min-width: 70px;
  font-size: 12px;
  line-height: 18px;
  padding: 7px 10px 12px;
  color: #435878;
  font-weight: 700;
  p {
    width: 100%;
  }
`;

// DataContents
const Container = styled.div`
  display: flex;
  flex-direction: row;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ModalBackGround = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(67, 88, 120, 0.3);
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background: #fff;
  height: 96px;
  width: 480px;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 16px;
  box-sizing: border-box;
  text-align: center;
  z-index: 1001;
  border: 1px solid #999;
`;

const ModalClose = styled.button`
  width: 24px;
  height: 24px;
  background: url(${close});
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  right: 8px;
  top: 6px;
`;
