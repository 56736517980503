import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { store, persistor, sagaMiddleware, history } from './store/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import rootSaga from './sagas';
import { StylesProvider } from '@material-ui/core';

sagaMiddleware.run(rootSaga);
// persistor.purge()
//===========================================
//rootComponent<App>を<PersistGate>でラップする。
//===========================================
ReactDOM.render(
  <Provider store={store}>
    <StylesProvider injectFirst>
    <PersistGate loading={null} persistor={persistor}>
      <App history={history} />
    </PersistGate>
    </StylesProvider>
  </Provider>,
  document.getElementById('root')
);
