// import update from "immutability-helper";
import { GET_COHORT_REQUESTED, GET_COHORT_SUCCEEDED, GET_COHORT_FAILED, UPDATE_BASIC_VALUE, SET_TARGET_SPAN, SET_START_MONTH, SET_BASIC_VALUES,SET_TARGET_MONTH, GET_FILTERED_COHORT_SUCCEEDED, UPDATE_SELECT_TYPE, UPDATE_CHURN_RATE_MEDIAN, SET_CLIENT_CHURNED, SET_REVENUE_CHURNED, SET_CHURN_RATE } from "./types";
import {RESET_ALL} from "../common/types";

const initialState = {
  target_month: new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(new Date(new Date().getFullYear(), new Date().getMonth(), 1).getMonth() - 12)),
  target_span: 12,
  target_data: '',
  target_filter_key: '',
  target_filter_value: '',
  target_start_month: new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0),
  churn_rate_rows: [],
  churn_rate_average: [],
  churn_rate_median : [],
  new_customers_array: [],
  new_customers_average: 0,
  filter_name: "",
  basic_values: [
    {
      basic_value: 0.10,
      data_name: "churn_rate",
      order: 1 
    },
    {
      basic_value: 0.12,
      data_name: "churn_rate",
      order: 2 
    },
    {
      basic_value: 0.14,
      data_name: "churn_rate",
      order: 3 
    },
    {
      basic_value: 0.16,
      data_name: "churn_rate",
      order: 4
    },
    {
      basic_value: 0.18,
      data_name: "churn_rate",
      order: 5
    },
    {
      basic_value: 0.20,
      data_name: "churn_rate",
      order: 6
    },
    {
      basic_value: 0.22,
      data_name: "churn_rate",
      order: 7
    },
    {
      basic_value: 0.24,
      data_name: "churn_rate",
      order: 8
    },
    {
      basic_value: 0.26,
      data_name: "churn_rate",
      order: 9
    },
    {
      basic_value: 0.28,
      data_name: "churn_rate",
      order: 10
    },
    {
      basic_value: 0.30,
      data_name: "churn_rate",
      order: 11
    },
    {
      basic_value: 0.32,
      data_name: "churn_rate",
      order: 12
    },
    {
      basic_value: 0.10,
      data_name: "churn_rate",
      order: 13 
    },
    {
      basic_value: 0.12,
      data_name: "churn_rate",
      order: 14
    },
    {
      basic_value: 0.14,
      data_name: "churn_rate",
      order: 15
    },
    {
      basic_value: 0.16,
      data_name: "churn_rate",
      order: 16
    },
    {
      basic_value: 0.18,
      data_name: "churn_rate",
      order: 17
    },
    {
      basic_value: 0.20,
      data_name: "churn_rate",
      order: 18
    },
    {
      basic_value: 0.22,
      data_name: "churn_rate",
      order: 19
    },
    {
      basic_value: 0.24,
      data_name: "churn_rate",
      order: 20
    },
    {
      basic_value: 0.26,
      data_name: "churn_rate",
      order: 21
    },
    {
      basic_value: 0.28,
      data_name: "churn_rate",
      order: 22
    },
    {
      basic_value: 0.30,
      data_name: "churn_rate",
      order: 23
    },
    {
      basic_value: 0.32,
      data_name: "churn_rate",
      order: 24
    },
    {
      basic_value: 0.10,
      data_name: "churn_rate",
      order: 25
    },
    {
      basic_value: 0.12,
      data_name: "churn_rate",
      order: 26
    },
    {
      basic_value: 0.14,
      data_name: "churn_rate",
      order: 27
    },
    {
      basic_value: 0.16,
      data_name: "churn_rate",
      order: 28
    },
    {
      basic_value: 0.18,
      data_name: "churn_rate",
      order: 29
    },
    {
      basic_value: 0.20,
      data_name: "churn_rate",
      order: 30
    },
    {
      basic_value: 0.22,
      data_name: "churn_rate",
      order: 31
    },
    {
      basic_value: 0.24,
      data_name: "churn_rate",
      order: 32
    },
    {
      basic_value: 0.26,
      data_name: "churn_rate",
      order: 33
    },
    {
      basic_value: 0.28,
      data_name: "churn_rate",
      order: 34
    },
    {
      basic_value: 0.30,
      data_name: "churn_rate",
      order: 35
    },
    {
      basic_value: 0.32,
      data_name: "churn_rate",
      order: 36
    },
  ],
  selectType : {
    churn_rate : "current-month",
    client_churned  : "current-month",
    revenue_churned : "current-month",
  },
  //
  churn_rate : [],
  client_churned : [],
  revenue_churned : [],
};

const cohortReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_ALL:
      return initialState;

    case GET_COHORT_SUCCEEDED:
      console.log('action', action)
      console.log('action.target_month', action.target_month)
      return {
        ...state,
        target_span: action.target_span,
        churn_rate_rows: action.churn_rate_rows,
        churn_rate_average: action.churn_rate_average,
        new_customers_array: action.new_customers_array,
        new_customers_average: action.new_customers_average,
        target_start_month: action.target_month,
        target_filter_key: "",
        target_filter_label: ""
      }

    case GET_COHORT_FAILED:
      console.log('error', action.message);
      return state;

    case UPDATE_BASIC_VALUE:
      return {
        ...state,
        basic_values: state.basic_values.map((item, index) => {
          if(item.data_name === action.data_name && item.order === action.order) {
            return {
              data_name : action.data_name,
              basic_value : action.updated_value,
              order : action.order
            }
          }else{
            return item
          }
        })
      }
    
    case UPDATE_SELECT_TYPE:
      return {
        ...state,
        selectType : {
          ...state.selectType,
          [action.key] : action.selectType
        }
      }
  
    case UPDATE_CHURN_RATE_MEDIAN:
      return {
        ...state,
        churn_rate_median : action.churn_rate_median
      }

    case SET_TARGET_SPAN:
      return {
        ...state,
        target_span:action.switched_span
      }

    case SET_START_MONTH:
      return {
        ...state,
        target_start_month: action.updated_month
      }

    case SET_BASIC_VALUES:
      return {
        ...state,
        basic_values : action.basic_values
      }

    case GET_FILTERED_COHORT_SUCCEEDED:
      return {
        ...state,
        target_start_month: action.target_month,
        churn_rate_rows: action.filtered_churn_results,
        churn_rate_average: action.filtered_churn_data_everage,
        new_customers_array: action.filtered_new_data_results,
        new_customers_average: action.filtered_new_data_average,
        target_filter_key: action.filter_name, 
        target_filter_label: action.filter_label, 
      }
    case SET_CHURN_RATE:
      return {
        ...state,
        churn_rate : action.churn_rate
      }
    case SET_CLIENT_CHURNED:
    return {
        ...state,
        client_churned : action.client_churned
      }
    case SET_REVENUE_CHURNED:
      return {
        ...state,
        revenue_churned : action.revenue_churned
      }

    default:
      return state;
  }
};

export default cohortReducer;
