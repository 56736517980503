import rsf from "../../rsf"
import firebase from "firebase"
import { call } from "redux-saga/effects"

const db = firebase.firestore()

export function get_dataUpdated(company_id ){
    return new Promise((resolve , reject) => {
        db.collection('companies')
            .doc(company_id)
            .get()
            .then(val => {
                const data = val.data();
                const data_updated = data?.data_updated || false;
                if(data_updated ) set_dataUpdated(company_id,false);
                return resolve({data_updated : data_updated });
            }).catch(err => {
                return reject();
            })
    })    
}

export function set_dataUpdated (company_id,data_updated ) {
    return new Promise((resolve ,reject) => {
        db.collection('companies')
            .doc(company_id)
            .update({data_updated})
            .then(val => {
                return resolve();
            }).catch(err => {
                return reject();
            })
    })
}