import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { setFilter } from "../../reducks/filter/actions";
import { getRawDataForFilterRequest } from "../../reducks/rawData/actions";
import { showLoading, hideLoading } from "../../reducks/loading/actions";
import {
  updateDownloadCsvTarget,
} from "../../reducks/download/actions";
import GraphSwicher from "./GraphSwitcher";
import GraphDataDownload from "./GraphDataDownload";

function DataFilters() {
  const dispatch = useDispatch();
  const company_id = useSelector((state: any) => state.user.company_id);
  const filters = useSelector((state: any) => state.filters);
  const activatedFilter = useSelector((state: any) => state.filter);

  const custom_span_start_time = useSelector(
    (state: any) => state.raw_data.selected_data_config.custom_span_start_time
  );
  const custom_span_end_time = useSelector(
    (state: any) => state.raw_data.selected_data_config.custom_span_end_time
  );

  const target_data_name = useSelector(
    (state: any) => state.target_data.target_data_name
  );

  const selected_data_config_data_type = useSelector(
    (state: any) => state.raw_data.selected_data_config.data_type
  );
  const selected_data_config_data_span = useSelector(
    (state: any) => state.raw_data.selected_data_config.data_span
  );

  const firstCalenderStartDate = useSelector(
    (state: any) => state.spanComparisonCalender.firstCalender.startDate
  );
  const firstCalenderEndDate = useSelector(
    (state: any) => state.spanComparisonCalender.firstCalender.endDate
  );
  const secondCalenderStartDate = useSelector(
    (state: any) => state.spanComparisonCalender.secondCalender.startDate
  );
  const secondCalenderEndDate = useSelector(
    (state: any) => state.spanComparisonCalender.secondCalender.endDate
  );

  const data_span = useSelector(
    (state: any) => state.raw_data.selected_data_config.data_span
  );

  const updateDownloadTabName = useSelector((state: any) => state.download.updateDownloadTabName);

  const handleFetchFilteredData = (filter_name: string) => {
    dispatch(showLoading());
    console.log("set_filter");
    if (filter_name === activatedFilter) {
      dispatch(setFilter(""));
      dispatch(hideLoading());
      return false;
    }

    console.log("filter_name should be changed. current:", activatedFilter);
    console.log(
      "selected_data_config_data_span",
      selected_data_config_data_span
    );
    dispatch(
      getRawDataForFilterRequest(
        company_id,
        filter_name,
        target_data_name,
        selected_data_config_data_type,
        selected_data_config_data_span,
        custom_span_start_time,
        custom_span_end_time
      )
    );
  };

  const dateFilterItemButtonClicked = (e: any) => {
    dispatch(setFilter("期間比較"));

    // dispatch(getResultsRequestForSpanComparisonData(result_data_type, resultSpan, company_id, firstCalenderStartDate, firstCalenderEndDate, 'firstSpan'));
    // dispatch(getResultsRequestForSpanComparisonData(result_data_type, resultSpan, company_id, secondCalenderStartDate, secondCalenderEndDate, 'secondSpan'));
  };

  function handleGraphDataDownloadClick(e:any) {
    if(activatedFilter===''){
      dispatch(
        updateDownloadCsvTarget("/"+activatedFilter)
      )
    }else if(activatedFilter==='期間比較'){
      dispatch(
        updateDownloadCsvTarget("/"+activatedFilter)
      )
    }else{
      let filter = (activatedFilter==='product')?'プラン比較':activatedFilter;
      dispatch(
        updateDownloadCsvTarget("/"+filter+"/"+updateDownloadTabName)
      )
    }
  }

  return (
    <>
      {/* フィルターリスト */}
      <DataFilterList>
        {/* プラン比較フィルター */}
        <DataFilterItem
          onClick={() => handleFetchFilteredData("product")}
          className={activatedFilter === "product" ? "active" : ""}
        >
          プラン比較
        </DataFilterItem>

        {/* 期間比較フィルター */}
        <DataFilterItem
          className={activatedFilter === "期間比較" ? "active" : ""}
        >
          <DataFilterItemButton onClick={dateFilterItemButtonClicked}>
            期間比較
          </DataFilterItemButton>
        </DataFilterItem>

        {/* 変動フィルター */}
        {filters.map((filter: any, i: number) => (
          <DataFilterItem
            key={i}
            onClick={() => handleFetchFilteredData(filter.name)}
            className={activatedFilter === filter.name ? "active" : ""}
          >
            {filter.name}
          </DataFilterItem>
        ))}
        <GraphSwicherDownloadWrapper>
          {activatedFilter === "" && 
            <GraphSwicher />
          }
          <div onClick={handleGraphDataDownloadClick}>
            <GraphDataDownload />
          </div>
        </GraphSwicherDownloadWrapper>
      </DataFilterList>
    </>
  );
}

export default DataFilters;

// DataFilterList
const DataFilterList = styled.div`
  margin: 0;
  padding: 0 8px 0 8px;
  box-sizing: border-box;
  list-style: none;
  background: #ffffff;
  border: 1px solid #e1e8ea;
  border-bottom: none;
  min-height: 56px;
  height: auto;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
// DataFilterItem
const DataFilterItem = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: #566970;
  margin-right: 15px;
  padding: 8px 17px 7px 16px;
  border: 1px solid #edf0f1;
  border-radius: 2px;
  box-sizing: border-box;
  background-color: #fff;
  min-width: 96px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  &:hover {
    /* font-weight: bold;
    border: 1px solid #0087ae;
    background: rgba(0, 135, 174, 0.08); */
    opacity: 0.6;
  }
  &.active {
    font-weight: bold;
    border: 1px solid #0087ae;
    background: rgba(0, 135, 174, 0.08);
  }
`;

const DataFilterItemSelect = styled.select`
  padding: 7px 17px 7px 16px;
  cursor: pointer;
`;

const DataFilterItemButton = styled.button`
  //padding: 7px 17px 7px 16px;
  cursor: pointer;
`;

const GraphSwicherDownloadWrapper = styled.div({
  flexGrow: 1,
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
});
