import React from "react"
import { useSelector } from "react-redux"
import { PieChart, Pie, Cell, Tooltip } from "recharts"
import styled from "styled-components"

const FunnelDetailBreakdownPieChart = (props: any) => {
  const raw_data_array = useSelector((state: any) => state.raw_data.raw_data_array)
  const target_funnel_name = props.target_funnel_name;
  // Tooltipのカスタムスタイリング
  const customWrapperStyle = {
    background: "white",
    width: "216px",
    height: "60px",
    borderRadius: "2px",
    boxShadow: "0 4px 5px 0 rgba(0, 0, 0, .25)",
    padding: "8px",
    fontSize: "12px",
  };

  const customTooltip = (props:any) => {
    //グラフのpayloadを取得
    const parentArray = props.payload;
    // console.log("parentArray:", parentArray)
    console.log('parentArray',parentArray)

    if (parentArray != null) {

      //取得したpayload(親)の中のpayload(子)を取得
      const childPayload = parentArray.flatMap((childPayload: any) => childPayload.payload);

      //取得した値から[0]番目のみ取得
      const tooltipPayload = childPayload[0];
      console.log('tooltipPayload',tooltipPayload)
      if (tooltipPayload != null) {
        return (
          <TooltipContent>
            <FunnelName>{target_funnel_name}</FunnelName>
            <TooltipData>
              <TooltipLabel>{tooltipPayload.name}</TooltipLabel>
              <TooltipVal style={{color:tooltipPayload.fill}}>{tooltipPayload.total.toLocaleString()}</TooltipVal>
            </TooltipData>
          </TooltipContent>
        )
      }
    return;
    }
    return false;
  }

  const data = raw_data_array
    .flatMap((dailyData: any) => dailyData.lead_new_count && dailyData.lead_new_count !== undefined ? dailyData.lead_new_count : [])
    .filter((funnels: any) => funnels.funnel_name === target_funnel_name)
    .flatMap((funnel: any) => funnel.lead_new_count_sources)

  // console.log("data", data)

  const nameArray = data.flatMap((item: any) => item.lead_source_name).filter((x: any, i: any, self: any) => self.indexOf(x) === i)
  const finalNameArray = nameArray.map((data:any) => ({ name:data }))

  const leadCountTotalArray = nameArray.map((name: any) => {
    return data.filter((item: any) => item.lead_source_name === name).reduce((t: any, x: any) => t + x.lead_new_count_total, 0)
  })
  const finalLeadCountTotalArray = leadCountTotalArray.map((leadCountTotal: any, index: any) => ({ total: leadCountTotal }))

  const graphData = finalNameArray.map((data:any, i:number) => {
    data["total"] = finalLeadCountTotalArray[i]["total"]
    return data
  });


  const COLORS = ["#0066FF", "#40AF9B", "#EFBE40", "#EC6E8C"]

  const percentLabel = (props: any) => `${(props.percent * 100).toFixed(0)}%`

  return (
    <>
      {
        finalLeadCountTotalArray.length > 0 ? (
          <Container style={{ display: "flex", alignItems: "center" }} ref={props.breakoutPieAreaRef}>
            <PieChart width={400} height={320}>
              <Tooltip content={customTooltip} wrapperStyle={customWrapperStyle}/>
              <Pie
                data={graphData}
                dataKey="total"
                cx={200} //横方向の位置
                cy={"50%"} //縦方向の位置
                outerRadius={120} //グラフの外半径
                innerRadius={40} //グラフの内半径
                startAngle={90} //値の表示グラフ表示描写開始位置の調整
                endAngle={-270} //値の表示グラフ表示描写終了位置の調整
                paddingAngle={0} //項目間の隙間調整
                label={percentLabel}
                labelLine={true}
                fill="#435878"
                isAnimationActive={false}
              >
                {data.map((entry: any, index: any) => (
                  <Cell key={index} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart>
            <ul>
              {nameArray.map((name: any, i: any) => {
                return (
                  <li key={i} style={{ marginBottom: "8px", position: "relative" }}>
                    <NameSelect>
                      <span style={{ color: COLORS[i % COLORS.length], paddingRight: "8px" }}>●</span>
                      {name}
                    </NameSelect>
                  </li>
                )
              })}
            </ul>
          </Container>
        ):(
          <NoData>グラフを表示できません</NoData>
        )
      }
    </>
  )
}

export default FunnelDetailBreakdownPieChart

const NameSelect = styled.div`
  width: 344px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #edf0f1;
  box-sizing: border-box;
  border-radius: 2px;
  color: #435878;
  font-size: 12px;
  padding: 8px;
  position: relative;
`

const Container = styled.div`
  tspan {
    font-size: 10px;
  }
`

// Tooltip
const TooltipContent = styled.div`
  width: 100%;
  background-color: #fff;
`;

const FunnelName = styled.p`
  font-size: 10px;
  font-weight: bold;
  line-height: 15px;
  text-align: center;
  color: #435878;
  margin-bottom: 8px;
`;

const TooltipData = styled.dl`
  display: flex;
  justify-content: space-around;
  padding: 5px 15px;
  box-sizing: border-box;
`;
const TooltipLabel = styled.td`
  font-size: 12px;
  font-weight: normal;
  color: #435878;
  width: 80px;
  box-sizing: border-box;
  text-align: center;
`;
const TooltipVal = styled.td`
  font-size: 12px;
  font-weight: bold;
  color: #435878;
  margin-right: 16px;
  text-align: right;
  width: 80px;
  box-sizing: border-box;
`

const NoData = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #435878;
  margin-bottom: 8px;
  &.main {
    margin-bottom: 8px;
  }
`
