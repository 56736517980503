import React from 'react'
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { useSelector, useDispatch } from 'react-redux';
import { showLoading } from "../../reducks/loading/actions";
import { getRawDataThisMonthRequest, getRawDataLastMonthRequest, getRawDataLast6MonthRequest, getRawDataLast12MonthRequest, getRawDataCustomSpanRequest, getRawDataWithSpanAndTypeAndTimeRequest } from "../../reducks/rawData/actions";
import { useForm } from "react-hook-form";
import dateArrow from "../../images/icons/arrow_down.svg";

export default function DefaultSpanSelectorSection() {
  const dispatch = useDispatch();
  const company_id = useSelector((state: any) => state.user.company_id);
  const filter_name = useSelector((state: any) => state.filter);
  const target_data_name = useSelector((state: any) => state.target_data.target_data_name);

  // データの範囲：this_month | last_month | last_6_months | last_12_months | custom
  const selected_data_config_data_span = useSelector((state: any) => state.raw_data.selected_data_config.data_span);

  // データの種類：月次・週次・日次
  const selected_data_config_data_type = useSelector((state: any) => state.raw_data.selected_data_config.data_type);

  // データの範囲が「カスタム」指定だった場合の開始と終了時点
  const selected_data_custom_span_start_time = useSelector((state: any) => state.raw_data.selected_data_config.custom_span_start_time);
  const selected_data_custom_span_end_time = useSelector((state: any) => state.raw_data.selected_data_config.custom_span_end_time);


  // URLを取得
  const location = useLocation();
  const pathname = location.pathname

  const { register } = useForm();

  // 期間変更によるrawData取得
  const handleSpanSelect = (e: any) => {
    console.log('e.target.value', e.target.value);
    switch (e.target.value) {
      case "this_month":

        // dispatch(getRawDataThisMonthRequest(company_id, selected_data_config_data_type, pathname));
        if(filter_name !== '') {
          dispatch(showLoading());
          dispatch(getRawDataThisMonthRequest(company_id, selected_data_config_data_type, pathname, filter_name, 1, target_data_name));
        } else {
          dispatch(showLoading());
          dispatch(getRawDataThisMonthRequest(company_id, selected_data_config_data_type, pathname, filter_name, 1));
        }
        break;

      case "last_month":
        // dispatch(getRawDataLastMonthRequest(company_id, selected_data_config_data_type, pathname));
        if(filter_name !== '') {
          dispatch(showLoading());
          dispatch(getRawDataLastMonthRequest(company_id, selected_data_config_data_type, pathname, filter_name, 3, target_data_name));
        } else {
          dispatch(showLoading());
          dispatch(getRawDataLastMonthRequest(company_id, selected_data_config_data_type, pathname, filter_name, 3));
        }
        break;

      case "last_6_months":
        if(filter_name !== '') {
          dispatch(showLoading());
          dispatch(getRawDataLast6MonthRequest(company_id, selected_data_config_data_type, pathname, filter_name, 6, target_data_name));
        } else {
          dispatch(showLoading());
          dispatch(getRawDataLast6MonthRequest(company_id, selected_data_config_data_type, pathname, filter_name, 6));
        }
        break;

      case "last_12_months":
        // dispatch(getRawDataLast12MonthRequest(company_id, selected_data_config_data_type, pathname));
        if(filter_name !== '') {
          dispatch(showLoading());
          dispatch(getRawDataLast12MonthRequest(company_id, selected_data_config_data_type, pathname, filter_name, 12, target_data_name));
        } else {
          dispatch(showLoading());
          dispatch(getRawDataLast12MonthRequest(company_id, selected_data_config_data_type, pathname, filter_name, 12));
        }
        break;

      case "custom":
        if(filter_name !== '') {
          dispatch(showLoading());
          dispatch(getRawDataCustomSpanRequest(company_id, selected_data_config_data_type, selected_data_custom_span_start_time, selected_data_custom_span_end_time, pathname, filter_name, target_data_name));
        } else {
          dispatch(showLoading());
          dispatch(getRawDataCustomSpanRequest(company_id, selected_data_config_data_type, selected_data_custom_span_start_time, selected_data_custom_span_end_time, pathname, filter_name));
        }

        break;

      default:
        break;
    }
  };

  return (
    <SpanSelectorSC>
      <SpanSelectorFormSC>
        <SpanSelectorFormSelectSC value={selected_data_config_data_span} name="span" ref={register} onChange={handleSpanSelect}>
          <option value="this_month">当月</option>
          <option value="last_month">前月</option>
          <option value="last_6_months">過去6ヶ月</option>
          <option value="last_12_months">過去12ヶ月</option>
          <option value="custom">カスタム</option>
        </SpanSelectorFormSelectSC>
      </SpanSelectorFormSC>
    </SpanSelectorSC>
  )
}

// 対処の期間
const SpanSelectorSC = styled.div`
  margin-right: 0;
  margin-left: 20px;
  background-color: #fff;
  position: relative;
  .dateArrow {
    width: 25px;
    height: auto;
    display: block;
    position: absolute;
    top: 50%;
    right: 18px;
    transform: translateY(-50%);
    z-index: 10;
  }
`;
const SpanSelectorFormSC = styled.form`
  border: none;
`;
const SpanSelectorFormSelectSC = styled.select`
  border: 1px solid #e2e9f3;
  border-radius: 2px;
  width: 113px;
  height: 32px;
  text-align: center;
  font-size: 12px;
  color: #435878;
  padding: 0 10px 0 20px;
  box-sizing: border-box;
  background-image: url(${dateArrow});
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 24px 24px;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`;