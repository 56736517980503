import rsf from "../../rsf";
import { call } from "redux-saga/effects";
import firebase from "firebase";

const db = firebase.firestore();

// 過去6ヶ月分データ取得
function* getRawDataLast6Month(company_id, selected_data_config_data_type) {
  try {
    // 当月
    const thisMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const month = thisMonth.getMonth();

    //前月
    const lastMonth = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 1));

    // 6ヶ月前
    const sixMonthAgo = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 6));

    if (selected_data_config_data_type === "monthly") {
      // データ取得
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${company_id}/result_monthly`)
          .where("result_at", ">=", sixMonthAgo)
          .where("result_at", "<=", lastMonth)
        );

      // 取得後データを整形
      let raw_data_array = [];
      snapshot.forEach(result_monthly => {
        raw_data_array = [...raw_data_array, { ...result_monthly.data(), docId: result_monthly.id }];
      });

      return raw_data_array;
    } else if (selected_data_config_data_type === "weekly") {
      // データ取得
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${company_id}/result_weekly`)
          .where("result_at", ">=", sixMonthAgo)
          .where("result_at", "<=", lastMonth)
        );

      // 取得後データを整形
      let raw_data_array = [];
      snapshot.forEach(result_monthly => {
        raw_data_array = [...raw_data_array, { ...result_monthly.data(), docId: result_monthly.id }];
      });

      return raw_data_array;
    } else {
      // データ取得
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${company_id}/result_daily`)
          .where("result_at", ">=", sixMonthAgo)
          .where("result_at", "<=", lastMonth)
        );

      // 取得後データを整形
      let raw_data_array = [];
      snapshot.forEach(result_monthly => {
        raw_data_array = [...raw_data_array, { ...result_monthly.data(), docId: result_monthly.id }];
      });

      return raw_data_array;
    }
  } catch (e) {
    console.log("error message", e.message);
  }
}


export default getRawDataLast6Month;