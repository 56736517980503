const GET_RAW_DATA_REQUESTED = "GET_RAW_DATA_REQUESTED";
const GET_RAW_DATA_SUCCEEDED = "GET_RAW_DATA_SUCCEEDED";
const GET_RAW_DATA_FAILED = "GET_RAW_DATA_FAILED";

const GET_RAW_DATA_THIS_MONTH_REQUEST = "GET_RAW_DATA_THIS_MONTH_REQUEST";
const GET_RAW_DATA_THIS_MONTH_SUCCEEDED = "GET_RAW_DATA_THIS_MONTH_SUCCEEDED";
const GET_RAW_DATA_THIS_MONTH_FAILED = "GET_RAW_DATA_THIS_MONTH_FAILED";

const GET_RAW_DATA_LAST_MONTH_REQUEST = "GET_RAW_DATA_LAST_MONTH_REQUEST";
const GET_RAW_DATA_LAST_MONTH_SUCCEEDED = "GET_RAW_DATA_LAST_MONTH_SUCCEEDED";
const GET_RAW_DATA_LAST_MONTH_FAILED = "GET_RAW_DATA_LAST_MONTH_FAILED";

const GET_RAW_DATA_LAST_6_MONTH_REQUEST = "GET_RAW_DATA_LAST_6_MONTH_REQUEST";
const GET_RAW_DATA_LAST_6_MONTH_SUCCEEDED = "GET_RAW_DATA_LAST_6_MONTH_SUCCEEDED";
const GET_RAW_DATA_LAST_6_MONTH_FAILED = "GET_RAW_DATA_LAST_6_MONTH_FAILED";

const GET_RAW_DATA_LAST_12_MONTH_REQUEST = "GET_RAW_DATA_LAST_12_MONTH_REQUEST";
const GET_RAW_DATA_LAST_12_MONTH_SUCCEEDED = "GET_RAW_DATA_LAST_12_MONTH_SUCCEEDED";
const GET_RAW_DATA_LAST_12_MONTH_FAILED = "GET_RAW_DATA_LAST_12_MONTH_FAILED";

const GET_RAW_DATA_CUSTOM_SPAN_REQUEST = "GET_RAW_DATA_CUSTOM_SPAN_REQUEST";
const GET_RAW_DATA_CUSTOM_SPAN_SUCCEEDED = "GET_RAW_DATA_CUSTOM_SPAN_SUCCEEDED";
const GET_RAW_DATA_CUSTOM_SPAN_FAILED = "GET_RAW_DATA_CUSTOM_SPAN_FAILED";

const GET_RAW_DATA_WITH_SPAN_AND_TYPE_AND_TIME_REQUEST = "GET_RAW_DATA_WITH_SPAN_AND_TYPE_AND_TIME_REQUEST";
const GET_RAW_DATA_WITH_SPAN_AND_TYPE_AND_TIME_SUCCEEDED = "GET_RAW_DATA_WITH_SPAN_AND_TYPE_AND_TIME_SUCCEEDED";
const GET_RAW_DATA_WITH_SPAN_AND_TYPE_AND_TIME_FAILED = "GET_RAW_DATA_WITH_SPAN_AND_TYPE_AND_TIME_FAILED";

const GET_RAW_DATA_FOR_FILTER_REQUEST = "GET_RAW_DATA_FOR_FILTER_REQUEST";
const GET_RAW_DATA_FOR_FILTER_SUCCEEDED = "GET_RAW_DATA_FOR_FILTER_SUCCEEDED";
const GET_RAW_DATA_FOR_FILTER_FAILED = "GET_RAW_DATA_FOR_FILTER_FAILED";

const SET_TARGET_LINE_OPACITY = "SET_TARGET_LINE_OPACITY";

const LOGOUT_REQUESTED = "LOGOUT_REQUESTED";

export { GET_RAW_DATA_REQUESTED, GET_RAW_DATA_SUCCEEDED, GET_RAW_DATA_FAILED, GET_RAW_DATA_THIS_MONTH_REQUEST, GET_RAW_DATA_THIS_MONTH_SUCCEEDED, GET_RAW_DATA_THIS_MONTH_FAILED, GET_RAW_DATA_LAST_MONTH_REQUEST, GET_RAW_DATA_LAST_MONTH_SUCCEEDED, GET_RAW_DATA_LAST_MONTH_FAILED, GET_RAW_DATA_LAST_6_MONTH_REQUEST, GET_RAW_DATA_LAST_6_MONTH_SUCCEEDED, GET_RAW_DATA_LAST_6_MONTH_FAILED, GET_RAW_DATA_LAST_12_MONTH_REQUEST, GET_RAW_DATA_LAST_12_MONTH_SUCCEEDED, GET_RAW_DATA_LAST_12_MONTH_FAILED, GET_RAW_DATA_CUSTOM_SPAN_REQUEST, GET_RAW_DATA_CUSTOM_SPAN_SUCCEEDED, GET_RAW_DATA_CUSTOM_SPAN_FAILED, GET_RAW_DATA_WITH_SPAN_AND_TYPE_AND_TIME_REQUEST, GET_RAW_DATA_WITH_SPAN_AND_TYPE_AND_TIME_SUCCEEDED, GET_RAW_DATA_WITH_SPAN_AND_TYPE_AND_TIME_FAILED, GET_RAW_DATA_FOR_FILTER_REQUEST, GET_RAW_DATA_FOR_FILTER_SUCCEEDED, GET_RAW_DATA_FOR_FILTER_FAILED, SET_TARGET_LINE_OPACITY, LOGOUT_REQUESTED };
