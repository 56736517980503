import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux"
import styled from 'styled-components';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
// import { getResultsRequestForNewForecast, setForecastFilterStatus, setForecastFilter, resetForecastFilter } from '../../../reducks/newForecast/actions';
import { getResultsForTargetForecastRequest, getResultsForTargetForecastCustomRequest,setForecastFilterStatus, setForecastFilter, resetForecastFilter, removeFutureDataFromIntegratedData } from '../../../reducks/targetForecast/actions';
import { updateCompanyFromToRequest, setCompanyFromTo } from '../../../reducks/company/actions';
import {
  updateDownloadCsvTarget,
} from "../../../reducks/download/actions";
import { setFilter } from "../../../reducks/filter/actions";
import ForecastDateSpanSelectorStart from "./ForecastDateSpanSelectorStart";
import ForecastDateSpanSelectorEnd from "./ForecastDateSpanSelectorEnd";
import GraphSwitcher from "../../Parts/GraphSwitcher";
import GraphDataDownload from "../../Parts/GraphDataDownload";


export default function ForecastPLDataFilterList() {
  const dispatch = useDispatch();
  const activatedFilter = useSelector((state: any) => state.filter)
  const selected_data_config_data_type = useSelector((state: any) => state.raw_data.selected_data_config.data_type);
  const selected_data_config_data_span = useSelector((state: any) => state.raw_data.selected_data_config.data_span);
  const custom_span_start_time = useSelector((state: any) => state.raw_data.selected_data_config.custom_span_start_time);
  const custom_span_end_time = useSelector((state: any) => state.raw_data.selected_data_config.custom_span_end_time);
  const company_id = useSelector((state: any) => state.user.company_id);

  const forecast_filter_status = useSelector((state: any) => state.target_forecast.forecast_filter_status);
  const forecast_custom_span_start_time = useSelector((state: any) => state.target_forecast.selected_data_config.custom_span_start_time)
  const forecast_custom_span_end_time = useSelector((state: any) => state.target_forecast.selected_data_config.custom_span_end_time)
  const integrated_array = useSelector((state: any) => state.target_forecast.integrated_array)


  const dateFilterItemButtonClicked = (e: any) => {
    dispatch(setFilter('期間比較'));
    // dispatch(resetForecastFilter(forecastFilter));

    // dispatch(getResultsRequestForSpanComparisonForecasts(resultSpan, company_id, firstCalenderStartDate, firstCalenderEndDate, 'firstSpan'));
    // dispatch(getResultsRequestForSpanComparisonForecasts(resultSpan, company_id, secondCalenderStartDate, secondCalenderEndDate, 'secondSpan'));
  };

  // 【未来予測　PL】
  const handleForecastTypeSwitch = (e: any) => {
    dispatch(setForecastFilterStatus(e.target.value))

    if (e.target.value === 'reset_forecast') {
      dispatch(removeFutureDataFromIntegratedData(integrated_array));
    } else if(e.target.value !== 'custom') {
      dispatch(
        getResultsForTargetForecastRequest(
          company_id,
          e.target.value,
          selected_data_config_data_span,
          selected_data_config_data_type,
          custom_span_start_time,
          custom_span_end_time,
          forecast_custom_span_start_time,
          forecast_custom_span_end_time
        )
      );
    } else {
      console.log('set forecastFilterItemOption', e.target.value);
      // set_span_select_modal_is_shown(true);
      // set_current_forecast_modal('PL');
      // dispatch(setForecastFilterStatus('custom'));
      // dispatch(setForecastFilter(forecastFilter));
    }
  };

  function handleGraphDataDownloadClick(e:any) {
    dispatch(
      updateDownloadCsvTarget("")
    )
  }

  return (
    <DataFilterList>
      {
        forecast_filter_status === "reset_forecast" &&
        <DataFilterItem className={activatedFilter === "期間比較" ? "active" : ""}>
          <DataFilterItemButton onClick={dateFilterItemButtonClicked}>期間比較</DataFilterItemButton>
        </DataFilterItem>
      }

      {
        selected_data_config_data_type === "monthly" && (
          <>
          <DataFilterItem>
            <DataFilterItemSelect name="forecast_type" value={forecast_filter_status} onChange={handleForecastTypeSwitch}>
              <option value="reset_forecast">予測基準</option>
              <option value="12">
                12ヶ月
              </option>
              <option value="24">24ヶ月</option>
              <option value="36">36ヶ月</option>
              <option value="custom">指定期間</option>
            </DataFilterItemSelect>
          </DataFilterItem>

          {forecast_filter_status === "custom" && (
            <Selector>
              <ForecastDateSpanSelectorStart />
              <Span>-</Span>
              <ForecastDateSpanSelectorEnd />
            </Selector>
          )}
        </>
        )
      }
      <GraphSwitcher />
      <div onClick={handleGraphDataDownloadClick}>
        <GraphDataDownload />
      </div>

    </DataFilterList>
  );
}


// DataFilterList
const DataFilterList = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0;
  padding: 8px 8px 9px 8px;
  border-bottom: 1px solid #e1e8ea;
  box-sizing: border-box;
  list-style: none;
  overflow: scroll;
`;
// DataFilterItem
const DataFilterItem = styled.div`
  position: relative;
  font-size: 12px;
  line-height: 18px;
  color: #566970;
  margin-right: 15px;
  border: 1px solid #edf0f1;
  border-radius: 2px;
  box-sizing: border-box;
  background-color: #fff;
  cursor: pointer;
  &:hover {
    font-weight: bold;
    border: 1px solid #0087ae;
    background: rgba(0, 135, 174, 0.08);
  }

  &.active {
    font-weight: bold;
    border: 1px solid #0087ae;
    background: rgba(0, 135, 174, 0.08);
  }
`;

const DataFilterItemSelect = styled.select`
  padding: 7px 25px 7px 20px;
  cursor: pointer;
`;

const DataFilterItemButton = styled.button`
  padding: 7px 17px 7px 16px;
  cursor: pointer;
`;
const Selector = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin-left: 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #7591bc;

  .makeStyles-container-9 {
    margin-top: 10px;
  }

  .MuiInput-underline {
    &::before,
    &::after {
      content: none;
    }
  }
  .MuiFormControl-marginNormal,
  .makeStyles-container-9 {
    margin: 0;
  }
  .MuiInputBase-input {
    letter-spacing: 1px;
  }
  .MuiSvgIcon-root {
    fill: #c4c4c4;
  }
`;
const Span = styled.span`
  padding: 0 20px 0 10px;
`;