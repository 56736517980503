import rsf from "../../rsf"
import firebase from "firebase"
import { call } from "redux-saga/effects"
const db = firebase.firestore()


function* fetchLayout(company_id) {
  try {
    const snapshot = yield call(rsf.firestore.getDocument, `companies/${company_id}`)
    const company = snapshot.data()
    const gridLayout = company.gridLayout
    return gridLayout
  } catch (e) {
    console.log("Company fetch failed", e.message)
  }
}
export default fetchLayout;