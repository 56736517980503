import React, { Component } from "react";
import styled from 'styled-components';
import { ResponsiveContainer, ComposedChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import revenueIcon from '../../../images/icons/graph_icon_revenue.svg';
import profitIcon from '../../../images/icons/graph_icon_profit.svg';
import { dateFormatter } from "../../Util/DateFormatter";
import { yAxisFormatter } from "../../Util/yAxisFormatter";
import ForecastSaleCostGraphDefaultCustomLegend from './ForecastSaleCostGraphDefaultCustomLegend'

interface Props {
  opacity_object: any;
  saleCostGraphData: any;
  resultSpan: any;
  areaRef: any;
}

export default class ForecastSaleCostGraphDefault extends Component<Props> {

  tooltip: HTMLElement | null;

  constructor(props: any) {
    super(props);
    this.tooltip = null;
  }

  showToolTip = (e: any) => {
    if (this.tooltip !== null) {
      this.tooltip.style.left = `${(e.cx + 50).toString()}px`;
      this.tooltip.style.top = `${(e.cy - 80).toString()}px`;
      // 最後のデータだった場合は、dotの左隣に出力
      if (this.props.saleCostGraphData.length - 1 === e.index) {
        this.tooltip.style.left = `${(e.cx - 170).toString()}px`;
      }
      this.tooltip.style.opacity = "1";
      const tooltipDate = this.tooltip.childNodes[0] as HTMLElement
      const tooltipDataName = this.tooltip.childNodes[1] as HTMLElement
      const tooltipDataKey = this.tooltip.childNodes[2].childNodes[0] as HTMLElement
      const tooltipDataValue = this.tooltip.childNodes[2].childNodes[1] as HTMLElement

      //ホバーしたラインのデータキーによって、日付を出し分け
      tooltipDate.innerHTML = new Date(e.payload.result_at.seconds * 1000).getFullYear() + "/" + (new Date(e.payload.result_at.seconds * 1000).getMonth() + 1)

      //キー名出し分け
      if (e.dataKey.includes("cost_sales_marketing_cost_revenue_rate")) {
        tooltipDataName.innerHTML = "Sales & Marketing比率"
      } else if (e.dataKey.includes("cost_research_development_cost_revenue_rate")) {
        tooltipDataName.innerHTML = "Research & Development比率"
      } else if (e.dataKey.includes("cost_general_administrative_cost_revenue_rate")) {
        tooltipDataName.innerHTML = "General & Administrative比率"
      } else {
        tooltipDataName.innerHTML = ""
      }
      tooltipDataKey.innerHTML = "比率";

      if(e.value === null || e.value === undefined) {
        tooltipDataValue.innerHTML = "<span>NoData</span>"
      } else {
        tooltipDataValue.innerHTML = (Math.round(e.value*100 * 100) / 100).toFixed(2)+' %';
      }
      this.tooltip.style.zIndex = "99999";
    } else {
      return false;
    }
  };

  hideTooltip = (e: any) => {
    if (this.tooltip !== null) {
      this.tooltip.style.opacity = "0";
    } else {
      return false;
    }
  };

  render() {

    const dataArray = [{
      name : "Sales & Marketing比率",
      dataKey:"cost_sales_marketing_cost_revenue_rate",
      yAxisId: 0,
      stroke : "#E53E66",
      strokeOpacity : this.props.opacity_object.cost_sales_marketing_cost_revenue_rate,
    },{
      name : "Research & Development比率",
      dataKey:"cost_research_development_cost_revenue_rate",
      yAxisId: 0,
      stroke : "#F8B200",
      strokeOpacity : this.props.opacity_object.cost_research_development_cost_revenue_rate,
    },{
      name : "General & Administrative比率",
      dataKey:"cost_general_administrative_cost_revenue_rate",
      yAxisId: 0,
      stroke : "#B7D20D",
      strokeOpacity : this.props.opacity_object.cost_general_administrative_cost_revenue_rate,
    }]

    return (
      <>
        {
          this.props.saleCostGraphData.length > 0 ? (
            <Graph>
              <ResponsiveContainer>
                <ComposedChart 
                  width={500} 
                  height={400} 
                  data={this.props.saleCostGraphData} 
                  margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                  ref={this.props.areaRef}
                >
                  <CartesianGrid vertical={false} stroke="#f5f5f5" />
                  <XAxis 
                    scale={"point"}
                    dataKey={"result_at.seconds"} 
                    tickLine={false}
                    tickFormatter={(tick) => dateFormatter(this.props.resultSpan, (tick * 1000))}
                    axisLine={{ stroke: '#f5f5f5' }}
                  />
                  <YAxis yAxisId={0} interval={0} tickLine={false} axisLine={false} tickMargin={10} tickFormatter={tick => yAxisFormatter(tick, 'sale_cost_ratio')} />

                  <Tooltip wrapperStyle={{ display: "none" }} />
                  <Legend content={ForecastSaleCostGraphDefaultCustomLegend} iconSize={20} verticalAlign="top" align="left" margin={{ bottom: 5 }} />

                  {dataArray.map((val ,i) => (
                    <Line
                      type="monotone"
                      key={i}
                      {...val}
                      dot={false}
                      strokeWidth="3"
                      activeDot={{
                        onMouseEnter: this.showToolTip,
                        onMouseLeave: this.hideTooltip
                      }}
                    />
                  ))}

                </ComposedChart>
              </ResponsiveContainer>
              <UiChartTooltip className="ui-chart-tooltip" ref={ref => (this.tooltip = ref)}>
                <div className="tooltip-date"></div>
                <div className="tooltip-data-name"></div>
                <div className="toltip-databox">
                  <div className="tooltip-data-key"></div>
                  <div className="tooltip-data-value"></div>
                </div>
              </UiChartTooltip>
            </Graph>
          ):(
            <NoData>グラフを表示できません</NoData>
          )
        }
      </>
    );
  }
}


// Graph
const Graph = styled.div`
  width: 100%;
  /* height: 272px; */
  height: 500px;
  margin-bottom: 18px;
  padding: 0 10px;
  box-sizing: border-box;
  position: relative;
  z-index: 100;

  .recharts-legend-wrapper {
    top: 5px !important;
    left: 10px !important;
  }
  .recharts-legend-item-text {
    font-size: 12px;
    color: #283f46;
  }
  .recharts-legend-item {
    position: relative;
    svg {
      display: none !important;
    }
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      transform: translateY(-50%);
    }
    &.legend-item-0,
    &.legend-item-2 {
      padding-left: 15px;
      &::before {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        top: 54%;
      }
    }
    &.legend-item-1,
    &.legend-item-3 {
      padding-left: 24px;
      &::before {
        width: 19px;
        height: 14px;
        top: 50%;
      }
    }
    &.legend-item-0 {
      &::before {
        background-color: #0066FF;
      }
    }
    &.legend-item-1 {
      &::before {
        background-image: url(${revenueIcon});
      }
    }
    &.legend-item-2 {
      &::before {
        background-color: #ec6e6e;
      }
    }
    &.legend-item-3 {
      &::before {
        background-image: url(${profitIcon});
      }
    }
  }
  .recharts-surface {
    overflow: visible;
  }
  .recharts-cartesian-axis-tick {
    .recharts-cartesian-axis-tick-value {
      font-size: 10px;
      color: #435878;
    }
  }
`;

const NoData = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #435878;
  margin-bottom: 8px;
  &.main {
    margin-bottom: 8px;
  }
`

const UiChartTooltip = styled.div`
  opacity: 0;
  position: absolute;
  z-index: -1;
  min-width: 178px;
  width: auto;
  min-height: 48px;
  height: auto;
  background: white;
  border: 1px solid #e2e9f3;
  border-radius: 2px;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.25);
  padding: 8px 9px;
  .tooltip-date {
    font-size: 13px;
    font-weight: bold;
    color: #435878;
    margin-bottom: 10px;
  }
  .tooltip-data-name {
    font-size: 12px;
    font-weight: bold;
    color: #7591BC;
    margin-bottom: 8px;
  }
  .toltip-databox {
    display: flex;
    justify-content: space-between;
  }
  .tooltip-data-key {
    font-size: 10px;
    color: #435878;
    width: 50%;
    word-break: break-all;
  }
  .tooltip-data-value {
    font-size: 12px;
    font-weight: bold;
    color: #435878;
    span {
      color: #AF002A;
    }
  }
`;