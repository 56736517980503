import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import GlobalNav from "../Layout/GlobalNav";
import PageHeader from "../Layout/PageHeader";
import error from "../../images/icons/error.svg";
import { Link, useHistory } from "react-router-dom";

const ErrorPageCushion = () => {
  const history = useHistory();
  //このページに来たらすぐに”〜〜/error”に遷移する（TOPページで死んだ場合、「Dashbordに戻る」のリンクが使えないため。）
  useEffect(() => {
    history.push("/error");
  }, [history]);

  return (
    <Container>
      <GlobalNav />
    </Container>
  );
};

export default ErrorPageCushion;

// ==============================================================================
// Styles
// ==============================================================================
const Main = styled.main`
  overflow: hidden;
  flex-grow: 1;
  background-color: #f4f4f4;
  height: 100vh;
  overflow: scroll;
  border-top: 8px solid #0050c9;
`;

// Container
const Container = styled.div`
  display: flex;
  min-width: 1200px;
`;

const ErrorContainer = styled.div`
  color: #35455d;
  margin: 0 auto;
  padding: 30px;
`;

const ErrorImage = styled.div`
  height: 100px;
  width: 100px;
  background-image: url(${error});
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 auto;
`;

const ErrorHeaderSC = styled.h1`
  font-size: 24px;
  text-align: center;
  padding: 24px 0px;
`;

const ErrorDescSC = styled.p`
  font-size: 18px;
  line-height: 36px;
  text-align: center;
  margin-bottom: 24px;
`;

const BackToTop = styled.div`
  color: #0066ff;
  text-align: center;
  &:hover {
    opacity: 0.6;
  }
`;
