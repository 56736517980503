import rsf from "../../rsf"

import firebase from "firebase"

const db = firebase.firestore()



// 詳細データの取得
const targetCollectionGroupPast = (collection_name, company_id, selected_data_config_data_type, forecast_base_span)=> {

  // 当月
  const thisMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  const month = thisMonth.getMonth();

  // 未来予測基準月数
  const forecastBaseMonth = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - (forecast_base_span - 1)));

  return db
    .collectionGroup(collection_name)
    .where('company_id', '==', company_id)
    .where('result_at', '>=', forecastBaseMonth)
    .where('result_at', '<=', thisMonth)
    .where('resultSpan', '==', selected_data_config_data_type)
    .get();
};

export default targetCollectionGroupPast;