import rsf from "../../rsf"
import firebase from "firebase"
import { call } from "redux-saga/effects"
const db = firebase.firestore()


function* fetchCostMasterList(company_id) {
  try {
    const snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${company_id}/cost_master`))
    let cost_master_list = []

    snapshot.forEach((cost_master) => {
      cost_master_list = [...cost_master_list, { ...cost_master.data(), docId: cost_master.id }]
    })
    return cost_master_list
  } catch (e) {
    console.log("error", e.message)
  }
}

export default fetchCostMasterList;