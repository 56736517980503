import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import "date-fns";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import format from "date-fns/format";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker
} from "@material-ui/pickers";

import { updateCompanyFromToRequest } from "../../reducks/company/actions";
import jaLocale from "date-fns/locale/ja";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap"
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200
    }
  })
);

class ExtendedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date: any) {
    return format(date, "MMM", { locale: this.locale });
  }
}

const DateSpanSelectorStart = (props: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const company_id = useSelector((state: any) => state.user.company_id);

  const selected_data_config_data_type = useSelector(
    (state: any) => state.raw_data.selected_data_config.data_type
  );
  const selected_data_custom_span_start_time = useSelector(
    (state: any) => state.raw_data.selected_data_config.custom_span_start_time
  );
  const selected_data_custom_span_end_time = useSelector(
    (state: any) => state.raw_data.selected_data_config.custom_span_end_time
  );

  const filter_name = useSelector((state: any) => state.filter);
  const target_data_name = useSelector(
    (state: any) => state.target_data.target_data_name
  );

  // カスタム期間変更によるデータ取得
  const handleDateChange = (date: Date | null) => {
    if (selected_data_custom_span_end_time !== "" && date != null) {
      if (date <= new Date(selected_data_custom_span_end_time.seconds * 1000)) {
        // 予測フィルターがONの場合
        // if (forecastFilter) {
        //   dispatch(updateCompanyFromToRequest(resultSpan, company_id, date, new Date(selected_data_custom_span_end_time.seconds * 1000)))
        //   dispatch(getResultsRequestForForecast(resultSpan, company_id, date, new Date(monthly_from_month.seconds * 1000)))
        // } else { // 通常時
        // }
        console.log(
          "selected_data_config_data_type",
          selected_data_config_data_type
        );
        if (filter_name !== "") {
          dispatch(
            updateCompanyFromToRequest(
              company_id,
              date,
              new Date(selected_data_custom_span_end_time.seconds * 1000),
              selected_data_config_data_type,
              filter_name,
              target_data_name
            )
          );
        } else {
          dispatch(
            updateCompanyFromToRequest(
              company_id,
              date,
              new Date(selected_data_custom_span_end_time.seconds * 1000),
              selected_data_config_data_type,
              filter_name
            )
          );
        }
        // if (forecast_filter_status !== 'custom') {
        //   dispatch(
        //     getResultsRequestForNewForecast(
        //       company_id,
        //       forecast_base_span,
        //       "custom",
        //       date,
        //       custom_span_end_time,
        //     )
        //   );
        // } else {
        //   dispatch(
        //     getResultsRequestForNewForecastForCustom(
        //       company_id,
        //       // 未来予測カスタム期間の差異の月数
        //       new Date(forecast_custom_span_end_time * 1000).getFullYear() * 12 + new Date(forecast_custom_span_end_time * 1000).getMonth() - new Date(forecast_custom_span_start_time * 1000).getFullYear() * 12 + new Date(forecast_custom_span_start_time * 1000).getMonth(),

        //       "custom",

        //       date,
        //       custom_span_end_time,

        //       forecast_custom_span_start_time,
        //       forecast_custom_span_end_time,
        //     )
        //   );
        // }
      } else {
        alert("終了日より前の日付を選択してください");
        return false;
      }
    }
  };

  // ===================================================== //
  //　週次表示時に特定の曜日を非表示にする
  const weekStartDay = useSelector(
    (state: any) => state.company.config.weekly_start_day
  );
  // const weekStartDay = 'Mon'

  let disableWeekDays;

  if (weekStartDay === "Mon") {
    disableWeekDays = (date: any) => {
      //1以外
      if (date < new Date()) {
        return (
          date.getDay() === 0 ||
          date.getDay() === 2 ||
          date.getDay() === 3 ||
          date.getDay() === 4 ||
          date.getDay() === 5 ||
          date.getDay() === 6
        );
      } else {
        return false;
      }
    };
  } else if (weekStartDay === "Tue") {
    disableWeekDays = (date: any) => {
      //2以外
      return (
        date.getDay() === 0 ||
        date.getDay() === 1 ||
        date.getDay() === 3 ||
        date.getDay() === 4 ||
        date.getDay() === 5 ||
        date.getDay() === 6
      );
    };
  } else if (weekStartDay === "Wed") {
    disableWeekDays = (date: any) => {
      //3以外
      return (
        date.getDay() === 0 ||
        date.getDay() === 1 ||
        date.getDay() === 2 ||
        date.getDay() === 4 ||
        date.getDay() === 5 ||
        date.getDay() === 6
      );
    };
  } else if (weekStartDay === "Thu") {
    disableWeekDays = (date: any) => {
      //4以外
      return (
        date.getDay() === 0 ||
        date.getDay() === 1 ||
        date.getDay() === 2 ||
        date.getDay() === 3 ||
        date.getDay() === 5 ||
        date.getDay() === 6
      );
    };
  } else if (weekStartDay === "Fri") {
    disableWeekDays = (date: any) => {
      //5以外
      return (
        date.getDay() === 0 ||
        date.getDay() === 1 ||
        date.getDay() === 2 ||
        date.getDay() === 3 ||
        date.getDay() === 4 ||
        date.getDay() === 6
      );
    };
  } else if (weekStartDay === "Sat") {
    disableWeekDays = (date: any) => {
      //6以外
      return (
        date.getDay() === 0 ||
        date.getDay() === 1 ||
        date.getDay() === 2 ||
        date.getDay() === 3 ||
        date.getDay() === 4 ||
        date.getDay() === 5
      );
    };
  } else if (weekStartDay === "Sun") {
    disableWeekDays = (date: any) => {
      //0以外
      return (
        date.getDay() === 1 ||
        date.getDay() === 2 ||
        date.getDay() === 3 ||
        date.getDay() === 4 ||
        date.getDay() === 5 ||
        date.getDay() === 6
      );
    };
  }
  //　週次表示時に特定の曜日を非表示にする
  // ===================================================== //

  return (
    <>
      <form
        style={{
          width: selected_data_config_data_type === "monthly" ? "100px" : ""
        }}
        className={classes.container}
        noValidate
      >
        {selected_data_config_data_type === "daily" && (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="yyyy/MM/dd(E)"
                margin="normal"
                id="date-picker-inline"
                value={
                  new Date(selected_data_custom_span_start_time.seconds * 1000)
                }
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
                disableFuture={false}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        )}

        {selected_data_config_data_type === "weekly" && (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="yyyy/MM/dd(E)"
                margin="normal"
                id="date-picker-inline"
                value={
                  new Date(selected_data_custom_span_start_time.seconds * 1000)
                }
                onChange={handleDateChange}
                shouldDisableDate={disableWeekDays}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
                disableFuture={false}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        )}

        {selected_data_config_data_type === "monthly" && (
          <MuiPickersUtilsProvider utils={ExtendedUtils} locale={jaLocale}>
            <Grid container justify="space-around">
              <DatePicker
                views={["month"]}
                variant="inline"
                format="yyyy/MM"
                margin="normal"
                id="date-picker-inline"
                value={
                  new Date(selected_data_custom_span_start_time.seconds * 1000)
                }
                onChange={handleDateChange}
                autoOk={true}
                disableFuture={false}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        )}

        {/* 月次 日付・曜日なし */}
      </form>
    </>
  );
};

export default DateSpanSelectorStart;
