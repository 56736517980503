import {
  LOGIN_REQUESTED,
  LOGOUT_REQUESTED,
  LOGIN_FAILED_RESET,
  SET_PAGE_PATH,
  TOGGLE_MOVING_AVERAGE_GRAPH_SWITCH,
  TOGGLE_THIS_TIME_GRAPH_SWITCH,
  URL_TO_DATA_NAME_ACTION,
  TOGGLE_EXPANDER,
  TOGGLE_TOTAL_SUMMARY,
  TOGGLE_MOVING_AVERAGE_SUMMARY,
  TOGGLE_TOTAL_DETAIL,
  TOGGLE_MOVING_AVERAGE_DETAIL,
  TOGGLE_FORECAST_REVENUE,
  TOGGLE_FORECAST_UNIT_COST,
  TOGGLE_FORECAST_GROSS_PROFIT,
  TOGGLE_FORECAST_SVG_COST,
  TOGGLE_FORECAST_OPERATING_PROFIT,
  TOGGLE_FORECAST_REVENUE_DETAIL,
  TOGGLE_FORECAST_UNIT_COST_DETAIL,
  TOGGLE_FORECAST_SVG_COST_DETAIL,
  TOGGLE_FORECAST_FUNNEL_TABLE,
  SET_WIDTH,
  TOGGLE_PERIODIC_AVERAGE_GRAPH
} from "./types";

export const loginRequest = (email: string, password: string) => ({
  type: LOGIN_REQUESTED,
  email,
  password
});

export const logoutRequest = () => ({
  type: LOGOUT_REQUESTED
});

export const loginFailedReset = () => ({
  type: LOGIN_FAILED_RESET
});

export const setPagePath = (page_path: string) => ({
  type: SET_PAGE_PATH,
  page_path
});

export const setWidth = (inner_width: number) => {
  return {
    type: SET_WIDTH,
    inner_width
  };
};

export const toggleMovingAverageGraphSwitch = (
  moving_average_graph_switch: boolean
) => ({
  type: TOGGLE_MOVING_AVERAGE_GRAPH_SWITCH,
  moving_average_graph_switch
});

//TOGGLE_PERIODIC_AVERAGE_GRAPH
export const togglePeriodicAverageGraph = (
  periodic_average_graph: boolean
) => ({
  type: TOGGLE_PERIODIC_AVERAGE_GRAPH,
  periodic_average_graph
});

export const toggleThisTimeGraphSwitch = (this_time_graph_switch: boolean) => ({
  type: TOGGLE_THIS_TIME_GRAPH_SWITCH,
  this_time_graph_switch
});

export const urlToDataNameAction = (location: string) => ({
  type: URL_TO_DATA_NAME_ACTION,
  location
});

export const toggleExpander = (expanderActivated: boolean) => ({
  type: TOGGLE_EXPANDER,
  expanderActivated
});

export const toggleTotalSummary = (totalSummaryOpened: boolean) => ({
  type: TOGGLE_TOTAL_SUMMARY,
  totalSummaryOpened
});

export const toggleMovingAverageSummary = (
  movingAverageSummaryOpened: boolean
) => ({
  type: TOGGLE_MOVING_AVERAGE_SUMMARY,
  movingAverageSummaryOpened
});

export const toggleTotalDetail = (totalDetailOpened: boolean) => ({
  type: TOGGLE_TOTAL_DETAIL,
  totalDetailOpened
});

export const toggleMovingAverageDetail = (
  movingAverageDetailOpened: boolean
) => ({
  type: TOGGLE_MOVING_AVERAGE_DETAIL,
  movingAverageDetailOpened
});

export const forecastRevenue = (forecastRevenueOpened: boolean) => ({
  type: TOGGLE_FORECAST_REVENUE,
  forecastRevenueOpened
});

export const forecastRevenueDetail = (
  forecastRevenueDetailOpened: boolean
) => ({
  type: TOGGLE_FORECAST_REVENUE_DETAIL,
  forecastRevenueDetailOpened
});

export const forecastUnitCost = (forecastUnitCostOpened: boolean) => ({
  type: TOGGLE_FORECAST_UNIT_COST,
  forecastUnitCostOpened
});

export const forecastUnitCostDetail = (
  forecastUnitCostDetailOpened: boolean
) => ({
  type: TOGGLE_FORECAST_UNIT_COST_DETAIL,
  forecastUnitCostDetailOpened
});

export const forecastGrossProfit = (forecastGrossProfitOpened: boolean) => ({
  type: TOGGLE_FORECAST_GROSS_PROFIT,
  forecastGrossProfitOpened
});

export const forecastSvgCost = (forecastSvgCostOpened: boolean) => ({
  type: TOGGLE_FORECAST_SVG_COST,
  forecastSvgCostOpened
});

export const forecastSvgCostDetail = (
  forecastSvgCostDetailOpened: boolean
) => ({
  type: TOGGLE_FORECAST_SVG_COST_DETAIL,
  forecastSvgCostDetailOpened
});

export const forecastOperatingProfit = (
  forecastOperatingProfitOpened: boolean
) => ({
  type: TOGGLE_FORECAST_OPERATING_PROFIT,
  forecastOperatingProfitOpened
});

export const toggleForecastFunnelTable = (
  target_raw: string,
  target_raw_is_open: boolean
) => ({
  type: TOGGLE_FORECAST_FUNNEL_TABLE,
  target_raw,
  target_raw_is_open
});
