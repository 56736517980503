const GET_RESULTS_REQUESTED = "GET_RESULTS_REQUESTED"
const GET_RESULTS_SUCCEEDED = "GET_RESULTS_SUCCEEDED"
const GET_RESULTS_FAILED = "GET_RESULTS_FAILED"

const GET_TARGET_RESULTS_REQUESTED = "GET_TARGET_RESULTS_REQUESTED"
const GET_TARGET_RESULTS_SUCCEEDED = "GET_TARGET_RESULTS_SUCCEEDED"
const GET_TARGET_RESULTS_FAILED = "GET_TARGET_RESULTS_FAILED"

const GET_RESULTS_REQUESTED_BY_DATE = "GET_RESULTS_REQUESTED_BY_DATE" //ページヘッダーのカレンダーから
const GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON = "GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON" //期間比較カレンダーから
const GET_RESULTS_FOR_SPAN_COMPARISON_SUCCEEDED = "GET_RESULTS_REQUESTED_FOR_SPAN__SUCCEEDED"
const GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_WITH_PAGE_INFO = "GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_WITH_PAGE_INFO" //期間比較カレンダーから
const SET_RESULTS_FOR_SIMULATION = "SET_RESULTS_FOR_SIMULATION" //シミュレーション
const UPDATE_SIMULATED_RESULT = "UPDATE_SIMULATED_RESULT" //シミュレーション
const UPDATE_FUNNEL_SIMULATED_RESULT = "UPDATE_FUNNEL_SIMULATED_RESULT" //Forecastsファネルのミュレーション
const SET_FILTERED_RESULTS = "SET_FILTERED_RESULTS" // 変動フィルターをかけたデータをセット
const GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_DATA = "GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_DATA"
const GET_RESULTS_FOR_SPAN_COMPARISON_DATA_SUCCEEDED = "GET_RESULTS_FOR_SPAN_COMPARISON_DATA_SUCCEEDED"
const GET_RESULTS_FOR_SPAN_COMPARISON_DATA_FAILED = "GET_RESULTS_FOR_SPAN_COMPARISON_DATA_FAILED"

const GET_FILTERED_RESULTS_REQUEST = "GET_FILTERED_RESULTS_REQUEST"
const GET_FILTERED_RESULTS_SUCCEEDED = "GET_FILTERED_RESULTS_SUCCEEDED"
const GET_FILTERED_RESULTS_FAILED = "GET_FILTERED_RESULTS_FAILED"

const GET_FILTERED_FUNNEL_RESULTS_REQUEST = "GET_FILTERED_FUNNEL_RESULTS_REQUEST"
const GET_FILTERED_FUNNEL_RESULTS_SUCCEEDED = "GET_FILTERED_FUNNEL_RESULTS_SUCCEEDED"
const GET_FILTERED_FUNNEL_RESULTS_FAILED = "GET_FILTERED_FUNNEL_RESULTS_FAILED"

const GET_DETAIL_REVENUE_REQUEST = "GET_DETAIL_REVENUE_REQUEST"
const GET_DETAIL_REVENUE_SUCCEEDED = "GET_DETAIL_REVENUE_SUCCEEDED"
const GET_DETAIL_REVENUE_FAILED = "GET_DETAIL_REVENUE_FAILED"

const UPDATE_REVENUE_DETAIL_SIMULATED_RESULT = "UPDATE_REVENUE_DETAIL_SIMULATED_RESULT";

export { GET_RESULTS_REQUESTED, GET_RESULTS_SUCCEEDED, GET_RESULTS_FAILED, GET_RESULTS_REQUESTED_BY_DATE, GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON, GET_RESULTS_FOR_SPAN_COMPARISON_SUCCEEDED, GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_WITH_PAGE_INFO, SET_RESULTS_FOR_SIMULATION, UPDATE_SIMULATED_RESULT, SET_FILTERED_RESULTS, GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_DATA, GET_RESULTS_FOR_SPAN_COMPARISON_DATA_SUCCEEDED, GET_RESULTS_FOR_SPAN_COMPARISON_DATA_FAILED, UPDATE_FUNNEL_SIMULATED_RESULT, GET_FILTERED_RESULTS_REQUEST, GET_FILTERED_RESULTS_SUCCEEDED, GET_FILTERED_RESULTS_FAILED, GET_FILTERED_FUNNEL_RESULTS_REQUEST, GET_FILTERED_FUNNEL_RESULTS_SUCCEEDED, GET_FILTERED_FUNNEL_RESULTS_FAILED, GET_DETAIL_REVENUE_REQUEST, GET_DETAIL_REVENUE_SUCCEEDED, GET_DETAIL_REVENUE_FAILED, UPDATE_REVENUE_DETAIL_SIMULATED_RESULT, GET_TARGET_RESULTS_REQUESTED, GET_TARGET_RESULTS_SUCCEEDED, GET_TARGET_RESULTS_FAILED}
