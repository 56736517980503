import rsf from '../rsf';
import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import firebase from 'firebase';
import {} from '../reducks/results/types';
import { HIDE_LOADING } from '../reducks/loading/types'
import { GET_RESULTS_REQUEST_FOR_NEW_FUNNEL_FORECAST, GET_RESULTS_REQUEST_FOR_NEW_FUNNEL_FORECAST_SUCCEEDED, GET_RESULTS_REQUEST_FOR_NEW_FUNNEL_FORECAST_FAILED } from '../reducks/newFunnelForecast/types';

const db = firebase.firestore();

function* getResultsForNewFunnelForcast(action) {
  try {
    const result_span = action.result_span;
    let snapshotForecast = [];
    let snapshotReal = [];
    let snapshotThisMonth = [];

    const startMonth = new Date(action.today.getFullYear() - 1, action.today.getMonth() + 1, 1); //昨年の次の月
    const endMonth = new Date(action.today.getFullYear(), action.today.getMonth(), 2); //今年の来月

    var n = 3;
    var forecastBaseStartMonth = new Date(action.today);
    var forecastBaseEndMonth = new Date(action.today);

    // setMonthで、「現在月 = 現在月 + ｎヶ月」を行う
    // setMonthだけだと時刻が半端になってしまい最初の月データを取得できないので、１月前にする。
    // setDateで１月前の２８日に設定することで最初の月データも取れる（閏年を考慮して28日）
    forecastBaseStartMonth.setMonth(forecastBaseStartMonth.getMonth() - action.forecast_base_span - 1);
    forecastBaseStartMonth.setDate(28);

    //　今月分のデータを取得するために先月と今日の日を取得（１０月分のデータないのでとりあえず９月分を取得）
    var lastMonth = new Date(action.today);
    lastMonth.setMonth(lastMonth.getMonth() - 2);
    var today = new Date(action.today);
    today.setMonth(today.getMonth() - 1);

    // 実測値を求める範囲の開始年月日
    const realStartYear = action.calenderStartDate.getFullYear(); //実測値を求める範囲の開始年
    const realStartMonth = action.calenderStartDate.getMonth(); //実測値を求める範囲の開始月
    const realStart = new Date(realStartYear, realStartMonth, 1); //実測値を求める範囲の開始年月日

    if (result_span === 'daily') {
      console.log('cannot forecast with daily data');
    } else if (result_span === 'weekly') {
      console.log('cannot forecast with weekly data');
    } else {
      // 過去データを取得
      snapshotForecast = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_monthly`).where('result_at', '>=', forecastBaseStartMonth).where('result_at', '<=', forecastBaseEndMonth));
      // 右上カレンダーで指定された実測値データを取得
      snapshotReal = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_monthly`).where('result_at', '>=', realStart).where('result_at', '<=', today));
      // 今月のデータを取得
      snapshotThisMonth = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_monthly`).where('result_at', '>=', lastMonth).where('result_at', '<=', today));
    }
    let resultsForecast = [];
    let resultsReal = [];
    let resultsThisMonth = [];

    snapshotForecast.forEach((result) => {
      resultsForecast = [...resultsForecast, { ...result.data() }];
    });
    snapshotReal.forEach((result) => {
      resultsReal = [...resultsReal, { ...result.data() }];
    });
    snapshotThisMonth.forEach((result) => {
      resultsThisMonth = [...resultsThisMonth, { ...result.data() }];
    });

    // revenue_detail過去データ
    const collectionGroup_past = () => {
      return db.collectionGroup('revenue_detail').where('company_id', '==', action.company_id).where('result_at', '>=', forecastBaseStartMonth).where('result_at', '<=', forecastBaseEndMonth).get();
    };
    const snapshotRevenueDetailPast = yield call(collectionGroup_past, 'revenue_detail');

    let resultsRevenueDetailPast = [];
    snapshotRevenueDetailPast.forEach((doc) => {
      resultsRevenueDetailPast = [...resultsRevenueDetailPast, { ...doc.data(), docId: doc.id }];
    });

    // revenue_detail右上カレンダーで指定された実測値データ
    const collectionGroup_real = () => {
      return db.collectionGroup('revenue_detail').where('company_id', '==', action.company_id).where('result_at', '>=', realStart).where('result_at', '<=', today).get();
    };
    const snapshotRevenueDetailReal = yield call(collectionGroup_real, 'revenue_detail');

    let resultsRevenueDetailReal = [];
    snapshotRevenueDetailReal.forEach((doc) => {
      resultsRevenueDetailReal = [...resultsRevenueDetailReal, { ...doc.data(), docId: doc.id }];
    });

    // revenue_detail今月
    const collectionGroup_thisMonth = () => {
      return db.collectionGroup('revenue_detail').where('company_id', '==', action.company_id).where('result_at', '>=', lastMonth).where('result_at', '<=', today).get();
    };
    const snapshotRevenueDetailThisMonth = yield call(collectionGroup_thisMonth, 'revenue_detail');

    let resultsRevenueDetailThisMonth = [];
    snapshotRevenueDetailThisMonth.forEach((doc) => {
      resultsRevenueDetailThisMonth = [...resultsRevenueDetailThisMonth, { ...doc.data(), docId: doc.id }];
    });
    yield all([
      put({ type: GET_RESULTS_REQUEST_FOR_NEW_FUNNEL_FORECAST_SUCCEEDED, resultsForecast: resultsForecast, resultsReal: resultsReal, resultsThisMonth: resultsThisMonth, resultsRevenueDetailThisMonth: resultsRevenueDetailThisMonth, resultsRevenueDetailPast: resultsRevenueDetailPast, resultsRevenueDetailReal: resultsRevenueDetailReal, result_span: result_span, forecast_base_span: action.forecast_base_span }),
      put({ type: HIDE_LOADING })
    ]) 
  } catch (e) {
    yield all([
      put({ type: GET_RESULTS_REQUEST_FOR_NEW_FUNNEL_FORECAST_FAILED, message: e.message }),
      put({type: HIDE_LOADING})
    ])
  }
}

const newFunnelForecastSagas = [takeLatest(GET_RESULTS_REQUEST_FOR_NEW_FUNNEL_FORECAST, getResultsForNewFunnelForcast)];
export default newFunnelForecastSagas;
