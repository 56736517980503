import {
  UPLOAD_DATA_REQUESTED, 
  UPLOAD_DATA_STARTED, 
  RESET_DATA
} from "./types";

export const uploadDataRequest = (formData: any, endpoint: string) => ({
  type: UPLOAD_DATA_REQUESTED,
  formData,
  endpoint,

})

export const uploadDataStart = () => ({
  type: UPLOAD_DATA_STARTED
})

export const resetData = () => ({
  type: RESET_DATA
})