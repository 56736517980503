import React, { Component } from "react";
import styled from "styled-components";
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  LineChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Bar
} from "recharts";
import { yAxisFormatter } from "../Util/yAxisFormatter";
import { tooltipDateFormatter } from "../Util/DateFormatter";
import {
  unitSwitcher,
  decimalPointChecker,
  decimalPointCheckerForPercent
} from "../Util/unitSwitcher";

const getBarGraphTooltipData = (targetDataName: string, value: number) => {
  if (targetDataName.match("churn_rate")) {
    return decimalPointCheckerForPercent(value);
  }
  return decimalPointChecker(value);
};

const CustomTooltipBar = ({ active, payload, label }: any) => {
  console.log("activeactiveactive", active);
  console.log("payloadpayloadpayload", payload);
  console.log("label", label);
  if (active && payload && payload.length > 0) {
    return (
      <BarGraphTooltip>
        {payload[0] && (
          <>
            <BarGraphTooltipDate>
              {new Date(
                payload[0] && payload[0].payload.date * 1000
              ).getFullYear()}
              .
              {new Date(
                payload[0] && payload[0].payload.date * 1000
              ).getMonth() + 1}
            </BarGraphTooltipDate>
            <BarGraphTooltipLabel>実績</BarGraphTooltipLabel>
            <BarGraphTooltipData>{`${getBarGraphTooltipData(
              payload[0].dataKey,
              payload[0] ? payload[0].value : 0
            )}`}</BarGraphTooltipData>
          </>
        )}
        {payload[1] && (
          <>
            <BarGraphTooltipHR />

            <BarGraphTooltipLabel>目標</BarGraphTooltipLabel>
            <BarGraphTooltipData>{`${getBarGraphTooltipData(
              payload[1].dataKey,
              payload[1] ? payload[1].value : 0
            )}`}</BarGraphTooltipData>
          </>
        )}

        {payload[2] && (
          <>
            <BarGraphTooltipHR />
            <BarGraphTooltipLabel>移動平均</BarGraphTooltipLabel>
            <BarGraphTooltipData>{`${getBarGraphTooltipData(
              payload[2].dataKey,
              payload[2] ? payload[2].value : 0
            )}`}</BarGraphTooltipData>
          </>
        )}
      </BarGraphTooltip>
    );
  }

  return null;
};

interface Props {
  graphObj: any;
  index: number;
  contents: any;
  resultSpan: string;
}

export default class TopGraph extends Component<Props> {
  area: any;
  tooltip: HTMLElement | null;
  point: any;

  constructor(props: any) {
    super(props);

    this.area = null;
    this.tooltip = null;
    this.point = null;
  }

  render() {
    const { graphObj, index, contents } = this.props;
    return (
      <Graph>
        {/* グラフ名 */}
        <ResponsiveContainer>
          <ComposedChart
            data={
              graphObj.filter(
                (item: any) => item.graphName === `${contents[index].name}`
              )[0].data
            }
            width={300}
            height={400}
            margin={{ bottom: 6 }}
            barSize={20}
            barGap={5}
          >
            <Legend
              verticalAlign={"top"}
              align={"left"}
              width={432}
              height={32}
              margin={{ right: 100 }}
              iconSize={12}
              wrapperStyle={{ fontSize: "12px", color: "#283f46" }}
            />
            <CartesianGrid stroke="#f5f5f5" vertical={false} />
            <Bar
              name="実績"
              dataKey={`${contents[index].name}_total`}
              fill="#0066FF"
            />
            <Bar
              name="目標"
              dataKey={`${contents[index].name}_objective`}
              fill="#99C2FF"
            />
            <Line
              type="linear"
              name="移動平均"
              dataKey={`${contents[index].name}_moving_average`}
              dot={false}
              stroke="#0066FF"
              strokeWidth="3"
              strokeDasharray="5 5"
            />

            <XAxis
              dataKey={"dateInGraphToDisplay"}
              hide={false}
              tickLine={false}
              padding={{ left: 0, right: 0 }}
              scale="auto"
            />

            <YAxis
              tickCount={10}
              interval={0}
              tickLine={false}
              tickMargin={10}
              tickFormatter={tick => {
                return `${contents[index].name}` === "ConversionRate"
                  ? yAxisFormatter(tick, "conversion_rate")
                  : `${contents[index].name}` === "UnitEconomics"
                  ? yAxisFormatter(tick, "unit_economics")
                  : `${contents[index].name}` === "churnRate"
                  ? yAxisFormatter(tick, "churn_rate")
                  : `${contents[index].name}` === "NRR"
                  ? yAxisFormatter(tick, "nrr")
                  : `${contents[index].name}` === "GRR"
                  ? yAxisFormatter(tick, "grr")
                  : `${contents[index].name}` === "QuickRatio"
                  ? yAxisFormatter(tick, "quick_ratio")
                  : `${contents[index].name}` === "PaybackPeriod"
                  ? yAxisFormatter(tick, "payback_period")
                  : yAxisFormatter(tick, "revenue");
              }}
            />

            <Tooltip content={<CustomTooltipBar />} />

            {/* <Tooltip content={customTooltip} wrapperStyle={customWrapperStyle} /> */}
          </ComposedChart>
        </ResponsiveContainer>
      </Graph>
    );
  }
}

//　グラフ関連のスタイル
const Graph = styled.div`
  width: 100%;
  height: 272px;
  padding: 20px 10px 0 10px;
  box-sizing: border-box;

  .recharts-legend-wrapper {
    top: -18px !important;
    left: -8px !important;

    .recharts-surface {
      position: relative;
      top: -1px;
    }
  }
  .recharts-wrapper {
    .recharts-surface {
      overflow: visible;
    }
    .recharts-cartesian-axis-tick {
      .recharts-cartesian-axis-tick-value {
        font-size: 10px;
        color: #435878;
      }
    }
  }
`;

const BarGraphTooltip = styled.div`
  background: #fff;
  padding: 8px 9px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid #e2e9f3;
  border-radius: 4px;
  max-width: 200px;
`;

// 日付
const BarGraphTooltipDate = styled.p`
  width: 100%;
  font-size: 10px;
  font-weight: bold;
  color: #7591bc;
  margin-bottom: 3px;
`;

// 当月 | 目標
const BarGraphTooltipLabel = styled.p`
  width: 30%;
  font-size: 10px;
  color: #435878;
  margin-bottom: 3px;
`;
// データ
const BarGraphTooltipData = styled.p`
  width: 70%;
  text-align: right;
  font-size: 10px;
  font-weight: bold;
  color: #435878;
  margin-bottom: 3px;
`;
// 仕切り線
const BarGraphTooltipHR = styled.hr`
  width: 100%;
  height: 1px;
  color: lightgray;
  margin-bottom: 3px;
`;
