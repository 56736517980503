import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import GlobalNav from "../../Layout/GlobalNav"
import PageHeaderRaw from "../../Layout/PageHeader"
import ConfigNavCard from "../ConfigNavCard"

import { transferOwnershipRequest } from "../../../reducks/members/actions"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      backgroundColor: "#F4F4F4",
    },
    configCard: {
      display: "flex",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  })
)

// Container
const Container = styled.div`
  display: flex;
`

// MemberContent
const MemberContent = styled.div`
  width: 100%;
  height: 100vh;
  padding: 33px 40px;
  box-sizing: border-box;
  background-color: #fff;
`

// MemberTitle
const MemberTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  line-height: 27px;
  margin: 0 0 28px 0;
`

// Form
const Form = styled.form`
  max-width: 570px;
  width: 100%;
`
// Line
const Line = styled.div`
  margin: 0 0 24px 0;
  padding-left: 100px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  label {
    color: #435878;
    text-align: right;
    width: 143px;
    margin-right: 34px;
    display: inline-block;

    span {
      font-size: 14px;
      font-weight: bold;
      color: #af002a;
    }
  }
  input {
    font-size: 14px;
    line-height: 21px;
    color: #283f46;
    width: 100%;
    max-width: 296px;
    border: 1px solid #c3cedf;
    border-radius: 4px;
    padding: 12px 21px;
    box-sizing: border-box;
  }
`

// ButtonBlock
const ButtonBlock = styled.div`
  max-width: 570px;
  width: 100%;
  padding-top: 30px;
  display: flex;
  justify-content: flex-end;
`
// Button
const Button = styled.button`
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
  color: #fff;
  text-align: center;
  max-width: 152px;
  width: 100%;
  height: 36px;
  padding: 9px 10px 10px 10px;
  display: inline-block;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  appearance: none;
  outline: none;
  a {
    color: #435878;
    text-decoration: none;
    display: block;
  }
  &.send {
    background-color: #0066ff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
  }
  &:hover {
    opacity: 0.6;
  }
`

const TransferOwnership: React.FC = (props: any) => {
  const classes = useStyles()
  let history = useHistory()

  const dispatch = useDispatch()
  console.log("props.params.id: ", props.match.params.id)
  const members = useSelector((state: any) => state.members)
  console.log("members: ", members)

  const urlDocId = props.match.params.id
  const memberEdited = members.find((member: any) => member.docId === urlDocId)
  const [lastName] = useState(memberEdited.lastName)
  const [firstName] = useState(memberEdited.firstName)
  const [email] = useState(memberEdited.email)
  const company_id = useSelector((state: any) => state.user.company_id)

  const new_owner = members.find((member: any) => member.docId === urlDocId);
  const old_owner = members.find((member: any) => member.owner === true);

  const handleTransferOwnership = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch(transferOwnershipRequest(company_id, new_owner, old_owner, history))
  }

  return (
    <Container>
      <GlobalNav />

      <main className={classes.content}>
        <PageHeaderRaw page_title="管理" />

        <div className={classes.configCard}>
          <ConfigNavCard />

          <MemberContent>
            <MemberTitle>以下のメンバーにオーナーシップを移管しますがよろしいですか？</MemberTitle>

            <Form onSubmit={handleTransferOwnership}>
              <Line>
                <label htmlFor="name">名前</label>
                <p>
                  {lastName} {firstName}
                </p>
              </Line>

              <Line>
                <label htmlFor="email">メールアドレス</label>
                <p>{email}</p>
              </Line>

              <ButtonBlock>
                <Button className="cancel">
                  <Link to="/config/member-setting">キャンセル</Link>
                </Button>
                <Button className="send" type="submit">
                  移管する
                </Button>
              </ButtonBlock>
            </Form>
          </MemberContent>
        </div>
      </main>
    </Container>
  )
}

export default TransferOwnership
