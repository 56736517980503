const GET_REVENUE_DETAIL_REQUEST = 'GET_REVENUE_DETAIL_REQUEST';
const GET_REVENUE_DETAIL_FAILED = 'GET_REVENUE_DETAIL_FAILED';
const GET_REVENUE_DETAIL_SUCCEEDED = 'GET_REVENUE_DETAIL_SUCCEEDED';

const GET_RESULTS_REQUEST_FOR_NEW_FORECAST = 'GET_RESULTS_REQUEST_FOR_NEW_FORECAST';
const GET_RESULTS_REQUEST_FOR_NEW_FORECAST_SUCCEEDED = 'GET_RESULTS_REQUEST_FOR_NEW_FORECAST_SUCCEEDED';
const GET_RESULTS_REQUEST_FOR_NEW_FORECAST_FAILED = 'GET_RESULTS_REQUEST_FOR_NEW_FORECAST_FAILED';

const UPDATE_NEW_FORECAST_SIMULATED_RESULT = 'UPDATE_NEW_FORECAST_SIMULATED_RESULT';

const SET_FORECAST_FILTER_STATUS = 'SET_FORECAST_FILTER_STATUS';

const SET_FORECAST_FILTER = 'SET_FORECAST_FILTER';
const RESET_FORECAST_FILTER = 'RESET_FORECAST_FILTER';
const GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_FORECASTS = 'GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_FORECASTS';
const GET_RESULTS_FOR_SPAN_COMPARISON_FORECASTS_SUCCEEDED = 'GET_RESULTS_FOR_SPAN_COMPARISON_FORECASTS_SUCCEEDED';
const GET_RESULTS_FOR_SPAN_COMPARISON_FORECASTS_FAILED = 'GET_RESULTS_FOR_SPAN_COMPARISON_FORECASTS_FAILED';

const GET_RESULTS_REQUEST_FOR_NEW_FORECAST_FOR_CUSTOM = 'GET_RESULTS_REQUEST_FOR_NEW_FORECAST_FOR_CUSTOM';

const SET_FORECAST_CUSTOM_SPAN = 'SET_FORECAST_CUSTOM_SPAN';

export { GET_REVENUE_DETAIL_REQUEST, GET_REVENUE_DETAIL_SUCCEEDED, GET_REVENUE_DETAIL_FAILED, GET_RESULTS_REQUEST_FOR_NEW_FORECAST, GET_RESULTS_REQUEST_FOR_NEW_FORECAST_SUCCEEDED, GET_RESULTS_REQUEST_FOR_NEW_FORECAST_FAILED, UPDATE_NEW_FORECAST_SIMULATED_RESULT, SET_FORECAST_FILTER_STATUS, SET_FORECAST_FILTER, RESET_FORECAST_FILTER, GET_RESULTS_FOR_SPAN_COMPARISON_FORECASTS_SUCCEEDED, GET_RESULTS_FOR_SPAN_COMPARISON_FORECASTS_FAILED, GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_FORECASTS, GET_RESULTS_REQUEST_FOR_NEW_FORECAST_FOR_CUSTOM, SET_FORECAST_CUSTOM_SPAN };
