import {
  UPDATE_COMPANY_FROM_TO_REQUESTED,
  SET_COMPANY_FROM_TO,
  UPDATE_MOVING_AVERAGE_REQUESTED,
  UPDATE_WEEK_START_DATE_REQUESTED,
  UPDATE_CLOSING_MONTH_REQUESTED,
  UPDATE_SPREADSHEET_SYNC
} from "./types";

export const updateCompanyFromToRequest = (
  company_id: string,
  from: any,
  to: any,
  selected_data_config_data_type: string,
  filter_name: string,
  target_data_name?: string
) => ({
  type: UPDATE_COMPANY_FROM_TO_REQUESTED,
  company_id,
  from,
  to,
  selected_data_config_data_type,
  filter_name,
  target_data_name
});

export const setCompanyFromTo = (
  resultSpan: string,
  company_id: string,
  from: any,
  to: any
) => ({
  type: SET_COMPANY_FROM_TO,
  resultSpan,
  company_id,
  from,
  to
});

export const updateMovingAverageRequest = (
  moving_average: string,
  config: any,
  company_id: string
) => ({
  type: UPDATE_MOVING_AVERAGE_REQUESTED,
  moving_average,
  config,
  company_id
});
export const updateWeekStartDateRequest = (
  weekly_start_day: string,
  config: any,
  company_id: string
) => ({
  type: UPDATE_WEEK_START_DATE_REQUESTED,
  weekly_start_day,
  config,
  company_id
});

export const updateClosingMonthRequested = (
  closing_month: string,
  company_id: string
) => {
  return {
    type: UPDATE_CLOSING_MONTH_REQUESTED,
    closing_month,
    company_id
  };
};

export const updateSpreadsheetSync = (
  google_spreadsheet_import_time: string,
  google_spreadsheet_import_master: boolean,
  google_spreadsheet_import_actual: boolean,
  company_id: string
) => {
  return {
    type: UPDATE_SPREADSHEET_SYNC,
    google_spreadsheet_import_time,
    google_spreadsheet_import_master,
    google_spreadsheet_import_actual,
    company_id
  };
};
