import React from "react";
import { useSelector, useDispatch } from "react-redux";

//  lib components
import { TableBody, TableHead, TableRow } from "@material-ui/core";

//  styled components
import { StyledTable, StickerHeadCell, StickyRow, Wrapper, ResultTable1, ResultTable2 } from "./styles";

//  modules
import { generateTableData } from "./modules";

//  types
import { ForecastData } from "./types";

interface BusinessPlanTableProps {
  dataType: "business";
  forecastData?: ForecastData[];
}
export const BusinessPlanTable: React.FC<BusinessPlanTableProps> = props => {
  const { dataType, forecastData } = props;

  //  modules
  const tableData = generateTableData({ dataType, forecastData });

  return (
    <Wrapper>
      {/* 【 実績 】 目標・実績・達成率 */}
      <StyledTable>
        {/* Head */}
        <TableHead style={{ marginBottom: 20 }}>
          <TableRow>
            <StickerHeadCell style={{ zIndex: 1 }} />
            {tableData.head.map((val, i) => (
              <StickerHeadCell key={i}>{val.value}</StickerHeadCell>
            ))}
          </TableRow>
        </TableHead>

        {/* Body */}
        <TableBody>
          {/* label */}
          <StickyRow>{"　"}</StickyRow>
        </TableBody>
      </StyledTable>

      {/* 売上 */}
      <ResultTable1
        label="売上"
        tableData={{
          head: tableData.head,
          body: tableData.body.earning
        }}
      />

      {/* 原価 */}
      <ResultTable1
        label="原価"
        tableData={{
          head: tableData.head,
          body: tableData.body.cost
        }}
        borderTop={"none"}
      />

      {/* 売上総利益 */}
      <ResultTable1
        label="売上総利益"
        tableData={{
          head: tableData.head,
          body: tableData.body.grossProfit
        }}
        borderTop={"none"}
      />

      {/* 販売管理費 */}
      <ResultTable1
        label="販売管理費"
        tableData={{
          head: tableData.head,
          body: tableData.body.managementCost
        }}
        borderTop={"none"}
      />

      {/* 営業利益 */}
      <ResultTable1
        label="営業利益"
        tableData={{
          head: tableData.head,
          body: tableData.body.operatingProfit
        }}
        borderTop={"none"}
      />
    </Wrapper>
  );
};
