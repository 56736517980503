import rsf from "../../rsf"
import firebase from "firebase"
import { call } from "redux-saga/effects"

const db = firebase.firestore()

// ユーザーの所属するcompany情報を取得

function* fetchCohortBasicValues() {
  try {
    const basic_values = yield call(promise_fetchCohortBasicValues);
    return basic_values;
  }catch(e){
    console.log("error message", e.message)
    return Promise.reject();
  }
}

export function promise_fetchCohortBasicValues(){
    return new Promise((resolve , reject) => {
        db.collection('companies')
            .doc('andactdemocompany')
            .collection('cohorts')
            .get()
            .then(val => {
                const churn_rate_values = val.docs.filter(item => {
                    const data = item.data();
                    if(data.data_name === "churn_rate") return data;
                })[0].data().basic_values
                    .sort((a,b) => a.order - b.order)
                    .map((val,i) => { return {...val , order : i + 1}})
                const client_churned_values = val.docs.filter(item => {
                    const data = item.data();
                    if(data.data_name === "client_churned") return data;
                })[0].data().basic_values
                    .sort((a,b) => a.order - b.order)
                    .map((val,i) => { return {...val , order : i + 1}})
                const revenue_churned_values = val.docs.filter(item => {
                    const data = item.data();
                    if(data.data_name === "revenue_churned") return data;
                })[0].data().basic_values
                    .sort((a,b) => a.order - b.order)
                    .map((val,i) => { return {...val , order : i + 1}})

                const basic_values = [
                    ...churn_rate_values,
                    ...client_churned_values,
                    ...revenue_churned_values
                ]

                return resolve(basic_values)
            }).catch(err => {
                console.log("err",err);
                return reject();
            })
    })    
}


export function* fetchCohort(comId) {
  try {
    const cohort_churn_rate = yield call(() => promise_fetchCohor(comId,"cohort_churn_rate"));
    const cohort_client_churned = yield call(() => promise_fetchCohor(comId,"cohort_client_churned"));
    const cohort_revenue_churned = yield call(() => promise_fetchCohor(comId,"cohort_revenue_churned"));
    console.log("🟢🟢 fetchCohort" , cohort_churn_rate);
    return {cohort_churn_rate,cohort_client_churned,cohort_revenue_churned}
  }catch(e){
    console.log("error message", e.message)
    return;
  }
}

function promise_fetchCohor(comId , collName) {
  return new Promise((resolve , reject) => {
    db.collection('companies').doc(comId).collection(collName)
      .get()
      .then(val => {
        const docs = val.docs;
        const data = docs.map(doc => {
          return {
            ...doc.data(),
            start_month : doc.data().start_month.toDate() 
          }
        });
        return resolve(data);
      }).catch(err => {
        console.log("err",err);
        return reject(err);
      })
  }) 
}


export default fetchCohortBasicValues;