const SET_FUNNELS = "SET_FUNNELS"
const SET_FUNNELS_DETAIL = "SET_FUNNELS_DETAIL"
const FETCH_FUNNELS_SUMMARY = "SET_FUNNELS_SUMMARY"
const FETCH_FUNNELS_DETAIL = "SET_FUNNELS_DETAIL"
const GET_RESULTS_FOR_SPAN_COMPARISON_FUNNEL_SUMMARY_SUCCEEDED = "GET_RESULTS_FOR_SPAN_COMPARISON_FUNNEL_SUMMARY_SUCCEEDED"
const GET_RESULTS_FOR_SPAN_COMPARISON_FUNNEL_SUMMARY_FAILED = "GET_RESULTS_FOR_SPAN_COMPARISON_FUNNEL_SUMMARY_FAILED"
const GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_FUNNEL_SUMMARY = "GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_FUNNEL_SUMMARY"

const GET_RESULTS_FOR_SPAN_COMPARISON_FUNNEL_DETAIL_SUCCEEDED = "GET_RESULTS_FOR_SPAN_COMPARISON_FUNNEL_DETAIL_SUCCEEDED"
const GET_RESULTS_FOR_SPAN_COMPARISON_FUNNEL_DETAIL_FAILED = "GET_RESULTS_FOR_SPAN_COMPARISON_FUNNEL_DETAIL_FAILED"
const GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_FUNNEL_DETAIL = "GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_FUNNEL_DETAIL"

const TOGGLE_ACTION_ACHIVEMENT_SELECTED_NAME_LIST = "TOGGLE_ACTION_ACHIVEMENT_SELECTED_NAME_LIST"
const TOGGLE_ACHIVEMENT_COST_SELECTED_NAME_LIST = "TOGGLE_ACHIVEMENT_COST_SELECTED_NAME_LIST"
const ACTION_ACHIVEMENT_SELECTED_NAME_LIST_SHOW_ALL = "ACTION_ACHIVEMENT_SELECTED_NAME_LIST_SHOW_ALL"
const ACHIVEMENT_COST_SELECTED_NAME_LIST_SHOW_ALL = "ACHIVEMENT_COST_SELECTED_NAME_LIST_SHOW_ALL"

const GET_FUNNEL_MASTER_LIST_SUCCEEDED = "GET_FUNNEL_MASTER_LIST_SUCCEEDED"



export { SET_FUNNELS, FETCH_FUNNELS_SUMMARY, FETCH_FUNNELS_DETAIL, SET_FUNNELS_DETAIL, GET_RESULTS_FOR_SPAN_COMPARISON_FUNNEL_SUMMARY_SUCCEEDED, GET_RESULTS_FOR_SPAN_COMPARISON_FUNNEL_SUMMARY_FAILED, GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_FUNNEL_SUMMARY, GET_RESULTS_FOR_SPAN_COMPARISON_FUNNEL_DETAIL_SUCCEEDED, GET_RESULTS_FOR_SPAN_COMPARISON_FUNNEL_DETAIL_FAILED, GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_FUNNEL_DETAIL,TOGGLE_ACTION_ACHIVEMENT_SELECTED_NAME_LIST, TOGGLE_ACHIVEMENT_COST_SELECTED_NAME_LIST, ACTION_ACHIVEMENT_SELECTED_NAME_LIST_SHOW_ALL,ACHIVEMENT_COST_SELECTED_NAME_LIST_SHOW_ALL, GET_FUNNEL_MASTER_LIST_SUCCEEDED }
