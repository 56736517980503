import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCommentsRequest } from "../../reducks/comments/actions";

export default function LoadComments() {
  const dispatch = useDispatch();
  const target_data_name = useSelector((state: any) => state.target_data.target_data_name);
  const company_id = useSelector((state: any) => state.user.company_id);
  const user_name = useSelector((state: any) => state.user.name);
  const selected_data_config_data_type = useSelector((state: any) => state.raw_data.selected_data_config.data_type);
  const selected_data_config_data_span = useSelector((state: any) => state.raw_data.selected_data_config.data_span);
  const custom_span_start_time = useSelector((state: any) => state.raw_data.selected_data_config.custom_span_start_time);
  const custom_span_end_time = useSelector((state: any) => state.raw_data.selected_data_config.custom_span_end_time);

  useEffect(() => {
    dispatch(getCommentsRequest(target_data_name, company_id, user_name, selected_data_config_data_type, selected_data_config_data_span, custom_span_start_time, custom_span_end_time));
  }, [dispatch, company_id, user_name, target_data_name, selected_data_config_data_type, selected_data_config_data_span, custom_span_start_time, custom_span_end_time]);
  return <></>;
}
