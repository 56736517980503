import { UPDATE_DISPALY_TYPE } from "./types";
import { RESET_ALL } from "../common/types";

export interface Update_DisplayType {
	type: typeof UPDATE_DISPALY_TYPE;
	payload: { displayType: "line" | "bar" };
}
export function update_displayType(displayType: "line" | "bar"): Update_DisplayType {
	return {
		type: UPDATE_DISPALY_TYPE,
		payload: { displayType },
	};
}

export type ActionProps =  Update_DisplayType;