import rsf from "../rsf";
import { call, put, takeLatest } from "redux-saga/effects";
// import { GET_MOVING_AVERAGE_REQUESTED, UPDATE_MOVING_AVERAGE_REQUESTED, UPDATE_MOVING_AVERAGE_SUCCEEDED, UPDATE_MOVING_AVERAGE_FAILED, GET_WEEK_START_DATE_REQUESTED, UPDATE_WEEK_START_DATE_REQUESTED, GET_WEEK_START_DATE_SUCCEEDED, GET_WEEK_START_DATE_FAILED, UPDATE_LAYOUT_SUCCEEDED, UPDATE_WEEK_START_DATE_FAILED } from "../reducks/config/types"
import {
  UPDATE_LAYOUT_REQUEST,
  UPDATE_LAYOUT_SUCCEEDED,
  UPDATE_LAYOUT_FAILED,
  GET_LAYOUT_REQUESTED,
  GET_LAYOUT_SUCCEEDED,
  GET_LAYOUT_FAILED
} from "../reducks/grid/gridTypes";

// グラフを消去したときもこのupdateLayoutが動く
function* updateLayout(action: any) {
  try {
    console.log("action in updateLayout", action);
    yield call(rsf.firestore.updateDocument, `companies/${action.company_id}`, {
      gridLayout: {
        content: action.content
      }
    });
    yield put({
      type: UPDATE_LAYOUT_SUCCEEDED,
      message: "ダッシュボードが更新されました"
    });
  } catch (e) {
    yield put({ type: UPDATE_LAYOUT_FAILED, message: e.message });
  }
}

// ログイン時最初の一回目にレイアウトを取得するのはsessionsSagaに書いてあります。
// これは別のページに行って戻ってきたとき用
function* getLayout(action: any) {
  console.log(action.company_id);
  try {
    const snapshot = yield call(
      rsf.firestore.getDocument,
      `companies/${action.company_id}`
    );
    const company = snapshot.data();
    const gridLayout = company.gridLayout;
    yield put({ type: GET_LAYOUT_SUCCEEDED, gridLayout: gridLayout });
  } catch (e) {
    yield put({ type: GET_LAYOUT_FAILED, message: e.message });
  }
}

const gridSagas = [
  takeLatest(UPDATE_LAYOUT_REQUEST, updateLayout),
  takeLatest(GET_LAYOUT_REQUESTED, getLayout)
];

export default gridSagas;
