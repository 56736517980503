import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import open from "../../images/icons/arrow_down.svg";
import close from "../../images/icons/arrow_up.svg";

interface Props {
  handlePathChange?: () => void;
}

const UploadNavCard = (props: Props) => {
  const location = useLocation();
  const pathName = location.pathname;
  const [isClientOpen, setIsClientOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isLeadOpen, setIsLeadOpen] = useState(false);
  const [isFunnelOpen, setIsFunnelOpen] = useState(false);
  const [isRevenueOpen, setIsRevenueOpen] = useState(false);
  const [isCostOpen, setIsCostOpen] = useState(false);
  const [isSettingOpen, setIsSettingOpen] = useState(false);
  const [isSettingOpenGD, setIsSettingOpenGD] = useState(false);
  const [isSettingOpenAD, setIsSettingOpenAD] = useState(false);

  const handleResetFormData = () => {
    props.handlePathChange && props.handlePathChange();
  };

  return (
    <UploadNav>
      {/* データ関連リンク */}
      <UploadNavList>
        <UploadNavItem onClick={handleResetFormData}>
          <Link
            className={pathName === "/data/data-sync" ? "current" : ""}
            to="/data/data-sync"
          >
            同期設定
          </Link>
        </UploadNavItem>
      </UploadNavList>

      <UploadNavList>
        {/* マスター */}
        <UploadNavItem>
          <Link
            className={pathName === "/data/upload-history" ? "current" : ""}
            to="/data/upload-history"
          >
            更新履歴
          </Link>
        </UploadNavItem>
        <UploadNavItem style={{ display: "flex" }}>
          {/* <img src={isSettingOpen ? `${close}` : `${open}`} alt="" /> */}
          <p onClick={() => setIsSettingOpen(!isSettingOpen)}>マスターデータ</p>
        </UploadNavItem>
        {/* <FoldingList style={{ display: isSettingOpen ? "block" : "none" }}> */}
        {/* <FoldingItem onClick={handleResetFormData}>
            <Link
              className={pathName === "/data/master-cost" ? "current" : ""}
              to="/data/master-cost"
            >
              M_コスト
            </Link>
          </FoldingItem> */}
        <FoldingItem onClick={handleResetFormData}>
          <Link
            className={
              pathName === "/data/master-service-product" ? "current" : ""
            }
            to="/data/master-service-product"
          >
            商品・サービス
          </Link>
        </FoldingItem>
        <FoldingItem onClick={handleResetFormData}>
          <Link
            className={pathName === "/data/master-funnel" ? "current" : ""}
            to="/data/master-funnel"
          >
            ファネル構成
          </Link>
        </FoldingItem>
        <FoldingItem onClick={handleResetFormData}>
          <Link
            className={pathName === "/data/master-filter" ? "current" : ""}
            to="/data/master-filter"
          >
            フィルター
          </Link>
        </FoldingItem>
        <FoldingItem onClick={handleResetFormData}>
          <Link
            className={
              pathName === "/data/master-leadsource-manager" ? "current" : ""
            }
            to="/data/master-leadsource-manager"
          >
            リードソース・担当者
          </Link>
        </FoldingItem>
        <FoldingItem onClick={handleResetFormData}>
          <Link
            className={
              pathName === "/data/master-leadsource-opportunity-status"
                ? "current"
                : ""
            }
            to="/data/master-leadsource-opportunity-status"
          >
            リード・商談ステータス
          </Link>
        </FoldingItem>
        <FoldingItem onClick={handleResetFormData}>
          <Link
            className={pathName === "/data/cost" ? "current" : ""}
            to="/data/cost"
          >
            コスト構成
          </Link>
        </FoldingItem>
        {/* </FoldingList> */}
        <UploadNavItem style={{ display: "flex" }}>
          {/* <img src={isSettingOpenGD ? `${close}` : `${open}`} alt="" /> */}
          <p onClick={() => setIsSettingOpenGD(!isSettingOpenGD)}>目標データ</p>
        </UploadNavItem>

        {/* <FoldingList style={{ display: isSettingOpenGD ? "block" : "none" }}> */}
        <FoldingItem onClick={handleResetFormData}>
          <Link
            className={pathName === "/data/mrr-objective" ? "current" : ""}
            to="/data/mrr-objective"
          >
            重要指標
          </Link>
        </FoldingItem>
        <FoldingItem onClick={handleResetFormData}>
          <Link
            className={pathName === "/data/cost-objective" ? "current" : ""}
            to="/data/cost-objective"
          >
            コスト
          </Link>
        </FoldingItem>
        {/* </FoldingList> */}

        <UploadNavItem style={{ display: "flex" }}>
          {/* <img src={isSettingOpenAD ? `${open}` : `${close}`} alt="" /> */}
          <p onClick={() => setIsSettingOpenAD(!isSettingOpenAD)}>実績データ</p>
        </UploadNavItem>

        {/* <FoldingList style={{ display: isSettingOpenAD ? "block" : "none" }}> */}
        <FoldingItem onClick={handleResetFormData}>
          <Link
            className={pathName === "/data/revenue-actual" ? "current" : ""}
            to="/data/revenue-actual"
          >
            売上
          </Link>
        </FoldingItem>
        <FoldingItem onClick={handleResetFormData}>
          <Link
            className={pathName === "/data/cost-actual" ? "current" : ""}
            to="/data/cost-actual"
          >
            コスト
          </Link>
        </FoldingItem>
        <FoldingItem onClick={handleResetFormData}>
          <Link
            className={pathName === "/data/client" ? "current" : ""}
            to="/data/client"
          >
            クライアント
          </Link>
        </FoldingItem>
        <FoldingItem onClick={handleResetFormData}>
          <Link
            className={pathName === "/data/lead-opportunity" ? "current" : ""}
            to="/data/lead-opportunity"
          >
            リード・商談履歴
          </Link>
        </FoldingItem>
        <FoldingItem onClick={handleResetFormData}>
          <Link
            className={pathName === "/data/lead-event" ? "current" : ""}
            to="/data/lead-event"
          >
            行動履歴
          </Link>
        </FoldingItem>
        {/* </FoldingList> */}
      </UploadNavList>
    </UploadNav>
  );
};

export default UploadNavCard;

//UploadNav
const UploadNav = styled.div`
  width: 240px;
`;
//UploadNavList
const UploadNavList = styled.ul`
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
`;
//UploadNavItem
const UploadNavItem = styled.li`
  display: block;
  font-size: 12px;
  line-height: 24px;
  width: 100%;
  align-items: center;
  img {
    display: block;
    width: 12px;
    height: 12px;
    position: relative;
    top: 3px;
  }
  p {
    color: #435878;
    font-weight: bold;
    padding-top: 8px;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
  }
  a {
    color: #0066ff;
    text-decoration: none;
    padding: 4px 8px 6px;
    box-sizing: border-box;
    display: block;
    &:hover,
    &.current {
      font-weight: bold;
      background-color: #fff;
    }
  }
`;

const FoldingList = styled(UploadNavList)`
  padding-left: 8px;
`;

const FoldingItem = styled(UploadNavItem)``;
