import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import GlobalNav from "../../Layout/GlobalNav";
import PageHeaderRaw from "../../Layout/PageHeader";
import ConfigNavCard from "../ConfigNavCard";
import { getBillingAccountRequest } from "../../../reducks/billingAccount/actions";
import { getBillingsRequest } from "../../../reducks/billings/actions";
import MenuButton from "./MenuButton";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      backgroundColor: "#F4F4F4"

    },
    configCard: {
      display: "flex",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    }
  })
);

// Container
const Container = styled.div`
  display: flex;
`;

// BillingContent
const BillingContent = styled.div`
  width: 100%;
  height: 100vh;
  padding: 28px 40px;
  box-sizing: border-box;
  background-color: #fff;
`;
// BillingHeading
const BillingHeading = styled.h2`
  font-size: 18px;
  font-weight: bold;
  line-height: 27px;
  color: #283f46;
  margin-bottom: 18px;
`;

const ContentCard = styled.div`
  display: flex;
  justify-content: space-between;
  color: #435878;
  border-bottom: 0.5px solid #F0F0F0;
  padding-bottom:16px;
  margin-bottom: 16px;
  position: relative;
  &:last-of-type {
    border-bottom: none;
  }
`

const ContentDetail = styled.div`
  display: block;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  width: 504px;
  padding: 14px 20px;
  margin: 0;
`

const CompanyName = styled.h3`
  font-size: 16px;
  line-height: 19px;
  margin: 0 0 16px 0;
`

const ContentHeading = styled.h2`
  font-size: 16px;
  margin: 0;
`

const ContentText = styled.p`
  font-size: 12px;
  margin:0 0 8px 0;
`

const HistoryItem = styled.li`
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  list-style: none;
  border-bottom: 0.5px solid #F0F0F0;
  padding-bottom: 16px;
  margin-bottom: 16px;
  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`

const EditLink = styled(Link)({
  color: '#435878',
  textDecoration: 'none',
  width: '100%'
}) 

const BillingSetting: React.FC = (props:any) => {
  const location = useLocation();
	const pathname = location.pathname;
  // GA計測タグ
	useEffect(() => {
		document.title = '請求管理'; // ページタイトルを変更
		window.gtag('config', 'G-YXXD65BEJ0', {
			'page_path': pathname // 任意のパス（あるいはprops.location.pathnameなど）
		});
	});

  const classes = useStyles();

  // Storeからログインユーザーの企業IDを取得
  const company_id = useSelector((state: any) => state.user.company_id);

  // Storeから請求履歴情報を取得
  const billings = useSelector((state: any) => state.billings);

  // Storeから決済方法、請求先情報を取得
  const billingAccountId = useSelector((state: any) => state.user.billingAccountId);
  const billingAccount = useSelector((state: any) => state.billingAccount);

  const dispatch = useDispatch();
  // コンポーネントマウント時に決済方法、請求先を取得
  useEffect(() => {
    dispatch(getBillingAccountRequest(company_id, billingAccountId));
  }, [billingAccountId, company_id, dispatch]);

  // 請求履歴取得
  useEffect(() => {
    dispatch(getBillingsRequest(company_id));
  }, [company_id, dispatch]);

  return (
    <Container>
      <GlobalNav />

      <main className={classes.content}>
        <PageHeaderRaw page_title="管理" />

        <div className={classes.configCard}>
          <ConfigNavCard />

          <BillingContent>
            <BillingHeading>請求管理</BillingHeading>

            {/* メインコンテンツ */}
            <div>
              {/* 決済方法 */}
              <ContentCard>
                <ContentHeading>決済方法</ContentHeading>
                <ContentDetail>
                  <MenuButton
                    value={<EditLink to="/config/billing-setting/edit-payment-method">変更する</EditLink>}
                  />
                  <ContentText>{billingAccount.credit_card_type}</ContentText>
                  <ContentText>カード番号下４桁：{billingAccount.credit_card_last4}</ContentText>
                  {/* ツールチップ想定 */}
                </ContentDetail>
              </ContentCard>

              {/* 請求先 */}
              <ContentCard>
                <ContentHeading>請求先</ContentHeading>
                <MenuButton value={<EditLink to={`/config/billing-setting/edit-billing-account`}>編集する</EditLink>}/>

                <ContentDetail>
                  <CompanyName>{billingAccount.company_name}</CompanyName>
                  <ContentText>
                    〒{billingAccount.postal_code} {billingAccount.accress_1}
                    {billingAccount.accress_2}
                  </ContentText>
                  <ContentText>{billingAccount.position}</ContentText>
                  <ContentText>
                    {billingAccount.lastName_jp} {billingAccount.firstName_jp}
                  </ContentText>
                  <ContentText>{billingAccount.email}</ContentText>
                  <ContentText>{billingAccount.tel}</ContentText>

                  {/* ツールチップ想定 */}
                  <div>
                    <Link to="/config/billing-setting/edit-billing-account">{props.value}</Link>
                  </div>
                </ContentDetail>
              </ContentCard>

              {/* 請求履歴 */}
              <ContentCard>
                <ContentHeading>請求履歴</ContentHeading>
                <ContentDetail as="ul">
                  {billings.map((billing: any) => (
                    <HistoryItem>
                      <div className="left">
                        <span>{billing.time}</span>
                        <span>{billing.name}</span>
                      </div>
                      <div className="right">
                        <span>{billing.amount.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })} (税別)</span>
                      </div>
                    </HistoryItem>
                  ))}
                </ContentDetail>
              </ContentCard>
            </div>
          </BillingContent>
        </div>
      </main>
    </Container>
  );
};

export default BillingSetting;
