import React from 'react';
import styled from 'styled-components';

const Error = styled.p`
  color: #AF002A;
  font-size: 10px;
  position: absolute;
  `


const ErrorText = () => {
  return (
    <Error>エラーエラーエラーエラーエラー</Error>
  );
};

export default ErrorText;