import React,{useState} from 'react';
import styled from 'styled-components';
import { addCommentRequest, closeCommentAddWindow } from '../../reducks/comments/actions'
import closeBtnSmall from "../../images/icons/close_large.svg"
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import LoadComments from './LoadComments'

const AddCommentWindow = (props: any) => {
  const dispatch = useDispatch()

  const [content, setContent] = useState('');

  const isAddCommentWindowDisplay = useSelector((state: any) => state.comments.commentAddWindow.display);
  const result_at_timestamp = useSelector((state:any)=> state.comments.commentAddWindow.result_at_timestamp)
  const target_data_name = useSelector((state:any)=> state.comments.commentAddWindow.target_data_name)
  const company_id = useSelector((state:any)=> state.comments.commentAddWindow.company_id)
  const user_name = useSelector((state:any)=> state.comments.commentAddWindow.user_name)
  const selected_data_config_data_type = useSelector((state:any)=> state.comments.commentAddWindow.selected_data_config_data_type)
  const selected_data_config_data_span = useSelector((state:any)=> state.comments.commentAddWindow.selected_data_config_data_span)
  const custom_span_start_time = useSelector((state:any)=> state.comments.commentAddWindow.custom_span_start_time)
  const custom_span_end_time = useSelector((state:any)=> state.comments.commentAddWindow.custom_span_end_time)

  const load_comments = useSelector((state:any)=> state.comments.load_comments)

  const company = useSelector((state:any)=> state.company)


  const {register, handleSubmit, errors} = useForm({reValidateMode: 'onBlur'})
  // const openAddComment = () => {
  //   setDisplayCommentSection(!displayCommentSection)
  // }
  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(e.target.value)
  }

  const handleAddComment = () => {
    dispatch(
      addCommentRequest(
        result_at_timestamp,
        target_data_name,
        company_id,
        user_name,
        selected_data_config_data_type,
        selected_data_config_data_span,
        custom_span_start_time,
        custom_span_end_time,
        content
      )
    )
    setContent('');
  }


  return (
    <AddCommentWindowSC className={isAddCommentWindowDisplay ? 'active AddCommentWindowSC' : 'AddCommentWindowSC'}>

      {
        // load_comments && 
        <LoadComments />
      }

      {/* コメントセクション */}
      <CommentContainer>
        <CommentHeader>
          <Close 
            onClick={ () => dispatch(closeCommentAddWindow())}
          />
        </CommentHeader>
        <form 
          action="submit" 
          onSubmit={handleSubmit(handleAddComment)}
          style={{'position': 'relative'}}
        >
          <InputBox 
            name="comment" 
            id="comment" 
            cols={30} 
            rows={10}
            ref={register({required: true})}
            value={content}
            onChange={handleTextChange}
          ></InputBox>
          {errors.comment && <Error>コメントが入力されていません</Error>}

          <AddBtn type="submit">追加</AddBtn>
        </form>
      </CommentContainer>
    </AddCommentWindowSC>
  );
};

export default AddCommentWindow;

const AddCommentWindowSC = styled.div`
  display: none;
  position: absolute;
  top: 50px;
  right: 0;
  left: 0;
  width: 216px;
  border-radius: 2px;
  background-color: #fff;
  padding: 8px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
  margin: 0 auto;
  z-index: 3;
  &.active {
    display: block;
  }
`

//コメント小窓
const CommentContainer = styled.div`
`

const Close = styled.button`
  display: inline-block;
  background-image: url(${closeBtnSmall});
  background-size: cover;
  position: relative;
  width: 16px;
  height: 16px;
`
const CommentHeader = styled.div`
  position: relative;
  margin-bottom: 8px;
  text-align: right;
`
const InputBox = styled.textarea`
  width: 200px;
  height: 105px;
  font-size: 10px;
  color: #35455D;
  resize: none;
  border: 1px solid #C3CEDF;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 8px;
`

const AddBtn = styled.button`
  width: 96px;
  height: 24px;
  background: #0066FF;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  color: #fff;
  display: block;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24); 
  border-radius: 4px;
  text-align: center;
  margin-left: auto;
  margin-right: 0px;
`

const Error = styled.p`
  color: #AF002A;
  font-size: 8px;
  position: relative;
  bottom: 8px;
`
