import React, { useCallback } from "react";
import FileSaver from "file-saver";
import {
  updateDownloadGraphStatus,
} from "../../../reducks/download/actions";
import {
  updateDownloadCsvTarget,
} from "../../../reducks/download/actions";
import { useCurrentPng } from "recharts-to-png";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import { yAxisFormatter } from "../../Util/yAxisFormatter";
import { unitSwitcher } from "../../Util/unitSwitcher";
import GraphDataDownload from "../../Parts/GraphDataDownload";
interface Props {
  funnel_summary_array: any;
  resultSpan: string;
  moving_average_graph_switch: any;
}

const CustomTooltipBar = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <BarGraphTooltip>
        <BarGraphTooltipHeading>{label}</BarGraphTooltipHeading>
        {payload[0] && (
          <>
            <BarGraphTooltipLabel>移行率 実績</BarGraphTooltipLabel>
            <BarGraphTooltipData>
              {`${unitSwitcher(payload[0].value, "rate")}`}
            </BarGraphTooltipData>
          </>
        )}
        {payload[1] && (
          <>
            <BarGraphTooltipHR />
            <BarGraphTooltipLabel>移行率 目標</BarGraphTooltipLabel>
            <BarGraphTooltipData>
              {`${unitSwitcher(payload[1].value, "rate")}`}
            </BarGraphTooltipData>
          </>
        )}
        {payload[2] && (
          <>
            <BarGraphTooltipHR />
            <BarGraphTooltipLabel>リードタイム 実績</BarGraphTooltipLabel>
            <BarGraphTooltipData>
              {`${unitSwitcher(payload[2].value, "lead_time")}`}
            </BarGraphTooltipData>
          </>
        )}
      </BarGraphTooltip>
    );
  }

  return null;
};

export interface TransitionRateLeadTime {
  funnel_name: string;
  lead_transition_rate_total: number;
  lead_transition_rate_objective: number;
  lead_transition_rate_rate: number;
  lead_transition_rate_difference: number;
  lead_transition_rate_last_year_comparison_rate: number;
  lead_time_total: number;
}

export interface FunnelSummaryGraphTransitionRateLeadTimeProps {
  transition_rate_lead_time_array: TransitionRateLeadTime[];
}
const FunnelSummaryGraphTransitionRateLeadTime: React.FC<FunnelSummaryGraphTransitionRateLeadTimeProps> =
  ({ transition_rate_lead_time_array }) => {
    const dispatch = useDispatch();
    
    // Graph Download Section
    const [getAreaPng, { ref: areaRef }] = useCurrentPng();
    const handleAreaDownload = useCallback(async () => {
      dispatch(
        updateDownloadGraphStatus(0)
      )
      const png = await getAreaPng();
      if (png) {
        FileSaver.saveAs(png, "graph.png");
      }
    }, [getAreaPng, dispatch]);

    function handleGraphDataDownloadClick(e:any, target:string) {
      if(e.target.innerText==='グラフ Export'){
          handleAreaDownload();
      }
      dispatch(
        updateDownloadCsvTarget("/"+target)
      )
    }
    
    return (
      <div>
        <FunnelSummaryGraphTransitionRateLeadTimeHeadingGraphDownloadWrapper>
          <FunnelSummaryGraphTransitionRateLeadTimeHeading>
            移行率・リードタイム
          </FunnelSummaryGraphTransitionRateLeadTimeHeading>
          <div onClick={(e) => {handleGraphDataDownloadClick(e, 'lead_time')}}>
            <GraphDataDownload />
          </div>
        </FunnelSummaryGraphTransitionRateLeadTimeHeadingGraphDownloadWrapper>
        {transition_rate_lead_time_array.length > 0 ? (
          <FunnelSummaryGraph>
            <ResponsiveContainer>
              <ComposedChart
                width={730}
                height={250}
                data={transition_rate_lead_time_array}
                ref={areaRef}
              >
                <XAxis
                  dataKey="funnel_name"
                  tickLine={false}
                  tickSize={10}
                  minTickGap={20}
                  tick={{ fontSize: 10 }}
                />

                <YAxis
                  type="number"
                  tickCount={10}
                  interval={0}
                  tickMargin={10}
                  tickLine={false}
                  yAxisId={0}
                  tickFormatter={tick =>
                    yAxisFormatter(tick, "transition_rate")
                  }
                />
                <YAxis
                  type="number"
                  orientation="right"
                  tickCount={10}
                  interval={0}
                  tickMargin={10}
                  tickLine={false}
                  yAxisId={1}
                  tickFormatter={tick => tick.toLocaleString()}
                />

                <Legend
                  verticalAlign={"top"}
                  align={"left"}
                  width={432}
                  height={32}
                  margin={{ right: 100 }}
                  iconSize={12}
                  wrapperStyle={{ fontSize: "12px", color: "#283f46" }}
                />

                <CartesianGrid stroke="#f5f5f5" />

                <Bar
                  name="実績"
                  dataKey="lead_transition_rate_total"
                  barSize={32}
                  fill="#0066ff"
                  yAxisId={0}
                />
                <Bar
                  name="目標"
                  dataKey="lead_transition_rate_objective"
                  barSize={32}
                  fill="#99C2FF"
                  yAxisId={0}
                />

                <Line
                  name="リードタイム"
                  dataKey="lead_time_total"
                  stroke="#0066ff"
                  yAxisId={1}
                  strokeWidth={3}
                />

                <Tooltip content={<CustomTooltipBar />} />
              </ComposedChart>
            </ResponsiveContainer>
          </FunnelSummaryGraph>
        ) : (
          <NoData>グラフを表示できません</NoData>
        )}
      </div>
    );
  };
export default FunnelSummaryGraphTransitionRateLeadTime;

// Graph
const FunnelSummaryGraph = styled.div`
  width: 100%;
  height: 272px;
  padding: 0 10px;
  box-sizing: border-box;

  .recharts-surface {
    position: relative;
    top: -1px;
    overflow: visible;
  }
  .recharts-cartesian-axis-tick {
    width: 100px !important;
    .recharts-cartesian-axis-tick-value {
      font-size: 10px;
      color: #435878;
    }
  }
`;
const NoData = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #435878;
  margin-bottom: 8px;
  &.main {
    margin-bottom: 8px;
  }
`;

const BarGraphTooltip = styled.div`
  background: #fff;
  padding: 8px 9px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid #e2e9f3;
  border-radius: 4px;
  max-width: 200px;
`;

// 日付
const BarGraphTooltipHeading = styled.h3`
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  color: #435878;
  margin-bottom: 5px;
`;

// 当月 | 目標
const BarGraphTooltipLabel = styled.p`
  width: 50%;
  font-size: 10px;
  color: #435878;
  margin-bottom: 3px;
`;
// データ
const BarGraphTooltipData = styled.p`
  width: 50%;
  text-align: right;
  font-size: 10px;
  font-weight: bold;
  color: #435878;
  margin-bottom: 3px;
`;
// 仕切り線
const BarGraphTooltipHR = styled.hr`
  width: 100%;
  height: 1px;
  color: lightgray;
  margin-bottom: 3px;
`;

const FunnelSummaryGraphTransitionRateLeadTimeHeading = styled.h2`
  color: #283f46;
  font-weight: bold;
  font-size: 22px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const FunnelSummaryGraphTransitionRateLeadTimeHeadingGraphDownloadWrapper = styled.div({
  flexGrow: 1,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});
