import React from 'react';
import styled from 'styled-components';

const Red = styled.p`
  display: inline;
  color: #AF002A;
  font-size: 14px;
  line-height: 21px;

`

const Require = () => {
  return (
    <Red>【必須】</Red>
  );
};

export default Require;