import React from "react";
import "./App.css";
import { ConnectedRouter } from "connected-react-router";
import { useSelector } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import LoadingCover from "./components/LoadingCover";
import Metrics from "./components/Metrics/Metrics";
import SignIn from "./components/Auth/SignIn";
import ForgetPassword from "./components/Auth/ForgetPassword";

import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import NotFound from "./components/Layout/NotFound";

// import Top from "./components/Top/Top"
import TopNew from "./components/Top/TopNew";
import FunnelsSummary from "./components/Funnels/FunnelsSummary";
import FunnelDetail from "./components/Funnels/FunnelDetail";
import Forecasts from "./components/Forecasts/Forecasts";

import DataUpload from "./components/Data";
import DataSync from "./components/Data/DataSync";
import UploadPage from "./components/Data/UploadPage";

// 管理画面
import IndicatorSetting from "./components/Config/Indicators/IndicatorSetting";

import MemberSetting from "./components/Config/Members/MemberSetting";
import AddMember from "./components/Config/Members/AddMember";
import EditMember from "./components/Config/Members/EditMember";
import DeleteMember from "./components/Config/Members/DeleteMember";
import TransferOwnership from "./components/Config/Members/TransferOwnership";

// 請求管理
import BillingSetting from "./components/Config/Billings/BillingSetting";
import BillingAccount from "./components/Config/Billings/BillingAccount";
import PaymentMethod from "./components/Config/Billings/PaymentMethod";

import PlanSetting from "./components/Config/Plans/PlanSetting";

import InstallInquiry from "./components/Landing/InstallInquiry";
import ErrorBoundary from "./components/Error/ErrorBoundary";
import ErrorPage from "./components/Error/ErrorPage";

function App({ history }: { history: any }) {
  const loggedIn = useSelector((state: any) => state.session.loggedIn);
  if (loggedIn === true) {
    return (
      <ConnectedRouter history={history}>
        <div className="App">
          <NotificationContainer />
          <LoadingCover />
          <Switch>
            {/* ランディングページ */}
            {/* <Route path="/landing" component={Landing} /> */}
            {/* ログイン */}
            <Route exact path="/forget-password" component={ForgetPassword} />
            <Route path="/signin">
              {loggedIn ? <Redirect to="/" /> : <SignIn />}
            </Route>

            {/* TOPページ */}
            {/* <Route exact path="/" component={Top} /> */}
            <Route exact path="/">
              <ErrorBoundary>
                <TopNew />
              </ErrorBoundary>
            </Route>

            {/* 重要指標 */}
            <Route exact path="/metrics">
              <ErrorBoundary>
                <Metrics />
              </ErrorBoundary>
            </Route>
            <Route exact path="/metrics/revenue">
              <ErrorBoundary>
                <Metrics />
              </ErrorBoundary>
            </Route>
            <Route exact path="/metrics/mrr">
              <ErrorBoundary>
                <Metrics />
              </ErrorBoundary>
            </Route>
            <Route exact path="/metrics/unit-economics">
              <ErrorBoundary>
                <Metrics />
              </ErrorBoundary>
            </Route>
            <Route exact path="/metrics/cac">
              <ErrorBoundary>
                <Metrics />
              </ErrorBoundary>
            </Route>
            <Route exact path="/metrics/payback-period">
              <ErrorBoundary>
                <Metrics />
              </ErrorBoundary>
            </Route>
            <Route exact path="/metrics/ltv">
              <ErrorBoundary>
                <Metrics />
              </ErrorBoundary>
            </Route>
            <Route exact path="/metrics/arpu">
              <ErrorBoundary>
                <Metrics />
              </ErrorBoundary>
            </Route>
            <Route exact path="/metrics/churn-rate">
              <ErrorBoundary>
                <Metrics />
              </ErrorBoundary>
            </Route>
            <Route exact path="/metrics/mrr-churn-rate">
              <ErrorBoundary>
                <Metrics />
              </ErrorBoundary>
            </Route>
            <Route exact path="/metrics/nrr">
              <ErrorBoundary>
                <Metrics />
              </ErrorBoundary>
            </Route>
            <Route exact path="/metrics/grr">
              <ErrorBoundary>
                <Metrics />
              </ErrorBoundary>
            </Route>
            <Route exact path="/metrics/quick-ratio">
              <ErrorBoundary>
                <Metrics />
              </ErrorBoundary>
            </Route>
            <Route exact path="/metrics/acv-cumulative">
              <ErrorBoundary>
                <Metrics />
              </ErrorBoundary>
            </Route>
            <Route exact path="/metrics/acv-new">
              <ErrorBoundary>
                <Metrics />
              </ErrorBoundary>
            </Route>
            <Route exact path="/metrics/conversion_rate">
              <ErrorBoundary>
                <Metrics />
              </ErrorBoundary>
            </Route>
            <Route exact path="/metrics/cpa">
              <ErrorBoundary>
                <Metrics />
              </ErrorBoundary>
            </Route>
            <Route exact path="/metrics/client-churned">
              <ErrorBoundary>
                <Metrics />
              </ErrorBoundary>
            </Route>
            <Route exact path="/metrics/account-churned">
              <ErrorBoundary>
                <Metrics />
              </ErrorBoundary>
            </Route>
            <Route exact path="/metrics/revenue-churned">
              <ErrorBoundary>
                <Metrics />
              </ErrorBoundary>
            </Route>
            <Route exact path="/metrics/new-customer">
              <ErrorBoundary>
                <Metrics />
              </ErrorBoundary>
            </Route>
            <Route exact path="/metrics/active-customer">
              <ErrorBoundary>
                <Metrics />
              </ErrorBoundary>
            </Route>

            {/* ファネル */}
            <Route exact path="/funnels/summary">
              <ErrorBoundary>
                <FunnelsSummary />
              </ErrorBoundary>
            </Route>
            <Route exact path="/funnels/:funnel">
              <ErrorBoundary>
                <FunnelDetail />
              </ErrorBoundary>
            </Route>

            {/* Forecasts */}
            <Route exact path="/forecasts">
              <ErrorBoundary>
                <Forecasts />
              </ErrorBoundary>
            </Route>

            {/* データ */}
            <Route exact path="/data">
              <ErrorBoundary>
                <DataUpload />
              </ErrorBoundary>
            </Route>
            <Route exact path="/data/data-sync">
              <ErrorBoundary>
                <DataSync />
              </ErrorBoundary>
            </Route>
            <Route exact path="/data/master-funnel">
              <ErrorBoundary>
                <UploadPage />
              </ErrorBoundary>
            </Route>
            <Route exact path="/data/master-leadsource-manager">
              <ErrorBoundary>
                <UploadPage />
              </ErrorBoundary>
            </Route>
            <Route exact path="/data/master-leadsource-opportunity-status">
              <ErrorBoundary>
                <UploadPage />
              </ErrorBoundary>
            </Route>
            <Route exact path="/data/master-filter">
              <ErrorBoundary>
                <UploadPage />
              </ErrorBoundary>
            </Route>
            <Route exact path="/data/master-service-product">
              <ErrorBoundary>
                <UploadPage />
              </ErrorBoundary>
            </Route>
            <Route exact path="/data/master-cost">
              <ErrorBoundary>
                <UploadPage />
              </ErrorBoundary>
            </Route>

            <Route exact path="/data/client">
              <ErrorBoundary>
                <UploadPage />
              </ErrorBoundary>
            </Route>
            <Route exact path="/data/lead-opportunity">
              <ErrorBoundary>
                <UploadPage />
              </ErrorBoundary>
            </Route>
            <Route exact path="/data/lead-event">
              <ErrorBoundary>
                <UploadPage />
              </ErrorBoundary>
            </Route>
            <Route exact path="/data/revenue-actual">
              <ErrorBoundary>
                <UploadPage />
              </ErrorBoundary>
            </Route>
            <Route exact path="/data/revenue-objective">
              <ErrorBoundary>
                <UploadPage />
              </ErrorBoundary>
            </Route>
            <Route exact path="/data/upload-history">
              <ErrorBoundary>
                <UploadPage />
              </ErrorBoundary>
            </Route>
            <Route exact path="/data/cost-actual">
              <ErrorBoundary>
                <UploadPage />
              </ErrorBoundary>
            </Route>
            <Route exact path="/data/cost-objective">
              <ErrorBoundary>
                <UploadPage />
              </ErrorBoundary>
            </Route>
            <Route exact path="/data/mrr-objective">
              <ErrorBoundary>
                <UploadPage />
              </ErrorBoundary>
            </Route>
            {/**new route for コスト構成 -> /data/cost */}
            <Route exact path="/data/cost">
              <ErrorBoundary>
                <UploadPage />
              </ErrorBoundary>
            </Route>

            {/* 管理ページ */}
            <Route path="/config/indicator-setting">
              <ErrorBoundary>
                <IndicatorSetting />
              </ErrorBoundary>
            </Route>

            {/* メンバー管理 */}
            <Route exact path="/config/member-setting">
              <ErrorBoundary>
                <MemberSetting />
              </ErrorBoundary>
            </Route>
            <Route exact path="/config/member-setting/add-member">
              <ErrorBoundary>
                <AddMember />
              </ErrorBoundary>
            </Route>
            <Route exact path="/config/member-setting/edit-member/:id">
              <ErrorBoundary>
                <EditMember />
              </ErrorBoundary>
            </Route>
            <Route exact path="/config/member-setting/delete-member/:id">
              <ErrorBoundary>
                <DeleteMember />
              </ErrorBoundary>
            </Route>
            <Route exact path="/config/member-setting/transfer-ownership/:id">
              <ErrorBoundary>
                <TransferOwnership />
              </ErrorBoundary>
            </Route>

            {/* 請求管理 */}
            <Route exact path="/config/billing-setting">
              <ErrorBoundary>
                <BillingSetting />
              </ErrorBoundary>
            </Route>
            <Route exact path="/config/billing-setting/edit-billing-account">
              <ErrorBoundary>
                <BillingAccount />
              </ErrorBoundary>
            </Route>
            <Route exact path="/config/billing-setting/edit-payment-method">
              <ErrorBoundary>
                <PaymentMethod />
              </ErrorBoundary>
            </Route>

            {/* プラン管理 */}
            <Route path="/config/plan-setting">
              <ErrorBoundary>
                <PlanSetting />
              </ErrorBoundary>
            </Route>

            {/* エラーページ */}
            <Route exact path="/error" component={ErrorPage} />

            {/* ページが見つからない場合 */}
            <Route component={NotFound} />
          </Switch>
        </div>
      </ConnectedRouter>
    );
  } else {
    return (
      <ConnectedRouter history={history}>
        <div className="App">
          <Switch>
            <Route path="/installinquiry" component={InstallInquiry} />
            <Route path="/signin" component={SignIn} />
            <Route exact path="/forget-password" component={ForgetPassword} />
            <Redirect to="/signin" />
          </Switch>
        </div>
      </ConnectedRouter>
    );
  }
}

export default App;
