import update from 'immutability-helper';
import { SET_FORECASTS, TOGGLE_TIME_SPAN_COMPARISON_FILTER_DISPLAY, GET_RESULTS_FOR_SPAN_COMPARISON_FORECASTS_SUCCEEDED, GET_RESULTS_FOR_SPAN_COMPARISON_FORECASTS_FAILED, GET_RESULTS_REQUESTED_FOR_FORECAST_SUCCEEDED, GET_RESULTS_REQUESTED_FOR_METRICS_FORECAST_SUCCEEDED, GET_RESULTS_FOR_SPAN_COMPARISON_METRICS_FORECASTS_SUCCEEDED, GET_RESULTS_FOR_SPAN_COMPARISON_METRICS_FORECASTS_FAILED, TOGGLE_FORECAST_FILTER, TOGGLE_METRICS_FORECAST_FILTER, UPDATE_FORECAST_SIMULATED_RESULT, UPDATE_METRICS_FORECAST_SIMULATED_RESULT, SET_FORECAST_FILTER, RESET_FORECAST_FILTER } from './types';
import {RESET_ALL} from "../common/types";

import { analytics } from 'firebase';
const today = new Date();
const nextMonth = new Date(new Date().setMonth(new Date().getMonth() + 1));
const twoMonthAgo = new Date(new Date().setMonth(new Date().getMonth() - 2));
const lastMonth = new Date(new Date().setMonth(new Date().getMonth() - 1));
const newLastMonth = new Date(new Date().setMonth(lastMonth.getDate() + 10));

const initialState: any = {
  resultSpan: 'monthly',
  resultMonthly: [],
  resultWeekly: [],
  resultDaily: [],
  firstCalender: {
    startDate: today,
    endDate: nextMonth,
    span: (nextMonth.getTime() - today.getTime()) / (1000 * 3600 * 24),
  },
  secondCalender: {
    startDate: twoMonthAgo,
    endDate: lastMonth,
    span: (lastMonth.getTime() - twoMonthAgo.getTime()) / (1000 * 3600 * 24),
  },
  firstResult: {
    resultMonthly: [],
    resultWeekly: [],
    resultDaily: [],
  },
  secondResult: {
    resultMonthly: [],
    resultWeekly: [],
    resultDaily: [],
  },
  firstMetricsResult: {
    resultMonthly: [],
    resultWeekly: [],
    resultDaily: [],
  },
  secondMetricsResult: {
    resultMonthly: [],
    resultWeekly: [],
    resultDaily: [],
  },
  forecastResult: {
    resultMonthly: [],
    resultWeekly: [],
    resultDaily: [],
  },
  forecastMetricsResult: {
    resultMonthly: [],
    resultWeekly: [],
    resultDaily: [],
  },
  forecastFilter: false,
  metricsForecastFilter: false,
};

const forecastsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_ALL:
      return initialState;

    case SET_FORECASTS:
      // console.log("results daily: ", action)
      if (action.resultSpan === 'monthly') {
        return {
          ...state,
          resultSpan: 'monthly',
          resultMonthly: action.results,
        };
      } else if (action.resultSpan === 'weekly') {
        return {
          ...state,
          resultSpan: 'weekly',
          resultWeekly: action.results,
        };
      } else {
        return {
          ...state,
          resultSpan: 'daily',
          resultDaily: action.results,
        };
      }

    case GET_RESULTS_FOR_SPAN_COMPARISON_FORECASTS_SUCCEEDED:
      // =================================
      // forecastsに必要なデータをresultからぬきだす
      const graphDateList = action.results.map((rd: any) => {
        if (action.spanOrder === 'firstSpan') {
          return {
            first_date: rd.result_at,
          };
        } else {
          return {
            second_date: rd.result_at,
          };
        }
      });

      const graphRevenueList = action.results
        .flatMap((rd: any) => rd.revenue)
        .map((rd: any) => {
          return {
            revenue_total: rd.revenue_total,
            revenue_objective: rd.revenue_objective,
            revenue_difference: rd.revenue_difference,
            revenue_last_year_comparison_total: rd.revenue_last_year_comparison_total,
            revenue_last_year_comparison_rate: rd.revenue_last_year_comparison_rate,
          };
        });

      const graphProfitList = action.results
        .flatMap((rd: any) => rd.profit_operating_profit)
        .map((rd: any) => {
          return {
            profit_operating_profit_total: rd.profit_operating_profit_total,
            profit_operating_profit_objective: rd.profit_operating_profit_objective,
            profit_operating_profit_difference: rd.profit_operating_profit_difference,
            profit_operating_profit_last_year_comparison_total: rd.profit_operating_profit_last_year_comparison_total,
            profit_operating_profit_last_year_comparison_rate: rd.profit_operating_profit_last_year_comparison_rate,
          };
        });

      const graphDateRevenueList = graphDateList.map((data: any, index: number) => {
        return { ...data, ...graphRevenueList[index] };
      });

      const graphDateRevProfitList = graphDateRevenueList.map((data: any, index: number) => {
        return { ...data, ...graphProfitList[index] };
      });

      const forecasts = graphDateRevProfitList.map((data: any, index: number) => {
        if (action.spanOrder === 'firstSpan') {
          return {
            first_forecasts_revenue: data.revenue_total,
            first_forecasts_revenue_objective: data.revenue_objective,
            first_forecasts_revenue_difference: data.revenue_difference,
            first_forecasts_revenue_last_year_comparison_total: data.revenue_last_year_comparison_total,
            first_forecasts_revenue_last_year_comparison_rate: data.revenue_last_year_comparison_rate,
            first_forecasts_profit: data.profit_operating_profit_total,
            first_forecasts_profit_objective: data.profit_operating_profit_objective,
            first_forecasts_profit_difference: data.profit_operating_profit_difference,
            first_forecasts_profit_last_year_comparison_total: data.profit_operating_profit_last_year_comparison_total,
            first_forecasts_profit_last_year_comparison_rate: data.profit_operating_profit_last_year_comparison_rate,
          };
        } else {
          return {
            second_forecasts_revenue: data.revenue_total,
            second_forecasts_revenue_objective: data.revenue_objective,
            second_forecasts_revenue_difference: data.revenue_difference,
            second_forecasts_revenue_last_year_comparison_total: data.revenue_last_year_comparison_total,
            second_forecasts_revenue_last_year_comparison_rate: data.revenue_last_year_comparison_rate,
            second_forecasts_profit: data.profit_operating_profit_total,
            second_forecasts_profit_objective: data.profit_operating_profit_objective,
            second_forecasts_profit_difference: data.profit_operating_profit_difference,
            second_forecasts_profit_last_year_comparison_total: data.profit_operating_profit_last_year_comparison_total,
            second_forecasts_profit_last_year_comparison_rate: data.profit_operating_profit_last_year_comparison_rate,
          };
        }
      });

      const graphForecastsList = graphDateRevProfitList.map((data: any, index: number) => {
        return { ...data, ...forecasts[index] };
      });
      // forecastsに必要なデータをresultからぬきだす
      // =================================

      if (action.resultSpan === 'monthly') {
        // return state
        if (action.spanOrder === 'firstSpan') {
          console.log('first result!');
          console.log('action.results', action.results);
          return {
            ...state,
            firstResult: {
              ...state.firstResult,
              resultDaily: graphForecastsList,
            },
          };
        } else {
          if (action.spanOrder === 'secondSpan') {
            console.log('second result!');
            return {
              ...state,
              secondResult: {
                ...state.secondResult,
                resultDaily: graphForecastsList,
              },
            };
          }
        }
      } else if (action.resultSpan === 'weekly') {
        // return state
        if (action.spanOrder === 'firstSpan') {
          console.log('first result!');
          console.log('action.results', action.results);
          return {
            ...state,
            firstResult: {
              ...state.firstResult,
              resultDaily: graphForecastsList,
            },
          };
        } else {
          if (action.spanOrder === 'secondSpan') {
            console.log('second result!');
            return {
              ...state,
              secondResult: {
                ...state.secondResult,
                resultDaily: graphForecastsList,
              },
            };
          }
        }
      } else {
        if (action.spanOrder === 'firstSpan') {
          console.log('first result!');
          console.log('action.results', action.results);
          return {
            ...state,
            firstResult: {
              ...state.firstResult,
              resultDaily: graphForecastsList,
            },
          };
        } else {
          if (action.spanOrder === 'secondSpan') {
            console.log('second result!');
            return {
              ...state,
              secondResult: {
                ...state.secondResult,
                resultDaily: graphForecastsList,
              },
            };
          }
        }
      }
      break;

    case GET_RESULTS_FOR_SPAN_COMPARISON_FORECASTS_FAILED:
      return {
        ...state,
        message: action.message,
        forecastFilter: false,
      };

    case GET_RESULTS_FOR_SPAN_COMPARISON_METRICS_FORECASTS_SUCCEEDED:
      // =================================
      // forecastsに必要なデータをresultからぬきだす
      const metricsDateList = action.results.map((rd: any) => {
        if (action.spanOrder === 'firstSpan') {
          return {
            first_date: rd.result_at,
          };
        } else {
          return {
            second_date: rd.result_at,
          };
        }
      });

      const metricsList = action.results.map((rd: any) => {
        return {
          cac_total: rd.cac.cac_total,
          cac_objective: rd.cac.cac_objective,
          mrr_total: rd.mrr_total_value.mrr_total_value_total,
          mrr_objective: rd.mrr_total_value.mrr_total_value_objective,
          arpu_total: rd.arpu.arpu_total,
          arpu_objective: rd.arpu.arpu_objective,
          ltv_total: rd.ltv.ltv_total,
          ltv_objective: rd.ltv.ltv_objective,
          unit_economics_total: rd.unit_economics.unit_economics_total,
          unit_economics_objective: rd.unit_economics.unit_economics_objective,
          payback_period_total: rd.payback_period.payback_period_total,
          payback_period_objective: rd.payback_period.payback_period_objective,
          churn_rate_total: rd.churn_rate.churn_rate_total,
          churn_rate_objective: rd.churn_rate.churn_rate_objective,
          nrr_total: rd.nrr.nrr_total,
          nrr_objective: rd.nrr.nrr_objective,
        };
      });
      console.log('metricsList', metricsList);

      const graphMetricsDatas = metricsDateList.map((data: any, index: number) => {
        return {
          ...data,
          ...metricsList[index],
        };
      });
      console.log('graphMetricsDatas', graphMetricsDatas);

      const metricsForecasts = graphMetricsDatas.map((data: any, index: number) => {
        if (action.spanOrder === 'firstSpan') {
          return {
            first_cac_total: data.cac_total,
            first_cac_objective: data.cac_objective,
            first_mrr_total: data.mrr_total,
            first_mrr_objective: data.mrr_objective,
            first_arpu_total: data.arpu_total,
            first_arpu_objective: data.arpu_objective,
            first_ltv_total: data.ltv_total,
            first_ltv_objective: data.ltv_objective,
            first_unit_economics_total: data.unit_economics_total,
            first_unit_economics_objective: data.unit_economics_objective,
            first_payback_period_total: data.payback_period_total,
            first_payback_period_objective: data.payback_period_objective,
            first_churn_rate_total: data.churn_rate_total,
            first_churn_rate_objective: data.churn_rate_objective,
            first_nrr_total: data.nrr_total,
            first_nrr_objective: data.nrr_objective,
          };
        } else {
          return {
            second_cac_total: data.cac_total,
            second_cac_objective: data.cac_objective,
            second_mrr_total: data.mrr_total,
            second_mrr_objective: data.mrr_objective,
            second_arpu_total: data.arpu_total,
            second_arpu_objective: data.arpu_objective,
            second_ltv_total: data.ltv_total,
            second_ltv_objective: data.ltv_objective,
            second_unit_economics_total: data.unit_economics_total,
            second_unit_economics_objective: data.unit_economics_objective,
            second_payback_period_total: data.payback_period_total,
            second_payback_period_objective: data.payback_period_objective,
            second_churn_rate_total: data.churn_rate_total,
            second_churn_rate_objective: data.churn_rate_objective,
            second_nrr_total: data.nrr_total,
            second_nrr_objective: data.nrr_objective,
          };
        }
      });
      console.log('metricsForecasts', metricsForecasts);
      // forecastsに必要なデータをresultからぬきだす
      // =================================

      if (action.resultSpan === 'monthly') {
        // return state
        if (action.spanOrder === 'firstSpan') {
          console.log('first result!');
          console.log('action.results', action.results);
          return {
            ...state,
            firstMetricsResult: {
              ...state.firstMetricsResult,
              resultDaily: metricsForecasts,
            },
          };
        } else {
          if (action.spanOrder === 'secondSpan') {
            console.log('second result!');
            return {
              ...state,
              secondMetricsResult: {
                ...state.secondMetricsResult,
                resultDaily: metricsForecasts,
              },
            };
          }
        }
      } else if (action.resultSpan === 'weekly') {
        // return state
        if (action.spanOrder === 'firstSpan') {
          console.log('first result!');
          console.log('action.results', action.results);
          return {
            ...state,
            firstMetricsResult: {
              ...state.firstMetricsResult,
              resultDaily: metricsForecasts,
            },
          };
        } else {
          if (action.spanOrder === 'secondSpan') {
            console.log('second result!');
            return {
              ...state,
              secondMetricsResult: {
                ...state.secondMetricsResult,
                resultDaily: metricsForecasts,
              },
            };
          }
        }
      } else {
        if (action.spanOrder === 'firstSpan') {
          console.log('first result!');
          console.log('action.results', action.results);
          return {
            ...state,
            firstMetricsResult: {
              ...state.firstMetricsResult,
              resultDaily: metricsForecasts,
            },
          };
        } else {
          if (action.spanOrder === 'secondSpan') {
            console.log('second result!');
            return {
              ...state,
              secondMetricsResult: {
                ...state.secondMetricsResult,
                resultDaily: metricsForecasts,
              },
            };
          }
        }
      }
      break;

    case GET_RESULTS_FOR_SPAN_COMPARISON_METRICS_FORECASTS_FAILED:
      return {
        ...state,
        message: action.message,
      };

    case GET_RESULTS_REQUESTED_FOR_FORECAST_SUCCEEDED:
      const result_span = action.result_span;
      const resultsForForecast = action.resultsForecast; //過去データ
      const realResult = action.resultsReal; //実測値データ

      console.log('resultsForForecast', resultsForForecast);
      console.log('realResult', realResult);

      // ============
      // 月次の時(週次・日次まだ)
      if (result_span === 'monthly') {
        //　実測値データ
        const real_revenue_array = realResult.map((result: any) => {
          const yearMonthDate = new Date(result.result_at.seconds * 1000);
          let year;
          let month: number;
          // 12月だけgetMonth()すると０になってバグるので
          if (yearMonthDate.getMonth() === 0) {
            month = 12;
            year = yearMonthDate.getFullYear() - 1;
          } else {
            // getMonthは実際の月より１少ない値を返すので
            month = yearMonthDate.getMonth() + 1;
            year = yearMonthDate.getFullYear();
          }

          //　グラフ、表内で使うデータをそれぞれ抜き出し
          return {
            revenue_total: result.revenue.revenue_total,
            revenue_objective: result.revenue.revenue_objective,
            revenue_difference: result.revenue.revenue_difference,
            revenue_rate: result.revenue.revenue_rate,
            operating_profit_total: result.profit_operating_profit.profit_operating_profit_total,
            operating_profit_objective: result.profit_operating_profit.profit_operating_profit_objective,
            operating_profit_difference: result.profit_operating_profit.profit_operating_profit_difference,
            operating_profit_rate: result.profit_operating_profit.profit_operating_profit_rate,
            cost_unit_cost_total: result.cost_unit_cost.cost_unit_cost_total,
            cost_unit_cost_objective: result.cost_unit_cost.cost_unit_cost_objective,
            cost_unit_cost_rate: result.cost_unit_cost.cost_unit_cost_rate,
            profit_gross_profit_total: result.profit_gross_profit.profit_gross_profit_total,
            profit_gross_profit_objective: result.profit_gross_profit.profit_gross_profit_objective,
            profit_gross_profit_rate: result.profit_gross_profit.profit_gross_profit_rate,
            cost_svg_cost_total: result.cost_svg_cost.cost_svg_cost_total,
            cost_svg_cost_objective: result.cost_svg_cost.cost_svg_cost_objective,
            cost_svg_cost_rate: result.cost_svg_cost.cost_svg_cost_rate,
            cost_labor_cost_total: result.cost_labor_cost.cost_labor_cost_total,
            cost_labor_cost_objective: result.cost_labor_cost.cost_labor_cost_objective,
            cost_labor_cost_rate: result.cost_labor_cost.cost_labor_cost_rate,
            cost_advertising_cost_total: result.cost_advertising_cost.cost_advertising_cost_total,
            cost_advertising_cost_objective: result.cost_advertising_cost.cost_advertising_cost_objective,
            cost_advertising_cost_rate: result.cost_advertising_cost.cost_advertising_cost_rate,
            result_at: result.result_at,
            year: year,
            month: month,
            date: yearMonthDate.getDate(),
            yearMonthDate: yearMonthDate,
          };
        });

        // 過去データの成長率だけ取り出して配列にする
        const last_year_comparison_rate_array = resultsForForecast.map((result: any) => {
          const last_year_comparison_rate = result.revenue.revenue_last_year_comparison_rate;
          return last_year_comparison_rate;
        });
        // 上で取り出した成長率の配列の中身を合計する（成長率の合計）
        const last_year_comparison_rate_sum = last_year_comparison_rate_array.reduce(function (a: any, x: any) {
          return a + x;
        }, 0);
        // 成長率の合計を、月の数（１年分）で割って平均成長率を出す
        const last_year_comparison_rate_average = last_year_comparison_rate_sum / resultsForForecast.length;
        console.log('last_year_comparison_rate_array', last_year_comparison_rate_array);
        console.log('last_year_comparison_rate_sum', last_year_comparison_rate_sum);
        console.log('last_year_comparison_rate_average', last_year_comparison_rate_average);
        console.log('resultsForForecast.length', resultsForForecast.length);

        // 予測値の配列（１年分）
        // 過去データに上で割り出した成長率をそれぞれ掛ける
        const forecasted_revenue_array = resultsForForecast.map((result: any, index: number) => {
          const yearMonthDate = new Date(result.result_at.seconds * 1000);
          let year;
          let month: number;
          if (yearMonthDate.getMonth() === 0) {
            month = 12;
            year = yearMonthDate.getFullYear();
          } else {
            month = yearMonthDate.getMonth() + 1;
            year = yearMonthDate.getFullYear() + 1;
          }

          return {
            revenue_total: result.revenue.revenue_total * last_year_comparison_rate_average,
            revenue_objective: result.revenue.revenue_objective * last_year_comparison_rate_average,
            revenue_difference: result.revenue.revenue_total * last_year_comparison_rate_average - result.revenue.revenue_objective * last_year_comparison_rate_average,
            revenue_rate: result.revenue.revenue_rate * last_year_comparison_rate_average,
            operating_profit_total: result.profit_operating_profit.profit_operating_profit_total * last_year_comparison_rate_average,
            operating_profit_objective: result.profit_operating_profit.profit_operating_profit_objective * last_year_comparison_rate_average,
            operating_profit_difference: result.profit_operating_profit.profit_operating_profit_total * last_year_comparison_rate_average - result.profit_operating_profit.profit_operating_profit_objective * last_year_comparison_rate_average,
            operating_profit_rate: result.profit_operating_profit.profit_operating_profit_rate * last_year_comparison_rate_average,
            cost_unit_cost_total: result.cost_unit_cost.cost_unit_cost_total * last_year_comparison_rate_average,
            cost_unit_cost_objective: result.cost_unit_cost.cost_unit_cost_objective * last_year_comparison_rate_average,
            cost_unit_cost_rate: result.cost_unit_cost.cost_unit_cost_rate * last_year_comparison_rate_average,
            profit_gross_profit_total: result.profit_gross_profit.profit_gross_profit_total * last_year_comparison_rate_average,
            profit_gross_profit_objective: result.profit_gross_profit.profit_gross_profit_objective * last_year_comparison_rate_average,
            profit_gross_profit_rate: result.profit_gross_profit.profit_gross_profit_rate * last_year_comparison_rate_average,
            cost_svg_cost_total: result.cost_svg_cost.cost_svg_cost_total * last_year_comparison_rate_average,
            cost_svg_cost_objective: result.cost_svg_cost.cost_svg_cost_objective * last_year_comparison_rate_average,
            cost_svg_cost_rate: result.cost_svg_cost.cost_svg_cost_rate * last_year_comparison_rate_average,
            cost_labor_cost_total: result.cost_labor_cost.cost_labor_cost_total * last_year_comparison_rate_average,
            cost_labor_cost_objective: result.cost_labor_cost.cost_labor_cost_objective * last_year_comparison_rate_average,
            cost_labor_cost_rate: result.cost_labor_cost.cost_labor_cost_rate * last_year_comparison_rate_average,
            cost_advertising_cost_total: result.cost_advertising_cost.cost_advertising_cost_total * last_year_comparison_rate_average,
            cost_advertising_cost_objective: result.cost_advertising_cost.cost_advertising_cost_objective * last_year_comparison_rate_average,
            cost_advertising_cost_rate: result.cost_advertising_cost.cost_advertising_cost_rate * last_year_comparison_rate_average,
            result_at: {
              seconds: Math.floor(new Date(year, month - 1, 1).getTime() / 1000),
              nanoseconds: 0,
            },
            year: year,
            month: month,
            date: yearMonthDate.getDate(),
            yearMonthDate: yearMonthDate,
          };
        });
        console.log('forecasted_revenue_array', forecasted_revenue_array);

        // 半年分にする
        const forecasted_array_of_sixmonth = forecasted_revenue_array.slice(0, 7);
        console.log('forecasted_array_of_sixmonth', forecasted_array_of_sixmonth);

        // 先月、今月のデータとくっつける
        const real_and_forecasted_array = real_revenue_array.concat(forecasted_array_of_sixmonth);
        console.log('real_and_forecasted_array', real_and_forecasted_array);

        // 先月・今月のデータと予測値データに、予測値グラフ描画用に修正を加える
        const real_and_forecasted_array_updated = real_and_forecasted_array.map((result: any) => {
          // 当月以前のデータには、実測値そのままに、予測値をつけておく
          if (result.result_at.seconds <= Math.floor(new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() / 1000)) {
            return {
              ...result,
              revenue_total_forecast: result.revenue_total,
              operating_profit_total_forecast: result.operating_profit_total,
            };
          } else {
            return {
              revenue_total_forecast: result.revenue_total,
              revenue_objective: result.revenue_objective,
              revenue_difference: result.revenue_difference,
              revenue_rate: result.revenue_rate,
              operating_profit_total_forecast: result.operating_profit_total,
              operating_profit_objective: result.operating_profit_objective,
              operating_profit_difference: result.operating_profit_difference,
              operating_profit_rate: result.operating_profit_rate,
              cost_unit_cost_total: result.cost_unit_cost_total,
              cost_unit_cost_objective: result.cost_unit_cost_objective,
              cost_unit_cost_rate: result.cost_unit_cost_rate,
              profit_gross_profit_total: result.profit_gross_profit_total,
              profit_gross_profit_objective: result.profit_gross_profit_objective,
              profit_gross_profit_rate: result.profit_gross_profit_rate,
              cost_svg_cost_total: result.cost_svg_cost_total,
              cost_svg_cost_objective: result.cost_svg_cost_objective,
              cost_svg_cost_rate: result.cost_svg_cost_rate,
              cost_labor_cost_total: result.cost_labor_cost_total,
              cost_labor_cost_objective: result.cost_labor_cost_objective,
              cost_labor_cost_rate: result.cost_labor_cost_rate,
              cost_advertising_cost_total: result.cost_advertising_cost_total,
              cost_advertising_cost_objective: result.cost_advertising_cost_objective,
              cost_advertising_cost_rate: result.cost_advertising_cost_rate,
              result_at: result.result_at,
              year: result.year,
              month: result.month,
              date: result.date,
              yearMonthDate: result.yearMonthDate,
            };
          }
        });
        console.log('real_and_forecasted_array_updated', real_and_forecasted_array_updated);

        return {
          ...state,
          forecastResult: {
            ...state.forecastResult,
            resultMonthly: real_and_forecasted_array_updated,
          },
        };
      } else {
        return state;
      }

    case GET_RESULTS_REQUESTED_FOR_METRICS_FORECAST_SUCCEEDED:
      const result_metrics_span = action.result_span;
      const resultsForMetricsForecast = action.resultsForecast; //過去データ
      const realMetricsResult = action.resultsReal; //実測値データ

      console.log('resultsForMetricsForecast', resultsForMetricsForecast);
      console.log('realMetricsResult', realMetricsResult);

      // ============
      // 月次の時(週次・日次まだ)
      if (result_metrics_span === 'monthly') {
        //　実測値データ
        const real_metrics_array = realMetricsResult.map((result: any) => {
          const yearMonthDate = new Date(result.result_at.seconds * 1000);
          let year;
          let month: number;
          // 12月だけgetMonth()すると０になってバグるので
          if (yearMonthDate.getMonth() === 0) {
            month = 12;
            year = yearMonthDate.getFullYear() - 1;
          } else {
            // getMonthは実際の月より１少ない値を返すので
            month = yearMonthDate.getMonth() + 1;
            year = yearMonthDate.getFullYear();
          }

          //　グラフ、表内で使うデータをそれぞれ抜き出し
          return {
            cac_total: result.cac.cac_total,
            cac_objective: result.cac.cac_objective,
            cac_rate: result.cac.cac_rate,
            mrr_total: result.mrr_total_value.mrr_total_value_total,
            mrr_objective: result.mrr_total_value.mrr_total_value_objective,
            mrr_rate: result.mrr_total_value.mrr_total_value_rate,
            arpu_total: result.arpu.arpu_total,
            arpu_objective: result.arpu.arpu_objective,
            arpu_rate: result.arpu.arpu_rate,
            ltv_total: result.ltv.ltv_total,
            ltv_objective: result.ltv.ltv_objective,
            ltv_rate: result.ltv.ltv_rate,
            unit_economics_total: result.unit_economics.unit_economics_total,
            unit_economics_objective: result.unit_economics.unit_economics_objective,
            unit_economics_rate: result.unit_economics.unit_economics_rate,
            payback_period_total: result.payback_period.payback_period_total,
            payback_period_objective: result.payback_period.payback_period_objective,
            payback_period_rate: result.payback_period.payback_period_rate,
            churn_rate_total: result.churn_rate.churn_rate_total,
            churn_rate_objective: result.churn_rate.churn_rate_objective,
            churn_rate_rate: result.churn_rate.churn_rate_rate,
            nrr_total: result.nrr.nrr_total,
            nrr_objective: result.nrr.nrr_objective,
            nrr_rate: result.nrr.nrr_rate,
            result_at: result.result_at,
            year: year,
            month: month,
            date: yearMonthDate.getDate(),
            yearMonthDate: yearMonthDate,
          };
        });

        // 過去データの成長率だけ取り出して配列にする
        const last_year_comparison_rate_array = resultsForMetricsForecast.map((result: any) => {
          const last_year_comparison_rate = result.revenue.revenue_last_year_comparison_rate;
          return last_year_comparison_rate;
        });
        const cac_last_year_comparison_rate_array = resultsForMetricsForecast.map((result: any) => {
          const last_year_comparison_rate = result.cac.cac_last_year_comparison_rate;
          return last_year_comparison_rate;
        });
        const mrr_last_year_comparison_rate_array = resultsForMetricsForecast.map((result: any) => {
          const last_year_comparison_rate = result.mrr_total_value.mrr_total_value_last_year_comparison_rate;
          return last_year_comparison_rate;
        });
        const arpu_last_year_comparison_rate_array = resultsForMetricsForecast.map((result: any) => {
          const last_year_comparison_rate = result.arpu.arpu_last_year_comparison_rate;
          return last_year_comparison_rate;
        });
        const ltv_last_year_comparison_rate_array = resultsForMetricsForecast.map((result: any) => {
          const last_year_comparison_rate = result.ltv.ltv_last_year_comparison_rate;
          return last_year_comparison_rate;
        });
        const unit_economics_last_year_comparison_rate_array = resultsForMetricsForecast.map((result: any) => {
          const last_year_comparison_rate = result.unit_economics.unit_economics_last_year_comparison_rate;
          return last_year_comparison_rate;
        });
        const payback_period_last_year_comparison_rate_array = resultsForMetricsForecast.map((result: any) => {
          const last_year_comparison_rate = result.payback_period.payback_period_last_year_comparison_rate;
          return last_year_comparison_rate;
        });
        const churn_rate_last_year_comparison_rate_array = resultsForMetricsForecast.map((result: any) => {
          const last_year_comparison_rate = result.churn_rate.churn_rate_last_year_comparison_rate;
          return last_year_comparison_rate;
        });
        const nrr_last_year_comparison_rate_array = resultsForMetricsForecast.map((result: any) => {
          const last_year_comparison_rate = result.nrr.nrr_last_year_comparison_rate;
          return last_year_comparison_rate;
        });

        // 上で取り出した成長率の配列の中身を合計する（成長率の合計）
        const cac_last_year_comparison_rate_sum = cac_last_year_comparison_rate_array.reduce(function (a: any, x: any) {
          return a + x;
        }, 0);
        const mrr_last_year_comparison_rate_sum = mrr_last_year_comparison_rate_array.reduce(function (a: any, x: any) {
          return a + x;
        }, 0);
        const arpu_last_year_comparison_rate_sum = arpu_last_year_comparison_rate_array.reduce(function (a: any, x: any) {
          return a + x;
        }, 0);
        const ltv_last_year_comparison_rate_sum = ltv_last_year_comparison_rate_array.reduce(function (a: any, x: any) {
          return a + x;
        }, 0);
        const unit_economics_last_year_comparison_rate_sum = unit_economics_last_year_comparison_rate_array.reduce(function (a: any, x: any) {
          return a + x;
        }, 0);
        const payback_period_last_year_comparison_rate_sum = payback_period_last_year_comparison_rate_array.reduce(function (a: any, x: any) {
          return a + x;
        }, 0);
        const churn_rate_last_year_comparison_rate_sum = churn_rate_last_year_comparison_rate_array.reduce(function (a: any, x: any) {
          return a + x;
        }, 0);
        const nrr_last_year_comparison_rate_sum = nrr_last_year_comparison_rate_array.reduce(function (a: any, x: any) {
          return a + x;
        }, 0);

        // 成長率の合計を、月の数（１年分）で割って平均成長率を出す
        const cac_last_year_comparison_rate_average = cac_last_year_comparison_rate_sum / resultsForMetricsForecast.length;
        const mrr_last_year_comparison_rate_average = mrr_last_year_comparison_rate_sum / resultsForMetricsForecast.length;
        const arpu_last_year_comparison_rate_average = arpu_last_year_comparison_rate_sum / resultsForMetricsForecast.length;
        const ltv_last_year_comparison_rate_average = ltv_last_year_comparison_rate_sum / resultsForMetricsForecast.length;
        const unit_economics_last_year_comparison_rate_average = unit_economics_last_year_comparison_rate_sum / resultsForMetricsForecast.length;
        const payback_period_last_year_comparison_rate_average = payback_period_last_year_comparison_rate_sum / resultsForMetricsForecast.length;
        const nrr_last_year_comparison_rate_average = nrr_last_year_comparison_rate_sum / resultsForMetricsForecast.length;
        const churn_rate_last_year_comparison_rate_average = churn_rate_last_year_comparison_rate_sum / resultsForMetricsForecast.length;
        console.log('last_year_comparison_rate_array', last_year_comparison_rate_array);
        console.log('cac_last_year_comparison_rate_sum', cac_last_year_comparison_rate_sum);
        console.log('mrr_last_year_comparison_rate_sum', mrr_last_year_comparison_rate_sum);
        console.log('arpu_last_year_comparison_rate_sum', arpu_last_year_comparison_rate_sum);
        console.log('ltv_last_year_comparison_rate_sum', ltv_last_year_comparison_rate_sum);
        console.log('unit_economics_last_year_comparison_rate_sum', unit_economics_last_year_comparison_rate_sum);
        console.log('payback_period_last_year_comparison_rate_sum', payback_period_last_year_comparison_rate_sum);
        console.log('nrr_last_year_comparison_rate_sum', nrr_last_year_comparison_rate_sum);
        console.log('churn_rate_last_year_comparison_rate_sum', churn_rate_last_year_comparison_rate_sum);

        console.log('cac_last_year_comparison_rate_average', cac_last_year_comparison_rate_average);
        console.log('mrr_last_year_comparison_rate_average', mrr_last_year_comparison_rate_average);
        console.log('arpu_last_year_comparison_rate_average', arpu_last_year_comparison_rate_average);
        console.log('ltv_last_year_comparison_rate_average', ltv_last_year_comparison_rate_average);
        console.log('unit_economics_last_year_comparison_rate_average', unit_economics_last_year_comparison_rate_average);
        console.log('nrr_last_year_comparison_rate_average', nrr_last_year_comparison_rate_average);
        console.log('churn_rate_last_year_comparison_rate_average', churn_rate_last_year_comparison_rate_average);
        console.log('payback_period_last_year_comparison_rate_average', payback_period_last_year_comparison_rate_average);
        console.log('resultsForMetricsForecast.length', resultsForMetricsForecast.length);

        // 予測値の配列（１年分）
        // 過去データに上で割り出した成長率をそれぞれ掛ける
        const forecasted_metrics_array = resultsForMetricsForecast.map((result: any, index: number) => {
          const yearMonthDate = new Date(result.result_at.seconds * 1000);
          let year;
          let month: number;
          if (yearMonthDate.getMonth() === 0) {
            month = 12;
            year = yearMonthDate.getFullYear();
          } else {
            month = yearMonthDate.getMonth() + 1;
            year = yearMonthDate.getFullYear() + 1;
          }

          return {
            cac_total: result.cac.cac_total * cac_last_year_comparison_rate_average,
            cac_objective: result.cac.cac_objective * cac_last_year_comparison_rate_average,
            cac_rate: result.cac.cac_rate * cac_last_year_comparison_rate_average,
            mrr_total: result.mrr_total_value.mrr_total_value_total * mrr_last_year_comparison_rate_average,
            mrr_objective: result.mrr_total_value.mrr_total_value_objective * mrr_last_year_comparison_rate_average,
            mrr_rate: result.mrr_total_value.mrr_total_value_rate * mrr_last_year_comparison_rate_average,
            arpu_total: result.arpu.arpu_total * arpu_last_year_comparison_rate_average,
            arpu_objective: result.arpu.arpu_objective * arpu_last_year_comparison_rate_average,
            arpu_rate: result.arpu.arpu_rate * arpu_last_year_comparison_rate_average,
            ltv_total: result.ltv.ltv_total * ltv_last_year_comparison_rate_average,
            ltv_objective: result.ltv.ltv_objective * ltv_last_year_comparison_rate_average,
            ltv_rate: result.ltv.ltv_rate * ltv_last_year_comparison_rate_average,
            unit_economics_total: result.unit_economics.unit_economics_total * unit_economics_last_year_comparison_rate_average,
            unit_economics_objective: result.unit_economics.unit_economics_objective * unit_economics_last_year_comparison_rate_average,
            unit_economics_rate: result.unit_economics.unit_economics_rate * unit_economics_last_year_comparison_rate_average,
            payback_period_total: result.payback_period.payback_period_total * payback_period_last_year_comparison_rate_average,
            payback_period_objective: result.payback_period.payback_period_objective * payback_period_last_year_comparison_rate_average,
            payback_period_rate: result.payback_period.payback_period_rate * payback_period_last_year_comparison_rate_average,
            churn_rate_total: result.churn_rate.churn_rate_total * churn_rate_last_year_comparison_rate_average,
            churn_rate_objective: result.churn_rate.churn_rate_objective * churn_rate_last_year_comparison_rate_average,
            churn_rate_rate: result.churn_rate.churn_rate_rate * churn_rate_last_year_comparison_rate_average,
            nrr_total: result.nrr.nrr_total * nrr_last_year_comparison_rate_average,
            nrr_objective: result.nrr.nrr_objective * nrr_last_year_comparison_rate_average,
            nrr_rate: result.nrr.nrr_objective * nrr_last_year_comparison_rate_average,
            // revenue_total: result.revenue.revenue_total * last_year_comparison_rate_average,
            // revenue_objective: result.revenue.revenue_objective * last_year_comparison_rate_average,
            // revenue_difference: result.revenue.revenue_total * last_year_comparison_rate_average - result.revenue.revenue_objective * last_year_comparison_rate_average,
            // revenue_rate: result.revenue.revenue_rate * last_year_comparison_rate_average,
            // operating_profit_total: result.profit_operating_profit.profit_operating_profit_total * last_year_comparison_rate_average,
            // operating_profit_objective: result.profit_operating_profit.profit_operating_profit_objective * last_year_comparison_rate_average,
            // operating_profit_difference: result.profit_operating_profit.profit_operating_profit_total * last_year_comparison_rate_average - result.profit_operating_profit.profit_operating_profit_objective * last_year_comparison_rate_average,
            // operating_profit_rate: result.profit_operating_profit.profit_operating_profit_rate * last_year_comparison_rate_average,
            // cost_unit_cost_total: result.cost_unit_cost.cost_unit_cost_total * last_year_comparison_rate_average,
            // cost_unit_cost_objective: result.cost_unit_cost.cost_unit_cost_objective * last_year_comparison_rate_average,
            // cost_unit_cost_rate: result.cost_unit_cost.cost_unit_cost_rate * last_year_comparison_rate_average,
            // profit_gross_profit_total: result.profit_gross_profit.profit_gross_profit_total * last_year_comparison_rate_average,
            // profit_gross_profit_objective: result.profit_gross_profit.profit_gross_profit_objective * last_year_comparison_rate_average,
            // profit_gross_profit_rate: result.profit_gross_profit.profit_gross_profit_rate * last_year_comparison_rate_average,
            // cost_svg_cost_total: result.cost_svg_cost.cost_svg_cost_total * last_year_comparison_rate_average,
            // cost_svg_cost_objective: result.cost_svg_cost.cost_svg_cost_objective * last_year_comparison_rate_average,
            // cost_svg_cost_rate: result.cost_svg_cost.cost_svg_cost_rate * last_year_comparison_rate_average,
            // cost_labor_cost_total: result.cost_labor_cost.cost_labor_cost_total * last_year_comparison_rate_average,
            // cost_labor_cost_objective: result.cost_labor_cost.cost_labor_cost_objective * last_year_comparison_rate_average,
            // cost_labor_cost_rate: result.cost_labor_cost.cost_labor_cost_rate * last_year_comparison_rate_average,
            // cost_advertising_cost_total: result.cost_advertising_cost.cost_advertising_cost_total * last_year_comparison_rate_average,
            // cost_advertising_cost_objective: result.cost_advertising_cost.cost_advertising_cost_objective * last_year_comparison_rate_average,
            // cost_advertising_cost_rate: result.cost_advertising_cost.cost_advertising_cost_rate * last_year_comparison_rate_average,
            result_at: {
              seconds: Math.floor(new Date(year, month - 1, 1).getTime() / 1000),
              nanoseconds: 0,
            },
            year: year,
            month: month,
            date: yearMonthDate.getDate(),
            yearMonthDate: yearMonthDate,
          };
        });
        console.log('forecasted_metrics_array', forecasted_metrics_array);

        // 半年分にする
        const forecasted_metrics_array_of_sixmonth = forecasted_metrics_array.slice(0, 7);
        console.log('forecasted_metrics_array_of_sixmonth', forecasted_metrics_array_of_sixmonth);

        // 先月、今月のデータとくっつける
        const real_and_forecasted_metrics_array = real_metrics_array.concat(forecasted_metrics_array_of_sixmonth);
        console.log('real_and_metrics_forecasted_array', real_and_forecasted_metrics_array);

        // 先月・今月のデータと予測値データに、予測値グラフ描画用に修正を加える
        const real_and_forecasted_metrics_array_updated = real_and_forecasted_metrics_array.map((result: any) => {
          // 当月以前のデータには、実測値そのままに、予測値をつけておく
          if (result.result_at.seconds <= Math.floor(new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() / 1000)) {
            return {
              ...result,
              cac_total_forecast: result.cac_total,
              mrr_total_forecast: result.mrr_total,
              arpu_total_forecast: result.arpu_total,
              ltv_total_forecast: result.ltv_total,
              unit_economics_total_forecast: result.unit_economics_total,
              payback_period_total_forecast: result.payback_period_total,
              churn_rate_total_forecast: result.churn_rate_total,
              nrr_total_forecast: result.nrr_total,
            };
          } else {
            return {
              cac_total_forecast: result.cac_total,
              cac_objective: result.cac_objective,
              mrr_total_forecast: result.mrr_total,
              mrr_objective: result.mrr_objective,
              arpu_total_forecast: result.arpu_total,
              arpu_objective: result.arpu_objective,
              ltv_total_forecast: result.ltv_total,
              ltv_objective: result.ltv_objective,
              unit_economics_total_forecast: result.unit_economics_total,
              unit_economics_objective: result.unit_economics_objective,
              payback_period_total_forecast: result.payback_period_total,
              payback_period_objective: result.payback_period_objective,
              churn_rate_total_forecast: result.churn_rate_total,
              churn_rate_objective: result.churn_rate_objective,
              nrr_total_forecast: result.nrr_total,
              nrr_objective: result.nrr_objective,
              result_at: result.result_at,
              year: result.year,
              month: result.month,
              date: result.date,
              yearMonthDate: result.yearMonthDate,
            };
          }
        });
        console.log('real_and_forecasted_metrics_array_updated', real_and_forecasted_metrics_array_updated);

        return {
          ...state,
          forecastMetricsResult: {
            ...state.forecastMetricsResult,
            resultMonthly: real_and_forecasted_metrics_array_updated,
          },
        };
      } else {
        return state;
      }

    case TOGGLE_FORECAST_FILTER:
      return {
        ...state,
        forecastFilter: !action.forecastFilter,
      };
    case SET_FORECAST_FILTER:
      return {
        ...state,
        forecastFilter: true,
      };
    case RESET_FORECAST_FILTER:
      return {
        ...state,
        forecastFilter: false,
      };

    case TOGGLE_METRICS_FORECAST_FILTER:
      return {
        ...state,
        metricsForecastFilter: !action.metricsForecastFilter,
      };

    case UPDATE_FORECAST_SIMULATED_RESULT:
      console.log(action.updated_item_index);
      console.log('action.updated_object', action.updated_object);

      if (action.resultSpan === 'monthly') {
        return update(state, {
          forecastResult: {
            ...state.forecastResult,
            resultMonthly: {
              [action.updated_item_index]: {
                $set: action.updated_object,
              },
            },
          },
        });
      }
      break;

    case UPDATE_METRICS_FORECAST_SIMULATED_RESULT:
      console.log(action.updated_item_index);
      console.log('action.updated_object', action.updated_object);

      if (action.resultSpan === 'monthly') {
        return update(state, {
          forecastMetricsResult: {
            ...state.forecastMetricsResult,
            resultMonthly: {
              [action.updated_item_index]: {
                $set: action.updated_object,
              },
            },
          },
        });
      }
      break;

    default:
      return state;
  }
};

export default forecastsReducer;
