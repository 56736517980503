import rsf from '../rsf';
import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import firebase from 'firebase';
import {} from '../reducks/results/types';
import { HIDE_LOADING } from '../reducks/loading/types'
import { GET_REVENUE_DETAIL_REQUEST, GET_REVENUE_DETAIL_SUCCEEDED, GET_REVENUE_DETAIL_FAILED, GET_RESULTS_REQUEST_FOR_NEW_FORECAST, GET_RESULTS_REQUEST_FOR_NEW_FORECAST_SUCCEEDED, GET_RESULTS_REQUEST_FOR_NEW_FORECAST_FAILED, GET_RESULTS_REQUEST_FOR_NEW_FORECAST_FOR_CUSTOM, SET_FORECAST_CUSTOM_SPAN } from '../reducks/newForecast/types';
import getTargetDataSequenceResult from "./rawDataSagas/getTargetDataSequenceResult";
// import { GET_RESULTS_SUCCEEDED_GRAPH } from "../reducks/graphs/graphTypes"

const db = firebase.firestore();

function* getFilteredResults(action) {
  try {
    const result_span = action.resultSpan;
    // let snapshot = []

    const collection_name = 'revenue_detail';

    let results = [];

    // コレクショングループでためす
    const newCollectionGroup = (collection_name) => {
      return db
        .collectionGroup(collection_name)
        .where('company_id', '==', action.company_id)
        .where('result_at', '>=', new Date(action.from.seconds * 1000))
        .where('result_at', '<=', new Date(action.to.seconds * 1000))
        .get();
    };
    const snapshot = yield call(newCollectionGroup, collection_name);

    snapshot.forEach((doc) => {
      results = [...results, { ...doc.data(), docId: doc.id }];
    });

    yield put({ type: GET_REVENUE_DETAIL_SUCCEEDED, results: results, result_data_type: action.result_data_type, filter_name: action.filter_name, from: action.from });
  } catch (e) {
    yield put({ type: GET_REVENUE_DETAIL_FAILED, message: e.message });
  }
}

// 指定された期間の過去のサマリーデータ
function* getRawDataPast(company_id, forecast_base_span) {
  try {
    // 当月
    const thisMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const month = thisMonth.getMonth();

    // 12,24,36ヶ月前
    // const forecastBaseStart = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - forecast_base_span - 1));
    const forecastBaseStart = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 6));  // データが足りないため現在は半年分のみ取得
    let raw_data_array_past = [];
    const snapshot = yield call(
      rsf.firestore.getCollection,
      db.collection(`companies/${company_id}/result_monthly`)
        .where("result_at", ">=", forecastBaseStart)
        .where("result_at", "<=", thisMonth)
      );

    // 取得後データを整形
    snapshot.forEach(result_monthly => {
      raw_data_array_past = [...raw_data_array_past, { ...result_monthly.data(), docId: result_monthly.id }];
    });

    return raw_data_array_past;
  } catch (e) {
    console.log("error message", e.message);
  }
}

// 指定されたカスタム期間の過去のサマリーデータ
function* getRawDataPastCustom(company_id, forecast_base_span, forecast_custom_span_start_time,
  forecast_custom_span_end_time) {
  try {
    // 当月
    const thisMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const month = thisMonth.getMonth();

    // 12,24,36ヶ月前
    // const forecastBaseStart = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - forecast_base_span - 1));
    const forecastBaseStart = new Date(forecast_custom_span_start_time * 1000);
    const forecastBaseEnd = new Date(forecast_custom_span_end_time * 1000);

    console.log('forecastBaseStart', forecastBaseStart);
    console.log('forecastBaseEnd', forecastBaseEnd);
    let raw_data_array_past = [];
    const snapshot = yield call(
      rsf.firestore.getCollection,
      db.collection(`companies/${company_id}/result_monthly`)
        .where("result_at", ">=", forecastBaseStart)
        .where("result_at", "<=", forecastBaseEnd)
      );

    // 取得後データを整形
    snapshot.forEach(result_monthly => {
      raw_data_array_past = [...raw_data_array_past, { ...result_monthly.data(), docId: result_monthly.id }];
    });

    return raw_data_array_past;
  } catch (e) {
    console.log("error message", e.message);
  }
}



//　右上カレンダーで指定された期間のサマリーデータ
function* getRawDataReal(company_id, selected_data_config_data_span, custom_span_start_time, custom_span_end_time ) {
  try {
    // 期間設定
    const start_time = new Date(custom_span_start_time.seconds * 1000);
    const end_time = new Date(custom_span_end_time.seconds * 1000);

    // 当月
    const thisMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const month = thisMonth.getMonth();

    // 昨月
    const lastMonth = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 1));

    // 翌月
    const nextMonth = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month + 1));

    // 一昨月
    const lastLastMonth = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 2));

    // 6ヶ月前
    const last6Months = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 5));

    // 12ヶ月前
    const last12Months = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 11));

    // コレクショングループでためす
    let raw_data_array_real = [];
    if (selected_data_config_data_span === 'custom') {
      // データ取得
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${company_id}/result_monthly`)
          .where("result_at", ">=", start_time)
          .where("result_at", "<=", end_time)
        );

      // 取得後データを整形
      snapshot.forEach(result_monthly => {
        raw_data_array_real = [...raw_data_array_real, { ...result_monthly.data(), docId: result_monthly.id }];
      });

      return raw_data_array_real;
    } else if (selected_data_config_data_span === 'this_month') {
      console.log('THIS MONTH')
      // データ取得
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${company_id}/result_monthly`)
          .where("result_at", ">=", lastMonth)
          .where("result_at", "<=", nextMonth)
        );

      // 取得後データを整形
      snapshot.forEach(result_monthly => {
        raw_data_array_real = [...raw_data_array_real, { ...result_monthly.data(), docId: result_monthly.id }];
      });
      return raw_data_array_real
    } else if (selected_data_config_data_span === 'last_month') {
      console.log('LAST MONTH')
      // データ取得
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${company_id}/result_monthly`)
          .where("result_at", ">=", lastLastMonth)
          .where("result_at", "<=", thisMonth)
        );

      // 取得後データを整形
      snapshot.forEach(result_monthly => {
        raw_data_array_real = [...raw_data_array_real, { ...result_monthly.data(), docId: result_monthly.id }];
      });
      return raw_data_array_real
    } else if (selected_data_config_data_span === 'last_6_months') {
      // データ取得
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${company_id}/result_monthly`)
          .where("result_at", ">=", last6Months)
          .where("result_at", "<=", thisMonth)
        );

      // 取得後データを整形
      snapshot.forEach(result_monthly => {
        raw_data_array_real = [...raw_data_array_real, { ...result_monthly.data(), docId: result_monthly.id }];
      });
      return raw_data_array_real
    } else if (selected_data_config_data_span === 'last_12_months') {
      // データ取得
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${company_id}/result_monthly`)
          .where("result_at", ">=", last12Months)
          .where("result_at", "<=", thisMonth)
        );

      // 取得後データを整形
      snapshot.forEach(result_monthly => {
        raw_data_array_real = [...raw_data_array_real, { ...result_monthly.data(), docId: result_monthly.id }];
      });
      return raw_data_array_real
    } else {
      // データ取得
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${company_id}/result_monthly`)
          .where("result_at", ">=", start_time)
          .where("result_at", "<=", end_time)
        );

      // 取得後データを整形
      snapshot.forEach(result_monthly => {
        raw_data_array_real = [...raw_data_array_real, { ...result_monthly.data(), docId: result_monthly.id }];
      });

      return raw_data_array_real;
    }


  } catch (e) {
    console.log("error message", e.message);
  }
}

// 「指定期間」以外の未来予測
function* getResultsForNewForcast(action) {
  try {
    const raw_data_array_past = yield* getRawDataPast(action.company_id, action.forecast_base_span);
    const raw_data_array_real = yield* getRawDataReal(action.company_id, action.selected_data_config_data_span, action.custom_span_start_time, action.custom_span_end_time);

    yield all([

      put ({
        type: GET_RESULTS_REQUEST_FOR_NEW_FORECAST_SUCCEEDED,
        raw_data_array_past: raw_data_array_past,
        // forecast_base_span: Number(action.forecast_base_span)
        forecast_base_span: 5,
        raw_data_array_real: raw_data_array_real,
      }),
      put({
        type: SET_FORECAST_CUSTOM_SPAN,
        forecast_custom_span_start_time: action.forecast_custom_span_start_time,
        forecast_custom_span_end_time: action.forecast_custom_span_end_time,
        forecast_base_span: action.forecast_base_span
      }),
      put({
        type: HIDE_LOADING
      })
    ])

  } catch (e) {
    yield all([
      put({
        type: HIDE_LOADING
      }),
      put({
        type: GET_RESULTS_REQUEST_FOR_NEW_FORECAST_FAILED,
        message: e.message
      }),
    ]);
  }
}

// 「指定期間」の未来予測
function* getResultsForNewForcastForCustom(action) {
  try {
    const raw_data_array_past = yield* getRawDataPastCustom(action.company_id, action.forecast_base_span, action.forecast_custom_span_start_time, action.forecast_custom_span_end_time);
    const raw_data_array_real = yield* getRawDataReal(action.company_id, action.selected_data_config_data_span, action.custom_span_start_time, action.custom_span_end_time);

    yield all([
      put({
        type: GET_RESULTS_REQUEST_FOR_NEW_FORECAST_SUCCEEDED,
        raw_data_array_past: raw_data_array_past,
        // forecast_base_span: Number(action.forecast_base_span)
        forecast_base_span: action.forecast_base_span,
        raw_data_array_real: raw_data_array_real,
      }),
      put({
        type: SET_FORECAST_CUSTOM_SPAN,
        forecast_custom_span_start_time: action.forecast_custom_span_start_time,
        forecast_custom_span_end_time: action.forecast_custom_span_end_time,
        forecast_base_span: action.forecast_base_span
      }),
      put({
        type: HIDE_LOADING
      })
    ]);

  } catch (e) {
    yield all([
      put({
        type: HIDE_LOADING
      }),
      put({
        type: GET_RESULTS_REQUEST_FOR_NEW_FORECAST_FAILED,
        message: e.message
      }),
    ]);
  }
}


const newForecastSagas = [takeLatest(GET_REVENUE_DETAIL_REQUEST, getFilteredResults), takeLatest(GET_RESULTS_REQUEST_FOR_NEW_FORECAST, getResultsForNewForcast), takeLatest(GET_RESULTS_REQUEST_FOR_NEW_FORECAST_FOR_CUSTOM, getResultsForNewForcastForCustom)];
export default newForecastSagas;
