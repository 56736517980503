import { ADD_ITEM_INTO_GRID, REMOVE_ITEM_FROM_GRID, SAVE_LAYOUT, UPDATE_LAYOUT_REQUEST, GET_LAYOUT_REQUESTED, TOGGLE_GRAPH } from "./gridTypes"

export const addItemIntoGrid = (i: string, x: number, y: number, w: number, h: number, content: string) => {
  return {
    type: ADD_ITEM_INTO_GRID,
    gridItem: { i, x, y, w, h },
    content: content,
  }
}

export const removeItemFromGrid = (contents: any, target_i: string) => {
  return {
    type: REMOVE_ITEM_FROM_GRID,
    contents,
    target_i,
  }
}

export const saveLayout = (newLayout: any) => {
  return {
    type: SAVE_LAYOUT,
    payload: newLayout,
  }
}

export const updateLayoutRequest = (company_id: string, content: any) => ({
  type: UPDATE_LAYOUT_REQUEST,
  company_id,
  content,
})

export const getLayoutRequest = (company_id: string) => ({
  type: GET_LAYOUT_REQUESTED,
  company_id: company_id,
})

export const toggleGraph = (target: string, contents:any) => ({
  type: TOGGLE_GRAPH,
  target,
  contents
})
