import React, { Component } from "react";
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Area,
  ReferenceLine
} from "recharts";
import styled from "styled-components";

import { dateFormatter } from "../../Util/DateFormatter";
import { decimalPointChecker } from "../../Util/unitSwitcher";
import graphTotalLegendIcon from "../../../images/icons/graph_total_legend.svg";
import graphObjectiveLegendIcon from "../../../images/icons/graph_objective_legend.svg";
import movingAverageLegendIcon from "../../../images/icons/moving_average_legend.svg";

const CustomTooltipLine = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    console.log("active", active);
    console.log("payload", payload);
    console.log("label", label);
    return (
      <BarGraphTooltip>
        <BarGraphTooltipDate>
          {new Date(
            payload[0] && payload[0].payload.date.seconds * 1000
          ).getFullYear()}
          .
          {new Date(
            payload[0] && payload[0].payload.date.seconds * 1000
          ).getMonth() + 1}
        </BarGraphTooltipDate>

        <BarGraphTooltipLabel>当月</BarGraphTooltipLabel>
        <BarGraphTooltipData>{`${decimalPointChecker(
          payload[0] ? payload[0].value : 0
        )}`}</BarGraphTooltipData>

        <BarGraphTooltipHR />

        <BarGraphTooltipLabel>目標</BarGraphTooltipLabel>
        <BarGraphTooltipData>{`${decimalPointChecker(
          payload[1] ? payload[1].value : 0
        )}`}</BarGraphTooltipData>

        {payload[2] && (
          <>
            <BarGraphTooltipHR />
            <BarGraphTooltipLabel>移動平均</BarGraphTooltipLabel>
            <BarGraphTooltipData>{`${decimalPointChecker(
              payload[2] ? payload[2].value : 0
            )}`}</BarGraphTooltipData>
          </>
        )}
      </BarGraphTooltip>
    );
  }

  return null;
};
const CustomTooltipBar = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    console.log("active", active);
    console.log("payload", payload);
    console.log("label", label);
    return (
      <BarGraphTooltip>
        <BarGraphTooltipDate>
          {new Date(
            payload[0] && payload[0].payload.date.seconds * 1000
          ).getFullYear()}
          .
          {new Date(
            payload[0] && payload[0].payload.date.seconds * 1000
          ).getMonth() + 1}
        </BarGraphTooltipDate>
        <BarGraphTooltipLabel>当月</BarGraphTooltipLabel>
        <BarGraphTooltipData>{`${decimalPointChecker(
          payload[0] ? payload[0].value : 0
        )}`}</BarGraphTooltipData>

        <BarGraphTooltipHR />

        <BarGraphTooltipLabel>目標</BarGraphTooltipLabel>
        <BarGraphTooltipData>{`${decimalPointChecker(
          payload[1] ? payload[1].value : 0
        )}`}</BarGraphTooltipData>

        {payload[2] && (
          <>
            <BarGraphTooltipHR />
            <BarGraphTooltipLabel>移動平均</BarGraphTooltipLabel>
            <BarGraphTooltipData>{`${decimalPointChecker(
              payload[2] ? payload[2].value : 0
            )}`}</BarGraphTooltipData>
          </>
        )}
      </BarGraphTooltip>
    );
  }

  return null;
};

//フィルターなしLegend
const noneFilterLegend = (data: {
  this_time_graph_switch: boolean;
  moving_average_graph_switch: boolean;
  periodic_average_graph: boolean;
}) => {
  return (
    <ul
      style={{
        display: "flex",
        fontSize: "12px",
        color: "#283f46",
        marginBottom: "16px"
      }}
    >
      <LegendItem className="total">実績</LegendItem>
      <LegendItem className="objective">目標</LegendItem>
      {data.moving_average_graph_switch && (
        <LegendItem className="movingAverage">移動平均</LegendItem>
      )}
    </ul>
  );
};

//Legendの中身
const customLegend = () => {
  return (
    <ul style={{ display: "flex", fontSize: "12px", color: "#283f46" }}>
      <LegendItem className="line">実績</LegendItem>
      <LegendItem className="line">目標</LegendItem>
      {/* <LegendItem className="line movingAverage">移動平均 実績</LegendItem>
      <LegendItem className="dots movingAverage">移動平均 目標</LegendItem> */}
    </ul>
  );
};

interface Props {
  defaultGraphAreaRef: any;
  target_funnel_name: any;
  raw_data_array: any;
  graphDatas: any;
  resultSpan: string;
  displayType: "line" | "bar";
  this_time_graph_switch: boolean;
  moving_average_graph_switch: boolean;
  periodic_average_graph: boolean;
  period_average_sum: any;
}
interface State {}

export default class FunnelDetailGraphDefault extends Component<Props, State> {
  area: any;
  tooltip: HTMLElement | null;
  point: any;

  constructor(props: any) {
    super(props);

    this.area = null;
    this.tooltip = null;
    this.point = null;
  }

  showToolTip = (e: any) => {
    if (this.tooltip !== null) {
      console.log("this.tooltip", this.tooltip);
      console.log("showTooltip e:", e);
      //console.log("raw_data_array:", this.props.raw_data_array);
      console.log("graphDatas:", this.props.graphDatas);
      this.tooltip.style.left = `${(e.cx + 50).toString()}px`;
      this.tooltip.style.top = `${(e.cy + 120).toString()}px`;
      // 最後のデータだった場合は、dotの左隣に出力
      if (this.props.graphDatas.length - 1 === e.index) {
        console.log("last data");
        console.log("last data x", `${(e.cx - 150).toString()}px`);
        this.tooltip.style.left = `${(e.cx - 170).toString()}px`;
      }
      this.tooltip.style.opacity = "1";
      console.log("this.tooltip.childNodes", this.tooltip.childNodes);
      const tooltipDate = this.tooltip.childNodes[0] as HTMLElement;
      const tooltipDataKey = this.tooltip.childNodes[1]
        .childNodes[0] as HTMLElement;
      const tooltipDataValue = this.tooltip.childNodes[1]
        .childNodes[1] as HTMLElement;

      //ホバーしたラインのデータキーによって、日付を出し分け
      tooltipDate.innerHTML =
        new Date(e.payload.date.seconds * 1000).getFullYear() +
        "/" +
        (new Date(e.payload.date.seconds * 1000).getMonth() + 1);

      //実績/目標/移動平均の出し分け
      console.log("e.dataKey", e.dataKey);
      if (e.dataKey.includes("_count_objective")) {
        tooltipDataKey.innerHTML = "当月 目標";
        // } else if (e.dataKey.includes("_count_total")) {
        //   tooltipDataKey.innerHTML = "当月 実績"
        // } else if (e.dataKey.includes("_count_moving_average_total")) {
        //   tooltipDataKey.innerHTML = "移動平均 実績"
      } else {
        //   tooltipDataKey.innerHTML = "移動平均 目標"
        tooltipDataKey.innerHTML = "当月 実績";
      }
      //value値の出しわけ
      if (e.value === null || e.value === undefined) {
        tooltipDataValue.innerHTML = "<span>NoData</span>";
      } else {
        tooltipDataValue.innerHTML = e.value.toLocaleString();
      }
      // document.getElementsByClassName('DataContent')[e.index].classList.add('activeDotShown');
      this.tooltip.style.zIndex = "99999";
      // this.tooltip.innerHTML = e.value;
    } else {
      console.log("showTooltip e is null:", e);
      return false;
    }
  };
  hideTooltip = (e: any) => {
    if (this.tooltip !== null) {
      console.log("this.tooltip", this.tooltip);
      console.log("hideTooltip e:", e);
      this.tooltip.style.opacity = "0";

      // document.getElementsByClassName('DataContent')[e.index].classList.remove('activeDotShown');
      // this.tooltip.style.zIndex = "-1";
    } else {
      console.log("hideTooltip e is null:", e);
      return false;
    }
  };

  render() {
    const displayType = this.props.displayType;
    return (
      <>
        <Graph>
          <ResponsiveContainer>
            <ComposedChart
              width={730}
              height={250}
              data={this.props.graphDatas}
              margin={{ bottom: 24 }}
              barSize={20}
              barGap={5}
              ref={this.props.defaultGraphAreaRef}
            >
              {displayType === "line" ? (
                <Legend
                  verticalAlign={"top"}
                  align={"left"}
                  width={560}
                  height={32}
                  margin={{ right: 100 }}
                  content={noneFilterLegend({
                    this_time_graph_switch: this.props.this_time_graph_switch,
                    moving_average_graph_switch:
                      this.props.moving_average_graph_switch,
                    periodic_average_graph: this.props.periodic_average_graph
                  })}
                  wrapperStyle={{ paddingTop: "16px" }}
                />
              ) : (
                <Legend
                  verticalAlign={"top"}
                  align={"left"}
                  width={560}
                  height={32}
                  margin={{ right: 100 }}
                  iconSize={12}
                  wrapperStyle={{ paddingTop: "16px" }}
                />
              )}

              <XAxis
                dataKey={"date.seconds"}
                scale={displayType === "line" ? "point" : "auto"}
                tickFormatter={tick =>
                  dateFormatter(this.props.resultSpan, tick * 1000)
                }
              />
              <YAxis
                yAxisId={0}
                type="number"
                tickCount={10}
                interval={0}
                tickMargin={10}
                tickLine={false}
                tickFormatter={tick => tick.toLocaleString()}
              />

              <CartesianGrid stroke="#f5f5f5" />

              {/*-*-*-*-* Bar *-*-*-*-*/}
              {/* [ 実績 ] 達成率 => 実績 ( Bar ) */}
              {displayType === "bar" && (
                <Bar
                  name="実績"
                  dataKey={"lead_new_count_total"}
                  fill="#0066FF"
                />
              )}

              {/* [ 実績 ] 達成率 => 目標 */}
              {displayType === "bar" && (
                <Bar
                  name="目標"
                  dataKey={"lead_new_count_objective"}
                  fill="#99C2FF"
                />
              )}

              {/*-*-*-*-* Area *-*-*-*-*/}
              {/* [ 実績 ] 達成率 => 実績 ( Area ) */}
              {displayType === "line" && (
                <Area
                  type="linear"
                  name="実績"
                  dataKey="lead_new_count_total"
                  dot={false}
                  fill="#0066FF"
                  fillOpacity="0.1"
                  stroke="none"
                />
              )}

              {/*-*-*-*-* Line *-*-*-*-*/}
              {/* [ 実績 ] 達成率 => 実績 ( Line ) */}
              {displayType === "line" &&
                !this.props.moving_average_graph_switch &&
                !this.props.periodic_average_graph && (
                  <Line
                    type="linear"
                    name="実績"
                    dataKey="lead_new_count_total"
                    dot={false}
                    stroke="#0066FF"
                    strokeWidth="3"
                  />
                )}

              {/* [ 移動平均 ] 達成率　=> 実績 */}
              {/* [ 実績 ] 達成率 => 目標 */}
              {displayType === "line" && (
                <Line
                  type="linear"
                  name="目標"
                  dataKey={"lead_new_count_objective"}
                  dot={false}
                  stroke="#99c2ff"
                  strokeWidth="3"
                />
              )}

              {/* [ 移動平均 ] 達成率 => 実績 */}
              {this.props.moving_average_graph_switch && (
                <Line
                  type="linear"
                  name="移動平均"
                  dataKey="lead_new_count_moving_average_total"
                  dot={false}
                  stroke="#0066FF"
                  strokeWidth="5"
                  strokeDasharray="5 5"
                />
              )}

              {/*-*-*-*-* 期間平均線 *-*-*-*-*/}
              {/* periodic_average_graph */}
              {this.props.periodic_average_graph && (
                <ReferenceLine
                  y={this.props.period_average_sum}
                  stroke="#0066FF"
                  strokeWidth={5}
                />
              )}
              {displayType === "line" ? (
                <Tooltip content={<CustomTooltipLine />} />
              ) : (
                <Tooltip content={<CustomTooltipBar />} />
              )}
            </ComposedChart>
          </ResponsiveContainer>

          <UiChartTooltip
            className="ui-chart-tooltip"
            ref={ref => (this.tooltip = ref)}
          >
            <div className="tooltip-date"></div>
            <div className="toltip-databox">
              <div className="tooltip-data-key"></div>
              <div className="tooltip-data-value"></div>
            </div>
          </UiChartTooltip>

          {/* コメントリスト */}
          {/* <CommentsWindow /> */}
          {/* コメント追加ウィンドウ */}
          {/* <CommentAddWindow /> */}
          {/* コメント編集ウィンドウ */}
          {/* <CommentEditWindow /> */}
        </Graph>
      </>
    );
  }
}

// Graph
const Graph = styled.div`
  width: 100%;
  height: 272px;
  padding: 0 10px;
  box-sizing: border-box;

  .recharts-tooltip-wrapper {
    max-width: 200px;
  }

  .recharts-legend-wrapper {
    top: -16px !important;
    left: 10px !important;
    font-size: 12px;
    color: #283f46;
  }
  .recharts-cartesian-axis-tick {
    width: 100px !important;
    .recharts-cartesian-axis-tick-value {
      font-size: 10px;
      color: #435878;
    }
  }
`;

//customLegend
const LegendItem = styled.li`
  margin-right: 16px;
  &.line {
    &::before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 3px;
      background-color: #0065db;
      border-radius: 1.5px;
      margin-right: 8px;
      position: relative;
      bottom: 3px;
    }
    &.movingAverage {
      &::before {
        background-color: #e53e66;
      }
    }
  }
  &.dots {
    &::before {
      content: "•••";
      display: inline-block;
      width: 16px;
      height: 3px;
      color: #0065db;
      font-weight: bold;
      margin-right: 8px;
      position: relative;
      bottom: 1px;
    }
    &.movingAverage {
      &::before {
        color: #e53e66;
      }
    }
  }
  &.total {
    &::before {
      content: "";
      display: inline-block;
      width: 19px;
      height: 14px;
      background-image: url(${graphTotalLegendIcon});
      background-size: cover;
      background-position: center;
      position: relative;
      top: 2px;
      left: -5px;
    }
  }
  &.objective {
    &::before {
      content: "";
      display: inline-block;
      width: 19px;
      height: 14px;
      background-image: url(${graphObjectiveLegendIcon});
      background-size: cover;
      background-position: center;
      position: relative;
      top: 2px;
      left: -5px;
    }
  }
  &.movingAverage {
    position: relative;
    top: 3px;
    left: 18px;
    &::before {
      content: "";
      display: inline-block;
      width: 19px;
      height: 9px;
      background-image: url(${movingAverageLegendIcon});
      background-size: cover;
      background-position: center;
      position: absolute;
      top: 2px;
      left: -22px;
    }
  }
`;

const UiChartTooltip = styled.div`
  opacity: 0;
  position: absolute;
  z-index: -1;
  min-width: 178px;
  width: auto;
  min-height: 48px;
  height: auto;
  background: white;
  border: 1px solid #e2e9f3;
  border-radius: 2px;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.25);
  padding: 8px 9px;
  .tooltip-date {
    font-size: 10px;
    font-weight: bold;
    color: #7591bc;
    margin-bottom: 5px;
  }
  .toltip-databox {
    display: flex;
    justify-content: space-between;
  }
  .tooltip-data-key {
    font-size: 10px;
    color: #435878;
    width: 50%;
    word-break: break-all;
  }
  .tooltip-data-value {
    font-size: 10px;
    font-weight: bold;
    color: #435878;
    span {
      color: #af002a;
    }
  }
`;

const BarGraphTooltip = styled.div`
  background: #fff;
  padding: 8px 9px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid #e2e9f3;
  border-radius: 4px;
  max-width: 200px;
`;

// 日付
const BarGraphTooltipDate = styled.p`
  width: 100%;
  font-size: 10px;
  font-weight: bold;
  color: #7591bc;
  margin-bottom: 3px;
`;

// 当月 | 目標
const BarGraphTooltipLabel = styled.p`
  width: 30%;
  font-size: 10px;
  color: #435878;
  margin-bottom: 3px;
`;
// データ
const BarGraphTooltipData = styled.p`
  width: 70%;
  text-align: right;
  font-size: 10px;
  font-weight: bold;
  color: #435878;
  margin-bottom: 3px;
`;
// 仕切り線
const BarGraphTooltipHR = styled.hr`
  width: 100%;
  height: 1px;
  color: lightgray;
  margin-bottom: 3px;
`;
