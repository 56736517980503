function numFormatter(num:any) {
  if((num > 999 && num < 1000000)　|| (num < -999 && num > -1000000)){
      return (num/1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
  }else if((num > 99999 && num < 1000000000) || (num < -99999 && num > -1000000000)){
      return (num/1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
  }else if(num <= -1000000000){
    return (num/1000000000).toFixed(1) + 'B';
  }else if((num < 1000) || (num > -1000)){
      return num; // if value < 1000, nothing to do
  }
}

export const yAxisFormatter = (result:number, target_data_name:string) => {
  switch(target_data_name) {
    case 'revenue':  
      return (
        result && isNaN(numFormatter(result)) 
        ? `¥${numFormatter(result)}`
        : numFormatter(result).toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })
      )
    case 'mrr_total_value':  
      return (
        result && isNaN(numFormatter(result)) 
        ? `¥${numFormatter(result)}`
        : numFormatter(result).toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })
      )
    case 'unit_economics':  
      return result && result.toFixed(1)
    case 'cac':  
      return (
        result && isNaN(numFormatter(result)) 
        ? `¥${numFormatter(result)}`
        : numFormatter(result).toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })
      )
    case 'payback_period':  
      return result && result.toLocaleString() + 'month'
    case 'ltv':  
      return (
        result && isNaN(numFormatter(result)) 
        ? `¥${numFormatter(result)}`
        : numFormatter(result).toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })
      )
    case 'arpu':  
      return (
        result && isNaN(numFormatter(result)) 
        ? `¥${numFormatter(result)}`
        : numFormatter(result).toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })
      )
    case 'churn_rate':  
      return result && Number((result * 100).toFixed(1)).toLocaleString() + '%'
    case 'mrr_churn_rate':  
      return result && Number((result * 100).toFixed(1)).toLocaleString() + '%'
    case 'nrr':  
      return result && Number((result * 100).toFixed(1)).toLocaleString() + '%'
    case 'grr':  
      return result && Number((result * 100).toFixed(1)).toLocaleString() + '%'
    case 'quick_ratio':
      return result && result.toFixed(1)
    case 'acv_cumulative':  
    return (
      result && isNaN(numFormatter(result)) 
      ? `¥${numFormatter(result)}`
      : numFormatter(result).toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })
    )
    case 'acv_new':  
    return (
      result && isNaN(numFormatter(result)) 
      ? `¥${numFormatter(result)}`
      : numFormatter(result).toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })
    )
    case 'cpa':  
    return (
      result && isNaN(numFormatter(result)) 
      ? `¥${numFormatter(result)}`
      : numFormatter(result).toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })
    )
    case 'conversion_rate':  
      return result && Number((result * 100).toFixed(1)).toLocaleString() + '%'
    case 'client_churned':  
      return (
        result && isNaN(numFormatter(result)) 
        ? `${numFormatter(result)} 社`
        : numFormatter(result).toLocaleString() + ' 社'
      )
    case 'account_churned':  
      return(
        result && isNaN(numFormatter(result)) 
        ? `${numFormatter(result)} 個`
        : numFormatter(result).toLocaleString() + ' 個'
      ) 
    case 'revenue_churned':  
      return (
        result && isNaN(numFormatter(result)) 
        ? `¥${numFormatter(result)}`
        : numFormatter(result).toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })
      )
    case 'new_customer':  
      return (
        result && isNaN(numFormatter(result)) 
        ? `${numFormatter(result)} 社`
        : numFormatter(result).toLocaleString() + ' 社'
      )

    case 'active_customer':  
      return (
        result && isNaN(numFormatter(result)) 
        ? `${numFormatter(result)} 社`
        : numFormatter(result).toLocaleString() + ' 社'
      )

    case 'funnel':  
      return (result !== null && result !== undefined ) ? result : 0;

    case 'transition_rate':  
    return result && Number((result * 100).toFixed(1)).toLocaleString() + '%'

    case 'sale_cost_ratio':  
    return result*100 + '%'
    default:
  }
}