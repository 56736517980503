function churnRateRowsGenerator(raw_data_for_cohort: any, target_data: string, target_filter_key: string, target_filter_value: string, target_span: number) {
  let churn_rate_raw_data: any;
  switch(target_data) {
    case 'churn_rate':
      churn_rate_raw_data = raw_data_for_cohort.map((r: any) => {
        return {
          result_at: r.result_at,
          churn_data: r.churn_rate.churn_rate_total　!== null ? r.churn_rate.churn_rate_total : 0,
          data_key: target_data
        }
      });
      break;
    case 'revenue_churned':
      churn_rate_raw_data = raw_data_for_cohort.map((r: any) => {
        console.log('CASE: revenue_churned')
        return {
          result_at: r.result_at,
          churn_data: r.revenue_churned.revenue_churned_total !== null ? r.revenue_churned.revenue_churned_total : 0,
          data_key: target_data
        }
      });
      break;
    case 'client_churned':
      churn_rate_raw_data = raw_data_for_cohort.map((r: any) => {
        console.log('CASE: client_churned')
        return {
          result_at: r.result_at,
          churn_data: r.client_churned.client_churned_total !== null ? r.client_churned.client_churned_total : 0,
          data_key: target_data
        }
      });
      break;
    default:
      churn_rate_raw_data = raw_data_for_cohort.map((r: any) => {
        return {
          result_at: r.result_at,
          churn_data: r.churn_rate.churn_rate_total !== null ? r.churn_rate.churn_rate_total : 0,
          data_key: target_data
        }
      });
  }
  console.log('churn_rate_raw_data',churn_rate_raw_data)
  // ここからchurn_rate_raw_dataを使いたい形に整形
  const churn_rate_rows = churn_rate_raw_data.map((item: any, index:number) => {
    return [
      ...churn_rate_raw_data.slice(index)
    ]
  })
  const filled_churn_raw = churn_rate_rows.map((item: any, index:number) => {
    for(let cell = item.length; cell < target_span; cell++) {
      item.push({churn_data:""}) 
    }
    return item
  })
  return filled_churn_raw;
}

export default churnRateRowsGenerator;