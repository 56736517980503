import { FETCH_FUNNELS_SUMMARY, FETCH_FUNNELS_DETAIL, GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_FUNNEL_SUMMARY, GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_FUNNEL_DETAIL, TOGGLE_ACTION_ACHIVEMENT_SELECTED_NAME_LIST, TOGGLE_ACHIVEMENT_COST_SELECTED_NAME_LIST, ACHIVEMENT_COST_SELECTED_NAME_LIST_SHOW_ALL,ACTION_ACHIVEMENT_SELECTED_NAME_LIST_SHOW_ALL } from "./types"

export const fetchFunnelsSummary = (raw_data:any) => {
  return {
    type: FETCH_FUNNELS_SUMMARY,
    raw_data
  }
}

export const getResultsRequestForSpanComparisonFunnelSummary = (resultSpan: string, company_id: string, startDate: any, endDate: any, spanOrder: string) => {
  return {
    type: GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_FUNNEL_SUMMARY,
    resultSpan,
    company_id,
    startDate,
    endDate,
    spanOrder,
  }
}

export const getResultsRequestForSpanComparisonFunnelDetail = (resultSpan: string, company_id: string, startDate: any, endDate: any, spanOrder: string) => {
  return {
    type: GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_FUNNEL_DETAIL,
    resultSpan,
    company_id,
    startDate,
    endDate,
    spanOrder,
  }
}

export const toggleActionAchievementSelectedNameList = (selectedName: string) => {
  return {
    type: TOGGLE_ACTION_ACHIVEMENT_SELECTED_NAME_LIST,
    selectedName,
  }
}
export const toggleAchivementCostSelectedNameList = (selectedName: string) => {
  return {
    type: TOGGLE_ACHIVEMENT_COST_SELECTED_NAME_LIST,
    selectedName,
  }
}

export const actionAchievementSelectedNameListShowAll = () => {
  return {
    type: ACTION_ACHIVEMENT_SELECTED_NAME_LIST_SHOW_ALL,
  }
}
export const achivementCostSelectedNameListShowAll = () => {
  return {
    type: ACHIVEMENT_COST_SELECTED_NAME_LIST_SHOW_ALL,
  }
}
