import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import color from '../../style/color'
import { unitSwitcher } from '../../Util/unitSwitcher'
import { dateFormatter} from '../../Util/DateFormatter'

function ForecastPLTableSpanFilter(props: any) {
  const resultSpan = useSelector((state: any) => state.target_data.resultSpan);
  console.log('resultSpan',resultSpan)

  // const target_data = useSelector((state: any) => state.results.target_data);
  const target_data = useSelector((state: any) => state.target_data.target_data);

  let spanComparisonData:[];
  const target_data_first_span = useSelector((state: any) => state.target_data.target_data_first_span);
  const first_span_start = useSelector((state: any) => state.target_data.first_span_start);
  const first_span_end = useSelector((state: any) => state.target_data.first_span_end);
  const target_data_second_span = useSelector((state: any) => state.target_data.target_data_second_span);
  const second_span_start = useSelector((state: any) => state.target_data.second_span_start);
  const second_span_end = useSelector((state: any) => state.target_data.second_span_end);

  console.log('start', new Date(first_span_start))
  console.log('end', new Date(first_span_end))

  spanComparisonData = target_data_first_span.map((data: any, index: number) => {
    return { ...data, ...target_data_second_span[index] };
  });

  const data_divider = ([...array], size: number) => {
    return spanComparisonData.reduce((acc: any, value: number, index: number) => index % size ? acc : [...acc, array.slice(index, index + size)], [])
  }

  const [width, setWidth] = useState(window.innerWidth)

  const getWindowSize = () => {
    setWidth(window.innerWidth)
    return width
  }  

  window.onresize = getWindowSize

  useEffect(() => {
    setWidth(window.innerWidth)
  },[width])

  const table_width = width - 630
  const table_body_width = table_width - 150
  const cell_quantity = Math.floor(table_body_width / 150)
  const divided_data = data_divider(spanComparisonData, cell_quantity)
  console.log('divided_data',divided_data)

  return (
    <div style={{display:"flex", flexDirection:"column"}}>
      {
        divided_data.map((results:[]) => {
          console.log('results',results)
          return (
            <DataTableSC id="table_width">
              <DataHeader>
                <p style={{ width: "150px" }}>&nbsp;</p>
                {
                  // dailyかweeklyだったら一日単位で表示
                  resultSpan !== "monthly" && target_data && results.length > 0 &&
                    results.map((result: any) => (
                      <p>
                        {result.first_result_at.seconds === undefined ? "データ不足" : `${new Date(result.first_result_at.seconds * 1000).getFullYear()}`}/{`${new Date(result.first_result_at.seconds * 1000).getMonth() + 1}`}/{`${new Date(result.first_result_at.seconds * 1000).getDate()}`}
                      </p>
                    ))
                }
                {
                  // monthlyだったら月まで表示
                  resultSpan === "monthly" && results && results.length > 0 &&
                    results.map((result: any) => (
                      <p>
                        {result.first_result_at.seconds === undefined ? "データ不足" : `${new Date(result.first_result_at.seconds * 1000).getFullYear()}`}/{`${new Date(result.first_result_at.seconds * 1000).getMonth() + 1}`}
                      </p>
                    ))
                }
              </DataHeader>
              <DataBody style={{display: "flex"}}>
                
                {/* DataTitle(ul) */}
                <DataTitleSC>
                  <TitleItemSC>
                      <h4 className="first_span">[{`${dateFormatter(resultSpan,first_span_start)} - ${dateFormatter(resultSpan,first_span_end)}`}]<br/>当月:売上</h4>
                  </TitleItemSC>
                  <TitleItemSC>
                      <h4 className="second_span">[{`${dateFormatter(resultSpan,second_span_start)} - ${dateFormatter(resultSpan,second_span_end)}`}]<br/>当月:売上</h4>
                  </TitleItemSC>
                  <TitleItemSC>
                      <h4 className="first_span">[{`${dateFormatter(resultSpan,first_span_start)} - ${dateFormatter(resultSpan,first_span_end)}`}]<br/>移動平均:売上</h4>
                  </TitleItemSC>
                  <TitleItemSC>
                      <h4 className="second_span">[{`${dateFormatter(resultSpan,second_span_start)} - ${dateFormatter(resultSpan,second_span_end)}`}]<br/>移動平均:売上</h4>
                  </TitleItemSC>
                  <TitleItemSC>
                      <h4 className="first_span">[{`${dateFormatter(resultSpan,first_span_start)} - ${dateFormatter(resultSpan,first_span_end)}`}]<br/>当月:原価</h4>
                  </TitleItemSC>
                  <TitleItemSC>
                      <h4 className="second_span">[{`${dateFormatter(resultSpan,second_span_start)} - ${dateFormatter(resultSpan,second_span_end)}`}]<br/>当月:原価</h4>
                  </TitleItemSC>
                  <TitleItemSC>
                      <h4 className="first_span">[{`${dateFormatter(resultSpan,first_span_start)} - ${dateFormatter(resultSpan,first_span_end)}`}]<br/>移動平均:原価</h4>
                  </TitleItemSC>
                  <TitleItemSC>
                      <h4 className="second_span">[{`${dateFormatter(resultSpan,second_span_start)} - ${dateFormatter(resultSpan,second_span_end)}`}]<br/>移動平均:原価</h4>
                  </TitleItemSC>
                  <TitleItemSC>
                      <h4 className="first_span">[{`${dateFormatter(resultSpan,first_span_start)} - ${dateFormatter(resultSpan,first_span_end)}`}]<br/>当月:売上総利益</h4>
                  </TitleItemSC>
                  <TitleItemSC>
                      <h4 className="second_span">[{`${dateFormatter(resultSpan,second_span_start)} - ${dateFormatter(resultSpan,second_span_end)}`}]<br/>当月:売上総利益</h4>
                  </TitleItemSC>
                  <TitleItemSC>
                      <h4 className="first_span">[{`${dateFormatter(resultSpan,first_span_start)} - ${dateFormatter(resultSpan,first_span_end)}`}]<br/>移動平均:売上総利益</h4>
                  </TitleItemSC>
                  <TitleItemSC>
                      <h4 className="second_span">[{`${dateFormatter(resultSpan,second_span_start)} - ${dateFormatter(resultSpan,second_span_end)}`}]<br/>移動平均:売上総利益</h4>
                  </TitleItemSC>
                  <TitleItemSC>
                      <h4 className="first_span">[{`${dateFormatter(resultSpan,first_span_start)} - ${dateFormatter(resultSpan,first_span_end)}`}]<br/>当月:販売管理費</h4>
                  </TitleItemSC>
                  <TitleItemSC>
                      <h4 className="second_span">[{`${dateFormatter(resultSpan,second_span_start)} - ${dateFormatter(resultSpan,second_span_end)}`}]<br/>当月:販売管理費</h4>
                  </TitleItemSC>
                  <TitleItemSC>
                      <h4 className="first_span">[{`${dateFormatter(resultSpan,first_span_start)} - ${dateFormatter(resultSpan,first_span_end)}`}]<br/>移動平均:販売管理費</h4>
                  </TitleItemSC>
                  <TitleItemSC>
                      <h4 className="second_span">[{`${dateFormatter(resultSpan,second_span_start)} - ${dateFormatter(resultSpan,second_span_end)}`}]<br/>移動平均:販売管理費</h4>
                  </TitleItemSC>
                  <TitleItemSC>
                      <h4 className="first_span">[{`${dateFormatter(resultSpan,first_span_start)} - ${dateFormatter(resultSpan,first_span_end)}`}]<br/>当月:営業利益</h4>
                  </TitleItemSC>
                  <TitleItemSC>
                      <h4 className="second_span">[{`${dateFormatter(resultSpan,second_span_start)} - ${dateFormatter(resultSpan,second_span_end)}`}]<br/>当月:営業利益</h4>
                  </TitleItemSC>
                  <TitleItemSC>
                      <h4 className="first_span">[{`${dateFormatter(resultSpan,first_span_start)} - ${dateFormatter(resultSpan,first_span_end)}`}]<br/>移動平均:営業利益</h4>
                  </TitleItemSC>
                  <TitleItemSC>
                      <h4 className="second_span">[{`${dateFormatter(resultSpan,second_span_start)} - ${dateFormatter(resultSpan,second_span_end)}`}]<br/>移動平均:営業利益</h4>
                  </TitleItemSC>
                </DataTitleSC>

                <DataContents style={{ display: "flex" }}>
                  {
                    results.map((result:any) => {
                      return (
                        <DataContent style={{height:"auto"}} className="DataContent">
                          {/* 売上 */}
                          <li>
                            {/* 期間１：当月 */}
                            <ContentItem>
                              {result && result.first_forecasts_revenue && unitSwitcher(result.first_forecasts_revenue, 'revenue')}
                            </ContentItem>
                          </li>
                          <li>
                            {/* 期間２：当月 */}
                            <ContentItem>
                              {result && result.second_forecasts_revenue && unitSwitcher(result.second_forecasts_revenue, 'revenue')}
                            </ContentItem>
                          </li>
                          <li>
                            {/* 期間１：移動平均 */}
                            <ContentItem>
                              {result && result.first_forecasts_revenue_moving_average && unitSwitcher(result.first_forecasts_revenue_moving_average, 'revenue')}
                            </ContentItem>
                          </li>
                          <li>
                            {/* 期間２：移動平均 */}
                            <ContentItem>
                              {result && result.second_forecasts_revenue_moving_average && unitSwitcher(result.second_forecasts_revenue_moving_average, 'revenue')}
                            </ContentItem>
                          </li>

                          {/* 原価 */}
                          <li>
                            {/* 期間１：当月 */}
                            <ContentItem>
                              {result && result.first_forecasts_unit_cost && unitSwitcher(result.first_forecasts_unit_cost, 'cost')}
                            </ContentItem>
                          </li>
                          <li>
                            {/* 期間２：当月 */}
                            <ContentItem>
                              {result && result.second_forecasts_unit_cost && unitSwitcher(result.second_forecasts_unit_cost, 'cost')}
                            </ContentItem>
                          </li>
                          <li>
                            {/* 期間１：移動平均 */}
                            <ContentItem>
                              {result && result.first_forecasts_unit_cost_moving_average && unitSwitcher(result.first_forecasts_unit_cost_moving_average, 'cost')}
                            </ContentItem>
                          </li>
                          <li>
                            {/* 期間２：移動平均 */}
                            <ContentItem>
                              {result && result.second_forecasts_unit_cost_moving_average && unitSwitcher(result.second_forecasts_unit_cost_moving_average, 'cost')}
                            </ContentItem>
                          </li>

                          {/* 売上総合利益 */}
                          <li>
                            {/* 期間１：当月 */}
                            <ContentItem>
                              {result && result.first_forecasts_gross_profit && unitSwitcher(result.first_forecasts_gross_profit, 'profit')}
                            </ContentItem>
                          </li>
                          <li>
                            {/* 期間２：当月 */}
                            <ContentItem>
                              {result && result.second_forecasts_gross_profit && unitSwitcher(result.second_forecasts_gross_profit, 'profit')}
                            </ContentItem>
                          </li>
                          <li>
                            {/* 期間１：移動平均 */}
                            <ContentItem>
                              {result && result.first_forecasts_gross_profit_moving_average && unitSwitcher(result.first_forecasts_gross_profit_moving_average, 'profit')}
                            </ContentItem>
                          </li>
                          <li>
                            {/* 期間２：移動平均 */}
                            <ContentItem>
                              {result && result.second_forecasts_gross_profit_moving_average && unitSwitcher(result.second_forecasts_gross_profit_moving_average, 'profit')}
                            </ContentItem>
                          </li>

                          {/* 販売管理費 */}
                          <li>
                            {/* 期間１：当月 */}
                            <ContentItem>
                              {result && result.first_forecasts_svg_cost && unitSwitcher(result.first_forecasts_svg_cost, 'cost')}
                            </ContentItem>
                          </li>
                          <li>
                            {/* 期間２：当月 */}
                            <ContentItem>
                              {result && result.second_forecasts_svg_cost && unitSwitcher(result.second_forecasts_svg_cost, 'cost')}
                            </ContentItem>
                          </li>
                          <li>
                            {/* 期間１：移動平均 */}
                            <ContentItem>
                              {result && result.first_forecasts_svg_cost_moving_average && unitSwitcher(result.first_forecasts_svg_cost_moving_average, 'cost')}
                            </ContentItem>
                          </li>
                          <li>
                            {/* 期間２：移動平均 */}
                            <ContentItem>
                              {result && result.second_forecasts_svg_cost_moving_average && unitSwitcher(result.second_forecasts_svg_cost_moving_average, 'cost')}
                            </ContentItem>
                          </li>

                          {/* 営業利益 */}
                          <li>
                            {/* 期間１：当月 */}
                            <ContentItem>
                              {result && result.first_forecasts_profit && unitSwitcher(result.first_forecasts_profit, 'profit')}
                            </ContentItem>
                          </li>
                          <li>
                            {/* 期間２：当月 */}
                            <ContentItem>
                              {result && result.second_forecasts_profit && unitSwitcher(result.second_forecasts_profit, 'profit')}
                            </ContentItem>
                          </li>
                          <li>
                            {/* 期間１：移動平均 */}
                            <ContentItem>
                              {result && result.first_forecasts_profit_moving_average && unitSwitcher(result.first_forecasts_profit_moving_average, 'profit')}
                            </ContentItem>
                          </li>
                          <li>
                            {/* 期間２：移動平均 */}
                            <ContentItem>
                              {result && result.second_forecasts_profit_moving_average && unitSwitcher(result.second_forecasts_profit_moving_average, 'profit')}
                            </ContentItem>
                          </li>
                        </DataContent>
                      )
                    })
                  }
                </DataContents>
              </DataBody>
            </DataTableSC>
          )
        })
      }
    </div>
  );
}

export default ForecastPLTableSpanFilter;

// DataTable
const DataTableSC = styled.div`
  margin-bottom: 27px;
  border-bottom: 1px solid #e2e9f3;
`;
// DataHeader
const DataHeader = styled.div`
  width: 100%;
  box-sizing: border-box;
  color: #435878;
  display: flex;
  font-size: 10px;
  line-height: 15px;

  p {
    min-width: 150px;
    width: 100%;
    text-align: center;
    padding: 5px 0;
    border-right: 1px solid #e2e9f3;
    background-color: #f3f6fa;
    color: #555;
    font-size: 10px;
    &:first-child {
      width: 150px;
      position: absolute;
      background-color: #f3f6fa;
      //left: 0;
    }
    &:nth-child(2) {
      margin-left: 150px;
    }
    &:last-child {
      border-right: none;
    }
  }
`;
// DataBody
const DataBody = styled.div`
  width: 100%;
  align-items: flex-start;
`;
// DataTitle
const DataTitleSC = styled.ul`
  color: #435878;
  min-width: 150px;
  position: absolute;
  //left: 0;
  border-right: 1px solid #e2e9f3;
  background-color: #fff;
`;

const TitleItemSC = styled.li`
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  width: 100%;
  input {
    display: none;
  }
  h4 {
    height: 40px;
    font-size: 11px;
    padding: 3px 0 0 20px;
    border-bottom: 1px solid #e2e9f3;
    &.first_span {
      &::before {
        content: "●";
        display: inline-block;
        height: 13px;
        width: 13px;
        left: 5px;
        color: ${color.primary};
        position: absolute;
      }
    }
    &.second_span {
      &::before  {
        content: "●";
        display: inline-block;
        height: 13px;
        width: 13px;
        left: 5px;
        color: ${color.secondary};
        position: absolute;
      }
    }
  label {
    width: 100%;
  }
}
`

// DataContents
const DataContents = styled.div`
  width: calc(100% - 150px);
  margin-left: 150px;
`;
// DataContent
const DataContent = styled.ul`
  font-size: 12px;
  min-width: 150px;
  width: 100%;
  border-right: 1px solid #e2e9f3;
  &:last-child {
    border-right: none;
  }
  &:hover, &.activeDotShown {
    background-color: rgba(0, 102, 255, 0.05);
  }
`;

const ContentItem = styled.h4`
  width: 100%;
  height: 40px;
  font-size: 12px;
  line-height: 18px;
  padding: 12px 10px 12px;
  text-align: right;
  border-bottom: 1px solid #e2e9f3;
  position: relative;
  color: #35455D;
`;
