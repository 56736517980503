const SET_FORECASTS = 'SET_FORECASTS';
const TOGGLE_TIME_SPAN_COMPARISON_FILTER_DISPLAY = 'TOGGLE_TIME_SPAN_COMPARISON_FILTER_DISPLAY';
const GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_FORECASTS = 'GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_FORECASTS';
const GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_METRICS_FORECASTS = 'GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_METRICS_FORECASTS';
const GET_RESULTS_FOR_SPAN_COMPARISON_FORECASTS_SUCCEEDED = 'GET_RESULTS_FOR_SPAN_COMPARISON_FORECASTS_SUCCEEDED';
const GET_RESULTS_FOR_SPAN_COMPARISON_FORECASTS_FAILED = 'GET_RESULTS_FOR_SPAN_COMPARISON_FORECASTS_FAILED';
const GET_RESULTS_FOR_SPAN_COMPARISON_METRICS_FORECASTS_SUCCEEDED = 'GET_RESULTS_FOR_SPAN_COMPARISON_METRICS_FORECASTS_SUCCEEDED';
const GET_RESULTS_FOR_SPAN_COMPARISON_METRICS_FORECASTS_FAILED = 'GET_RESULTS_FOR_SPAN_COMPARISON_METRICS_FORECASTS_FAILED';

const GET_RESULTS_REQUESTED_FOR_FORECAST = 'GET_RESULTS_REQUESTED_FOR_FORECAST';
const GET_RESULTS_REQUESTED_FOR_METRICS_FORECAST = 'GET_RESULTS_REQUESTED_FOR_METRICS_FORECAST';
const GET_RESULTS_REQUESTED_FOR_FORECAST_SUCCEEDED = 'GET_RESULTS_REQUESTED_FOR_FORECAST_SUCCEEDED';
const GET_RESULTS_REQUESTED_FOR_FORECAST_FAILED = 'GET_RESULTS_REQUESTED_FOR_FORECAST_FAILED';
const GET_RESULTS_REQUESTED_FOR_METRICS_FORECAST_SUCCEEDED = 'GET_RESULTS_REQUESTED_FOR_METRICS_FORECAST_SUCCEEDED';
const GET_RESULTS_REQUESTED_FOR_METRICS_FORECAST_FAILED = 'GET_RESULTS_REQUESTED_FOR_METRICS_FORECAST_FAILED';
const TOGGLE_FORECAST_FILTER = 'TOGGLE_FORECAST_FILTER';
const SET_FORECAST_FILTER = 'SET_FORECAST_FILTER';
const RESET_FORECAST_FILTER = 'RESET_FORECAST_FILTER';
const TOGGLE_METRICS_FORECAST_FILTER = 'TOGGLE_METRICS_FORECAST_FILTER';

const UPDATE_FORECAST_SIMULATED_RESULT = 'UPDATE_FORECAST_SIMULATED_RESULT';
const UPDATE_METRICS_FORECAST_SIMULATED_RESULT = 'UPDATE_METRICS_FORECAST_SIMULATED_RESULT';

export { SET_FORECASTS, TOGGLE_TIME_SPAN_COMPARISON_FILTER_DISPLAY, GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_FORECASTS, GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_METRICS_FORECASTS, GET_RESULTS_FOR_SPAN_COMPARISON_FORECASTS_SUCCEEDED, GET_RESULTS_FOR_SPAN_COMPARISON_FORECASTS_FAILED, GET_RESULTS_REQUESTED_FOR_FORECAST, GET_RESULTS_REQUESTED_FOR_FORECAST_SUCCEEDED, GET_RESULTS_REQUESTED_FOR_FORECAST_FAILED, GET_RESULTS_FOR_SPAN_COMPARISON_METRICS_FORECASTS_SUCCEEDED, GET_RESULTS_FOR_SPAN_COMPARISON_METRICS_FORECASTS_FAILED, GET_RESULTS_REQUESTED_FOR_METRICS_FORECAST, GET_RESULTS_REQUESTED_FOR_METRICS_FORECAST_SUCCEEDED, GET_RESULTS_REQUESTED_FOR_METRICS_FORECAST_FAILED, TOGGLE_FORECAST_FILTER, TOGGLE_METRICS_FORECAST_FILTER, UPDATE_FORECAST_SIMULATED_RESULT, UPDATE_METRICS_FORECAST_SIMULATED_RESULT, SET_FORECAST_FILTER, RESET_FORECAST_FILTER };
