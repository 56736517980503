import React, { useEffect, useState } from "react";
import ForecastPLIndex from "./ForecastPL/ForecastPLIndex";
import ForecastSaleCostIndex from "./ForecastSaleCost/ForecastSaleCostIndex";
import ForecastMetricsIndex from "./ForecastMetrics/ForecastMetricsIndex";
import ForecastFunnelsIndex from "./ForecastFunnels/ForecastFunnelsIndex";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import GlobalNav from "../Layout/GlobalNav";
import PageHeader from "../Layout/PageHeader";
import PageHeaderForForecast from "../Layout/PageHeaderForForecast";
import LoadingCover from "../LoadingCover";
import { setWidth } from "../../reducks/sessions/actions";
import { useLocation } from "react-router-dom";
import { startTargetDataSequence } from "../../reducks/targetData/actions";

function Forecasts() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const activatedFilter = useSelector((state: any) => state.filter);
  const forecast_filter_status = useSelector(
    (state: any) => state.target_forecast.forecast_filter_status
  );
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  console.log("innerWidth", innerWidth);

  const getWindowSize = () => {
    setInnerWidth(window.innerWidth);
    dispatch(setWidth(innerWidth));
  };

  window.onresize = getWindowSize;

  const location = useLocation();
  const pathname = location.pathname;

  // GA計測タグ
  useEffect(() => {
    document.title = "Forecast"; // ページタイトルを変更
    window.gtag("config", "G-YXXD65BEJ0", {
      page_path: pathname // 任意のパス（あるいはprops.location.pathnameなど）
    });
  });

  const raw_data_array = useSelector(
    (state: any) => state.raw_data.raw_data_array
  );

  const selected_data_config_data_type = useSelector(
    (state: any) => state.raw_data.selected_data_config.data_type
  );

  useEffect(() => {
    dispatch(
      startTargetDataSequence(
        "/forecasts",
        raw_data_array,
        selected_data_config_data_type
      )
    );
  }, [dispatch, location, raw_data_array, selected_data_config_data_type]);

  return (
    <Container>
      <GlobalNav />

      <main className={classes.content}>
        {forecast_filter_status === "reset_forecast" ? (
          <PageHeader page_title="事業計画" />
        ) : (
          <PageHeaderForForecast page_title="事業計画" />
        )}
        {/* <PageHeader page_title="事業計画" /> */}

        <div className={classes.globalCard}>
          <ForecastsContent>
            <LoadingCover />
            <ScrollContent>
              {
                // span_select_modal_is_shown &&
                // ==========================追加カード選択モーダル=======================================
                // <ModalBackGround />
              }

              {/* PL */}
              <ForecastPLIndex />

              <ForecastSaleCostIndex />

              {/* Metrics */}
              {activatedFilter === "" &&
                forecast_filter_status === "reset_forecast" && (
                  <ForecastMetricsIndex />
                )}

              {/* ForecastFunnels */}
              {/* {
                activatedFilter === '' && forecast_filter_status === 'reset_forecast' &&
                <ForecastFunnelsIndex />
              } */}
            </ScrollContent>
          </ForecastsContent>
        </div>
      </main>
    </Container>
  );
}

export default Forecasts;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      backgroundColor: "#F4F4F4",
      width: "calc(100% - 264px)"
    },
    globalCard: {
      display: "flex",
      justifyContent: "space-between",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    },
    nav: {
      width: 240
    },
    cell: {
      fontWeight: "normal"
    }
  })
);

// Container
const Container = styled.div`
  display: flex;
  min-width: 1000px;
`;
// ForecastsContent
const ForecastsContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 15px 17px;
  box-sizing: border-box;
`;
//ScrollContent {
const ScrollContent = styled.div`
  max-width: 100%;
`;
