import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { toggleMovingAverageGraphSwitch } from "../../../reducks/sessions/actions";

//  lib components
import {
  Collapse,
  IconButton,
  TableBody,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";

//  styled components
import {
  StyledTable,
  StyledCell,
  StickyCell,
  StickerHeadCell,
  StickyRow,
  StyledTableRow,
  Wrapper,
  SvgImage,
  CaretSvgImage,
  StyledButton,
  CellText,
  CellContainer
} from "./styles";

//  modules
import { generateTableData } from "./modules";

//  types
import { TargetData, FunnelData, Prefix, GraphData } from "./types";

//  assest
import UpArrow from "../../../images/icons/reached_arrow.svg";
import DownArrow from "../../../images/icons/unreached_icon.svg";

interface DraftRevenueMrrTableProps {
  dataType: "metrics" | "funnel";
  prefix?: Prefix;
  targetData?: TargetData[];
  funnelData?: FunnelData[];
  graphData?: GraphData[];
}
export const DraftRevenueMrrTable: React.FC<DraftRevenueMrrTableProps> =
  props => {
    const { dataType, targetData, funnelData, prefix, graphData } = props;
    console.log("funnelData", funnelData);

    //  hooks
    const dispatch = useDispatch();
    const [breakdownOpen, setBreakdownOpen] = React.useState(false);

    //  modules
    const tableData = generateTableData({
      dataType,
      prefix,
      targetData,
      funnelData,
      graphData
    });
    console.log("tableData", tableData);

    return (
      <React.Fragment>
        <Wrapper>
          {/* 【 実績 】 目標・実績・達成率 */}
          <StyledTable>
            {/* Head */}
            <TableHead>
              <TableRow>
                <StickerHeadCell style={{ zIndex: 1 }} />
                {tableData.head.map((val, i) => (
                  <StickerHeadCell key={i}>{val.value}</StickerHeadCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              <StickyRow>　</StickyRow>

              {/* 実績 */}
              <StyledTableRow>
                <StickyCell style={{ fontWeight: "bolder" }}>
                  <CellContainer>
                    <CellText style={{ fontWeight: "bold" }}>MRR比率</CellText>
                    {/* {tableData.body.breakdown && (
                      <IconButton
                        size="small"
                        onClick={() => setBreakdownOpen(!breakdownOpen)}
                      >
                        <CaretSvgImage open={breakdownOpen} />
                      </IconButton>
                    )} */}
                  </CellContainer>
                </StickyCell>
                {tableData.body.mrrRate.map((val, i) => {
                  return (
                    <StyledCell key={i} style={{ fontWeight: "bolder" }}>
                      {val.label}
                    </StyledCell>
                  );
                })}
              </StyledTableRow>
            </TableBody>
          </StyledTable>
          <Collapse in={breakdownOpen}>
            <StyledTable>
              <TableBody>
                {tableData.body.breakdown?.map((val, i) => (
                  <StyledTableRow>
                    <StickyCell indent={1} style={{ borderTop: "none" }}>
                      <CellText>{val.name}</CellText>
                    </StickyCell>
                    {val.data.map((val, i) => (
                      <StyledCell key={i} style={{ borderTop: "none" }}>
                        {val.label}
                      </StyledCell>
                    ))}
                  </StyledTableRow>
                ))}
              </TableBody>
            </StyledTable>
          </Collapse>

          <StyledTable>
            <TableBody>
              {/* 目標 */}
              <StyledTableRow>
                <StickyCell style={{ borderTop: "none" }}>
                  <CellText>MRR</CellText>
                </StickyCell>
                {tableData.body.totalMrr.map((val, i) => {
                  return (
                    <StyledCell key={i} style={{ borderTop: "none" }}>
                      {val.label}
                    </StyledCell>
                  );
                })}
              </StyledTableRow>

              <StyledTableRow>
                <StickyCell style={{ borderTop: "none" }}>
                  <CellText>その他売上</CellText>
                </StickyCell>
                {tableData.body.totalOthers.map((val, i) => {
                  return (
                    <StyledCell key={i} style={{ borderTop: "none" }}>
                      {val.label}
                    </StyledCell>
                  );
                })}
              </StyledTableRow>
            </TableBody>
          </StyledTable>
        </Wrapper>
      </React.Fragment>
    );
  };
