import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import GlobalNav from "../Layout/GlobalNav";
import PageHeaderRaw from "../Layout/PageHeaderNoCarendar";
import UploadNavCard from "./UploadNavCard";
import { useLocation } from "react-router-dom";
import TableUploadHistory from "../Table/TableUploadHistory";
import {
  uploadDataRequest,
  uploadDataStart,
  resetData
} from "../../reducks/data/actions";
import axios from "axios";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import "date-fns";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import jaLocale from "date-fns/locale/ja";
import Popup from "./Popup";
import color from "../style/color";
import close from "../../images/icons/close-large.svg";
import { NotificationManager } from "react-notifications";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      backgroundColor: "#F4F4F4"
    },
    configCard: {
      display: "flex",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    }
  })
);

let currentUploadPage: string;
let textHeading: string;

let endpoint: string;
let target: string;

const UploadPage: React.FC = () => {
  const location = useLocation();
  const pathName = location.pathname;
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState("");

  const dispatch = useDispatch();

  const { register, handleSubmit, errors, watch, reset } = useForm();

  //表示ページを取得する
  if (pathName === "/data/master-funnel") {
    currentUploadPage = "ファネル構成";
    textHeading = "データを削除する";
    endpoint = "/funnel";
    target = "funnel";
  } else if (pathName === "/data/master-leadsource-manager") {
    currentUploadPage = "リードソース・担当者";
    textHeading = "データを削除する";
    endpoint = "/lead/source";
    target = "lead_source";
  } else if (pathName === "/data/master-leadsource-opportunity-status") {
    currentUploadPage = "リード・商談ステータス";
    textHeading = "データを削除する";
    endpoint = "/lead/status";
    target = "lead_status";
  } else if (pathName === "/data/master-filter") {
    currentUploadPage = "フィルター";
    textHeading = "データを削除する";
    endpoint = "/filter";
    target = "filter";
  } else if (pathName === "/data/master-service-product") {
    currentUploadPage = "商品・サービス";
    textHeading = "データを削除する";
    endpoint = "/product";
    target = "product";
  } else if (pathName === "/data/master-cost") {
    currentUploadPage = "マスター：コスト";
    endpoint = "/cost";
  } else if (pathName === "/data/client") {
    currentUploadPage = "クライアント";
    textHeading = "データを削除する";
    endpoint = "/account";
    target = "account";
  } else if (pathName === "/data/lead-opportunity") {
    currentUploadPage = "リード・商談履歴";
    textHeading = "指定期間のデータを削除する";
    endpoint = "/opportunity";
    target = "opportunity";
  } else if (pathName === "/data/lead-event") {
    currentUploadPage = "行動履歴";
    textHeading = "指定期間のデータを削除する";
    endpoint = "/lead/action";
    target = "lead_action";
  } else if (pathName === "/data/revenue-actual") {
    currentUploadPage = "売上";
    textHeading = "指定期間のデータを削除する";
    endpoint = "/sales/result";
    target = "sales_result";
  } else if (pathName === "/data/upload-history") {
    currentUploadPage = "更新履歴";
    endpoint = "/data/upload-history";
  } else if (pathName === "/data/revenue-objective") {
    currentUploadPage = "売上目標";
    endpoint = "/sales/target";
  } else if (pathName === "/data/cost-actual") {
    currentUploadPage = "コスト";
    textHeading = "指定期間のデータを削除する";
    endpoint = "/cost/result";
    target = "cost_result";
  } else if (pathName === "/data/cost-objective") {
    currentUploadPage = "コスト目標";
    textHeading = "指定期間のデータを削除する";
    endpoint = "/cost/target";
    target = "cost_target";
  } else if (pathName === "/data/mrr-objective") {
    currentUploadPage = "重要指標";
    textHeading = "指定期間のデータを削除する";
    endpoint = "/mrr/objective";
    target = "mrr_objective";
  } else if (pathName === "/data/cost") {
    currentUploadPage = "コスト構成";
    textHeading = "データを削除する";
    endpoint = "/cost";
    target = "cost";
  } else {
    currentUploadPage = "No Matched";
  }

  const user = useSelector((state: any) => state.user);
  const salesforce_api_key = useSelector(
    (state: any) => state.session.salesforce_api_key
  );
  const salesforce_secret_key = useSelector(
    (state: any) => state.session.salesforce_secret_key
  );
  const access_token = useSelector((state: any) => state.session.access_token);
  const message = useSelector((state: any) => state.data.message);

  const formData = new FormData();
  const handleUpload = () => {
    if (watchSelectedFile && watchSelectedFile[0]) {
      formData.append("file", watchSelectedFile[0]);
      // formData.append('salesforce_api_key', user.salesforce_api_key)
      // formData.append('salesforce_api_secret', user.salesforce_api_secret)
      formData.append("salesforce_api_key", salesforce_api_key);
      formData.append("salesforce_api_secret", salesforce_secret_key);
      formData.append("email", user && user.email ? user.email : "");
      dispatch(uploadDataRequest(formData, endpoint));
      dispatch(uploadDataStart());
      setSelectedFile("");
    }
  };

  const watchSelectedFile = watch("selectedFile");

  const handleSelectFile = () => {
    if (watchSelectedFile && watchSelectedFile[0]) {
      setSelectedFile(watchSelectedFile[0].name);
    }
  };

  const handlePathChange = () => {
    reset();
    setSelectedFile("");
  };

  // GA計測タグ
  useEffect(() => {
    document.title = "データアップロード: " + currentUploadPage; // ページタイトルを変更
    window.gtag("config", "G-YXXD65BEJ0", {
      page_path: pathName // 任意のパス（あるいはprops.location.pathnameなど）
    });
  });
  /*********First Date */
  const [fullMonthYearFirstDash, setfullMonthYearFirstDash] = React.useState(
    new Date("2019-08-18T21:11:54")
  );
  const handleDateChange = (fullMonthYearFirstDash: any) => {
    setfullMonthYearFirstDash(fullMonthYearFirstDash);
  };
  var monthFirstNumber = ("0" + (fullMonthYearFirstDash.getMonth() + 1)).slice(
    -2
  );
  var startYear = fullMonthYearFirstDash.getFullYear();

  const firstYearMonth = startYear + "-" + monthFirstNumber;

  /*********End Date  */
  const [fullMonthYearEndDash, setfullMonthYearEndDash] = React.useState(
    new Date("2019-08-18T21:11:54")
  );
  const handleDateChangeEnd = (fullMonthYearEndDash: any) => {
    setfullMonthYearEndDash(fullMonthYearEndDash);
  };
  var monthFirstNumberEnd = ("0" + (fullMonthYearEndDash.getMonth() + 1)).slice(
    -2
  );
  var endYear = fullMonthYearEndDash.getFullYear();
  const endYearMonth = endYear + "-" + monthFirstNumberEnd;

  const handleRemove = async () => {
    const params = new URLSearchParams({
      from_month: firstYearMonth,
      to_month: endYearMonth,
      salesforce_api_key: salesforce_api_key,
      salesforce_api_secret: salesforce_secret_key
    }).toString();

    const options = {
      headers: { "Content-Type": "application/json" }
    };

    if (currentUploadPage === "商品・サービス") {
      console.log(salesforce_api_key);
      const params = new URLSearchParams({
        salesforce_api_key: salesforce_api_key,
        salesforce_api_secret: salesforce_secret_key
      }).toString();

      const options = {
        headers: { "Content-Type": "application/json" }
      };
      axios
        .delete(
          process.env.REACT_APP_UPLOAD_CSV_ENDPOINT + `/product?` + params,
          options
        )
        .then(response => {
          if (response.data.status === 1) {
            NotificationManager.success("削除しました");
          } else if (response.data.status !== 1) {
            NotificationManager.error("削除に失敗しました");
          }
        })
        .catch(err => {
          NotificationManager.error("システムエラーが発生しました");
        });
    } else if (currentUploadPage === "ファネル構成") {
      const params = new URLSearchParams({
        salesforce_api_key: salesforce_api_key,
        salesforce_api_secret: salesforce_secret_key
      }).toString();

      const options = {
        headers: { "Content-Type": "application/json" }
      };
      axios
        .delete(
          process.env.REACT_APP_UPLOAD_CSV_ENDPOINT + `/funnel?` + params,
          options
        )
        .then(response => {
          if (response.data.status === 1) {
            NotificationManager.success("削除しました");
          } else if (response.data.status !== 1) {
            NotificationManager.error("削除に失敗しました");
          }
        })
        .catch(err => {
          NotificationManager.error("システムエラーが発生しました");
        });
    } else if (currentUploadPage === "フィルター") {
      const params = new URLSearchParams({
        salesforce_api_key: salesforce_api_key,
        salesforce_api_secret: salesforce_secret_key
      }).toString();

      const options = {
        headers: { "Content-Type": "application/json" }
      };
      axios
        .delete(
          process.env.REACT_APP_UPLOAD_CSV_ENDPOINT + `/filter?` + params,
          options
        )
        .then(response => {
          if (response.data.status === 1) {
            NotificationManager.success("削除しました");
          } else if (response.data.status !== 1) {
            NotificationManager.error("削除に失敗しました");
          }
        })
        .catch(err => {
          NotificationManager.error("システムエラーが発生しました");
        });
    } else if (currentUploadPage === "リードソース・担当者") {
      const params = new URLSearchParams({
        salesforce_api_key: salesforce_api_key,
        salesforce_api_secret: salesforce_secret_key
      }).toString();

      const options = {
        headers: { "Content-Type": "application/json" }
      };
      axios
        .delete(
          process.env.REACT_APP_UPLOAD_CSV_ENDPOINT + `/lead/source?` + params,
          options
        )
        .then(response => {
          if (response.data.status === 1) {
            NotificationManager.success("削除しました");
          } else if (response.data.status !== 1) {
            NotificationManager.error("削除に失敗しました");
          }
        })
        .catch(err => {
          NotificationManager.error("システムエラーが発生しました");
        });
    } else if (currentUploadPage === "リード・商談ステータス") {
      const params = new URLSearchParams({
        salesforce_api_key: salesforce_api_key,
        salesforce_api_secret: salesforce_secret_key
      }).toString();

      const options = {
        headers: { "Content-Type": "application/json" }
      };
      axios
        .delete(
          process.env.REACT_APP_UPLOAD_CSV_ENDPOINT + `/lead/status?` + params,
          options
        )
        .then(response => {
          if (response.data.status === 1) {
            NotificationManager.success("削除しました");
          } else if (response.data.status !== 1) {
            NotificationManager.error("削除に失敗しました");
          }
        })
        .catch(err => {
          NotificationManager.error("システムエラーが発生しました");
        });
    } else if (currentUploadPage === "コスト構成") {
      const params = new URLSearchParams({
        from_month: "1919-08",
        to_month: "1919-08",
        salesforce_api_key: salesforce_api_key,
        salesforce_api_secret: salesforce_secret_key
      }).toString();

      const options = {
        headers: { "Content-Type": "application/json" }
      };
      axios
        .delete(
          process.env.REACT_APP_UPLOAD_CSV_ENDPOINT + `/cost?` + params,
          options
        )
        .then(response => {
          if (response.data.status === 1) {
            NotificationManager.success("削除しました");
          } else if (response.data.status !== 1) {
            NotificationManager.error("削除に失敗しました");
          }
        })
        .catch(err => {
          NotificationManager.error("システムエラーが発生しました");
        });
    } else if (currentUploadPage === "重要指標") {
      const params = new URLSearchParams({
        from_month: firstYearMonth,
        to_month: endYearMonth,
        salesforce_api_key: salesforce_api_key,
        salesforce_api_secret: salesforce_secret_key
      }).toString();

      const options = {
        headers: { "Content-Type": "application/json" }
      };
      axios
        .delete(
          process.env.REACT_APP_UPLOAD_CSV_ENDPOINT +
            `/mrr/objective?` +
            params,
          options
        )
        .then(response => {
          if (response.data.status === 1) {
            NotificationManager.success("削除しました");
          } else if (response.data.status !== 1) {
            NotificationManager.error("削除に失敗しました");
          }
        })
        .catch(err => {
          NotificationManager.error("システムエラーが発生しました");
        });
    } else if (currentUploadPage === "コスト目標") {
      const params = new URLSearchParams({
        from_month: firstYearMonth,
        to_month: endYearMonth,
        salesforce_api_key: salesforce_api_key,
        salesforce_api_secret: salesforce_secret_key
      }).toString();

      const options = {
        headers: { "Content-Type": "application/json" }
      };
      axios
        .delete(
          process.env.REACT_APP_UPLOAD_CSV_ENDPOINT + `/cost/target?` + params,
          options
        )
        .then(response => {
          if (response.data.status === 1) {
            NotificationManager.success("削除しました");
          } else if (response.data.status !== 1) {
            NotificationManager.error("削除に失敗しました");
          }
        })
        .catch(err => {
          NotificationManager.error("システムエラーが発生しました");
        });
    } else if (currentUploadPage === "売上") {
      const params = new URLSearchParams({
        from_month: firstYearMonth,
        to_month: endYearMonth,
        salesforce_api_key: salesforce_api_key,
        salesforce_api_secret: salesforce_secret_key
      }).toString();

      const options = {
        headers: { "Content-Type": "application/json" }
      };
      axios
        .delete(
          process.env.REACT_APP_UPLOAD_CSV_ENDPOINT + `/sales/result?` + params,
          options
        )
        .then(response => {
          if (response.data.status === 1) {
            NotificationManager.success("削除しました");
          } else if (response.data.status !== 1) {
            NotificationManager.error("削除に失敗しました");
          }
        })
        .catch(err => {
          NotificationManager.error("システムエラーが発生しました");
        });
    } else if (currentUploadPage === "コスト") {
      const params = new URLSearchParams({
        from_month: firstYearMonth,
        to_month: endYearMonth,
        salesforce_api_key: salesforce_api_key,
        salesforce_api_secret: salesforce_secret_key
      }).toString();

      const options = {
        headers: { "Content-Type": "application/json" }
      };
      axios
        .delete(
          process.env.REACT_APP_UPLOAD_CSV_ENDPOINT + `/cost/result?` + params,
          options
        )
        .then(response => {
          if (response.data.status === 1) {
            NotificationManager.success("削除しました");
          } else if (response.data.status !== 1) {
            NotificationManager.error("削除に失敗しました");
          }
        })
        .catch(err => {
          NotificationManager.error("システムエラーが発生しました");
        });
    } else if (currentUploadPage === "クライアント") {
      const params = new URLSearchParams({
        from_month: "1919-08",
        to_month: "1919-08",
        salesforce_api_key: salesforce_api_key,
        salesforce_api_secret: salesforce_secret_key
      }).toString();

      const options = {
        headers: { "Content-Type": "application/json" }
      };
      axios
        .delete(
          process.env.REACT_APP_UPLOAD_CSV_ENDPOINT + `/account?` + params,
          options
        )
        .then(response => {
          if (response.data.status === 1) {
            NotificationManager.success("削除しました");
          } else if (response.data.status !== 1) {
            NotificationManager.error("削除に失敗しました");
          }
        })
        .catch(err => {
          NotificationManager.error("システムエラーが発生しました");
        });
    } else if (currentUploadPage === "リード・商談履歴") {
      const params = new URLSearchParams({
        from_month: firstYearMonth,
        to_month: endYearMonth,
        salesforce_api_key: salesforce_api_key,
        salesforce_api_secret: salesforce_secret_key
      }).toString();

      console.log("lead/opportunity");

      const options = {
        headers: { "Content-Type": "application/json" }
      };
      axios
        .delete(
          process.env.REACT_APP_UPLOAD_CSV_ENDPOINT + `/opportunity?` + params,
          options
        )
        .then(response => {
          if (response.data.status === 1) {
            NotificationManager.success("削除しました");
          } else if (response.data.status !== 1) {
            NotificationManager.error("削除に失敗しました");
          }
        })
        .catch(err => {
          NotificationManager.error("システムエラーが発生しました");
        });
    } else if (currentUploadPage === "行動履歴") {
      const params = new URLSearchParams({
        from_month: firstYearMonth,
        to_month: endYearMonth,
        salesforce_api_key: salesforce_api_key,
        salesforce_api_secret: salesforce_secret_key
      }).toString();

      const options = {
        headers: { "Content-Type": "application/json" }
      };
      axios
        .delete(
          process.env.REACT_APP_UPLOAD_CSV_ENDPOINT + `/lead/action?` + params,
          options
        )
        .then(response => {
          if (response.data.status === 1) {
            NotificationManager.success("削除しました");
          } else if (response.data.status !== 1) {
            NotificationManager.error("削除に失敗しました");
          }
        })
        .catch(err => {
          NotificationManager.error("システムエラーが発生しました");
        });
    }
  };
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const updateSpreadsheet = async () => {
    axios
      .post(
        process.env.REACT_APP_API_HOST + `/sync/google_spreadsheet`,
        {
          salesforce_api_key: salesforce_api_key,
          salesforce_api_secret: salesforce_secret_key,
          target
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`
          }
        }
      )
      .then(response => {
        if (response.data.status === 1) {
          NotificationManager.success("更新しました");
        } else if (response.data.status !== 1) {
          NotificationManager.error("更新に失敗しました");
        }
      })
      .catch(err => {
        NotificationManager.error("システムエラーが発生しました");
      });
  };

  return (
    <Container>
      <GlobalNav />

      <main className={classes.content}>
        <PageHeaderRaw page_title="データ" />

        <div className={classes.configCard}>
          <UploadNavCard handlePathChange={handlePathChange} />
          {(() => {
            if (endpoint === "/data/upload-history") {
              return (
                <DataContent>
                  <DataTitle>{currentUploadPage}</DataTitle>
                  <TableUploadHistory />
                </DataContent>
              );
            } else {
              return (
                <DataContent>
                  <DataTitle>{currentUploadPage}</DataTitle>
                  <ErrorMessage>{message}</ErrorMessage>

                  <Form onSubmit={handleSubmit(handleUpload)}>
                    <Line>
                      <SelectedFileDisplay
                        type="text"
                        placeholder="選択したファイルが表示されます"
                        value={selectedFile}
                      />
                      {errors.lastName && (
                        <ErrorText>ファイルが選択されていません</ErrorText>
                      )}
                      <FakeInput
                        id="fileSelect"
                        type="file"
                        name="selectedFile"
                        onChange={handleSelectFile}
                        ref={register({ required: true })}
                      />
                    </Line>
                    <ButtonBlock>
                      <FileSelectButton as="label" htmlFor="fileSelect">
                        ファイルを選択
                      </FileSelectButton>
                      <Button className="send" type="submit">
                        データアップロード
                      </Button>
                    </ButtonBlock>
                    <DataTitleDP>{textHeading}</DataTitleDP>

                    {(() => {
                      if (textHeading !== "データを削除する") {
                        return (
                          <>
                            <MuiPickersUtilsProvider
                              utils={DateFnsUtils}
                              locale={jaLocale}
                            >
                              {/* <Grid container justify="space-around"> */}
                              <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="yyyy/MM"
                                views={["year", "month"]}
                                margin="normal"
                                id="date-picker-inline"
                                value={fullMonthYearFirstDash}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                  "aria-label": "change date"
                                }}
                                autoOk={true}
                              />

                              {/* </Grid> */}
                            </MuiPickersUtilsProvider>
                            &nbsp; ~ &nbsp;
                            <MuiPickersUtilsProvider
                              utils={DateFnsUtils}
                              locale={jaLocale}
                            >
                              {/* <Grid container justify="space-around"> */}
                              <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="yyyy/MM"
                                views={["year", "month"]}
                                margin="normal"
                                id="date-picker-inline-end"
                                value={fullMonthYearEndDash}
                                onChange={handleDateChangeEnd}
                                KeyboardButtonProps={{
                                  "aria-label": "change date"
                                }}
                                autoOk={true}
                              />

                              {/* </Grid> */}
                            </MuiPickersUtilsProvider>
                          </>
                        );
                      } else if (textHeading === "データを削除する") {
                        return "";
                      }
                    })()}

                    <ButtonBlock>
                      <Button
                        className="send"
                        //type="submit"
                        //onClick={togglePopup => handleRemove()}
                        type="button"
                        onClick={togglePopup}
                        //value="削除する"
                        name="削除する"
                      >
                        削除する
                        {/* 削除する */}
                        {isOpen && (
                          <Popup
                            content={
                              <>
                                <ModalBackGround
                                  onClick={() => setIsOpen(false)}
                                >
                                  <ModalContainer>
                                    <div>
                                      <p
                                        style={{
                                          color: "#435878",
                                          marginBottom: "16px"
                                        }}
                                      >
                                        削除しますか？
                                      </p>
                                      <ModalClose
                                        onClick={() => setIsOpen(false)}
                                      ></ModalClose>
                                    </div>
                                    <div>
                                      <CancelBtn
                                        onClick={() => setIsOpen(false)}
                                      >
                                        Cancel
                                      </CancelBtn>

                                      <DeleteBtn onClick={handleRemove}>
                                        Delete
                                      </DeleteBtn>
                                    </div>
                                  </ModalContainer>
                                </ModalBackGround>
                              </>
                            }
                            handleClose={togglePopup}
                          />
                        )}
                      </Button>
                    </ButtonBlock>
                    <DataTitleDP>Spreadsheet連携の手動更新</DataTitleDP>
                    <ButtonBlock>
                      <Button
                        className="send"
                        type="button"
                        name="更新する"
                        onClick={updateSpreadsheet}
                      >
                        今すぐ更新
                      </Button>
                    </ButtonBlock>
                  </Form>
                </DataContent>
              );
            }
          })()}
        </div>
      </main>
    </Container>
  );
};

export default UploadPage;

const ErrorMessage = styled.p`
  margin-bottom: 10px;
  color: red;
`;

// Container
const Container = styled.div`
  display: flex;
`;

// DataContent
const DataContent = styled.div`
  width: 100%;
  height: 100vh;
  padding: 33px 40px;
  box-sizing: border-box;
  background-color: #fff;
`;

// DataTitle
const DataTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  line-height: 27px;
  margin: 0 0 28px 0;
`;

// Form
const Form = styled.form``;

// Line
const Line = styled.div`
  padding-left: 40px;
  box-sizing: border-box;
  position: relative;
`;

const FakeInput = styled.input`
  display: none;
`;

const SelectedFileDisplay = styled.input`
  font-size: 14px;
  line-height: 21px;
  color: #283f46;
  width: 320px;
  border: 1px solid #c3cedf;
  border-radius: 4px;
  padding: 12px 21px;
  box-sizing: border-box;
  &::placeholder {
    color: #c3cedf;
  }
`;

// ButtonBlock
const ButtonBlock = styled.div`
  max-width: 570px;
  width: 100%;
  padding-top: 16px;
  padding-left: 40px;
  display: flex;
`;
// Button
const Button = styled.button`
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
  color: #fff;
  text-align: center;
  width: 152px;
  height: 36px;
  padding: 8px 10px 10px 10px;
  display: inline-block;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  appearance: none;
  outline: none;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
  &.send {
    background-color: #0066ff;
  }
`;

const FileSelectButton = styled(Button)`
  color: #435878;
  border: 1px solid #435878;
  padding: 7px 10px 10px 10px;
  margin-right: 16px;
`;

const ErrorText = styled.p`
  font-size: 12px;
  color: #af002a;
  top: 50px;
`;
const DateSelect = styled.div`
  margin-right: 0;
  padding-left: 40px;
  background-color: #fff;
  position: relative;
  padding-top: 16px;
  padding-left: 40px;
  display: flex;
  .dateArrow {
    width: 35px;
    height: auto;
    display: block;
    position: absolute;
    top: 50%;
    right: 18px;
    transform: translateY(-50%);
    z-index: 10;
  }
`;

// DataTitle Delete Part ##DP
const DataTitleDP = styled.h3`
  font-size: 18px;
  font-weight: bold;
  line-height: 27px;
  margin: 0 0 10px 0;
  padding-top: 16px;
`;

const ModalBackGround = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(67, 88, 120, 0.3);
`;

const DeleteBtn = styled.button`
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
  color: #fff;
  text-align: center;
  width: 80px;
  height: 24px;
  display: inline-block;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  appearance: none;
  outline: none;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
  background-color: ${color.primary};
  &:hover {
    opacity: 0.6;
  }
`;

const CancelBtn = styled(DeleteBtn)`
  background-color: #fff;
  color: #435878;
  border: 1px solid #435878;
  margin-right: 16px;
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background: #fff;
  height: 96px;
  width: 480px;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 16px;
  box-sizing: border-box;
  text-align: center;
  z-index: 1001;
  border: 1px solid #999;
`;
const ModalClose = styled.button`
  width: 24px;
  height: 24px;
  background: url(${close});
  background-repeat: no-repeat;
  position: absolute;
  right: 8px;
  top: 6px;
`;
