import React from "react";
import { useLocation } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { showLoading } from "../../reducks/loading/actions";
import "date-fns";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import format from "date-fns/format";
import { getResultsForTargetForecastRequest } from "../../reducks/targetForecast/actions";
import { getRawDataCustomSpanRequest } from "../../reducks/rawData/actions";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import jaLocale from "date-fns/locale/ja";
import {
  getResultsRequestForNewForecast,
  getResultsRequestForNewForecastForCustom
} from "../../reducks/newForecast/actions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap"
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200
    }
  })
);

class ExtendedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date: any) {
    return format(date, "MMM", { locale: this.locale });
  }
}

const DateSpanSelectorStart = (props: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const company_id = useSelector((state: any) => state.user.company_id);

  const selected_data_config_data_type = useSelector(
    (state: any) => state.raw_data.selected_data_config.data_type
  );
  const selected_data_custom_span_start_time = useSelector(
    (state: any) => state.raw_data.selected_data_config.custom_span_start_time
  );
  const selected_data_custom_span_end_time = useSelector(
    (state: any) => state.raw_data.selected_data_config.custom_span_end_time
  );

  const forecast_filter_status = useSelector(
    (state: any) => state.target_forecast.forecast_filter_status
  );
  const custom_span_end_time = useSelector(
    (state: any) => state.raw_data.selected_data_config.custom_span_end_time
  );
  const forecast_custom_span_start_time = useSelector(
    (state: any) =>
      state.target_forecast.selected_data_config.custom_span_start_time
  );
  const forecast_custom_span_end_time = useSelector(
    (state: any) =>
      state.target_forecast.selected_data_config.custom_span_end_time
  );
  // const forecastFilter = useSelector((state: any) => state.forecasts.forecastFilter)
  const forecast_base_span = useSelector(
    (state: any) =>
      state.target_forecast.selected_data_config.forecast_base_span
  );
  const filter_name = useSelector((state: any) => state.filter);
  const target_data_name = useSelector(
    (state: any) => state.target_data.target_data_name
  );

  // URLを取得
  const location = useLocation();
  const pathname = location.pathname;

  // カスタム期間変更によるデータ取得
  const handleDateChange = (date: Date | null) => {
    if (selected_data_custom_span_end_time !== "" && date != null) {
      const dateObjectForStart = {
        seconds: date.getTime() / 1000,
        nanoseconds: 0
      };

      if (date <= new Date(selected_data_custom_span_end_time.seconds * 1000)) {
        if (filter_name !== "") {
          dispatch(showLoading());
          dispatch(
            getRawDataCustomSpanRequest(
              company_id,
              selected_data_config_data_type,
              dateObjectForStart,
              custom_span_end_time,
              pathname,
              filter_name,
              target_data_name
            )
          );
        } else {
          dispatch(showLoading());
          dispatch(
            getRawDataCustomSpanRequest(
              company_id,
              selected_data_config_data_type,
              dateObjectForStart,
              custom_span_end_time,
              pathname,
              filter_name,
              target_data_name
            )
          );
          dispatch(
            getResultsForTargetForecastRequest(
              company_id,
              forecast_base_span,
              "custom",
              "monthly",
              dateObjectForStart,
              custom_span_end_time,
              forecast_custom_span_start_time,
              forecast_custom_span_end_time
            )
          );
        }

        if (forecast_filter_status !== "custom") {
          dispatch(showLoading());
          dispatch(
            getResultsRequestForNewForecast(
              company_id,
              forecast_base_span,
              "custom",
              dateObjectForStart,
              custom_span_end_time
            )
          );
        } else {
          dispatch(showLoading());
          dispatch(
            getResultsRequestForNewForecastForCustom(
              company_id,
              // 未来予測カスタム期間の差異の月数
              new Date(forecast_custom_span_end_time * 1000).getFullYear() *
                12 +
                new Date(forecast_custom_span_end_time * 1000).getMonth() -
                new Date(forecast_custom_span_start_time * 1000).getFullYear() *
                  12 +
                new Date(forecast_custom_span_start_time * 1000).getMonth(),

              "custom",

              dateObjectForStart,
              custom_span_end_time,

              forecast_custom_span_start_time,
              forecast_custom_span_end_time
            )
          );
        }
      } else {
        alert("終了日より前の日付を選択してください");
        return false;
      }
    }
  };

  return (
    <>
      <form
        style={{
          width: selected_data_config_data_type === "monthly" ? "100px" : ""
        }}
        className={classes.container}
        noValidate
      >
        {selected_data_config_data_type === "monthly" && (
          <MuiPickersUtilsProvider utils={ExtendedUtils} locale={jaLocale}>
            <Grid container justify="space-around">
              <DatePicker
                views={["month"]}
                variant="inline"
                format="yyyy/MM"
                margin="normal"
                id="date-picker-inline"
                value={
                  new Date(selected_data_custom_span_start_time.seconds * 1000)
                }
                onChange={handleDateChange}
                autoOk={true}
                disableFuture={true}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        )}

        {/* 月次 日付・曜日なし */}
      </form>
    </>
  );
};

export default DateSpanSelectorStart;
