// import update from "immutability-helper";
import { UPLOAD_DATA_STARTED, UPLOAD_DATA_SUCCEEDED, UPLOAD_DATA_FAILED, RESET_DATA } from "./types";
import { NotificationManager } from 'react-notifications';
import {RESET_ALL} from "../common/types";

const initialState = {
  result: {},
  message: '',
};

const dataReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_ALL:
      return initialState;

    case UPLOAD_DATA_STARTED:
      NotificationManager.info('アップロードが開始されました', 'Upload Started', 1500)
      return state

    case UPLOAD_DATA_SUCCEEDED:
      NotificationManager.success('アップロードが成功しました', 'Upload Succeeded', 1500)
      return {
        ...state,
        message: ''
      }

    case UPLOAD_DATA_FAILED:
      const message = action.error_response.data.message
      NotificationManager.error('アップロードに失敗しました', 'Upload Failed', 1500)

      return {
        ...state,
        message: message
      };

    case RESET_DATA:
      return {
        result: {},
        message: ''
      }

      default:
      return state;
  }
};

export default dataReducer;
