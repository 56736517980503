import rsf from "../../rsf"
import { call } from "redux-saga/effects"
import firebase from "firebase"
const db = firebase.firestore()

// 指定された期間の過去のサマリーデータ
function* getRawDataPast(company_id, forecast_base_span) {
  try {
    // 当月
    const thisMonth = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
    const month = thisMonth.getMonth();

    // 12,24,36ヶ月前
    const forecastBaseStart = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - forecast_base_span));
    // const forecastBaseStart = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 6));  // データが足りないため現在は半年分のみ取得
    let raw_data_array_past = [];
    const snapshot = yield call(
      rsf.firestore.getCollection,
      db.collection(`companies/${company_id}/result_monthly`)
        .where("result_at", ">=", forecastBaseStart)
        .where("result_at", "<=", thisMonth)
      );

    // 取得後データを整形
    snapshot.forEach(result_monthly => {
      raw_data_array_past = [...raw_data_array_past, { ...result_monthly.data(), docId: result_monthly.id }];
    });
    console.log("raw_data_array_past", raw_data_array_past)
    return raw_data_array_past;
  } catch (e) {
    console.log("error message", e.message);
  }
}

export default getRawDataPast;