import { all, call, put, takeLatest } from "redux-saga/effects";
import { HIDE_LOADING } from "../reducks/loading/types";
import { GET_COHORT_SUCCEEDED, GET_COHORT_FAILED, GET_COHORT_REQUESTED, GET_FILTERED_COHORT_REQUEDSTED } from "../reducks/cohort/types";
import getRawDataForCohort from "./cohortSaga/getChurnRateRows";
import getFilteredCohort from "./cohortSaga/getFilteredCohort";
import churnRateRowsGenerator from "./cohortSaga/churnRateRowsGenerator";
import churnRateAverageGenerator from "./cohortSaga/churnRateAverageGenerator"
import newCustomersArrayGenerator from "./cohortSaga/newCustomersArrayGenerator";
import newCustomersAverageGenerator from "./cohortSaga/newCustomersAvrerageGenerator"

function* getCohort(action: any) {
  try {

    // 設定期間のrawデータを取得する
    const raw_data_for_cohort = yield* getRawDataForCohort(action.company_id, action.target_month, action.target_span);
    console.log('raw_data_for_cohort', raw_data_for_cohort)

    // churnデータを整形する
    const churn_rate_rows = churnRateRowsGenerator(raw_data_for_cohort, action.target_data, action.target_filter_key, action.target_filter_value, action.target_span);

    // 平均値を計算するする
    const churn_rate_average = churnRateAverageGenerator(raw_data_for_cohort, action.target_data, action.target_filter_key, action.target_filter_value, action.target_span);

    // newデータを整形する
    const new_customers_array = newCustomersArrayGenerator(raw_data_for_cohort, action.target_data, action.target_span);
    
    // newデータを整形する
    const new_customers_average = newCustomersAverageGenerator(raw_data_for_cohort, action.target_data, action.target_span);

    console.log('new_customers_average', new_customers_average)


    yield all([
      put({
        type: GET_COHORT_SUCCEEDED,
        target_span: action.target_span,
        target_month: action.target_month,
        churn_rate_rows: churn_rate_rows,
        churn_rate_average: churn_rate_average,
        new_customers_array: new_customers_array,
        new_customers_average: new_customers_average,
      }),
      put({
        type: HIDE_LOADING
      }),
    ]);
  } catch (e) {
    yield all([
      put({
        type: HIDE_LOADING
      }),
      put({
        type: GET_COHORT_FAILED,
        message: e.message
      })
    ]);
  }
}

const targetDataSagas = [
  takeLatest(GET_COHORT_REQUESTED, getCohort),
  takeLatest(GET_FILTERED_COHORT_REQUEDSTED, getFilteredCohort)
];

export default targetDataSagas;
