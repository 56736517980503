import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox'
import Styled from 'styled-components'
import {styled} from '@material-ui/core/styles';
import close from '../../../images/icons/close-small.svg'
import FormDesc from './FormDesc';
import Require from './Require'
import { useForm } from 'react-hook-form'
import ErrorText from './ErrorText';
import Select from '../../../images/icons/select.svg'
import { toggleLandingPageModal } from '../../../reducks/landingPageModal/actions'


type Inputs = {
  companyName: string,
  department:string,
  position: string,
  name: string,
  email: string,
  tel: string,
  request: string,
}


const FormModal = styled(Dialog)({

})

//資料請求・お問い合わせボタン
const InquiryButton = styled(Button)({
  fontSize: '24px',
  fontWeight: 'bold',
  lineHeight: '36px',
  color: '#fff',
  width: '632px',
  margin: '0 auto',
  padding: '20px',
  border: '2px solid #fff',
  borderRadius: '10px',
  boxSizing: 'border-box'
})

//ModalHeading
const ModalHeading = Styled.h2`
  font-size: 24px;
  font-weight: bold; 
  line-height: 36px;
  margin-bottom: 16px;
  color: #35455D;
`

//InputItem
const InputItem = Styled.dl`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  margin-right: 232px;
`

//InputLabel
const ItemLabel = Styled.dt`
  color: #435878;
  text-align: right;
  line-height: 21px;
  font-size: 14px;
  font-weight: normal;
  width: 200px;
  margin-right: 32px;
`

//入力ボックス
const InputBox = Styled.input`
  background: #fff;
  border: 1px solid #C3CEDF;
  border-radius: 4px;
  color: #7591BC;
  font-size: 14px;
  line-height: 21px;
  padding: 12px 14px 13px;
  width: 296px;
  resize: none;
  &::placeholder {
    color: #7591BC;
  }
  `
  const SelectBox = Styled.select`
    background-image:url(${Select});
    background-repeat:no-repeat;
    background-position: center right 24px;
    border: 1px solid #C3CEDF;
    border-radius: 4px;
    color: #7591BC;
    font-size: 14px;
    line-height: 21px;
    padding: 12px 14px 13px;
    width: 296px;
    resize: none;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
    &::placeholder {
      color: #7591BC;
    }
  `
  const TextBox = InputBox.withComponent('textarea')


//CloseButton
const CloseBtn = Styled.button`
  display: inline-block;
  position: absolute;
  right: 26px;
  top: 26px;
  &:hover {
    opacity: 0.6;
  }
`

//checkBox
const ApplyDesc = Styled.p`
  font-size: 14px;
  color: #35455D;
`
const PrivacyLink = Styled.span`
  color: #0066FF;
  cursor: pointer;
`
const FlexDiv = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  `

//送信ボタン
const Submit = Styled.button`
  background: #0769FC;
  border-radius: 4px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
  box-sizing: border-box;
  color: #fff;
  display: block;
  font-size: 14px;  
  height: 36px;
  margin: 0 auto 32px;
  padding: 10px auto;
  text-align: center;
  width: 296px;
  &:hover {
    opacity: 0.6;
  }
`

//ModalContainer
const ModalContainer = Styled.div`
  width: 968px;
  height: 808px;
  padding: 32px;
  background: #fff;
  posiiton: relative;
`

export default function FormMordal() {
  const { register, errors, handleSubmit } = useForm<Inputs>()
  const onSubmit = (data: Inputs) => console.log(data);
  const [open, setOpen] = React.useState(false)
  const [state, setState] = React.useState({checked: false,});

  // const handleClickOpen = () => {
  //   setOpen(true)
  // }

  // const handleClose = () => {
  //   setOpen(false)
  // }

  const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [name]: event.target.checked });
  };

  //モーダル表示非表示
  const dispatch = useDispatch();
  const lp_open = useSelector((state: any) => state.landingPageModal.open);
  const clickModalOpen = () => {
    dispatch(toggleLandingPageModal(!lp_open))
  }

  return(
    <>
      <InquiryButton onClick={clickModalOpen}>資料請求・お問い合わせ</InquiryButton>
      <FormModal
        open={lp_open}
        aria-labelledby="form-dialog-title" maxWidth='md'
      >
        <ModalContainer>
          <CloseBtn onClick={clickModalOpen}><img src={close} alt="close"/></CloseBtn>
          <ModalHeading>資料請求・お問い合わせ</ModalHeading>
          <FormDesc />
          <form onSubmit={handleSubmit(onSubmit)}>

            {/* 会社名 */}
            <InputItem>
              <ItemLabel><Require/><label>会社名</label></ItemLabel>
              <dd>
                <InputBox 
                  type="text"
                  name="companyName"
                  ref={register({ required: true})}
                  />
                {errors.companyName && <ErrorText/>}
              </dd>
            </InputItem>

            {/* 部署 */}
            <InputItem>
              <ItemLabel><label>部署</label></ItemLabel>
              <dd>
                <InputBox 
                  type="text" 
                  name="department"
                  ref={register()}
                />
              </dd>
            </InputItem>

            {/* 役職 */}
            <InputItem>
              <ItemLabel><label>役職</label></ItemLabel>
              <dd>
                <SelectBox 
                  name="position" 
                  ref={register()}
                >
                  <option value="">選択してください</option>
                  <option value="社長">社長</option>
                  <option value="部長">部長</option>
                  <option value="課長">課長</option>
                </SelectBox>
              </dd>
            </InputItem>

            {/* 名前 */}
            <InputItem>
              <ItemLabel>
                <Require/><label>名前</label>
              </ItemLabel>
              <dd>
                <InputBox
                  type="text" 
                  name="name"
                  ref={register({ required: true})}
                  />
                {errors.name && <ErrorText/>}
              </dd>
            </InputItem>

            {/* メールドレス */}
            <InputItem>
              <ItemLabel><Require/><label>メールアドレス</label></ItemLabel>
              <dd>
                <InputBox
                  type="text"
                  name="email"
                  ref={register({ required: true, pattern:/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/})}
                />
                {errors.email && <ErrorText/>}
              </dd>
            </InputItem>

            {/* 電話番号 */}
            <InputItem>
              <ItemLabel><Require/><label>電話番号</label></ItemLabel>
              <dd>
                <InputBox 
                  type="text" 
                  name="tel"
                  ref={register({ required: true, pattern:/^[0-9]+$/})}
                  />
                {errors.tel && <ErrorText/>}
              </dd>
            </InputItem>

            {/* ご要望 */}
            <InputItem>
              <ItemLabel><Require/><label>ご要望</label></ItemLabel>
              <dd>
                <TextBox 
                  name="request" 
                  id=""
                  cols={30} 
                  rows={5}
                  placeholder="資料が欲しい、デモ画面を見たい、提案が欲しいなど、具体的なご要望を記入してください。"
                  ref={register({ required: true})}
                  >
                </TextBox>
                {errors.request && <ErrorText/>}
              </dd>
            </InputItem>

            <FlexDiv>
              <Checkbox
              checked={state.checked} onChange={handleChange('checked')}
              value="checked" color="primary"
              inputProps={{'aria-label': 'secondary checkbox',}}/>
              <ApplyDesc><PrivacyLink>個人情報の取扱</PrivacyLink>について同意します。</ApplyDesc>
            </FlexDiv>

            <Submit>送信</Submit>

          </form>
        </ModalContainer>
      </FormModal>
    </>
  )
}