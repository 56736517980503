import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
//import TextField from '@material-ui/core/TextField';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom";
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { getTargetDataForSpanComparison } from '../../reducks/targetData/actions';
import { MuiPickersUtilsProvider, KeyboardDatePicker, DatePicker } from '@material-ui/pickers';
import jaLocale from 'date-fns/locale/ja';
import format from 'date-fns/format';

import { showLoading } from '../../reducks/loading/actions'


class ExtendedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date: any) {
    return format(date, 'MMM', { locale: this.locale });
  }
}

// ===================================================== //
//　週次表示時に特定の曜日を非表示にする（現在は6:土曜日を非表示）
const disableWeekDays = (date: any) => {
  return date.getDay() === 0 || date.getDay() === 1 || date.getDay() === 2 || date.getDay() === 3 || date.getDay() === 4 || date.getDay() === 5;
};
//　週次表示時に特定の曜日を非表示にする（現在は6:土曜日を非表示）
// ===================================================== //

const SecondSpanComparisonCalenderEnd = (props: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const company_id = useSelector((state: any) => state.user.company_id);
  const selected_data_config_data_type = useSelector((state: any) => state.raw_data.selected_data_config.data_type);
  const location = useLocation();
  const pathName = location.pathname;

  const first_span_start = useSelector((state: any) => state.target_data.first_span_start);
  const first_span_end = useSelector((state: any) => state.target_data.first_span_end);
  const second_span_start = useSelector((state: any) => state.target_data.second_span_start);
  const second_span_end = useSelector((state: any) => state.target_data.second_span_end);

  const handleDateChange = (date: Date | null) => {
    if (second_span_start !== '' && date !== null) {
      if (second_span_start < date.getTime()) {
        // 当年カレンダーの終了日
        const this_year_first_span_end = new Date(date.setMonth(date.getMonth() + 12)).getTime()
        console.log('this_year_first_span_end', new Date(this_year_first_span_end));
        dispatch(
          getTargetDataForSpanComparison(
            company_id,
            selected_data_config_data_type,
            pathName,
            first_span_start,
            this_year_first_span_end,
            second_span_start,
            new Date(date.setMonth(date.getMonth() - 12)).getTime(),
          )
        );
      } else {
        alert('開始日より後の日付を選択してください');
      }
    }
  };

  return (
    <>
      <form className={classes.container} noValidate>
        {selected_data_config_data_type === 'daily' && (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="yyyy/MM/dd(E)"
                margin="normal"
                id="date-picker-inline"
                value={second_span_end}
                // onChange={handleDateChange(page_is)}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        )}

        {selected_data_config_data_type === 'weekly' && (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="yyyy/MM/dd(E)"
                margin="normal"
                id="date-picker-inline"
                value={second_span_end}
                // onChange={handleDateChange(page_is)}
                onChange={handleDateChange}
                shouldDisableDate={disableWeekDays}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        )}

        {selected_data_config_data_type === 'monthly' && (
          <MuiPickersUtilsProvider utils={ExtendedUtils} locale={jaLocale}>
            <Grid container justify="space-around">
              <DatePicker
                views={['month']}
                autoOk={true}
                variant="inline"
                format="yyyy/MM"
                margin="normal"
                id="date-picker-inline"
                value={second_span_end}
                // onChange={handleDateChange(page_is)}
                onChange={handleDateChange}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        )}

        {/* 月次 日付・曜日なし */}
      </form>
    </>
  );
};

export default SecondSpanComparisonCalenderEnd;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      '& input': {
        color: '#E53E66',
      }
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  })
);