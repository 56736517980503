import rsf from "../../rsf";
import { call } from "redux-saga/effects";
import firebase from "firebase";

const db = firebase.firestore();

// 過去6ヶ月分データ取得
function* getRawDataCustomSpan(company_id, selected_data_config_data_type, custom_span_start_time,
  custom_span_end_time) {
  try {

    // 期間設定
    const start_time = new Date(custom_span_start_time.seconds * 1000);
    const end_time = new Date(custom_span_end_time.seconds * 1000);

    // 月次設定の場合
    if (selected_data_config_data_type === "monthly") {
      const firstDateMonth = new Date((start_time.setDate(1) / 1000) * 1000)
      // データ取得
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${company_id}/result_monthly`)
          .where("result_at", ">=", firstDateMonth)
          .where("result_at", "<=", end_time)
        );

      // 取得後データを整形
      let raw_data_array = [];
      snapshot.forEach(result_monthly => {
        raw_data_array = [...raw_data_array, { ...result_monthly.data(), docId: result_monthly.id }];
      });

      return raw_data_array;
    } else if (selected_data_config_data_type === "weekly") { // 週次設定の場合
      // データ取得
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${company_id}/result_weekly`)
          .where("result_at", ">=", start_time)
          .where("result_at", "<=", end_time)
        );

      // 取得後データを整形
      let raw_data_array = [];
      snapshot.forEach(result_monthly => {
        raw_data_array = [...raw_data_array, { ...result_monthly.data(), docId: result_monthly.id }];
      });

      return raw_data_array;
    } else { // 日次設定の場合
      // データ取得
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${company_id}/result_daily`)
          .where("result_at", ">=", start_time)
          .where("result_at", "<=", end_time)
        );

      // 取得後データを整形
      let raw_data_array = [];
      snapshot.forEach(result_monthly => {
        raw_data_array = [...raw_data_array, { ...result_monthly.data(), docId: result_monthly.id }];
      });

      return raw_data_array;
    }
  } catch (e) {
    console.log("error message", e.message);
  }
}


export default getRawDataCustomSpan;
