import rsf from "../../rsf";
import { call, put, all } from "redux-saga/effects";
import firebase from "firebase";
import {GET_FILTERED_COHORT_SUCCEEDED, GET_FILTERED_COHORT_FAILED} from "../../reducks/cohort/types"
import { HIDE_LOADING } from "../../reducks/loading/types";
import FilteredChurnAverageGenerator from "./FilteredChurnAverageGenerator"

const db = firebase.firestore();

// cohortデータを取得
function* getFilteredDataForCohort(action) {
  try {

    // 期間設定
    const collection_name = action.target_data_name + '_detail'
    const key_total = action.target_data_name + '_total'
    const start_time = new Date(action.target_month);
    const month = start_time.getMonth();
    const end_time = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month + (action.target_span - 13)));
    console.log('month', month)
    console.log('start_time', start_time)
    console.log('end_time', end_time)
    const targetCollectionGroup = collection_name => {
      console.log('collection_name', collection_name)
      return db
        .collectionGroup(collection_name)
        .where('company_id', '==', action.company_id)
        .where('result_at', '>=', start_time)
        .where('result_at', '<=', end_time)
        .where('resultSpan', '==', action.resultSpan)
        .where('filter', '==', action.filter_name)
        .get()
    }

    // データ取得
    const churn_snapshot = yield call(targetCollectionGroup, collection_name);
    const new_data_snapshot = action.target_data_name === 'revenue_churned' 
    ? yield call(targetCollectionGroup, 'revenue_detail') 
    : yield call(targetCollectionGroup, 'new_customer_detail');

    // 取得後データを整形
    let churn_data_array = [];
    let new_data_array = [];

    churn_snapshot.forEach(doc => {
      churn_data_array = [...churn_data_array, { ...doc.data(), docId: doc.id }];
    });
    
    new_data_snapshot.forEach(doc => {
      new_data_array = [...new_data_array, { ...doc.data(), docId: doc.id }];
    });


    //空白データ追加用関数
    const fill_loop = (array) => {
      for(let cell = array.length; cell < action.target_span; cell++) {
        array.push({churn_data:""}) 
      }
      return array
    }
    
    // 解約データを整形する
    churn_data_array = churn_data_array.map((churn_data) => {
      const churn_data_object = churn_data.list.find((item) => item.name === action.filter_label)
      console.log('churn_data_object', churn_data_object)
      return {
        churn_data: churn_data_object[key_total] !== null ? churn_data_object[key_total] : 0,
        result_at: churn_data.result_at,
      }
    })

    // churn_dataをコホートを描写するための階段上のデータの形に整形する。
    const filtered_churn_data_row = churn_data_array.map((churn_data, index) => {
      return [
        ...churn_data_array.slice(index)
      ]
    })

    const filled_filterd_churn_row = filtered_churn_data_row.map((item) => {
      fill_loop(item)
      return item
    })

    console.log('filled_filterd_churn_row',filled_filterd_churn_row)

    console.log('filtered_churn_data_row', filtered_churn_data_row)
    
    // 新規顧客データを整形する
    new_data_array = new_data_array.map((new_customer) => {
      const new_customer_object = new_customer.list.find((item) => item.name === action.filter_label)
      if(action.target_data_name === "revenue_churned") {
        console.log('new_customer_object', new_customer_object)
        return {
          new_data: new_customer_object.revenue_total !== null ? new_customer_object.revenue_total : 0,
          result_at: new_customer.result_at,
        } 
      } else {
        return {
          new_data: new_customer_object.new_customer_total !== null ? new_customer_object.new_customer_total : 0,
          result_at: new_customer.result_at,
        }
      }
    })

    // 解約データ平均値を作成
    const churn_data_average = FilteredChurnAverageGenerator(churn_data_array, action.target_span)
    // 新規データ平均値を作成
    const filtered_new_data_average = new_data_array.reduce((acc, cur) => acc + cur.new_data,0) / new_data_array.length

    console.log('churn_data_array', churn_data_array)
    console.log('new_data_array', new_data_array)
    yield all([
      put({
        type: GET_FILTERED_COHORT_SUCCEEDED,
        target_month: action.target_month,
        filtered_churn_results: filled_filterd_churn_row,
        filtered_new_data_results: new_data_array,
        filtered_churn_data_everage : churn_data_average,
        filtered_new_data_average: filtered_new_data_average,
        filter_name: action.filter_name,
        filter_label: action.filter_label 
      }),
      put({
        type: HIDE_LOADING
      })
    ])
  } catch (e) {
    yield all([
      put({
        type: HIDE_LOADING
      }),
      put({
        type: GET_FILTERED_COHORT_FAILED,
        message: e.message
      })
    ]);
  }
}


export default getFilteredDataForCohort;
