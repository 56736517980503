import update from 'immutability-helper';
import { GET_RESULTS_SUCCEEDED, GET_RESULTS_FAILED, SET_RESULTS_FOR_SIMULATION, UPDATE_SIMULATED_RESULT, SET_FILTERED_RESULTS, GET_RESULTS_FOR_SPAN_COMPARISON_DATA_SUCCEEDED, GET_RESULTS_FOR_SPAN_COMPARISON_DATA_FAILED, GET_FILTERED_RESULTS_SUCCEEDED, GET_FILTERED_FUNNEL_RESULTS_SUCCEEDED, GET_DETAIL_REVENUE_SUCCEEDED, UPDATE_REVENUE_DETAIL_SIMULATED_RESULT, GET_TARGET_RESULTS_SUCCEEDED,
  GET_TARGET_RESULTS_FAILED } from './types';
import {RESET_ALL} from "../common/types";
import resultToMetrics from "./resultToMetrics";


const initialState: any = {
  resultSpan: 'monthly',
  result_data_type: '',
  result_data_detail_type: '',
  resultMonthly: [],
  resultWeekly: [],
  resultDaily: [],
  filteredResults: [],
  detailedResults: [],
  labelsNameArray: [],
  firstResult: {
    resultMonthly: [],
    resultWeekly: [],
    resultDaily: [],
  },
  secondResult: {
    resultMonthly: [],
    resultWeekly: [],
    resultDaily: [],
  },
  target_results: [],
  target_data_name: '',
  key_total: '',
  key_objective: ''
};

const resultsReducer = (state = initialState, action: any) => {
  const key_total = action.result_data_type + '_total';
  const key_objective = action.result_data_type + '_objective';
  const key_rate = action.result_data_type + '_rate';
  const key_difference = action.result_data_type + '_difference';
  const key_moving_average = action.result_data_type + '_moving_average_total';
  const key_moving_average_objective = action.result_data_type + '_moving_average_objective';
  const key_moving_average_rate = action.result_data_type + '_moving_average_rate';
  const key_moving_average_difference = action.result_data_type + '_moving_average_difference';
  const key_last_year_total = action.result_data_type + '_last_year_total';
  const key_last_year_objective = action.result_data_type + '_last_year_objective';
  const key_last_year_difference = action.result_data_type + '_last_year_difference';
  const key_last_year_rate = action.result_data_type + '_last_year_rate';
  const key_last_year_comparison_total = action.result_data_type + '_last_year_comparison_total';
  const key_last_year_comparison_objective = action.result_data_type + '_last_year_comparison_objective';
  const key_last_year_comparison_rate = action.result_data_type + '_last_year_comparison_rate';
  switch (action.type) {
    case RESET_ALL:
      return initialState;

    case GET_RESULTS_SUCCEEDED:
      // console.log("results daily: ", action)
      if (action.resultSpan === 'monthly') {
        return {
          ...state,
          resultSpan: 'monthly',
          resultMonthly: action.results,
        };
      } else if (action.resultSpan === 'weekly') {
        return {
          ...state,
          resultSpan: 'weekly',
          resultWeekly: action.results,
        };
      } else {
        return {
          ...state,
          resultSpan: 'daily',
          resultDaily: action.results,
        };
      }

    case GET_RESULTS_FAILED:
      return {
        ...state,
        message: action.message,
      };

    case SET_RESULTS_FOR_SIMULATION:
      let simulationResultsList: any = action.results;
      console.log(simulationResultsList.resultMonthly[action.index]);

      const splitKeys = action.key.split('.');
      console.log('splitKeys', splitKeys);
      const simulationResultsList1grade = simulationResultsList.revenues_summary;
      console.log('simulationResultsList[revenues_summary]', simulationResultsList.revenues_summary);
      simulationResultsList1grade[splitKeys[0]] = action.value;

      console.log(simulationResultsList);
      return state;

    case UPDATE_SIMULATED_RESULT:
      if (action.resultSpan === 'monthly') {
        return update(state, {
          resultMonthly: {
            [action.updated_item_index]: {
              $set: action.updated_object,
            },
          },
        });
      } else if (action.resultSpan === 'weekly') {
        return update(state, {
          resultWeekly: {
            [action.updated_item_index]: {
              $set: action.updated_object,
            },
          },
        });
      } else {
        return update(state, {
          resultDaily: {
            [action.updated_item_index]: {
              $set: action.updated_object,
            },
          },
        });
      }

    case SET_FILTERED_RESULTS:
      return {
        ...state,
        filteredResults: action.filtered_results,
      };

    case GET_FILTERED_RESULTS_SUCCEEDED:
      console.log('SUCCEEDED!');
      const fil_results = action.results.filter((data: any) => data.filter === action.filter_name);
      const up_fl = fil_results.map((item: any) => {
        return {
          result_at: item.result_at,
          list: item.list,
        };
      });
      const wip = up_fl.map((item: any) => {
        return item.list.map((i: any) => {
          const variantTotalKey = i.name + '_' + key_total;
          const variantObjectiveKey = i.name + '_' + key_objective;
          const variantDifferenceKey = i.name + '_' + key_difference;
          const variantRateKey = i.name + '_' + key_rate;
          const variantLastYearRateKey = i.name + '_' + key_last_year_rate;
          const variantMovingAverage = i.name + '_' + key_moving_average;
          const variantMovingAverageObjective = i.name + '_' + key_moving_average_objective;
          const variantMovingAverageRate = i.name + '_' + key_moving_average_rate;

          return {
            result_at: item.result_at,
            [variantTotalKey]: i[key_total],
            [variantObjectiveKey]: i[key_objective],
            [variantDifferenceKey]: i[key_difference],
            [variantRateKey]: i[key_rate],
            [variantLastYearRateKey]: i[key_last_year_rate],
            [variantMovingAverage]: i[key_moving_average],
            [variantMovingAverageObjective]: i[key_moving_average_objective],
            [variantMovingAverageRate]: i[key_moving_average_rate],
          };
        });
      });
      const wwip = wip.map((item: any) => {
        return item.reduce((result: any, current: any) => {
          return Object.assign(result, current);
        }, {});
      });

      // グラフ内LineのnameとdataKeyの指定に使用
      const labelsNameArray = action.results
        .filter((result: any) => result.result_at.seconds === action.results[0].result_at.seconds)
        .filter((rev_detail: any) => rev_detail.filter === action.filter_name)
        .flatMap((item: any) => item.list)
        .map((p: any) => {
          const nameList = p.name;
          const lineNameTotal = p.name + '：実績';
          const lineNameObjective = p.name + '：目標';
          const dataKeyNameTotal = p.name + '_' + key_total;
          const dataKeyNameObjective = p.name + '_' + key_objective;
          const lineNameDifference = p.name + '：差異';
          const lineNameRate = p.name + '：達成率';
          const dataKeyNameDifference = p.name + '_' + key_difference;
          const dataKeyNameRate = p.name + '_' + key_rate;
          const lineNameLastYearRate = p.name + '：昨年比';
          const lineNameMovingAverage = p.name + '：移動平均';
          const dataKeyNameLastYearRate = p.name + '_' + key_last_year_rate;
          const dataKeyNameMovingAverage = p.name + '_' + key_moving_average;
          const dataKeyNameMovingAverageObjective = p.name + '_' + key_moving_average_objective;
          const dataKeyNameMovingAverageRate = p.name + '_' + key_moving_average_rate;
          return { nameList, lineNameTotal, lineNameObjective, dataKeyNameTotal, dataKeyNameObjective, lineNameDifference, lineNameRate, dataKeyNameDifference, dataKeyNameRate, lineNameLastYearRate, lineNameMovingAverage, dataKeyNameLastYearRate, dataKeyNameMovingAverage, dataKeyNameMovingAverageObjective, dataKeyNameMovingAverageRate };
        });

      return {
        ...state,
        detailedResults: action.results,
        filteredResults: wwip,
        labelsNameArray: labelsNameArray,
      };

    case GET_FILTERED_FUNNEL_RESULTS_SUCCEEDED:
      console.log('SUCCEEDED!');
      const funnel_fil_results = action.results.flatMap((result: any) => {
        console.log(result[action.data_type].filter((gfl: any) => gfl.funnel_name === action.urlFunnelName)[0].funnel_data);
        return {
          result_at: result.result_at,
          // [data_type]: result[data_type].find((data: any) => data.filter === filter_name),
          [action.data_type]: result[action.data_type].filter((gfl: any) => gfl.funnel_name === action.urlFunnelName)[0].funnel_data,
        };
      });

      console.log('funnel_fil_results', funnel_fil_results);

      const funnel_up_fl = funnel_fil_results.map((item: any) => {
        const filteredList = item.lead_count_detail.filter((i: any) => i.filter === action.filter_name);
        console.log('filteredList', filteredList);
        return {
          result_at: item.result_at,
          list: filteredList,
        };
      });
      console.log('funnel_up_fl', funnel_up_fl);

      const funnel_wip = funnel_up_fl.map((item: any) => {
        return item.list[0].list.map((i: any) => {
          const leadCountTotalKey = i.name + '_lead_count_total';
          const leadCountObjectiveKey = i.name + '_lead_count_objective';
          const leadCountDifferenceKey = i.name + '_lead_count_difference';
          const leadCountRateKey = i.name + '_lead_count_rate';
          const leadCountLastYearRateKey = i.name + '_lead_count_last_year_rate';
          const leadCountMovingAverage = i.name + '_lead_count_moving_average_total';
          return {
            result_at: item.result_at,
            [leadCountTotalKey]: i.lead_count_total,
            [leadCountObjectiveKey]: i.lead_count_objective,
            [leadCountDifferenceKey]: i.lead_count_difference,
            [leadCountRateKey]: i.lead_count_rate,
            [leadCountLastYearRateKey]: i.lead_count_last_year_rate,
            [leadCountMovingAverage]: i.lead_count_moving_average_total,
          };
        });
      });

      const funnel_wwip = funnel_wip.map((item: any) => {
        return item.reduce((result: any, current: any) => {
          return Object.assign(result, current);
        }, {});
      });

      console.log('action.results', action.results);

      const funnelLabelsNameArray = action.results[0]['lead_count_detail']
        .filter((rev_detail: any) => rev_detail.funnel_name === action.urlFunnelName)[0]
        .funnel_data.filter((rev_detail: any) => rev_detail.filter === action.activatedFilter)
        .flatMap((item: any) => item.list)
        .map((p: any) => {
          const lineNameTotal = p.name + '：実績';
          const lineNameObjective = p.name + '：目標';
          const dataKeyNameTotal = p.name + '_' + key_total;
          const dataKeyNameObjective = p.name + '_' + key_objective;
          const lineNameDifference = p.name + '：差異';
          const lineNameRate = p.name + '：達成率';
          const dataKeyNameDifference = p.name + '_' + key_difference;
          const dataKeyNameRate = p.name + '_' + key_rate;
          const lineNameLastYearRate = p.name + '：昨年比';
          const lineNameMovingAverage = p.name + '：移動平均';
          const dataKeyNameLastYearRate = p.name + '_' + key_last_year_rate;
          const dataKeyNameMovingAverage = p.name + '_' + key_moving_average;
          return { lineNameTotal, lineNameObjective, dataKeyNameTotal, dataKeyNameObjective, lineNameDifference, lineNameRate, dataKeyNameDifference, dataKeyNameRate, lineNameLastYearRate, lineNameMovingAverage, dataKeyNameLastYearRate, dataKeyNameMovingAverage };
        });

      console.log('funnel_wwip', funnel_wwip);
      console.log('funnelLabelsNameArray', funnelLabelsNameArray);
      labelsNameArray.map((p: any, index: number) => {
        console.log('p.dataKeyNameTotal', p.dataKeyNameTotal);
      });

      return {
        ...state,
        detailedResults: action.results,
        filteredResults: funnel_wwip,
        labelsNameArray: funnelLabelsNameArray,
      };

    case GET_RESULTS_FOR_SPAN_COMPARISON_DATA_SUCCEEDED:
      let dateDataList;

      const selected_dates = action.results.map((rd: any) => {
        if (action.spanOrder === 'firstSpan') {
          return {
            first_date: rd.result_at,
          };
        } else {
          return {
            second_date: rd.result_at,
          };
        }
      });

      const selectedSpanDataList = action.results
        .flatMap((rd: any) => rd[action.result_data_type])
        .map((rd: any) => {
          if (action.spanOrder === 'firstSpan') {
            return {
              first_key_total: rd[key_total],
              first_key_objective: rd[key_objective],
              first_key_difference: rd[key_difference],
              first_key_rate: rd[key_rate],
              first_key_last_year_total: rd[key_last_year_total],
              first_key_last_year_objective: rd[key_last_year_objective],
              first_key_moving_average: rd[key_moving_average],
              first_key_moving_average_objective: rd[key_moving_average_objective],
              first_key_moving_average_difference: rd[key_moving_average_difference],
              first_key_moving_average_rate: rd[key_moving_average_rate],
              first_key_last_year_comparison_total: rd[key_last_year_comparison_total],
              first_key_last_year_comparison_objective: rd[key_last_year_comparison_objective],
              first_key_last_year_comparison_rate: rd[key_last_year_comparison_rate],
            };
          } else {
            console.log('KEY_TOTAL', key_total);
            console.log('RD', rd);
            console.log('action.results', action.results);
            console.log('action.result_data_type', action.result_data_type);
            console.log(
              'action.results.flatMap((rd: any) => rd[action.result_data_type])',
              action.results.flatMap((rd: any) => rd[action.result_data_type])
            );
            return {
              second_key_total: rd[key_total],
              second_key_objective: rd[key_objective],
              second_key_difference: rd[key_difference],
              second_key_rate: rd[key_rate],
              second_key_last_year_total: rd[key_last_year_total],
              second_key_last_year_objective: rd[key_last_year_objective],
              second_key_moving_average: rd[key_moving_average],
              second_key_moving_average_objective: rd[key_moving_average_objective],
              second_key_moving_average_difference: rd[key_moving_average_difference],
              second_key_moving_average_rate: rd[key_moving_average_rate],
              second_key_last_year_comparison_total: rd[key_last_year_comparison_total],
              second_key_last_year_comparison_objective: rd[key_last_year_comparison_objective],
              second_key_last_year_comparison_rate: rd[key_last_year_comparison_rate],
            };
          }
        });

      dateDataList = selected_dates.map((data: any, index: number) => {
        return { ...data, ...selectedSpanDataList[index] };
      });

      if (action.resultSpan === 'monthly') {
        // return state
        if (action.spanOrder === 'firstSpan') {
          console.log('first result!');
          console.log('action.results', action.results);
          return {
            ...state,
            firstResult: {
              ...state.firstResult,
              resultMonthly: dateDataList,
            },
          };
        } else {
          return {
            ...state,
            secondResult: {
              ...state.secondResult,
              resultMonthly: dateDataList,
            },
          };
        }
      } else if (action.resultSpan === 'weekly') {
        // return state
        if (action.spanOrder === 'firstSpan') {
          console.log('first result!');
          console.log('action.results', action.results);
          return {
            ...state,
            firstResult: {
              ...state.firstResult,
              resultWeekly: dateDataList,
            },
          };
        } else {
          return {
            ...state,
            secondResult: {
              ...state.secondResult,
              resultWeekly: dateDataList,
            },
          };
        }
      } else {
        if (action.spanOrder === 'firstSpan') {
          return {
            ...state,
            firstResult: {
              ...state.firstResult,
              // resultDaily: action.results,
              resultDaily: dateDataList,
            },
          };
        } else {
          return {
            ...state,
            secondResult: {
              ...state.secondResult,
              // resultDaily: action.results,
              resultDaily: dateDataList,
            },
          };
        }
      }

    case GET_RESULTS_FOR_SPAN_COMPARISON_DATA_FAILED:
      return {
        ...state,
        message: action.message,
      };


    case GET_DETAIL_REVENUE_SUCCEEDED:
      if (action.resultSpan === 'monthly') {
        return {
          ...state,
          resultSpan: 'monthly',
          revenue_detail: action.detail_results,
        };
      } else if (action.resultSpan === 'weekly') {
        return {
          ...state,
          resultSpan: 'weekly',
          revenue_detail: action.detail_results,
        };
      } else {
        return {
          ...state,
          resultSpan: 'daily',
          revenue_detail: action.detail_results,
        };
      }


    case UPDATE_REVENUE_DETAIL_SIMULATED_RESULT:
      if (action.resultSpan === 'monthly') {
        return update(state, {
          resultMonthly: {
            [action.updated_item_index]: {
              $set: action.updated_object,
            },
          },
          revenue_detail: {
            [action.updated_rd_item_index]: {
              $set: action.updated_rd_object,
            },
          }
        });
      } else if (action.resultSpan === 'weekly') {
        return update(state, {
          resultWeekly: {
            [action.updated_item_index]: {
              $set: action.updated_object,
            },
          },
          revenue_detail: {
            [action.updated_rd_item_index]: {
              $set: action.updated_rd_object,
            },
          }
        });
      } else {
        return update(state, {
          resultDaily: {
            [action.updated_item_index]: {
              $set: action.updated_object,
            },
          },
          revenue_detail: {
            [action.updated_rd_item_index]: {
              $set: action.updated_rd_object,
            },
          }
        });
      }



    case GET_TARGET_RESULTS_SUCCEEDED:
      const target_data_name = action.location === '/metrics/arpu' ? 'arpu' : 'revenue';
      const key_total = target_data_name + '_total';
      const target_results = resultToMetrics(action.results, action.resultSpan, action.target_data_name);

      if (action.resultSpan === 'monthly') {
        return {
          ...state,
          resultSpan: 'monthly',
          raw_results: action.results,
          resultMonthly: action.results,
          target_results: target_results,
          target_data_name: target_data_name,
          key_total: key_total
        };
      } else if (action.resultSpan === 'weekly') {
        return {
          ...state,
          resultSpan: 'weekly',
          raw_results: action.results,
          resultWeekly: action.results,
          target_results: target_results,
          target_data_name: target_data_name,
          key_total: key_total
        };
      } else {
        return {
          ...state,
          resultSpan: 'daily',
          raw_results: action.results,
          resultDaily: action.results,
          target_results: target_results,
          target_data_name: target_data_name,
          key_total: key_total
        };
      }

    case GET_TARGET_RESULTS_FAILED:
      return {
        ...state,
        message: action.message,
      };

    default:
      return state;
  }
};

export default resultsReducer;
