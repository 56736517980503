import React from "react";
import styled from "styled-components";
import ForecastSaleCostGraphDefaultCustomLegendItem from './ForecastSaleCostGraphDefaultCustomLegendItem';

export default function ForecastSaleCostGraphDefaultCustomLegend(props: any) {
  const payload = props.payload

  const legend_items_array = [
    {
      class_name: 'customLegend-sm',
      sc_name: 'Sales & Marketing比率',
      color_code: '#0066FF'
    },
    {
      class_name: 'customLegend-rd',
      sc_name: 'Research & Development比率',
      color_code: '#E53E66'
    },
    {
      class_name: 'customLegend-ga',
      sc_name: 'General & Administrative比率',
      color_code: '#EAA800'
    },
  ];
  return (
    <>
      <LegendList
        style={{ display: "flex", flexWrap: "wrap", alignItems: "center", fontSize: "12px", color: "#283f46" }}
      >
        {
          payload.map((result: any, index: number) => (
            <ForecastSaleCostGraphDefaultCustomLegendItem
              result={result}
              index={index}
              class_name={legend_items_array[index].class_name}
              sc_name={legend_items_array[index].sc_name}
            />
          ))
        }
      </LegendList>
    </>
  );
}


//customLegend
const LegendList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  top: -4px;
`;
