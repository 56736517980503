import { all, call, put} from "redux-saga/effects"
import firebase from "firebase"

import { 
  GET_RESULTS_FOR_TARGET_FORECAST_SUCCEEDED, 
  GET_RESULTS_FOR_TARGET_FORECAST_FAILED, 
  SET_FORECAST_CUSTOM_SPAN
} from "../../reducks/targetForecast/types"
import { HIDE_LOADING } from '../../reducks/loading/types'
import getRawDataPast from './getRawDataPast'
import getRawDataReal from './getRawDataReal'
import targetCollectionGroup from './targetCollectionGroup'
import targetCollectionGroupPast from './targetCollectionGroupPast'
import targetForecastIntegratedArrayDetailReal from './targetForecastIntegratedArrayDetailReal.ts'
import getFutureRevenueDetail from './getFutureRevenueDetail.ts'
import getFutureUnitCostDetail from './getFutureUnitCostDetail.ts'
import getFutureSvgCostDetail from './getFutureSvgCostDetail.ts'

// 「指定期間」以外の未来予測
function* getResultsForTargetForecast(action) {
  try {
    const raw_data_array_past = yield* getRawDataPast(action.company_id, action.forecast_base_span);
    const raw_data_array_real = yield* getRawDataReal(
      action.company_id, 
      action.selected_data_config_data_span, 
      action.custom_span_start_time, 
      action.custom_span_end_time
    );

    const revenue_snapshot_real = yield call(
      targetCollectionGroup, 
      'revenue_detail', 
      action.custom_span_start_time, 
      action.custom_span_end_time,
      action.company_id, 
      action.selected_data_config_data_span, 
      action.selected_data_config_data_type
    );

    const revenue_snapshot_past = yield call(
      targetCollectionGroupPast, 
      'revenue_detail', 
      action.company_id,
      action.selected_data_config_data_type, 
      action.forecast_base_span
    );

    const cost_unit_cost_snapshot_real = yield call(
      targetCollectionGroup, 
      'cost_unit_cost_detail', 
      action.custom_span_start_time, 
      action.custom_span_end_time, 
      action.company_id, 
      action.selected_data_config_data_span, 
      action.selected_data_config_data_type
    );

    const cost_unit_cost_snapshot_past = yield call(
      targetCollectionGroupPast, 
      'cost_unit_cost_detail', 
      action.company_id, 
      action.selected_data_config_data_type, 
      action.forecast_base_span
    );

    const cost_svg_cost_snapshot_real = yield call(
      targetCollectionGroup, 
      'cost_svg_cost_detail', 
      action.custom_span_start_time, 
      action.custom_span_end_time, 
      action.company_id, 
      action.selected_data_config_data_span, 
      action.selected_data_config_data_type
    );

    const cost_svg_cost_snapshot_past = yield call(
      targetCollectionGroupPast, 
      'cost_svg_cost_detail', 
      action.company_id, 
      action.selected_data_config_data_type,
      action.forecast_base_span
    );

    let revenue_detail_results_real = [];
    let revenue_detail_results_past = [];
    let cost_unit_cost_detail_results_real = [];
    let cost_unit_cost_detail_results_past = [];
    let cost_svg_cost_detail_results_real = [];
    let cost_svg_cost_detail_results_past = [];

    revenue_snapshot_real.forEach(doc => {
      revenue_detail_results_real = [...revenue_detail_results_real, { ...doc.data(), docId: doc.id }];
      revenue_detail_results_real = revenue_detail_results_real.filter(result => 
        result.filter === 'product' 
        && 
        result.resultSpan === action.selected_data_config_data_type
      );
    });

    revenue_snapshot_past.forEach(doc => {
      revenue_detail_results_past = [
        ...revenue_detail_results_past, 
        { ...doc.data(), docId: doc.id }
      ];
      revenue_detail_results_past = revenue_detail_results_past.filter(result => 
        result.filter === 'product' 
        && 
        result.resultSpan === action.selected_data_config_data_type
      );
    });

    cost_unit_cost_snapshot_real.forEach(doc => {
      cost_unit_cost_detail_results_real = [
        ...cost_unit_cost_detail_results_real,
        { ...doc.data(), docId: doc.id }
      ];
      cost_unit_cost_detail_results_real = cost_unit_cost_detail_results_real.filter(result => 
        result.category_1 === '原価' 
      );
    });

    cost_unit_cost_snapshot_past.forEach(doc => {
      cost_unit_cost_detail_results_past = [
        ...cost_unit_cost_detail_results_past,
        { ...doc.data(), docId: doc.id }
      ];
      cost_unit_cost_detail_results_past = cost_unit_cost_detail_results_past.filter(result => 
        result.category_1 === '原価' 
      );
    });

    cost_svg_cost_snapshot_real.forEach(doc => {
      cost_svg_cost_detail_results_real = [
        ...cost_svg_cost_detail_results_real, 
        {
          ...doc.data(), 
          docId: doc.id 
        }
      ];
      cost_svg_cost_detail_results_real = cost_svg_cost_detail_results_real.filter(result => 
        result.category_1 === '販管費'
      );
    });

    cost_svg_cost_snapshot_past.forEach(doc => {
      cost_svg_cost_detail_results_past = [
        ...cost_svg_cost_detail_results_past,
        {
          ...doc.data(), 
          docId: doc.id 
        }
      ];
      cost_svg_cost_detail_results_past = cost_svg_cost_detail_results_past.filter(result => 
        result.category_1 === '販管費' 
      );
    });


    const integrated_array_forForecast = targetForecastIntegratedArrayDetailReal(
      revenue_detail_results_real, 
      cost_unit_cost_detail_results_real, 
      cost_svg_cost_detail_results_real, 
      action.selected_data_config_data_type, 
      raw_data_array_real
    )


    const integrated_array_forForecast_without_future = integrated_array_forForecast.filter(result => 
      result.result_at.seconds * 1000 <= new Date().getTime()
    );

    // -----------------------------------------------------
    // 過去分のrevenue_detail配列を取得
    const future_revenue_detail = getFutureRevenueDetail(
      revenue_detail_results_past, 
      Number(action.forecast_base_span)
    );

    const future_unit_cost_detail = getFutureUnitCostDetail(
      cost_unit_cost_detail_results_past, 
      Number(action.forecast_base_span)
    );

    const future_svg_cost_detail = getFutureSvgCostDetail(
      cost_svg_cost_detail_results_past,
      Number(action.forecast_base_span)
    );

    yield all([
      put ({
        type: GET_RESULTS_FOR_TARGET_FORECAST_SUCCEEDED,
        raw_data_array_past: raw_data_array_past,
        forecast_base_span: Number(action.forecast_base_span),
        raw_data_array_real: raw_data_array_real,
        revenue_detail_results: revenue_detail_results_real,
        revenue_detail_results_past: revenue_detail_results_past,
        cost_unit_cost_detail_results: cost_unit_cost_detail_results_real,
        cost_svg_cost_detail_results: cost_svg_cost_detail_results_real,
        selected_data_config_data_type: action.selected_data_config_data_type,
        integrated_array_forForecast: integrated_array_forForecast_without_future,
        future_revenue_detail: future_revenue_detail,
        future_unit_cost_detail: future_unit_cost_detail,
        future_svg_cost_detail: future_svg_cost_detail
      }),
      put({
        type: SET_FORECAST_CUSTOM_SPAN,
        forecast_custom_span_start_time: action.forecast_custom_span_start_time,
        forecast_custom_span_end_time: action.forecast_custom_span_end_time,
        forecast_base_span: Number(action.forecast_base_span)
      }),
    ])

  } catch (e) {
    yield all([
      put({
        type: HIDE_LOADING
      }),
      put({
        type: GET_RESULTS_FOR_TARGET_FORECAST_FAILED,
        message: e.message
      }),
    ]);
  }
}

export default getResultsForTargetForecast;