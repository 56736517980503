import rsf from '../rsf';
import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import firebase from 'firebase';
import { HIDE_LOADING } from '../reducks/loading/types'
import { GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_ARPU, GET_RESULTS_FOR_SPAN_COMPARISON_ARPU_SUCCEEDED, GET_RESULTS_FOR_SPAN_COMPARISON_ARPU_FAILED } from '../reducks/metrics/arpu/types';
import { GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_UNIT_ECONOMICS, GET_RESULTS_FOR_SPAN_COMPARISON_UNIT_ECONOMICS_SUCCEEDED, GET_RESULTS_FOR_SPAN_COMPARISON_UNIT_ECONOMICS_FAILED } from '../reducks/metrics/unitEconomics/types';
import { GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_CONVERSION_RATE, GET_RESULTS_FOR_SPAN_COMPARISON_CONVERSION_RATE_SUCCEEDED, GET_RESULTS_FOR_SPAN_COMPARISON_CONVERSION_RATE_FAILED } from '../reducks/metrics/conversionRate/types';
import { GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_REVENUE, GET_RESULTS_FOR_SPAN_COMPARISON_REVENUE_SUCCEEDED, GET_RESULTS_FOR_SPAN_COMPARISON_REVENUE_FAILED } from '../reducks/revenue/types';
import { GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_CAC, GET_RESULTS_FOR_SPAN_COMPARISON_CAC_SUCCEEDED, GET_RESULTS_FOR_SPAN_COMPARISON_CAC_FAILED } from '../reducks/metrics/cac/types';
import { GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_CHURN, GET_RESULTS_FOR_SPAN_COMPARISON_CHURN_SUCCEEDED, GET_RESULTS_FOR_SPAN_COMPARISON_CHURN_FAILED } from '../reducks/metrics/churn/types';
import { GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_LTV, GET_RESULTS_FOR_SPAN_COMPARISON_LTV_SUCCEEDED, GET_RESULTS_FOR_SPAN_COMPARISON_LTV_FAILED } from '../reducks/metrics/ltv/types';
import { GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_MRR, GET_RESULTS_FOR_SPAN_COMPARISON_MRR_SUCCEEDED, GET_RESULTS_FOR_SPAN_COMPARISON_MRR_FAILED } from '../reducks/metrics/mrr/types';
import { GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_PAYBACK_PERIOD, GET_RESULTS_FOR_SPAN_COMPARISON_PAYBACK_PERIOD_SUCCEEDED, GET_RESULTS_FOR_SPAN_COMPARISON_PAYBACK_PERIOD_FAILED } from '../reducks/metrics/paybackPeriod/types';
import { GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_FUNNEL_SUMMARY, GET_RESULTS_FOR_SPAN_COMPARISON_FUNNEL_SUMMARY_SUCCEEDED, GET_RESULTS_FOR_SPAN_COMPARISON_FUNNEL_SUMMARY_FAILED, GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_FUNNEL_DETAIL, GET_RESULTS_FOR_SPAN_COMPARISON_FUNNEL_DETAIL_SUCCEEDED, GET_RESULTS_FOR_SPAN_COMPARISON_FUNNEL_DETAIL_FAILED } from '../reducks/funnels/types';
import { GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_FORECASTS, GET_RESULTS_FOR_SPAN_COMPARISON_FORECASTS_SUCCEEDED, GET_RESULTS_FOR_SPAN_COMPARISON_FORECASTS_FAILED } from '../reducks/newForecast/types';
import { GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_METRICS_FORECASTS, GET_RESULTS_FOR_SPAN_COMPARISON_METRICS_FORECASTS_SUCCEEDED, GET_RESULTS_FOR_SPAN_COMPARISON_METRICS_FORECASTS_FAILED } from '../reducks/newMetricsForecast/types';
import { GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_MARKETING_FUNNEL_FORECASTS, GET_RESULTS_FOR_SPAN_COMPARISON_MARKETING_FUNNEL_FORECASTS_SUCCEEDED, GET_RESULTS_FOR_SPAN_COMPARISON_MARKETING_FUNNEL_FORECASTS_FAILED } from '../reducks/newFunnelForecast/types';
import { GET_RESULTS_FAILED, GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_DATA, GET_RESULTS_FOR_SPAN_COMPARISON_DATA_SUCCEEDED, GET_RESULTS_FOR_SPAN_COMPARISON_DATA_FAILED } from '../reducks/results/types';

const db = firebase.firestore();

// ======================================================= //
// 期間比較ののカレンダーからデータを取得する用（月、日切り替え）
// ======================================================= //
function* getResultsForSpanComparisonArpu(action) {
  console.log('action', action);
  console.log('fetching result');
  const start = action.startDate;
  const startMonth = start.getMonth();
  const startYear = start.getFullYear();
  const startDate = start.getDate();
  const startDayAM00 = new Date(startYear, startMonth, startDate); //action.startDateは時間が現在時刻のため0:00にしたい（日次表示表）
  const startDay0 = new Date(startYear, startMonth, 1); //action.startDateは日付が半端なので１日にしたい。(月次表示用)

  const end = action.endDate;

  try {
    const resultSpan = action.resultSpan;

    let snapshot = [];

    if (resultSpan === 'daily') {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_daily`).where('result_at', '>=', startDayAM00).where('result_at', '<=', end));
    } else if (resultSpan === 'weekly') {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_weekly`).where('result_at', '>=', startDayAM00).where('result_at', '<=', end));
    } else {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_monthly`).where('result_at', '>=', startDay0).where('result_at', '<=', end));
    }
    let results = [];

    snapshot.forEach((result) => {
      results = [...results, { ...result.data() }];
    });
    console.log('results', results, action.spanOrder);
    yield all([
      put({ type: GET_RESULTS_FOR_SPAN_COMPARISON_ARPU_SUCCEEDED, results: results, resultSpan: resultSpan, spanOrder: action.spanOrder }),
      put({ type: HIDE_LOADING })
    ]) 
  } catch (e) {
    yield all([
      put({ type: GET_RESULTS_FOR_SPAN_COMPARISON_ARPU_FAILED, message: e.message }),
      put({ type: HIDE_LOADING })
    ]) 
  }
}

function* getResultsForSpanComparisonUnitEconomics(action) {
  console.log('action', action);
  console.log('fetching result');
  const start = action.startDate;
  const startMonth = start.getMonth();
  const startYear = start.getFullYear();
  const startDate = start.getDate();
  const startDayAM00 = new Date(startYear, startMonth, startDate); //action.startDateは時間が現在時刻のため0:00にしたい（日次表示表）
  const startDay0 = new Date(startYear, startMonth, 1); //action.startDateは日付が半端なので１日にしたい。(月次表示用)

  // console.log("action.resultSpan", action.resultSpan)
  // console.log("startDayAM00", startDayAM00)

  const end = action.endDate;

  try {
    const resultSpan = action.resultSpan;

    let snapshot = [];

    if (resultSpan === 'daily') {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_daily`).where('result_at', '>=', startDayAM00).where('result_at', '<=', end));
    } else if (resultSpan === 'weekly') {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_weekly`).where('result_at', '>=', startDayAM00).where('result_at', '<=', end));
    } else {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_monthly`).where('result_at', '>=', startDay0).where('result_at', '<=', end));
    }
    let results = [];

    snapshot.forEach((result) => {
      results = [...results, { ...result.data() }];
    });
    console.log('results', results, action.spanOrder);
    yield all([
      put({ type: GET_RESULTS_FOR_SPAN_COMPARISON_UNIT_ECONOMICS_SUCCEEDED, results: results, resultSpan: resultSpan, spanOrder: action.spanOrder }),
      put({ type: HIDE_LOADING })
    ]) 
  } catch (e) { 
    yield all([
      put({ type: GET_RESULTS_FOR_SPAN_COMPARISON_UNIT_ECONOMICS_FAILED, message: e.message }),
      put({ type: HIDE_LOADING })
    ])
  }
}

function* getResultsForSpanComparisonConversionRate(action) {
  console.log('action', action);
  console.log('fetching result');
  const start = action.startDate;
  const startMonth = start.getMonth();
  const startYear = start.getFullYear();
  const startDate = start.getDate();
  const startDayAM00 = new Date(startYear, startMonth, startDate); //action.startDateは時間が現在時刻のため0:00にしたい（日次表示表）
  const startDay0 = new Date(startYear, startMonth, 1); //action.startDateは日付が半端なので１日にしたい。(月次表示用)

  // console.log("action.resultSpan", action.resultSpan)
  // console.log("startDayAM00", startDayAM00)

  const end = action.endDate;

  try {
    const resultSpan = action.resultSpan;

    let snapshot = [];

    if (resultSpan === 'daily') {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_daily`).where('result_at', '>=', startDayAM00).where('result_at', '<=', end));
    } else if (resultSpan === 'weekly') {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_weekly`).where('result_at', '>=', startDayAM00).where('result_at', '<=', end));
    } else {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_monthly`).where('result_at', '>=', startDay0).where('result_at', '<=', end));
    }
    let results = [];

    snapshot.forEach((result) => {
      results = [...results, { ...result.data() }];
    });
    console.log('results', results, action.spanOrder);
    yield all([
      put({ type: GET_RESULTS_FOR_SPAN_COMPARISON_CONVERSION_RATE_SUCCEEDED, results: results, resultSpan: resultSpan, spanOrder: action.spanOrder }),
      put({ type: HIDE_LOADING })
    ]) 
  } catch (e) {
    yield all([
      put({ type: GET_RESULTS_FOR_SPAN_COMPARISON_CONVERSION_RATE_FAILED, message: e.message }),
      put({ type: HIDE_LOADING })
    ])
  }
}

function* getResultsForSpanComparisonRevenue(action) {
  console.log(action);
  console.log('fetching result');
  const start = action.startDate;
  const startMonth = start.getMonth();
  const startYear = start.getFullYear();
  const startDate = start.getDate();
  const startDayAM00 = new Date(startYear, startMonth, startDate); //action.startDateは時間が現在時刻のため0:00にしたい（日次表示表）
  const startDay0 = new Date(startYear, startMonth, 1); //action.startDateは日付が半端なので１日にしたい。(月次表示用)
  console.log(startDayAM00);

  const end = action.endDate;

  try {
    const resultSpan = action.resultSpan;
    let snapshot = [];

    if (resultSpan === 'daily') {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_daily`).where('result_at', '>=', startDayAM00).where('result_at', '<=', end));
    } else if (resultSpan === 'weekly') {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_weekly`).where('result_at', '>=', startDayAM00).where('result_at', '<=', end));
    } else {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_monthly`).where('result_at', '>=', startDay0).where('result_at', '<=', end));
    }
    let results = [];

    snapshot.forEach((result) => {
      results = [...results, { ...result.data() }];
    });
    console.log('results', results);
    yield all([
      put({ type: GET_RESULTS_FOR_SPAN_COMPARISON_REVENUE_SUCCEEDED, results: results, resultSpan: resultSpan, spanOrder: action.spanOrder }),
      put({ type: HIDE_LOADING })
    ]) 
  } catch (e) {
    yield all([
      put({ type: GET_RESULTS_FOR_SPAN_COMPARISON_REVENUE_FAILED, message: e.message }),
      put({ type: HIDE_LOADING })
    ])
  }
}

function* getResultsForSpanComparisonCac(action) {
  console.log('action', action);
  console.log('fetching result');
  const start = action.startDate;
  const startMonth = start.getMonth();
  const startYear = start.getFullYear();
  const startDate = start.getDate();
  const startDayAM00 = new Date(startYear, startMonth, startDate); //action.startDateは時間が現在時刻のため0:00にしたい（日次表示表）
  const startDay0 = new Date(startYear, startMonth, 1); //action.startDateは日付が半端なので１日にしたい。(月次表示用)

  const end = action.endDate;

  try {
    const resultSpan = action.resultSpan;

    let snapshot = [];

    if (resultSpan === 'daily') {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_daily`).where('result_at', '>=', startDayAM00).where('result_at', '<=', end));
    } else if (resultSpan === 'weekly') {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_weekly`).where('result_at', '>=', startDayAM00).where('result_at', '<=', end));
    } else {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_monthly`).where('result_at', '>=', startDay0).where('result_at', '<=', end));
    }
    let results = [];

    snapshot.forEach((result) => {
      results = [...results, { ...result.data() }];
    });
    console.log('results', results, action.spanOrder);
    yield all([
      put({ type: GET_RESULTS_FOR_SPAN_COMPARISON_CAC_SUCCEEDED, results: results, resultSpan: resultSpan, spanOrder: action.spanOrder }),
      put({ type: HIDE_LOADING })
    ]) 
  } catch (e) {
    yield all([
      put({ type: GET_RESULTS_FOR_SPAN_COMPARISON_CAC_FAILED, message: e.message }),
      put({ type: HIDE_LOADING })
    ])
  }
}

function* getResultsForSpanComparisonChurn(action) {
  console.log('action', action);
  console.log('fetching result');
  const start = action.startDate;
  const startMonth = start.getMonth();
  const startYear = start.getFullYear();
  const startDate = start.getDate();
  const startDayAM00 = new Date(startYear, startMonth, startDate); //action.startDateは時間が現在時刻のため0:00にしたい（日次表示表）
  const startDay0 = new Date(startYear, startMonth, 1); //action.startDateは日付が半端なので１日にしたい。(月次表示用)

  const end = action.endDate;

  try {
    const resultSpan = action.resultSpan;

    let snapshot = [];

    if (resultSpan === 'daily') {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_daily`).where('result_at', '>=', startDayAM00).where('result_at', '<=', end));
    } else if (resultSpan === 'weekly') {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_weekly`).where('result_at', '>=', startDayAM00).where('result_at', '<=', end));
    } else {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_monthly`).where('result_at', '>=', startDay0).where('result_at', '<=', end));
    }
    let results = [];

    snapshot.forEach((result) => {
      results = [...results, { ...result.data() }];
    });
    console.log('results', results, action.spanOrder);
    yield all([
      put({ type: GET_RESULTS_FOR_SPAN_COMPARISON_CHURN_SUCCEEDED, results: results, resultSpan: resultSpan, spanOrder: action.spanOrder }),
      put({ type: HIDE_LOADING })
    ]) 
  } catch (e) {
    yield all([
      put({ type: GET_RESULTS_FOR_SPAN_COMPARISON_CHURN_FAILED, message: e.message }),
      put({ type: HIDE_LOADING })
    ])
  }
}

function* getResultsForSpanComparisonLtv(action) {
  console.log('action', action);
  console.log('fetching result');
  const start = action.startDate;
  const startMonth = start.getMonth();
  const startYear = start.getFullYear();
  const startDate = start.getDate();
  const startDayAM00 = new Date(startYear, startMonth, startDate); //action.startDateは時間が現在時刻のため0:00にしたい（日次表示表）
  const startDay0 = new Date(startYear, startMonth, 1); //action.startDateは日付が半端なので１日にしたい。(月次表示用)

  const end = action.endDate;

  console.log('startDayAM00', startDayAM00);
  console.log('end', end);

  try {
    const resultSpan = action.resultSpan;
    console.log(resultSpan);

    let snapshot = [];

    if (resultSpan === 'daily') {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_daily`).where('result_at', '>=', startDayAM00).where('result_at', '<=', end));
      console.log('snapshot', snapshot);
    } else if (resultSpan === 'weekly') {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_weekly`).where('result_at', '>=', startDayAM00).where('result_at', '<=', end));
    } else {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_monthly`).where('result_at', '>=', startDay0).where('result_at', '<=', end));
    }
    let results = [];

    snapshot.forEach((result) => {
      results = [...results, { ...result.data() }];
    });
    console.log('results', results, action.spanOrder);
    yield all([
      put({ type: GET_RESULTS_FOR_SPAN_COMPARISON_LTV_SUCCEEDED, results: results, resultSpan: resultSpan, spanOrder: action.spanOrder }),
      put({ type: HIDE_LOADING })
    ]) 
  } catch (e) {
    yield all([
      put({ type: GET_RESULTS_FOR_SPAN_COMPARISON_LTV_FAILED, message: e.message }),
      put({ type: HIDE_LOADING })
    ]) 
  }
}

function* getResultsForSpanComparisonMrr(action) {
  console.log('action', action);
  console.log('fetching result');
  const start = action.startDate;
  const startMonth = start.getMonth();
  const startYear = start.getFullYear();
  const startDate = start.getDate();
  const startDayAM00 = new Date(startYear, startMonth, startDate); //action.startDateは時間が現在時刻のため0:00にしたい（日次表示表）
  const startDay0 = new Date(startYear, startMonth, 1); //action.startDateは日付が半端なので１日にしたい。(月次表示用)

  const end = action.endDate;

  try {
    const resultSpan = action.resultSpan;

    let snapshot = [];

    if (resultSpan === 'daily') {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_daily`).where('result_at', '>=', startDayAM00).where('result_at', '<=', end));
    } else if (resultSpan === 'weekly') {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_weekly`).where('result_at', '>=', startDayAM00).where('result_at', '<=', end));
    } else {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_monthly`).where('result_at', '>=', startDay0).where('result_at', '<=', end));
    }
    let results = [];

    snapshot.forEach((result) => {
      results = [...results, { ...result.data() }];
    });
    console.log('results', results, action.spanOrder);
    yield all([
      put({ type: GET_RESULTS_FOR_SPAN_COMPARISON_MRR_SUCCEEDED, results: results, resultSpan: resultSpan, spanOrder: action.spanOrder }),
      put({ type: HIDE_LOADING })
    ]) 
  } catch (e) {
    yield all([
      put({ type: GET_RESULTS_FOR_SPAN_COMPARISON_MRR_FAILED, message: e.message }),
      put({ type: HIDE_LOADING })
    ]) 
  }
}

function* getResultsForSpanComparisonPaybackPeriod(action) {
  console.log('action', action);
  console.log('fetching result');
  const start = action.startDate;
  const startMonth = start.getMonth();
  const startYear = start.getFullYear();
  const startDate = start.getDate();
  const startDayAM00 = new Date(startYear, startMonth, startDate); //action.startDateは時間が現在時刻のため0:00にしたい（日次表示表）
  const startDay0 = new Date(startYear, startMonth, 1); //action.startDateは日付が半端なので１日にしたい。(月次表示用)

  const end = action.endDate;

  try {
    const resultSpan = action.resultSpan;

    let snapshot = [];

    if (resultSpan === 'daily') {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_daily`).where('result_at', '>=', startDayAM00).where('result_at', '<=', end));
    } else if (resultSpan === 'weekly') {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_weekly`).where('result_at', '>=', startDayAM00).where('result_at', '<=', end));
    } else {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_monthly`).where('result_at', '>=', startDay0).where('result_at', '<=', end));
    }
    let results = [];

    snapshot.forEach((result) => {
      results = [...results, { ...result.data() }];
    });
    console.log('results', results, action.spanOrder);
    yield all([
      put({ type: GET_RESULTS_FOR_SPAN_COMPARISON_PAYBACK_PERIOD_SUCCEEDED, results: results, resultSpan: resultSpan, spanOrder: action.spanOrder }),
      put({ type: HIDE_LOADING })
    ]) 
  } catch (e) {
    yield all([
      put({ type: GET_RESULTS_FOR_SPAN_COMPARISON_PAYBACK_PERIOD_FAILED, message: e.message }),
      put({ type: HIDE_LOADING })
    ]) 
  }
}

function* getResultsForSpanComparisonFunnelSummary(action) {
  console.log('action', action);
  console.log('fetching result');
  const start = action.startDate;
  const startMonth = start.getMonth();
  const startYear = start.getFullYear();
  const startDate = start.getDate();
  const startDayAM00 = new Date(startYear, startMonth, startDate); //action.startDateは時間が現在時刻のため0:00にしたい（日次表示表）
  const startDay0 = new Date(startYear, startMonth, 1); //action.startDateは日付が半端なので１日にしたい。(月次表示用)

  const end = action.endDate;

  try {
    const resultSpan = action.resultSpan;

    let snapshot = [];

    if (resultSpan === 'daily') {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_daily`).where('result_at', '>=', startDayAM00).where('result_at', '<=', end));
    } else if (resultSpan === 'weekly') {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_weekly`).where('result_at', '>=', startDayAM00).where('result_at', '<=', end));
    } else {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_monthly`).where('result_at', '>=', startDay0).where('result_at', '<=', end));
    }
    let results = [];

    snapshot.forEach((result) => {
      results = [...results, { ...result.data() }];
    });
    console.log('results', results, action.spanOrder);
    console.log('action.timeSpan', action.timeSpan);
    console.log('action.company_id', action.company_id);
    console.log('action.firstCalenderStartDate', action.firstCalenderStartDate);
    console.log('action.firstCalenderEndDate', action.firstCalenderEndDate);
    console.log('action.secondCalenderStartDate', action.secondCalenderStartDate);
    console.log('action.secondCalenderEndDate', action.secondCalenderEndDate);
    yield put({ type: GET_RESULTS_FOR_SPAN_COMPARISON_FUNNEL_SUMMARY_SUCCEEDED, results: results, resultSpan: resultSpan, spanOrder: action.spanOrder });
  } catch (e) {
    yield put({ type: GET_RESULTS_FOR_SPAN_COMPARISON_FUNNEL_SUMMARY_FAILED, message: e.message });
  }
}

function* getResultsForSpanComparisonFunnelDetail(action) {
  console.log('action', action);
  console.log('fetching result');
  const start = action.startDate;
  const startMonth = start.getMonth();
  const startYear = start.getFullYear();
  const startDate = start.getDate();
  const startDayAM00 = new Date(startYear, startMonth, startDate); //action.startDateは時間が現在時刻のため0:00にしたい（日次表示表）
  const startDay0 = new Date(startYear, startMonth, 1); //action.startDateは日付が半端なので１日にしたい。(月次表示用)

  const end = action.endDate;

  try {
    const resultSpan = action.resultSpan;

    let snapshot = [];

    if (resultSpan === 'daily') {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_daily`).where('result_at', '>=', startDayAM00).where('result_at', '<=', end));
    } else if (resultSpan === 'weekly') {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_weekly`).where('result_at', '>=', startDayAM00).where('result_at', '<=', end));
    } else {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_monthly`).where('result_at', '>=', startDay0).where('result_at', '<=', end));
    }
    let results = [];

    snapshot.forEach((result) => {
      results = [...results, { ...result.data() }];
    });
    console.log('results', results, action.spanOrder);
    console.log('action.timeSpan', action.timeSpan);
    console.log('action.company_id', action.company_id);
    console.log('action.firstCalenderStartDate', action.firstCalenderStartDate);
    console.log('action.firstCalenderEndDate', action.firstCalenderEndDate);
    console.log('action.secondCalenderStartDate', action.secondCalenderStartDate);
    console.log('action.secondCalenderEndDate', action.secondCalenderEndDate);
    yield all([
      put({ type: GET_RESULTS_FOR_SPAN_COMPARISON_FUNNEL_DETAIL_SUCCEEDED, results: results, resultSpan: resultSpan, spanOrder: action.spanOrder }),
      put({ type: HIDE_LOADING })
    ]) 
  } catch (e) {
    yield all([
      put({ type: GET_RESULTS_FOR_SPAN_COMPARISON_FUNNEL_DETAIL_FAILED, message: e.message }),
      put({ type: HIDE_LOADING })
    ]) 
  }
}

function* getResultsForSpanComparisonForecasts(action) {
  console.log('action', action);
  console.log('fetching result');
  const start = action.startDate;
  const startMonth = start.getMonth();
  const startYear = start.getFullYear();
  const startDate = start.getDate();
  const startDayAM00 = new Date(startYear, startMonth, startDate); //action.startDateは時間が現在時刻のため0:00にしたい（日次表示表）
  const startDay0 = new Date(startYear, startMonth, 1); //action.startDateは日付が半端なので１日にしたい。(月次表示用)

  const end = action.endDate;

  try {
    const resultSpan = action.resultSpan;

    let snapshot = [];

    if (resultSpan === 'daily') {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_daily`).where('result_at', '>=', startDayAM00).where('result_at', '<=', end));
    } else if (resultSpan === 'weekly') {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_weekly`).where('result_at', '>=', startDayAM00).where('result_at', '<=', end));
    } else {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_monthly`).where('result_at', '>=', startDay0).where('result_at', '<=', end));
    }
    let results = [];

    snapshot.forEach((result) => {
      results = [...results, { ...result.data() }];
    });
    console.log('results', results, action.spanOrder);
    yield all([
      put({ type: GET_RESULTS_FOR_SPAN_COMPARISON_FORECASTS_SUCCEEDED, results: results, resultSpan: resultSpan, spanOrder: action.spanOrder }),
      put({ type: HIDE_LOADING })
    ]) 
  } catch (e) {
    yield all([
      put({ type: GET_RESULTS_FOR_SPAN_COMPARISON_FORECASTS_FAILED, message: e.message }),
      put({ type: HIDE_LOADING })
    ]) 
  }
}

function* getResultsForSpanComparisonMetricsForecasts(action) {
  console.log('action', action);
  console.log('fetching result');
  const start = action.startDate;
  const startMonth = start.getMonth();
  const startYear = start.getFullYear();
  const startDate = start.getDate();
  const startDayAM00 = new Date(startYear, startMonth, startDate); //action.startDateは時間が現在時刻のため0:00にしたい（日次表示表）
  const startDay0 = new Date(startYear, startMonth, 1); //action.startDateは日付が半端なので１日にしたい。(月次表示用)

  const end = action.endDate;

  try {
    const resultSpan = action.resultSpan;

    let snapshot = [];

    if (resultSpan === 'daily') {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_daily`).where('result_at', '>=', startDayAM00).where('result_at', '<=', end));
    } else if (resultSpan === 'weekly') {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_weekly`).where('result_at', '>=', startDayAM00).where('result_at', '<=', end));
    } else {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_monthly`).where('result_at', '>=', startDay0).where('result_at', '<=', end));
    }
    let results = [];

    snapshot.forEach((result) => {
      results = [...results, { ...result.data() }];
    });
    console.log('results', results, action.spanOrder);
    yield all([
      put({ type: GET_RESULTS_FOR_SPAN_COMPARISON_METRICS_FORECASTS_SUCCEEDED, results: results, resultSpan: resultSpan, spanOrder: action.spanOrder }),
      put({ type: HIDE_LOADING })
    ])
  } catch (e) {
    yield all([
      put({ type: GET_RESULTS_FOR_SPAN_COMPARISON_METRICS_FORECASTS_FAILED, message: e.message }),
      put({ type: HIDE_LOADING })
    ]) 
  }
}

function* getResultsForSpanComparisonMarketingFunnelForecasts(action) {
  console.log('action', action);
  console.log('fetching result');
  const start = action.startDate;
  const startMonth = start.getMonth();
  const startYear = start.getFullYear();
  const startDate = start.getDate();
  const startDayAM00 = new Date(startYear, startMonth, startDate); //action.startDateは時間が現在時刻のため0:00にしたい（日次表示表）
  const startDay0 = new Date(startYear, startMonth, 1); //action.startDateは日付が半端なので１日にしたい。(月次表示用)

  const end = action.endDate;

  try {
    const resultSpan = action.resultSpan;

    let snapshot = [];

    if (resultSpan === 'daily') {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_daily`).where('result_at', '>=', startDayAM00).where('result_at', '<=', end));
    } else if (resultSpan === 'weekly') {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_weekly`).where('result_at', '>=', startDayAM00).where('result_at', '<=', end));
    } else {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_monthly`).where('result_at', '>=', startDay0).where('result_at', '<=', end));
    }
    let results = [];

    snapshot.forEach((result) => {
      results = [...results, { ...result.data() }];
    });
    console.log('results', results, action.spanOrder);
    yield all([
      put({ type: GET_RESULTS_FOR_SPAN_COMPARISON_MARKETING_FUNNEL_FORECASTS_SUCCEEDED, results: results, resultSpan: resultSpan, spanOrder: action.spanOrder }),
      put({ type: HIDE_LOADING })
    ]) 
  } catch (e) {
    yield all([
      put({ type: GET_RESULTS_FOR_SPAN_COMPARISON_MARKETING_FUNNEL_FORECASTS_FAILED, message: e.message }),
      put({ type: HIDE_LOADING })
    ])
  }
}

function* getResultsForSpanComparisonData(action) {
  const start = action.startDate;
  const startMonth = start.getMonth();
  const startYear = start.getFullYear();
  const startDate = start.getDate();
  const startDayAM00 = new Date(startYear, startMonth, startDate); //action.startDateは時間が現在時刻のため0:00にしたい（日次表示表）
  const startDay0 = new Date(startYear, startMonth, 1); //action.startDateは日付が半端なので１日にしたい。(月次表示用)

  const end = action.endDate;

  try {
    const resultSpan = action.resultSpan;

    let snapshot = [];

    if (resultSpan === 'daily') {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_daily`).where('result_at', '>=', startDayAM00).where('result_at', '<=', end));
    } else if (resultSpan === 'weekly') {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_weekly`).where('result_at', '>=', startDayAM00).where('result_at', '<=', end));
    } else {
      snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_monthly`).where('result_at', '>=', startDay0).where('result_at', '<=', end));
    }
    let results = [];

    snapshot.forEach((result) => {
      results = [...results, { ...result.data() }];
    });

    yield all([
      put({ type: GET_RESULTS_FOR_SPAN_COMPARISON_DATA_SUCCEEDED, results: results, resultSpan: resultSpan, spanOrder: action.spanOrder, result_data_type: action.result_data_type }),
      put({ type: HIDE_LOADING })
    ])
  } catch (e) {
    yield all([
      put({ type: GET_RESULTS_FOR_SPAN_COMPARISON_DATA_FAILED, message: e.message }),
      put({ type: HIDE_LOADING })
    ]) 
  }
}

const spanComparisonSagas = [takeEvery(GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_CONVERSION_RATE, getResultsForSpanComparisonConversionRate), takeEvery(GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_UNIT_ECONOMICS, getResultsForSpanComparisonUnitEconomics), takeEvery(GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_PAYBACK_PERIOD, getResultsForSpanComparisonPaybackPeriod), takeEvery(GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_MRR, getResultsForSpanComparisonMrr), takeEvery(GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_LTV, getResultsForSpanComparisonLtv), takeEvery(GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_CHURN, getResultsForSpanComparisonChurn), takeEvery(GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_CAC, getResultsForSpanComparisonCac), takeEvery(GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_ARPU, getResultsForSpanComparisonArpu), takeEvery(GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_REVENUE, getResultsForSpanComparisonRevenue), takeEvery(GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_FUNNEL_SUMMARY, getResultsForSpanComparisonFunnelSummary), takeEvery(GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_FUNNEL_DETAIL, getResultsForSpanComparisonFunnelDetail), takeEvery(GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_FORECASTS, getResultsForSpanComparisonForecasts), takeEvery(GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_DATA, getResultsForSpanComparisonData), takeEvery(GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_METRICS_FORECASTS, getResultsForSpanComparisonMetricsForecasts), takeEvery(GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_MARKETING_FUNNEL_FORECASTS, getResultsForSpanComparisonMarketingFunnelForecasts)];

export default spanComparisonSagas;
