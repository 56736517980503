import React, { useEffect, useCallback } from "react";
import FileSaver from "file-saver";
import {
  updateDownloadGraphStatus,
} from "../../../reducks/download/actions";
import { useCurrentPng } from "recharts-to-png";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import SpanComparisonFilter from "../../SpanComparisonFilters/SpanComparisonFilter";
import ForecastPLDataFilterList from "./ForecastPLDataFilterList";
import ForecastPLGraphDefault from "./ForecastPLGraphDefault";
import ForecastPLGraphSpanFilter from "./ForecastPLGraphSpanFilter";
import ForecastPLGraphFuture from "./ForecastPLGraphFuture";
import ForecastPLTableSpanFilter from "./ForecastPLTableSpanFilter";
import ForecastPLTableFuture from "./ForecastPLTableFuture";
import ForecastPLHeader from "./ForecastPLHeader";
import { getIntegratedArray } from "../../../reducks/targetForecast/actions";
import { BusinessPlanTable } from "../../Table/BusinessPlanTable";

import { unitSwitcher } from "../../Util/unitSwitcher";
import { colorSwitcherRate } from "../../Util/colorSwitcher";
import reached_arrow from "../../../images/icons/reached_arrow.svg";
import unreached_arrow from "../../../images/icons/unreached_icon.svg";

function ForecastPLIndex() {
  const dispatch = useDispatch();
  const forecast_filter_status = useSelector(
    (state: any) => state.target_forecast.forecast_filter_status
  );
  const activatedFilter = useSelector((state: any) => state.filter);
  const raw_data_array = useSelector(
    (state: any) => state.raw_data.raw_data_array
  );
  const resultSpan = useSelector((state: any) => state.target_data.resultSpan);

  // グラフの表示切り替え
  const this_time_graph_switch = useSelector(
    (state: any) => state.session.this_time_graph_switch
  );
  const moving_average_graph_switch = useSelector(
    (state: any) => state.session.moving_average_graph_switch
  );

  // グラフで必要なデータキー名
  const target_data_name = useSelector(
    (state: any) => state.target_data.target_data_name
  );
  const key_total = target_data_name + "_total";
  const key_objective = target_data_name + "_objective";
  const key_rate = target_data_name + "_rate";
  const key_difference = target_data_name + "_difference";
  const key_moving_average = target_data_name + "_moving_average_total";
  const key_moving_average_objective =
    target_data_name + "_moving_average_objective";
  const key_last_time_comparison_rate =
    target_data_name + "_last_time_comparison_rate";
  const key_last_time_difference = target_data_name + "_last_time_difference";

  const target_data = useSelector(
    (state: any) => state.target_data.target_data
  );
  let spanComparisonData;
  const target_data_first_span = useSelector(
    (state: any) => state.target_data.target_data_first_span
  );
  const target_data_second_span = useSelector(
    (state: any) => state.target_data.target_data_second_span
  );

  const selected_data_config_data_type = useSelector(
    (state: any) => state.raw_data.selected_data_config.data_type
  );
  const selected_data_config_data_span = useSelector(
    (state: any) => state.raw_data.selected_data_config.data_span
  );
  const custom_span_start_time = useSelector(
    (state: any) => state.raw_data.selected_data_config.custom_span_start_time
  );
  const custom_span_end_time = useSelector(
    (state: any) => state.raw_data.selected_data_config.custom_span_end_time
  );
  const company_id = useSelector((state: any) => state.user.company_id);
  const integrated_array = useSelector(
    (state: any) => state.target_forecast.integrated_array
  );
  const comments = useSelector((state: any) => state.comments.comments);
  const displayType = useSelector((state: any) => state.graph.displayType);

  spanComparisonData = target_data_first_span.map(
    (data: any, index: number) => {
      return { ...data, ...target_data_second_span[index] };
    }
  );

  useEffect(() => {
    dispatch(
      getIntegratedArray(
        raw_data_array,
        company_id,
        selected_data_config_data_type,
        selected_data_config_data_span,
        custom_span_start_time,
        custom_span_end_time
      )
    );
  }, [
    dispatch,
    raw_data_array,
    company_id,
    selected_data_config_data_type,
    selected_data_config_data_span,
    custom_span_start_time,
    custom_span_end_time
  ]);

  const real_data = integrated_array.filter(
    (item: any) => item.real_data === true
  );

  // Graph Download Section
  const [getAreaPng, { ref: areaRef }] = useCurrentPng();
  const downloadGraphStatus = useSelector((state: any) => state.download.downloadGraphStatus);
  const handleAreaDownload = useCallback(async () => {
    dispatch(
      updateDownloadGraphStatus(0)
    )
    const png = await getAreaPng();
    if (png) {
      FileSaver.saveAs(png, "graph.png");
    }
  }, [getAreaPng, dispatch]);

  if (downloadGraphStatus === 1) {
    handleAreaDownload();
  }

  return (
    <ContentCard>
      <ForecastPLDataFilterList />

      {/* 事業計画PLヘッダー */}
      {integrated_array[integrated_array.length - 1]
        ? forecast_filter_status === "reset_forecast" &&
          activatedFilter === "" && (
            <ForecastPLHeader
              resultSpan={resultSpan}
              target_data={target_data}
              target_data_name={target_data_name}
              key_total={key_total}
              key_rate={key_rate}
              key_difference={key_difference}
              key_last_time_comparison_rate={key_last_time_comparison_rate}
              key_last_time_difference={key_last_time_difference}
            />
          )
        : "データが不足しています"}

      <CardBody>
        {/* 期間比較フィルター */}
        {activatedFilter === "期間比較" && <SpanComparisonFilter />}

        {/* グラフ：フィルターなし */}
        {forecast_filter_status === "reset_forecast" &&
          activatedFilter === "" && (
            <ForecastPLGraphDefault
              areaRef={areaRef}
              integrated_array={real_data}
              comments={comments.filter(
                (comment: any) => comment.target_data_name === "PL"
              )}
              displayType={displayType}
              moving_average_graph_switch={moving_average_graph_switch}
            />
          )}

        {/* グラフ：期間比較 */}
        {forecast_filter_status === "reset_forecast" &&
          activatedFilter === "期間比較" && (
            <ForecastPLGraphSpanFilter
              areaRef={areaRef}
              activatedFilter={activatedFilter}
              this_time_graph_switch={this_time_graph_switch}
              moving_average_graph_switch={moving_average_graph_switch}
              target_data_name={target_data_name}
              key_total={key_total}
              key_objective={key_objective}
              key_moving_average={key_moving_average}
              key_moving_average_objective={key_moving_average_objective}
              target_data={target_data}
              spanComparisonData={spanComparisonData}
              resultSpan={resultSpan}
            />
          )}

        {/* グラフ：未来予測 */}
        {forecast_filter_status !== "reset_forecast" && (
          <ForecastPLGraphFuture
            areaRef={areaRef}
            integrated_array={integrated_array}
            comments={comments.filter(
              (comment: any) => comment.target_data_name === "PL"
            )}
          />
        )}

        {/* デフォルト表示テーブル */}
        {forecast_filter_status === "reset_forecast" &&
          activatedFilter !== "期間比較" && (
            <BusinessPlanTable
              dataType="business"
              forecastData={integrated_array}
            />
          )}
        {/* テーブル：期間比較 */}
        {forecast_filter_status === "reset_forecast" &&
          activatedFilter === "期間比較" && <ForecastPLTableSpanFilter />}
        {/* テーブル：未来予測 */}
        {forecast_filter_status !== "reset_forecast" && (
          <ForecastPLTableFuture />
        )}
      </CardBody>
    </ContentCard>
  );
}

export default ForecastPLIndex;

// ContentCard
const ContentCard = styled.div`
  margin-bottom: 16px;
  border: 1px solid #e1e8ea;
  box-sizing: border-box;
  background-color: #fff;
`;
// CardBody
const CardBody = styled.div`
  padding: 15px 17px;
  box-sizing: border-box;
  position: relative;
`;

// CardTitle
const CardTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #2a3385;
  margin: 0 0 5px 0;
`;
// Sum
const Sum = styled.p`
  font-size: 28px;
  font-weight: bold;
  line-height: 33px;
  color: #0066ff;
  margin: 0 0 24px 0;
`;

// ThisMonthTotal
const ThisMonthTotal = styled.p`
  font-size: 24px;
  font-weight: bold;
  line-height: 36px;
  color: #435878;
  margin: 0 0 24px 0;
  &.reached {
    color: #028cab;
  }
  &.unreached {
    color: #af002a;
  }
  &.equal {
    color: #35455d;
  }
  span {
    display: inline-block;
    font-size: 12px;
    font-weight: normal;
    position: relative;
    margin-left: 10px;
    top: -4px;
    &.reached {
      color: #028cab;
      &::before {
        content: "";
        display: inline-block;
        height: 18px;
        width: 24px;
        top: 5px;
        position: relative;
        background-image: url(${reached_arrow});
        background-repeat: no-repeat;
      }
    }
    &.unreached {
      color: #af002a;
      &::before {
        content: "";
        display: inline-block;
        height: 18px;
        width: 24px;
        top: 5px;
        position: relative;
        background-image: url(${unreached_arrow});
        background-repeat: no-repeat;
      }
    }
    &.equal {
      color: #35455d;
      &::before {
        content: "";
        display: inline-block;
        height: 18px;
        width: 20px;
      }
    }
  }
`;
