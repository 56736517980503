import React, { useEffect, useCallback, useRef } from "react";
import FileSaver from "file-saver";
import {
  updateDownloadGraphStatus,
} from "../../reducks/download/actions";
import {
  updateDownloadCsvTarget,
} from "../../reducks/download/actions";
import { useCurrentPng } from "recharts-to-png";
import { exportComponentAsPNG } from 'react-component-export-image';
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import GlobalNav from "../Layout/GlobalNav";
import PageHeader from "../Layout/PageHeader";
import MetricsSubMenu from "../Layout/MetricsSubMenu";
import MetricsHeader from "./MetricsHeader";
// データの名前から単位を切り替える関数をimport

import { titleSwitcher } from "../Util/titleSwitcher";

// ページ内コンポーネントをインポート
import DataFilters from "../Parts/DataFilters";
import DataGraph from "../Parts/DataGraph";

// URLからデータ名へ変換
import { startTargetDataSequence } from "../../reducks/targetData/actions";

import SpanComparisonFilter from "../SpanComparisonFilters/SpanComparisonFilter";

import ValiantPieChart from "./ValiantPieChart";
import StackedDetailChartGrr from "../Graph/StackedDetailChartGrr";
import StackedDetailChartNrr from "../Graph/StackedDetailChartNrr";
import StackedDetailChartMrr from "../Graph/StackedDetailChartMrr";
import StackedDetailChartRevenueMrr from "../Graph/StackedDetailChartRevenueMrr";
import { setForecastFilterStatus } from "../../reducks/targetForecast/actions";
import { DraftTable } from "../Table/Draft";
import { DraftRevenueMrrTable } from "../Table/DraftRevenueMrr";
import TableSpanFilter from "../Table/TableSpanFilter";

import PlanComparisonGraphAndTable from "./PlanComparison/PlanComparisonGraphAndTable";
import VariantFilterGraphAndTable from "./VariantFilter/VariantFilterGraphAndTable";
import SpanComparisonTable from "./SpanComparison/SpanComparisonTable";
import GraphDataDownload from "../Parts/GraphDataDownload";

const Metrics = () => {
  const dispatch = useDispatch();
  const valiantPieRef = useRef<HTMLDivElement>(null);
  // URLを取得
  const location = useLocation();
  const pathName = location.pathname;

  // store
  const expanderActivated = useSelector(
    (state: any) => state.session.expanderActivated
  );
  const resultSpan = useSelector((state: any) => state.target_data.resultSpan);
  const selected_data_config_data_type = useSelector(
    (state: any) => state.raw_data.selected_data_config.data_type
  );
  const raw_data_array = useSelector(
    (state: any) => state.raw_data.raw_data_array
  );
  const target_data_name = useSelector(
    (state: any) => state.target_data.target_data_name
  );
  const key_total = useSelector((state: any) => state.target_data.key_total);
  const key_objective = useSelector(
    (state: any) => state.target_data.key_objective
  );
  const key_rate = useSelector((state: any) => state.target_data.key_rate);
  const key_last_time_comparison_rate = useSelector(
    (state: any) => state.target_data.key_last_time_comparison_rate
  );
  const key_last_time_difference = useSelector(
    (state: any) => state.target_data.key_last_time_difference
  );
  const key_difference = useSelector(
    (state: any) => state.target_data.key_difference
  );
  const target_data = useSelector(
    (state: any) => state.target_data.target_data
  );

  const activatedFilter = useSelector((state: any) => state.filter);
  const page_title = titleSwitcher(target_data_name);

  const labels_name_array = useSelector(
    (state: any) => state.raw_data.labels_name_array
  );
  const filtered_data = useSelector(
    (state: any) => state.raw_data.filtered_data
  );

  // Graph Download Section
  const [getBarAreaPng, { ref: barAreaRef }] = useCurrentPng();

  const handleAreaDownload = useCallback(async (param) => {
    dispatch(
      updateDownloadGraphStatus(0)
    )
    let png;
    if(param==='valiant_pie'){
      exportComponentAsPNG(valiantPieRef);
    }else{
      png = await getBarAreaPng();
    }
    if (png) {
      FileSaver.saveAs(png, "graph.png");
    }
  }, [getBarAreaPng, dispatch]);

  function handleGraphDataDownloadClick(e:any, target:string) {
    if(e.target.innerText==='グラフ Export'){
        handleAreaDownload(target);
    }
    if(target==='valiant_pie'){
      let filter = (activatedFilter==='product')?'プラン比較':activatedFilter;
      dispatch(
        updateDownloadCsvTarget("/"+filter+"/"+target)
      )
    }else{
      dispatch(
        updateDownloadCsvTarget("/"+target)
      )
    }
  }

  useEffect(() => {
    dispatch(setForecastFilterStatus("reset_forecast"));
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      startTargetDataSequence(
        location.pathname,
        raw_data_array,
        selected_data_config_data_type
      )
    );
  }, [dispatch, location, raw_data_array, selected_data_config_data_type]);

  // GA計測タグ
  useEffect(() => {
    document.title = page_title; // ページタイトルを変更
    window.gtag("config", "G-YXXD65BEJ0", {
      page_path: pathName // 任意のパス（あるいはprops.location.pathnameなど）
    });
  });

  return (
    <Container>
      <GlobalNav />

      <Main>
        <PageHeader page_title="重要指標" />

        <GlobalCard>
          <SubMenuWrapper
            className={expanderActivated ? "isExpanderActivated" : ""}
          >
            <MetricsSubMenu />
          </SubMenuWrapper>

          <RevenueContent
            className={expanderActivated ? "isExpanderActivated" : ""}
          >
            <ScrollContent>
              {/* フィルターリスト */}
              <DataFilters />

              {(activatedFilter === "" || activatedFilter === "期間比較") && (
                <ContentCard>
                  {/* 当月実績 */}
                  {target_data.length > 0 && activatedFilter === "" && (
                    <MetricsHeader
                      resultSpan={resultSpan}
                      target_data={target_data}
                      target_data_name={target_data_name}
                      key_total={key_total}
                      key_rate={key_rate}
                      key_difference={key_difference}
                      key_last_time_comparison_rate={
                        key_last_time_comparison_rate
                      }
                      key_last_time_difference={key_last_time_difference}
                      key_objective={key_objective}
                    />
                  )}

                  <CardBody>
                    {/* 期間比較フィルター */}
                    {activatedFilter === "期間比較" && (
                      <SpanComparisonFilter page_is="revenue" />
                    )}

                    {/* グラフ */}
                    <DataGraph result_data_type={target_data_name} />

                    {/* データテーブル */}
                    {activatedFilter === "" && (
                      <DraftTable
                        dataType="metrics"
                        prefix={target_data_name}
                        targetData={target_data}
                      />
                    )}
                    {activatedFilter === "期間比較" && (
                      <SpanComparisonTable resultSpan={resultSpan} />
                    )}
                    {/* {activatedFilter === "product" && (
                    <TableProductValiantFilter />
                  )} */}
                    {/* {activatedFilter !== "" &&
                    activatedFilter !== "期間比較" &&
                    activatedFilter !== "product" && <TableValiantFilter />} */}
                  </CardBody>
                </ContentCard>
              )}
              {activatedFilter === "product" && (
                <PlanComparisonGraphAndTable
                  target_data_name={target_data_name}
                  labels_name_array={labels_name_array}
                  filtered_data={filtered_data}
                  result_span={resultSpan}
                />
              )}

              {activatedFilter !== "" &&
                activatedFilter !== "期間比較" &&
                activatedFilter !== "product" && (
                  <VariantFilterGraphAndTable
                    target_data_name={target_data_name}
                    labels_name_array={labels_name_array}
                    filtered_data={filtered_data}
                    result_span={resultSpan}
                  />
                )}

              {target_data_name === "nrr" ? (
                <ContentCard>
                  <CardBody>
                    <CardTitleGraphDownloadWrapper>
                      <CardTitle>{page_title}:内訳</CardTitle>
                        <div onClick={(e) => {handleGraphDataDownloadClick(e, 'nrr')}}>
                          <GraphDataDownload />
                        </div>
                    </CardTitleGraphDownloadWrapper>
                    <br></br>
                    <StackedDetailChartNrr areaRef={barAreaRef} />
                  </CardBody>
                </ContentCard>
              ) : target_data_name === "grr" ? (
                <ContentCard>
                  <CardBody>
                    <CardTitleGraphDownloadWrapper>
                      <CardTitle>{page_title}:内訳</CardTitle>
                      <div onClick={(e) => {handleGraphDataDownloadClick(e, 'grr')}}>
                        <GraphDataDownload />
                      </div>
                    </CardTitleGraphDownloadWrapper>
                    <br></br>
                    <StackedDetailChartGrr areaRef={barAreaRef} />
                  </CardBody>
                </ContentCard>
              ) : target_data_name === "mrr_total_value" ? (
                <ContentCard>
                  <CardBody>
                    <CardTitleGraphDownloadWrapper>
                      <CardTitle>{page_title}:内訳</CardTitle>
                      <div onClick={(e) => {handleGraphDataDownloadClick(e, 'mrr_total_value')}}>
                        <GraphDataDownload />
                      </div>
                    </CardTitleGraphDownloadWrapper>
                    <br></br>
                    <StackedDetailChartMrr areaRef={barAreaRef} />
                  </CardBody>
                </ContentCard>
              ) : target_data_name === "revenue" ? (
                <ContentCard>
                  <CardBody>
                    <CardTitleGraphDownloadWrapper>
                      <CardTitle>MRR:内訳</CardTitle>
                      <div onClick={(e) => {handleGraphDataDownloadClick(e, 'mrr')}}>
                        <GraphDataDownload />
                      </div>
                    </CardTitleGraphDownloadWrapper>
                    <br></br>
                    <StackedDetailChartRevenueMrr areaRef={barAreaRef} />
                    <DraftRevenueMrrTable
                      dataType="metrics"
                      prefix={target_data_name}
                      targetData={target_data}
                    />
                  </CardBody>
                </ContentCard>
              ) : (
                ""
              )}
              {/* {activatedFilter === "" && target_data_name === "churn_rate" && (
                <>
                  <ContentCard>
                    <CohortHeader />
                    <CohortGraph data_name="churn_rate" />
                  </ContentCard>
                </>
              )} */}

              {activatedFilter !== "" && activatedFilter !== "期間比較" && (
                <ContentCard>
                  <CardBody>
                    <CardTitleGraphDownloadWrapper>
                      <CardTitle>{page_title}:プラン比較</CardTitle>
                      <div onClick={(e) => {handleGraphDataDownloadClick(e, 'valiant_pie')}}>
                        <GraphDataDownload />
                      </div>
                    </CardTitleGraphDownloadWrapper>
                    <ValiantPieChart valiantPieRef={valiantPieRef}></ValiantPieChart>
                  </CardBody>
                </ContentCard>
              )}
            </ScrollContent>
          </RevenueContent>
        </GlobalCard>
      </Main>
    </Container>
  );
};

export default Metrics;

// ==============================================================================
// Styles
// ==============================================================================
const Main = styled.main`
  overflow: hidden;
  flex-grow: 1;
  background-color: #f4f4f4;
  height: 100vh;
  overflow: scroll;
  gap: 30px 20px;
`;

const GlobalCard = styled.div`
  display: flex;
  padding-left: 24px;
  padding-right: 24px;
  justify-content: space-between;
`;

const SubMenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  &.isExpanderActivated {
    display: none;
  }
`;

// Container
const Container = styled.div`
  display: flex;
  min-width: 1200px;
`;
// RevenueContent
const RevenueContent = styled.div`
  width: calc(100% - 120px);
  height: 100%;
  padding: 15px 17px;
  box-sizing: border-box;
  &.isExpanderActivated {
    width: 100%;
    padding: 0;
  }
`;
const ScrollContent = styled.div``;
// ContentCard
const ContentCard = styled.div`
  margin-bottom: 16px;
  border: 1px solid #e1e8ea;
  box-sizing: border-box;
  background-color: #fff;
`;
// CardBody
const CardBody = styled.div`
  padding: 15px 17px;
  box-sizing: border-box;
  position: relative;
`;

// CardTitle
const CardTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #2a3385;
  margin: 0 0 12px 0;
`;

const CardTitleGraphDownloadWrapper = styled.div({
  flexGrow: 1,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});
