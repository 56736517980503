import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import GlobalNav from "../Layout/GlobalNav";
import error from "../../images/icons/error.svg";
import { Link } from "react-router-dom";
import { getRawDataThisMonthRequest } from "../../reducks/rawData/actions";

const ErrorPage = () => {
  const dispatch = useDispatch();
  const company_id = useSelector((state: any) => state.user.company_id);

  // ページ遷移時に初期状態のRawDataを取得する（当月の月次データ）
  useEffect(() => {
    dispatch(getRawDataThisMonthRequest(company_id, "monthly", "/", "", 1));
  }, [company_id, dispatch]);

  return (
    <Container>
      <GlobalNav />
      <Main>
        <ErrorContainer>
          <ErrorImage />
          <ErrorHeaderSC>何らかのエラーが発生しました</ErrorHeaderSC>
          <ErrorDescSC>
            システムに問題が発生いたしました。別のページをご覧ください。
            <br />
            問題が解決しない場合は、お客様窓口までお問い合わせください。
          </ErrorDescSC>
          <BackToTop>
            <Link to="/">Dashbordに戻る</Link>
          </BackToTop>
        </ErrorContainer>
      </Main>
    </Container>
  );
};

export default ErrorPage;

// ==============================================================================
// Styles
// ==============================================================================
const Main = styled.main`
  overflow: hidden;
  flex-grow: 1;
  background-color: #f4f4f4;
  height: 100vh;
  overflow: scroll;
  border-top: 8px solid #0050c9;
`;

// Container
const Container = styled.div`
  display: flex;
  min-width: 1200px;
`;

const ErrorContainer = styled.div`
  color: #35455d;
  margin: 0 auto;
  padding: 30px;
`;

const ErrorImage = styled.div`
  height: 100px;
  width: 100px;
  background-image: url(${error});
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 auto;
`;

const ErrorHeaderSC = styled.h1`
  font-size: 24px;
  text-align: center;
  padding: 24px 0px;
`;

const ErrorDescSC = styled.p`
  font-size: 18px;
  line-height: 36px;
  text-align: center;
  margin-bottom: 24px;
`;

const BackToTop = styled.div`
  color: #0066ff;
  text-align: center;
  &:hover {
    opacity: 0.6;
  }
`;
