import { UPDATE_DOWNLOAD_GRAPH_STATUS, UPDATE_DOWNLOAD_CSV_TARGET, UPDATE_DOWNLOAD_TAB_NAME } from "./types";

export const updateDownloadGraphStatus = (downloadGraphStatus: number) => ({
  type: UPDATE_DOWNLOAD_GRAPH_STATUS,
  downloadGraphStatus
});

export const updateDownloadCsvTarget = (updateDownloadCsvTarget: string) => ({
  type: UPDATE_DOWNLOAD_CSV_TARGET,
  updateDownloadCsvTarget
});

export const updateDownloadTabName = (updateDownloadTabName: string) => ({
  type: UPDATE_DOWNLOAD_TAB_NAME,
  updateDownloadTabName
});
