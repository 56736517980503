import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface Props {
  contentName: string;
}

const pathList = [
  {
    contentName: "ConversionRate",
    nameToShow: "有料転換率",
    path: "/metrics/conversion_rate"
  },
  { contentName: "arpu", nameToShow: "ARPU", path: "/metrics/arpu" },
  { contentName: "cac", nameToShow: "CAC", path: "/metrics/cac" },

  {
    contentName: "churnRate",
    nameToShow: "Churn Rate",
    path: "/metrics/churn-rate"
  },
  {
    contentName: "mrrChurnRate",
    nameToShow: "MRR Churn Rate",
    path: "/metrics/mrr-churn-rate"
  },

  { contentName: "LTV", nameToShow: "LTV", path: "/metrics/ltv" },
  {
    contentName: "PaybackPeriod",
    nameToShow: "PaybackPeriod",
    path: "/metrics/payback-period"
  },
  {
    contentName: "UnitEconomics",
    nameToShow: "UnitEconomics",
    path: "/metrics/unit-economics"
  },
  { contentName: "Revenue", nameToShow: "Revenue", path: "/metrics/revenue" },
  { contentName: "MRR", nameToShow: "MRR", path: "/metrics/mrr" },
  { contentName: "NRR", nameToShow: "NRR", path: "/metrics/nrr" },
  { contentName: "GRR", nameToShow: "GRR", path: "/metrics/grr" },
  {
    contentName: "QuickRatio",
    nameToShow: "QuickRatio",
    path: "/metrics/quick-ratio"
  },
  {
    contentName: "ACVCumulative",
    nameToShow: "ACV（累計）",
    path: "/metrics/acv-cumulative"
  },
  {
    contentName: "ACVNew",
    nameToShow: "ACV（新規）",
    path: "/metrics/acv-new"
  },
  {
    contentName: "funnelLead",
    nameToShow: "リード獲得",
    path: "/funnels/リード獲得"
  },
  {
    contentName: "funnelAppointment",
    nameToShow: "アポ獲得",
    path: "/funnels/アポ獲得"
  },
  {
    contentName: "funnelOpportunity",
    nameToShow: "商談化",
    path: "/funnels/商談化"
  },
  { contentName: "funnelClosedDeal", nameToShow: "受注", path: "/funnels/受注" }
];

const TopGraphLabel = ({ contentName }: Props) => {
  const currentContent = pathList.find(
    value => value.contentName === contentName
  );
  return (
    <TopGraphLabelSC>
      {currentContent !== undefined ? (
        <Link to={currentContent.path}>{currentContent.nameToShow}</Link>
      ) : (
        "データが見つかりません"
      )}
    </TopGraphLabelSC>
  );
};

export default TopGraphLabel;

// GraphLabel
const TopGraphLabelSC = styled.p`
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  color: #0066ff;
  padding: 6px 18px 2px 0;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 30px;
  margin-top: 28px;
  a {
    display: inline-block;
    width: 100%;
    height: 85px;
    position: absolute;
    top: 10px;
    left: 0;
  }
  &:hover {
    opacity: 0.7;
  }
`;
