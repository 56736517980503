import React, { useCallback, useRef } from "react";
import FileSaver from "file-saver";
import {
  updateDownloadGraphStatus,
} from "../../../reducks/download/actions";
import {
  updateDownloadCsvTarget,
} from "../../../reducks/download/actions";
import { useCurrentPng } from "recharts-to-png";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from "recharts";
import { exportComponentAsPNG } from 'react-component-export-image';
import { yAxisFormatter } from "../../Util/yAxisFormatter";
import { unitSwitcher } from "../../Util/unitSwitcher";
import GraphDataDownload from "../../Parts/GraphDataDownload";
interface Props {
  funnel_summary_array: any;
  resultSpan: string;
  moving_average_graph_switch: any;
}

const customTooltip = ({ active, payload, label }: any) => {
  console.log("active", active);
  console.log("payload", payload);
  console.log("label", label);
  if (active && payload && payload.length) {
    return (
      <BarGraphTooltip>
        <BarGraphTooltipLabel>Channel</BarGraphTooltipLabel>
        <BarGraphTooltipData>
          {payload[0].payload.channel_name}
        </BarGraphTooltipData>
        <BarGraphTooltipHR />
        <BarGraphTooltipLabel>リード件数</BarGraphTooltipLabel>
        <BarGraphTooltipData>
          {`${unitSwitcher(
            payload[0].payload.lead_new_count_total,
            "lead_count"
          )}`}
        </BarGraphTooltipData>
      </BarGraphTooltip>
    );
  }

  return null;
};

export interface TransitionRateLeadTime {
  funnel_name: string;
  lead_transition_rate_total: number;
  lead_transition_rate_objective: number;
  lead_transition_rate_rate: number;
  lead_transition_rate_difference: number;
  lead_transition_rate_last_year_comparison_rate: number;
  lead_time_total: number;
}

interface LeadNewCountTotalWithChannelName {
  lead_new_count_total: number;
  channel_name: string;
}

interface ColorItem {
  id: number;
  color_code: string;
}

export interface FunnelSummaryGraphTransitionRateLeadTimeProps {
  transition_rate_lead_time_array: TransitionRateLeadTime[];
  sum_lead_new_count_total_array_lead_array: LeadNewCountTotalWithChannelName[];
  sum_lead_new_count_total_array_appointment_array: LeadNewCountTotalWithChannelName[];
  sum_lead_new_count_total_array_opportunity_array: LeadNewCountTotalWithChannelName[];
  sum_lead_new_count_total_array_contract_array: LeadNewCountTotalWithChannelName[];
  graph_color_array: ColorItem[];
}
const FunnelSummaryGraphTransitionRateLeadTime: React.FC<FunnelSummaryGraphTransitionRateLeadTimeProps> =
  ({
    transition_rate_lead_time_array,
    sum_lead_new_count_total_array_lead_array,
    sum_lead_new_count_total_array_appointment_array,
    sum_lead_new_count_total_array_opportunity_array,
    sum_lead_new_count_total_array_contract_array,
    graph_color_array
  }) => {
    const dispatch = useDispatch();
    const componentRef = useRef<HTMLDivElement>(null);
    console.log(
      "sssssum_lead_new_count_total_array_lead_array",
      sum_lead_new_count_total_array_lead_array
    );

    const percentLabel = (props: any) => `${(props.percent * 100).toFixed(0)}%`;

    const [targetFunnel, setTargetFunnel] = React.useState("funnel_lead");

    let targetGraphData: any = [];
    switch (targetFunnel) {
      case "funnel_lead":
        targetGraphData = sum_lead_new_count_total_array_lead_array.map(
          (channel_name, index) => {
            if (index < 7) {
              return {
                ...channel_name,
                channel_color: graph_color_array[index].color_code
              };
            } else {
              const calc_index = index % 7;
              return {
                ...channel_name,
                channel_color: graph_color_array[calc_index].color_code
              };
            }
          }
        );
        break;
      case "funnel_appointment":
        targetGraphData = sum_lead_new_count_total_array_appointment_array.map(
          (channel_name, index) => {
            if (index < 7) {
              return {
                ...channel_name,
                channel_color: graph_color_array[index].color_code
              };
            } else {
              const calc_index = index % 7;
              return {
                ...channel_name,
                channel_color: graph_color_array[calc_index].color_code
              };
            }
          }
        );
        break;
      case "funnel_opportunity":
        targetGraphData = sum_lead_new_count_total_array_opportunity_array.map(
          (channel_name, index) => {
            if (index < 7) {
              return {
                ...channel_name,
                channel_color: graph_color_array[index].color_code
              };
            } else {
              const calc_index = index % 7;
              return {
                ...channel_name,
                channel_color: graph_color_array[calc_index].color_code
              };
            }
          }
        );
        break;
      case "funnel_contract":
        targetGraphData = sum_lead_new_count_total_array_contract_array.map(
          (channel_name, index) => {
            if (index < 7) {
              return {
                ...channel_name,
                channel_color: graph_color_array[index].color_code
              };
            } else {
              const calc_index = index % 7;
              return {
                ...channel_name,
                channel_color: graph_color_array[calc_index].color_code
              };
            }
          }
        );
        break;
      default:
        targetGraphData = sum_lead_new_count_total_array_lead_array.map(
          (channel_name, index) => {
            if (index < 7) {
              return {
                ...channel_name,
                channel_color: graph_color_array[index].color_code
              };
            } else {
              const calc_index = index % 7;
              return {
                ...channel_name,
                channel_color: graph_color_array[calc_index].color_code
              };
            }
          }
        );
    }

    // Graph Download Section
    const handleAreaDownload = useCallback(async () => {
      dispatch(
        updateDownloadGraphStatus(0)
      )
      exportComponentAsPNG(componentRef)
    }, [dispatch]);
    function handleGraphDataDownloadClick(e:any, target:string) {
      if(e.target.innerText==='グラフ Export'){
          handleAreaDownload();
      }
      dispatch(
        updateDownloadCsvTarget("/"+target)
      )
    }

    return (
      <div>
        <FunnelSummaryGraphTransitionRateLeadTimeHeadingGraphDownloadWrapper>
          <FunnelSummaryGraphTransitionRateLeadTimeHeading>
            Channel - 内訳
          </FunnelSummaryGraphTransitionRateLeadTimeHeading>
          <div onClick={(e) => {handleGraphDataDownloadClick(e, 'breakdown/'+targetFunnel)}}>
            <GraphDataDownload />
          </div>
        </FunnelSummaryGraphTransitionRateLeadTimeHeadingGraphDownloadWrapper>
        {transition_rate_lead_time_array.length > 0 ? (
          <FunnelSummaryGraph>
            <TargetFunnelSwitchList>
              <TargetFunnelSwitchItem>
                <TargetFunnelSwitchButton
                  className={targetFunnel === "funnel_lead" ? "active" : ""}
                  onClick={() => {
                    setTargetFunnel("funnel_lead");
                  }}
                >
                  リード獲得
                </TargetFunnelSwitchButton>
              </TargetFunnelSwitchItem>
              <TargetFunnelSwitchItem>
                <TargetFunnelSwitchButton
                  className={
                    targetFunnel === "funnel_appointment" ? "active" : ""
                  }
                  onClick={() => {
                    setTargetFunnel("funnel_appointment");
                  }}
                >
                  アポ獲得
                </TargetFunnelSwitchButton>
              </TargetFunnelSwitchItem>
              <TargetFunnelSwitchItem>
                <TargetFunnelSwitchButton
                  className={
                    targetFunnel === "funnel_opportunity" ? "active" : ""
                  }
                  onClick={() => {
                    setTargetFunnel("funnel_opportunity");
                  }}
                >
                  商談化
                </TargetFunnelSwitchButton>
              </TargetFunnelSwitchItem>
              <TargetFunnelSwitchItem>
                <TargetFunnelSwitchButton
                  className={targetFunnel === "funnel_contract" ? "active" : ""}
                  onClick={() => {
                    setTargetFunnel("funnel_contract");
                  }}
                >
                  受注
                </TargetFunnelSwitchButton>
              </TargetFunnelSwitchItem>
            </TargetFunnelSwitchList>
            <PieChartContentContainer ref={componentRef}>
              <PieChartContentGraph>
                <ResponsiveContainer>
                  <PieChart>
                    <Tooltip content={customTooltip} />
                    <Pie
                      data={targetGraphData}
                      dataKey="lead_new_count_total"
                      cx={180} //横方向の位置
                      outerRadius={120} //グラフの外半径
                      innerRadius={40} //グラフの内半径
                      startAngle={90} //値の表示グラフ表示描写開始位置の調整
                      endAngle={-270} //値の表示グラフ表示描写終了位置の調整
                      paddingAngle={3} //項目間の隙間調整
                      label={percentLabel}
                      labelLine={true}
                      fill="#435878"
                      isAnimationActive={false}
                    >
                      {targetGraphData.map((entry: any, index: any) => (
                        <Cell key={index} fill={entry.channel_color} />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </PieChartContentGraph>

              <PieChartContentNameArray>
                {targetGraphData.map((entry: any, i: any) => {
                  return (
                    <li
                      key={i}
                      style={{ marginBottom: "8px", position: "relative" }}
                    >
                      <NameSelect>
                        <span
                          style={{
                            color: `${entry.channel_color}`,
                            paddingRight: "8px"
                          }}
                        >
                          ●
                        </span>
                        {entry.channel_name}
                      </NameSelect>
                    </li>
                  );
                })}
              </PieChartContentNameArray>
            </PieChartContentContainer>
          </FunnelSummaryGraph>
        ) : (
          <NoData>グラフを表示できません</NoData>
        )}
      </div>
    );
  };
export default FunnelSummaryGraphTransitionRateLeadTime;

// Graph
const FunnelSummaryGraph = styled.div`
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;

  .recharts-surface {
    position: relative;
    top: -1px;
    overflow: visible;
  }
  .recharts-cartesian-axis-tick {
    width: 100px !important;
    .recharts-cartesian-axis-tick-value {
      font-size: 10px;
      color: #435878;
    }
  }
`;
const NoData = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #435878;
  margin-bottom: 8px;
  &.main {
    margin-bottom: 8px;
  }
`;

const BarGraphTooltip = styled.div`
  background: #fff;
  padding: 8px 9px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid #e2e9f3;
  border-radius: 4px;
  max-width: 200px;
`;

// 日付
const BarGraphTooltipHeading = styled.h3`
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  color: #435878;
  margin-bottom: 5px;
`;

// 当月 | 目標
const BarGraphTooltipLabel = styled.p`
  width: 50%;
  font-size: 10px;
  color: #435878;
  margin-bottom: 3px;
`;
// データ
const BarGraphTooltipData = styled.p`
  width: 50%;
  text-align: right;
  font-size: 10px;
  font-weight: bold;
  color: #435878;
  margin-bottom: 3px;
`;
// 仕切り線
const BarGraphTooltipHR = styled.hr`
  width: 100%;
  height: 1px;
  color: lightgray;
  margin-bottom: 3px;
`;

const FunnelSummaryGraphTransitionRateLeadTimeHeading = styled.h2`
  color: #283f46;
  font-weight: bold;
  font-size: 22px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const NameSelect = styled.div`
  width: 344px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #edf0f1;
  box-sizing: border-box;
  border-radius: 2px;
  color: #435878;
  font-size: 12px;
  padding: 8px;
  position: relative;
`;

const TargetFunnelSwitchList = styled.ul`
  display: flex;
`;

const TargetFunnelSwitchButton = styled.button`
  color: #6b6d82;
  padding: 6px 0;
  font-size: 12px;
  display: block;
  width: 100%;
  &.active {
    background-color: #0066ff;
    color: #fff;
    font-weight: 600;
  }
`;

const TargetFunnelSwitchItem = styled.li`
  border: 1px solid #edf0f1;
  border-right: none;
  text-align: center;
  width: 92px;

  &:first-of-type {
    border-radius: 4px 0 0 4px;
    ${TargetFunnelSwitchButton} {
      border-radius: 4px 0 0 4px;
    }
  }
  &:last-of-type {
    border-right: 1px solid #edf0f1;
    border-radius: 0 4px 4px 0;
    ${TargetFunnelSwitchButton} {
      border-radius: 0 4px 4px 0;
    }
  }
`;

const PieChartContentContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 100px 0;
`;

const PieChartContentGraph = styled.div`
  width: 400px;
  height: 308px;
  margin: -75px 0px;
`;
const PieChartContentNameArray = styled.ul`
  width: calc(100% - 400px);
  margin-left: 50px;
  position: relative;
  top: -50px;
`;

const FunnelSummaryGraphTransitionRateLeadTimeHeadingGraphDownloadWrapper = styled.div({
  flexGrow: 1,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});
