import {
  SET_TARGET_DATA_SUCCEEDED,
  SET_TARGET_DATA_FAILED,
  SET_DATA_KEYS,
  SELECT_SPAN,
  GET_TARGET_DATA_FOR_SPAN_COMPARISON_SUCCEEDED,
  GET_TARGET_DATA_FOR_SPAN_COMPARISON_FAILED,
  GET_TARGET_DATA_FOR_VARIANT_FILTER_SUCCEEDED,
  GET_TARGET_DATA_FOR_VARIANT_FILTER_FAILED,
  CLEAR_TARGET_DATA_FOR_SPAN_COMPARISON,
  LOGOUT_REQUESTED
} from "./types";
import { RESET_ALL } from "../common/types";

const initialState: any = {
  target_data: [],
  target_data_name: "",
  selected_span: "this_month",
  target_data_first_span: [],
  target_data_second_span: [],
  // 当月
  first_span_start: new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  ).getTime(),

  // 3ヶ月後
  first_span_end: new Date(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1).getMonth() +
        2
    )
  ).getTime(),

  // 昨年同月
  second_span_start: new Date(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1).getMonth() -
        12
    )
  ).getTime(),

  // 昨年同月から3ヶ月後
  second_span_end: new Date(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1).getMonth() -
        10
    )
  ).getTime(),
  filtered_data: [],
  labels_name_array: [],
  target_data_span_comparison: []
};

const targetDataReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_ALL:
      return initialState;

    case LOGOUT_REQUESTED:
      return {
        ...state,
        selected_span: "this_month"
      };
    case SET_TARGET_DATA_SUCCEEDED:
      if (action.resultSpan === "monthly") {
        return {
          ...state,
          resultSpan: "monthly",
          target_data: action.target_data
        };
      } else if (action.resultSpan === "weekly") {
        return {
          ...state,
          resultSpan: "weekly",
          target_data: action.target_data
        };
      } else {
        return {
          ...state,
          resultSpan: "daily",
          target_data: action.target_data
        };
      }

    case SET_TARGET_DATA_FAILED:
      return {
        ...state,
        error: action.message
      };

    case SELECT_SPAN:
      return {
        ...state,
        selected_span: action.selected_span
      };

    case SET_DATA_KEYS:
      let target_data_name: string;
      switch (action.location) {
        case "/metrics/revenue":
          target_data_name = "revenue";
          return {
            ...state,
            target_data_name: "revenue",
            target_data_monthly: "revenue_monthly",
            target_data_weekly: "revenue_weekly",
            target_data_daily: "revenue_daily",
            target_detail_data_name: "revenue_detail",
            target_detail_data_monthly: "revenue_detail_monthly",
            target_detail_data_weekly: "revenue_detail_weekly",
            target_detail_data_daily: "revenue_detail_daily",
            key_total: target_data_name + "_total",
            key_objective: target_data_name + "_objective",
            key_rate: target_data_name + "_rate",
            key_difference: target_data_name + "_difference",
            key_moving_average_total:
              target_data_name + "_moving_average_total",
            key_moving_average_objective:
              target_data_name + "_moving_average_objective",
            key_moving_average_rate: target_data_name + "_moving_average_rate",
            key_moving_average_last_year_comparison_rate:
              target_data_name + "_moving_average_last_year_comparison_rate",
            key_moving_average_difference:
              target_data_name + "_moving_average_difference",
            key_last_year_total: target_data_name + "_last_year_total",
            key_last_year_objective: target_data_name + "_last_year_objective",
            key_last_year_difference:
              target_data_name + "_last_year_difference",
            key_last_year_rate: target_data_name + "_last_year_rate",
            key_last_year_comparison_total:
              target_data_name + "_last_year_comparison_total",
            key_last_year_comparison_objective:
              target_data_name + "_last_year_comparison_objective",
            key_last_year_comparison_rate:
              target_data_name + "_last_year_comparison_rate",
            key_last_time_difference:
              target_data_name + "_last_time_difference",
            key_last_time_comparison_rate:
              target_data_name + "_last_time_comparison_rate"
          };

        case "/metrics/mrr":
          target_data_name = "mrr";
          return {
            ...state,
            target_data_name: "mrr_total_value",
            target_data_monthly: "mrr_monthly",
            target_data_weekly: "mrr_weekly",
            target_data_daily: "mrr_daily",
            target_detail_data_name: "mrr_detail",
            target_detail_data_monthly: "mrr_detail_monthly",
            target_detail_data_weekly: "mrr_detail_weekly",
            target_detail_data_daily: "mrr_detail_daily",
            key_total: target_data_name + "_total_value_total",
            key_objective: target_data_name + "_total_value_objective",
            key_rate: target_data_name + "_total_value_rate",
            key_difference: target_data_name + "_total_value_difference",
            key_moving_average_total:
              target_data_name + "_total_value_moving_average_total",
            key_moving_average_objective:
              target_data_name + "_total_value_moving_average_objective",
            key_moving_average_rate:
              target_data_name + "_total_value_moving_average_rate",
            key_moving_average_last_year_comparison_rate:
              target_data_name +
              "_total_value_moving_average_last_year_comparison_rate",
            key_moving_average_difference:
              target_data_name + "_total_value_moving_average_difference",
            key_last_year_total:
              target_data_name + "_total_value_last_year_total",
            key_last_year_objective:
              target_data_name + "_total_value_last_year_objective",
            key_last_year_difference:
              target_data_name + "_total_value_last_year_difference",
            key_last_year_rate:
              target_data_name + "_total_value_last_year_rate",
            key_last_year_comparison_total:
              target_data_name + "_total_value_last_year_comparison_total",
            key_last_year_comparison_objective:
              target_data_name + "_total_value_last_year_comparison_objective",
            key_last_year_comparison_rate:
              target_data_name + "_total_value_last_year_comparison_rate",
            key_last_time_difference:
              target_data_name + "_total_value_last_time_difference",
            key_last_time_comparison_rate:
              target_data_name + "_total_value_last_time_comparison_rate"
          };

        case "/metrics/unit-economics":
          target_data_name = "unit_economics";
          return {
            ...state,
            target_data_name: "unit_economics",
            target_data_monthly: "unit_economics_monthly",
            target_data_weekly: "unit_economics_weekly",
            target_data_daily: "unit_economics_daily",

            target_detail_data_name: "unit_economics_detail",
            target_detail_data_monthly: "unit_economics_detail_monthly",
            target_detail_data_weekly: "unit_economics_detail_weekly",
            target_detail_data_daily: "unit_economics_detail_daily",

            key_total: target_data_name + "_total",
            key_objective: target_data_name + "_objective",
            key_rate: target_data_name + "_rate",
            key_difference: target_data_name + "_difference",
            key_moving_average_total:
              target_data_name + "_moving_average_total",
            key_moving_average_objective:
              target_data_name + "_moving_average_objective",
            key_moving_average_rate: target_data_name + "_moving_average_rate",
            key_moving_average_last_year_comparison_rate:
              target_data_name + "_moving_average_last_year_comparison_rate",
            key_moving_average_difference:
              target_data_name + "_moving_average_difference",
            key_last_year_total: target_data_name + "_last_year_total",
            key_last_year_objective: target_data_name + "_last_year_objective",
            key_last_year_difference:
              target_data_name + "_last_year_difference",
            key_last_year_rate: target_data_name + "_last_year_rate",
            key_last_year_comparison_total:
              target_data_name + "_last_year_comparison_total",
            key_last_year_comparison_objective:
              target_data_name + "_last_year_comparison_objective",
            key_last_year_comparison_rate:
              target_data_name + "_last_year_comparison_rate",
            key_last_time_difference:
              target_data_name + "_last_time_difference",
            key_last_time_comparison_rate:
              target_data_name + "_last_time_comparison_rate"
          };
        case "/metrics/cac":
          target_data_name = "cac";
          return {
            ...state,
            target_data_name: "cac",
            target_data_monthly: "cac_monthly",
            target_data_weekly: "cac_weekly",
            target_data_daily: "cac_daily",

            target_detail_data_name: "cac_detail",
            target_detail_data_monthly: "cac_detail_monthly",
            target_detail_data_weekly: "cac_detail_weekly",
            target_detail_data_daily: "cac_detail_daily",

            key_total: target_data_name + "_total",
            key_objective: target_data_name + "_objective",
            key_rate: target_data_name + "_rate",
            key_difference: target_data_name + "_difference",
            key_moving_average_total:
              target_data_name + "_moving_average_total",
            key_moving_average_objective:
              target_data_name + "_moving_average_objective",
            key_moving_average_rate: target_data_name + "_moving_average_rate",
            key_moving_average_last_year_comparison_rate:
              target_data_name + "_moving_average_last_year_comparison_rate",
            key_moving_average_difference:
              target_data_name + "_moving_average_difference",
            key_last_year_total: target_data_name + "_last_year_total",
            key_last_year_objective: target_data_name + "_last_year_objective",
            key_last_year_difference:
              target_data_name + "_last_year_difference",
            key_last_year_rate: target_data_name + "_last_year_rate",
            key_last_year_comparison_total:
              target_data_name + "_last_year_comparison_total",
            key_last_year_comparison_objective:
              target_data_name + "_last_year_comparison_objective",
            key_last_year_comparison_rate:
              target_data_name + "_last_year_comparison_rate",
            key_last_time_difference:
              target_data_name + "_last_time_difference",
            key_last_time_comparison_rate:
              target_data_name + "_last_time_comparison_rate"
          };
        case "/metrics/payback-period":
          target_data_name = "payback_period";
          return {
            ...state,
            target_data_name: "payback_period",
            target_data_monthly: "payback_period_monthly",
            target_data_weekly: "payback_period_weekly",
            target_data_daily: "payback_period_daily",

            target_detail_data_name: "payback_period_detail",
            target_detail_data_monthly: "payback_period_detail_monthly",
            target_detail_data_weekly: "payback_period_detail_weekly",
            target_detail_data_daily: "payback_period_detail_daily",

            key_total: target_data_name + "_total",
            key_objective: target_data_name + "_objective",
            key_rate: target_data_name + "_rate",
            key_difference: target_data_name + "_difference",
            key_moving_average_total:
              target_data_name + "_moving_average_total",
            key_moving_average_objective:
              target_data_name + "_moving_average_objective",
            key_moving_average_rate: target_data_name + "_moving_average_rate",
            key_moving_average_last_year_comparison_rate:
              target_data_name + "_moving_average_last_year_comparison_rate",
            key_moving_average_difference:
              target_data_name + "_moving_average_difference",
            key_last_year_total: target_data_name + "_last_year_total",
            key_last_year_objective: target_data_name + "_last_year_objective",
            key_last_year_difference:
              target_data_name + "_last_year_difference",
            key_last_year_rate: target_data_name + "_last_year_rate",
            key_last_year_comparison_total:
              target_data_name + "_last_year_comparison_total",
            key_last_year_comparison_objective:
              target_data_name + "_last_year_comparison_objective",
            key_last_year_comparison_rate:
              target_data_name + "_last_year_comparison_rate",
            key_last_time_difference:
              target_data_name + "_last_time_difference",
            key_last_time_comparison_rate:
              target_data_name + "_last_time_comparison_rate"
          };
        case "/metrics/ltv":
          target_data_name = "ltv";
          return {
            ...state,
            target_data_name: "ltv",
            target_data_monthly: "ltv_monthly",
            target_data_weekly: "ltv_weekly",
            target_data_daily: "ltv_daily",

            target_detail_data_name: "ltv_detail",
            target_detail_data_monthly: "ltv_detail_monthly",
            target_detail_data_weekly: "ltv_detail_weekly",
            target_detail_data_daily: "ltv_detail_daily",

            key_total: target_data_name + "_total",
            key_objective: target_data_name + "_objective",
            key_rate: target_data_name + "_rate",
            key_difference: target_data_name + "_difference",
            key_moving_average_total:
              target_data_name + "_moving_average_total",
            key_moving_average_objective:
              target_data_name + "_moving_average_objective",
            key_moving_average_rate: target_data_name + "_moving_average_rate",
            key_moving_average_last_year_comparison_rate:
              target_data_name + "_moving_average_last_year_comparison_rate",
            key_moving_average_difference:
              target_data_name + "_moving_average_difference",
            key_last_year_total: target_data_name + "_last_year_total",
            key_last_year_objective: target_data_name + "_last_year_objective",
            key_last_year_difference:
              target_data_name + "_last_year_difference",
            key_last_year_rate: target_data_name + "_last_year_rate",
            key_last_year_comparison_total:
              target_data_name + "_last_year_comparison_total",
            key_last_year_comparison_objective:
              target_data_name + "_last_year_comparison_objective",
            key_last_year_comparison_rate:
              target_data_name + "_last_year_comparison_rate",
            key_last_time_difference:
              target_data_name + "_last_time_difference",
            key_last_time_comparison_rate:
              target_data_name + "_last_time_comparison_rate"
          };
        case "/metrics/arpu":
          target_data_name = "arpu";
          return {
            ...state,
            target_data_name: "arpu",
            target_data_monthly: "arpu_monthly",
            target_data_weekly: "arpu_weekly",
            target_data_daily: "arpu_daily",

            target_detail_data_name: "arpu_detail",
            target_detail_data_monthly: "arpu_detail_monthly",
            target_detail_data_weekly: "arpu_detail_weekly",
            target_detail_data_daily: "arpu_detail_daily",

            key_total: target_data_name + "_total",
            key_objective: target_data_name + "_objective",
            key_rate: target_data_name + "_rate",
            key_difference: target_data_name + "_difference",
            key_moving_average_total:
              target_data_name + "_moving_average_total",
            key_moving_average_objective:
              target_data_name + "_moving_average_objective",
            key_moving_average_rate: target_data_name + "_moving_average_rate",
            key_moving_average_last_year_comparison_rate:
              target_data_name + "_moving_average_last_year_comparison_rate",
            key_moving_average_difference:
              target_data_name + "_moving_average_difference",
            key_last_year_total: target_data_name + "_last_year_total",
            key_last_year_objective: target_data_name + "_last_year_objective",
            key_last_year_difference:
              target_data_name + "_last_year_difference",
            key_last_year_rate: target_data_name + "_last_year_rate",
            key_last_year_comparison_total:
              target_data_name + "_last_year_comparison_total",
            key_last_year_comparison_objective:
              target_data_name + "_last_year_comparison_objective",
            key_last_year_comparison_rate:
              target_data_name + "_last_year_comparison_rate",
            key_last_time_difference:
              target_data_name + "_last_time_difference",
            key_last_time_comparison_rate:
              target_data_name + "_last_time_comparison_rate"
          };
        case "/metrics/churn-rate":
          target_data_name = "churn_rate";
          return {
            ...state,
            target_data_name: "churn_rate",
            target_data_monthly: "churn_rate_monthly",
            target_data_weekly: "churn_rate_weekly",
            target_data_daily: "churn_rate_daily",

            target_detail_data_name: "churn_rate_detail",
            target_detail_data_monthly: "churn_rate_detail_monthly",
            target_detail_data_weekly: "churn_rate_detail_weekly",
            target_detail_data_daily: "churn_rate_detail_daily",

            key_total: target_data_name + "_total",
            key_objective: target_data_name + "_objective",
            key_rate: target_data_name + "_rate",
            key_difference: target_data_name + "_difference",
            key_moving_average_total:
              target_data_name + "_moving_average_total",
            key_moving_average_objective:
              target_data_name + "_moving_average_objective",
            key_moving_average_rate: target_data_name + "_moving_average_rate",
            key_moving_average_last_year_comparison_rate:
              target_data_name + "_moving_average_last_year_comparison_rate",
            key_moving_average_difference:
              target_data_name + "_moving_average_difference",
            key_last_year_total: target_data_name + "_last_year_total",
            key_last_year_objective: target_data_name + "_last_year_objective",
            key_last_year_difference:
              target_data_name + "_last_year_difference",
            key_last_year_rate: target_data_name + "_last_year_rate",
            key_last_year_comparison_total:
              target_data_name + "_last_year_comparison_total",
            key_last_year_comparison_objective:
              target_data_name + "_last_year_comparison_objective",
            key_last_year_comparison_rate:
              target_data_name + "_last_year_comparison_rate",
            key_last_time_difference:
              target_data_name + "_last_time_difference",
            key_last_time_comparison_rate:
              target_data_name + "_last_time_comparison_rate"
          };
        case "/metrics/mrr-churn-rate":
          target_data_name = "mrr_churn_rate";
          return {
            ...state,
            target_data_name: "mrr_churn_rate",
            target_data_monthly: "mrr_churn_rate_monthly",
            target_data_weekly: "mrr_churn_rate_weekly",
            target_data_daily: "mrr_churn_rate_daily",

            target_detail_data_name: "mrr_churn_rate_detail",
            target_detail_data_monthly: "mrr_churn_rate_detail_monthly",
            target_detail_data_weekly: "mrr_churn_rate_detail_weekly",
            target_detail_data_daily: "mrr_churn_rate_detail_daily",

            key_total: target_data_name + "_total",
            key_objective: target_data_name + "_objective",
            key_rate: target_data_name + "_rate",
            key_difference: target_data_name + "_difference",
            key_moving_average_total:
              target_data_name + "_moving_average_total",
            key_moving_average_objective:
              target_data_name + "_moving_average_objective",
            key_moving_average_rate: target_data_name + "_moving_average_rate",
            key_moving_average_last_year_comparison_rate:
              target_data_name + "_moving_average_last_year_comparison_rate",
            key_moving_average_difference:
              target_data_name + "_moving_average_difference",
            key_last_year_total: target_data_name + "_last_year_total",
            key_last_year_objective: target_data_name + "_last_year_objective",
            key_last_year_difference:
              target_data_name + "_last_year_difference",
            key_last_year_rate: target_data_name + "_last_year_rate",
            key_last_year_comparison_total:
              target_data_name + "_last_year_comparison_total",
            key_last_year_comparison_objective:
              target_data_name + "_last_year_comparison_objective",
            key_last_year_comparison_rate:
              target_data_name + "_last_year_comparison_rate",
            key_last_time_difference:
              target_data_name + "_last_time_difference",
            key_last_time_comparison_rate:
              target_data_name + "_last_time_comparison_rate"
          };
        case "/metrics/nrr":
          target_data_name = "nrr";
          return {
            ...state,
            target_data_name: "nrr",
            target_data_monthly: "nrr_monthly",
            target_data_weekly: "nrr_weekly",
            target_data_daily: "nrr_daily",

            target_detail_data_name: "nrr_detail",
            target_detail_data_monthly: "nrr_detail_monthly",
            target_detail_data_weekly: "nrr_detail_weekly",
            target_detail_data_daily: "nrr_detail_daily",

            key_total: target_data_name + "_total",
            key_objective: target_data_name + "_objective",
            key_rate: target_data_name + "_rate",
            key_difference: target_data_name + "_difference",
            key_moving_average_total:
              target_data_name + "_moving_average_total",
            key_moving_average_objective:
              target_data_name + "_moving_average_objective",
            key_moving_average_rate: target_data_name + "_moving_average_rate",
            key_moving_average_last_year_comparison_rate:
              target_data_name + "_moving_average_last_year_comparison_rate",
            key_moving_average_difference:
              target_data_name + "_moving_average_difference",
            key_last_year_total: target_data_name + "_last_year_total",
            key_last_year_objective: target_data_name + "_last_year_objective",
            key_last_year_difference:
              target_data_name + "_last_year_difference",
            key_last_year_rate: target_data_name + "_last_year_rate",
            key_last_year_comparison_total:
              target_data_name + "_last_year_comparison_total",
            key_last_year_comparison_objective:
              target_data_name + "_last_year_comparison_objective",
            key_last_year_comparison_rate:
              target_data_name + "_last_year_comparison_rate",
            key_last_time_difference:
              target_data_name + "_last_time_difference",
            key_last_time_comparison_rate:
              target_data_name + "_last_time_comparison_rate"
          };
        case "/metrics/grr":
          target_data_name = "grr";
          return {
            ...state,
            target_data_name: "grr",
            target_data_monthly: "grr_monthly",
            target_data_weekly: "grr_weekly",
            target_data_daily: "grr_daily",

            target_detail_data_name: "grr_detail",
            target_detail_data_monthly: "grr_detail_monthly",
            target_detail_data_weekly: "grr_detail_weekly",
            target_detail_data_daily: "grr_detail_daily",

            key_total: target_data_name + "_total",
            key_objective: target_data_name + "_objective",
            key_rate: target_data_name + "_rate",
            key_difference: target_data_name + "_difference",
            key_moving_average_total:
              target_data_name + "_moving_average_total",
            key_moving_average_objective:
              target_data_name + "_moving_average_objective",
            key_moving_average_rate: target_data_name + "_moving_average_rate",
            key_moving_average_last_year_comparison_rate:
              target_data_name + "_moving_average_last_year_comparison_rate",
            key_moving_average_difference:
              target_data_name + "_moving_average_difference",
            key_last_year_total: target_data_name + "_last_year_total",
            key_last_year_objective: target_data_name + "_last_year_objective",
            key_last_year_difference:
              target_data_name + "_last_year_difference",
            key_last_year_rate: target_data_name + "_last_year_rate",
            key_last_year_comparison_total:
              target_data_name + "_last_year_comparison_total",
            key_last_year_comparison_objective:
              target_data_name + "_last_year_comparison_objective",
            key_last_year_comparison_rate:
              target_data_name + "_last_year_comparison_rate",
            key_last_time_difference:
              target_data_name + "_last_time_difference",
            key_last_time_comparison_rate:
              target_data_name + "_last_time_comparison_rate"
          };
        case "/metrics/quick-ratio":
          target_data_name = "quick_ratio";
          return {
            ...state,
            target_data_name: "quick_ratio",
            target_data_monthly: "quick_ratio_monthly",
            target_data_weekly: "quick_ratio_weekly",
            target_data_daily: "quick_ratio_daily",

            target_detail_data_name: "quick_ratio_detail",
            target_detail_data_monthly: "quick_ratio_detail_monthly",
            target_detail_data_weekly: "quick_ratio_detail_weekly",
            target_detail_data_daily: "quick_ratio_detail_daily",

            key_total: target_data_name + "_total",
            key_objective: target_data_name + "_objective",
            key_rate: target_data_name + "_rate",
            key_difference: target_data_name + "_difference",
            key_moving_average_total:
              target_data_name + "_moving_average_total",
            key_moving_average_objective:
              target_data_name + "_moving_average_objective",
            key_moving_average_rate: target_data_name + "_moving_average_rate",
            key_moving_average_last_year_comparison_rate:
              target_data_name + "_moving_average_last_year_comparison_rate",
            key_moving_average_difference:
              target_data_name + "_moving_average_difference",
            key_last_year_total: target_data_name + "_last_year_total",
            key_last_year_objective: target_data_name + "_last_year_objective",
            key_last_year_difference:
              target_data_name + "_last_year_difference",
            key_last_year_rate: target_data_name + "_last_year_rate",
            key_last_year_comparison_total:
              target_data_name + "_last_year_comparison_total",
            key_last_year_comparison_objective:
              target_data_name + "_last_year_comparison_objective",
            key_last_year_comparison_rate:
              target_data_name + "_last_year_comparison_rate",
            key_last_time_difference:
              target_data_name + "_last_time_difference",
            key_last_time_comparison_rate:
              target_data_name + "_last_time_comparison_rate"
          };
        case "/metrics/acv-cumulative":
          target_data_name = "acv_cumulative";
          return {
            ...state,
            target_data_name: "acv_cumulative",
            target_data_monthly: "acv_cumulative_monthly",
            target_data_weekly: "acv_cumulative_weekly",
            target_data_daily: "acv_cumulative_daily",

            target_detail_data_name: "acv_cumulative_detail",
            target_detail_data_monthly: "acv_cumulative_detail_monthly",
            target_detail_data_weekly: "acv_cumulative_detail_weekly",
            target_detail_data_daily: "acv_cumulative_detail_daily",

            key_total: target_data_name + "_total",
            key_objective: target_data_name + "_objective",
            key_rate: target_data_name + "_rate",
            key_difference: target_data_name + "_difference",
            key_moving_average_total:
              target_data_name + "_moving_average_total",
            key_moving_average_objective:
              target_data_name + "_moving_average_objective",
            key_moving_average_rate: target_data_name + "_moving_average_rate",
            key_moving_average_last_year_comparison_rate:
              target_data_name + "_moving_average_last_year_comparison_rate",
            key_moving_average_difference:
              target_data_name + "_moving_average_difference",
            key_last_year_total: target_data_name + "_last_year_total",
            key_last_year_objective: target_data_name + "_last_year_objective",
            key_last_year_difference:
              target_data_name + "_last_year_difference",
            key_last_year_rate: target_data_name + "_last_year_rate",
            key_last_year_comparison_total:
              target_data_name + "_last_year_comparison_total",
            key_last_year_comparison_objective:
              target_data_name + "_last_year_comparison_objective",
            key_last_year_comparison_rate:
              target_data_name + "_last_year_comparison_rate",
            key_last_time_difference:
              target_data_name + "_last_time_difference",
            key_last_time_comparison_rate:
              target_data_name + "_last_time_comparison_rate"
          };
        case "/metrics/acv-new":
          target_data_name = "acv_new";
          return {
            ...state,
            target_data_name: "acv_new",
            target_data_monthly: "acv_new_monthly",
            target_data_weekly: "acv_new_weekly",
            target_data_daily: "acv_new_daily",

            target_detail_data_name: "acv_new_detail",
            target_detail_data_monthly: "acv_new_detail_monthly",
            target_detail_data_weekly: "acv_new_detail_weekly",
            target_detail_data_daily: "acv_new_detail_daily",

            key_total: target_data_name + "_total",
            key_objective: target_data_name + "_objective",
            key_rate: target_data_name + "_rate",
            key_difference: target_data_name + "_difference",
            key_moving_average_total:
              target_data_name + "_moving_average_total",
            key_moving_average_objective:
              target_data_name + "_moving_average_objective",
            key_moving_average_rate: target_data_name + "_moving_average_rate",
            key_moving_average_last_year_comparison_rate:
              target_data_name + "_moving_average_last_year_comparison_rate",
            key_moving_average_difference:
              target_data_name + "_moving_average_difference",
            key_last_year_total: target_data_name + "_last_year_total",
            key_last_year_objective: target_data_name + "_last_year_objective",
            key_last_year_difference:
              target_data_name + "_last_year_difference",
            key_last_year_rate: target_data_name + "_last_year_rate",
            key_last_year_comparison_total:
              target_data_name + "_last_year_comparison_total",
            key_last_year_comparison_objective:
              target_data_name + "_last_year_comparison_objective",
            key_last_year_comparison_rate:
              target_data_name + "_last_year_comparison_rate",
            key_last_time_difference:
              target_data_name + "_last_time_difference",
            key_last_time_comparison_rate:
              target_data_name + "_last_time_comparison_rate"
          };
        case "/metrics/conversion_rate":
          target_data_name = "conversion_rate";
          return {
            ...state,
            target_data_name: "conversion_rate",
            target_data_monthly: "conversion_rate_monthly",
            target_data_weekly: "conversion_rate_weekly",
            target_data_daily: "conversion_rate_daily",

            target_detail_data_name: "conversion_rate_detail",
            target_detail_data_monthly: "conversion_rate_detail_monthly",
            target_detail_data_weekly: "conversion_rate_detail_weekly",
            target_detail_data_daily: "conversion_rate_detail_daily",

            key_total: target_data_name + "_total",
            key_objective: target_data_name + "_objective",
            key_rate: target_data_name + "_rate",
            key_difference: target_data_name + "_difference",
            key_moving_average_total:
              target_data_name + "_moving_average_total",
            key_moving_average_objective:
              target_data_name + "_moving_average_objective",
            key_moving_average_rate: target_data_name + "_moving_average_rate",
            key_moving_average_last_year_comparison_rate:
              target_data_name + "_moving_average_last_year_comparison_rate",
            key_moving_average_difference:
              target_data_name + "_moving_average_difference",
            key_last_year_total: target_data_name + "_last_year_total",
            key_last_year_objective: target_data_name + "_last_year_objective",
            key_last_year_difference:
              target_data_name + "_last_year_difference",
            key_last_year_rate: target_data_name + "_last_year_rate",
            key_last_year_comparison_total:
              target_data_name + "_last_year_comparison_total",
            key_last_year_comparison_objective:
              target_data_name + "_last_year_comparison_objective",
            key_last_year_comparison_rate:
              target_data_name + "_last_year_comparison_rate",
            key_last_time_difference:
              target_data_name + "_last_time_difference",
            key_last_time_comparison_rate:
              target_data_name + "_last_time_comparison_rate"
          };
        case "/metrics/cpa":
          target_data_name = "cpa";
          return {
            ...state,
            target_data_name: "cpa",
            target_data_monthly: "cpa_monthly",
            target_data_weekly: "cpa_weekly",
            target_data_daily: "cpa_daily",

            target_detail_data_name: "cpa_detail",
            target_detail_data_monthly: "cpa_detail_monthly",
            target_detail_data_weekly: "cpa_detail_weekly",
            target_detail_data_daily: "cpa_detail_daily",

            key_total: target_data_name + "_total",
            key_objective: target_data_name + "_objective",
            key_rate: target_data_name + "_rate",
            key_difference: target_data_name + "_difference",
            key_moving_average_total:
              target_data_name + "_moving_average_total",
            key_moving_average_objective:
              target_data_name + "_moving_average_objective",
            key_moving_average_rate: target_data_name + "_moving_average_rate",
            key_moving_average_last_year_comparison_rate:
              target_data_name + "_moving_average_last_year_comparison_rate",
            key_moving_average_difference:
              target_data_name + "_moving_average_difference",
            key_last_year_total: target_data_name + "_last_year_total",
            key_last_year_objective: target_data_name + "_last_year_objective",
            key_last_year_difference:
              target_data_name + "_last_year_difference",
            key_last_year_rate: target_data_name + "_last_year_rate",
            key_last_year_comparison_total:
              target_data_name + "_last_year_comparison_total",
            key_last_year_comparison_objective:
              target_data_name + "_last_year_comparison_objective",
            key_last_year_comparison_rate:
              target_data_name + "_last_year_comparison_rate",
            key_last_time_difference:
              target_data_name + "_last_time_difference",
            key_last_time_comparison_rate:
              target_data_name + "_last_time_comparison_rate"
          };

        case "/funnels/client-churned":
          target_data_name = "client_churned";
          return {
            ...state,
            target_data_name: "client_churned",
            target_data_monthly: "client_churned_monthly",
            target_data_weekly: "client_churned_weekly",
            target_data_daily: "client_churned_daily",

            target_detail_data_name: "client_churned_detail",
            target_detail_data_monthly: "client_churned_detail_monthly",
            target_detail_data_weekly: "client_churned_detail_weekly",
            target_detail_data_daily: "client_churned_detail_daily",

            key_total: target_data_name + "_total",
            key_objective: target_data_name + "_objective",
            key_rate: target_data_name + "_rate",
            key_difference: target_data_name + "_difference",
            key_moving_average_total:
              target_data_name + "_moving_average_total",
            key_moving_average_objective:
              target_data_name + "_moving_average_objective",
            key_moving_average_rate: target_data_name + "_moving_average_rate",
            key_moving_average_last_year_comparison_rate:
              target_data_name + "_moving_average_last_year_comparison_rate",
            key_moving_average_difference:
              target_data_name + "_moving_average_difference",
            key_last_year_total: target_data_name + "_last_year_total",
            key_last_year_objective: target_data_name + "_last_year_objective",
            key_last_year_difference:
              target_data_name + "_last_year_difference",
            key_last_year_rate: target_data_name + "_last_year_rate",
            key_last_year_comparison_total:
              target_data_name + "_last_year_comparison_total",
            key_last_year_comparison_objective:
              target_data_name + "_last_year_comparison_objective",
            key_last_year_comparison_rate:
              target_data_name + "_last_year_comparison_rate",
            key_last_time_difference:
              target_data_name + "_last_time_difference",
            key_last_time_comparison_rate:
              target_data_name + "_last_time_comparison_rate"
          };

        case "/funnels/account-churned":
          target_data_name = "account_churned";
          return {
            ...state,
            target_data_name: "account_churned",
            target_data_monthly: "account_churned_monthly",
            target_data_weekly: "account_churned_weekly",
            target_data_daily: "account_churned_daily",

            target_detail_data_name: "account_churned_detail",
            target_detail_data_monthly: "account_churned_detail_monthly",
            target_detail_data_weekly: "account_churned_detail_weekly",
            target_detail_data_daily: "account_churned_detail_daily",

            key_total: target_data_name + "_total",
            key_objective: target_data_name + "_objective",
            key_rate: target_data_name + "_rate",
            key_difference: target_data_name + "_difference",
            key_moving_average_total:
              target_data_name + "_moving_average_total",
            key_moving_average_objective:
              target_data_name + "_moving_average_objective",
            key_moving_average_rate: target_data_name + "_moving_average_rate",
            key_moving_average_last_year_comparison_rate:
              target_data_name + "_moving_average_last_year_comparison_rate",
            key_moving_average_difference:
              target_data_name + "_moving_average_difference",
            key_last_year_total: target_data_name + "_last_year_total",
            key_last_year_objective: target_data_name + "_last_year_objective",
            key_last_year_difference:
              target_data_name + "_last_year_difference",
            key_last_year_rate: target_data_name + "_last_year_rate",
            key_last_year_comparison_total:
              target_data_name + "_last_year_comparison_total",
            key_last_year_comparison_objective:
              target_data_name + "_last_year_comparison_objective",
            key_last_year_comparison_rate:
              target_data_name + "_last_year_comparison_rate",
            key_last_time_difference:
              target_data_name + "_last_time_difference",
            key_last_time_comparison_rate:
              target_data_name + "_last_time_comparison_rate"
          };

        case "/funnels/revenue-churned":
          target_data_name = "revenue_churned";
          return {
            ...state,
            target_data_name: "revenue_churned",
            target_data_monthly: "revenue_churned_monthly",
            target_data_weekly: "revenue_churned_weekly",
            target_data_daily: "revenue_churned_daily",

            target_detail_data_name: "revenue_churned_detail",
            target_detail_data_monthly: "revenue_churned_detail_monthly",
            target_detail_data_weekly: "revenue_churned_detail_weekly",
            target_detail_data_daily: "revenue_churned_detail_daily",

            key_total: target_data_name + "_total",
            key_objective: target_data_name + "_objective",
            key_rate: target_data_name + "_rate",
            key_difference: target_data_name + "_difference",
            key_moving_average_total:
              target_data_name + "_moving_average_total",
            key_moving_average_objective:
              target_data_name + "_moving_average_objective",
            key_moving_average_rate: target_data_name + "_moving_average_rate",
            key_moving_average_last_year_comparison_rate:
              target_data_name + "_moving_average_last_year_comparison_rate",
            key_moving_average_difference:
              target_data_name + "_moving_average_difference",
            key_last_year_total: target_data_name + "_last_year_total",
            key_last_year_objective: target_data_name + "_last_year_objective",
            key_last_year_difference:
              target_data_name + "_last_year_difference",
            key_last_year_rate: target_data_name + "_last_year_rate",
            key_last_year_comparison_total:
              target_data_name + "_last_year_comparison_total",
            key_last_year_comparison_objective:
              target_data_name + "_last_year_comparison_objective",
            key_last_year_comparison_rate:
              target_data_name + "_last_year_comparison_rate",
            key_last_time_difference:
              target_data_name + "_last_time_difference",
            key_last_time_comparison_rate:
              target_data_name + "_last_time_comparison_rate"
          };

        case "/funnels/new-customer":
          target_data_name = "new_customer";
          return {
            ...state,
            target_data_name: "new_customer",
            target_data_monthly: "new_customer_monthly",
            target_data_weekly: "new_customer_weekly",
            target_data_daily: "new_customer_daily",

            target_detail_data_name: "new_customer_detail",
            target_detail_data_monthly: "new_customer_detail_monthly",
            target_detail_data_weekly: "new_customer_detail_weekly",
            target_detail_data_daily: "new_customer_detail_daily",

            key_total: target_data_name + "_total",
            key_objective: target_data_name + "_objective",
            key_rate: target_data_name + "_rate",
            key_difference: target_data_name + "_difference",
            key_moving_average_total:
              target_data_name + "_moving_average_total",
            key_moving_average_objective:
              target_data_name + "_moving_average_objective",
            key_moving_average_rate: target_data_name + "_moving_average_rate",
            key_moving_average_last_year_comparison_rate:
              target_data_name + "_moving_average_last_year_comparison_rate",
            key_moving_average_difference:
              target_data_name + "_moving_average_difference",
            key_last_year_total: target_data_name + "_last_year_total",
            key_last_year_objective: target_data_name + "_last_year_objective",
            key_last_year_difference:
              target_data_name + "_last_year_difference",
            key_last_year_rate: target_data_name + "_last_year_rate",
            key_last_year_comparison_total:
              target_data_name + "_last_year_comparison_total",
            key_last_year_comparison_objective:
              target_data_name + "_last_year_comparison_objective",
            key_last_year_comparison_rate:
              target_data_name + "_last_year_comparison_rate",
            key_last_time_difference:
              target_data_name + "_last_time_difference",
            key_last_time_comparison_rate:
              target_data_name + "_last_time_comparison_rate"
          };

        case "/funnels/active-customer":
          target_data_name = "active_customer";
          return {
            ...state,
            target_data_name: "active_customer",
            target_data_monthly: "active_customer_monthly",
            target_data_weekly: "active_customer_weekly",
            target_data_daily: "active_customer_daily",

            target_detail_data_name: "active_customer_detail",
            target_detail_data_monthly: "active_customer_detail_monthly",
            target_detail_data_weekly: "active_customer_detail_weekly",
            target_detail_data_daily: "active_customer_detail_daily",

            key_total: target_data_name + "_total",
            key_objective: target_data_name + "_objective",
            key_rate: target_data_name + "_rate",
            key_difference: target_data_name + "_difference",
            key_moving_average_total:
              target_data_name + "_moving_average_total",
            key_moving_average_objective:
              target_data_name + "_moving_average_objective",
            key_moving_average_rate: target_data_name + "_moving_average_rate",
            key_moving_average_last_year_comparison_rate:
              target_data_name + "_moving_average_last_year_comparison_rate",
            key_moving_average_difference:
              target_data_name + "_moving_average_difference",
            key_last_year_total: target_data_name + "_last_year_total",
            key_last_year_objective: target_data_name + "_last_year_objective",
            key_last_year_difference:
              target_data_name + "_last_year_difference",
            key_last_year_rate: target_data_name + "_last_year_rate",
            key_last_year_comparison_total:
              target_data_name + "_last_year_comparison_total",
            key_last_year_comparison_objective:
              target_data_name + "_last_year_comparison_objective",
            key_last_year_comparison_rate:
              target_data_name + "_last_year_comparison_rate",
            key_last_time_difference:
              target_data_name + "_last_time_difference",
            key_last_time_comparison_rate:
              target_data_name + "_last_time_comparison_rate"
          };

        case "/forecasts":
          target_data_name = "revenue";
          return {
            ...state,
            target_data_name: "revenue",
            target_data_monthly: "revenue_monthly",
            target_data_weekly: "revenue_weekly",
            target_data_daily: "revenue_daily",
            target_detail_data_name: "revenue_detail",
            target_detail_data_monthly: "revenue_detail_monthly",
            target_detail_data_weekly: "revenue_detail_weekly",
            target_detail_data_daily: "revenue_detail_daily",
            key_total: target_data_name + "_total",
            key_objective: target_data_name + "_objective",
            key_rate: target_data_name + "_rate",
            key_difference: target_data_name + "_difference",
            key_moving_average_total:
              target_data_name + "_moving_average_total",
            key_moving_average_objective:
              target_data_name + "_moving_average_objective",
            key_moving_average_rate: target_data_name + "_moving_average_rate",
            key_moving_average_last_year_comparison_rate:
              target_data_name + "_moving_average_last_year_comparison_rate",
            key_moving_average_difference:
              target_data_name + "_moving_average_difference",
            key_last_year_total: target_data_name + "_last_year_total",
            key_last_year_objective: target_data_name + "_last_year_objective",
            key_last_year_difference:
              target_data_name + "_last_year_difference",
            key_last_year_rate: target_data_name + "_last_year_rate",
            key_last_year_comparison_total:
              target_data_name + "_last_year_comparison_total",
            key_last_year_comparison_objective:
              target_data_name + "_last_year_comparison_objective",
            key_last_year_comparison_rate:
              target_data_name + "_last_year_comparison_rate",
            key_last_time_difference:
              target_data_name + "_last_time_difference",
            key_last_time_comparison_rate:
              target_data_name + "_last_time_comparison_rate"
          };

        default:
          target_data_name = "revenue";
          return {
            ...state,
            target_data_name: "revenue",
            target_data_monthly: "revenue_monthly",
            target_data_weekly: "revenue_weekly",
            target_data_daily: "revenue_daily",
            target_detail_data_name: "revenue_detail",
            target_detail_data_monthly: "revenue_detail_monthly",
            target_detail_data_weekly: "revenue_detail_weekly",
            target_detail_data_daily: "revenue_detail_daily",

            key_total: target_data_name + "_total",
            key_objective: target_data_name + "_objective",
            key_rate: target_data_name + "_rate",
            key_difference: target_data_name + "_difference",
            key_moving_average_total:
              target_data_name + "_moving_average_total",
            key_moving_average_objective:
              target_data_name + "_moving_average_objective",
            key_moving_average_rate: target_data_name + "_moving_average_rate",
            key_moving_average_last_year_comparison_rate:
              target_data_name + "_moving_average_last_year_comparison_rate",
            key_moving_average_difference:
              target_data_name + "_moving_average_difference",
            key_last_year_total: target_data_name + "_last_year_total",
            key_last_year_objective: target_data_name + "_last_year_objective",
            key_last_year_difference:
              target_data_name + "_last_year_difference",
            key_last_year_rate: target_data_name + "_last_year_rate",
            key_last_year_comparison_total:
              target_data_name + "_last_year_comparison_total",
            key_last_year_comparison_objective:
              target_data_name + "_last_year_comparison_objective",
            key_last_year_comparison_rate:
              target_data_name + "_last_year_comparison_rate",
            key_last_time_difference:
              target_data_name + "_last_time_difference",
            key_last_time_comparison_rate:
              target_data_name + "_last_time_comparison_rate"
          };
      }

    case GET_TARGET_DATA_FOR_SPAN_COMPARISON_SUCCEEDED:
      return {
        ...state,
        first_span_start: action.first_span_start,
        first_span_end: action.first_span_end,
        second_span_start: action.second_span_start,
        second_span_end: action.second_span_end,
        target_data_first_span: action.target_data_first_span,
        target_data_second_span: action.target_data_second_span,
        target_data_span_comparison: action.target_data_span_comparison
      };
    case GET_TARGET_DATA_FOR_SPAN_COMPARISON_FAILED:
      console.log("error", action.message);
      return state;

    case CLEAR_TARGET_DATA_FOR_SPAN_COMPARISON:
      return {
        ...state,
        first_span_start: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1
        ).getTime(),

        // 3ヶ月後
        first_span_end: new Date(
          new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              1
            ).getMonth() + 2
          )
        ).getTime(),

        // 昨年同月
        second_span_start: new Date(
          new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              1
            ).getMonth() - 12
          )
        ).getTime(),

        // 昨年同月から3ヶ月後
        second_span_end: new Date(
          new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              1
            ).getMonth() - 10
          )
        ).getTime(),
        target_data_first_span: [],
        target_data_second_span: []
      };

    default:
      return state;
  }
};

export default targetDataReducer;
