// 引数：過去のrevenueの配列。個数は左上、12ヶ月、24ヶ月、36ヶ月、カスタム期間のいずれか
const getFutureUnitCostDetail = (
  unit_cost_detail_results_past: any,
  forecast_base_span: number
) => {
  console.log("ここまでうごいてるか")
  // 原価カテゴリーリスト作成
  const unit_cost_name_list_forForecast = unit_cost_detail_results_past.map((item: any) => {
    return {
      category_1: item.category_1,
      category_2: item.category_2,
      category_3: item.category_3
    };
  });

  // unit_costのcategory_3の数分だけの数値リスト
  // 原価カテゴリーリストから重複のないリスト作成
  const pure_unit_cost_name_list_forForecast = 
    unit_cost_name_list_forForecast.filter((element: any, index: any, self: any) => 
      self.findIndex((e: any) => 
        e.category_1 === element.category_1 
        && 
        e.category_2 === element.category_2 
        && e.category_3 === element.category_3
      ) === index
    );


  const acc_list = pure_unit_cost_name_list_forForecast.map((item: any) => {
    // unit_cost_detail_results_past(大本の引数)から、ターゲットのコストを指定
    const target_cost_array = unit_cost_detail_results_past.filter((unit_cost_past: any) => unit_cost_past.category_1 === item.category_1 && unit_cost_past.category_2 === item.category_2 && unit_cost_past.category_3 === item.category_3 )

    // category_3の成長率を計算
    const growth_rate = target_cost_array.map((item: any, index: number) => {
      if (index === 0) {
        // 前の月と比べるので最初の月はなし。
        return {
          cost_unit_cost_total: 0,
        };
      } else {
        if (item.cost_unit_cost_total === 0 && target_cost_array[index - 1].cost_unit_cost_total !== 0) { // 当月の値に0が入っている場合
          return {
            cost_unit_cost_total: target_cost_array[index - 1].cost_unit_cost_total / target_cost_array[index - 1].cost_unit_cost_total, // 0でないほうの値を入れる
          };
        } else if (item.cost_unit_cost_total !== 0 && target_cost_array[index - 1].cost_unit_cost_total === 0) { // 昨月の値に0が入っている場合
          return {
            cost_unit_cost_total: item.cost_unit_cost_total / item.cost_unit_cost_total, // 0でないほうの値を入れる
          };
        } else if (item.cost_unit_cost_total === 0 && target_cost_array[index - 1].cost_unit_cost_total === 0) { // 当月の値と昨月の値両方に0が入っている場合
          return {
            cost_unit_cost_total: 1,
          };
        } else {
          return {
            cost_unit_cost_total: item.cost_unit_cost_total / target_cost_array[index - 1].cost_unit_cost_total,
          };
        }
      }
    }).reduce((accumulator: number, currentValue: any) => accumulator + currentValue.cost_unit_cost_total, 0) / forecast_base_span;

    return {
      category_1: item.category_1,
      category_2: item.category_2,
      category_3: item.category_3,
      cost_unit_cost_total: target_cost_array.reduce((acc: any, cur: any) => acc + cur.cost_unit_cost_total, 0),
      category_3_growth_rate: growth_rate
    }
  })


  // 将来データのもとを作成
  const futureArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  // // 日付ごとのコスト統合データ
  const integrated_array_unit_cost_detail = futureArray.map((number: number, costDateIndex: number) => {
    const thisMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const thisMonthResult = unit_cost_detail_results_past.filter((result: any) => result.result_at.seconds === thisMonth.getTime() / 1000);
    // unit_costのcategory_2、category_3をオブジェクト化したリスト
    // 販売管理費カテゴリーリスト作成
    const unit_cost_name_list = unit_cost_detail_results_past.map((item: any) => {
      return {
        category_2: item.category_2,
        category_3: item.category_3
      };
    });

    // unit_costのcategory_2のリスト
    const unit_cost_name_big_list = unit_cost_detail_results_past.map((item: any) => item.category_2);
    const pure_unit_cost_name_big_list = [...new Set(unit_cost_name_big_list)];

    // 販売管理費カテゴリーリストから重複のない文字列リスト作成
    const pure_unit_cost_name_list = unit_cost_name_list
    .filter((element: any, index: any, self: any) => 
      self.findIndex((e: any) => 
        e.category_2 === element.category_2 
        && 
        e.category_3 === element.category_3
      ) === index
    );
    const unit_cost_cat_1_total = pure_unit_cost_name_list.map((svg_category: any) => {
      // 同じカテゴリー3の名前を持つオブジェクトを見つける
      const same_category3_name_object = acc_list.find((item: any) => item.category_3 === svg_category.category_3).category_3_growth_rate
      // それぞれの成長率を代入
      const growth_rate_category_3_for_category_1 = same_category3_name_object.category_3_growth_rate
      // 月ごとにそのカテゴリー名は存在するか判定。
      const same_category3_is_exist = thisMonthResult.some((unit_cost: any) => unit_cost.category_3 === svg_category.category_3) 
      // カテゴリー名存在するなら値をそのまま保存、無い場合はundefinedになってしまうので「0」を入れる
      const unit_cost_cat_3_total = same_category3_is_exist
        ? thisMonthResult.find((unit_cost: any) => 
            unit_cost.category_3 === svg_category.category_3
          ).cost_unit_cost_total * growth_rate_category_3_for_category_1 ** number
        : 0
        return {
        unit_cost_cat_3_total: unit_cost_cat_3_total ? unit_cost_cat_3_total : 0
      };
    }).reduce((acc: any, cur: any) => acc + cur.unit_cost_cat_3_total, 0)

    return {
      unit_cost_cat_1_name: "原価",
      unit_cost_cat_1_total: unit_cost_cat_1_total,
      unit_cost_cat_2_array: pure_unit_cost_name_big_list.map((unit_cat_2_name: any) => {
        const categry_2_name_list = pure_unit_cost_name_list.filter((pure_unit_cost_name: any) => pure_unit_cost_name.category_2 === unit_cat_2_name)
        const unit_cost_cat_2_total = categry_2_name_list.map((unit_category: any) => {
          const growth_rate_category_3_for_category_2 = acc_list.find((item: any) => 
            item.category_1 === "原価" 
            && 
            item.category_2 === unit_cat_2_name 
            && 
            item.category_3 === unit_category.category_3
          ).category_3_growth_rate
          return {
            unit_cost_cat_3_total: 
              thisMonthResult.some((unit_cost: any) => unit_cost.category_3 === unit_category.category_3)
              ? thisMonthResult.find((unit_cost: any) => unit_cost.category_3 === unit_category.category_3).cost_unit_cost_total * growth_rate_category_3_for_category_2 ** number
              : 0
          };
        }).reduce((acc: any, cur: any) => acc + cur.unit_cost_cat_3_total, 0)
        return {
          unit_cost_cat_2_name: unit_cat_2_name,
          unit_cost_cat_2_total: unit_cost_cat_2_total,
          unit_cost_cat_3_array: pure_unit_cost_name_list
            .filter((pure_unit_cost_name: any) => pure_unit_cost_name.category_2 === unit_cat_2_name)
            .map((unit_category: any) => {
              const growth_rate_category_3 = acc_list.find((item: any) => 
                item.category_1 === "原価"
                && 
                item.category_2 === unit_cat_2_name 
                && 
                item.category_3 === unit_category.category_3
              ).category_3_growth_rate
              return {
                unit_cost_cat_3_name: unit_category.category_3,
                unit_cost_cat_3_total: 
                  thisMonthResult.some((unit_cost: any) => unit_cost.category_3 === unit_category.category_3) 
                  ? thisMonthResult.find((unit_cost: any) => unit_cost.category_3 === unit_category.category_3).cost_unit_cost_total * growth_rate_category_3 ** number
                  : 0
              };
            })
        };
      }),

    };


  });
  return integrated_array_unit_cost_detail

};

export default getFutureUnitCostDetail;
