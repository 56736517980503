// import update from "immutability-helper";
import { FETCH_FUNNELS_SUMMARY, FETCH_FUNNELS_DETAIL, SET_FUNNELS, SET_FUNNELS_DETAIL, GET_RESULTS_FOR_SPAN_COMPARISON_FUNNEL_SUMMARY_SUCCEEDED, GET_RESULTS_FOR_SPAN_COMPARISON_FUNNEL_SUMMARY_FAILED, GET_RESULTS_FOR_SPAN_COMPARISON_FUNNEL_DETAIL_SUCCEEDED, GET_RESULTS_FOR_SPAN_COMPARISON_FUNNEL_DETAIL_FAILED, TOGGLE_ACTION_ACHIVEMENT_SELECTED_NAME_LIST, TOGGLE_ACHIVEMENT_COST_SELECTED_NAME_LIST, ACTION_ACHIVEMENT_SELECTED_NAME_LIST_SHOW_ALL,ACHIVEMENT_COST_SELECTED_NAME_LIST_SHOW_ALL, GET_FUNNEL_MASTER_LIST_SUCCEEDED } from "./types"
import {RESET_ALL} from "../common/types";

const today = new Date()
const nextMonth = new Date(new Date().setMonth(new Date().getMonth() + 1))
const twoMonthAgo = new Date(new Date().setMonth(new Date().getMonth() - 2))
const lastMonth = new Date(new Date().setMonth(new Date().getMonth() - 1))
const newLastMonth = new Date(new Date().setMonth(lastMonth.getDate() + 10))

const initialState: any = {
  resultSpan: "monthly",
  resultMonthly: [],
  resultWeekly: [],
  resultDaily: [],
  firstCalender: {
    startDate: today,
    endDate: nextMonth,
    span: (nextMonth.getTime() - today.getTime()) / (1000 * 3600 * 24),
  },
  secondCalender: {
    startDate: twoMonthAgo,
    endDate: lastMonth,
    span: (lastMonth.getTime() - twoMonthAgo.getTime()) / (1000 * 3600 * 24),
  },
  firstResult: {
    resultMonthly: [],
    resultWeekly: [],
    resultDaily: [],
  },
  secondResult: {
    resultMonthly: [],
    resultWeekly: [],
    resultDaily: [],
  },
  actionAchievementSelectedNameList: [],
  achievementCostSelectedNameList: [],
  funnel_master_list: [],
  funnels_summary_data: []
}

const funnelsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_ALL:
      return initialState;

    case SET_FUNNELS:
      // console.log("results daily: ", action)
      if (action.resultSpan === "monthly") {
        return {
          ...state,
          resultSpan: "monthly",
          resultMonthly: action.results,
        }
      } else if (action.resultSpan === "weekly") {
        return {
          ...state,
          resultSpan: "weekly",
          resultWeekly: action.results,
        }
      } else {
        return {
          ...state,
          resultSpan: "daily",
          resultDaily: action.results,
        }
      }
    case SET_FUNNELS_DETAIL:
      if (action.resultSpan === "monthly") {
        return {
          ...state,
          resultSpan: "monthly",
          resultMonthly: action.results,
        }
      } else if (action.resultSpan === "weekly") {
        return {
          ...state,
          resultSpan: "weekly",
          resultWeekly: action.results,
        }
      } else {
        return {
          ...state,
          resultSpan: "daily",
          resultDaily: action.results,
        }
      }
    
    case FETCH_FUNNELS_SUMMARY:
      const funnels_data_list = action.raw_data.map((result:any) => result.lead_count).flat()

      const funnels_sum_data = funnels_data_list.reduce((result: any, current: any) => {
        const element = result.find((p: any) => p.funnel_name === current.funnel_name)
        if (element) {
          element.lead_count_total += current.lead_count_total;
          element.lead_count_objective += current.lead_count_objective;
          element.lead_count_last_year_comparison_rate += Number(current.lead_count_last_year_comparison_rate);
          element.lead_count_moving_average_total += current.lead_count_moving_average_total;
          element.lead_count_moving_average_objective += current.lead_count_moving_average_objective;
          element.lead_count_last_year_comparison_moving_average_objective += current.lead_count_last_year_comparison_moving_average_objective;
        } else {
          result.push({
            funnel_name: current.funnel_name,
            lead_count_total: current.lead_count_total,
            lead_count_objective: current.lead_count_objective,
            lead_count_last_year_comparison_rate_sum: Number(current.lead_count_last_year_comparison_rate),
            lead_count_moving_average_total: current.lead_count_moving_average_total,
            lead_count_moving_average_objective: current.lead_count_moving_average_objective,
          })
        }
        return result
      }, [])
      console.log('funnels_sum_data', funnels_sum_data)

      const funnels_data_array = funnels_sum_data.map((result: any) => {
        return {
          ...result,
          lead_count_difference: (result.lead_count_total - result.lead_count_objective),
          lead_count_rate: (result.lead_count_total / result.lead_count_objective),
          lead_count_last_year_comparison_rate: result.lead_count_last_year_comparison_rate_sum / (funnels_data_list.length / funnels_sum_data.length),
          lead_count_moving_average_differenece: (result.lead_count_moving_average_total - result.lead_count_moving_average_objective),
          lead_count_moving_average_rate: (result.lead_count_moving_average_total / result.lead_count_moving_average_objective),
        }
      })

      //移行率
      const transition_data_list = action.raw_data.map((result: any) => result.lead_transition_rate).flat()
      console.log('transition_data_list', transition_data_list)
        // 移行率のデータ
      const transition_rate_sum = transition_data_list.reduce((result: any, current: any) => {
        const element = result.find((p: any) => p.funnel_name === current.funnel_name);
        if (element) {
          element.lead_transition_rate_moving_average_objective += current.lead_transition_rate_moving_average_objective;
          element.lead_transition_rate_moving_average_total += current.lead_transition_rate_moving_average_total;
          element.lead_transition_rate_objective += current.lead_transition_rate_objective;
          element.lead_transition_rate_total += current.lead_transition_rate_total;
          element.lead_transition_rate_rate += current.lead_transition_rate_rate;
        } else {
          result.push({
            funnel_name: current.funnel_name,
            lead_transition_rate_moving_average_objective: current.lead_transition_rate_moving_average_objective,
            lead_transition_rate_moving_average_total: current.lead_transition_rate_moving_average_total,
            lead_transition_rate_objective: current.lead_transition_rate_objective,
            lead_transition_rate_total: current.lead_transition_rate_total,
            lead_transition_rate_rate: current.lead_transition_rate_rate
          });
        }
        return result;
      }, []);
      console.log('transition_rate_sum', transition_rate_sum)


      return {
        ...state,
        funnels_summary_data: funnels_data_array
      }

    case GET_RESULTS_FOR_SPAN_COMPARISON_FUNNEL_SUMMARY_SUCCEEDED:
      const FunnelDatas = action.results.map((result: any) => result.lead_count).flat()
      const TransitionDatas = action.results.map((result: any) => result.lead_transition_rate).flat()

      const leadDatas = FunnelDatas.reduce((result: any, current: any) => {
        const element = result.find((p: any) => p.funnel_name === current.funnel_name)
        console.log("current", current)
        if (action.spanOrder === "firstSpan") {
          if (element) {
            element.lead_count_total += current.lead_count_total
            element.lead_count_objective += current.lead_count_objective
            element.lead_count_difference += current.lead_count_difference
            element.lead_count_moving_average_total += current.lead_count_moving_average_total
            element.lead_count_moving_average_objective += current.lead_count_moving_average_objective
            element.lead_count_moving_average_difference += current.lead_count_moving_average_difference
          } else {
            result.push({
              first_funnel_name: current.funnel_name,
              first_lead_count_total: current.lead_count_total,
              first_lead_count_objective: current.lead_count_objective,
              first_lead_count_diff: current.lead_count_total - current.lead_count_objective,
              first_lead_count_rate: current.lead_count_total / current.lead_count_objective,
              first_lead_count_moving_average_total: current.lead_count_moving_average_total,
              first_lead_count_moving_average_objective: current.lead_count_moving_average_objective,
              first_lead_count_moving_average_diff: current.lead_count_moving_average_total - current.lead_count_moving_average_objective,
              first_lead_count_moving_average_rate: current.lead_count_moving_average_total / current.lead_count_moving_average_objective,
            })
          }
        } else {
          if (element) {
            element.lead_count_total += current.lead_count_total
            element.lead_count_objective += current.lead_count_objective
            element.lead_count_difference += current.lead_count_difference
            element.lead_count_moving_average_total += current.lead_count_moving_average_total
            element.lead_count_moving_average_objective += current.lead_count_moving_average_objective
            element.lead_count_moving_average_difference += current.lead_count_moving_average_difference
          } else {
            result.push({
              second_funnel_name: current.funnel_name,
              second_lead_count_total: current.lead_count_total,
              second_lead_count_objective: current.lead_count_objective,
              second_lead_count_diff: current.lead_count_total - current.lead_count_objective,
              second_lead_count_rate: current.lead_count_total / current.lead_count_objective,
              second_lead_count_moving_average_total: current.lead_count_moving_average_total,
              second_lead_count_moving_average_objective: current.lead_count_moving_average_objective,
              second_lead_count_moving_average_diff: current.lead_count_moving_average_total - current.lead_count_moving_average_objective,
              second_lead_count_moving_average_rate: current.lead_count_moving_average_total / current.lead_count_moving_average_objective,
            })
            console.log("result", result)
          }
        }
        return result
      }, [])

      // 移行率のデータ
      const transitionRates = TransitionDatas.reduce((result: any, current: any) => {
        const element = result.find((p: any) => p.funnel_name === current.funnel_name)
        if (action.spanOrder === "firstSpan") {
          if (element) {
            element.lead_transition_rate_moving_average_objective += current.lead_transition_rate_moving_average_objective
            element.lead_transition_rate_moving_average_total += current.lead_transition_rate_moving_average_total
            element.lead_transition_rate_objective += current.lead_transition_rate_objective
            element.lead_transition_rate_total += current.lead_transition_rate_total
            element.lead_transition_rate_rate += current.lead_transition_rate_rate
          } else {
            result.push({
              first_funnel_name: current.funnel_name,
              first_lead_transition_rate_moving_average_objective: current.lead_transition_rate_moving_average_objective,
              first_lead_transition_rate_moving_average_total: current.lead_transition_rate_moving_average_total,
              first_lead_transition_rate_objective: current.lead_transition_rate_objective,
              first_lead_transition_rate_total: current.lead_transition_rate_total,
              first_lead_transition_rate_rate: current.lead_transition_rate_rate,
            })
          }
        } else {
          if (element) {
            element.lead_transition_rate_moving_average_objective += current.lead_transition_rate_moving_average_objective
            element.lead_transition_rate_moving_average_total += current.lead_transition_rate_moving_average_total
            element.lead_transition_rate_objective += current.lead_transition_rate_objective
            element.lead_transition_rate_total += current.lead_transition_rate_total
            element.lead_transition_rate_rate += current.lead_transition_rate_rate
          } else {
            result.push({
              second_funnel_name: current.funnel_name,
              second_lead_transition_rate_moving_average_objective: current.lead_transition_rate_moving_average_objective,
              second_lead_transition_rate_moving_average_total: current.lead_transition_rate_moving_average_total,
              second_lead_transition_rate_objective: current.lead_transition_rate_objective,
              second_lead_transition_rate_total: current.lead_transition_rate_total,
              second_lead_transition_rate_rate: current.lead_transition_rate_rate,
            })
          }
        }
        return result
      }, [])

      const rateAverage = transitionRates.map((data: any, index: number) => {
        if (action.spanOrder === "firstSpan") {
          let transitionRateAverage = Math.round(data.lead_transition_rate_total / transitionRates.length)
          let transitionRateObjectiveAverage = Math.round(data.lead_transition_rate_objective / transitionRates.length)
          let transitionRateDiff = transitionRateAverage - transitionRateObjectiveAverage
          let transitionRateRate = transitionRateAverage / transitionRateObjectiveAverage
          return {
            first_transition_rate_average: transitionRateAverage,
            first_transition_rate_objective_average: transitionRateObjectiveAverage,
            first_transition_rate_diff: transitionRateDiff,
            first_transition_rate_rate: transitionRateRate,
          }
        } else {
          let transitionRateAverage = Math.round(data.lead_transition_rate_total / transitionRates.length)
          let transitionRateObjectiveAverage = Math.round(data.lead_transition_rate_objective / transitionRates.length)
          let transitionRateDiff = transitionRateAverage - transitionRateObjectiveAverage
          let transitionRateRate = transitionRateAverage / transitionRateObjectiveAverage
          return {
            second_transition_rate_average: transitionRateAverage,
            second_transition_rate_objective_average: transitionRateObjectiveAverage,
            second_transition_rate_diff: transitionRateDiff,
            second_transition_rate_rate: transitionRateRate,
          }
        }
      })

      const leadTransitionDatas = leadDatas.map((data: any, index: number) => {
        return {
          ...data,
          ...transitionRates[index],
        }
      })

      const finalData = leadTransitionDatas.map((data: any, index: number) => {
        return {
          ...data,
          ...rateAverage[index],
        }
      })

      if (action.resultSpan === "monthly") {
        // return state
        if (action.spanOrder === "firstSpan") {
          console.log("first result!")
          console.log("action.results", action.results)
          return {
            ...state,
            firstResult: {
              ...state.firstResult,
              resultDaily: finalData,
            },
          }
        } else {
          if (action.spanOrder === "secondSpan") {
            console.log("second result!")
            return {
              ...state,
              secondResult: {
                ...state.secondResult,
                resultDaily: finalData,
              },
            }
          }
        }
      } else if (action.resultSpan === "weekly") {
        // return state
        if (action.spanOrder === "firstSpan") {
          console.log("first result!")
          console.log("action.results", action.results)
          return {
            ...state,
            firstResult: {
              ...state.firstResult,
              resultDaily: finalData,
            },
          }
        } else {
          if (action.spanOrder === "secondSpan") {
            console.log("second result!")
            return {
              ...state,
              secondResult: {
                ...state.secondResult,
                resultDaily: finalData,
              },
            }
          }
        }
      } else {
        if (action.spanOrder === "firstSpan") {
          console.log("first result!")
          console.log("action.results", action.results)
          return {
            ...state,
            firstResult: {
              ...state.firstResult,
              resultDaily: finalData,
            },
          }
        } else {
          if (action.spanOrder === "secondSpan") {
            console.log("second result!")
            return {
              ...state,
              secondResult: {
                ...state.secondResult,
                resultDaily: finalData,
              },
            }
          }
        }
      }
      break;

    case GET_RESULTS_FOR_SPAN_COMPARISON_FUNNEL_DETAIL_SUCCEEDED:
      const dateList = action.results.map((rd: any) => {
        if (action.spanOrder === "firstSpan") {
          return {
            first_date: rd.result_at,
          }
        } else {
          return {
            second_date: rd.result_at,
          }
        }
      })

      const funnelDetail = action.results
        .flatMap((rd: any) => rd.lead_count)
        .map((rd: any, index: number) => {
          if (action.spanOrder === "firstSpan") {
            return {
              first_funnel_name: rd.funnel_name,
              first_lead_count_total: rd.lead_count_total,
              first_lead_count_objective: rd.lead_count_objective,
              first_lead_count_rate: rd.lead_count_rate,
              first_lead_count_difference: rd.lead_count_difference,
              first_lead_count_moving_average_total: rd.lead_count_moving_average_total,
              first_lead_count_moving_average_objective: rd.lead_count_moving_average_objective,
              first_lead_count_last_year_comparison_total: rd.lead_count_last_year_comparison_total,
              first_lead_count_last_year_comparison_objective: rd.lead_count_last_year_comparison_objective,
              first_lead_count_last_year_comparison_rate: rd.lead_count_last_year_comparison_rate,
            }
          } else {
            return {
              second_funnel_name: rd.funnel_name,
              second_lead_count_total: rd.lead_count_total,
              second_lead_count_objective: rd.lead_count_objective,
              second_lead_count_rate: rd.lead_count_rate,
              second_lead_count_difference: rd.lead_count_difference,
              second_lead_count_moving_average_total: rd.lead_count_moving_average_total,
              second_lead_count_moving_average_objective: rd.lead_count_moving_average_objective,
              second_lead_count_last_year_comparison_total: rd.lead_count_last_year_comparison_total,
              second_lead_count_last_year_comparison_objective: rd.lead_count_last_year_comparison_objective,
              second_lead_count_last_year_comparison_rate: rd.lead_count_last_year_comparison_rate,
            }
          }
        })

      const DatefunnelDetailList = dateList.map((data: any, index: number) => {
        return { ...data, ...funnelDetail[index] }
      })

      console.log(DatefunnelDetailList)

      if (action.resultSpan === "monthly") {
        // return state
        if (action.spanOrder === "firstSpan") {
          console.log("first result!")
          console.log("action.results", action.results)
          return {
            ...state,
            firstResult: {
              ...state.firstResult,
              resultDaily: DatefunnelDetailList,
            },
          }
        } else {
          if (action.spanOrder === "secondSpan") {
            console.log("second result!")
            return {
              ...state,
              secondResult: {
                ...state.secondResult,
                resultDaily: DatefunnelDetailList,
              },
            }
          }
        }
      } else if (action.resultSpan === "weekly") {
        // return state
        if (action.spanOrder === "firstSpan") {
          console.log("first result!")
          console.log("action.results", action.results)
          return {
            ...state,
            firstResult: {
              ...state.firstResult,
              resultDaily: DatefunnelDetailList,
            },
          }
        } else {
          if (action.spanOrder === "secondSpan") {
            console.log("second result!")
            return {
              ...state,
              secondResult: {
                ...state.secondResult,
                resultDaily: DatefunnelDetailList,
              },
            }
          }
        }
      } else {
        if (action.spanOrder === "firstSpan") {
          console.log("first result!")
          console.log("action.results", action.results)
          return {
            ...state,
            firstResult: {
              ...state.firstResult,
              resultDaily: DatefunnelDetailList,
            },
          }
        } else {
          if (action.spanOrder === "secondSpan") {
            console.log("second result!")
            return {
              ...state,
              secondResult: {
                ...state.secondResult,
                resultDaily: DatefunnelDetailList,
              },
            }
          }
        }
      }
      break;

    case GET_RESULTS_FOR_SPAN_COMPARISON_FUNNEL_SUMMARY_FAILED:
      return {
        ...state,
        message: action.message,
      }
    case GET_RESULTS_FOR_SPAN_COMPARISON_FUNNEL_DETAIL_FAILED:
      return {
        ...state,
        message: action.message,
      }

    case TOGGLE_ACHIVEMENT_COST_SELECTED_NAME_LIST:
      if (state.achievementCostSelectedNameList.includes(action.selectedName)) {
        console.log("already in")
        const newSelectedNameList = state.achievementCostSelectedNameList.filter((name: any) => name !== action.selectedName)
        console.log(newSelectedNameList)
        return {
          ...state,
          achievementCostSelectedNameList: newSelectedNameList,
        }
      } else {
        return {
          ...state,
          achievementCostSelectedNameList: [...state.achievementCostSelectedNameList, action.selectedName],
        }
      }

    case TOGGLE_ACTION_ACHIVEMENT_SELECTED_NAME_LIST:
      if (state.actionAchievementSelectedNameList.includes(action.selectedName)) {
        console.log("already in")
        const newSelectedNameList = state.actionAchievementSelectedNameList.filter((name: any) => name !== action.selectedName)
        console.log(newSelectedNameList)
        return {
          ...state,
          actionAchievementSelectedNameList: newSelectedNameList,
        }
      } else {
        return {
          ...state,
          actionAchievementSelectedNameList: [...state.actionAchievementSelectedNameList, action.selectedName],
        }
      }

    case ACHIVEMENT_COST_SELECTED_NAME_LIST_SHOW_ALL:
      return {
        ...state,
        achievementCostSelectedNameList: [],
      }

    case ACTION_ACHIVEMENT_SELECTED_NAME_LIST_SHOW_ALL:
      return {
        ...state,
        actionAchievementSelectedNameList: [],
      }

    case GET_FUNNEL_MASTER_LIST_SUCCEEDED:
      return {
        ...state,
        funnel_master_list: action.funnel_master_list
      }

    default:
      return state
  }
}

export default funnelsReducer
