import React from "react";
import styled from "styled-components";
import {
  ResponsiveContainer,
  Line,
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import { yAxisFormatter } from "../../Util/yAxisFormatter";
import { unitSwitcher } from "../../Util/unitSwitcher";
import { dateFormatter } from "../../Util/DateFormatter";
//customLegend
const customLegend = (props: any) => {
  const { payload } = props;
  return (
    <LegendList>
      {payload.map((result: any, index: number) => (
        <LegendItem key={`item-${index}`}>
          {result.dataKey.includes("_total") ? (
            <span
              className="item-total"
              style={{ backgroundColor: `${result.color}` }}
            ></span>
          ) : (
            <span className="item-obj" style={{ color: `${result.color}` }}>
              …
            </span>
          )}
          {result.value}
        </LegendItem>
      ))}
    </LegendList>
  );
};

const CustomTooltip = ({
  active,
  payload,
  target_data_name,
  resultSpan
}: any) => {
  console.log("payload", payload);
  if (active && payload && payload.length) {
    return (
      <StyledCustomTooltip>
        <TooltipHeading>期間比較</TooltipHeading>

        {payload.map((payload_item: any, index: number) => {
          console.log(payload_item.name);
          return (
            <TooltipContent key={payload_item.dataKey}>
              <TooltipLabel>
                <TooltipBullet color={payload_item.color}>
                  {payload_item.name}:
                </TooltipBullet>
                {index === 0
                  ? dateFormatter(
                      resultSpan,
                      payload_item.payload.first_result_at.seconds * 1000
                    )
                  : dateFormatter(
                      resultSpan,
                      payload_item.payload.second_result_at.seconds * 1000
                    )}
              </TooltipLabel>
              <TooltipData>{`${unitSwitcher(
                payload_item.value,
                target_data_name
              )}`}</TooltipData>
              <TooltipHR />
            </TooltipContent>
          );
        })}
      </StyledCustomTooltip>
    );
  }

  return null;
};

interface SpanComparisonGraphProps {
  data: any;
  target_data_name: string;
  resultSpan: string;
}

const SpanComparisonGraph: React.FC<SpanComparisonGraphProps> = ({
  data,
  target_data_name,
  resultSpan
}) => {
  return (
    <>
      {data.length > 0 ? (
        <Graph>
          <ResponsiveContainer>
            <LineChart width={1000} height={400} data={data}>
              <Legend verticalAlign="top" align="left" content={customLegend} />
              <CartesianGrid stroke="#f5f5f5" vertical={false} />
              <Line
                type="linear"
                name="期間１"
                dataKey="first_key_total"
                dot={false}
                stroke="#0066FF"
                strokeWidth="6"
              />

              <Line
                type="linear"
                name="期間２"
                dataKey="second_key_total"
                dot={false}
                stroke="#E53E66"
                strokeWidth="6"
              />

              <XAxis hide={true} scale="point" />
              <YAxis
                tickCount={10}
                interval={0}
                tickLine={false}
                tickMargin={10}
                tickFormatter={tick => yAxisFormatter(tick, target_data_name)}
              />

              <Tooltip
                content={
                  <CustomTooltip
                    target_data_name={target_data_name}
                    resultSpan={resultSpan}
                  />
                }
              />
            </LineChart>
          </ResponsiveContainer>
        </Graph>
      ) : (
        <NoData>グラフを表示できません</NoData>
      )}
    </>
  );
};

export default SpanComparisonGraph;
const Graph = styled.div`
  width: 100%;
  height: 300px;
  padding: 24px 10px;
  box-sizing: border-box;
  margin: -26px 0px 0px 0px;

  .recharts-legend-wrapper {
    overflow: visible;
    top: -20px !important;
    left: 10px !important;
    font-size: 12px;
    color: #283f46;
  }

  .recharts-surface {
    position: relative;
    top: -2px;
    overflow: visible;
  }
  .recharts-cartesian-axis-tick {
    width: 100px !important;
    .recharts-cartesian-axis-tick-value {
      font-size: 10px;
      color: #435878;
    }
  }
  .recharts-yAxis {
    .recharts-cartesian-axis-line {
      stroke: #f5f5f5;
    }
  }
`;

//customLegend
const LegendList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  top: -4px;
`;
const LegendItem = styled.li`
  font-size: 12px;
  line-height: 1.6;
  margin-right: 16px;
  padding-left: 24px;
  box-sizing: borde-box;
  position: relative;
  span {
    border-radius: 6px;
    display: block;
    position: absolute;
    left: 0;
    transform: translateY(-50%);
    &.item-total {
      width: 16px;
      height: 3px;
      top: 50%;
      left: 2px;
    }
    &.item-obj {
      font-size: 30px;
      line-height: 1;
      top: 2%;
    }
  }
`;

const NoData = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #435878;
  margin-bottom: 8px;
  &.main {
    margin-bottom: 8px;
  }
`;

const StyledCustomTooltip = styled.div`
  background: #fff;
  padding: 8px 9px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid #e2e9f3;
  border-radius: 4px;
  max-width: 200px;
`;

// 日付
const TooltipHeading = styled.h3`
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  color: #435878;
  margin-bottom: 5px;
`;

const TooltipContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
// 当月 | 目標
const TooltipLabel = styled.p`
  font-size: 10px;
  color: #435878;
  margin-bottom: 3px;
`;
// データ
const TooltipData = styled.p`
  text-align: right;
  font-size: 10px;
  font-weight: bold;
  color: #435878;
  margin-bottom: 3px;
`;
// 仕切り線
const TooltipHR = styled.hr`
  width: 100%;
  height: 1px;
  color: lightgray;
  margin-bottom: 3px;
`;

type Color = {
  color: string;
};
const TooltipBullet = styled.span<Color>`
  &::before {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    margin-right: 4px;
    border-radius: 50%;
    background-color: ${props => props.color};
  }
`;
