import React from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { setFilter } from '../../reducks/filter/actions';
import { clearTargetDataForSpanComparison } from '../../reducks/targetData/actions';

const MetricsSubMenu = () => {
  const location = useLocation();
  const pathName = location.pathname;
  const dispatch = useDispatch();

  const handleClickSubMenu = () => {
    dispatch(setFilter(''))
    dispatch(clearTargetDataForSpanComparison())
  }

  return (
    <SubMenuWrapper>
      <SubMenuList>
        <SubMenuLabel>重要指標</SubMenuLabel>
        <SubMenuItem onClick={handleClickSubMenu}>
          <SubMenuLink to="/metrics/revenue" className={pathName.match('/metrics/revenue') ? 'current' : ''}>
            REVENUE
          </SubMenuLink>
        </SubMenuItem>
        {/* ここで===は文字列をマッハするために使用されます。それ以外の場合、mrr-churn-rateパスが選択されると、MRRパスも選択されるためです。 */}
        <SubMenuItem onClick={handleClickSubMenu}>
          <SubMenuLink to="/metrics/mrr" className={pathName==='/metrics/mrr' ? 'current' : ''}>
            MRR
          </SubMenuLink>
        </SubMenuItem>
        <SubMenuItem onClick={handleClickSubMenu}>
          <SubMenuLink to="/metrics/unit-economics" className={pathName.match('/metrics/unit-economics') ? 'current' : ''}>
            Unit Economics
          </SubMenuLink>
        </SubMenuItem>
        <SubMenuItem onClick={handleClickSubMenu}>
          <SubMenuLink to="/metrics/cac" className={pathName.match('/metrics/cac') ? 'current' : ''}>
            CAC
          </SubMenuLink>
        </SubMenuItem>
        <SubMenuItem onClick={handleClickSubMenu}>
          <SubMenuLink to="/metrics/payback-period" className={pathName.match('/metrics/payback-period') ? 'current' : ''}>
            Payback Period
          </SubMenuLink>
        </SubMenuItem>
        <SubMenuItem onClick={handleClickSubMenu}>
          <SubMenuLink to="/metrics/ltv" className={pathName.match('/metrics/ltv') ? 'current' : ''}>
            LTV
          </SubMenuLink>
        </SubMenuItem>
        <SubMenuItem onClick={handleClickSubMenu}>
          <SubMenuLink to="/metrics/arpu" className={pathName.match('/metrics/arpu') ? 'current' : ''}>
            ARPU
          </SubMenuLink>
        </SubMenuItem>
        <SubMenuItem onClick={handleClickSubMenu}>
          <SubMenuLink to="/metrics/churn-rate" className={pathName.match('/metrics/churn-rate') ? 'current' : ''}>
            Churn Rate
          </SubMenuLink>
        </SubMenuItem>
        <SubMenuItem onClick={handleClickSubMenu}>
          <SubMenuLink to="/metrics/mrr-churn-rate" className={pathName.match('/metrics/mrr-churn-rate') ? 'current' : ''}>
            MRR Churn Rate
          </SubMenuLink>
        </SubMenuItem>
        <SubMenuItem onClick={handleClickSubMenu}>
          <SubMenuLink to="/metrics/grr" className={pathName.match('/metrics/grr') ? 'current' : ''}>
            GRR
          </SubMenuLink>
        </SubMenuItem>
        <SubMenuItem onClick={handleClickSubMenu}>
          <SubMenuLink to="/metrics/nrr" className={pathName.match('/metrics/nrr') ? 'current' : ''}>
            NRR
          </SubMenuLink>
        </SubMenuItem>
        <SubMenuItem onClick={handleClickSubMenu}>
          <SubMenuLink to="/metrics/quick-ratio" className={pathName.match('/metrics/quick-ratio') ? 'current' : ''}>
            Quick Ratio
          </SubMenuLink>
        </SubMenuItem>
        <SubMenuItem onClick={handleClickSubMenu}>
          <SubMenuLink to="/metrics/acv-cumulative" className={pathName.match('/metrics/acv-cumulative') ? 'current' : ''}>
            ACV(累積)
          </SubMenuLink>
        </SubMenuItem>
        <SubMenuItem onClick={handleClickSubMenu}>
          <SubMenuLink to="/metrics/acv-new" className={pathName.match('/metrics/acv-new') ? 'current' : ''}>
            ACV(新規)
          </SubMenuLink>
        </SubMenuItem>
        <SubMenuItem onClick={handleClickSubMenu}>
          <SubMenuLink to="/metrics/cpa" className={pathName.match('/metrics/cpa') ? 'current' : ''}>
            CPA
          </SubMenuLink>
        </SubMenuItem>
        <SubMenuItem onClick={handleClickSubMenu}>
          <SubMenuLink to="/metrics/conversion_rate" className={pathName.match('/metrics/conversion_rate') ? 'current' : ''}>
            有料転換率
          </SubMenuLink>
        </SubMenuItem>
      </SubMenuList>
    </SubMenuWrapper>
  );
};

export default MetricsSubMenu;


const SubMenuWrapper = styled.div({
  paddingTop: '20px',
});

const SubMenuList = styled.dl({
  backgroundColor: '#F4F4F4',
  padding: '0 0 20px 0',
  width: '120px'
});

const SubMenuLabel = styled.dt`
  color: #566970;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 10px;
  &.churn, &.customer {
    padding-top: 16px;
  }
  
`;

const SubMenuItem = styled.dd({
  margin: '0',
});

const SubMenuLink = styled(NavLink)({
  display: 'block',
  color: '#0066FF',
  fontSize: '12px',
  padding: '6px 9px',
  '&:hover': {
    backgroundColor: '#0066FF',
    fontWeight: 'bold',
    color: '#FFFFFF',
  },
  '&.current': {
    backgroundColor: '#0066FF',
    fontWeight: 'bold',
    color: '#FFFFFF',
  },
});
