import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createStore } from '@reduxjs/toolkit';
import { combineReducers, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootSaga from '../sagas';

import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import userReducer from '../reducks/user/reducers';
import usersReducer from '../reducks/users';
import sessionsReducer from '../reducks/sessions/reducers';
import membersReducer from '../reducks/members/reducers';
import billingAccountReducer from '../reducks/billingAccount/reducers';
import billingsReducer from '../reducks/billings/reducers';
import planReducer from '../reducks/plan/reducers';
import filtersReducer from '../reducks/filters/reducers';
import filterReducer from '../reducks/filter/reducers';
import commentsReducer from '../reducks/comments/reducers';
import resultsReducer from '../reducks/results/reducers';

import calendarReducer from '../reducks/calendar/reducers';

import gridReducer from '../reducks/grid/gridReducers';
import dataReducer from '../reducks/data/reducers';
import arpuReducer from '../reducks/metrics/arpu/reducers';
import cacReducer from '../reducks/metrics/cac/reducers';
import churnReducer from '../reducks/metrics/churn/reducers';
import conversionRateReducer from '../reducks/metrics/conversionRate/reducers';
import ltvReducer from '../reducks/metrics/ltv/reducers';
import mrrReducer from '../reducks/metrics/mrr/reducers';
import paybackPeriodReducer from '../reducks/metrics/paybackPeriod/reducers';
import unitEconomicsReducer from '../reducks/metrics/unitEconomics/reducers';
import funnelsReducer from '../reducks/funnels/reducers';
import leadsReducer from '../reducks/leads/reducers';
import spanComparisonCalenderReducer from '../reducks/spanComparisonCalenders/reducers';
// import newForecastReducer from '../reducks/newForecast/reducers';
import newMetricsForecastReducer from '../reducks/newMetricsForecast/reducers';
import newFunnelForecastReducer from '../reducks/newFunnelForecast/reducers';

import forecastsReducer from '../reducks/forecasts/reducers';
import marketingFunnelForecastsReducer from '../reducks/marketingFunnelForecast/reducers';

import profitReducer from '../reducks/profit/reducers';
import revenueReducer from '../reducks/revenue/reducers';
import costReducer from '../reducks/cost/reducers';

import landingPageModalReducer from '../reducks/landingPageModal/reducers';

import companyReducer from '../reducks/company/reducers.js';

import uploadHistoryReducer from '../reducks/uploadHistory/reducers';

import productsReducer from '../reducks/products/reducers';

import loadingReducer from '../reducks/loading/reducers';

import uploadStatusReducer from '../reducks/uploadStatus/reducers';


import revenueMonthlyReducer from '../reducks/revenue/monthly/reducer';
import revenueWeeklyReducer from '../reducks/revenue/weekly/reducer';
import revenueDailyReducer from '../reducks/revenue/daily/reducer';

import arpuMonthlyReducer from '../reducks/arpu/monthly/reducer';
import arpuWeeklyReducer from '../reducks/arpu/weekly/reducer';
import arpuDailyReducer from '../reducks/arpu/daily/reducer';

import targetDataReducer from '../reducks/targetData/reducer';
import rawDataReducer from '../reducks/rawData/reducer';
import targetForecastReducer from '../reducks/targetForecast/reducer';

import inquiryReducer from '../reducks/inquiries/reducers';
import cohortReducer from '../reducks/cohort/reducers';

import graphReducer from "../reducks/graph/reducers";
import downloadReducer from "../reducks/download/reducers";

// Devtoolsを使用するために宣言
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// SagaのMiddleware宣言
// const sagaMiddleware = createSagaMiddleware();

// Reducerを一つにまとめる
const rootReducer = (history: any) => combineReducers({
  session: sessionsReducer,
  user: userReducer,
  company: companyReducer,
  upload_histories: uploadHistoryReducer,

  results: resultsReducer,
  users: usersReducer,
  comments: commentsReducer,
  members: membersReducer,

  // billings: billingsReducer,
  // billingAccount: billingAccountReducer,
  // plan: planReducer,
  filters: filtersReducer,
  filter: filterReducer,

  calendar: calendarReducer,
  forecasts: forecastsReducer,
  // marketingFunnelForecast: marketingFunnelForecastsReducer,
  grid: gridReducer,
  data: dataReducer,

  // arpu: arpuReducer,
  // cac: cacReducer,
  // churn: churnReducer,
  // conversionRate: conversionRateReducer,
  // ltv: ltvReducer,
  // mrr: mrrReducer,
  // paybackPeriod: paybackPeriodReducer,
  // unitEconomics: unitEconomicsReducer,

  funnels: funnelsReducer,
  leads: leadsReducer,
  spanComparisonCalender: spanComparisonCalenderReducer,
  // newForecast: newForecastReducer,
  // newMetricsForecast: newMetricsForecastReducer,
  // newFunnelForecast: newFunnelForecastReducer,

  // profit: profitReducer,
  // revenue: revenueReducer,
  // cost: costReducer,

  landingPageModal: landingPageModalReducer,
  products: productsReducer,

  isLoading: loadingReducer,

  // uploadStatus: uploadStatusReducer,


  // revenue_monthly: revenueMonthlyReducer,
  // revenue_weekly: revenueWeeklyReducer,
  // revenue_daily: revenueDailyReducer,

  // arpu_monthly: arpuMonthlyReducer,
  // arpu_weekly: arpuWeeklyReducer,
  // arpu_daily: arpuDailyReducer,

  target_data: targetDataReducer,
  raw_data: rawDataReducer,
  target_forecast: targetForecastReducer,

  inquiries: inquiryReducer,
  cohort: cohortReducer,

  graph : graphReducer,

  download : downloadReducer,

  router: connectRouter(history)

});

// Middlewareを１つにまとめる
// const middlewares = [logger, sagaMiddleware];

// Storeの設定
// const configureStore = () => {
//   const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)));
//   sagaMiddleware.run(rootSaga);

//   return store;
// };

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'session',
    'user',
    'members',
    'company',
    'upload_histories',
    'grid',
    'filters',
    'results',
    'comments',
    'products',
    'filter',
    // 'cost',
    'funnels',
    // 'revenue_monthly',
    // 'revenue_weekly',
    // 'revenue_daily',
    // 'arpu_monthly',
    // 'arpu_weekly',
    // 'arpu_daily',
    'target_data',
    'target_forecast',
    'raw_data',
    'forecasts',
    'cohort',
  ]
};

export const history = createBrowserHistory()

const persistedReducer = persistReducer(persistConfig, rootReducer(history));
const sagaMiddleware = createSagaMiddleware();
const middlewares = [logger, sagaMiddleware, routerMiddleware(history)];

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

const persistor = persistStore(store);

export { store, persistor, sagaMiddleware };
