import {
  LOGIN_SUCCEEDED,
  LOGIN_FAILED,
  LOGIN_FAILED_RESET,
  LOGOUT_REQUESTED,
  LOGOUT_SUCCEEDED,
  LOGOUT_FAILED,
  SET_PAGE_PATH,
  TOGGLE_MOVING_AVERAGE_GRAPH_SWITCH,
  TOGGLE_THIS_TIME_GRAPH_SWITCH,
  SET_ACCESS_TOKEN,
  URL_TO_DATA_NAME_ACTION,
  TOGGLE_EXPANDER,
  TOGGLE_TOTAL_SUMMARY,
  TOGGLE_MOVING_AVERAGE_SUMMARY,
  TOGGLE_TOTAL_DETAIL,
  TOGGLE_MOVING_AVERAGE_DETAIL,
  TOGGLE_FORECAST_REVENUE,
  TOGGLE_FORECAST_UNIT_COST,
  TOGGLE_FORECAST_GROSS_PROFIT,
  TOGGLE_FORECAST_SVG_COST,
  TOGGLE_FORECAST_OPERATING_PROFIT,
  TOGGLE_FORECAST_REVENUE_DETAIL,
  TOGGLE_FORECAST_UNIT_COST_DETAIL,
  TOGGLE_FORECAST_SVG_COST_DETAIL,
  TOGGLE_FORECAST_FUNNEL_TABLE,
  SET_DISPLAYED_FORECAST_FUNNEL_RAWS,
  SET_WIDTH,
  TOGGLE_PERIODIC_AVERAGE_GRAPH
} from "./types";
import { RESET_ALL } from "../common/types";

interface User {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  first_name_jp: string;
  last_name_jp: string;
  company_id: string;
  company_name: string;
  role: string;
}

interface ForecastFunnelRaw {
  [funnel_name: string]: boolean;
}
interface Session {
  loggedIn: boolean;
  user: User;
  page_path: string;
  this_time_graph_switch: boolean;
  moving_average_graph_switch: boolean;
  periodic_average_graph: boolean; //  PERIODIC_AVERAGE_GRAPH
  access_token: string;
  inner_width: number;
  expanderActivated: boolean;
  totalSummaryOpened: boolean;
  movingAverageSummaryOpened: boolean;
  forecastRevenueOpened: boolean;
  forecastRevenueDetailOpened: boolean;
  forecastUnitCostOpened: boolean;
  forecastUnitCostDetailOpened: boolean;
  forecastGrossProfitOpened: boolean;
  forecastSvgCostOpened: boolean;
  forecastSvgCostDetailOpened: boolean;
  forecastOperatingProfitOpened: boolean;
  period_average_sum: number;
  displayed_forecast_funnel_raws: ForecastFunnelRaw;
}

const initialState: Session = {
  loggedIn: false,
  user: {
    id: "",
    email: "",
    first_name: "",
    last_name: "",
    first_name_jp: "",
    last_name_jp: "",
    company_id: "",
    company_name: "",
    role: ""
  },
  page_path: "",
  this_time_graph_switch: true,
  moving_average_graph_switch: false,
  periodic_average_graph: false, //PERIODIC_AVERAGE_GRAPH
  access_token: "",
  inner_width: window.innerWidth,
  expanderActivated: false,
  totalSummaryOpened: false,
  movingAverageSummaryOpened: false,
  forecastRevenueOpened: false,
  forecastRevenueDetailOpened: false,
  forecastUnitCostOpened: false,
  forecastUnitCostDetailOpened: false,
  forecastGrossProfitOpened: false,
  forecastSvgCostOpened: false,
  forecastSvgCostDetailOpened: false,
  forecastOperatingProfitOpened: false,
  displayed_forecast_funnel_raws: {
    funnel_1: false,
    first_transition_rate: false
  },
  period_average_sum: 0 //TOGGLE_PERIOD_AVERAGE_SUM
};

const sessionsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_ALL:
      return initialState;

    case LOGIN_SUCCEEDED:
      return {
        ...state,
        loggedIn: true,
        user: {
          email: action.user.email
        },
        result_daily: action.result_daily,
        page_path: "/"
      };

    case LOGIN_FAILED:
      return {
        ...state,
        message: action.message
      };

    case LOGIN_FAILED_RESET:
      return {
        ...state,
        message: ""
      };

    case LOGOUT_REQUESTED:
      return {
        ...state,
        loggedIn: false,
        user: {
          id: "",
          email: "",
          first_name: "",
          last_name: "",
          first_name_jp: "",
          last_name_jp: "",
          company_id: "",
          company_name: "",
          role: ""
        },
        notifications: []
      };

    case SET_PAGE_PATH:
      return {
        ...state,
        page_path: action.page_path
      };

    case SET_ACCESS_TOKEN:
      return {
        ...state,
        access_token: action.access_token,
        salesforce_api_key: action.salesforce_api_key,
        salesforce_secret_key: action.salesforce_secret_key
      };

    case SET_WIDTH:
      return {
        ...state,
        inner_width: action.inner_width
      };

    case TOGGLE_THIS_TIME_GRAPH_SWITCH:
      return {
        ...state,
        this_time_graph_switch: !action.this_time_graph_switch
      };

    case TOGGLE_MOVING_AVERAGE_GRAPH_SWITCH:
      return {
        ...state,
        moving_average_graph_switch: !action.moving_average_graph_switch
      };

    //PERIODIC_AVERAGE_GRAPH
    case TOGGLE_PERIODIC_AVERAGE_GRAPH:
      return {
        ...state,
        periodic_average_graph: !action.periodic_average_graph
      };

    case TOGGLE_EXPANDER:
      return {
        ...state,
        expanderActivated: !action.expanderActivated
      };

    case TOGGLE_TOTAL_SUMMARY:
      return {
        ...state,
        totalSummaryOpened: !action.totalSummaryOpened
      };

    case TOGGLE_MOVING_AVERAGE_SUMMARY:
      return {
        ...state,
        movingAverageSummaryOpened: !action.movingAverageSummaryOpened
      };

    case TOGGLE_TOTAL_DETAIL:
      return {
        ...state,
        totalDetailOpened: !action.totalDetailOpened
      };

    case TOGGLE_MOVING_AVERAGE_DETAIL:
      return {
        ...state,
        movingAverageDetailOpened: !action.movingAverageDetailOpened
      };

    case TOGGLE_FORECAST_REVENUE:
      return {
        ...state,
        forecastRevenueOpened: !action.forecastRevenueOpened
      };

    case TOGGLE_FORECAST_REVENUE_DETAIL:
      return {
        ...state,
        forecastRevenueDetailOpened: !action.forecastRevenueDetailOpened
      };

    case TOGGLE_FORECAST_UNIT_COST:
      return {
        ...state,
        forecastUnitCostOpened: !action.forecastUnitCostOpened
      };

    case TOGGLE_FORECAST_UNIT_COST_DETAIL:
      return {
        ...state,
        forecastUnitCostDetailOpened: !action.forecastUnitCostDetailOpened
      };

    case TOGGLE_FORECAST_GROSS_PROFIT:
      return {
        ...state,
        forecastGrossProfitOpened: !action.forecastGrossProfitOpened
      };

    case TOGGLE_FORECAST_SVG_COST:
      return {
        ...state,
        forecastSvgCostOpened: !action.forecastSvgCostOpened
      };

    case TOGGLE_FORECAST_SVG_COST_DETAIL:
      return {
        ...state,
        forecastSvgCostDetailOpened: !action.forecastSvgCostDetailOpened
      };

    case TOGGLE_FORECAST_OPERATING_PROFIT:
      return {
        ...state,
        forecastOperatingProfitOpened: !action.forecastOperatingProfitOpened
      };

    case URL_TO_DATA_NAME_ACTION:
      console.log("action.location", action.location);
      switch (action.location) {
        case "/metrics/revenue":
          return {
            ...state,
            target_data_name: "revenue",
            target_data_monthly: "revenue_monthly",
            target_data_weekly: "revenue_weekly",
            target_data_daily: "revenue_daily",
            target_detail_data_name: "revenue_detail",
            target_detail_data_monthly: "revenue_detail_monthly",
            target_detail_data_weekly: "revenue_detail_weekly",
            target_detail_data_daily: "revenue_detail_daily"
          };

        case "/metrics/mrr":
          return {
            ...state,
            target_data_name: "mrr",
            target_data_monthly: "mrr_monthly",
            target_data_weekly: "mrr_weekly",
            target_data_daily: "mrr_daily",
            target_detail_data_name: "mrr_detail",
            target_detail_data_monthly: "mrr_detail_monthly",
            target_detail_data_weekly: "mrr_detail_weekly",
            target_detail_data_daily: "mrr_detail_daily"
          };

        case "/metrics/unit-economics":
          return {
            ...state,
            target_data_name: "unit_economics",
            target_data_monthly: "unit_economics_monthly",
            target_data_weekly: "unit_economics_weekly",
            target_data_daily: "unit_economics_daily",

            target_detail_data_name: "unit_economics_detail",
            target_detail_data_monthly: "unit_economics_detail_monthly",
            target_detail_data_weekly: "unit_economics_detail_weekly",
            target_detail_data_daily: "unit_economics_detail_daily"
          };
        case "/metrics/cac":
          return {
            ...state,
            target_data_name: "cac",
            target_data_monthly: "cac_monthly",
            target_data_weekly: "cac_weekly",
            target_data_daily: "cac_daily",

            target_detail_data_name: "cac_detail",
            target_detail_data_monthly: "cac_detail_monthly",
            target_detail_data_weekly: "cac_detail_weekly",
            target_detail_data_daily: "cac_detail_daily"
          };
        case "/metrics/payback-period":
          return {
            ...state,
            target_data_name: "payback_period",
            target_data_monthly: "payback_period_monthly",
            target_data_weekly: "payback_period_weekly",
            target_data_daily: "payback_period_daily",

            target_detail_data_name: "payback_period_detail",
            target_detail_data_monthly: "payback_period_detail_monthly",
            target_detail_data_weekly: "payback_period_detail_weekly",
            target_detail_data_daily: "payback_period_detail_daily"
          };
        case "/metrics/ltv":
          return {
            ...state,
            target_data_name: "ltv",
            target_data_monthly: "ltv_monthly",
            target_data_weekly: "ltv_weekly",
            target_data_daily: "ltv_daily",

            target_detail_data_name: "ltv_detail",
            target_detail_data_monthly: "ltv_detail_monthly",
            target_detail_data_weekly: "ltv_detail_weekly",
            target_detail_data_daily: "ltv_detail_daily"
          };
        case "/metrics/arpu":
          return {
            ...state,
            target_data_name: "arpu",
            target_data_monthly: "arpu_monthly",
            target_data_weekly: "arpu_weekly",
            target_data_daily: "arpu_daily",

            target_detail_data_name: "arpu_detail",
            target_detail_data_monthly: "arpu_detail_monthly",
            target_detail_data_weekly: "arpu_detail_weekly",
            target_detail_data_daily: "arpu_detail_daily"
          };
        case "/metrics/churn-rate":
          return {
            ...state,
            target_data_name: "churn_rate",
            target_data_monthly: "churn_rate_monthly",
            target_data_weekly: "churn_rate_weekly",
            target_data_daily: "churn_rate_daily",

            target_detail_data_name: "churn_rate_detail",
            target_detail_data_monthly: "churn_rate_detail_monthly",
            target_detail_data_weekly: "churn_rate_detail_weekly",
            target_detail_data_daily: "churn_rate_detail_daily"
          };
        case "/metrics/mrr-churn-rate":
            return {
            ...state,
            target_data_name: "mrr_churn_rate",
            target_data_monthly: "mrr_churn_rate_monthly",
            target_data_weekly: "mrr_churn_rate_weekly",
            target_data_daily: "mrr_churn_rate_daily",
  
            target_detail_data_name: "mrr_churn_rate_detail",
            target_detail_data_monthly: "mrr_churn_rate_detail_monthly",
            target_detail_data_weekly: "mrr_churn_rate_detail_weekly",
            target_detail_data_daily: "mrr_churn_rate_detail_daily"
          };
        case "/metrics/nrr":
          return {
            ...state,
            target_data_name: "nrr",
            target_data_monthly: "nrr_monthly",
            target_data_weekly: "nrr_weekly",
            target_data_daily: "nrr_daily",

            target_detail_data_name: "nrr_detail",
            target_detail_data_monthly: "nrr_detail_monthly",
            target_detail_data_weekly: "nrr_detail_weekly",
            target_detail_data_daily: "nrr_detail_daily"
          };
        case "/metrics/grr":
          return {
            ...state,
            target_data_name: "grr",
            target_data_monthly: "grr_monthly",
            target_data_weekly: "grr_weekly",
            target_data_daily: "grr_daily",

            target_detail_data_name: "grr_detail",
            target_detail_data_monthly: "grr_detail_monthly",
            target_detail_data_weekly: "grr_detail_weekly",
            target_detail_data_daily: "grr_detail_daily"
          };
        case "/metrics/quick-ratio":
          return {
            ...state,
            target_data_name: "quick_ratio",
            target_data_monthly: "quick_ratio_monthly",
            target_data_weekly: "quick_ratio_weekly",
            target_data_daily: "quick_ratio_daily",

            target_detail_data_name: "quick_ratio_detail",
            target_detail_data_monthly: "quick_ratio_detail_monthly",
            target_detail_data_weekly: "quick_ratio_detail_weekly",
            target_detail_data_daily: "quick_ratio_detail_daily"
          };
        case "/metrics/acv-cumulative":
          return {
            ...state,
            target_data_name: "acv_cumulative",
            target_data_monthly: "acv_cumulative_monthly",
            target_data_weekly: "acv_cumulative_weekly",
            target_data_daily: "acv_cumulative_daily",

            target_detail_data_name: "acv_cumulative_detail",
            target_detail_data_monthly: "acv_cumulative_detail_monthly",
            target_detail_data_weekly: "acv_cumulative_detail_weekly",
            target_detail_data_daily: "acv_cumulative_detail_daily"
          };
        case "/metrics/acv-new":
          return {
            ...state,
            target_data_name: "acv_new",
            target_data_monthly: "acv_new_monthly",
            target_data_weekly: "acv_new_weekly",
            target_data_daily: "acv_new_daily",

            target_detail_data_name: "acv_new_detail",
            target_detail_data_monthly: "acv_new_detail_monthly",
            target_detail_data_weekly: "acv_new_detail_weekly",
            target_detail_data_daily: "acv_new_detail_daily"
          };
        case "/metrics/conversion_rate":
          return {
            ...state,
            target_data_name: "conversion_rate",
            target_data_monthly: "conversion_rate_monthly",
            target_data_weekly: "conversion_rate_weekly",
            target_data_daily: "conversion_rate_daily",

            target_detail_data_name: "conversion_rate_detail",
            target_detail_data_monthly: "conversion_rate_detail_monthly",
            target_detail_data_weekly: "conversion_rate_detail_weekly",
            target_detail_data_daily: "conversion_rate_detail_daily"
          };
        default:
          return {
            ...state,
            target_data_name: "revenue",
            target_data_monthly: "revenue_monthly",
            target_data_weekly: "revenue_weekly",
            target_data_daily: "revenue_daily",
            target_detail_data_name: "revenue_detail",
            target_detail_data_monthly: "revenue_detail_monthly",
            target_detail_data_weekly: "revenue_detail_weekly",
            target_detail_data_daily: "revenue_detail_daily"
          };
      }

    case TOGGLE_FORECAST_FUNNEL_TABLE:
      console.log("YO");
      return {
        ...state,
        displayed_forecast_funnel_raws: {
          ...state.displayed_forecast_funnel_raws,
          [action.target_raw]: !action.target_raw_is_open
        }
      };

    case SET_DISPLAYED_FORECAST_FUNNEL_RAWS:
      let initial_set_dispalyed_forecast_funnel_raws: {} =
        state.displayed_forecast_funnel_raws;
      console.log(
        "action.funnel_master_list in 431",
        action.funnel_master_list
      );
      action.funnel_master_list.forEach((funnel_item: any) => {
        initial_set_dispalyed_forecast_funnel_raws = {
          ...initial_set_dispalyed_forecast_funnel_raws,
          [funnel_item.name]: false
        };
      });
      return {
        ...state,
        displayed_forecast_funnel_raws:
          initial_set_dispalyed_forecast_funnel_raws
      };

    default:
      return state;
  }
};

export default sessionsReducer;
