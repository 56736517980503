import { getInquiriesApi } from "../inquiryApi";
import { call, put, takeLatest } from "redux-saga/effects";
import { GET_INQUIRIES_REQUESTED, GET_INQUIRIES_SUCCEEDED, GET_INQUIRIES_FAILED } from "../reducks/inquiries/types";

function* getInquiries(action: any) {
  try {
    const result = yield call(getInquiriesApi);
    yield put({ type: GET_INQUIRIES_SUCCEEDED, result: result.data });
  } catch (e) {
    yield put({ type: GET_INQUIRIES_FAILED, message: e.message });
  }
}

const dataSagas = [takeLatest(GET_INQUIRIES_REQUESTED, getInquiries)];

export default dataSagas;
