import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { toggleMovingAverageGraphSwitch } from "../../../reducks/sessions/actions";

//  lib components
import {
  Collapse,
  IconButton,
  TableBody,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";

//  styled components
import {
  StyledTable,
  StyledCell,
  StickyCell,
  StickerHeadCell,
  StickyRow,
  StyledTableRow,
  Wrapper,
  SvgImage,
  CaretSvgImage,
  StyledButton,
  CellText,
  CellContainer
} from "./styles";

//  modules
import { generateTableData } from "./modules";

//  types
import { TargetData, FunnelData, Prefix, GraphData } from "./types";

//  assest
import UpArrow from "../../../images/icons/reached_arrow.svg";
import DownArrow from "../../../images/icons/unreached_icon.svg";

interface DraftTableProps {
  dataType: "metrics" | "funnel";
  prefix?: Prefix;
  targetData?: TargetData[];
  funnelData?: FunnelData[];
  graphData?: GraphData[];
}
export const DraftTable: React.FC<DraftTableProps> = props => {
  const { dataType, targetData, funnelData, prefix, graphData } = props;

  //  hooks
  const dispatch = useDispatch();

  //  states
  const [open, setOpen] = React.useState(false);
  const [openMovingAverage, setOpenMovingAverage] = React.useState(false);
  const [breakdownOpen, setBreakdownOpen] = React.useState(false);
  const [averageBreakdownOpen, setAverageBreakdownOpen] = React.useState(false);

  //  hendlers
  const clickedMovingAverageButton = () => {
    dispatch(toggleMovingAverageGraphSwitch(movingAverageGraphSwitch));
  };

  //  modules
  const tableData = generateTableData({
    dataType,
    prefix,
    targetData,
    funnelData,
    graphData
  });
  const movingAverageGraphSwitch = useSelector(
    (state: any) => state.session.moving_average_graph_switch
  );

  return (
    <React.Fragment>
      <Wrapper>
        {/* 【 実績 】 目標・実績・達成率 */}
        <StyledTable>
          {/* Head */}
          <TableHead>
            <TableRow>
              <StickerHeadCell style={{ zIndex: 1 }} />
              {tableData.head.map((val, i) => (
                <StickerHeadCell key={i}>{val.value}</StickerHeadCell>
              ))}
            </TableRow>
          </TableHead>

          {/* Body */}
          <TableBody>
            {/* label */}
            <StickyRow>　</StickyRow>

            {/* 実績 */}
            <StyledTableRow>
              <StickyCell style={{ fontWeight: "bolder" }}>
                <CellContainer>
                  <CellText style={{ fontWeight: "bold" }}>実績</CellText>
                  {tableData.body.breakdown && (
                    <IconButton
                      size="small"
                      onClick={() => setBreakdownOpen(!breakdownOpen)}
                    >
                      <CaretSvgImage open={breakdownOpen} />
                    </IconButton>
                  )}
                </CellContainer>
              </StickyCell>
              {tableData.body.actual.map((val, i) => {
                return (
                  <StyledCell key={i} style={{ fontWeight: "bolder" }}>
                    {val.label}
                  </StyledCell>
                );
              })}
            </StyledTableRow>
          </TableBody>
        </StyledTable>

        {/* 内訳 */}
        <Collapse in={breakdownOpen}>
          <StyledTable>
            <TableBody>
              {tableData.body.breakdown?.map((val, i) => (
                <StyledTableRow>
                  <StickyCell indent={1} style={{ borderTop: "none" }}>
                    <CellText>{val.name}</CellText>
                  </StickyCell>
                  {val.data.map((val, i) => (
                    <StyledCell key={i} style={{ borderTop: "none" }}>
                      {val.label}
                    </StyledCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </StyledTable>
        </Collapse>

        {/* 【 実績 】　目標・達成率 */}
        <StyledTable>
          <TableBody>
            {/* 目標 */}
            <StyledTableRow>
              <StickyCell style={{ borderTop: "none" }}>
                <CellText>目標</CellText>
              </StickyCell>
              {tableData.body.target.map((val, i) => (
                <StyledCell key={i} style={{ borderTop: "none" }}>
                  {val.label}
                </StyledCell>
              ))}
            </StyledTableRow>

            {/* 達成率 */}
            <StyledTableRow>
              <StickyCell>
                <CellText>達成率</CellText>
              </StickyCell>
              {tableData.body.achievement.map((val, i) => {
                const up = val.value * 100 >= 100 ? true : false;
                return (
                  <StyledCell key={i} up={up}>
                    <SvgImage src={up ? UpArrow : DownArrow} />
                    {val.label}
                  </StyledCell>
                );
              })}
            </StyledTableRow>
          </TableBody>
        </StyledTable>

        {/* 【 実績 】 差異・昨対比 */}
        <Collapse in={open}>
          <StyledTable>
            <TableBody>
              {/* 差異 */}
              <StyledTableRow>
                <StickyCell style={{ borderTop: "none" }}>
                  <CellText>差異</CellText>
                </StickyCell>

                {tableData.body.difference.map((val, i) => {
                  const up = val.value >= 0 ? true : false;
                  return (
                    <StyledCell key={i} style={{ borderTop: "none" }} up={up}>
                      {val.label}
                    </StyledCell>
                  );
                })}
              </StyledTableRow>

              {/* 昨対比 */}
              <StyledTableRow>
                <StickyCell>
                  <CellText>昨対比</CellText>
                </StickyCell>
                {tableData.body.comparison.map((val, i) => {
                  const up = val.value >= 0 ? true : false;
                  return (
                    <StyledCell key={i} up={up}>
                      {val.label}
                    </StyledCell>
                  );
                })}
              </StyledTableRow>
            </TableBody>
          </StyledTable>
        </Collapse>

        {/* 【 実績 】 詳細の開閉ボタン */}
        <StyledTable>
          {/* Head */}
          <TableHead>
            <TableRow>
              <StickerHeadCell style={{ zIndex: 1 }} />
              {tableData.head.map((val, i) => (
                <StickerHeadCell key={i}></StickerHeadCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {/* label */}
            <StickyRow>
              <StyledButton
                onClick={() => setOpen(!open)}
                style={{
                  justifyContent: "start",
                  minWidth: "unset"
                }}
              >
                詳細
                <CaretSvgImage open={open} />
              </StyledButton>
            </StickyRow>
          </TableBody>
        </StyledTable>

        {/* 【 移動平均 】移動平均ラベル */}
        {dataType === "metrics" && (
          <StyledTable>
            {/* Head */}
            <TableHead>
              <TableRow>
                <StickerHeadCell style={{ zIndex: 1 }} />
                {tableData.head.map((val, i) => (
                  <StickerHeadCell key={i}></StickerHeadCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {/* label */}
              <StickyRow>
                移動平均
                <StyledButton onClick={clickedMovingAverageButton}>
                  {movingAverageGraphSwitch ? "非表示" : "表示"}
                </StyledButton>
              </StickyRow>
            </TableBody>
          </StyledTable>
        )}
        {/* 【 移動平均 】 実績 */}
        {dataType === "metrics" && (
          <Collapse in={movingAverageGraphSwitch}>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <StickerHeadCell />
                  {tableData.head.map((val, i) => (
                    <StickerHeadCell key={i}></StickerHeadCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* 実績 */}
                <StyledTableRow>
                  <StickyCell style={{ fontWeight: "bolder" }}>
                    <CellContainer>
                      <CellText style={{ fontWeight: "bold" }}>実績</CellText>
                      {tableData.body.averageBreakdown && (
                        <IconButton
                          size="small"
                          onClick={() =>
                            setAverageBreakdownOpen(!averageBreakdownOpen)
                          }
                        >
                          <CaretSvgImage open={averageBreakdownOpen} />
                        </IconButton>
                      )}
                    </CellContainer>
                  </StickyCell>
                  {tableData.body.movingActual.map((val, i) => {
                    return (
                      <StyledCell key={i} style={{ fontWeight: "bolder" }}>
                        {val.label}
                      </StyledCell>
                    );
                  })}
                </StyledTableRow>
              </TableBody>
            </StyledTable>

            {/* 内訳 */}
            <Collapse in={averageBreakdownOpen}>
              <StyledTable>
                <TableBody>
                  {tableData.body.averageBreakdown?.map((val, i) => (
                    <StyledTableRow>
                      <StickyCell indent={1} style={{ borderTop: "none" }}>
                        <CellText>{val.name}</CellText>
                      </StickyCell>
                      {val.data.map((val, i) => (
                        <StyledCell
                          key={i}
                          style={{
                            borderTop: "none"
                          }}
                        >
                          {val.label}
                        </StyledCell>
                      ))}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </Collapse>

            {/* 【 移動平均 】 目標・達成率 */}
            <StyledTable>
              <TableBody>
                {/* 目標 */}
                <StyledTableRow>
                  <StickyCell style={{ borderTop: "none" }}>
                    <CellText>目標</CellText>
                  </StickyCell>
                  {tableData.body.movingTarget.map((val, i) => {
                    return (
                      <StyledCell key={i} style={{ borderTop: "none" }}>
                        {val.label}
                      </StyledCell>
                    );
                  })}
                </StyledTableRow>

                {/* 達成率 */}
                <StyledTableRow>
                  <StickyCell>
                    <CellText>達成率</CellText>
                  </StickyCell>
                  {tableData.body.movingAchievement.map((val, i) => {
                    const up = val.value * 100 >= 100 ? true : false;
                    return (
                      <StyledCell key={i} up={up}>
                        <SvgImage src={up ? UpArrow : DownArrow} />
                        {val.label}
                      </StyledCell>
                    );
                  })}
                </StyledTableRow>
              </TableBody>
            </StyledTable>

            {/* 差異・昨対比 */}
            <Collapse in={openMovingAverage}>
              <StyledTable>
                <TableBody>
                  {/* 差異 */}
                  <StyledTableRow>
                    <StickyCell style={{ borderTop: "none" }}>
                      <CellText>差異</CellText>
                    </StickyCell>

                    {tableData.body.movingDifference.map((val, i) => {
                      const up = val.value >= 0 ? true : false;
                      return (
                        <StyledCell
                          key={i}
                          up={up}
                          style={{
                            borderTop: "none"
                          }}
                        >
                          {val.label}
                        </StyledCell>
                      );
                    })}
                  </StyledTableRow>

                  {/* 昨対比 */}
                  {/* <StyledTableRow>
                    <StickyCell>
                      <CellText>昨対比</CellText>
                    </StickyCell>
                    {tableData.body.movingComparison.map((val, i) => {
                      const up = val.value >= 0 ? true : false;
                      return (
                        <StyledCell key={i} up={up}>
                          {val.label}
                        </StyledCell>
                      );
                    })}
                  </StyledTableRow> */}

                  {/* 移行率 */}
                  {tableData.body.movingTransitionRate && (
                    <StyledTableRow>
                      <StickyCell>
                        <CellText>移行率</CellText>
                      </StickyCell>
                      {tableData.body.movingTransitionRate.map((val, i) => {
                        return <StyledCell key={i}>{val.label}</StyledCell>;
                      })}
                    </StyledTableRow>
                  )}
                </TableBody>
              </StyledTable>
            </Collapse>

            {/* 詳細の開閉ボタン */}
            <StyledTable>
              {/* Head */}
              <TableHead>
                <TableRow>
                  <StickerHeadCell style={{ zIndex: 1 }} />
                  {tableData.head.map((val, i) => (
                    <StickerHeadCell key={i}></StickerHeadCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {/* label */}
                <StickyRow>
                  <StyledButton
                    style={{
                      justifyContent: "start",
                      minWidth: "unset"
                    }}
                    onClick={() => setOpenMovingAverage(!openMovingAverage)}
                  >
                    詳細
                    <CaretSvgImage open={open} />
                  </StyledButton>
                </StickyRow>
              </TableBody>
            </StyledTable>
          </Collapse>
        )}
      </Wrapper>
    </React.Fragment>
  );
};
