import { unitSwitcher } from "../../Util/unitSwitcher";
import { FunnelData, TargetData, Prefix, GraphData } from "./types";

export const generateTableData = (params: {
  dataType: "metrics" | "funnel";
  prefix?: Prefix;
  targetData?: TargetData[];
  funnelData?: FunnelData[];
  graphData?: GraphData[];
}): {
	head: { value: string }[];
	body: {
		target: { value: number; label: string }[];
		actual: { value: number; label: string }[];
		achievement: { value: number; label: string }[];
		difference: { value: number; label: string }[];
		comparison: { value: number; label: string }[];
		movingTarget: { value: number; label: string }[];
		movingActual: { value: number; label: string }[];
		movingAchievement: { value: number; label: string }[];
		movingDifference: { value: number; label: string }[];
		movingComparison: { value: number; label: string }[];
		transitionRate?: { value: number; label: string }[];
		movingTransitionRate?: { value: number; label: string }[];
		leadTime?: { value: number; label: string }[];
		breakdown?: {
			name: string;
			data: { value: number; label: string }[];
		}[];
		averageBreakdown?: {
			name: string;
			data: { value: number; label: string }[];
		}[];
	};
} => {
	const { dataType, prefix, targetData, funnelData, graphData } = params;

  //  重要指標の場合
  if (dataType === "metrics" && targetData && prefix) {
    const tableHead =
      targetData.map((val) => {
        const year = new Date(val.result_at.seconds * 1000).getFullYear();
        const month = new Date(val.result_at.seconds * 1000).getMonth() + 1;
        return {
          value: `${year}/${month}`,
        };
      }) || [];

    // 実績 - 目標
    const target =
      targetData.map((val) => {
        const key = `${prefix}_objective` as keyof TargetData;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value, prefix),
        };
      }) || [];

    // 実績 - 実績
    const actual =
      targetData.map((val) => {
        const key = `${prefix}_total` as keyof TargetData;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value, prefix),
        };
      }) || [];

    //  実績 - 達成率
    const achievement =
      targetData.map((val) => {
        const key = `${prefix}_rate` as keyof TargetData;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value, prefix, { isPercent: true }),
        };
      }) || [];

    // 実績 - 差異
    const difference =
      targetData.map((val) => {
        const key = `${prefix}_difference` as keyof TargetData;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value, prefix),
        };
      }) || [];

    // 実績 - 昨対比
    const comparison =
      targetData.map((val) => {
        const key = `${prefix}_last_year_comparison_rate` as keyof TargetData;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value, prefix, { isPercent: true }),
        };
      }) || [];

    // 移動平均 - 目標
    const movingTarget =
      targetData.map((val) => {
        const key = `${prefix}_moving_average_objective` as keyof TargetData;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value, prefix),
        };
      }) || [];

    // 移動平均 - 実績
    const movingActual =
      targetData.map((val) => {
        const key = `${prefix}_moving_average_total` as keyof TargetData;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value, prefix),
        };
      }) || [];

    // 移動平均 - 達成率
    const movingAchievement =
      targetData.map((val) => {
        const key = `${prefix}_moving_average_rate` as keyof TargetData;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value, prefix, { isPercent: true }),
        };
      }) || [];

    // 移動平均 - 差異
    const movingDifference =
      targetData.map((val) => {
        const key = `${prefix}_moving_average_difference` as keyof TargetData;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value, prefix),
        };
      }) || [];

    //  移動平均 - 昨対比
    const movingComparison =
      targetData.map((val) => {
        return { value: 0, label: "No Data" };
      }) || [];

    const breakdown = () => {
      if (prefix === "mrr_total_value") {
        return [
          {
            name: "Existing",
            data: targetData.map((val) => {
              const key: keyof TargetData = "mrr_existing_value_total";
              const value = val[key] || 0;
              return {
                value,
                label: getLabelWithUnit(value, "revenue"),
              };
            }),
          },
          {
            name: "New",
            data: targetData.map((val) => {
              const key: keyof TargetData = "mrr_new_value_total";
              const value = val[key] || 0;
              return {
                value,
                label: getLabelWithUnit(value, "revenue"),
              };
            }),
          },
          {
            name: "Churn",
            data: targetData.map((val) => {
              const key: keyof TargetData = "mrr_churn_value_total";
              const value = val[key] || 0;
              return {
                value,
                label: getLabelWithUnit(value, "revenue"),
              };
            }),
          },
          {
            name: "Expansion",
            data: targetData.map((val) => {
              const key: keyof TargetData = "mrr_expansion_value_total";
              const value = val[key] || 0;
              return {
                value,
                label: getLabelWithUnit(value, "revenue"),
              };
            }),
          },
          {
            name: "Contraction",
            data: targetData.map((val) => {
              const key: keyof TargetData = "mrr_contraction_value_total";
              const value = val[key] || 0;
              return {
                value,
                label: getLabelWithUnit(value, "revenue"),
              };
            }),
          },
          {
            name: "Upselling",
            data: targetData.map((val) => {
              const key: keyof TargetData = "mrr_upselling_value_total";
              const value = val[key] || 0;
              return {
                value,
                label: getLabelWithUnit(value, "revenue"),
              };
            }),
          },
          {
            name: "Crossselling",
            data: targetData.map((val) => {
              const key: keyof TargetData = "mrr_crossselling_value_total";
              const value = val[key] || 0;
              return {
                value,
                label: getLabelWithUnit(value, "revenue"),
              };
            }),
          },
        ];
      } else if (prefix === "grr") {
        return [
          {
            name: "Existing",
            data: targetData.map((val) => {
              const key: keyof TargetData = "mrr_existing_value_total";
              const value = val[key] || 0;
              return {
                value,
                label: getLabelWithUnit(value, "revenue"),
              };
            }),
          },
          {
            name: "Churn",
            data: targetData.map((val) => {
              const key: keyof TargetData = "mrr_churn_value_total";
              const value = val[key] || 0;
              return {
                value,
                label: getLabelWithUnit(value, "revenue"),
              };
            }),
          },
          {
            name: "Contraction",
            data: targetData.map((val) => {
              const key: keyof TargetData = "mrr_contraction_value_total";
              const value = val[key] || 0;
              return {
                value,
                label: getLabelWithUnit(value, "revenue"),
              };
            }),
          },
        ];
      } else if (prefix === "nrr") {
        return [
          {
            name: "Existing",
            data: targetData.map((val) => {
              const key: keyof TargetData = "mrr_existing_value_total";
              const value = val[key] || 0;
              return {
                value,
                label: getLabelWithUnit(value, "revenue"),
              };
            }),
          },
          {
            name: "Churn",
            data: targetData.map((val) => {
              const key: keyof TargetData = "mrr_churn_value_total";
              const value = val[key] || 0;
              return {
                value,
                label: getLabelWithUnit(value, "revenue"),
              };
            }),
          },
          {
            name: "Expansion",
            data: targetData.map((val) => {
              const key: keyof TargetData = "mrr_expansion_value_total";
              const value = val[key] || 0;
              return {
                value,
                label: getLabelWithUnit(value, "revenue"),
              };
            }),
          },
          {
            name: "Contraction",
            data: targetData.map((val) => {
              const key: keyof TargetData = "mrr_contraction_value_total";
              const value = val[key] || 0;
              return {
                value,
                label: getLabelWithUnit(value, "revenue"),
              };
            }),
          },
          {
            name: "Upselling",
            data: targetData.map((val) => {
              const key: keyof TargetData = "mrr_upselling_value_total";
              const value = val[key] || 0;
              return {
                value,
                label: getLabelWithUnit(value, "revenue"),
              };
            }),
          },
          {
            name: "Crossselling",
            data: targetData.map((val) => {
              const key: keyof TargetData = "mrr_crossselling_value_total";
              const value = val[key] || 0;
              return {
                value,
                label: getLabelWithUnit(value, "revenue"),
              };
            }),
          },
        ];
      } else {
        return undefined;
      }
    };

    const averageBreakdown = () => {
      if (prefix === "mrr_total_value") {
        return [
          {
            name: "Existing",
            data: targetData.map((val) => {
              const key: keyof TargetData =
                "mrr_existing_value_moving_average_total";
              const value = val[key] || 0;
              return {
                value,
                label: getLabelWithUnit(value, "revenue"),
              };
            }),
          },
          {
            name: "New",
            data: targetData.map((val) => {
              const key: keyof TargetData =
                "mrr_new_value_moving_average_total";
              const value = val[key] || 0;
              return {
                value,
                label: getLabelWithUnit(value, "revenue"),
              };
            }),
          },
          {
            name: "Churn",
            data: targetData.map((val) => {
              const key: keyof TargetData =
                "mrr_churn_value_moving_average_total";
              const value = val[key] || 0;
              return {
                value,
                label: getLabelWithUnit(value, "revenue"),
              };
            }),
          },
          {
            name: "Expansion",
            data: targetData.map((val) => {
              const key: keyof TargetData =
                "mrr_expansion_value_moving_average_total";
              const value = val[key] || 0;
              return {
                value,
                label: getLabelWithUnit(value, "revenue"),
              };
            }),
          },
          {
            name: "Contraction",
            data: targetData.map((val) => {
              const key: keyof TargetData =
                "mrr_contraction_value_moving_average_total";
              const value = val[key] || 0;
              return {
                value,
                label: getLabelWithUnit(value, "revenue"),
              };
            }),
          },
          {
            name: "Upselling",
            data: targetData.map((val) => {
              const key: keyof TargetData =
                "mrr_upselling_value_moving_average_total";
              const value = val[key] || 0;
              return {
                value,
                label: getLabelWithUnit(value, "revenue"),
              };
            }),
          },
          {
            name: "Crossselling",
            data: targetData.map((val) => {
              const key: keyof TargetData =
                "mrr_crossselling_value_moving_average_total";
              const value = val[key] || 0;
              return {
                value,
                label: getLabelWithUnit(value, "revenue"),
              };
            }),
          },
        ];
      } else if (prefix === "grr") {
        return [
          {
            name: "Existing",
            data: targetData.map((val) => {
              const key: keyof TargetData =
                "mrr_existing_value_moving_average_total";
              const value = val[key] || 0;
              return {
                value,
                label: getLabelWithUnit(value, "revenue"),
              };
            }),
          },
          {
            name: "Churn",
            data: targetData.map((val) => {
              const key: keyof TargetData =
                "mrr_churn_value_moving_average_total";
              const value = val[key] || 0;
              return {
                value,
                label: getLabelWithUnit(value, "revenue"),
              };
            }),
          },
          {
            name: "Contraction",
            data: targetData.map((val) => {
              const key: keyof TargetData =
                "mrr_contraction_value_moving_average_total";
              const value = val[key] || 0;
              return {
                value,
                label: getLabelWithUnit(value, "revenue"),
              };
            }),
          },
        ];
      } else if (prefix === "nrr") {
        return [
          {
            name: "Existing",
            data: targetData.map((val) => {
              const key: keyof TargetData =
                "mrr_existing_value_moving_average_total";
              const value = val[key] || 0;
              return {
                value,
                label: getLabelWithUnit(value, "revenue"),
              };
            }),
          },
          {
            name: "Churn",
            data: targetData.map((val) => {
              const key: keyof TargetData =
                "mrr_churn_value_moving_average_total";
              const value = val[key] || 0;
              return {
                value,
                label: getLabelWithUnit(value, "revenue"),
              };
            }),
          },
          {
            name: "Expansion",
            data: targetData.map((val) => {
              const key: keyof TargetData =
                "mrr_expansion_value_moving_average_total";
              const value = val[key] || 0;
              return {
                value,
                label: getLabelWithUnit(value, "revenue"),
              };
            }),
          },
          {
            name: "Contraction",
            data: targetData.map((val) => {
              const key: keyof TargetData =
                "mrr_contraction_value_moving_average_total";
              const value = val[key] || 0;
              return {
                value,
                label: getLabelWithUnit(value, "revenue"),
              };
            }),
          },
          {
            name: "Upselling",
            data: targetData.map((val) => {
              const key: keyof TargetData =
                "mrr_upselling_value_moving_average_total";
              const value = val[key] || 0;
              return {
                value,
                label: getLabelWithUnit(value, "revenue"),
              };
            }),
          },
          {
            name: "Crossselling",
            data: targetData.map((val) => {
              const key: keyof TargetData =
                "mrr_crossselling_value_moving_average_total";
              const value = val[key] || 0;
              return {
                value,
                label: getLabelWithUnit(value, "revenue"),
              };
            }),
          },
        ];
      } else {
        return undefined;
      }
    };

		return {
			head: tableHead,
			body: {
				target,
				actual,
				achievement,
				comparison,
				difference,
				movingTarget,
				movingActual,
				movingAchievement,
				movingDifference,
				movingComparison,
				breakdown: breakdown(),
				averageBreakdown: averageBreakdown(),
			},
		};
	}

	//  ファネル - Summaryの場合
	else if (dataType === "funnel" && funnelData) {
		const tableHead =
			funnelData.map((val) => {
				return { value: `${val.funnel_name}` };
			}) || [];

    //  実績 - 目標
    const target =
      funnelData.map((val) => {
        const key = "lead_new_count_objective" as keyof FunnelData;
        const value = val[key] as number;
        return {
          value,
          label: `${Number(value.toFixed(0)).toLocaleString()}`,
        };
      }) || [];

    //  実績 - 実績
    const actual =
      funnelData.map((val) => {
        const key = "lead_new_count_total" as keyof FunnelData;
        const value = val[key] as number;
        return {
          value,
          label: `${Number(value.toFixed(0)).toLocaleString()}`,
        };
      }) || [];

    //  実績 - 達成率
    const achievement =
      funnelData.map((val) => {
        const key = "lead_new_count_rate" as keyof FunnelData;
        const value = val[key] as number;
        return {
          value,
          label: (value * 100)?.toFixed(1) + "%",
        };
      }) || [];

    // 実績 - 差異
    const difference =
      funnelData.map((val) => {
        const key = "lead_new_count_difference" as keyof FunnelData;
        const value = val[key] as number;
        return {
          value,
          label: `${value.toLocaleString()}`,
        };
      }) || [];

    //  実績 - 昨対比
    const comparison =
      funnelData.map((val) => {
        const key =
          "lead_new_count_last_year_comparison_rate" as keyof FunnelData;
        const value = val[key] as number;
        return {
          value,
          label: (value * 100)?.toFixed(1) + "%",
        };
      }) || [];

		/** 実績 - 移行率 */
		const transitionRate = funnelData.map((val) => {
			const key: keyof FunnelData = "lead_transition_rate_total";
			const value = val[key] || 0;
			return {
				value,
				label: (value * 100 || 0).toFixed(1) + "%",
			};
		});

		//  実績 - リードタイム
		const leadTime = funnelData.map((val) => {
			const key: keyof FunnelData = "lead_time_total";
			const value = val[key] || 0;
			return {
				value,
				label: `${value.toFixed(1)} days`,
			};
		});

    // 移動平均 - 目標
    const movingTarget =
      funnelData.map((val) => {
        const key = "lead_new_count_objective" as keyof FunnelData;
        const value = val[key] as number;
        return {
          value,
          label: `${Number(value.toFixed(0)).toLocaleString()}`,
        };
      }) || [];

    // 移動平均 - 実績
    const movingActual =
      funnelData.map((val) => {
        const key = "lead_new_count_moving_average_total" as keyof FunnelData;
        const value = val[key] as number;
        return {
          value,
          label: `${Number(value.toFixed(0)).toLocaleString()}`,
        };
      }) || [];

    // 移動平均 - 達成率
    const movingAchievement =
      funnelData.map((val) => {
        const key = "lead_new_count_moving_average_rate" as keyof FunnelData;
        const value = val[key] as number;
        return {
          value,
          label: (value * 100)?.toFixed(1) + "%",
        };
      }) || [];

    // 移動平均 - 差異
    const movingDifference =
      funnelData.map((val) => {
        const key =
          "lead_new_count_moving_average_difference" as keyof FunnelData;
        const value = val[key] as number;
        return {
          value,
          label: `${value.toLocaleString()}`,
        };
      }) || [];

		//  移動平均 - 昨対比
		const movingTransitionRate =
			funnelData.map((val) => {
				const key =
					"lead_transition_rate_moving_average_objective" as keyof FunnelData;
				const value = val[key] as number;
				return {
					value,
					label: (value * 100 || 0).toFixed(1) + "%",
				};
			}) || [];

		//  移動平均 - 昨対比
		const movingComparison =
			funnelData.map((val) => {
				const key =
					"lead_new_count_moving_average_last_year_comparison_rate" as keyof FunnelData;
				const value = val[key] as number;
				return {
					value,
					label: (value * 100)?.toFixed(1) + "%",
				};
			}) || [];

		return {
			head: tableHead,
			body: {
				target,
				actual,
				achievement,
				comparison,
				difference,
				transitionRate,
				leadTime,
				movingTarget,
				movingActual,
				movingAchievement,
				movingDifference,
				movingComparison,
				movingTransitionRate,
			},
		};
	}

	//  ファネル - リード獲得 , アポ獲得 , 商談化 , 受注
	else if (dataType === "funnel" && graphData) {
		const tableHead =
			graphData.map((val) => {
				const year = new Date(val.date.seconds * 1000).getFullYear();
				const month = new Date(val.date.seconds * 1000).getMonth() + 1;
				return {
					value: `${year}/${month}`,
				};
			}) || [];

    // 実績 - 目標
    const target =
      graphData.map((val) => {
        const key = `lead_new_count_objective` as keyof GraphData;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value, prefix),
        };
      }) || [];

    // 実績 - 実績
    const actual =
      graphData.map((val) => {
        const key = `lead_new_count_total` as keyof GraphData;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value, prefix),
        };
      }) || [];

    // 実績 - 達成率
    const achievement =
      graphData.map((val) => {
        const key = `lead_new_count_rate` as keyof GraphData;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value, prefix, { isPercent: true }),
        };
      }) || [];

    // 実績 - 差異
    const difference =
      graphData.map((val) => {
        const key = `lead_new_count_difference` as keyof GraphData;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value, prefix),
        };
      }) || [];

    // 実績 - 昨対比
    const comparison =
      graphData.map((val) => {
        const key =
          `lead_new_count_last_year_comparison_rate` as keyof GraphData;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value, prefix, { isPercent: true }),
        };
      }) || [];

    // 移動平均 - 目標
    const movingTarget =
      graphData.map((val) => {
        const key = `lead_new_count_moving_average_objective`;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value, prefix),
        };
      }) || [];

    // 移動平均 - 実績
    const movingActual =
      graphData.map((val) => {
        const key = `lead_new_count_moving_average_total`;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value, prefix),
        };
      }) || [];

    // 移動平均 - 達成率
    const movingAchievement =
      graphData.map((val) => {
        const key = `lead_new_count_moving_average_rate`;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value, prefix, { isPercent: true }),
        };
      }) || [];

    // 移動平均 - 差異
    const movingDifference =
      graphData.map((val) => {
        const key = `lead_new_count_moving_average_difference`;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value),
        };
      }) || [];

    //  移動平均 - 昨対比
    const movingComparison =
      graphData.map((val) => {
        return { value: 0, label: "No Data" };
      }) || [];

    return {
      head: tableHead,
      body: {
        target,
        actual,
        achievement,
        difference,
        comparison,
        movingTarget,
        movingActual,
        movingAchievement,
        movingDifference,
        movingComparison,
      },
    };
  }
  //  ファネル - Client Churned , Account Churned , Revenue Churned , New Customer , Active Customer
  else if (dataType === "funnel" && targetData) {
    const tableHead =
      targetData.map((val) => {
        const year = new Date(val.result_at.seconds * 1000).getFullYear();
        const month = new Date(val.result_at.seconds * 1000).getMonth() + 1;
        return {
          value: `${year}/${month}`,
        };
      }) || [];

    // 実績 - 目標
    const target =
      targetData.map((val) => {
        const key = `${prefix}_objective` as keyof TargetData;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value, prefix),
        };
      }) || [];

    // 実績 - 実績
    const actual =
      targetData.map((val) => {
        const key = `${prefix}_total` as keyof TargetData;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value, prefix),
        };
      }) || [];

    //  実績 - 達成率
    const achievement =
      targetData.map((val) => {
        const key = `${prefix}_rate` as keyof TargetData;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value, prefix, { isPercent: true }),
        };
      }) || [];

    // 実績 - 差異
    const difference =
      targetData.map((val) => {
        const key = `${prefix}_difference` as keyof TargetData;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value, prefix),
        };
      }) || [];

    // 実績 - 昨対比
    const comparison =
      targetData.map((val) => {
        const key = `${prefix}_last_year_comparison_rate` as keyof TargetData;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value, prefix, { isPercent: true }),
        };
      }) || [];

    // 移動平均 - 目標
    const movingTarget =
      targetData.map((val) => {
        const key = `${prefix}_moving_average_objective` as keyof TargetData;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value, prefix),
        };
      }) || [];

    // 移動平均 - 実績
    const movingActual =
      targetData.map((val) => {
        const key = `${prefix}_moving_average_total` as keyof TargetData;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value, prefix),
        };
      }) || [];

    // 移動平均 - 達成率
    const movingAchievement =
      targetData.map((val) => {
        const key = `${prefix}_moving_average_rate` as keyof TargetData;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value, prefix, { isPercent: true }),
        };
      }) || [];

    // 移動平均 - 差異
    const movingDifference =
      targetData.map((val) => {
        const key = `${prefix}_moving_average_difference` as keyof TargetData;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value),
        };
      }) || [];

    //  移動平均 - 昨対比
    const movingComparison =
      targetData.map((val) => {
        return { value: 0, label: "No Data" };
      }) || [];

    return {
      head: tableHead,
      body: {
        target,
        actual,
        achievement,
        comparison,
        movingTarget,
        movingActual,
        movingAchievement,
        movingDifference,
        movingComparison,
        difference,
      },
    };
  }
  else {
    return {
      head: [],
      body: {
        target: [],
        actual: [],
        achievement: [],
        difference: [],
        comparison: [],
        movingTarget: [],
        movingActual: [],
        movingAchievement: [],
        movingDifference: [],
        movingComparison: [],
      },
    };
  }
};

const getLabelWithUnit = (
  val: number,
  prefix?: Prefix,
  options?: { isPercent?: boolean }
): string => {
  const isPercent = options?.isPercent;
  if (isPercent) return (val * 100)?.toFixed(1) + "%";

  switch (prefix) {
    case "revenue":
    case "mrr_total_value":
    case "cac":
    case "ltv":
    case "arpu":
    case "acv_cumulative":
    case "acv_new":
    case "cpa":
    case "revenue_churned":
      return unitSwitcher(val, "revenue_churned");
    case "unit_economics":
    case "quick_ratio":
      return unitSwitcher(val, "quick_ratio");
    case "payback_period":
      return unitSwitcher(val, "payback_period");
    case "churn_rate":
    case "mrr_churn_rate":
    case "grr":
    case "nrr":
      return unitSwitcher(val, "rate");
    case "client_churned":
    case "new_customer":
    case "active_customer":
      return unitSwitcher(val, "active_customer");

    default:
      return unitSwitcher(val, "");
  }
};
