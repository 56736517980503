export const dateFormatter = (resultSpan: string, result_at:number) => {
  if (resultSpan !== "monthly") {
    return (
      new Date(result_at).getFullYear() + "/" + (new Date(result_at).getMonth() + 1) + "/" + new Date(result_at).getDate()
      ) 
  } else {
    return new Date(result_at).getFullYear() + "/" + (new Date(result_at).getMonth() + 1)
  }
}

export const tooltipDateFormatter = (resultSpan: string, result_at:number) => {
  if (resultSpan !== "monthly") {
    return (
      new Date(result_at * 1000).getFullYear() + "/" + (new Date(result_at * 1000).getMonth() + 1) + "/" + new Date(result_at * 1000).getDate()
      ) 
  } else {
    return new Date(result_at * 1000).getFullYear() + "/" + (new Date(result_at * 1000).getMonth() + 1)
  }
}
