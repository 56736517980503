import { SET_TARGET_FORECAST_REVENUE_PROFIT_ARRAY, SET_TARGET_FORECAST_FUNNEL_ARRAY, SET_TARGET_FORECAST_METRICS_ARRAY, GET_TARGET_FORECAST_REVENUE_PROFIT_DETAIL_REQUEST,UPDATE_FORECAST_PL_DATA, UPDATE_FORECAST_METRICS_DATA, UPDATE_FORECAST_FUNNEL_DATA, GET_REVENUE_DETAIL_REQUEST, GET_RESULTS_FOR_TARGET_FORECAST_REQUEST, UPDATE_NEW_FORECAST_SIMULATED_RESULT, SET_FORECAST_FILTER_STATUS, SET_FORECAST_FILTER, RESET_FORECAST_FILTER, GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_FORECASTS, GET_RESULTS_FOR_TARGET_FORECAST_CUSTOM_REQUEST, GET_INTEGRATED_ARRAY_FORECAST_REQUEST, SET_PL_GROSS_PROFIT_OBJECTIVE, SET_PL_OPERATING_PROFIT_OBJECTIVE, UPDATE_REVENUE_DETAIL_DATA, UPDATE_REVENUE_TOTAL_DATA, UPDATE_UNIT_COST_CAT_2_DETAIL_DATA, UPDATE_SVG_COST_CAT_2_DETAIL_DATA, UPDATE_UNIT_COST_CAT_3_DETAIL_DATA, UPDATE_SVG_COST_CAT_3_DETAIL_DATA ,UPDATE_UNIT_COST_TOTAL_DATA, UPDATE_SVG_COST_TOTAL_DATA, UPDATE_UNIT_COST_CAT_2_TOTAL_DATA, UPDATE_SVG_COST_CAT_2_TOTAL_DATA, UPDATE_PL_GROSS_PROFIT_TOTAL_FORECASTS, UPDATE_PL_OPERATING_PROFIT_TOTAL_FORECASTS, REMOVE_FUTURE_DATA_FROM_INTEGRATED_DATA, UPDATE_REVENUE_TOTAL_FORECAST_DATA, UPDATE_PL_GROSS_PROFIT_TOTAL, UPDATE_PL_OPERATING_PROFIT_TOTAL, HANDLE_UPDATE_REVENUE_DETAIL_TOTAL, HANDLE_UPDATE_COST_CAT_2, HANDLE_UPDATE_COST_CAT_3} from "./types";
// import { GET_REVENUE_DETAIL_REQUEST, GET_RESULTS_REQUEST_FOR_NEW_FORECAST, UPDATE_NEW_FORECAST_SIMULATED_RESULT, SET_FORECAST_FILTER_STATUS, SET_FORECAST_FILTER, RESET_FORECAST_FILTER, GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_FORECASTS, GET_RESULTS_REQUEST_FOR_NEW_FORECAST_FOR_CUSTOM } from './types';

export const setTargetForecastRevenueProfitArray = (raw_data_array: any) => ({
  type: SET_TARGET_FORECAST_REVENUE_PROFIT_ARRAY,
  raw_data_array
});

export const setTargetForecastFunnelArray = (raw_data_array: any, funnel_names: any) => ({
  type: SET_TARGET_FORECAST_FUNNEL_ARRAY,
  raw_data_array,
  funnel_names
});

export const setTargetForecastMetricsArray = (raw_data_array: any) => ({
  type: SET_TARGET_FORECAST_METRICS_ARRAY,
  raw_data_array
});

export const getTargetForecastRevenueProfitDetailRequest = (company_id: string, selected_data_config_data_type: string, selected_data_config_data_span: string, custom_span_start_time: any, custom_span_end_time: any, raw_data_array: any) => ({
  type: GET_TARGET_FORECAST_REVENUE_PROFIT_DETAIL_REQUEST,
  company_id,
  selected_data_config_data_type,
  selected_data_config_data_span,
  custom_span_start_time,
  custom_span_end_time,
  raw_data_array
});


// PL内のサマリー、詳細データを取得
export const getIntegratedArray = (raw_data_array: any, company_id: string, selected_data_config_data_type: string, selected_data_config_data_span: string, custom_span_start_time: any, custom_span_end_time: any) => ({
  type: GET_INTEGRATED_ARRAY_FORECAST_REQUEST,
  raw_data_array,
  company_id,
  selected_data_config_data_type,
  selected_data_config_data_span,
  custom_span_start_time,
  custom_span_end_time,
})


export const updateForecastPLData = (handleSimulatedObject: any) => ({
  type: UPDATE_FORECAST_PL_DATA,
  handleSimulatedObject
})

export const updateForecastMetricsData = (handleSimulatedObject: any) => ({
  type: UPDATE_FORECAST_METRICS_DATA,
  handleSimulatedObject
})

export const updateForecastFunnelData = (handleSimulatedObject: any) => ({
  type: UPDATE_FORECAST_FUNNEL_DATA,
  handleSimulatedObject
})

export const updateRevenueDetailData = (updated_data: any, result_at_seconds: any, index: number, product_name: string,) => {
  return {
    type: UPDATE_REVENUE_DETAIL_DATA,
    updated_data,
    result_at_seconds,
    index,
    product_name
  }
}

export const updateRevenueTotalData = (new_revenue_total: number, index:number, result_at_seconds: any) => {
  return {
    type: UPDATE_REVENUE_TOTAL_DATA,
    new_revenue_total,
    index,
    result_at_seconds
  }
}

export const updateRevenueTotalForecastData = (new_revenue_total: number, index:number, result_at_seconds: any) => {
  return {
    type: UPDATE_REVENUE_TOTAL_FORECAST_DATA,
    new_revenue_total,
    index,
    result_at_seconds
  }
}

export const updateUnitCostCat2DetailData = (updated_data: any, result_at_seconds: any, cat_2_index: number, cat_2_name: string ) => {
  return {
    type: UPDATE_UNIT_COST_CAT_2_DETAIL_DATA,
    updated_data,
    result_at_seconds,
    cat_2_index,
    cat_2_name
  }
}

export const updateSvgCostCat2DetailData = (updated_data: any, result_at_seconds: any, cat_2_index: number, cat_2_name: string ) => {
  return {
    type: UPDATE_SVG_COST_CAT_2_DETAIL_DATA,
    updated_data,
    result_at_seconds,
    cat_2_index,
    cat_2_name
  }
}

export const updateUnitCostCat3DetailData = (updated_data: any, result_at_seconds: any, cat_2_name: string, cat_3_name:string ) => {
  return {
    type: UPDATE_UNIT_COST_CAT_3_DETAIL_DATA,
    updated_data,
    result_at_seconds,
    cat_2_name,
    cat_3_name
  }
}

export const updateSvgCostCat3DetailData = (updated_data: any, result_at_seconds: any, cat_2_name: string, cat_3_name:string ) => {
  return {
    type: UPDATE_SVG_COST_CAT_3_DETAIL_DATA,
    updated_data,
    result_at_seconds,
    cat_2_name,
    cat_3_name
  }
}

export const updateUnitCostTotalData = (result_at_seconds:number) => {
  return {
    type: UPDATE_UNIT_COST_TOTAL_DATA,
    result_at_seconds
  }
}

export const updateSvgCostTotalData = (result_at_seconds:number) => {
  return {
    type: UPDATE_SVG_COST_TOTAL_DATA,
    result_at_seconds
  }
}

export const updateUnitCostCat2TotalData = (new_cat_2_cost_total: any, result_at_seconds:number, cat_2_name: string) => {
  return {
    type: UPDATE_UNIT_COST_CAT_2_TOTAL_DATA,
    new_cat_2_cost_total,
    result_at_seconds,
    cat_2_name
  }
}

export const updateSvgCostCat2TotalData = (new_cat_2_cost_total: any, result_at_seconds:number, cat_2_name: string) => {
  return {
    type: UPDATE_SVG_COST_CAT_2_TOTAL_DATA,
    new_cat_2_cost_total,
    result_at_seconds,
    cat_2_name
  }
}

export const setPLGrossProfitTotalForecasts = (calculated_gross_profit: any, result_at_seconds: number) => {
  return {
    type: UPDATE_PL_GROSS_PROFIT_TOTAL_FORECASTS,
    calculated_gross_profit,
    result_at_seconds
  }
}

export const setPLOperatingProfitTotalForecasts = (calculated_operating_profit: any, result_at_seconds: number) => {
  return {
    type: UPDATE_PL_OPERATING_PROFIT_TOTAL_FORECASTS,
    calculated_operating_profit,
    result_at_seconds
  }
}

export const setPLGrossProfitTotal = (calculated_gross_profit: any, result_at_seconds: number) => {
  return {
    type: UPDATE_PL_GROSS_PROFIT_TOTAL,
    calculated_gross_profit,
    result_at_seconds
  }
}

export const setPLOperatingProfitTotal = (calculated_operating_profit: any, result_at_seconds: number) => {
  return {
    type: UPDATE_PL_OPERATING_PROFIT_TOTAL,
    calculated_operating_profit,
    result_at_seconds
  }
}


//このアクション一つで各コストカテゴリーのtotalとそれに伴って変動する売上純利益と営業利益を書き換えたい。
//コストカテゴリ2が編集された時
export const handleUpdateCostCat2 = (handleSimulatedObject: any) => {
  return {
    type: HANDLE_UPDATE_COST_CAT_2,
    handleSimulatedObject
  }
}

//コストカテゴリ3が編集された時
export const handleUpdateCostCat3 = (handleSimulatedObject: any) => {
  return {
    type: HANDLE_UPDATE_COST_CAT_3,
    handleSimulatedObject
  }
}

// newForecast
export const getRevenueDetailRequest = (resultSpan: string, from: any, to: any, company_id: string) => {
  return {
    type: GET_REVENUE_DETAIL_REQUEST,
    resultSpan,
    from,
    to,
    company_id,
  };
};

export const getResultsForTargetForecastRequest = (
  company_id: string,
  forecast_base_span: number,
  selected_data_config_data_span: string,
  selected_data_config_data_type: string,
  custom_span_start_time: any,
  custom_span_end_time: any,
  forecast_custom_span_start_time: any,
  forecast_custom_span_end_time: any
) => {
  return {
    type: GET_RESULTS_FOR_TARGET_FORECAST_REQUEST,
    company_id,
    forecast_base_span,
    selected_data_config_data_span,
    selected_data_config_data_type,
    custom_span_start_time,
    custom_span_end_time,
    forecast_custom_span_start_time,
    forecast_custom_span_end_time
  };
};

export const getResultsForTargetForecastCustomRequest = (
  company_id: string,
  forecast_base_span: number,
  selected_data_config_data_span: string,
  selected_data_config_data_type: string,
  custom_span_start_time: Date,
  custom_span_end_time: Date,
  forecast_custom_span_start_time: number,
  forecast_custom_span_end_time: number,
) => {
  return {
    type: GET_RESULTS_FOR_TARGET_FORECAST_CUSTOM_REQUEST,
    company_id,
    forecast_base_span,
    selected_data_config_data_span,
    selected_data_config_data_type,
    custom_span_start_time,
    custom_span_end_time,
    forecast_custom_span_start_time,
    forecast_custom_span_end_time
  };
};

export const updateSimulatedNewForecastResult = (updated_object: any, resultSpan: string, updated_item_index: number) => {
  return {
    type: UPDATE_NEW_FORECAST_SIMULATED_RESULT,
    updated_object,
    resultSpan,
    updated_item_index,
  };
};
export const setForecastFilter = (forecastFilter: boolean) => {
  return {
    type: SET_FORECAST_FILTER,
    forecastFilter,
  };
};
export const resetForecastFilter = (forecastFilter: boolean) => {
  return {
    type: RESET_FORECAST_FILTER,
    forecastFilter,
  };
};
export const setForecastFilterStatus = (
  forecast_filter_status: string
) => {
  return {
    type: SET_FORECAST_FILTER_STATUS,
    forecast_filter_status
  };
};

export const getResultsRequestForSpanComparisonForecasts = (resultSpan: string, company_id: string, startDate: any, endDate: any, spanOrder: string) => {
  return {
    type: GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_FORECASTS,
    resultSpan,
    company_id,
    startDate,
    endDate,
    spanOrder,
  };
};

export const setPLGrossProfitObjective = (calculated_gross_profit: any, index: number) => {
  return {
    type: SET_PL_GROSS_PROFIT_OBJECTIVE,
    calculated_gross_profit,
    index
  }
}
export const setPLOperatingProfitObjective = (calculated_operating_profit: any, index: number) => {
  return {
    type: SET_PL_OPERATING_PROFIT_OBJECTIVE,
    calculated_operating_profit,
    index
  }
}

export const removeFutureDataFromIntegratedData = (integrated_array: any) => {
  return {
    type: REMOVE_FUTURE_DATA_FROM_INTEGRATED_DATA,
    integrated_array
  }
}