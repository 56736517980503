import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import DateSpanSelectorStart from "./DateSpanSelectorStart";
import DateSpanSelectorEnd from "./DateSpanSelectorEnd";
import FutureDateSpanSelectorStart from "./FutureDateSpanSelectorStart";
import FutureDateSpanSelectorEnd from "./FutureDateSpanSelectorEnd";
import ForecastSpanSelectorSection from "./ForecastSpanSelectorSection";
import DefaultSpanSelectorSection from "./DefaultSpanSelectorSection";
import { useForm } from "react-hook-form";
import { getResultsRequest } from "../../reducks/results/actions";
import { getCommentsRequest } from "../../reducks/comments/actions";
import dateArrow from "../../images/icons/arrow_down.svg";
import expandIcon from "../../images/icons/expand.svg";
import shrinkIcon from "../../images/icons/shrink.svg";
import { setFilter } from "../../reducks/filter/actions";
import { showLoading } from "../../reducks/loading/actions";
import { toggleExpander } from "../../reducks/sessions/actions";
import { selectSpan } from "../../reducks/targetData/actions";
import { getRawDataThisMonthRequest, getRawDataLastMonthRequest, getRawDataLast6MonthRequest, getRawDataLast12MonthRequest, getRawDataCustomSpanRequest, getRawDataWithSpanAndTypeAndTimeRequest } from "../../reducks/rawData/actions";
import { startTargetDataSequence } from "../../reducks/targetData/actions";
import { getResultsRequestForNewForecast, getResultsRequestForNewForecastForCustom } from '../../reducks/newForecast/actions';
import { withRouter, RouteComponentProps } from "react-router";
import {get_dataUpdated} from "../../sagas/rawDataSagas/getDataUpdated";

const PageHeaderRaw = (props: any) => {
  const page_title = props.page_title;
  const company_id = useSelector((state: any) => state.user.company_id);
  const data_type = useSelector((state: any) => state.raw_data.selected_data_config.data_type)
  const dispatch = useDispatch();

  const { register } = useForm();


  // データの範囲：this_month | last_month | last_6_months | last_12_months | custom
  const selected_data_config_data_span = useSelector((state: any) => state.raw_data.selected_data_config.data_span);

  // データの種類：月次・週次・日次
  const selected_data_config_data_type = useSelector((state: any) => state.raw_data.selected_data_config.data_type);

  // データの範囲が「カスタム」指定だった場合の開始と終了時点
  const selected_data_custom_span_start_time = useSelector((state: any) => state.raw_data.selected_data_config.custom_span_start_time);
  const selected_data_custom_span_end_time = useSelector((state: any) => state.raw_data.selected_data_config.custom_span_end_time);
  const forecast_filter_status = useSelector((state: any) => state.target_forecast.forecast_filter_status);

  // URLを取得
  const location = useLocation();
  const pathname = location.pathname

  const filter_name = useSelector((state: any) => state.filter);
  const target_data_name = useSelector((state: any) => state.target_data.target_data_name);

  // 月次・週次・日次switcherの変更検知
  const handleResultSpanSwitch = (value: any) => {
    // 現在設定されているデータの範囲に合わせてデータ取得
    // dispatch(getRawDataWithSpanAndTypeAndTimeRequest(
    //   company_id,
    //   e.target.value,
    //   selected_data_config_data_span,
    //   selected_data_custom_span_start_time,
    //   selected_data_custom_span_end_time,
    //   pathname
    // ));

    switch (selected_data_config_data_span) {
      case "this_month":

        // dispatch(getRawDataThisMonthRequest(company_id, selected_data_config_data_type, pathname));
        if(filter_name !== '') {
          dispatch(showLoading());
          dispatch(getRawDataThisMonthRequest(company_id, value, pathname, filter_name, 1, target_data_name));
        } else {
          dispatch(showLoading());
          dispatch(getRawDataThisMonthRequest(company_id, value, pathname, filter_name, 1));
        }
        break;

      case "last_month":
        // dispatch(getRawDataLastMonthRequest(company_id, e.target.value, pathname));
        if(filter_name !== '') {
          dispatch(showLoading());
          dispatch(getRawDataLastMonthRequest(company_id, value, pathname, filter_name, 3, target_data_name));
        } else {
          dispatch(showLoading());
          dispatch(getRawDataLastMonthRequest(company_id,value, pathname, filter_name, 3));
        }
        break;

      case "last_6_months":
        if(filter_name !== '') {
          dispatch(showLoading());
          dispatch(getRawDataLast6MonthRequest(company_id, value, pathname, filter_name, 6, target_data_name));
        } else {
          dispatch(showLoading());
          dispatch(getRawDataLast6MonthRequest(company_id, value, pathname, filter_name, 6));
        }
        break;

      case "last_12_months":
        // dispatch(getRawDataLast12MonthRequest(company_id, e.target.value, pathname));
        if(filter_name !== '') {
          dispatch(showLoading());
          dispatch(getRawDataLast12MonthRequest(company_id, value, pathname, filter_name, 12, target_data_name));
        } else {
          dispatch(showLoading());
          dispatch(getRawDataLast12MonthRequest(company_id,value, pathname, filter_name, 12));
        }
        break;

      case "custom":
        if(filter_name !== '') {
          dispatch(showLoading());
          dispatch(getRawDataCustomSpanRequest(company_id, value, selected_data_custom_span_start_time, selected_data_custom_span_end_time, pathname, filter_name, target_data_name));
        } else {
          dispatch(showLoading());
          dispatch(getRawDataCustomSpanRequest(company_id, value, selected_data_custom_span_start_time, selected_data_custom_span_end_time, pathname, filter_name));
        }

        break;

      default:
        break;
    }

    dispatch(showLoading())



    // if (e.target.value === "monthly") {
    //   dispatch(showLoading());
    //   // dispatch(getResultsRequest(e.target.value, company_id, company.config.daily_from_date, company.config.daily_to_date))
    //   dispatch(getResultsRequest(e.target.value, company_id, new Date(monthly_from_month.seconds * 1000), new Date(monthly_to_month.seconds * 1000)));
    //   dispatch(getCommentsRequest(company_id, page_path, "monthly", new Date(monthly_from_month.seconds * 1000), new Date(monthly_to_month.seconds * 1000)));
    //   if (activatedFilter !== "") {
    //     dispatch(setFilter(""));
    //   }
    // } else if (e.target.value === "weekly") {
    //   dispatch(showLoading());
    //   // dispatch(getResultsRequest(e.target.value, company_id, company.config.weekly_from_date, company.config.weekly_to_date))
    //   dispatch(getResultsRequest(e.target.value, company_id, new Date(weekly_from_date.seconds * 1000), new Date(weekly_to_date.seconds * 1000)));
    //   dispatch(getCommentsRequest(company_id, page_path, "weekly", new Date(weekly_from_date.seconds * 1000), new Date(weekly_to_date.seconds * 1000)));
    //   if (activatedFilter !== "") {
    //     dispatch(setFilter(""));
    //   }
    // } else {
    //   dispatch(showLoading());
    //   // dispatch(getResultsRequest(e.target.value, company_id, company.config.monthly_from_month, company.config.monthly_to_month))
    //   dispatch(getResultsRequest(e.target.value, company_id, new Date(daily_from_date.seconds * 1000), new Date(daily_to_date.seconds * 1000)));
    //   dispatch(getCommentsRequest(company_id, page_path, "daily", new Date(daily_from_date.seconds * 1000), new Date(daily_to_date.seconds * 1000)));
    //   if (activatedFilter !== "") {
    //     dispatch(setFilter(""));
    //   }
    // }
  };

  // 画面全表示を切り替え
  const expanderActivated = useSelector((state: any) => state.session.expanderActivated);
  const handleExpander = (e: any) => {
    dispatch(toggleExpander(expanderActivated));
  };
  const [fetching, setFetching] = React.useState(false);
  props.history.listen(() => {
    setFetching(true)
  });

  React.useEffect(() => {
    if(fetching) {
        setFetching(false);
    }else{
        get_dataUpdated(company_id).then(val => {
            const data_updated = val?.data_updated || false;
            if(data_updated && !fetching) {
                handleResultSpanSwitch(data_type)
            }
        }).catch(err => {
        })
    }
  },[fetching])

  return (
    <HeaderBlock>
      <HeaderTitle>{page_title}</HeaderTitle>

      <DateWrapper>
        <DateSelect>
          <ResultSpanSwitchForm>
            <ResultSpanSwitchSelect value={selected_data_config_data_type} name="result_type" ref={register} onChange={(e) => handleResultSpanSwitch(e.target.value)}>
              <option value="monthly">月次</option>
              {/* 週次、月次は未来予測を行っていない場合のみ選択可能 */}
              {(forecast_filter_status === 'reset_forecast') && <option value="weekly">週次</option>}
              {(forecast_filter_status === 'reset_forecast') && <option value="daily">日次</option>}
            </ResultSpanSwitchSelect>
          </ResultSpanSwitchForm>
        </DateSelect>

        {
          (page_title === '事業計画' && forecast_filter_status !== 'reset_forecast') ? <ForecastSpanSelectorSection /> : <DefaultSpanSelectorSection />
        }

        {selected_data_config_data_span === "custom" && (
          <Selector>
            {
              (page_title === '事業計画' && forecast_filter_status !== 'reset_forecast') ? <FutureDateSpanSelectorStart /> : <DateSpanSelectorStart />
            }
            <Span>-</Span>
            {
              (page_title === '事業計画' && forecast_filter_status !== 'reset_forecast') ? <FutureDateSpanSelectorEnd /> : <DateSpanSelectorEnd />
            }
          </Selector>
        )}

        <ExpanderSC>
          <ExpanderContainerSC onClick={handleExpander} className={expanderActivated && "activated"}>
            {expanderActivated ? "戻る" : "フルスクリーン"}
          </ExpanderContainerSC>
        </ExpanderSC>
      </DateWrapper>
    </HeaderBlock>
  );
};

export default withRouter(PageHeaderRaw);

// HeaderTitle
const HeaderBlock = styled.div`
  border-top: 8px solid #0050c9;
  padding: 16px 24px 14px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
`;
const HeaderTitle = styled.h2`
  font-size: 28px;
  line-height: 42px;
  margin: 0;
  font-weight: bold;
  color: #35455d;
`;
const DateWrapper = styled.div`
  display: flex;
  align-items: center;
`;
// DateSelect
const DateSelect = styled.div`
  margin-right: 0;
  margin-left: 20px;
  background-color: #fff;
  position: relative;
  .dateArrow {
    width: 25px;
    height: auto;
    display: block;
    position: absolute;
    top: 50%;
    right: 18px;
    transform: translateY(-50%);
    z-index: 10;
  }
`;
const ResultSpanSwitchForm = styled.form`
  border: none;
`;
const ResultSpanSwitchSelect = styled.select`
  border: 1px solid #e2e9f3;
  border-radius: 2px;
  width: 113px;
  height: 32px;
  text-align: center;
  font-size: 12px;
  color: #435878;
  padding: 0 10px 0 20px;
  box-sizing: border-box;
  background-image: url(${dateArrow});
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 24px 24px;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`;
const Selector = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin-left: 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #7591bc;

  .makeStyles-container-9 {
    margin-top: 10px;
  }

  .MuiInput-underline {
    &::before,
    &::after {
      content: none;
    }
  }
  .MuiFormControl-marginNormal,
  .makeStyles-container-9 {
    margin: 0;
  }
  .MuiInputBase-input {
    letter-spacing: 1px;
  }
  .MuiSvgIcon-root {
    fill: #c4c4c4;
  }
`;
const Span = styled.span`
  padding: 0 20px 0 10px;
`;

// Expand / Shrink
const ExpanderSC = styled.div`
  margin-right: 16px;
  margin-left: 20px;
  background-color: #fff;
  position: relative;
  .dateArrow {
    width: 25px;
    height: auto;
    display: block;
    position: absolute;
    top: 50%;
    right: 18px;
    transform: translateY(-50%);
    z-index: 10;
  }
`;
const ExpanderContainerSC = styled.div`
  border: 1px solid #e2e9f3;
  border-radius: 2px;
  width: 122px;
  height: 32px;
  text-align: center;
  font-size: 12px;
  color: #435878;
  padding: 9px 2px 0 25px;
  box-sizing: border-box;
  background-image: url(${expandIcon});
  background-repeat: no-repeat;
  background-position-x: 10px;
  background-position-y: center;
  background-size: 14px 14px;
  cursor: pointer;
  &.activated {
    background-image: url(${shrinkIcon});
  }
  &:hover {
    opacity: 0.6;
  }
`;
