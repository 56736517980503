import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import GlobalNav from "../../Layout/GlobalNav";
import PageHeaderRaw from "../../Layout/PageHeader";
import ConfigNavCard from "../ConfigNavCard";
import { getBillingAccountRequest, updateBillingAccountRequest } from "../../../reducks/billingAccount/actions";
import { getBillingsRequest } from "../../../reducks/billings/actions";
import MenuButton from "./MenuButton";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      backgroundColor: "#F4F4F4"

    },
    configCard: {
      display: "flex",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    }
  })
);

// Container
const Container = styled.div`
  display: flex;
`;

// BillingContent
const BillingContent = styled.div`
  width: 100%;
  height: 100vh;
  padding: 28px 40px;
  box-sizing: border-box;
  background-color: #fff;
`;
// BillingHeading
const BillingHeading = styled.h2`
  font-size: 18px;
  font-weight: bold;
  line-height: 27px;
  color: #283f46;
  margin-bottom: 18px;
`;

const ContentCard = styled.div`
  display: flex;
  justify-content: space-between;
  color: #435878;
  border-bottom: 0.5px solid #F0F0F0;
  padding-bottom:16px;
  margin-bottom: 16px;
  position: relative;
  &:last-of-type {
    border-bottom: none;
  }
`

const ContentDetail = styled.div`
  display: block;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  width: 504px;
  padding: 14px 20px;
  margin: 0;
`

const CompanyName = styled.h3`
  font-size: 16px;
  line-height: 19px;
  margin: 0 0 16px 0;
`

const ContentHeading = styled.h2`
  font-size: 16px;
  margin: 0;
`

const ContentText = styled.p`
  font-size: 12px;
  margin:0 0 8px 0;
`

const HistoryItem = styled.li`
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  list-style: none;
  border-bottom: 0.5px solid #F0F0F0;
  padding-bottom: 16px;
  margin-bottom: 16px;
  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`

const EditLink = styled(Link)({
  color: '#435878',
  textDecoration: 'none',
  width: '100%'
}) 


// Form
const Form = styled.form`
  max-width: 570px;
  width: 100%;
`;
// Line
const Line = styled.div`
  margin: 0 0 24px 0;
  padding-left: 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  label {
    color: #435878;
    margin-right: 34px;
    display: inline-block;

    span {
      font-size: 14px;
      font-weight: bold;
      color: #AF002A;
    }
  }
  input {
    font-size: 14px;
    line-height: 21px;
    color: #283F46;
    width: 100%;
    max-width: 296px;
    border: 1px solid #C3CEDF;
    border-radius: 4px;
    padding: 12px 21px;
    box-sizing: border-box;
  }
`;
// ButtonBlock
const ButtonBlock = styled.div`
  max-width: 570px;
  width: 100%;
  padding-top: 30px;
  display: flex;
  justify-content: flex-end;
`;
// Button
const Button = styled.button`
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
  color: #fff;
  text-align: center;
  max-width: 152px;
  width: 100%;
  height: 36px;
  padding: 9px 10px 10px 10px;
  display: inline-block;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  appearance: none;
  outline: none;
  a {
    color: #435878;
    text-decoration: none;
    display: block;
  }
  &.send {
    background-color: #0066FF;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
  }
  &:hover {
    opacity: .6;
  }
`;

const ErrorText = styled.p`
  font-size: 12px;
  color: #AF002A;
  position: absolute;
  top: 50px;
  left: 288px;
`

const BillingAccount: React.FC = (props:any) => {
  const location = useLocation();
	const pathname = location.pathname;
  // GA計測タグ
	useEffect(() => {
		document.title = '請求先アカウント編集'; // ページタイトルを変更
		window.gtag('config', 'G-YXXD65BEJ0', {
			'page_path': pathname // 任意のパス（あるいはprops.location.pathnameなど）
		});
	});

  const classes = useStyles();
  let history = useHistory();

  // Storeからログインユーザーの企業IDを取得
  const company_id = useSelector((state: any) => state.user.company_id);

  // Storeから請求履歴情報を取得
  const billings = useSelector((state: any) => state.billings);

  // Storeから決済方法、請求先情報を取得
  const billingAccountId = useSelector((state: any) => state.user.billingAccountId);
  const billingAccount = useSelector((state: any) => state.billingAccount);

  const dispatch = useDispatch();

  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastNameJp, setLastNameJp] = useState('');
  const [firstNameJp, setFirstNameJp] = useState('');
  const [position, setPosition] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [tel, setTel] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const { register, errors, handleSubmit } = useForm()

  // コンポーネントマウント時に決済方法、請求先を取得
  useEffect(() => {
    dispatch(getBillingAccountRequest(company_id, billingAccountId));
    setFirstName(billingAccount.firstName);
    setFirstNameJp(billingAccount.firstName_jp);
    setLastName(billingAccount.lastName);
    setLastNameJp(billingAccount.lastName_jp);
    setPosition(billingAccount.position);
    setPostalCode(billingAccount.postal_code);
    setTel(billingAccount.tel);
    setAddress1(billingAccount.address_1);
    setAddress2(billingAccount.address_2);
    setEmail(billingAccount.email);
    setCompanyName(billingAccount.company_name);
  }, [billingAccount.address1, billingAccount.address2, billingAccount.address_1, billingAccount.address_2, billingAccount.companyName, billingAccount.company_name, billingAccount.email, billingAccount.firstName, billingAccount.firstNameJp, billingAccount.firstName_jp, billingAccount.lastName, billingAccount.lastNameJp, billingAccount.lastName_jp, billingAccount.position, billingAccount.postalCode, billingAccount.postal_code, billingAccount.tel, billingAccountId, company_id, dispatch]);

  // 請求履歴取得
  useEffect(() => {
    dispatch(getBillingsRequest(company_id));
  }, [company_id, dispatch]);

  const handleUpdateBillingAccount = () => {
    dispatch(updateBillingAccountRequest(
      company_id,
      billingAccountId,
      firstName,
      firstNameJp,
      lastName,
      lastNameJp,
      position,
      postalCode,
      tel,
      email,
      address1,
      address2,
      companyName,));
    history.push("/config/billing-setting");
  }

  return (
    <Container>
      <GlobalNav />

      <main className={classes.content}>
        <PageHeaderRaw page_title="管理" />

        <div className={classes.configCard}>
          <ConfigNavCard />

          <BillingContent>
            <BillingHeading>請求先情報編集</BillingHeading>
            <Form onSubmit={ handleSubmit(handleUpdateBillingAccount) }>

              <Line>
                <label htmlFor="companyName"><span>【必須】</span>会社名</label>
                <input 
                  type="text" 
                  name="companyName"
                  value={companyName} 
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setCompanyName(e.target.value);
                  }}
                  ref={register( {required:true} )}
                />
                {errors.companyName && <ErrorText>必須項目です</ErrorText>}
              </Line>


              <Line>
                <label htmlFor="position"><span>【必須】</span>部署 役職</label>
                <input 
                  type="text" 
                  name="position"
                  value={position} 
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setPosition(e.target.value);
                  }}
                  ref={register( {required:true} )}
                />
                {errors.position && <ErrorText>必須項目です</ErrorText>}
              </Line>

              <Line>
                <label htmlFor="lastNameJp"><span>【必須】</span>姓</label>
                <input 
                  type="text" 
                  name="lastNameJp"
                  value={lastNameJp} 
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setLastNameJp(e.target.value);
                  }}
                  ref={register( {required:true} )}
                />
                {errors.lastNameJp && <ErrorText>必須項目です</ErrorText>}
              </Line>

              <Line>
                <label htmlFor="firstNameJp"><span>【必須】</span>名</label>
                <input 
                  type="text"
                  name="firstNameJp"
                  value={firstNameJp} 
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFirstNameJp(e.target.value);
                  }}
                  ref={register( {required:true} )}
                />
                {errors.firstNameJp && <ErrorText>必須項目です</ErrorText>}
              </Line>

              <Line>
                <label htmlFor="lastName"><span>【必須】</span>Last Name</label>
                <input 
                  type="text" 
                  name="lastName"
                  value={lastName} 
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {setLastName(e.target.value);}}
                  ref={register( {required:true} )}
                />
                {errors.lastName && <ErrorText>必須項目です</ErrorText>}
              </Line>

              <Line>
                <label htmlFor="firstName"><span>【必須】</span>First Name</label>
                <input 
                  type="text"
                  name="firstName"
                  value={firstName} 
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFirstName(e.target.value);
                  }}
                  ref={register( {required:true} )}
                />
                {errors.firstName && <ErrorText>必須項目です</ErrorText>}
              </Line>

              <Line>
                <label htmlFor="postalCode"><span>【必須】</span>郵便番号</label>
                <input 
                  type="text" 
                  name="postalCode"
                  value={postalCode} 
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setPostalCode(e.target.value);
                  }}
                  ref={register( {required:true} )}
                />
                {errors.postalCode && <ErrorText>必須項目です</ErrorText>}
              </Line>


              <Line>
                <label htmlFor="address1"><span>【必須】</span>住所１</label>
                <input 
                  type="text" 
                  name="address1"
                  value={address1} 
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setAddress1(e.target.value);
                  }}
                  ref={register( {required:true} )}
                />
                {errors.address1 && <ErrorText>必須項目です</ErrorText>}
              </Line>


              <Line>
                <label htmlFor="address2"><span>【必須】</span>住所２</label>
                <input 
                  type="text" 
                  name="address2"
                  value={address2} 
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setAddress2(e.target.value);
                  }}
                  ref={register( {required:true} )}
                />
                {errors.address2 && <ErrorText>必須項目です</ErrorText>}
              </Line>

              <Line>
                <label htmlFor="email"><span>【必須】</span>メールアドレス</label>
                <input 
                  type="email" 
                  name="email"
                  value={email} 
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(e.target.value);
                  }}
                  ref={register( {required:true} )}
                />
                {errors.email && <ErrorText>必須項目です</ErrorText>}
              </Line>

              <Line>
                <label htmlFor="tel"><span>【必須】</span>電話番号</label>
                <input 
                  type="text" 
                  name="tel"
                  value={tel} 
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setTel(e.target.value);
                  }}
                  ref={register( {required:true} )}
                />
                {errors.tel && <ErrorText>必須項目です</ErrorText>}
              </Line>

              <ButtonBlock>
                <Button className="cancel">
                  <Link to='/config/billing-setting'>キャンセル</Link>
                </Button>
                <Button className="send" type="submit">更新</Button>
              </ButtonBlock>

            </Form>

          </BillingContent>
        </div>
      </main>
    </Container>
  );
};

export default BillingAccount;
