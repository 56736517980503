const handleMetricsSimulation = (input_value: string, result_at: any, target_data_key: string, target_data_array: any) => {
  const target_to_be_changed = target_data_array.find((target_data: any) => target_data.result_at.seconds === result_at.seconds);
  const target_index = target_data_array.findIndex((target_data: any) =>target_data.result_at.seconds === result_at.seconds);

  const input_value_out_of_comma = input_value.replace(/,/g, '');
  const input_value_out_of_yen = input_value_out_of_comma.replace(/￥/g, '');
  const input_value_out_of_month = input_value_out_of_comma.replace(/month/g, '');
  const input_value_out_of_percentage = input_value_out_of_comma.replace(/%/g, '');
  // // const first_value = target_result[target_data_name];

  let input_value_num: number
  // 単位￥の場合
  if ( input_value.indexOf('￥') !== -1) {
    input_value_num = parseInt(input_value_out_of_yen);

    // 単位monthの場合
  } else if ( input_value.indexOf('month') !== -1) {
    input_value_num = Number(input_value_out_of_month);

    // 単位%の場合
  } else if ( input_value.indexOf('%') !== -1) {
    const input_value_devided_by_100: number = Number(input_value_out_of_percentage) / 100
    input_value_num = input_value_devided_by_100

    // 単位無し
  } else {
    input_value_num = Number(input_value)
  }

  // 数字を全部消してしまった場合
  if(input_value_num.toString() === '') {
    input_value_num = 0
  }

  // unit_economicsだけ数値に1.2掛けてあるのでそれに対応
  const target_name = target_data_key.replace(/_objective/g, '');
  if(target_name === 'unit_economics') {
    input_value_num = input_value_num / 1.2
  }

  // console.log('input_value_num', input_value_num);

  // // const second_value = target_result[target_data_name][target_data_key];
  // // console.log('second_value', second_value);
  const updated_target_data = {
    ...target_to_be_changed,
    [target_data_key]: input_value_num,
  };
  console.log('updated_target_data', updated_target_data);
  return {
    result_at: result_at,
    target_data_key_to_update: target_data_key,
    value_to_update: input_value_num,
    target_index: target_index,
    updated_target_data: updated_target_data
  }
};


export default handleMetricsSimulation;