import React, { useCallback } from "react";
import FileSaver from "file-saver";
import {
  updateDownloadGraphStatus,
} from "../../../reducks/download/actions";
import {
  updateDownloadCsvTarget,
} from "../../../reducks/download/actions";
import { useCurrentPng } from "recharts-to-png";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import { yAxisFormatter } from "../../Util/yAxisFormatter";
import { unitSwitcher } from "../../Util/unitSwitcher";
import GraphDataDownload from "../../Parts/GraphDataDownload";
interface Props {
  funnel_summary_array: any;
  resultSpan: string;
  moving_average_graph_switch: any;
}

const CustomTooltip = ({ active, payload, label }: any) => {
  console.log("payload", payload);
  if (active && payload && payload.length) {
    return (
      <BarGraphTooltip>
        <BarGraphTooltipHeading>
          {payload[0].payload.funnel_name}
        </BarGraphTooltipHeading>

        {payload.map((payload_item: any) => (
          <BarGraphTooltipContent key={payload_item.dataKey}>
            <BarGraphTooltipLabel bgcolor={payload_item.fill}>
              {payload_item.dataKey}
            </BarGraphTooltipLabel>
            <BarGraphTooltipData>{`${unitSwitcher(
              payload_item.value,
              "lead_count"
            )}`}</BarGraphTooltipData>
            <BarGraphTooltipHR />
          </BarGraphTooltipContent>
        ))}
      </BarGraphTooltip>
    );
  }

  return null;
};

const LegendListContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

const LegendList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding-top: 15px;
`;

const LegendItem = styled.li`
  font-size: 12px;
  line-height: 1.6;
  margin-right: 16px;
  margin-bottom: 10px;
  padding: 6px 8px 6px 30px;
  box-sizing: border-box;
  position: relative;
  border: 1px solid #edf0f1;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
  &.deactivated {
    background-color: #edf0f1;
  }
  button {
    position: relative;
    span {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 2px;
      left: -22px;
      border-radius: 8px;
    }
  }
`;

export interface ChannelName {
  channel_id: number;
  channel_name: string;
  deactivated: boolean;
  channel_color: string;
}

export interface TransitionRateLeadTime {
  funnel_name: string;
  lead_transition_rate_total: number;
  lead_transition_rate_objective: number;
  lead_transition_rate_rate: number;
  lead_transition_rate_difference: number;
  lead_transition_rate_last_year_comparison_rate: number;
  lead_time_total: number;
}

export interface FunnelSummaryGraphTransitionRateLeadTimeProps {
  transition_rate_lead_time_array: TransitionRateLeadTime[];
  channel_name_array: ChannelName[];
  sum_lead_new_count_total_array_all: any;
}
const FunnelSummaryGraphTransitionRateLeadTime: React.FC<FunnelSummaryGraphTransitionRateLeadTimeProps> =
  ({
    transition_rate_lead_time_array,
    channel_name_array,
    sum_lead_new_count_total_array_all
  }) => {
    const dispatch = useDispatch();
    const [channelArray, setChannelArray] = React.useState(channel_name_array);

    // カスタムレジェンドから呼び出す、レジェンドアイテムのクリック関数
    const handleLegendItemClick = (dataKey: any) => {
      const updatedChannelArray = channelArray.map((channelItem: any) => {
        if (channelItem.channel_name === dataKey) {
          return {
            ...channelItem,
            deactivated: !channelItem.deactivated
          };
        }
        return channelItem;
      });
      setChannelArray(updatedChannelArray);
    };

    // Graph Download Section
    const [getAreaPng, { ref: areaRef }] = useCurrentPng();
    const handleAreaDownload = useCallback(async () => {
      dispatch(
        updateDownloadGraphStatus(0)
      )
      const png = await getAreaPng();
      if (png) {
        FileSaver.saveAs(png, "graph.png");
      }
    }, [getAreaPng, dispatch]);
    function handleGraphDataDownloadClick(e:any, target:string) {
      if(e.target.innerText==='グラフ Export'){
          handleAreaDownload();
      }
      dispatch(
        updateDownloadCsvTarget("/"+target)
      )
    }

    // カスタムレジェンド
    const CustomLegend = (props: any) => {
      return (
        <LegendListContainer>
          <LegendList>
            {channelArray.map((channelItem: any, index: number) => (
              <LegendItem
                key={`LegendItem-${index}`}
                className={channelItem.deactivated ? "deactivated" : ""}
                onClick={() => handleLegendItemClick(channelItem.channel_name)}
              >
                <button className="item-total">
                  <span
                    style={{ backgroundColor: `${channelItem.channel_color}` }}
                  ></span>
                  {channelItem.channel_name}
                </button>
              </LegendItem>
            ))}
          </LegendList>
        </LegendListContainer>
      );
    };

    return (
      <div>
        <FunnelSummaryGraphTransitionRateLeadTimeHeadingGraphDownloadWrapper>
          <FunnelSummaryGraphTransitionRateLeadTimeHeading>
            Channel - 件数の推移
          </FunnelSummaryGraphTransitionRateLeadTimeHeading>
          <div onClick={(e) => {handleGraphDataDownloadClick(e, 'number_of_cases')}}>
            <GraphDataDownload />
          </div>
        </FunnelSummaryGraphTransitionRateLeadTimeHeadingGraphDownloadWrapper>
        {sum_lead_new_count_total_array_all.length > 0 ? (
          <FunnelSummaryGraph>
            <ResponsiveContainer>
              <BarChart
                width={730}
                height={250}
                data={sum_lead_new_count_total_array_all}
                ref={areaRef}
              >
                <Legend
                  verticalAlign={"top"}
                  align={"left"}
                  content={CustomLegend}
                />
                <XAxis
                  dataKey="funnel_name"
                  tickLine={false}
                  tickSize={10}
                  minTickGap={20}
                  tick={{ fontSize: 10 }}
                />

                <YAxis
                  type="number"
                  tickCount={10}
                  interval={0}
                  tickMargin={10}
                  tickLine={false}
                  yAxisId={0}
                  // tickFormatter={tick => yAxisFormatter(tick, "lead_count")}
                />
                <CartesianGrid stroke="#f5f5f5" />

                {channelArray.map(
                  (channelItem, index) =>
                    !channelItem.deactivated && (
                      <Bar
                        id={channelItem.channel_id.toString()}
                        name={channelItem.channel_name}
                        dataKey={channelItem.channel_name}
                        fill={channelItem.channel_color}
                        stackId="channel_bar"
                      />
                    )
                )}
                <Tooltip
                  content={<CustomTooltip />}
                  cursor={{ fill: "transparent" }}
                />
              </BarChart>
            </ResponsiveContainer>
          </FunnelSummaryGraph>
        ) : (
          <NoData>グラフを表示できません</NoData>
        )}
      </div>
    );
  };
export default FunnelSummaryGraphTransitionRateLeadTime;

// Graph
const FunnelSummaryGraph = styled.div`
  width: 100%;
  height: 400px;
  padding: 0 10px;
  box-sizing: border-box;

  .recharts-surface {
    position: relative;
    top: -1px;
    overflow: visible;
  }
  .recharts-cartesian-axis-tick {
    width: 100px !important;
    .recharts-cartesian-axis-tick-value {
      font-size: 10px;
      color: #435878;
    }
  }
  .recharts-legend-wrapper {
    width: 100% !important;
    height: 60px !important;
    overflow-y: scroll;
  }
`;
const NoData = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #435878;
  margin-bottom: 8px;
  &.main {
    margin-bottom: 8px;
  }
`;

const BarGraphTooltip = styled.div`
  background: #fff;
  padding: 8px 9px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid #e2e9f3;
  border-radius: 4px;
  max-width: 200px;
`;

// 日付
const BarGraphTooltipHeading = styled.h3`
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  color: #435878;
  margin-bottom: 5px;
`;

const BarGraphTooltipContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
// 当月 | 目標
const BarGraphTooltipLabel = styled.p<{ bgcolor: string }>`
  position: relative;
  font-size: 10px;
  color: #435878;
  left: 10px;
  margin-bottom: 3px;
  &::before {
    position: absolute;
    top: 3px;
    left: -8px;
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: ${props => props.bgcolor};
  }
`;
// データ
const BarGraphTooltipData = styled.p`
  text-align: right;
  font-size: 10px;
  font-weight: bold;
  color: #435878;
  margin-bottom: 3px;
`;
// 仕切り線
const BarGraphTooltipHR = styled.hr`
  width: 100%;
  height: 1px;
  color: lightgray;
  margin-bottom: 3px;
`;

const FunnelSummaryGraphTransitionRateLeadTimeHeading = styled.h2`
  color: #283f46;
  font-weight: bold;
  font-size: 22px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const FunnelSummaryGraphTransitionRateLeadTimeHeadingGraphDownloadWrapper = styled.div({
  flexGrow: 1,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});
