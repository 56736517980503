import React from 'react';
import styled from 'styled-components'

const Desc = styled.p`
  font-size: 16px;
  margin:0 0 24px 0;
  color: #35455D;
`

const Attention = styled.span`
  font-size: 12px;
  color: #789097;
`

const FormDesc = () => {
  return(
    <>
      <Desc>
      サービスの導入を検討の方は、こちらのフォームよりご要望をお伝えください。折り返しご連絡いたします。
      <br/>
      <Attention>
        ※トライアルは、管理者機能を除いた専用環境となります。<br/>個人の方や、当社が競合他社と判断したお申込みにはご提供をお断りする場合がございますので、ご了承ください。
      </Attention>
      </Desc>
    </>
  )
}

export default FormDesc