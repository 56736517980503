// import update from "immutability-helper";
import { TOGGLE_COMMENTS_WINDOW_DISPLAY, TOGGLE_COMMENT_EDIT_WINDOW_DISPLAY, TOGGLE_COMMENT_ADD_WINDOW_DISPLAY, HANDLE_COMMENT_CONTENT, ADD_COMMENT_SUCCEEDED, ADD_COMMENT_FAILED, GET_COMMENTS_SUCCEEDED, GET_COMMENTS_FAILED, UPDATE_COMMENT_SUCCEEDED, UPDATE_COMMENT_FAILED, DELETE_COMMENT_SUCCEEDED, DELETE_COMMENT_FAILED, CLOSE_COMMENTS_WINDOW, CLOSE_COMMENT_EDIT_WINDOW, CLOSE_COMMENT_ADD_WINDOW, OPEN_COMMENT_EDIT_WINDOW, UPDATE_COMMENT_EDIT_WINDOW_CONTENT } from "./types";
import {RESET_ALL} from "../common/types";

const initialState = {
  comments: [
    {
      result_id: "3krBGjH1k5Wx3gv0zHZX_MONTHLY_2020-07-01",
      page_path: "/metrics/revenue",
      user_name: "ha-ma",
      updated_at: "2020/08/01",
      content: "test content 1 test content 1",
      result_at: '',
      result_span: "daily"
    },
    {
      doc_id: "testComment2",
      result_id: "a00QlqpoaSFVNM5FMlYa",
      page_path: "/metrics/revenue",
      user_name: "ha-ma",
      updated_at: "2020/08/02",
      content: "test content 2 test content 2",
      result_at: '',
      result_span: "daily"
    },
    {
      doc_id: "testComment3",
      result_id: "3krBGjH1k5Wx3gv0zHZX_MONTHLY_2020-08-01",
      page_path: "/metrics/arpu",
      user_name: "kosuke",
      updated_at: "2020/08/02",
      content: "test content 3 test content 3",
      result_at: '',
      result_span: "daily"
    }
  ],
  commentsWindow: {
    display: false,
    result_at_timestamp: ''
  },
  displayedComments: [],
  displayedCommentsResultAt: "",
  commentAddWindow: {
    display: false,
    result_at_timestamp: '',
    target_data_name: '',
    company_id: '',
    user_name: '',
    selected_data_config_data_type: '',
    updated_at: '',
    content: ""
  },
  commentEditWindow: {
    display: false,
    doc_id: "",
    content: "initial content"
  },
  load_comments: false
};

const commentsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_ALL:
      return initialState;

    case TOGGLE_COMMENTS_WINDOW_DISPLAY:
      return {
        ...state,
        commentsWindow: {
          display: !action.display,
          result_at_timestamp: action.result_at_timestamp
        },
        commentEditWindow: {
          display: false
        },
        commentAddWindow: {
          display: false
        },
      };

    case CLOSE_COMMENTS_WINDOW:
      return {
        ...state,
        commentsWindow: {
          display: false
        }
      }

    case TOGGLE_COMMENT_EDIT_WINDOW_DISPLAY:
      return {
        ...state,
        commentEditWindow: {
          display: true,
          result_id: action.result_id,
          user_name: action.user_name,
          company_id: action.company_id,
          page_path: action.page_path,
          result_at: new Date(action.result_at.seconds * 1000),
          result_span: action.result_span
        }
      };

    case OPEN_COMMENT_EDIT_WINDOW:
      return {
        ...state,
        commentEditWindow: {
          display: true,
          docId: action.docId,
          content: action.content
        },
        commentsWindow: {
          display: false
        },
        commentAddWindow: {
          display: false
        }
      }

    case CLOSE_COMMENT_EDIT_WINDOW:
      return {
        ...state,
        commentEditWindow: {
          display: false
        }
      }

    case UPDATE_COMMENT_EDIT_WINDOW_CONTENT:
      return {
        ...state,
        commentEditWindow: {
          ...state.commentEditWindow,
          content: action.content
        }
      }

    case UPDATE_COMMENT_SUCCEEDED:
      return {
        ...state,
        comments: action.comments,
        commentEditWindow: {
          display: false,
          docId: '',
          content: ''
        }
      }

    case UPDATE_COMMENT_FAILED:
      console.log('saga error', action.message);
      return state;

    case TOGGLE_COMMENT_ADD_WINDOW_DISPLAY:
      return {
        ...state,
        commentAddWindow: {
          ...state.commentAddWindow,
          display: !action.display,
          result_at_timestamp: action.result_at_timestamp,
          target_data_name: action.target_data_name,
          company_id: action.company_id,
          user_name: action.user_name,
          selected_data_config_data_type: action.selected_data_config_data_type,
          selected_data_config_data_span: action.selected_data_config_data_span,
          custom_span_start_time: action.custom_span_start_time,
          custom_span_end_time: action.custom_span_end_time
        },
        commentEditWindow: {
          display: false
        },
        commentsWindow: {
          display: false
        }
      };

    case CLOSE_COMMENT_ADD_WINDOW:
      return {
        ...state,
        commentAddWindow: {
          display: false
        }
      }

    case HANDLE_COMMENT_CONTENT:
      return {
        ...state
        // openedEditWindow: {
        //   ...state.openedEditWindow,
        //   content: action.content
        // }
      };

    case GET_COMMENTS_SUCCEEDED:
      return {
        ...state,
        comments: action.comments,
        load_comments: false
      }

    case GET_COMMENTS_FAILED:
      console.log('message', action.message);
      return {
        ...state,
        load_comments: false
      }

    case ADD_COMMENT_SUCCEEDED:
      return {
        ...state,
        comments: action.comments,
        load_comments: true,
        commentAddWindow: {
          display: false
        }
      }

    case ADD_COMMENT_FAILED:
      console.log('Saga error', action.message);
      return state

    case DELETE_COMMENT_SUCCEEDED:
      return {
        ...state,
        comments: action.comments,
        commentEditWindow: {
          display: false
        }
      }

    case DELETE_COMMENT_FAILED:
      console.log('Saga error', action.message);
      return state

    default:
      return state;
  }
};

export default commentsReducer;
