const GET_RESULTS_REQUESTED_FOR_MARKETING_FUNNEL_FORECAST = "GET_RESULTS_REQUESTED_FOR_MARKETING_FUNNEL_FORECAST"
const MARKETING_FUNNEL_TOGGLE_FORECAST_FILTER = "MARKETING_FUNNEL_TOGGLE_FORECAST_FILTER"
const GET_RESULTS_REQUESTED_FOR_MARKETING_FUNNEL_FORECAST_SUCCEEDED = "GET_RESULTS_REQUESTED_FOR_MARKETING_FUNNEL_FORECAST_SUCCEEDED"
const GET_RESULTS_REQUESTED_FOR_MARKETING_FUNNEL_FORECAST_FAILED = "GET_RESULTS_REQUESTED_FOR_MARKETING_FUNNEL_FORECAST_FAILED"
const UPDATE_MARKETING_FUNNEL_SIMULATED_RESULT = "UPDATE_MARKETING_FUNNEL_SIMULATED_RESULT"
const GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_MARKETING_FUNNEL_FORECASTS = "GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_MARKETING_FUNNEL_FORECASTS"

const GET_RESULTS_FOR_SPAN_COMPARISON_MARKETING_FUNNEL_FORECASTS_SUCCEEDED = "GET_RESULTS_FOR_SPAN_COMPARISON_MARKETING_FUNNEL_FORECASTS_SUCCEEDED"
const GET_RESULTS_FOR_SPAN_COMPARISON_MARKETING_FUNNEL_FORECASTS_FAILED = "GET_RESULTS_FOR_SPAN_COMPARISON_MARKETING_FUNNEL_FORECASTS_FAILED"

export { GET_RESULTS_REQUESTED_FOR_MARKETING_FUNNEL_FORECAST, MARKETING_FUNNEL_TOGGLE_FORECAST_FILTER, GET_RESULTS_REQUESTED_FOR_MARKETING_FUNNEL_FORECAST_SUCCEEDED, GET_RESULTS_REQUESTED_FOR_MARKETING_FUNNEL_FORECAST_FAILED, UPDATE_MARKETING_FUNNEL_SIMULATED_RESULT, GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_MARKETING_FUNNEL_FORECASTS, GET_RESULTS_FOR_SPAN_COMPARISON_MARKETING_FUNNEL_FORECASTS_SUCCEEDED, GET_RESULTS_FOR_SPAN_COMPARISON_MARKETING_FUNNEL_FORECASTS_FAILED }
