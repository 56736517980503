import rsf from "../../rsf"
import firebase from "firebase"
import { call } from "redux-saga/effects"
const db = firebase.firestore()


function* fetchFunnelMasterList(company_id) {
  try {
    const snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${company_id}/funnel`).orderBy("id"))
    let funnel_master_list = []

    snapshot.forEach((funnel) => {
      funnel_master_list = [...funnel_master_list, { ...funnel.data(), docId: funnel.id }]
    })
    return funnel_master_list
  } catch (e) {
    console.log("error", e.message)
  }
}

export default fetchFunnelMasterList;