// resultデータから重要指標データの配列へ変換
const resultToMetrics = (
  raw_results: any,
  resultSpan: string,
  target_data_name: string,

) => {
  return raw_results.map((result: any) => {
    return {
      ...result[target_data_name],
      result_at: result.result_at,
      doc_id: result.doc_id
    }
  })
}

export default resultToMetrics;