import firebase from "firebase";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  LOGIN_REQUESTED,
  LOGIN_SUCCEEDED,
  LOGIN_FAILED,
  SET_ACCESS_TOKEN,
  SET_DISPLAYED_FORECAST_FUNNEL_RAWS
} from "../reducks/sessions/types";
import {
  GET_RESULTS_SUCCEEDED,
  GET_DETAIL_REVENUE_SUCCEEDED
} from "../reducks/results/types";
import { GET_UPLOAD_HISTORY_LIST_REQUESTED } from "../reducks/uploadHistory/types";
import { GET_RAW_DATA_SUCCEEDED } from "../reducks/rawData/types";
import { SET_REVENUE } from "../reducks/revenue/types";
import { SET_COST } from "../reducks/cost/types";
import { SET_PROFIT } from "../reducks/profit/types";
import { SET_COMPANY } from "../reducks/company/types";
import { HIDE_LOADING, SET_LOADING } from "../reducks/loading/types";
import { GET_COMMENTS_SUCCEEDED } from "../reducks/comments/types";
import { GET_PRODUCTS_SUCCEEDED } from "../reducks/products/types";
import { GET_LAYOUT_SUCCEEDED } from "../reducks/grid/gridTypes";
import { GET_FILTER_LIST_SUCCEEDED } from "../reducks/filters/types";
import { GET_UPLOAD_HISTORY_LIST_SUCCEEDED } from "../reducks/uploadHistory/types";
import { GET_FUNNEL_MASTER_LIST_SUCCEEDED } from "../reducks/funnels/types";
import { GET_COST_MASTER_LIST_SUCCEEDED } from "../reducks/cost/types";
import {
  SET_BASIC_VALUES,
  SET_CHURN_RATE,
  SET_CLIENT_CHURNED,
  SET_REVENUE_CHURNED
} from "../reducks/cohort/types";

import { SET_MONTHLY_REVENUE } from "../reducks/revenue/monthly/types";

import fetchUserFromFirestore from "./sessionSagas/fetchUserFromFirestore";
import getResultMonthly from "./sessionSagas/getResultMonthly";
import getDetailMonthly from "./sessionSagas/getDetailMonthly";
import fetchCompanyFromFirestore from "./sessionSagas/fetchCompanyFromFirestore";
import fetchUploadHistory from "./sessionSagas/fetchUploadHistory";
import fetchComments from "./sessionSagas/fetchComments";
import fetchProducts from "./sessionSagas/fetchProducts";
import fetchLayout from "./sessionSagas/fetchLayout";
import fetchFilterList from "./sessionSagas/fetchFilterList";
import fetchFunnelMasterList from "./sessionSagas/fetchFunnelMasterList";
import fetchCostMasterList from "./sessionSagas/fetchCostMasterList";
import fetchAccessToken from "./sessionSagas/fetchAccessToken";
import authApiPost from "./sessionSagas/authApiPost";
import getRawDataThisMonth from "./rawDataSagas/getRawDataThisMonth";
import { fetchCohort } from "./sessionSagas/fetchCohorts";

function* getUploadHistoryList(action) {
  try {
    const upload_histories = yield* fetchUploadHistory(action.company_id);
    yield all([
      put({
        type: GET_UPLOAD_HISTORY_LIST_SUCCEEDED,
        upload_histories: upload_histories
      })
    ]);
  } catch (e) {
    console.log("e", e);
  }
}

function* loginUser(action) {
  try {
    const auth = firebase.auth();
    const result = yield call(
      [auth, auth.signInWithEmailAndPassword],
      action.email,
      action.password
    );

    //ユーザーをFirestoreから取得
    const user = yield* fetchUserFromFirestore(result.user.uid);

    // ユーザーの所属するcompanyのconfig情報を取得
    const company = yield* fetchCompanyFromFirestore(user.company_id);

    // upload history情報を取得
    const upload_histories = yield* fetchUploadHistory(user.company_id);

    const raw_data_array = yield* getRawDataThisMonth(
      user.company_id,
      "monthly"
    );

    // result_monthlyデータをFirestoreから取得
    const result_monthly = yield* getResultMonthly(
      user.company_id,
      company.config.monthly_from_month,
      company.config.monthly_to_month
    );

    // detailデータをFirestoreから取得
    const detail_revenue_monthly = yield* getDetailMonthly(
      user.company_id,
      company.config.monthly_from_month,
      company.config.monthly_to_month,
      "revenue_detail"
    );
    // const detail_cost_monthly = yield* getDetailMonthly(user.company_id, company.config.monthly_from_month, company.config.monthly_to_month, 'cost_detail')

    const comments = yield* fetchComments(
      user.company_id,
      "monthly",
      new Date(company.config.monthly_from_month.seconds * 1000),
      new Date(company.config.monthly_to_month.seconds * 1000)
    );

    const products = yield* fetchProducts(user.company_id);

    const gridLayout = yield* fetchLayout(user.company_id);

    const filter_list = yield* fetchFilterList(user.company_id);

    const funnel_master_list = yield* fetchFunnelMasterList(user.company_id);

    const cost_master_list = yield* fetchCostMasterList(user.company_id);

    const result_span = "monthly";

    //  const basic_values = yield* fetchCohortBasicValues();
    //  console.log("==== basic_values ===",basic_values);

    const cohorts = yield* fetchCohort(user.company_id);

    // access_tokenを取得
    const access_token_result = yield authApiPost(
      company.salesforce_api_key,
      company.salesforce_secret_key
    );
    const access_token = access_token_result.data.results.access_token;
    // console.log('access_token', access_token);
    // const access_token_result = yield authApiPost(company.salesforce_api_key, company.salesforce_secret_key)
    // const access_token = 'testaccesstoken'
    // console.log('access_token', access_token);

    yield all([
      put({
        type: LOGIN_SUCCEEDED,
        user: result.user
      }),
      put({
        type: GET_RAW_DATA_SUCCEEDED,
        raw_data_array: raw_data_array,
        custom_span_start_time: company.config.monthly_from_month,
        custom_span_end_time: company.config.monthly_to_month
      }),
      // put({
      //   type: GET_RESULTS_SUCCEEDED,
      //   results: result_monthly,
      //   resultSpan: result_span, // ログイン時のデフォルトはmonthlyで固定
      // }),
      // put({
      //   type: GET_DETAIL_REVENUE_SUCCEEDED,
      //   detail_results: detail_revenue_monthly,
      //   resultSpan: result_span
      // }),
      // put({
      //   type: SET_REVENUE,
      //   results: result_monthly,
      //   resultSpan: result_span, // ログイン時のデフォルトはmonthlyで固定
      // }),
      // put({
      //   type: SET_COST,
      //   results: result_monthly,
      //   resultSpan: result_span, // ログイン時のデフォルトはmonthlyで固定
      // }),
      // put({
      //   type: SET_PROFIT,
      //   results: result_monthly,
      //   resultSpan: result_span, // ログイン時のデフォルトはmonthlyで固定
      // }),
      put({
        type: "USER_FETCH_SUCCEEDED",
        user: user
      }),
      put({
        type: SET_COMPANY,
        company: company
      }),
      put({
        type: GET_UPLOAD_HISTORY_LIST_SUCCEEDED,
        upload_histories: upload_histories
      }),
      put({
        type: GET_COMMENTS_SUCCEEDED,
        comments: comments
      }),
      // put({
      //   type: GET_PRODUCTS_SUCCEEDED,
      //   products: products,
      // }),
      put({
        type: GET_LAYOUT_SUCCEEDED,
        gridLayout: gridLayout
      }),
      put({
        type: GET_FILTER_LIST_SUCCEEDED,
        filter_list: filter_list
      }),
      put({
        type: GET_FUNNEL_MASTER_LIST_SUCCEEDED,
        funnel_master_list: funnel_master_list
      }),
      put({
        type: SET_DISPLAYED_FORECAST_FUNNEL_RAWS,
        funnel_master_list: funnel_master_list
      }),
      put({
        type: GET_COST_MASTER_LIST_SUCCEEDED,
        cost_master_list: cost_master_list
      }),
      put({
        type: SET_CHURN_RATE,
        churn_rate: cohorts.cohort_churn_rate
      }),
      put({
        type: SET_CLIENT_CHURNED,
        client_churned: cohorts.cohort_client_churned
      }),
      put({
        type: SET_REVENUE_CHURNED,
        revenue_churned: cohorts.cohort_revenue_churned
      }),
      //  put({
      //    type : SET_BASIC_VALUES,
      //    basic_values
      //  }),
      // put({
      //   type: SET_MONTHLY_REVENUE,
      //   result_monthly: result_monthly
      // }),
      put({
        type: SET_ACCESS_TOKEN,
        access_token: access_token,
        salesforce_api_key: company.salesforce_api_key,
        salesforce_secret_key: company.salesforce_secret_key
      }),
      put({
        type: HIDE_LOADING
      })
    ]);
  } catch (e) {
    yield all([
      put({
        type: HIDE_LOADING
      }),
      put({
        type: LOGIN_FAILED,
        message: e.message
      })
    ]);
  }
}

const sessionsSagas = [
  takeLatest(LOGIN_REQUESTED, loginUser),
  takeLatest(GET_UPLOAD_HISTORY_LIST_REQUESTED, getUploadHistoryList)
];

export default sessionsSagas;
