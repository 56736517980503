import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import reached_arrow from "../../images/icons/reached_arrow.svg";
import unreached_arrow from "../../images/icons/unreached_icon.svg";
import { colorSwitcherRate } from "../Util/colorSwitcher";
import { unitSwitcher, unitSwitcherForTop } from "../Util/unitSwitcher";

interface Props {
  result_at_seconds: number;
  this_month_total: number;
  this_month_rate: number;
  target_data: [];
  target_data_name: string;
}

export default function ThisMonthSummary(props: Props) {
  const resultSpan = useSelector((state: any) => state.target_data.resultSpan);
  const {target_data, target_data_name, this_month_rate, this_month_total, result_at_seconds} = props

  return (
    <ThisMonthSummarySC>
      {/* 直近の実績 */}
      <SummaryContent>
        <SummaryLabel>
          {
            // dailyかweeklyだったら一日単位で表示
            resultSpan !== "monthly" &&
              target_data[target_data.length - 1] &&
              `
        ${new Date(result_at_seconds * 1000).getFullYear()}/
        ${new Date(result_at_seconds * 1000).getMonth() + 1}/
        ${new Date(result_at_seconds * 1000).getDate()}
      `
          }
          {
            // monthlyだったら月まで表示
            resultSpan === "monthly" &&
              target_data[target_data.length - 1] &&
              `
        ${new Date(result_at_seconds * 1000).getFullYear()}/
        ${new Date(result_at_seconds * 1000).getMonth() + 1}
      `
          }
        </SummaryLabel>
        <ThisMonthTotal
          className={
            target_data[target_data.length - 1] &&
            colorSwitcherRate(target_data_name, this_month_rate)
          }
        >
          {target_data[target_data.length - 1] &&
            unitSwitcherForTop(this_month_total, target_data_name)}
        </ThisMonthTotal>
      </SummaryContent>
      {/* 直近の達成率 */}
      <SummaryContent>
        <SummaryLabel>達成率</SummaryLabel>
        <ThisMonthRate
          className={
            target_data[target_data.length - 1] &&
            colorSwitcherRate(target_data_name, this_month_rate)
          }
        >
          (
          {target_data[target_data.length - 1] &&
            unitSwitcher(this_month_rate, "rate")})
        </ThisMonthRate>
      </SummaryContent>
    </ThisMonthSummarySC>
  );
}

const ThisMonthSummarySC = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;
const SummaryContent = styled.div`
  display: flex;
  flex-direction: column;
  &:first-of-type {
    margin-right: 16px;
  }
`;

const SummaryLabel = styled.p`
  color: #b5b5b5;
  font-size: 10px;
  line-height: 15px;
`;

// ThisMonthTotal
const ThisMonthTotal = styled.p`
  font-size: 30px;
  font-weight: bold;
  line-height: 42px;
  color: #435878;
  &.reached {
    color: #028cab;
  }
  &.unreached {
    color: #af002a;
  }
  &.equal {
    color: #35455d;
  }
`;

const ThisMonthRate = styled.p`
  display: inline-block;
  font-size: 18px;
  line-height: 42px;
  font-weight: normal;
  position: relative;
  font-weight: bold;
  &.reached {
    color: #028cab;
    &::before {
      content: "";
      display: inline-block;
      height: 24px;
      width: 24px;
      top: 6px;
      position: relative;
      margin-right: 8px;
      background-image: url(${reached_arrow});
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  &.unreached {
    color: #af002a;
    &::before {
      content: "";
      display: inline-block;
      height: 24px;
      width: 24px;
      top: 6px;
      position: relative;
      margin-right: 8px;
      background-image: url(${unreached_arrow});
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  &.equal {
    color: #35455d;
    &::before {
      content: "";
      display: inline-block;
      height: 18px;
      width: 20px;
    }
  }
`;
