
//lead_count一般（百分率（％）表記じゃ無いやつ）
export const leadCountDataGenerator = (raw_data_array: any, funnel_name: string, target_key_name: string) => {
  let key = target_key_name
  const lnc = raw_data_array.flatMap((r: any) => (r.lead_new_count) ? r.lead_new_count : [])
  const f_lnc = lnc.filter((item: any) => (item.funnel_name !== undefined && item.funnel_name !== null) && item.funnel_name === funnel_name)
  const sum_f_lnc = f_lnc.reduce((acc: any, cur: any) => acc + cur[key], 0)
  return sum_f_lnc
}

//lead_countのRate関連用
export const leadCountRateDataGenerator = (raw_data_array: any, funnel_name: string, target_key_name: string) => {
  let key = target_key_name
  const lnc = raw_data_array.flatMap((r: any) => (r.lead_new_count) ? r.lead_new_count : [])
  const f_lnc = lnc.filter((item: any) => (item.funnel_name !== undefined && item.funnel_name !== null) && item.funnel_name === funnel_name)
  const sum_f_lnc = f_lnc.reduce((acc: any, cur: any) => acc + cur[key], 0) / raw_data_array.length
  return sum_f_lnc
}

//taransition関連用
export const transitionRateDataGenerator = (raw_data_array: any, funnel_name: string, target_key_name: string) => {
  let key = target_key_name
  const ltr = raw_data_array.flatMap((r: any) => (r.lead_transition_rate) ? r.lead_transition_rate : [])
  const f_ltr = ltr.filter((item: any) => (item.funnel_name !== undefined && item.funnel_name !== null) && item.funnel_name === funnel_name)
  const sum_f_ltr = f_ltr.reduce((acc: any, cur: any) => acc + cur[key], 0) / raw_data_array.length
  return sum_f_ltr
}

export const leadTimeGenerator = (raw_data_array: any, funnel_name: string) => {
  const lt = raw_data_array.flatMap((r: any) => (r.lead_time) ? r.lead_time : [])
  const f_lt = lt.filter((item: any) => (item.funnel_name !== undefined && item.funnel_name !== null) && item.funnel_name === funnel_name)
  const sum_f_lt = f_lt.reduce((acc: any, cur: any) => acc + cur.lead_time_total, 0)
  return sum_f_lt / raw_data_array.length
}

export const genFix = (genData: any) => {
  if (genData === undefined || genData === null || genData === Infinity || isNaN(genData)) {
    return 0;
  }
  return genData;
}

