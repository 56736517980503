function churnRateAverageGenerator(raw_data_for_cohort: any, target_data: string, target_filter_key: string, target_filter_value: string, target_span: number) {
  let churn_rate_raw_data: any;
  switch(target_data) {
    case 'churn_rate':
      churn_rate_raw_data = raw_data_for_cohort.map((r: any) => {
        return {
          result_at: r.result_at,
          churn_data: r.churn_rate.churn_rate_total
        }
      });
      break;
    case 'revenue_churned':
      churn_rate_raw_data = raw_data_for_cohort.map((r: any) => {
        return {
          result_at: r.result_at,
          churn_data: r.revenue_churned.revenue_churned_total
        }
      });
      break;
    case 'client_churned':
      churn_rate_raw_data = raw_data_for_cohort.map((r: any) => {
        return {
          result_at: r.result_at,
          churn_data: r.client_churned.client_churned_total
        }
      });
      break;
    default:
      churn_rate_raw_data = raw_data_for_cohort.map((r: any) => {
        return {
          result_at: r.result_at,
          churn_data: r.churn_rate.churn_rate_total
        }
      });
  }

  // 平均値を計算
  const churn_rate_raws_average = churn_rate_raw_data.map((item: any, index: number) => {
    return [
      ...churn_rate_raw_data.slice(index)
    ].reduce((acc:any, cur:any) => {
      if(cur.churn_data !== null && cur.churn_data !== undefined) {
        return {
          index: index + 1,
          length: churn_rate_raw_data.length - index,
          churn_sum: (acc.churn_sum + cur.churn_data),
          churn_average: (acc.churn_sum + cur.churn_data)/(churn_rate_raw_data.length - index)
        }
      }
      // cur.churn_dataがnullだったら
      return {
        index: index + 1,
        length: churn_rate_raw_data.length - index,
        churn_sum: acc.churn_sum + 0,
        churn_average: (acc.churn_sum + cur.churn_data)/(churn_rate_raw_data.length - index)
      }
    },{churn_sum: 0})
  })

  const fill_loop = (array: any) => {
    for(let cell = array.length; cell < target_span; cell++) {
      array.push({churn_average:""}) 
    }
    return array
  }

  const filled_average_array = fill_loop(churn_rate_raws_average)

  console.log('churn_rate_raws_average',churn_rate_raws_average)
  console.log('filled_average_array',filled_average_array)
  
  return churn_rate_raws_average;
}



export default churnRateAverageGenerator;