import React, { Component } from "react";
import styled from 'styled-components';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ResponsiveContainer, ComposedChart, Line, Bar, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import revenueIcon from '../../../images/icons/graph_icon_revenue.svg';
import profitIcon from '../../../images/icons/graph_icon_profit.svg';
import legendIcon from "../../../images/icons/area_legend_icon.svg";
import CommentTick from "../../Comment/CommentTick";
import CommentModule from "../../Comment/CommentModule";
import  { yAxisFormatter } from "../../Util/yAxisFormatter"

interface Props {
  areaRef: any;
  integrated_array: any;
  comments: any;
}

interface State {}

export default class ForecastPLGraphFuture extends Component<Props, State> {
  area: any;
  tooltip: HTMLElement | null;
  point: any;

  constructor(props: any) {
    super(props);

    this.area = null;
    this.tooltip = null;
    this.point = null;
  }

  showToolTip = (e: any) => {
    if (this.tooltip !== null) {
      console.log("this.tooltip", this.tooltip);
      console.log("showTooltip e:", e);

      this.tooltip.style.left = `${(e.cx + 50).toString()}px`;
      this.tooltip.style.top = `${(e.cy - 80).toString()}px`;
      // 最後のデータだった場合は、dotの左隣に出力
      if (this.props.integrated_array.length - 1 === e.index) {
        console.log('last data');
        console.log('last data x', `${(e.cx - 150).toString()}px`);
        this.tooltip.style.left = `${(e.cx - 170).toString()}px`;
      }
      this.tooltip.style.opacity = "1";
      console.log('this.tooltip.childNodes', this.tooltip.childNodes);
      const tooltipDate = this.tooltip.childNodes[0] as HTMLElement
      const tooltipDataName = this.tooltip.childNodes[1] as HTMLElement
      const tooltipDataKey_total = this.tooltip.childNodes[2].childNodes[0] as HTMLElement
      const tooltipDataKey_obj = this.tooltip.childNodes[3].childNodes[0] as HTMLElement
      const tooltipDataValue_total = this.tooltip.childNodes[2].childNodes[1] as HTMLElement
      const tooltipDataValue_obj = this.tooltip.childNodes[3].childNodes[1] as HTMLElement
      
      //ホバーしたラインのデータキーによって、日付を出し分け
      tooltipDate.innerHTML = new Date(e.payload.result_at.seconds * 1000).getFullYear() + "/" + (new Date(e.payload.result_at.seconds * 1000).getMonth() + 1)

      //キー名出し分け
      console.log('e.dataKey', e.dataKey)
      
      if (e.dataKey.includes("revenue")) {
        tooltipDataName.innerHTML = "売上"
      } else if (e.dataKey.includes("profit")) {
        tooltipDataName.innerHTML = "営業利益" 
      }

      //実績/目標の出し分け
      tooltipDataKey_total.innerHTML = "実績"
      tooltipDataKey_obj.innerHTML = "目標" 
      
      //値の出しわけ
      if(e.value === null || e.value === undefined) {
        tooltipDataValue_total.innerHTML = "<span>NoData</span>"
      } else {
        tooltipDataValue_total.innerHTML = e.value.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })
      }

      const dataKey = e.dataKey
      if(e.payload.revenue_objective_forecasted === null || e.payload.revenue_objective_forecasted === undefined || e.payload.profit_operating_profit_objective_forecasted === null || e.payload.profit_operating_profit_objective_forecasted === undefined) {
        tooltipDataValue_obj.innerHTML = "<span>NoData</span>"
      } else if(dataKey === 'revenue_total_forecasted') {
        tooltipDataValue_obj.innerHTML = e.payload.revenue_objective_forecasted.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })
      } else {
        tooltipDataValue_obj.innerHTML = e.payload.profit_operating_profit_objective_forecasted.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })
      }

      // document.getElementsByClassName('DataContent')[e.index].classList.add('activeDotShown');
      this.tooltip.style.zIndex = "99999";
      // this.tooltip.innerHTML = e.value;
    } else {
      console.log("showTooltip e is null:", e);
      return false;
    }
  };
  hideTooltip = (e: any) => {
    if (this.tooltip !== null) {
      console.log("this.tooltip", this.tooltip);
      console.log("hideTooltip e:", e);
      this.tooltip.style.opacity = "0";
      // document.getElementsByClassName('DataContent')[e.index].classList.remove('activeDotShown');
      // this.tooltip.style.zIndex = "-1";
    } else {
      console.log("hideTooltip e is null:", e);
      return false;
    }
  };

  render() {
    return (
      <>
        {
          this.props.integrated_array.length > 0 ? (
            <Graph>
              <ResponsiveContainer>
                <ComposedChart width={500} height={400} data={this.props.integrated_array} margin={{ top: 20, right: 20, bottom: 20, left: 20 }}  ref={this.props.areaRef}>
                  <CartesianGrid vertical={false} stroke="#f5f5f5" />

                  <XAxis
                    dataKey={"result_at.seconds"}
                    scale="point"
                    tick={<CommentTick comments={this.props.comments} target_data_name='PL' />}
                    tickLine={false}
                    padding={{ left: 0, right: 0 }}
                  />


                  <YAxis tickCount={10} interval={0} tickLine={false} axisLine={false} tickMargin={10} tickFormatter={tick => yAxisFormatter(tick, 'revenue')} />
                  {/* <Tooltip content={customTooltip} /> */}
                  <Tooltip wrapperStyle={{ display: "none" }} />
                  <Legend iconSize={20} verticalAlign="top" align="left" margin={{ bottom: 5 }} content={customLegend}/>
                  <Area name="営業利益目標" type="linear" dataKey="profit_operating_profit_objective_forecasted" stroke="transparent" fill="#E53E66" fillOpacity="0.35" />
                  <Area name="売上目標" type="linear" dataKey="revenue_objective_forecasted" stroke="transparent" fill="#0066FF" fillOpacity="0.35" />
                  <Line 
                    name="売上" 
                    type="linear" 
                    dot={false} 
                    dataKey="revenue_total_forecasted" 
                    stroke="#0066FF" 
                    strokeWidth="3" 
                    strokeDasharray="5 5" />
                  <Line 
                    name="売上" 
                    type="linear" 
                    dot={false} 
                    dataKey="revenue_total" 
                    stroke="#0066FF"
                    strokeWidth="3" 
                    activeDot={{
                      onMouseEnter: this.showToolTip,
                      onMouseLeave: this.hideTooltip
                    }}
                  />
                  
                  <Line name="営業利益" type="linear" dot={false} dataKey="profit_operating_profit_total_forecasted" stroke="#E53E66" strokeWidth="3" strokeDasharray="5 5" />
                  <Line 
                    name="営業利益" 
                    type="linear" 
                    dot={false} 
                    dataKey="profit_operating_profit_total" 
                    stroke="#E53E66" 
                    strokeWidth="3" 
                    activeDot={{
                      onMouseEnter: this.showToolTip,
                      onMouseLeave: this.hideTooltip
                    }}
                  />
                </ComposedChart>
              </ResponsiveContainer>

              <UiChartTooltip className="ui-chart-tooltip" ref={ref => (this.tooltip = ref)}>
                <div className="tooltip-date"></div>
                <div className="tooltip-data-name"></div>
                <div className="toltip-databox">
                  <div className="tooltip-data-key"></div>
                  <div className="tooltip-data-value"></div>
                </div>
                <div className="toltip-databox">
                  <div className="tooltip-data-key"></div>
                  <div className="tooltip-data-value"></div>
                </div>
              </UiChartTooltip>

              <CommentModule />
            </Graph>
          ):(
            <NoData>グラフを表示できません</NoData>
          )
        }
      </>
    );
  }
}

// ==================================================
// Legend

const customLegend = () => {
  return (
    <ul
      style={{
        display: 'flex',
        fontSize: '12px',
        color: '#283f46',
        marginLeft: '16px'
      }}
    >
      <LegendItem className="area">目標</LegendItem>
      <LegendItem className="line">実績</LegendItem>
      <LegendItem className="dots">予測</LegendItem>
    </ul>
  );
};
// ==================================================

// Graph
const Graph = styled.div`
  width: 100%;
  height: 272px;
  margin-bottom: 18px;
  padding: 0 10px;
  box-sizing: border-box;
  position: relative;
  z-index: 100;

  .recharts-legend-wrapper {
    top: 1px !important;
    left: 10px !important;
  }
  .recharts-legend-item-text {
    font-size: 12px;
    color: #283f46;
  }
  .recharts-legend-item {
    position: relative;
    svg {
      display: none !important;
    }
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      transform: translateY(-50%);
    }
    &.legend-item-0,
    &.legend-item-2 {
      padding-left: 15px;
      &::before {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        top: 54%;
      }
    }
    &.legend-item-1,
    &.legend-item-3 {
      padding-left: 24px;
      &::before {
        width: 19px;
        height: 14px;
        top: 50%;
      }
    }
    &.legend-item-0 {
      &::before {
        background-color: #0066FF;
      }
    }
    &.legend-item-1 {
      &::before {
        background-image: url(${revenueIcon});
      }
    }
    &.legend-item-2 {
      &::before {
        background-color: #ec6e6e;
      }
    }
    &.legend-item-3 {
      &::before {
        background-image: url(${profitIcon});
      }
    }
  }
  .recharts-surface {
    overflow: visible;
  }
  .recharts-cartesian-axis-tick {
    .recharts-cartesian-axis-tick-value {
      font-size: 10px;
      color: #435878;
    }
  }
`;

// GraphXAxis
const GraphXAxis = styled.div`
  position: relative;
  top: -20px;
  width: 100%;
  &.marketing_funnel {
    max-width: 660px;
    margin: 0 auto;
  }
`;

const UiChartTooltip = styled.div`
  opacity: 0;
  position: absolute;
  z-index: -1;
  min-width: 178px;
  width: auto;
  min-height: 48px;
  height: auto;
  background: white;
  border: 1px solid #e2e9f3;
  border-radius: 2px;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.25);
  padding: 8px 9px;
  .tooltip-date {
    font-size: 13px;
    font-weight: bold;
    color: #435878;
    margin-bottom: 10px;
  }
  .tooltip-data-name {
    font-size: 12px;
    font-weight: bold;
    color: #7591BC;
    margin-bottom: 10px;
  }
  .toltip-databox {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .tooltip-data-key {
    font-size: 10px;
    color: #435878;
    width: 50%;
    white-space: nowrap;
  }
  .tooltip-data-value {
    font-size: 12px;
    font-weight: bold;
    color: #435878;
    span {
      color: #AF002A;
    }
  }
`;

//customLegend
const LegendItem = styled.li`
  margin-right: 16px;
  &.dots {
    &::before {
      content: "•••";
      display: inline-block;
      width: 16px;
      height: 3px;
      color:#C4C4C4;
      font-weight: bold;
      margin-right: 8px;
      position: relative;
      bottom: 1px;
    }
  }
  &.line {
    &::before {
      content: "";
      display: inline-block;
      border-bottom: 2px solid #C4C4C4;
      width: 16px;
      height: 3px;
      color:#C4C4C4;
      font-weight: bold;
      margin-right: 8px;
      position: relative;
      bottom: 3px;
    }
  }
  &.area {
    position: relative;
    padding-left: 24px;
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 19px;
      height: 14px;
      top: 50%;
      background-image: url(${legendIcon});
      background-color: transparent;
    }
  }
`;

const NoData = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #435878;
  margin-bottom: 8px;
  &.main {
    margin-bottom: 8px;
  }
`