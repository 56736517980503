import { ADD_MEMBER_REQUESTED, UPDATE_MEMBER_REQUESTED, GET_MEMBERS_REQUESTED, FETCH_MEMBER_REQUESTED, DELETE_MEMBER_REQUESTED, GET_AUTH_REQUESTED, TRANSFER_OWNERSHIP_REQUESTED, TOGGLE_MEMBER_TO_EDIT_POST_STATUS, TOGGLE_MEMBER_TO_ADD_POST_STATUS,
  TOGGLE_MEMBER_TO_DELETE_POST_STATUS } from "./types"

export const addMemberRequest = (firstName: string, lastName: string, email: string, password: string, company_id: string) => ({
  type: ADD_MEMBER_REQUESTED,
  firstName,
  lastName,
  email,
  password,
  company_id,
})

export const updateMemberRequest = (id: string, firstName: string, lastName: string, email: string, company_id: string) => ({
  type: UPDATE_MEMBER_REQUESTED,
  id,
  firstName,
  lastName,
  email,
  company_id
})

export const getMembersRequest = (company_id: string) => ({
  type: GET_MEMBERS_REQUESTED,
  company_id: company_id,
})

export const requestMemberFetch = (memberId: string) => ({
  type: FETCH_MEMBER_REQUESTED,
  payload: memberId,
})

export const deleteMemberRequest = (company_id: string, docId: string ) => ({
  type: DELETE_MEMBER_REQUESTED,
  company_id,
  docId
})

export const getAuthReq = () => ({
  type: GET_AUTH_REQUESTED
})

export const transferOwnershipRequest = (company_id: string, new_owner: any, old_owner: any, history: any) => ({
  type: TRANSFER_OWNERSHIP_REQUESTED,
  company_id,
  new_owner,
  old_owner,
  history
})

export const toggleMemberToEditPostStatus = (post_status: boolean) => ({
  type: TOGGLE_MEMBER_TO_EDIT_POST_STATUS,
  post_status
})

export const toggleMemberToAddPostStatus = (post_status: boolean) => ({
  type: TOGGLE_MEMBER_TO_ADD_POST_STATUS,
  post_status
})

export const toggleMemberToDeletePostStatus = (post_status: boolean) => ({
  type: TOGGLE_MEMBER_TO_DELETE_POST_STATUS,
  post_status
})

