import { call } from "redux-saga/effects";
import firebase from "firebase";

const db = firebase.firestore();

// 過去6ヶ月分データ取得
function* getRawDataDetailData(company_id, filter_name, target_data_name, selected_data_config_data_type, selected_data_config_data_span, custom_span_start_time, custom_span_end_time) {
  try {

    // 期間設定
    const start_time = new Date(custom_span_start_time.seconds * 1000);
    const end_time = new Date(custom_span_end_time.seconds * 1000);

    // コレクション名を設定
    const collection_name = target_data_name + '_detail';
    console.log('collection_name', collection_name);


    // コレクショングループでためす
    const targetCollectionGroup = collection_name => {
      return db
        .collectionGroup(collection_name)
        .where('company_id', '==', company_id)
        .where('result_at', '>=', start_time)
        .where('result_at', '<=', end_time)
        .get();
    };
    const snapshot = yield call(targetCollectionGroup, collection_name);

    let results = [];
    snapshot.forEach(doc => {
      results = [...results, { ...doc.data(), docId: doc.id }];
    });

    return results;

  } catch (e) {
    console.log("error message", e.message);
  }
}


export default getRawDataDetailData;
