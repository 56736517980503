/**
 * @description データの存在をチェックして、なかったらnullを返す。
 * @param raw_data_result raw_dataが入ります。
 * @param dataFiledKey raw_dataのフィールド名が入ります（arpuとか、cacなど）
 * @param typeOfData 対象データの種別が入ります（total, objectiveなど）
 */

export const dataErrorEscaper = (
  raw_data_result: any,
  dataFiledKey: string,
  typeOfData: string
) => {
  if (raw_data_result !== undefined && raw_data_result[dataFiledKey]) {
    return raw_data_result[dataFiledKey][`${dataFiledKey}_${typeOfData}`];
  } else {
    return null;
  }
};

export const forecastErrorEscaper = (result_data: any, datakey: string) => {
  if (result_data !== undefined && result_data[datakey]) {
    return result_data[datakey];
  } else {
    return 0;
  }
};

export const dataForFunnelErrorEscaper = (args: {
  raw_data_result: any;
  funnel_name: string;
  target_key: string;
}) => {
  const { raw_data_result, funnel_name, target_key } = args;
  if (
    raw_data_result !== undefined &&
    raw_data_result !== null &&
    raw_data_result.lead_new_count !== undefined &&
    raw_data_result.lead_new_count !== null
  ) {
    return raw_data_result.lead_new_count.find(
      (lnc: any) => lnc.funnel_name === funnel_name
    )[target_key];
  } else {
    return null;
  }
};
