import { takeLatest } from "redux-saga/effects"

import { GET_RESULTS_FOR_TARGET_FORECAST_REQUEST, GET_INTEGRATED_ARRAY_FORECAST_REQUEST, GET_RESULTS_FOR_TARGET_FORECAST_CUSTOM_REQUEST } from "../reducks/targetForecast/types"

import getIntegratedArray from './targetForecastSagas/getIntegratedArray'
import getResultsForTargetForcastCustom from './targetForecastSagas/getResultsForTargetForcastCustom'
import getResultsForTargetForecast from './targetForecastSagas/getResultsForTargetForecast'

const forecastSagas = [
  takeLatest(GET_RESULTS_FOR_TARGET_FORECAST_REQUEST, getResultsForTargetForecast), 
  takeLatest(GET_RESULTS_FOR_TARGET_FORECAST_CUSTOM_REQUEST, getResultsForTargetForcastCustom), 
  takeLatest(GET_INTEGRATED_ARRAY_FORECAST_REQUEST, getIntegratedArray)]

export default forecastSagas





