import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import GlobalHeader from "../Layout/GlobalHeader";
import LandingMv from "../../images/landing/mv.jpg";
import FormModal from "./Form/FormModal";

import { getInquiriesRequest } from "../../reducks/inquiries/actions";

const InstallInquiry = () => {
  const dispatch = useDispatch();

  const inquiries = useSelector((state: any) => state.inquiries);
  useEffect(() => {
    dispatch(getInquiriesRequest());
  }, []);

  return (
    <>
      <InquiryContainer>
        <GlobalHeader />

        <InquiryTitleBlock>
          <InquiryTitle>ビジネスをもっと確実なものへ</InquiryTitle>
          <InquiryDesc>事業データから、会社の課題を可視化し、たしかな成果へと導きます。</InquiryDesc>

          <NewsSection>
            <ul>
              {inquiries.map((inquiry: any) => (
                <NewsItem>
                  <NewsItemTitle>
                    <h4>{inquiry.date}</h4>
                    <h3>{inquiry.title.rendered}</h3>
                  </NewsItemTitle>
                  <NewsItemContent>
                    <div dangerouslySetInnerHTML={{__html: inquiry.content.rendered}}></div>
                  </NewsItemContent>
                </NewsItem>
              ))}
            </ul>
          </NewsSection>

          <FormModal />
        </InquiryTitleBlock>
      </InquiryContainer>
    </>
  );
};

export default InstallInquiry;

const InquiryContainer = styled.div`
  background: url(${LandingMv}) no-repeat center center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: relative;
`;

const InquiryTitleBlock = styled.div`
  text-align: center;
  position: absolute;
  top: 35%;
  left: 0;
  right: 0;
`;

const InquiryTitle = styled.h2`
  font-size: 48px;
  line-height: 72px;
  color: #fff;
  margin: 0;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
`;

const InquiryDesc = styled.p`
  font-size: 18px;
  line-height: 27px;
  color: #fff;
  margin-bottom: 60px;
`;

const NewsSection = styled.section`
  width: 80%;
  margin: 0 auto 40px;
  max-height: 200px;
  overflow: scroll;
  background: rgba(255,255,255,.75);
  padding-top: 10px;
`;

const NewsItem = styled.li`
  width: 100%;
  overflow: scroll;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid black;
`;

const NewsItemTitle = styled.div`
  width: 30%;
`;

const NewsItemContent = styled.div`
  width: 70%;
  text-align: left;
`;