import React from "react";
import styled from 'styled-components';
import { useSelector } from "react-redux";
import { yAxisFormatter } from "../Util/yAxisFormatter"
import { dateFormatter, tooltipDateFormatter } from "../Util/DateFormatter"
import { ResponsiveContainer, ComposedChart, Line, Bar,  XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine } from "recharts";
import { NodeArray } from "typescript";




function StackedDetailChartGrr(props: any) {

  const target_data_name = useSelector((state: any) => state.target_data.target_data_name);

  const resultSpan = useSelector((state: any) => state.target_data.resultSpan);

  // const target_data = useSelector((state: any) => state.results.target_data);
  const target_data = useSelector((state: any) => state.target_data.target_data);
  const key_total = useSelector((state: any) => state.target_data.key_total);
  const key_objective = useSelector((state: any) => state.target_data.key_objective);
  const key_moving_average_total = useSelector((state: any) => state.target_data.key_moving_average_total);

  const customTooltip = (props:any) => {
    //グラフのpayloadを取得
    const parentArray = props.payload;
    console.log("parentArray:", parentArray)

    if (parentArray != null) {

      //取得したpayload(親)の中のpayload(子)を取得
      const childPayload = parentArray.flatMap((childPayload: any) => childPayload.payload);

      //取得した値から[0]番目のみ取得
      const tooltipPayload = childPayload[0];
      console.log('tooltipPayload',tooltipPayload)
      if (tooltipPayload != null) {
        return (
          <TooltipContent>
            <TooltipDate>{tooltipDateFormatter(resultSpan, tooltipPayload.result_at.seconds)}</TooltipDate>

            {
              parentArray.map((payload:any) => {
                return(
                  <TooltipData>
                    <TooltipLabel>
                      {
                        payload.name.includes('grr_total') && 
                        <>
                          <span style={{color: "#35455D"}}>■</span>GRR
                        </>
                      }
                      {
                        payload.name.includes('mrr_existing_value_total') && 
                        <>
                          <span style={{color: "#E53E66"}}>■</span>Existing
                        </>
                      }
                      {
                        payload.name.includes('mrr_churn_value_total') && 
                        <>
                          <span style={{color: "#B7D20D"}}>■</span>Churn
                        </>
                      }
                      {
                        payload.name.includes('mrr_contraction_value_total') &&  
                        <>
                          <span style={{color: "#009479"}}>■</span>Contraction
                        </>
                      }
                    </TooltipLabel>
                      {
                        payload.name === 'grr_total'
                        ?
                        <TooltipVal>{payload.value * 100 + "%"}</TooltipVal>
                        :
                        
                        payload.name === 'mrr_existing_value_total'
                        ?
                        <TooltipVal>{payload.value.toLocaleString("ja-JP", { style: "currency", currency: "JPY" })}</TooltipVal>
                        :

                        payload.name === 'mrr_churn_value_total' || payload.name === 'mrr_contraction_value_total' ?
                          <TooltipVal style={{color:'#AF002A'}}>{payload.value.toLocaleString("ja-JP", { style: "currency", currency: "JPY" })}</TooltipVal>

                        :""
                      }
                      
                    {/* <TooltipVal style={{color:payload.fill}}>{payload.value.toLocaleString("ja-JP", { style: "currency", currency: "JPY" })}</TooltipVal> */}
                  </TooltipData>
                )
              })
            }            
          </TooltipContent>
        )
      }
    return;
    }
    return false;
  }
  return (
    <div>
      {
        target_data.length > 0 ? (
          <>
            <Graph>
              <ResponsiveContainer>
                <ComposedChart stackOffset="sign" width={1000} height={400} data={target_data} barSize="0" margin={{ bottom: 24 }} ref={props.areaRef}>
                  <CartesianGrid stroke="#f5f5f5" vertical={false} />
                  <XAxis 
                    dataKey={"result_at.seconds"} 
                    tickFormatter={(tick) => dateFormatter(resultSpan, (tick*1000))}
                    axisLine={{ stroke: '#f5f5f5' }}
                  />
                  <ReferenceLine y={0} yAxisId={1} stroke="#35455D"/>
                  <YAxis 
                    yAxisId={0} tickCount={10} 
                    interval={0} tickLine={false} 
                    tickMargin={10} tickFormatter={tick => yAxisFormatter(tick, target_data_name)} 
                    orientation="right"
                  />
                  <YAxis 
                    yAxisId={1} tickCount={10} 
                    interval={0} tickLine={false} 
                    tickMargin={10} tickFormatter={tick => yAxisFormatter(tick, 'mrr_total_value')} 
                  />
                  {/* churnとかcontractionはマイナスとして処理したい */}
                  <Bar stackId="detail" fill="#B7D20D" yAxisId={1} dataKey="mrr_churn_value_total"/>
                  <Bar stackId="detail" fill="#009479" yAxisId={1} dataKey="mrr_contraction_value_total"/>
                  <Bar stackId="detail" fill="#E53E66" yAxisId={1} dataKey="mrr_existing_value_total"/>
                  <Line yAxisId={0} dataKey="grr_total" strokeWidth="3" stroke="#35455D"></Line>

                  {/* <Tooltip wrapperStyle={{ display: "none" }} /> */}
                  <Tooltip content={customTooltip} />
                </ComposedChart>
              </ResponsiveContainer>
            </Graph>
          </>
        ):(
          <NoData>グラフを表示できません</NoData>
        )
      }
    </div>
  );
}

export default StackedDetailChartGrr;

// Graph
const Graph = styled.div`
  width: 100%;
  height: 272px;
  padding: 0 10px;
  box-sizing: border-box;

  .recharts-legend-wrapper {
    overflow: visible;
    top: -20px !important;
    left: 10px !important;
    font-size: 12px;
    color: #283f46;
  }

  .recharts-surface {
    position: relative;
    top: -2px;
    overflow: visible;
  }
  .recharts-cartesian-axis-tick {
    width: 100px !important;
    .recharts-cartesian-axis-tick-value {
      font-size: 10px;
      color: #435878;
    }
  }
  .recharts-yAxis {
    .recharts-cartesian-axis-line {
      stroke: #f5f5f5;
    }
  }
`;

const NoData = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #435878;
  margin-bottom: 8px;
  &.main {
    margin-bottom: 8px;
  }
`

// Tooltipのカスタムスタイリング
const customWrapperStyle = {
  background: "white",
  width: "auto",
  height: "auto",
  borderRadius: "2px",
  boxShadow: "0 4px 5px 0 rgba(0, 0, 0, .25)",
  zIndex: "99",
  // overflow: "hidden",
  padding: "8px 16px",
  boxSizing: "border-box"
};
// Tooltipのカスタムスタイリング
const productComparisonWrapperStyle = {
  background: "white",
  width: "auto",
  height: "auto",
  borderRadius: "2px",
  boxShadow: "0 4px 5px 0 rgba(0, 0, 0, .25)",
  zIndex: "99",
  padding: "8px 16px"
};

// Tooltip
const TooltipContent = styled.div`
  width: 178px;
  padding: 10px 9px 9px 9px;
  background-color: #fff;
`;

const TooltipDate = styled.p`
  font-size: 10px;
  font-weight: bold;
  line-height: 15px;
  margin-left: 13px;
  color: #435878;
  margin-bottom: 10px;
`;

const TooltipData = styled.dl`
  display: flex;
  padding: 5px 15px;
  box-sizing: border-box;
  text-arign: left;
`;
const TooltipLabel = styled.td`
  font-size: 8px;
  font-weight: normal;
  color: #435878;
  width: 80px;
  text-align: left;
  box-sizing: border-box;
`;
const TooltipVal = styled.td`
  font-size: 10px;
  color: #435878;
  text-align: right;
  width: 90px;
  box-sizing: border-box;
`
