import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { unitSwitcher } from "../../Util/unitSwitcher";
import { colorSwitcherRate, colorSwitcherDif } from "../../Util/colorSwitcher";
import MetricsHeader from "../../Metrics/MetricsHeader";
import DataGraph from "../../Parts/DataGraph";
import { startTargetDataSequence } from "../../../reducks/targetData/actions";
import { updateForecastMetricsData } from "../../../reducks/targetForecast/actions";
import reached_arrow from "../../../images/icons/reached_arrow.svg";
import unreached_arrow from "../../../images/icons/unreached_icon.svg";
import open_arrow from "../../../images/icons/arrow_down.svg";
import close_arrow from "../../../images/icons/arrow_up.svg";
import close from "../../../images/icons/close-large.svg";

import handleMetricsSimulation from "../../Util/handleMetricsSimulation";

function ForecastMetricsTableDefault(props: any) {
  const [metricsContentClicked, setMetricsContentClicked] = useState(false);

  const [revenueOpen, setRevenueOpen] = useState(false);
  const [mrrOpen, setMrrOpen] = useState(false);
  const [arpuOpen, setArpuOpen] = useState(false);
  const [UEOpen, setUEOpen] = useState(false);
  const [PPOpen, setPPOpen] = useState(false);
  const [churnRateOpen, setChurnRateOpen] = useState(false);
  const [cacOpen, setCacOpen] = useState(false);
  const [ltvOpen, setLtvOpen] = useState(false);
  const [ConversionRateOpen, setConversionRateOpen] = useState(false);
  const [nrrOpen, setNrrOpen] = useState(false);
  const [grrOpen, setGrrOpen] = useState(false);
  const [quickRatioOpen, setQuickRatioOpen] = useState(false);
  const [acvCumulativeOpen, setAcvCumulativeOpen] = useState(false);
  const [acvNewOpen, setAcvNewOpen] = useState(false);
  const [cpaOpen, setCpaOpen] = useState(false);

  const dispatch = useDispatch();

  const target_forecast_metrics_array = useSelector((state: any) => state.target_forecast.target_forecast_metrics_array)
  const resultSpan = useSelector((state: any) => state.target_data.resultSpan);
  const selected_data_config_data_type = useSelector((state: any) => state.raw_data.selected_data_config.data_type);
  const raw_data_array = useSelector((state: any) => state.raw_data.raw_data_array);
  const target_data_name = useSelector((state: any) => state.target_data.target_data_name);
  const key_total = useSelector((state: any) => state.target_data.key_total);
  const key_objective = useSelector((state: any) => state.target_data.key_objective);
  const key_rate = useSelector((state: any) => state.target_data.key_rate);
  const key_last_time_comparison_rate = useSelector((state: any) => state.target_data.key_last_time_comparison_rate);
  const key_last_time_difference = useSelector((state: any) => state.target_data.key_last_time_difference);
  const key_difference = useSelector((state: any) => state.target_data.key_difference);
  const target_data = useSelector((state: any) => state.target_data.target_data);

  const [metricsDataisOpen, setMetricsDataisOpen] = useState(false);
  const [metricsTitle, setMetricsTitle] = useState("");
  const handleMetricsClick = (location: string, metricsTitle: string) => {
    setMetricsTitle(metricsTitle);
    dispatch(
      startTargetDataSequence(
        "/metrics/"+location,
        raw_data_array,
        selected_data_config_data_type
      )
    );
    setMetricsDataisOpen(true);
  };

  const handleSimulation = (updatedValue: string, resultAt: any,  dataKey: string) => {
    dispatch(
      updateForecastMetricsData(
        handleMetricsSimulation(updatedValue, resultAt, dataKey, target_forecast_metrics_array)
      )
    )
  }

  return (
    <Wrapper className="ForecastMetricsTableDefault">
          <DataTableSC id="table_width">
            <DataHeader>
              <p style={{ width: "150px" }}>&nbsp;</p>
              {
                // dailyかweeklyだったら一日単位で表示
                resultSpan !== "monthly" &&
                target_forecast_metrics_array &&
                target_forecast_metrics_array.length > 0 &&
                target_forecast_metrics_array.map((result: any) => (
                    <p>
                      {result.result_at.seconds == null ? "データ不足" : `${new Date(result.result_at.seconds * 1000).getFullYear()}`}/{`${new Date(result.result_at.seconds * 1000).getMonth() + 1}`}/{`${new Date(result.result_at.seconds * 1000).getDate()}`}
                    </p>
                  ))
              }
              {
                // monthlyだったら月まで表示
                resultSpan === "monthly" &&
                target_forecast_metrics_array &&
                target_forecast_metrics_array.length > 0 &&
                target_forecast_metrics_array.map((result: any) => (
                    <p>
                      {result.result_at.seconds == null ? "データ不足" : `${new Date(result.result_at.seconds * 1000).getFullYear()}`}/{`${new Date(result.result_at.seconds * 1000).getMonth() + 1}`}
                    </p>
                  ))
              }
            </DataHeader>
            <DataBody style={{ display: "flex" }}>
              {/* DataTitle(ul) */}
              <DataTitleSC>
                {/* 営業利益 */}
                <TitleItemSC>
                  <label htmlFor="revenue">
                    <h4 className={revenueOpen ? "opened" : "closed"}>Revenue</h4>
                    <input type="checkbox" id="revenue" checked={revenueOpen} onChange={() => setRevenueOpen(!revenueOpen)} />
                  </label>
                  {revenueOpen && (
                    <SummaryListSC>
                      <SummaryItemSC className="label">目標</SummaryItemSC>
                      <SummaryItemSC className="label">達成率</SummaryItemSC>
                      <SummaryItemSC className="label">差異</SummaryItemSC>
                      <SummaryItemSC className="label">昨対比</SummaryItemSC>
                    </SummaryListSC>
                  )}
                </TitleItemSC>
                <TitleItemSC>
                  <label htmlFor="mrr">
                    <h4 className={mrrOpen ? "opened" : "closed"}>MRR</h4>
                    <input type="checkbox" id="mrr" checked={mrrOpen} onChange={() => setMrrOpen(!mrrOpen)} />
                  </label>
                  {mrrOpen && (
                    <SummaryListSC>
                      <SummaryItemSC className="label">目標</SummaryItemSC>
                      <SummaryItemSC className="label">達成率</SummaryItemSC>
                      <SummaryItemSC className="label">差異</SummaryItemSC>
                      <SummaryItemSC className="label">昨対比</SummaryItemSC>
                    </SummaryListSC>
                  )}
                </TitleItemSC>
                <TitleItemSC>
                  <label htmlFor="arpu">
                    <h4 className={arpuOpen ? "opened" : "closed"}>ARPU</h4>
                    <input type="checkbox" id="arpu" checked={arpuOpen} onChange={() => setArpuOpen(!arpuOpen)} />
                  </label>
                  {arpuOpen && (
                    <SummaryListSC>
                      <SummaryItemSC className="label">目標</SummaryItemSC>
                      <SummaryItemSC className="label">達成率</SummaryItemSC>
                      <SummaryItemSC className="label">差異</SummaryItemSC>
                      <SummaryItemSC className="label">昨対比</SummaryItemSC>
                    </SummaryListSC>
                  )}
                </TitleItemSC>
                <TitleItemSC>
                  <label htmlFor="unit_economics">
                    <h4 className={UEOpen ? "opened" : "closed"}>Unit Economics</h4>
                    <input type="checkbox" id="unit_economics" checked={UEOpen} onChange={() => setUEOpen(!UEOpen)} />
                  </label>
                  {UEOpen && (
                    <SummaryListSC>
                      <SummaryItemSC className="label">目標</SummaryItemSC>
                      <SummaryItemSC className="label">達成率</SummaryItemSC>
                      <SummaryItemSC className="label">差異</SummaryItemSC>
                      <SummaryItemSC className="label">昨対比</SummaryItemSC>
                    </SummaryListSC>
                  )}
                </TitleItemSC>
                <TitleItemSC>
                  <label htmlFor="payback_period">
                    <h4 className={PPOpen ? "opened" : "closed"}>Payback Period</h4>
                    <input type="checkbox" id="payback_period" checked={PPOpen} onChange={() => setPPOpen(!PPOpen)} />
                  </label>
                  {PPOpen && (
                    <SummaryListSC>
                      <SummaryItemSC className="label">目標</SummaryItemSC>
                      <SummaryItemSC className="label">達成率</SummaryItemSC>
                      <SummaryItemSC className="label">差異</SummaryItemSC>
                      <SummaryItemSC className="label">昨対比</SummaryItemSC>
                    </SummaryListSC>
                  )}
                </TitleItemSC>
                <TitleItemSC>
                  <label htmlFor="churn_rate">
                    <h4 className={churnRateOpen ? "opened" : "closed"}>Churn Rate</h4>
                    <input type="checkbox" id="churn_rate" checked={churnRateOpen} onChange={() => setChurnRateOpen(!churnRateOpen)} />
                  </label>
                  {churnRateOpen && (
                    <SummaryListSC>
                      <SummaryItemSC className="label">目標</SummaryItemSC>
                      <SummaryItemSC className="label">達成率</SummaryItemSC>
                      <SummaryItemSC className="label">差異</SummaryItemSC>
                      <SummaryItemSC className="label">昨対比</SummaryItemSC>
                    </SummaryListSC>
                  )}
                </TitleItemSC>
                <TitleItemSC>
                  <label htmlFor="cac">
                    <h4 className={cacOpen ? "opened" : "closed"}>CAC</h4>
                    <input type="checkbox" id="cac" checked={cacOpen} onChange={() => setCacOpen(!cacOpen)} />
                  </label>
                  {cacOpen && (
                    <SummaryListSC>
                      <SummaryItemSC className="label">目標</SummaryItemSC>
                      <SummaryItemSC className="label">達成率</SummaryItemSC>
                      <SummaryItemSC className="label">差異</SummaryItemSC>
                      <SummaryItemSC className="label">昨対比</SummaryItemSC>
                    </SummaryListSC>
                  )}
                </TitleItemSC>
                <TitleItemSC>
                  <label htmlFor="ltv">
                    <h4 className={ltvOpen ? "opened" : "closed"}>LTV</h4>
                    <input type="checkbox" id="ltv" checked={ltvOpen} onChange={() => setLtvOpen(!ltvOpen)} />
                  </label>
                  {ltvOpen && (
                    <SummaryListSC>
                      <SummaryItemSC className="label">目標</SummaryItemSC>
                      <SummaryItemSC className="label">達成率</SummaryItemSC>
                      <SummaryItemSC className="label">差異</SummaryItemSC>
                      <SummaryItemSC className="label">昨対比</SummaryItemSC>
                    </SummaryListSC>
                  )}
                </TitleItemSC>
                <TitleItemSC>
                  <label htmlFor="conversion_rate">
                    <h4 className={ConversionRateOpen ? "opened" : "closed"}>有料転換率</h4>
                    <input type="checkbox" id="conversion_rate" checked={ConversionRateOpen} onChange={() => setConversionRateOpen(!ConversionRateOpen)} />
                  </label>
                  {ConversionRateOpen && (
                    <SummaryListSC>
                      <SummaryItemSC className="label">目標</SummaryItemSC>
                      <SummaryItemSC className="label">達成率</SummaryItemSC>
                      <SummaryItemSC className="label">差異</SummaryItemSC>
                      <SummaryItemSC className="label">昨対比</SummaryItemSC>
                    </SummaryListSC>
                  )}
                </TitleItemSC>
                <TitleItemSC>
                  <label htmlFor="nrr">
                    <h4 className={nrrOpen ? "opened" : "closed"}>NRR</h4>
                    <input type="checkbox" id="nrr" checked={nrrOpen} onChange={() => setNrrOpen(!nrrOpen)} />
                  </label>
                  {nrrOpen && (
                    <SummaryListSC>
                      <SummaryItemSC className="label">目標</SummaryItemSC>
                      <SummaryItemSC className="label">達成率</SummaryItemSC>
                      <SummaryItemSC className="label">差異</SummaryItemSC>
                      <SummaryItemSC className="label">昨対比</SummaryItemSC>
                    </SummaryListSC>
                  )}
                </TitleItemSC>
                <TitleItemSC>
                  <label htmlFor="grr">
                    <h4 className={grrOpen ? "opened" : "closed"}>GRR</h4>
                    <input type="checkbox" id="grr" checked={grrOpen} onChange={() => setGrrOpen(!grrOpen)} />
                  </label>
                  {grrOpen && (
                    <SummaryListSC>
                      <SummaryItemSC className="label">目標</SummaryItemSC>
                      <SummaryItemSC className="label">達成率</SummaryItemSC>
                      <SummaryItemSC className="label">差異</SummaryItemSC>
                      <SummaryItemSC className="label">昨対比</SummaryItemSC>
                    </SummaryListSC>
                  )}
                </TitleItemSC>
                <TitleItemSC>
                  <label htmlFor="quick_ratio">
                    <h4 className={quickRatioOpen ? "opened" : "closed"}>Quick Ratio</h4>
                    <input type="checkbox" id="quick_ratio" checked={quickRatioOpen} onChange={() => setQuickRatioOpen(!quickRatioOpen)} />
                  </label>
                  {quickRatioOpen && (
                    <SummaryListSC>
                      <SummaryItemSC className="label">目標</SummaryItemSC>
                      <SummaryItemSC className="label">達成率</SummaryItemSC>
                      <SummaryItemSC className="label">差異</SummaryItemSC>
                      <SummaryItemSC className="label">昨対比</SummaryItemSC>
                    </SummaryListSC>
                  )}
                </TitleItemSC>
                <TitleItemSC>
                  <label htmlFor="acv_cumulative">
                    <h4 className={acvCumulativeOpen ? "opened" : "closed"}>ACV(累積)</h4>
                    <input type="checkbox" id="acv_cumulative" checked={acvCumulativeOpen} onChange={() => setAcvCumulativeOpen(!acvCumulativeOpen)} />
                  </label>
                  {acvCumulativeOpen && (
                    <SummaryListSC>
                      <SummaryItemSC className="label">目標</SummaryItemSC>
                      <SummaryItemSC className="label">達成率</SummaryItemSC>
                      <SummaryItemSC className="label">差異</SummaryItemSC>
                      <SummaryItemSC className="label">昨対比</SummaryItemSC>
                    </SummaryListSC>
                  )}
                </TitleItemSC>
                <TitleItemSC>
                  <label htmlFor="acv_new">
                    <h4 className={acvNewOpen ? "opened" : "closed"}>ACV(新規)</h4>
                    <input type="checkbox" id="acv_new" checked={acvNewOpen} onChange={() => setAcvNewOpen(!acvNewOpen)} />
                  </label>
                  {acvNewOpen && (
                    <SummaryListSC>
                      <SummaryItemSC className="label">目標</SummaryItemSC>
                      <SummaryItemSC className="label">達成率</SummaryItemSC>
                      <SummaryItemSC className="label">差異</SummaryItemSC>
                      <SummaryItemSC className="label">昨対比</SummaryItemSC>
                    </SummaryListSC>
                  )}
                </TitleItemSC>
                <TitleItemSC>
                  <label htmlFor="cpa">
                    <h4 className={cpaOpen ? "opened" : "closed"}>CPA</h4>
                    <input type="checkbox" id="cpa" checked={cpaOpen} onChange={() => setCpaOpen(!cpaOpen)} />
                  </label>
                  {cpaOpen && (
                    <SummaryListSC>
                      <SummaryItemSC className="label">目標</SummaryItemSC>
                      <SummaryItemSC className="label">達成率</SummaryItemSC>
                      <SummaryItemSC className="label">差異</SummaryItemSC>
                      <SummaryItemSC className="label">昨対比</SummaryItemSC>
                    </SummaryListSC>
                  )}
                </TitleItemSC>
              </DataTitleSC>

              <DataContents style={{ display: "flex" }}>
                {target_forecast_metrics_array.map((result: any) => {
                  return (
                    <DataContent style={{ height: "auto" }} className="DataContent">
                      {/* Revenue */}
                      <InnerList onClick={() => handleMetricsClick('revenue', 'Revenue')}>
                        {/* Revenue：実績 */}
                        <ContentItem>
                          {result && unitSwitcher(result.revenue_total, 'revenue')}
                          </ContentItem>
                        {revenueOpen && (
                          <SummaryListSC>
                            {/* Revenue：目標 */}

                            {
                              metricsContentClicked ? (
                                result && 
                                <SummaryItemSC onBlur={() => setMetricsContentClicked(false)}>
                                  <input
                                    type="text"
                                    onChange={(e) => handleSimulation(e.target.value, result.result_at,  'revenue_objective')}
                                    value={unitSwitcher(result.revenue_objective, 'revenue')}
                                  />
                                </SummaryItemSC>
                              ) : (
                                <SummaryItemSC onClick={() => setMetricsContentClicked(true)}>
                                  {result && unitSwitcher(result.revenue_objective, 'revenue')}
                                </SummaryItemSC>
                              )
                            }

                            {/* Revenue：達成率 */}
                            <SummaryItemSCAR className={colorSwitcherRate('revenue', result.revenue_rate)}>
                              {result && unitSwitcher(result.revenue_rate, "rate")}
                            </SummaryItemSCAR>

                            {/* Revenue：差異 */}
                            <SummaryItemSC className={colorSwitcherDif('revenue', result.revenue_difference)}>
                              {result && unitSwitcher(result.revenue_difference, 'revenue')}
                            </SummaryItemSC>

                            {/* Revenue：昨対比 */}
                            <SummaryItemSC className={colorSwitcherRate('revenue', result.revenue_last_year_comparison_rate)}>
                              {result && unitSwitcher(result.revenue_last_year_comparison_rate, "rate") }
                            </SummaryItemSC>
                          </SummaryListSC>
                        )}
                      </InnerList>

                      {/* MRR */}
                      <InnerList onClick={() => handleMetricsClick('mrr', 'MRR')}>
                        {/* MRR：実績 */}
                        <ContentItem>
                          {result && unitSwitcher(result.mrr_total_value_total, 'mrr_total_value')}
                          </ContentItem>
                        {mrrOpen && (
                          <SummaryListSC>
                            {/* MRR：目標 */}
                            {metricsContentClicked ? (
                                result && 
                                <SummaryItemSC onBlur={() => setMetricsContentClicked(false)}>
                                  <input
                                    type="text"
                                    onChange={(e) =>handleSimulation(e.target.value, result.result_at,  'mrr_total_value_objective')}
                                    value={unitSwitcher(result.mrr_total_value_objective, 'mrr_total_value')}
                                  />
                                </SummaryItemSC>
                              ) : (
                                <SummaryItemSC onClick={() => setMetricsContentClicked(true)}>
                                  {result && unitSwitcher(result.mrr_total_value_objective, 'mnrr_total_value')}
                                </SummaryItemSC>
                              )}

                            {/* MRR：達成率 */}
                            <SummaryItemSCAR className={colorSwitcherRate('revenue', result.mrr_total_value_rate)}>
                              {result && unitSwitcher(result.mrr_total_value_rate, "rate")}
                            </SummaryItemSCAR>

                            {/* MRR：差異 */}
                            <SummaryItemSC className={colorSwitcherDif('mrr_total_value', result.mrr_total_value_difference)}>
                              {result && unitSwitcher(result.mrr_total_value_difference, 'mrr_total_value')}
                            </SummaryItemSC>

                            {/* MRR：昨対比 */}
                            <SummaryItemSC className={colorSwitcherRate('mrr_total_value', result.mrr_total_value_last_year_comparison_rate)}>
                              {result && unitSwitcher(result.mrr_total_value_last_year_comparison_rate, "rate")}
                            </SummaryItemSC>
                          </SummaryListSC>
                        )}
                      </InnerList>
                      {/* ARPU */}
                      <InnerList onClick={() => handleMetricsClick('arpu', 'ARPU')}>
                        {/* ARPU：実績 */}
                        <ContentItem>
                          {result && unitSwitcher(result.arpu_total, 'arpu')}
                        </ContentItem>
                        {arpuOpen && (
                          <SummaryListSC>
                            {/* ARPU：目標 */}
                            {metricsContentClicked ? (
                                result && 
                                <SummaryItemSC onBlur={() => setMetricsContentClicked(false)}>
                                  <input
                                    type="text"
                                    onChange={(e) => handleSimulation(e.target.value, result.result_at,  'arpu_objective')}
                                    value={unitSwitcher(result.arpu_objective, 'arpu')}
                                  />
                                </SummaryItemSC>
                              ) : (
                                <SummaryItemSC onClick={() => setMetricsContentClicked(true)}>
                                  {result && unitSwitcher(result.arpu_objective, 'arpu')}
                                </SummaryItemSC>
                              )
                            }

                            {/* ARPU：達成率 */}
                            <SummaryItemSCAR className={colorSwitcherRate('arpu', result.arpu_rate)}>
                              {result && unitSwitcher(result.arpu_rate, "rate")}
                            </SummaryItemSCAR>

                            {/* ARPU：差異 */}
                            <SummaryItemSC className={colorSwitcherDif('arpu', result.arpu_difference)}>
                              {result && unitSwitcher(result.arpu_difference, 'arpu')}
                            </SummaryItemSC>

                            {/* ARPU：昨対比 */}
                            <SummaryItemSC className={colorSwitcherRate('arpu', result.arpu_last_year_comparison_rate)}>
                              {result && unitSwitcher(result.arpu_last_year_comparison_rate, "rate") }
                            </SummaryItemSC>
                          </SummaryListSC>
                        )}
                      </InnerList>

                      {/* UnitEconomics */}
                      <InnerList onClick={() => handleMetricsClick('unit-economics', 'Unit Economics')}>
                        {/* UnitEconomics：実績 */}
                        <ContentItem>
                          {result && unitSwitcher(result.unit_economics_total, 'unit_economics')}
                        </ContentItem>
                        {UEOpen && (
                          <SummaryListSC>
                            {/* UnitEconomics：目標 */}
                            {
                              metricsContentClicked ? (
                                result &&
                                <SummaryItemSC onBlur={() => setMetricsContentClicked(false)}>
                                  <input
                                    type="text"
                                    onChange={(e) => handleSimulation(e.target.value, result.result_at,  'unit_economics_objective')}
                                    value={unitSwitcher(result.unit_economics_objective, 'unit_economics')}
                                  />
                                </SummaryItemSC>
                              ) : (
                                <SummaryItemSC onClick={() => setMetricsContentClicked(true)}>
                                  {result && unitSwitcher(result.unit_economics_objective, 'unit_economics')}
                                </SummaryItemSC>
                              )
                            }

                            {/* UnitEconomics：達成率 */}
                            <SummaryItemSCAR className={colorSwitcherRate('unit_economics', result.unit_economics_rate)}>
                              {result && unitSwitcher(result.unit_economics_rate, "rate")}
                            </SummaryItemSCAR>

                            {/* UnitEconomics：差異 */}
                            <SummaryItemSC className={colorSwitcherDif('unit_economics', result.unit_economics_difference)}>
                              {result && unitSwitcher(result.unit_economics_difference, 'unit_economics')}
                            </SummaryItemSC>

                            {/* UnitEconomics：昨対比 */}
                            <SummaryItemSC className={colorSwitcherRate('unit_economics', result.unit_economics_last_year_comparison_rate)}>
                              {result && unitSwitcher(result.unit_economics_last_year_comparison_rate, "rate")}
                            </SummaryItemSC>
                          </SummaryListSC>
                        )}
                      </InnerList>
                      {/* PaybackPeriod */}
                      <InnerList onClick={() => handleMetricsClick('payback-period', 'Payback Period')}>
                        {/* PaybackPeriod：実績 */}
                        <ContentItem>
                          {result && unitSwitcher(result.payback_period_total, "payback_period")}
                        </ContentItem>
                        {PPOpen && (
                          <SummaryListSC>
                            {/* PaybackPeriod：目標 */}
                            {
                              metricsContentClicked ? (
                                result && 
                                <SummaryItemSC onBlur={() => setMetricsContentClicked(false)}>
                                  <input
                                    type="text"
                                    onChange={(e) => handleSimulation(e.target.value, result.result_at,  'payback_period_objective')}
                                    value={unitSwitcher(result.payback_period_objective, 'payback_period')}
                                  />
                                </SummaryItemSC>
                              ) : (
                                <SummaryItemSC onClick={() => setMetricsContentClicked(true)}>
                                  {result && unitSwitcher(result.payback_period_objective, "payback_period")}
                                </SummaryItemSC>
                              )
                            }

                            {/* PaybackPeriod：達成率 */}
                            <SummaryItemSCAR className={colorSwitcherRate('payback_period', result.payback_period_rate)}>
                              {result && unitSwitcher(result.payback_period_rate, "rate")}
                            </SummaryItemSCAR>

                            {/* PaybackPeriod：差異 */}
                            <SummaryItemSC className={colorSwitcherDif('payback_period', result.payback_period_difference)}>
                              {result && unitSwitcher(result.payback_period_diference, "payback_period")}
                            </SummaryItemSC>

                            {/* PaybackPeriod：昨対比 */}
                            <SummaryItemSC className={colorSwitcherRate('payback_period', result.payback_period_last_year_comparison_rate)}>
                              {result && unitSwitcher(result.payback_period_last_year_comparison_rate, "rate")}
                            </SummaryItemSC>
                          </SummaryListSC>
                        )}
                      </InnerList>

                      {/* ChurnRate */}
                      <InnerList onClick={() => handleMetricsClick('churn-rate', 'Churn Rate')}>
                        {/* ChurnRate：実績 */}
                        <ContentItem>
                          {result && unitSwitcher(result.churn_rate_total, "churn_rate")}
                        </ContentItem>
                        {churnRateOpen && (
                          <SummaryListSC>
                            {/* ChurnRate：目標 */}
                            {
                              metricsContentClicked ? (
                                result && 
                                <SummaryItemSC onBlur={() => setMetricsContentClicked(false)}>
                                  <input
                                    type="text"
                                    onChange={(e) => handleSimulation(e.target.value, result.result_at,  'churn_rate_objective')}
                                    value={unitSwitcher(result.churn_rate_objective, 'churn_rate')}
                                  />
                                </SummaryItemSC>
                              ) : (
                                <SummaryItemSC onClick={() => setMetricsContentClicked(true)}>
                                  {result && unitSwitcher(result.churn_rate_objective, "churn_rate")}
                                </SummaryItemSC>
                              )
                            }

                            {/* ChurnRate：達成率 */}
                            <SummaryItemSCAR className={colorSwitcherRate('churn_rate', result.churn_rate_rate)}>
                              {result && unitSwitcher(result.churn_rate_rate, "rate")}
                            </SummaryItemSCAR>

                            {/* ChurnRate：差異 */}
                            <SummaryItemSC className={colorSwitcherDif('churn_rate', result.churn_rate_difference)}>
                              {result && unitSwitcher(result.churn_rate_difference, " churn_rate")}
                            </SummaryItemSC>

                            {/* ChurnRate：昨対比 */}
                            <SummaryItemSC className={colorSwitcherRate('churn_rate', result.churn_rate_last_year_comparison_rate)}>
                              {result && unitSwitcher(result.churn_rate_last_year_comparison_rate, "rate")}
                            </SummaryItemSC>
                          </SummaryListSC>
                        )}
                      </InnerList>
                      {/* CAC */}
                      <InnerList onClick={() => handleMetricsClick('cac', 'CAC')}>
                        {/* CAC：実績 */}
                        <ContentItem>
                          {result && unitSwitcher(result.cac_total, "cac")}
                        </ContentItem>
                        {cacOpen && (
                          <SummaryListSC>
                            {/* CAC：目標 */}
                            {
                              metricsContentClicked ? (
                                result && 
                                <SummaryItemSC onBlur={() => setMetricsContentClicked(false)}>
                                  <input
                                    type="text"
                                    onChange={(e) => handleSimulation(e.target.value, result.result_at, 'cac_objective')}
                                    value={unitSwitcher(result.cac_objective, 'cac')}
                                  />
                                </SummaryItemSC>
                              ) : (
                                <SummaryItemSC onClick={() => setMetricsContentClicked(true)}>
                                  {result && unitSwitcher(result.cac_objective, "cac")}
                                </SummaryItemSC>
                              )
                            }

                            {/* CAC：達成率 */}
                            <SummaryItemSCAR className={colorSwitcherRate('cac', result.cac_rate)}>
                              {result && unitSwitcher(result.cac_rate, "rate")}
                            </SummaryItemSCAR>

                            {/* CAC：差異 */}
                            <SummaryItemSC className={colorSwitcherDif('cac', result.cac_difference)}>
                              {result && unitSwitcher(result.cac_difference, "cac")} 
                            </SummaryItemSC>

                            {/* CAC：昨対比 */}
                            <SummaryItemSC className={colorSwitcherRate('cac', result.cac_last_year_comparison_rate)}>
                              {result && unitSwitcher(result.cac_last_year_comparison_rate, "rate")} 
                            </SummaryItemSC>
                          </SummaryListSC>
                        )}
                      </InnerList>

                      {/* LTV */}
                      <InnerList onClick={() => handleMetricsClick('ltv', 'LTV')}>
                        {/* LTV：実績 */}
                        <ContentItem>
                          {result && unitSwitcher(result.ltv_total, "ltv")} 
                        </ContentItem>
                        {ltvOpen && (
                          <SummaryListSC>
                            {/* LTV：目標 */}
                            {
                              metricsContentClicked ? (
                                result && 
                                <SummaryItemSC onBlur={() => setMetricsContentClicked(false)}>
                                  <input
                                    type="text"
                                    onChange={(e) => handleSimulation(e.target.value, result.result_at,  'ltv_objective')}
                                    value={unitSwitcher(result.ltv_objective, 'ltv')}
                                  />
                                </SummaryItemSC>
                              ) : (
                                <SummaryItemSC onClick={() => setMetricsContentClicked(true)}>
                                  {result && unitSwitcher(result.ltv_objective, "ltv")} 
                                </SummaryItemSC>
                              )
                            }

                            {/* LTV：達成率 */}
                            <SummaryItemSCAR className={colorSwitcherRate('ltv', result.ltv_rate)}>
                              {result && unitSwitcher(result.ltv_rate, "rate")}
                            </SummaryItemSCAR>

                            {/* LTV：差異 */}
                            <SummaryItemSC className={colorSwitcherDif('ltv', result.ltv_difference)}>
                              {result && unitSwitcher(result.ltv_difference, "ltv")}
                            </SummaryItemSC>

                            {/* LTV：昨対比 */}
                            <SummaryItemSC className={colorSwitcherRate('ltv', result.ltv_last_year_comparison_rate)}>
                              {result && unitSwitcher(result.ltv_last_year_comparison_rate, "ltv")}
                            </SummaryItemSC>
                          </SummaryListSC>
                        )}
                      </InnerList>
                      {/* 有料転換率 */}
                      <InnerList onClick={() => handleMetricsClick('conversion_rate', '有料転換率')}>
                        {/* 有料転換率：実績 */}
                        <ContentItem>
                          {result && unitSwitcher(result.conversion_rate_total, "conversion_rate")}
                        </ContentItem>
                        {ConversionRateOpen && (
                          <SummaryListSC>
                            {/* 有料転換率：目標 */}
                            {
                              metricsContentClicked ? (
                                result && 
                                <SummaryItemSC onBlur={() => setMetricsContentClicked(false)}>
                                  <input
                                    type="text"
                                    onChange={(e) => handleSimulation(e.target.value, result.result_at,  'conversion_rate_objective')}
                                    value={unitSwitcher(result.conversion_rate_objective, 'conversion_rate')}
                                  />
                                </SummaryItemSC>
                              ) : (
                                <SummaryItemSC onClick={() => setMetricsContentClicked(true)}>
                                  {result && unitSwitcher(result.conversion_rate_objective, "conversion_rate")}
                                </SummaryItemSC>
                              )
                            }

                            {/* 有料転換率：達成率 */}
                            <SummaryItemSCAR className={colorSwitcherRate('conversion_rate', result.conversion_rate_rate)}>
                              {result && unitSwitcher(result.conversion_rate_rate, "rate")}
                            </SummaryItemSCAR>

                            {/* 有料転換率：差異 */}
                            <SummaryItemSC className={colorSwitcherDif('conversion_rate', result.conversion_rate_difference)}>
                              {result && unitSwitcher(result.conversion_rate_difference, "conversion_rate")}
                            </SummaryItemSC>

                            {/* 有料転換率：昨対比 */}
                            <SummaryItemSC className={colorSwitcherRate('conversion_rate', result.conversion_rate_last_year_comparison_rate)}>
                              {result && unitSwitcher(result.conversion_rate_last_year_comparison_rate, "rate")}
                            </SummaryItemSC>
                          </SummaryListSC>
                        )}
                      </InnerList>

                      {/* NRR */}
                      <InnerList onClick={() => handleMetricsClick('nrr', 'NRR')}>
                        {/* NRR：実績 */}
                        <ContentItem>
                          {result && unitSwitcher(result.nrr_total, "nrr")}
                        </ContentItem>
                        {nrrOpen && (
                          <SummaryListSC>
                            {/* NRR：目標 */}
                            {
                              metricsContentClicked ? (
                                result && 
                                <SummaryItemSC onBlur={() => setMetricsContentClicked(false)}>
                                  <input
                                    type="text"
                                    onChange={(e) => handleSimulation(e.target.value, result.result_at,  'nrr_objective')}
                                    value={unitSwitcher(result.nrr_objective, 'nrr')}
                                  >
                                  </input>
                                </SummaryItemSC>
                              ) : (
                                <SummaryItemSC onClick={() => setMetricsContentClicked(true)}>
                                  {result && unitSwitcher(result.nrr_objective, "nrr")}
                                </SummaryItemSC>
                              )
                            }

                            {/* NRR：達成率 */}
                            <SummaryItemSCAR className={colorSwitcherRate('nrr', result.nrr_rate)}>
                              {result && unitSwitcher(result.nrr_rate, "rate")}
                            </SummaryItemSCAR>

                            {/* NRR：差異 */}
                            <SummaryItemSC className={colorSwitcherDif('nrr', result.nrr_difference)}>
                              {result && unitSwitcher(result.nrr_difference, "nrr")}

                            </SummaryItemSC>

                            {/* NRR：昨対比 */}
                            <SummaryItemSC className={colorSwitcherRate('nrr', result.nrr_last_year_comparison_rate)}>
                              {result && unitSwitcher(result.nrr_last_year_comparison_rate, "rate")}
                            </SummaryItemSC>
                          </SummaryListSC>
                        )}
                      </InnerList>

                      {/* GRR */}
                      <InnerList onClick={() => handleMetricsClick('grr', 'GRR')}>
                        {/* GRR：実績 */}
                        <ContentItem>
                          {result && unitSwitcher(result.grr_total, "grr")}
                        </ContentItem>
                        {grrOpen && (
                          <SummaryListSC>
                            {/* GRR：目標 */}
                            {
                              metricsContentClicked ? (
                                result && 
                                <SummaryItemSC onBlur={() => setMetricsContentClicked(false)}>
                                  <input
                                    type="text"
                                    onChange={(e) => handleSimulation(e.target.value, result.result_at,  'grr_objective')}
                                    value={unitSwitcher(result.grr_objective, 'grr')}
                                  />
                                </SummaryItemSC>
                              ) : (
                                <SummaryItemSC onClick={() => setMetricsContentClicked(true)}>
                                  {result && unitSwitcher(result.grr_objective, "grr")}
                                </SummaryItemSC>
                              )
                            }

                            {/* GRR：達成率 */}
                            <SummaryItemSCAR className={colorSwitcherRate('grr', result.grr_rate)}>
                              {result && unitSwitcher(result.grr_rate, "rate")}
                            </SummaryItemSCAR>

                            {/* GRR：差異 */}
                            <SummaryItemSC className={colorSwitcherDif('grr', result.grr_difference)}>
                              {result && unitSwitcher(result.grr_difference, "grr")}
                            </SummaryItemSC>

                            {/* GRR：昨対比 */}
                            <SummaryItemSC className={colorSwitcherRate('grr', result.grr_last_year_comparison_rate)}>
                              {result && unitSwitcher(result.grr_last_year_comparison_rate, "rate")}
                            </SummaryItemSC>
                          </SummaryListSC>
                        )}
                      </InnerList>

                      {/* QuickRatio */}
                      <InnerList onClick={() => handleMetricsClick('quick-ratio', 'Quick Ratio')}>
                        {/* QuickRatio：実績 */}
                        <ContentItem>
                          {result && unitSwitcher(result.quick_ratio_total, "quick_ratio")}
                        </ContentItem>
                        {quickRatioOpen && (
                          <SummaryListSC>
                            {/* QuickRatio：目標 */}
                            {
                              metricsContentClicked ? (
                                result && 
                                <SummaryItemSC onBlur={() => setMetricsContentClicked(false)}>
                                  <input
                                    type="text"
                                    onChange={(e) => handleSimulation(e.target.value, result.result_at,  'quick_ratio_objective')}
                                    value={unitSwitcher(result.quick_ratio_objective, 'quick_ratio')}
                                  />
                                </SummaryItemSC>
                              ) : (
                                <SummaryItemSC onClick={() => setMetricsContentClicked(true)}>
                                  {result && unitSwitcher(result.quick_ratio_objective, "quick_ratio")}
                                </SummaryItemSC>
                              )
                            }

                            {/* QuickRatio：達成率 */}
                            <SummaryItemSCAR className={colorSwitcherRate('quick_ratio', result.quick_ratio_rate)}>
                              {result && unitSwitcher(result.quick_ratio_rate, "rate")}
                            </SummaryItemSCAR>

                            {/* QuickRatio：差異 */}
                            <SummaryItemSC className={colorSwitcherDif('quick_ratio', result.quick_ratio_difference)}>
                              {result && unitSwitcher(result.quick_ratio_difference, "quick_ratio")}
                            </SummaryItemSC>

                            {/* QuickRatio：昨対比 */}
                            <SummaryItemSC className={colorSwitcherRate('quick_ratio', result.quick_ratio_last_year_comparison_rate)}>
                              {result && unitSwitcher(result.quick_ratio_last_year_comparison_rate, "rate")}
                            </SummaryItemSC>
                          </SummaryListSC>
                        )}
                      </InnerList>

                      {/* ACV */}
                      <InnerList onClick={() => handleMetricsClick('acv-cumulative', 'ACV(累積)')}>
                        {/* ACV(累計)実績 */}
                        <ContentItem>
                          {result && unitSwitcher(result.acv_cumulative_total, "acv_cumulative")}
                        </ContentItem>
                        {acvCumulativeOpen && (
                          <SummaryListSC>
                            {/* ACV(累計)目標 */}
                            {
                              metricsContentClicked ? (
                                result && 
                                <SummaryItemSC onBlur={() => setMetricsContentClicked(false)}>
                                  <input
                                    type="text"
                                    onChange={(e) => handleSimulation(e.target.value, result.result_at,  'acv_cumulative_objective')}
                                    value={unitSwitcher(result.acv_cumulative_objective, 'acv_cumulative')}
                                  />
                                </SummaryItemSC>
                              ) : (
                                <SummaryItemSC onClick={() => setMetricsContentClicked(true)}>
                                  {result && unitSwitcher(result.acv_cumulative_objective, "acv_cumulative")}
                                </SummaryItemSC>
                              )
                            }

                            {/* ACV(累計)達成率 */}
                            <SummaryItemSCAR className={colorSwitcherRate('acv_cumulative', result.acv_cumulative_rate)}>
                              {result && unitSwitcher(result.acv_cumulative_rate, "rate")}
                            </SummaryItemSCAR>

                            {/* ACV(累計)差異 */}
                            <SummaryItemSC className={colorSwitcherDif('acv_cumulative', result.acv_cumulative_difference)}>
                              {result && unitSwitcher(result.acv_cumulative_difference, "acv_cumulative")}
                            </SummaryItemSC>

                            {/* ACV(累計)昨対比 */}
                            <SummaryItemSC className={colorSwitcherRate('acv_cumulative', result.acv_cumulative_last_year_comparison_rate)}>
                              {result && unitSwitcher(result.acv_cumulative_last_year_comparison_rate, "rate")}
                            </SummaryItemSC>
                          </SummaryListSC>
                        )}
                      </InnerList>

                      {/* ACV(新規) */}
                      <InnerList onClick={() => handleMetricsClick('acv-new', 'ACV(新規)')}>
                        {/* ACV(新規)実績 */}
                        <ContentItem>
                          {result && unitSwitcher(result.acv_new_total, "acv_new")}
                        </ContentItem>
                        {acvNewOpen && (
                          <SummaryListSC>
                            {/* ACV(新規)目標 */}
                            {
                              metricsContentClicked ? (
                                result && 
                                <SummaryItemSC onBlur={() => setMetricsContentClicked(false)}>
                                  <input
                                    type="text"
                                    onChange={(e) => handleSimulation(e.target.value, result.result_at,  'acv_new_objective')}
                                    value={unitSwitcher(result.acv_new_objective, 'acv_new')}
                                  />
                                </SummaryItemSC>
                              ) : (
                                <SummaryItemSC onClick={() => setMetricsContentClicked(true)}>
                                  {result && unitSwitcher(result.acv_new_objective, "acv_new")}
                                </SummaryItemSC>
                              )
                            }

                            {/* ACV(新規)達成率 */}
                            <SummaryItemSCAR className={colorSwitcherRate('acv_new', result.acv_new_rate)}>
                              {result && unitSwitcher(result.acv_new_rate, "rate")}
                            </SummaryItemSCAR>

                            {/* ACV(新規)差異 */}
                            <SummaryItemSC className={colorSwitcherDif('acv_new', result.acv_new_difference)}>
                              {result && unitSwitcher(result.acv_new_difference, "acv_new")}
                            </SummaryItemSC>

                            {/* ACV(新規)昨対比 */}
                            <SummaryItemSC className={colorSwitcherRate('acv_new', result.acv_new_last_year_comparison_rate)}>
                              {result && unitSwitcher(result.acv_new_last_year_comparison_rate, "rate")}
                            </SummaryItemSC>
                          </SummaryListSC>
                        )}
                      </InnerList>

                      {/* CPA */}
                      <InnerList onClick={() => handleMetricsClick('cpa', 'CPA')}>
                        {/* CPA:実績 */}
                        <ContentItem>
                          {result && unitSwitcher(result.cpa_total, "cpa")}
                        </ContentItem>
                        {cpaOpen && (
                          <SummaryListSC>
                            {/* CPA:目標 */}
                            {
                              metricsContentClicked ? (
                                result && 
                                <SummaryItemSC onBlur={() => setMetricsContentClicked(false)}>
                                  <input
                                    type="text"
                                    onChange={(e) => handleSimulation(e.target.value, result.result_at,  'cpa_objective')}
                                    value={unitSwitcher(result.cpa_objective, 'cpa')}
                                  />
                                </SummaryItemSC>
                              ) : (
                                <SummaryItemSC onClick={() => setMetricsContentClicked(true)}>
                                  {result && unitSwitcher(result.cpa_objective, "cpa")}
                                </SummaryItemSC>
                              )
                            }
                            {/* CPA:達成率 */}
                            <SummaryItemSCAR className={colorSwitcherRate('cpa', result.cpa_rate)}>
                              {result && unitSwitcher(result.cpa_rate, "rate")}
                            </SummaryItemSCAR>

                            {/* CPA:差異 */}
                            <SummaryItemSC className={colorSwitcherDif('cpa', result.cpa_difference)}>
                              {result && unitSwitcher(result.cpa_difference, "cpa")}
                            </SummaryItemSC>

                            {/* CPA:昨対比 */}
                            <SummaryItemSC className={colorSwitcherRate('cpa', result.cpa_last_year_comparison_rate)}>
                              {result && unitSwitcher(result.cpa_last_year_comparison_rate, "rate")}
                            </SummaryItemSC>
                          </SummaryListSC>
                        )}
                      </InnerList>
                    </DataContent>
                  );
                })}
              </DataContents>
            </DataBody>
          </DataTableSC>
      {metricsDataisOpen ? (
        <>
          <ModalBackGround onClick={() => setMetricsDataisOpen(false)}></ModalBackGround>
          <ModalContainer>
            <MetricsDataContent>
              <GlobalCard>
                <RevenueContent>
                  <ContentCard>
                    <MetricsTitleContainer>
                      <MetricsTitle>{metricsTitle}</MetricsTitle>
                      <ModalClose onClick={() => setMetricsDataisOpen(false)}/>
                    </MetricsTitleContainer>
                    {/* 当月実績 */}
                    {target_data.length > 0 && (
                      <MetricsHeader
                        resultSpan={resultSpan}
                        target_data={target_data}
                        target_data_name={target_data_name}
                        key_total={key_total}
                        key_rate={key_rate}
                        key_difference={key_difference}
                        key_last_time_comparison_rate={key_last_time_comparison_rate}
                        key_last_time_difference={key_last_time_difference}
                        key_objective={key_objective}
                      />
                    )}

                    <CardBody>
                      {/* グラフ */}
                      <DataGraph result_data_type={target_data_name} />
                    </CardBody>
                  </ContentCard>
                </RevenueContent>
              </GlobalCard>
            </MetricsDataContent>
          </ModalContainer>
        </>
      ) : (
        ""
      )}
    </Wrapper>
  );
}

export default ForecastMetricsTableDefault;

// DataTable
const DataTableSC = styled.div`
  margin-bottom: 27px;
  border-bottom: 1px solid #e2e9f3;
  width: max-content;
  min-width: 100%;
`;
// DataHeader
const DataHeader = styled.div`
  width: 100%;
  box-sizing: border-box;
  color: #435878;
  display: flex;
  font-size: 10px;
  line-height: 15px;

  p {
    min-width: 120px;
    width: 100%;
    text-align: center;
    padding: 5px 0;
    border-right: 1px solid #e2e9f3;
    background-color: #f3f6fa;
    color: #555;
    font-size: 10px;
    &:first-child {
      width: 150px;
      position: absolute;
      background-color: #f3f6fa;
      min-width: 150px;
      position: -webkit-sticky;
      position: sticky;
      left: 0px;
      z-index: 500;
      overflow: auto;
      //left: 0;
    }
    &:last-child {
      border-right: none;
    }
  }
`;
// DataBody
const DataBody = styled.div`
  width: 100%;
  align-items: flex-start;
`;
// DataTitle
const DataTitleSC = styled.ul`
  color: #435878;
  min-width: 150px;
  border-right: 1px solid #e2e9f3;
  background-color: #fff;
  position: sticky;
  left: 0px;
  z-index: 500;
  overflow: auto;
`;

const TitleItemSC = styled.li`
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  width: 100%;
  input {
    display: none;
  }
  h4 {
    height: 40px;
    padding: 12px 0 12px 20px;
    border-bottom: 1px solid #e2e9f3;
    &.opened {
      &::after {
        content: "";
        display: inline-block;
        height: 13px;
        width: 18px;
        position: absolute;
        right: 10px;
        background-image: url(${close_arrow});
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    &.closed {
      &::after {
        content: "";
        display: inline-block;
        height: 13px;
        width: 18px;
        position: absolute;
        right: 10px;
        background-image: url(${open_arrow});
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    label {
      width: 100%;
    }
  }
`;

const SummaryListSC = styled.ul``;

const SummaryItemSC = styled.li`
  height: 40px;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  border-bottom: 1px solid #e2e9f3;
  padding: 12px 10px 12px;
  width: 100%;
  text-align: right;
  &.label {
    text-align: left;
    padding: 12px 10px 12px 20px;
  }
  &.reached {
    color: #028cab;
  }
  &.unreached {
    color: #af002a;
  }
  &.equal {
    color: #35455d;
  }
`;


// DataContents
const DataContents = styled.div`
  width: calc(100% - 150px);
`;
// DataContent
const DataContent = styled.ul`
  font-size: 12px;
  min-width: 120px;
  width: 100%;
  border-right: 1px solid #e2e9f3;
  &:last-child {
    border-right: none;
  }
  &.activeDotShown {
    background-color: rgba(0, 102, 255, 0.05);
  }
`;

const ContentItem = styled.h4`
  width: 100%;
  height: 40px;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  padding: 7px 10px 12px;
  text-align: right;
  border-bottom: 1px solid #e2e9f3;
  position: relative;
  &.reached {
    color: #028cab;
    &::before {
      content: "";
      display: inline-block;
      height: 18px;
      width: 24px;
      position: relative;
      top: 4px;
      background-image: url(${reached_arrow});
      background-repeat: no-repeat;
    }
  }
  &.unreached {
    color: #af002a;
    &::before {
      content: "";
      display: inline-block;
      height: 18px;
      width: 24px;
      position: relative;
      top: 4px;
      background-image: url(${unreached_arrow});
      background-repeat: no-repeat;
    }
  }
  &.equal {
    color: #35455d;
    &::before {
      content: "";
      display: inline-block;
      height: 18px;
      width: 20px;
    }
  }
  input {
    width: 100%;
  }
  p {
    width: 100%;
  }
`;

const SummaryItemSCAR = styled.h4`
  height: 40px;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  border-bottom: 1px solid #e2e9f3;
  padding: 7px 10px 12px;
  width: 100%;
  text-align: right;
  &.label {
    text-align: left;
    padding: 12px 10px 12px 20px;
  }
  &.reached {
    color: #028cab;
    &::before {
      content: "";
      display: inline-block;
      height: 18px;
      width: 24px;
      position: relative;
      top: 4px;
      background-image: url(${reached_arrow});
      background-repeat: no-repeat;
    }
  }
  &.unreached {
    color: #af002a;
    &::before {
      content: "";
      display: inline-block;
      height: 18px;
      width: 24px;
      position: relative;
      top: 4px;
      background-image: url(${unreached_arrow});
      background-repeat: no-repeat;
    }
  }
  &.equal {
    color: #35455d;
    &::before {
      content: "";
      display: inline-block;
      height: 18px;
      width: 20px;
    }
  }
`;

const MetricsTitle = styled.h1`
  line-height: 21px;
  padding: 10px 0px;
  font-weight: 600;
  font-size: 25px;
  color: #35455d;
  text-align: left;
  width: 50%;
`;

const GlobalCard = styled.div`
  display: flex;
  justify-content: space-between;
`;

// RevenueContent
const RevenueContent = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

// ContentCard
const ContentCard = styled.div`
  box-sizing: border-box;
  background-color: #fff;
`;

// CardBody
const CardBody = styled.div`
  padding: 0x 17px;
  box-sizing: border-box;
  position: relative;
`;

const InnerList = styled.li`
  &:hover {
    cursor: pointer;
  }
`;

const MetricsDataContent = styled.div({
  margin: "auto",
  width: "96%"
});

const MetricsTitleContainer = styled.div({
  display: "flex",
});

const Wrapper = styled.div({
  scrollbarWidth: "none",
	"&::-webkit-scrollbar": { display: "none" },
  "-ms-overflow-style": "none",
  width: "flex",
  overflow: "scroll",
  position: "relative"
});

const ModalBackGround = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(67, 88, 120, 0.3);
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  height: 509px;
  width: 65%;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
  z-index: 1001;
  background: white
`;

const ModalClose = styled.button`
  background: url(${close});
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 58%;
  width: 24px;
  height: 24px;
  margin-top: 9px;
`;
