import React, {useEffect} from 'react'
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import ForecastMetricsFilterList from './ForecastMetricsFilterList'
import ForecastMetricsTableDefault from './ForecastMetricsTableDefault'
import { setTargetForecastMetricsArray } from '../../../reducks/targetForecast/actions'
import {
  updateDownloadCsvTarget,
} from "../../../reducks/download/actions";
import GraphDataDownload from "../../Parts/GraphDataDownload";

export default function ForecastMetricsIndex() {
  const dispatch = useDispatch();
  const metricsForecastFilter = useSelector((state: any) => state.forecasts.metricsForecastFilter);
  const activatedFilter = useSelector((state: any) => state.filter);
  const raw_data_array = useSelector((state: any) => state.raw_data.raw_data_array);

  useEffect(() => {
    // revenueとprofitの合算されたデータを取得
    dispatch(setTargetForecastMetricsArray(raw_data_array))
  }, [dispatch, raw_data_array])

  function handleGraphDataDownloadClick(e:any, target:string) {
    dispatch(
      updateDownloadCsvTarget("/"+target)
    )
  }

  return (
    <ContentCard>
      <ForecastMetricsFilterList />

      <CardBody>
        <CardTitleGraphDownloadWrapper>
          <CardTitle>重要指標</CardTitle>
          <div onClick={(e) => {handleGraphDataDownloadClick(e, 'important_indicators')}}>
            <GraphDataDownload GraphImageDownload="hide"/>
          </div>
        </CardTitleGraphDownloadWrapper>
        <br/>
        {/* テーブル：フィルターなし */}
        {!metricsForecastFilter && activatedFilter === '' && <ForecastMetricsTableDefault />}
        
      </CardBody>
    </ContentCard>
  )
}


// ContentCard
const ContentCard = styled.div`
  margin-bottom: 16px;
  border: 1px solid #e1e8ea;
  box-sizing: border-box;
  background-color: #fff;
`;
// CardBody
const CardBody = styled.div`
  padding: 15px 17px;
  box-sizing: border-box;
  position: relative;
`;

// CardTitle
const CardTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #2a3385;
  margin: 0 0 14px 0;
  display : flex;
  flex-direction : row;
  align-items : center;
`;

const CardTitleGraphDownloadWrapper = styled.div({
  flexGrow: 1,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});
