import rsf from "../rsf";
import { call, put, takeLatest } from "redux-saga/effects";
import { GET_BILLINGS_REQUESTED, GET_BILLINGS_SUCCEEDED, GET_BILLINGS_FAILED } from "../reducks/billings/types";

function* getBillings(action: any) {
  try {
    const snapshot = yield call(rsf.firestore.getCollection, `companies/${action.company_id}/billings`);
    let billings: Object[] = [];
    snapshot.forEach((billing: any) => {
      billings = [...billings, billing.data()]
    });
    yield put({ type: GET_BILLINGS_SUCCEEDED, billings: billings });
  } catch (e) {
    yield put({ type: GET_BILLINGS_FAILED, message: e.message });
  }
}


const usersSagas = [ 
  takeLatest(GET_BILLINGS_REQUESTED, getBillings),
];

export default usersSagas;
