function FilteredChurnAverageGenerator(filtered_churn_data: any, target_span: number) {
  //平均値を計算
  const churn_rate_raws_average = filtered_churn_data.map((item: any, index: number) => {
    return [
      ...filtered_churn_data.slice(index)
    ].reduce((acc:any, cur:any) => {
      if(cur.churn_data !== null && cur.churn_data !== undefined) {
        return {
          index: index + 1,
          length: filtered_churn_data.length - index,
          churn_sum: (acc.churn_sum + cur.churn_data),
          churn_average: (acc.churn_sum + cur.churn_data)/(filtered_churn_data.length - index)
        }
      }
      // cur.churn_dataがnullだったら
      return {
        index: index + 1,
        length: filtered_churn_data.length - index,
        churn_sum: acc.churn_sum + 0,
        churn_average: (acc.churn_sum + cur.churn_data)/(filtered_churn_data.length - index)
      }
    },{churn_sum: 0})
  })

  console.log('churn_rate_raws_average',churn_rate_raws_average)
  console.log('taregt_span', target_span)

  const fill_loop = (array: any) => {
    console.log('array.length',array.length)
    if(array.length !== target_span)
    for(let cell = array.length; cell < target_span; cell++) {
      console.log('cell', cell)
      console.log('array',array)
      array.push({churn_average:""}) 
    }
    return array
  }

  const filled_average_array = fill_loop(churn_rate_raws_average)

    console.log('filled_average_array',filled_average_array)
  
  return filled_average_array;
}



export default FilteredChurnAverageGenerator;