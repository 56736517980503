import rsf from "../../rsf"
import firebase from "firebase"
import { call } from "redux-saga/effects"

const db = firebase.firestore()

function* getDetailMonthly(company_id, daily_from_date, daily_to_date, collection_name) {
  try {

    let detail_results = [];

    // コレクショングループでためす
    const detailCollectionGroup = (collection_name) => {
      console.log("detailCollectionGroup : collection_name",collection_name);
      return db
        .collectionGroup(collection_name)
        .where('company_id', '==', company_id)
        .where('result_at', '>=', daily_from_date)
        .where('result_at', '<=', daily_to_date)
        .where('resultSpan', '==', 'monthly')
        .get();
    };
    const snapshot = yield call(detailCollectionGroup, collection_name);

    snapshot.forEach((doc) => {
      detail_results = [...detail_results, { ...doc.data(), docId: doc.id }];
    });
    return detail_results
  } catch (e) {
    console.log("error message", e.message)
  }
}

export default getDetailMonthly;