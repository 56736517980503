import React from 'react'
import styled from "styled-components";
import { setTargetLineOpacity } from '../../../reducks/rawData/actions'

import { useDispatch } from 'react-redux';


export default function ForecastSaleCostGraphDefaultCustomLegendItem(props: any) {
  const dispatch = useDispatch();
  const result = props.result;
  const index = props.index;
  const sc_name = props.sc_name;
  const class_name = props.class_name;

  const legendClick = (e: any, result: any) => {
    if (result.payload.strokeOpacity === 0) {
      console.log('result', result);
      dispatch(setTargetLineOpacity(result.payload.dataKey, 1))
    } else {
      console.log('result', result);
      dispatch(setTargetLineOpacity(result.payload.dataKey, 0))
    }
  };

  return (
    <LegendItem
      key={`item-${index}`}
      className={ result.payload.strokeOpacity === 0 ? `${class_name} deactivated` : `${class_name}` }
      onClick={(e: any) => legendClick(e, result)}
    >
      {sc_name}
    </LegendItem>
  )
}
const LegendItem = styled.li`
  font-size: 12px;
  line-height: 1.6;
  margin-right: 16px;
  margin-bottom: 10px;
  padding: 6px 8px 6px 30px;
  box-sizing: border-box;
  position: relative;
  border: 1px solid #EDF0F1;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
  &.deactivated {
    background-color: #EDF0F1;
  }
  &::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }
  &.customLegend-sm {
    &::before {
      background-color: #E53E66;
    }
    &.forecast {
      &::before {
        border-top: 2px dashed #E53E66;
      }
    }
  }
  &.customLegend-rd {
    &::before {
      background-color: #F8B200;
    }
    &.forecast {
      &::before {
        border-top: 2px dashed #F8B200;
      }
    }
  }
  &.customLegend-ga {
    &::before {
      background-color: #B7D20D;
    }
    &.forecast {
      &::before {
        border-top: 2px dashed #B7D20D;
      }
    }
  }

  span {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    &.item-total {
      width: 16px;
      height: 3px;
      top: 50%;
      left: 2px;
    }
    &.item-obj {
      font-size: 30px;
      line-height: 1.0;
      top: 2%;
    }
  }
`;


