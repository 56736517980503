import { SET_FIRST_CALENDER_START_DATE, SET_FIRST_CALENDER_END_DATE, SET_SECOND_CALENDER_START_DATE, SET_SECOND_CALENDER_END_DATE, HIDE_TIME_SPAN_COMPARISON_FILTER } from './types';
import {RESET_ALL} from "../common/types";

// import update from "immutability-helper";
const today = new Date();
const nextMonth = new Date(new Date().setMonth(new Date().getMonth() + 1));
const twoMonthAgo = new Date(new Date().setMonth(new Date().getMonth() - 2));
const lastMonth = new Date(new Date().setMonth(new Date().getMonth() - 1));
const newLastMonth = new Date(new Date().setMonth(lastMonth.getDate() + 10));

const initialState: object = {
  dateSpanComparisonFilterDisplay: false,
  firstCalender: {
    startDate: today,
    endDate: nextMonth,
    span: (nextMonth.getTime() - today.getTime()) / (1000 * 3600 * 24),
  },
  secondCalender: {
    startDate: twoMonthAgo,
    endDate: lastMonth,
    span: (lastMonth.getTime() - twoMonthAgo.getTime()) / (1000 * 3600 * 24),
  },
};

const spanComparisonCalender = (state: any = initialState, action: any) => {
  switch (action.type) {
    case RESET_ALL:
      return initialState;

    case HIDE_TIME_SPAN_COMPARISON_FILTER:
      return {
        ...state,
        dateSpanComparisonFilterDisplay: false,
      };
    case SET_FIRST_CALENDER_START_DATE:
      // First Calender内Start Dateを設定することで生まれるFirst Calenderの期間日数
      const newFirstCalenderSpanFromStart = (state.firstCalender.endDate.getTime() - action.startDate.getTime()) / (1000 * 3600 * 24);

      return {
        ...state,
        firstCalender: {
          ...state.firstCalender,
          startDate: action.startDate,
          span: newFirstCalenderSpanFromStart,
        },

        secondCalender: {
          ...state.secondCalender,
          endDate: new Date(state.secondCalender.startDate.getTime() + newFirstCalenderSpanFromStart * 86400000),
          span: newFirstCalenderSpanFromStart,
        },
      };

    case SET_FIRST_CALENDER_END_DATE:
      const newFirstCalenderSpanFromEnd = (action.endDate.getTime() - state.firstCalender.startDate.getTime()) / (1000 * 3600 * 24);
      return {
        ...state,
        firstCalender: {
          ...state.firstCalender,
          endDate: action.endDate,
          span: newFirstCalenderSpanFromEnd,
        },

        secondCalender: {
          ...state.secondCalender,
          endDate: new Date(state.secondCalender.startDate.getTime() + newFirstCalenderSpanFromEnd * 86400000),
          span: newFirstCalenderSpanFromEnd,
        },
      };

    case SET_SECOND_CALENDER_START_DATE:
      // First Calender内Start Dateを設定することで生まれるFirst Calenderの期間日数
      const newSecondCalenderSpanFromStart = (state.secondCalender.endDate.getTime() - action.startDate.getTime()) / (1000 * 3600 * 24);

      return {
        ...state,
        firstCalender: {
          ...state.firstCalender,
          endDate: new Date(state.firstCalender.startDate.getTime() + newSecondCalenderSpanFromStart * 86400000),
          span: newSecondCalenderSpanFromStart,
        },

        secondCalender: {
          ...state.secondCalender,
          startDate: action.startDate,
          span: newSecondCalenderSpanFromStart,
        },
      };

    case SET_SECOND_CALENDER_END_DATE:
      const newSecondCalenderSpanFromEnd = (action.endDate.getTime() - state.secondCalender.startDate.getTime()) / (1000 * 3600 * 24);
      return {
        ...state,
        firstCalender: {
          ...state.firstCalender,
          endDate: new Date(state.firstCalender.startDate.getTime() + newSecondCalenderSpanFromEnd * 86400000),
          span: newSecondCalenderSpanFromEnd,
        },

        secondCalender: {
          ...state.secondCalender,
          endDate: action.endDate,
          span: newSecondCalenderSpanFromEnd,
        },
      };

    default:
      return state;
  }
};

export default spanComparisonCalender;
