import rsf from "../../rsf";
import { call } from "redux-saga/effects";
import firebase from "firebase";
import logger from "redux-logger";

const db = firebase.firestore();

// 過去6ヶ月分データ取得
function* getCustomFilteredData(company_id, target_data_name, custom_span_start_time, custom_span_end_time) {
  try {
    const  startMonth = new Date(custom_span_start_time.seconds * 1000);
    const  endMonth = new Date(custom_span_end_time.seconds * 1000);
     
    // コレクション名を設定
    const collection_name = target_data_name + '_detail';
    console.log('collection_name', collection_name);

    console.log('last6Month', startMonth)
    console.log('thisMonth', endMonth)


    // コレクショングループでためす
    const targetCollectionGroup = collection_name => {
      return db
        .collectionGroup(collection_name)
        .where('company_id', '==', company_id)
        .where('result_at', '>=', startMonth)
        .where('result_at', '<=', endMonth)
        .get();
    };
    const snapshot = yield call(targetCollectionGroup, collection_name);

    let results = [];
    snapshot.forEach(doc => {
      results = [...results, { ...doc.data(), docId: doc.id }];
    });

    return results;

  } catch (e) {
    console.log("error message", e.message);
  }
}


export default getCustomFilteredData;
