import React, { Component } from "react";
import styled from "styled-components";
import { ResponsiveContainer, ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
interface Props {
  spanComparisonData: any;
}
interface State {}
export default class FunnelSummaryGraphSpanFilter extends Component<Props, State> {
  
  render() {
    return (
      <>
      {
        this.props.spanComparisonData.length > 0 ? (
          <Graph>
            <ResponsiveContainer>
              <ComposedChart width={730} height={250} data={this.props.spanComparisonData}>
                <XAxis dataKey="first_funnel_name" tickLine={false} tickSize={10} minTickGap={20} tick={{ fontSize: 10 }} />

                <YAxis yAxisId={0} dataKey="first_lead_new_count_total" type="number" tickCount={10} interval={0} tickMargin={10} tickLine={false} />
                <YAxis yAxisId={1} orientation="right" dataKey="first_lead_new_count_moving_average_total" type="number" tickCount={10} interval={0} tickMargin={10} tickLine={false} />

                {/* <Tooltip content={customTooltip} /> */}
                <Legend verticalAlign={"top"} align={"left"} width={432} height={60} margin={{ right: 100 }} iconSize={12} wrapperStyle={{ fontSize: "12px", color: "#283f46" }} />
                <CartesianGrid stroke="#f5f5f5" />
                <Bar name="期間１：実績" dataKey="first_lead_new_count_total" barSize={32} fill="#0066ff" yAxisId={0} />
                <Bar name="期間１：目標" dataKey="first_lead_new_count_objective" barSize={32} fill="#E53E66" yAxisId={0} />
                <Bar name="期間２：実績" dataKey="second_lead_new_count_total" barSize={32} fill="#E63E66" yAxisId={0} />
                <Bar name="期間２：目標" dataKey="second_lead_new_count_objective" barSize={32} fill="#E53E66" yAxisId={0} />
                <Line name="期間１移動平均：実績" dataKey="first_lead_new_count_moving_average_total" yAxisId={1} strokeWidth={3} stroke="#73abff" />
                <Line name="期間１移動平均：目標" dataKey="first_lead_new_count_moving_average_objective" yAxisId={1} stroke="#f3cf73" strokeWidth={3} />
                <Line name="期間２移動平均：実績" dataKey="second_lead_new_count_moving_average_total" yAxisId={1} stroke="#F195AB" strokeWidth={3} />
                <Line name="期間２移動平均：目標" dataKey="second_lead_new_count_moving_average_objective" yAxisId={1} stroke="#D7E67A" strokeWidth={3} />
              </ComposedChart>
            </ResponsiveContainer>
          </Graph>
        ):(
          <NoData>グラフを表示できません</NoData>
        )
      }
      </>
    );
  }
}

  //カスタムTooltip
  // const customTooltip = (props: any) => {
  //   //グラフのpayloadを取得
  //   const parentArray = props.payload;
  //   // console.log("parentArray:", parentArray)

  //   if (parentArray != null) {
  //     //取得したpayload(親)の中のpayload(子)を取得
  //     const childPayload = parentArray.flatMap((childPayload: any) => childPayload.payload);
  //     //取得した値から[0]番目のみ取得
  //     const tooltipPayload = childPayload[0];

  //     // console.log("tooltipPayload: ", tooltipPayload)
  //     if (tooltipPayload != null) {
  //       //移行率移動平均
  //       // const transitionTotal = tooltipPayload.revenue_total - tooltipPayload.revenue_last_year_total;
  //       // const transitionOvj = (tooltipPayload.revenue_total / tooltipPayload.revenue_last_year_total * 100) - 100;

  //       return (
  //         <TooltipContent>
  //           {dateSpanComparisonFilterDisplay ? (
  //             <>
  //               {/* ファネル名 */}
  //               <TooltipDaily>{`${tooltipPayload.first_funnel_name}`}</TooltipDaily>
  //               <table>
  //                 <thead>
  //                   <tr>
  //                     <TooltipTitle>&nbsp;</TooltipTitle>
  //                     <TooltipTitle>移動平均</TooltipTitle>
  //                     <TooltipTitle style={{ paddingLeft: "32px" }}>当月</TooltipTitle>
  //                   </tr>
  //                 </thead>
  //                 <tbody>
  //                   <tr>
  //                     <TooltipLabel>期間1:目標</TooltipLabel>
  //                     <TooltipVal className="averageObj">{(tooltipPayload.first_lead_new_count_moving_average_objective).toLocaleString()}</TooltipVal>
  //                     <TooltipVal className="obj" style={{ paddingLeft: "16px" }}>
  //                       {" "}
  //                       {(tooltipPayload.first_lead_new_count_objective).toLocaleString()}
  //                     </TooltipVal>
  //                   </tr>
  //                   <tr>
  //                     <TooltipLabel>期間1:実績</TooltipLabel>
  //                     <TooltipVal className="averageTotal">{(tooltipPayload.first_lead_new_count_moving_average_total).toLocaleString()}</TooltipVal>
  //                     <TooltipVal className="total" style={{ paddingLeft: "16px" }}>
  //                       {" "}
  //                       {(tooltipPayload.first_lead_new_count_total).toLocaleString()}
  //                     </TooltipVal>
  //                   </tr>
  //                   <tr>
  //                     <TooltipLabel>期間2:目標</TooltipLabel>
  //                     <TooltipVal className="subAverageObj">{(tooltipPayload.second_lead_new_count_moving_average_objective).toLocaleString()}</TooltipVal>
  //                     <TooltipVal className="subObj" style={{ paddingLeft: "16px" }}>
  //                       {" "}
  //                       {(tooltipPayload.second_lead_new_count_objective).toLocaleString()}
  //                     </TooltipVal>
  //                   </tr>
  //                   <tr>
  //                     <TooltipLabel>期間2:実績</TooltipLabel>
  //                     <TooltipVal className="subAverageTotal">{(tooltipPayload.second_lead_new_count_moving_average_total).toLocaleString()}</TooltipVal>
  //                     <TooltipVal className="subTotal" style={{ paddingLeft: "16px" }}>
  //                       {" "}
  //                       {(tooltipPayload.second_lead_new_count_total).toLocaleString()}
  //                     </TooltipVal>
  //                   </tr>
  //                 </tbody>
  //               </table>
  //               {tooltipPayload.funnel_name !== "流入リード" && (
  //                 <>
  //                   {/* 移行率 */}
  //                   <TooltipTitle as={"h4"} className="center">
  //                     移行率
  //                   </TooltipTitle>
  //                   <table>
  //                     {/* <thead>
  //                     <tr>
  //                       <TooltipTitle>&nbsp;</TooltipTitle>
  //                       <TooltipTitle>移動平均</TooltipTitle>
  //                       <TooltipTitle style={{'paddingLeft':'32px'}}>当月</TooltipTitle>  
  //                     </tr>
  //                   </thead> */}
  //                     <tbody>
  //                       <tr>
  //                         <TooltipLabel>期間1:目標</TooltipLabel>
  //                         <TooltipVal>&nbsp;</TooltipVal>
  //                         <TooltipVal className="obj" style={{ paddingLeft: "16px" }}>
  //                           {" "}
  //                           {tooltipPayload.first_lead_transition_rate_objective.toFixed(1)}%
  //                         </TooltipVal>
  //                       </tr>
  //                       <tr>
  //                         <TooltipLabel>期間1:実績</TooltipLabel>
  //                         <TooltipVal>&nbsp;</TooltipVal>
  //                         <TooltipVal className="total" style={{ paddingLeft: "16px" }}>
  //                           {" "}
  //                           {tooltipPayload.first_lead_transition_rate_total.toFixed(1)}%
  //                         </TooltipVal>
  //                       </tr>
  //                       <tr>
  //                         <TooltipLabel>期間2:目標</TooltipLabel>
  //                         <TooltipVal>&nbsp;</TooltipVal>
  //                         <TooltipVal className="subTotal" style={{ paddingLeft: "16px" }}>
  //                           {" "}
  //                           {tooltipPayload.second_lead_transition_rate_objective.toFixed(1)}%
  //                         </TooltipVal>
  //                       </tr>
  //                       <tr>
  //                         <TooltipLabel>期間2:実績</TooltipLabel>
  //                         <TooltipVal>&nbsp;</TooltipVal>
  //                         <TooltipVal className="subObj" style={{ paddingLeft: "16px" }}>
  //                           {" "}
  //                           {tooltipPayload.second_lead_transition_rate_total.toFixed(1)}%
  //                         </TooltipVal>
  //                       </tr>
  //                     </tbody>
  //                   </table>
  //                 </>
  //               )}
  //             </>
  //           ) : (
  //             <>
  //               {/* ファネル名 */}
  //               <TooltipDaily>{`${tooltipPayload.funnel_name}`}</TooltipDaily>
  //               <table>
  //                 <thead>
  //                   <tr>
  //                     <TooltipTitle>&nbsp;</TooltipTitle>
  //                     <TooltipTitle>移動平均</TooltipTitle>
  //                     <TooltipTitle style={{ paddingLeft: "32px" }}>当月</TooltipTitle>
  //                   </tr>
  //                 </thead>
  //                 <tbody>
  //                   <tr>
  //                     <TooltipLabel>目標</TooltipLabel>
  //                     <TooltipVal className="averageObj">{(tooltipPayload.lead_new_count_moving_average_objective).toLocaleString()}</TooltipVal>
  //                     <TooltipVal className="obj" style={{ paddingLeft: "16px" }}>
  //                       {" "}
  //                       {(tooltipPayload.lead_new_count_objective).toLocaleString()}
  //                     </TooltipVal>
  //                   </tr>
  //                   <tr>
  //                     <TooltipLabel>実績</TooltipLabel>
  //                     <TooltipVal className="averageTotal">{(tooltipPayload.lead_new_count_moving_average_total).toLocaleString()}</TooltipVal>
  //                     <TooltipVal className="total" style={{ paddingLeft: "16px" }}>
  //                       {" "}
  //                       {(tooltipPayload.lead_new_count_total).toLocaleString()}
  //                     </TooltipVal>
  //                   </tr>
  //                 </tbody>
  //               </table>
  //               {tooltipPayload.funnel_name !== "流入リード" && (
  //                 <>
  //                   {/* 移行率 */}
  //                   <TooltipTitle as={"h4"} className="center">
  //                     移行率
  //                   </TooltipTitle>
  //                   <table>
  //                     <tbody>
  //                       <tr>
  //                         <TooltipLabel>目標</TooltipLabel>
  //                         <TooltipVal>&nbsp;</TooltipVal>
  //                         <TooltipVal className="obj" style={{ paddingLeft: "16px" }}>
  //                           {" "}
  //                           {tooltipPayload.transition_rate_objective_average}%
  //                         </TooltipVal>
  //                       </tr>
  //                       <tr>
  //                         <TooltipLabel>実績</TooltipLabel>
  //                         <TooltipVal>&nbsp;</TooltipVal>
  //                         <TooltipVal className="total" style={{ paddingLeft: "16px" }}>
  //                           {" "}
  //                           {tooltipPayload.transition_rate_average}%
  //                         </TooltipVal>
  //                       </tr>
  //                     </tbody>
  //                   </table>
  //                 </>
  //               )}
  //             </>
  //           )}
  //         </TooltipContent>
  //       );
  //     }
  //     return;
  //   }
  //   return false;
  // };

  // Tooltipのカスタムスタイリング
  const customWrapperStyle = {
    background: "white",
    width: "216px",
    height: "168px",
    borderRadius: "2px",
    boxShadow: "0 4px 5px 0 rgba(0, 0, 0, .25)",
    padding: "8px"
  };

// Graph
const Graph = styled.div`
  width: 100%;
  height: 272px;
  padding: 0 10px;
  box-sizing: border-box;

  .recharts-legend-wrapper {
    top: -20px !important;
    left: 10px !important;
  }
  .recharts-surface {
    position: relative;
    top: -2px;
    overflow: visible;
  }
  .recharts-cartesian-axis-tick {
    width: 100px !important;
    .recharts-cartesian-axis-tick-value {
      font-size: 10px;
      color: #435878;
    }
  }
`;


// Tooltip
const TooltipContent = styled.div`
  width: 296px;
  background-color: #fff;
  padding: 8px;
`;
const TooltipDaily = styled.p`
  font-size: 14px;
  font-weight: bold;
  line-height: 15px;
  text-align: center;
  color: #435878;
  margin-bottom: 10px;
`;

const TooltipTitle = styled.th`
  width: 80px;
  font-size: 10px;
  color: #435878;
  text-align: center;
  padding: 4px 4px 4px 16px;
  &.center {
    padding: 8px;
    width: 100%;
  }
`;
const TooltipData = styled.dl`
  display: flex;
  justify-content: space-around;
  padding: 5px 15px;
  box-sizing: border-box;
`;
const TooltipLabel = styled.td`
  font-size: 12px;
  font-weight: normal;
  color: #435878;
  width: 80px;
  padding: 4px;
  box-sizing: border-box;
  text-align: center;
`;
const TooltipVal = styled.td`
  font-size: 12px;
  font-weight: bold;
  color: #435878;
  margin-right: 16px;
  text-align: right;
  padding: 4px;
  width: 80px;
  box-sizing: border-box;
  &.total {
    color: #0066ff;
  }
  &.obj {
    color: #eaa800;
  }
  &.subTotal {
    color: #e63e66;
  }
  &.subObj {
    color: #EAA800;
  }
  &.averageTotal {
    color: #73abff;
  }
  &.averageObj {
    color: #f3cf73;
  }
  &.subAverageTotal {
    color: #f195ab;
  }
  &.subAverageObj {
    color: #d7e67a;
  }
  &.maxVal {
    color: #028cab;
  }
  &.minVal {
    color: #af002a;
  }
`;

const NoData = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #435878;
  margin-bottom: 8px;
  &.main {
    margin-bottom: 8px;
  }
`