import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logoHome from "../../images/logos/logo-home.png";


// LoginHeaderBlock
const LoginHeaderBlock = styled.div`
  width: 100%;
  height: 80px;
  padding: 0 24px 0 48px;
  box-sizing: border-box;
  background: rgba(0, 102, 255);
  display: flex;
  justify-content: space-between;
`;
// LoginHeaderLogo
const LoginHeaderLogo = styled.p`
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  color: #fff;
  margin: 0;
  padding: 28px 0 27px;
`;

const LoginHeader = () => {
  return(
    <LoginHeaderBlock>
      <LoginHeaderLogo>
        <Link to='/'><img src={logoHome} alt="and act logo" style={{ width: "100px" }} /></Link>
      </LoginHeaderLogo>
    </LoginHeaderBlock>
  );
}

export default LoginHeader;