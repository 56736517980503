export const decimalPointCheckerForPercent = (result: number): string => {
  if (
    Number((result * 100).toFixed(1)).toLocaleString()[
      Number((result * 100).toFixed(1)).toLocaleString().length - 2
    ] !== "."
  ) {
    return Number((result * 100).toFixed(1)).toLocaleString() + ".0%";
  } else {
    return Number((result * 100).toFixed(1)).toLocaleString() + "%";
  }
};

export const decimalPointChecker = (result: number): string => {
  if (
    Number(result.toFixed(1)).toLocaleString()[
      Number(result.toFixed(1)).toLocaleString().length - 2
    ] !== "."
  ) {
    return Number(result.toFixed(1)).toLocaleString() + ".0";
  } else {
    return Number(result.toFixed(1)).toLocaleString();
  }
};

export const unitSwitcher = (result: number, target_data_name: string) => {
  //データの存在有無
  const dataIsExist = result !== null && result !== undefined;
  //データ型が正しいか
  const dataIsValid = typeof result === "number" && isNaN(result) === false;
  if (!dataIsExist) {
    return "NoData";
  } else if (!dataIsValid) {
    return "invalid data";
  } else {
    switch (target_data_name) {
      case "revenue":
        return result.toLocaleString("ja-JP", {
          style: "currency",
          currency: "JPY"
        });
      case "mrr_total_value":
        return result.toLocaleString("ja-JP", {
          style: "currency",
          currency: "JPY"
        });
      case "unit_economics":
        return decimalPointChecker(result);
      case "cac":
        return result.toLocaleString("ja-JP", {
          style: "currency",
          currency: "JPY"
        });
      case "payback_period":
        return decimalPointChecker(result) + "month";
      case "ltv":
        return result.toLocaleString("ja-JP", {
          style: "currency",
          currency: "JPY"
        });
      case "arpu":
        return result.toLocaleString("ja-JP", {
          style: "currency",
          currency: "JPY"
        });
      case "churn_rate":
        return decimalPointCheckerForPercent(result);
      case "mrr_churn_rate":
        return decimalPointCheckerForPercent(result);
      case "nrr":
        return decimalPointCheckerForPercent(result);
      case "grr":
        return decimalPointCheckerForPercent(result);
      case "quick_ratio":
        return decimalPointChecker(result);
      case "acv_cumulative":
        return result.toLocaleString("ja-JP", {
          style: "currency",
          currency: "JPY"
        });
      case "acv_new":
        return result.toLocaleString("ja-JP", {
          style: "currency",
          currency: "JPY"
        });
      case "cpa":
        return result.toLocaleString("ja-JP", {
          style: "currency",
          currency: "JPY"
        });
      case "conversion_rate":
        return decimalPointCheckerForPercent(result);
      case "client_churned":
        return decimalPointChecker(result) + "社";
      case "account_churned":
        return decimalPointChecker(result);
      case "revenue_churned":
        return result.toLocaleString("ja-JP", {
          style: "currency",
          currency: "JPY"
        });
      case "new_customer":
        return decimalPointChecker(result) + "社";
      case "active_customer":
        return decimalPointChecker(result) + "社";
      case "lead_count":
        return decimalPointChecker(result);
      case "lead_time":
        return decimalPointChecker(result);
      case "lead_count_rate":
        return result !== 0 ? decimalPointCheckerForPercent(result) : "0%";
      case "lead_transition_rate":
        return result !== 0 ? decimalPointCheckerForPercent(result) : "0%";
      case "rate":
        return result !== 0 ? decimalPointCheckerForPercent(result) : "0%";
      case "last_year_comparison_rate":
        return result !== 0 ? decimalPointCheckerForPercent(result) : "0%";
      default:
        return result.toLocaleString("ja-JP", {
          style: "currency",
          currency: "JPY"
        });
    }
  }
};

export const unitSwitcherForSaleCost = (result: number, target_data_name: string) => {
  //データの存在有無
  const dataIsExist = result !== null && result !== undefined;
  //データ型が正しいか
  const dataIsValid = typeof result === "number" && isNaN(result) === false;
  if (!dataIsExist) {
    return "NoData";
  } else if (!dataIsValid) {
    return "invalid data";
  } else {
    switch (target_data_name) {
      case "sale_cost_total":
        return result.toLocaleString("ja-JP", {
          style: "currency",
          currency: "JPY"
        });
      case "rate":
        return result !== 0 ? decimalPointCheckerForPercent(result) : "0%";
      default:
    }
  }
};

export const unitSwitcherForTop = (
  result: number,
  target_data_name: string
) => {
  //dataのresultがnullもしくはundefinedでない判定
  const dataIsExist = result !== null && result !== undefined;
  const dataIsValid = typeof result === "number";

  if (!dataIsExist) {
    return "NoData";
  } else if (!dataIsValid) {
    return "invalid data";
  } else {
    switch (target_data_name) {
      case "Revenue":
        return result.toLocaleString("ja-JP", {
          style: "currency",
          currency: "JPY"
        });
      case "MRR":
        return result.toLocaleString("ja-JP", {
          style: "currency",
          currency: "JPY"
        });
      case "UnitEconomics":
        return result.toFixed(1);
      case "cac":
        return result.toLocaleString("ja-JP", {
          style: "currency",
          currency: "JPY"
        });
      case "PaybackPeriod":
        return result.toLocaleString() + "month";
      case "LTV":
        return result.toLocaleString("ja-JP", {
          style: "currency",
          currency: "JPY"
        });
      case "arpu":
        return result.toLocaleString("ja-JP", {
          style: "currency",
          currency: "JPY"
        });
      case "churnRate":
        return decimalPointCheckerForPercent(result);
      case "NRR":
        return decimalPointCheckerForPercent(result);
      case "GRR":
        return decimalPointCheckerForPercent(result);
      case "QuickRatio":
        return result.toFixed(1);
      case "ACVCumulative":
        return result.toLocaleString("ja-JP", {
          style: "currency",
          currency: "JPY"
        });
      case "ACVNew":
        return result.toLocaleString("ja-JP", {
          style: "currency",
          currency: "JPY"
        });
      case "ConversionRate":
        return Number((result * 100).toFixed(1)).toLocaleString() + "%";
    }
  }
};

export const unitSwitcherForTooltip = (
  result: number,
  total_dataKey: string
) => {
  const dataIsExist = result !== null && result !== undefined;
  const dataIsValid = typeof result === "number";

  if (!dataIsExist) {
    return "NoData";
  } else if (!dataIsValid) {
    return "invalid data";
  } else {
    switch (total_dataKey) {
      case "mrr_total":
        return result.toLocaleString("ja-JP", {
          style: "currency",
          currency: "JPY"
        });
      case "mrr_objective":
        return result.toLocaleString("ja-JP", {
          style: "currency",
          currency: "JPY"
        });
      case "unit_economics_total":
        return (result * 1.2).toFixed(1);
      case "unit_economics_objective":
        return (result * 1.2).toFixed(1);
      case "cac_total":
        return result.toLocaleString("ja-JP", {
          style: "currency",
          currency: "JPY"
        });
      case "cac_objective":
        return result.toLocaleString("ja-JP", {
          style: "currency",
          currency: "JPY"
        });
      case "payback_period_total":
        return result.toLocaleString() + "month";
      case "payback_period_objective":
        return result.toLocaleString() + "month";
      case "ltv_total":
        return result.toLocaleString("ja-JP", {
          style: "currency",
          currency: "JPY"
        });
      case "ltv_objective":
        return result.toLocaleString("ja-JP", {
          style: "currency",
          currency: "JPY"
        });
      case "arpu_total":
        return result.toLocaleString("ja-JP", {
          style: "currency",
          currency: "JPY"
        });
      case "arpu_objective":
        return result.toLocaleString("ja-JP", {
          style: "currency",
          currency: "JPY"
        });
      case "churn_rate_total":
        return decimalPointCheckerForPercent(result);
      case "churn_rate_objective":
        return decimalPointCheckerForPercent(result);
      case "nrr_objective":
        return decimalPointCheckerForPercent(result);
      case "nrr_total":
        return decimalPointCheckerForPercent(result);
      case "grr_total":
        return decimalPointCheckerForPercent(result);
      case "conversion_rate_total":
        return decimalPointCheckerForPercent(result);
      case "lead_transition_rate":
        return decimalPointCheckerForPercent(result);
      default:
    }
  }
};
