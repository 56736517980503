import { SET_INDICATOR_FILTER, GET_FILTER_LIST_SUCCEEDED } from "./types"
import {RESET_ALL} from "../common/types";

const initialState: object[] = []

const filtersReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_ALL:
      return initialState;

    case GET_FILTER_LIST_SUCCEEDED:
      return action.filter_list
    default:
      return state
  }
}

export default filtersReducer
