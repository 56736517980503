import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const ForgetPassword = (params: {}) =>
	makeStyles((theme: Theme) => {
		const {} = params;

		return createStyles({
			ForgetPassword: {
                backgroundColor : "#F2F6FB",
                height: "100vh"
            },
			Container: {
				paddingTop: theme.spacing(7),
			},

			/*-*-*-*-* label *-*-*-*-*/
			label: {
				marginBottom: theme.spacing(5),
			},
			"label-Typography": {
				fontSize: 24,
				fontWeight: "bolder",
			},

			/*-*-*-*-* form *-*-*-*-*/
			form: {
				display: "flex",
				flexDirection: "column",
                padding: theme.spacing(4, 0),
                marginBottom : theme.spacing(3)
			},
			"form-input": {
                backgroundColor : "white"
            },

			/*-*-*-*-* submit *-*-*-*-*/
			submit: {
				textAlign: "center",
				marginBottom: theme.spacing(4),
			},
			"submit-btn": {
				minWidth: 296,
				backgroundColor: "#0066FF",
				color: "white",
				"&:hover": {
					backgroundColor: "#0066FF",
					color: "white",
				},
			},

			/*-*-*-*-* signin *-*-*-*-*/
			signin: {
                textAlign: "center",
            },
            "signin-link" : {
                textDecoration : "underline",
                textUnderlineOffset : "2px",
                color : "rgba(25,118,210 ,1)"
            }
		});
	})();
