import React from "react";
import styled from "styled-components";
import closeBtnSmall from "../../images/icons/close_large.svg";
import { useSelector, useDispatch } from "react-redux";
import blueDots from "../../images/icons/edit_gray.svg";
import { openCommentEditWindow, closeCommentsWindow } from "../../reducks/comments/actions";

//コメント小窓

const CommentListWindow = () => {

  const dispatch = useDispatch();
  const comments = useSelector((state: any) => state.comments.comments);

  const commentsWindow = useSelector((state: any) => state.comments.commentsWindow);

  const displayed_comments = comments.filter((comment: any) => comment.result_at.seconds === commentsWindow.result_at_timestamp);

  return (
    <CommentListWindowSC>

      {/* コメントセクション */}
      <CommentContainer className="CommentContainer" style={{ display: `${commentsWindow.display ? "block" : "none"}` }}>
        <CommentHeader>
          <MemoHeading>{new Date(commentsWindow.result_at_timestamp * 1000).toLocaleDateString("ja-JP")}へのコメント</MemoHeading>

          <CommentListCloseIcon onClick={() => dispatch(closeCommentsWindow())} />
        </CommentHeader>
        <CommentList>
          {
            displayed_comments.map((comment: any, index: number) => (
            <CommentItem>
              <CommentInfo>
                <span>{new Date(comment.updated_at.seconds * 1000).toLocaleDateString("ja-JP")}</span> | <span>{comment.user_name}</span>
              </CommentInfo>
              <CommentContent>{comment.content}</CommentContent>

              <div>
                <IconButton
                  onClick={() => {
                    dispatch(openCommentEditWindow(comment.docId, comment.content));
                  }}
                >
                  <img src={blueDots} alt="edit" />
                </IconButton>
              </div>
            </CommentItem>
          ))}
        </CommentList>
      </CommentContainer>

    </CommentListWindowSC>
  );
};

export default CommentListWindow;

const IconButton = styled.button`
  display: inline-block;
  position: absolute;
  right: 24px;
  top: 8px;
`;

const CommentContainer = styled.div`
  width: 216px;
  max-height: 300px;
  border-radius: 2px;
  background-color: #fff;
  padding: 8px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
`;

const MemoHeading = styled.p`
  display: inline-block;
  color: #435878;
  font-size: 10px;
  font-weight: bold;
  line-height: 15px;
`;

const CommentListCloseIcon = styled.button`
  display: inline-block;
  background-image: url(${closeBtnSmall});
  background-size: cover;
  position: relative;
  left: 58px;
  width: 16px;
  height: 16px;
`;
const CommentHeader = styled.div`
  position: relative;
  margin-bottom: 8px;
`;

const CommentList = styled.ul`
  overflow: scroll;
  height: 240px;
`;

const CommentItem = styled.li`
  position: relative;
  padding: 8px 0;
  border-bottom: 1px solid #e2e9f3;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    border-bottom: none;
  }
`;

const CommentInfo = styled.p`
  font-size: 8px;
  line-height: 12px;
  color: #7591bc;
  margin-bottom: 8px;
`;

const CommentContent = styled.p`
  word-wrap: break-word;
  color: #35455d;
  font-size: 10px;
  line-height: 15px;
  padding-right: 40px;
`;

const CommentListWindowSC = styled.div`
  position: absolute;
  top: 100px;
  right: 0;
  left: 0;
  margin: 0 auto;
  width: 216px;
  z-index: 3;
`;
