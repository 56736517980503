import {css} from 'styled-components'

export default  {
  primary:'#0066FF',
  primaryDarker:'#0050c9',
  visited:'#896CC7',
  reached:'#028CAB',
  unreached:'#AF002A',
  equal:'#35455D',
  secondary: "#E53E66"
}

export const resultColor = css`
  .reached {
    color: #028CAB;
  }
  .unreached {
    color: #AF002A;
  }
  .equal {
    color: #35455D;
  }
`