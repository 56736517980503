// resultデータから重要指標データの配列へ変換
function resultToMetrics(raw_results, target_data_name) {
  console.log('target_data_name', target_data_name);
  return raw_results.map((result) => {
    return {
      ...result[target_data_name],
      result_at: result.result_at,
      doc_id: result.doc_id
    };
  });
}

function getTargetDataSequenceResult(raw_data_array, pathname) {
  console.log('action.raw_data_array', raw_data_array);
  let target_data_name;
  switch(pathname) {
    case "/metrics/revenue":
      target_data_name = "revenue";
      break;
    case "/metrics/mrr":
      target_data_name = "mrr";
      break;
    case "/metrics/arpu":
      target_data_name = "arpu";
      break;
    case "/metrics/unit-economics":
      target_data_name = "unit_economics";
      break;
    case "/metrics/payback-period":
      target_data_name = "payback_period";
      break;
    case "/metrics/churn-rate":
      target_data_name = "churn_rate";
      break;
    case "/metrics/mrr-churn-rate":
      target_data_name = "mrr_churn_rate";
      break;
    case "/metrics/cac":
      target_data_name = "cac";
      break;
    case "/metrics/ltv":
      target_data_name = "ltv";
      break;
    case "/metrics/conversion_rate":
      target_data_name = "conversion_rate";
      break;
    case "/metrics/nrr":
      target_data_name = "nrr";
      break;
    case "/metrics/grr":
      target_data_name = "grr";
      break;
    case "/metrics/quick-ratio":
      target_data_name = "quick_ratio";
      break;
    case "/metrics/acv-cumulative":
      target_data_name = "acv_cumulative";
      break;
    case "/metrics/acv-new":
      target_data_name = "acv_new";
      break;
    case "/metrics/cpa":
      target_data_name = "cpa";
      break;
    case "/metrics/client-churnd":
      target_data_name = "client_churned";
      break;
    case "/metrics/account-churnd":
      target_data_name = "account_churnd";
      break;
    case "/metrics/revenue-churnd":
      target_data_name = "revenue_churnd";
      break;
    case "/metrics/new-customer":
      target_data_name = "new_customer";
      break;
    case "/metrics/active-customer":
      target_data_name = "active_customer";
      break;
    default:
      target_data_name = "revenue";
  }
  const target_data = resultToMetrics(raw_data_array, target_data_name)
  console.log('target_data', target_data);

  try {
    return target_data;
  } catch (e) {
    console.log("error message", e.message);
  }
}

export default getTargetDataSequenceResult;
