import {
  ADD_ITEM_INTO_GRID,
  REMOVE_ITEM_FROM_GRID,
  SAVE_LAYOUT,
  UPDATE_LAYOUT_SUCCEEDED,
  UPDATE_LAYOUT_FAILED,
  GET_LAYOUT_SUCCEEDED,
  GET_LAYOUT_FAILED,
  TOGGLE_GRAPH
} from "./gridTypes";
import { RESET_ALL } from "../common/types";
import update from "immutability-helper";

interface GridItem {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
}
interface Content {
  content: string;
  i: string;
}

const initialLayouts = {
  // gridItemsはreactGridLayoutにこの形で読ませないとエラーが出るため、それぞれの中身であるcontentは分けて記述。
  // component側ではcontent[index]番目のcontentという形で取得
  gridItems: [],
  contents: [
    { name: "Revenue", index: 1, display: "none" },
    { name: "MRR", index: 2, display: "none" },
    { name: "UnitEconomics", index: 3, display: "none" },
    { name: "cac", index: 4, display: "none" },
    { name: "PaybackPeriod", index: 5, display: "none" },
    { name: "LTV", index: 6, display: "none" },
    { name: "arpu", index: 7, display: "none" },
    { name: "churnRate", index: 8, display: "none" },
    { name: "NRR", index: 9, display: "none" },
    { name: "GRR", index: 10, display: "none" },
    { name: "QuickRatio", index: 11, display: "none" },
    { name: "ACVCumulative", index: 12, display: "none" },
    { name: "ACVNew", index: 13, display: "none" },
    { name: "ConversionRate", index: 14, display: "none" }
  ],
  message: "",
  compactType: "horizontal"
};

const gridReducer = (state = initialLayouts, action: any) => {
  switch (action.type) {
    case RESET_ALL:
      return initialLayouts;

    case TOGGLE_GRAPH:
      const contentsInitialState = action.contents;

      const contentsNewState = contentsInitialState.map((content: any) => {
        if (content.name === action.target) {
          if (!content.display) {
            content.display = true;
          } else {
            content.display = false;
          }
        }
        return content;
      });
      return {
        ...state,
        contents: contentsNewState
      };

    case ADD_ITEM_INTO_GRID:
      return {
        ...state,
        gridItems: [...state.gridItems, action.gridItem],
        contents: [
          ...state.contents,
          { content: action.content, i: action.gridItem.i }
        ]
      };
    case REMOVE_ITEM_FROM_GRID:
      return {
        ...state,
        gridItems: state.gridItems.filter(
          (gridItem: any) => gridItem.i !== action.target_i
        ),
        contents: state.contents.filter(
          (content: any) => content.i !== action.target_i
        )
      };
    case SAVE_LAYOUT:
      return {
        ...state,
        gridItems: action.payload
      };
    case UPDATE_LAYOUT_SUCCEEDED:
      return {
        ...state,
        ...action.gridLayout,
        message: action.message
      };

    case UPDATE_LAYOUT_FAILED:
      return {
        ...state,
        message: action.message
      };
    case GET_LAYOUT_SUCCEEDED:
      console.log("action.gridLayout.content", action.gridLayout.content);
      const sortedGridLayoutContents = action.gridLayout.content.sort(
        (a: any, b: any) => a.index - b.index
      );
      console.log("sortedGridLayoutContents", sortedGridLayoutContents);
      return {
        ...state,
        gridItems: action.gridLayout.layout,
        contents: sortedGridLayoutContents
      };
    case GET_LAYOUT_FAILED:
      return {
        ...state,
        message: action.message
      };
    default:
      return state;
  }
};

export default gridReducer;
