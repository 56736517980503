import React from "react";
import styled from "styled-components";
import { colorSwitcherRate } from "../../Util/colorSwitcher";
import { unitSwitcher } from "../../Util/unitSwitcher";
import reached_arrow from "../../../images/icons/reached_arrow.svg";
import unreached_arrow from "../../../images/icons/unreached_icon.svg";

interface ForecastPLHeaderProps {
  resultSpan: string;
  target_data: any;
  target_data_name: string;
  key_total: string;
  key_rate: string;
  key_difference: string;
  key_last_time_comparison_rate: string;
  key_last_time_difference: string;
}

const ForecastPLHeader: React.FC<ForecastPLHeaderProps> = props => {
  const {
    resultSpan,
    target_data,
    target_data_name,
    key_total,
    key_rate,
    key_difference,
    key_last_time_comparison_rate,
    key_last_time_difference
  } = props;
  console.log("props...", props);

  let sum_target_data_total = 0;

  target_data.forEach((td: any) => {
    if (
      td[key_total] === undefined ||
      td[key_total] === null ||
      isNaN(td[key_total])
    ) {
      return (td[key_total] = 0);
    }

    sum_target_data_total += td[key_total];
  });

  const period_average_sum =
    target_data && sum_target_data_total / target_data.length;

  return (
    <ForecastPLHeaderSC>
      {/* 直近の実績 */}
      <ForecastPLHeaderItem>
        <SummaryContent>
          <SummaryLabel>
            {
              // dailyかweeklyだったら一日単位で表示
              resultSpan !== "monthly" &&
                target_data[target_data.length - 1] &&
                `
              ${new Date(
                target_data[target_data.length - 1].result_at.seconds * 1000
              ).getFullYear()}.
              ${
                new Date(
                  target_data[target_data.length - 1].result_at.seconds * 1000
                ).getMonth() + 1
              }

            `
            }
            {
              // monthlyだったら月まで表示
              resultSpan === "monthly" &&
                target_data[target_data.length - 1] &&
                `
              ${new Date(
                target_data[target_data.length - 1].result_at.seconds * 1000
              ).getFullYear()}.
              ${
                new Date(
                  target_data[target_data.length - 1].result_at.seconds * 1000
                ).getMonth() + 1
              }
            `
            }
          </SummaryLabel>

          {/* 直近の実績データ */}
          <ForecastPLHeaderItemHeading
            className={
              target_data[target_data.length - 1] &&
              colorSwitcherRate(
                target_data_name,
                target_data[target_data.length - 1][key_total]
              )
            }
          >
            {target_data[target_data.length - 1] &&
              unitSwitcher(
                target_data[target_data.length - 1][key_total],
                target_data_name
              )}
          </ForecastPLHeaderItemHeading>

          {/* 前月比セクション */}
          <ForecastPLHeaderItemDetail>
            {/* ラベル */}
            <ForecastPLHeaderItemDetailLabel
              className={
                target_data[target_data.length - 1] &&
                colorSwitcherRate(
                  target_data_name,
                  target_data[target_data.length - 1][
                    key_last_time_comparison_rate
                  ]
                )
              }
            >
              前月比
            </ForecastPLHeaderItemDetailLabel>
            {/* 前月比 割合 */}
            <ForecastPLHeaderItemDetailPercentage
              className={
                target_data[target_data.length - 1] &&
                colorSwitcherRate(
                  target_data_name,
                  target_data[target_data.length - 1][
                    key_last_time_comparison_rate
                  ]
                )
              }
            >
              {target_data[target_data.length - 1] &&
                unitSwitcher(
                  target_data[target_data.length - 1][
                    key_last_time_comparison_rate
                  ],
                  "rate"
                )}
            </ForecastPLHeaderItemDetailPercentage>

            {/* 前月比 差異 */}
            <ForecastPLHeaderItemDetailDifference
              className={
                target_data[target_data.length - 1] &&
                colorSwitcherRate(
                  target_data_name,
                  target_data[target_data.length - 1][key_last_time_difference]
                )
              }
            >
              （
              {target_data[target_data.length - 1] &&
                unitSwitcher(
                  target_data[target_data.length - 1][key_last_time_difference],
                  "difference"
                )}
              ）
            </ForecastPLHeaderItemDetailDifference>
          </ForecastPLHeaderItemDetail>
        </SummaryContent>
      </ForecastPLHeaderItem>

      {/* 直近の目標比 */}
      <ForecastPLHeaderItem>
        <SummaryContent>
          {/* ラベル */}
          <SummaryLabel>目標比</SummaryLabel>

          {/* 目標比 */}
          <ForecastPLHeaderItemHeading
            className={
              target_data[target_data.length - 1] &&
              colorSwitcherRate(
                target_data_name,
                target_data[target_data.length - 1][key_difference]
              )
            }
          >
            {target_data[target_data.length - 1] &&
              unitSwitcher(
                target_data[target_data.length - 1][key_difference],
                target_data_name
              )}
          </ForecastPLHeaderItemHeading>

          {/* 目標比率 */}
          <ForecastPLHeaderItemDetail>
            {/* 前月比 割合 */}
            <ForecastPLHeaderItemDetailPercentage
              className={
                target_data[target_data.length - 1] &&
                colorSwitcherRate(
                  target_data_name,
                  target_data[target_data.length - 1][key_rate]
                )
              }
            >
              {target_data[target_data.length - 1] &&
                unitSwitcher(
                  target_data[target_data.length - 1][key_rate],
                  "rate"
                )}
            </ForecastPLHeaderItemDetailPercentage>
          </ForecastPLHeaderItemDetail>
        </SummaryContent>
      </ForecastPLHeaderItem>

      {/* 期間合計 */}
      <ForecastPLHeaderItem>
        <SummaryContent>
          <SummaryLabel>期間合計</SummaryLabel>

          {/* 期間合計 */}
          <ForecastPLHeaderItemHeading
            className={
              target_data[target_data.length - 1] &&
              colorSwitcherRate(target_data_name, sum_target_data_total)
            }
          >
            {target_data[target_data.length - 1] &&
              unitSwitcher(sum_target_data_total, target_data_name)}
          </ForecastPLHeaderItemHeading>

          <ForecastPLHeaderItemDetail>
            {/* ラベル */}
            <ForecastPLHeaderItemDetailLabel
              className={
                target_data[target_data.length - 1] &&
                colorSwitcherRate(
                  target_data_name,
                  target_data[target_data.length - 1][key_rate]
                )
              }
            >
              目標達成率
            </ForecastPLHeaderItemDetailLabel>
            {/* 目標達成率 割合 */}
            <ForecastPLHeaderItemDetailPercentage
              className={
                target_data[target_data.length - 1] &&
                colorSwitcherRate(
                  target_data_name,
                  target_data[target_data.length - 1][key_rate]
                ) &&
                colorSwitcherRate(
                  target_data_name,
                  target_data[target_data.length - 1][key_difference]
                )
              }
            >
              {target_data[target_data.length - 1] &&
                unitSwitcher(
                  target_data[target_data.length - 1][key_rate],
                  "rate"
                )}
            </ForecastPLHeaderItemDetailPercentage>
          </ForecastPLHeaderItemDetail>
        </SummaryContent>
      </ForecastPLHeaderItem>
    </ForecastPLHeaderSC>
  );
};

export default ForecastPLHeader;

const ForecastPLHeaderSC = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  background: #f4f9ff;
  padding: 25px 0;
`;

const ForecastPLHeaderItem = styled.div`
  width: calc(33.333% - 2px);
  background-color: #f4f9ff;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e2e9f3;
  &:last-child {
    border-right: none;
  }
`;

const SummaryContent = styled.div`
  display: flex;
  flex-direction: column;
  &:first-of-type {
    margin-right: 16px;
  }
  align-items: center;
`;

const SummaryLabel = styled.p`
  color: #666666;
  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
  align-items: center;
  margin-bottom: 14px;
`;

// ForecastPLHeaderItemHeading
const ForecastPLHeaderItemHeading = styled.p`
  font-weight: 600;
  font-size: 32px;
  color: #35455d;
  margin-bottom: 14px;
  /* &.reached {
    color: #10b6db;
  }
  &.unreached {
    color: #da1948;
  }
  &.equal {
    color: #35455d;
  } */
`;

const ForecastPLHeaderItemDetail = styled.div`
  text-align: center;
`;
const ForecastPLHeaderItemDetailLabel = styled.span`
  font-weight: 600;
  color: #666666;
  font-size: 12px;
  font-weight: 600;
  margin-right: 8px;
  &.reached {
    margin-right: 26px;
  }
  &.unreached {
    margin-right: 26px;
  }
  &.equal {
  }
`;
const ForecastPLHeaderItemDetailPercentage = styled.span`
  position: relative;
  font-weight: 600;
  color: #35455d;
  font-size: 12px;
  font-weight: 600;

  &::before {
    position: absolute;
    top: 2.5px;
    left: -14px;
    content: "";
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 12px;
    height: 11px;
  }

  &.reached {
    color: #10b6db;
    &::before {
      background-image: url(${reached_arrow});
    }
  }
  &.unreached {
    color: #da1948;
    &::before {
      background-image: url(${unreached_arrow});
    }
  }
  &.equal {
    color: #35455d;
  }
`;

const ForecastPLHeaderItemDetailDifference = styled.span`
  font-weight: 600;
  color: #35455d;
  font-size: 12px;
  font-weight: 600;
  &.reached {
    color: #10b6db;
  }
  &.unreached {
    color: #da1948;
  }
  &.equal {
    color: #35455d;
  }
`;
