import { GET_RAW_DATA_REQUESTED, GET_RAW_DATA_THIS_MONTH_REQUEST, GET_RAW_DATA_LAST_MONTH_REQUEST, GET_RAW_DATA_LAST_6_MONTH_REQUEST, GET_RAW_DATA_LAST_12_MONTH_REQUEST, GET_RAW_DATA_CUSTOM_SPAN_REQUEST, GET_RAW_DATA_WITH_SPAN_AND_TYPE_AND_TIME_REQUEST, GET_RAW_DATA_FOR_FILTER_REQUEST, SET_TARGET_LINE_OPACITY } from "./types";

export const getRawDataRequested = (resultSpan: string) => ({
  type: GET_RAW_DATA_REQUESTED,
  resultSpan
});

export const getRawDataThisMonthRequest = (company_id: string, selected_data_config_data_type: string, pathname: string, filter_name: string, timespan: number, target_data_name?: string) => ({
  type: GET_RAW_DATA_THIS_MONTH_REQUEST,
  company_id,
  selected_data_config_data_type,
  pathname,
  filter_name,
  timespan,
  target_data_name
});

export const getRawDataLastMonthRequest = (company_id: string, selected_data_config_data_type: string, pathname: string, filter_name: string, timespan: number, target_data_name?: string) => ({
  type: GET_RAW_DATA_LAST_MONTH_REQUEST,
  company_id,
  selected_data_config_data_type,
  pathname,
  filter_name,
  timespan,
  target_data_name
});

export const getRawDataLast6MonthRequest = (company_id: string, selected_data_config_data_type: string, pathname: string, filter_name: string, timespan: number, target_data_name?: string) => ({
  type: GET_RAW_DATA_LAST_6_MONTH_REQUEST,
  company_id,
  selected_data_config_data_type,
  pathname,
  filter_name,
  timespan,
  target_data_name
});

export const getRawDataLast12MonthRequest = (company_id: string, selected_data_config_data_type: string, pathname: string, filter_name: string, timespan: number, target_data_name?: string) => ({
  type: GET_RAW_DATA_LAST_12_MONTH_REQUEST,
  company_id,
  selected_data_config_data_type,
  pathname,
  filter_name,
  timespan,
  target_data_name
});

export const getRawDataCustomSpanRequest = (company_id: string, selected_data_config_data_type: string, custom_span_start_time: any, custom_span_end_time: any, pathname: string, filter_name: string, target_data_name?: string) => ({
  type: GET_RAW_DATA_CUSTOM_SPAN_REQUEST,
  company_id,
  selected_data_config_data_type,
  custom_span_start_time,
  custom_span_end_time,
  pathname,
  filter_name,
  target_data_name
});

export const getRawDataWithSpanAndTypeAndTimeRequest = (company_id: string, selected_data_config_data_type: string, selected_data_config_data_span: string, selected_data_custom_span_start_time: any, selected_data_custom_span_end_time: any, pathname: string) => ({
  type: GET_RAW_DATA_WITH_SPAN_AND_TYPE_AND_TIME_REQUEST,
  company_id,
  selected_data_config_data_type,
  selected_data_config_data_span,
  selected_data_custom_span_start_time,
  selected_data_custom_span_end_time,
  pathname
});

export const getRawDataForFilterRequest = (company_id: string, filter_name: string, target_data_name: string, selected_data_config_data_type: string, selected_data_config_data_span: string, custom_span_start_time: any, custom_span_end_time: any) => {
  return {
    type: GET_RAW_DATA_FOR_FILTER_REQUEST,
    company_id,
    filter_name,
    target_data_name,
    selected_data_config_data_type,
    selected_data_config_data_span,
    custom_span_start_time,
    custom_span_end_time
  };
};


export const setTargetLineOpacity = (dataKey: string, opacity: number ) => {
  return {
    type: SET_TARGET_LINE_OPACITY,
    dataKey,
    opacity
  }
}