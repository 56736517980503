import React from 'react';
import styled from 'styled-components';

// HeaderTitle
const HeaderBlock = styled.div`
  border-top: 8px solid #0050C9;
  padding: 16px 24px 14px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
`;
const HeaderTitle = styled.h2`
  font-size: 28px;
  line-height: 42px;
  margin: 0;
  font-weight: bold;
`;



const PageHeaderNoCarendar = ({page_title}: {page_title: string}) => {
  return(
    <HeaderBlock>
      <HeaderTitle>
        { page_title }
      </HeaderTitle>
    </HeaderBlock>
  );
}

export default PageHeaderNoCarendar;