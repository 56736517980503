import React, { Component } from "react";
import styled from "styled-components";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { ResponsiveContainer, ComposedChart, Line, Bar, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import revenueIcon from '../../../images/icons/graph_icon_revenue.svg';
import profitIcon from '../../../images/icons/graph_icon_profit.svg';
import  { yAxisFormatter } from "../../Util/yAxisFormatter"


// Legend(PL)
const customLegendPL = () => {
  return (
    <ul style={{ display: "flex", flexWrap: "wrap", alignItems: "center", fontSize: "12px", color: "#283f46" }}>
      <LegendItem className="customLegend-revenue">売上</LegendItem>
      <LegendItem className="customLegend-revenueObj">売上目標</LegendItem>
      <LegendItem className="customLegend-profit">営業利益</LegendItem>
      <LegendItem className="customLegend-profitObj">営業利益目標</LegendItem>
    </ul>
  );
};

interface Props {
  areaRef: any;
  activatedFilter: any;
  this_time_graph_switch: any;
  moving_average_graph_switch: any;
  target_data_name: any;
  key_total: any;
  key_objective: any;
  key_moving_average: any;
  key_moving_average_objective: any;
  target_data: any;
  spanComparisonData: any;
  resultSpan: any;
}

export default class ForecastPLGraphSpanFilter extends Component<Props> {
  render() {
    return (
      <>
      {
        this.props.spanComparisonData.length > 0 ? (
        <Graph>
          <ResponsiveContainer>
            <ComposedChart width={500} height={400} data={this.props.spanComparisonData} margin={{ top: 20, right: 20, bottom: 20, left: 20 }} ref={this.props.areaRef}>
              <CartesianGrid vertical={false} stroke="#f5f5f5" />
              <XAxis hide={true} scale="point" />
              <YAxis tickCount={10} interval={0} tickLine={false} axisLine={false} tickMargin={10} tickFormatter={tick => yAxisFormatter(tick, 'revenue')} />
              {/* <Tooltip content={customTooltip} /> */}
              <Legend content={customLegendPL} iconSize={20} verticalAlign="top" align="left" margin={{ bottom: 5 }} />

              <Line name="期間１売上" type="linear" dot={false} dataKey="first_forecasts_revenue" stroke="#0066FF" strokeWidth="3" strokeDasharray="5 5" />
              <Line name="期間２売上" type="linear" dot={false} dataKey="second_forecasts_revenue" stroke="#E53E66" strokeWidth="3" strokeDasharray="5 5" />


              <Line name="期間１営業利益" type="linear" dot={false} dataKey="first_forecasts_profit" stroke="#0066FF" strokeWidth="3" />
              <Line name="期間２営業利益" type="linear" dot={false} dataKey="second_forecasts_profit" stroke="#E53E66" strokeWidth="3" />

            </ComposedChart>
          </ResponsiveContainer>
        </Graph>
        ):(
          <NoData>グラフを表示できません</NoData>
        )
      }
      </>
    );
  }
}

// カスタムLegend
const LegendItem = styled.li`
  font-size: 12px;
  color: #283f46;
  margin: 0 10px 10px 0;
  padding-left: 15px;
  box-sizing: border-box;
  position: relative;
  &::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 54%;
    left: 0;
    transform: translateY(-50%);
  }
  &.customLegend-revenue {
    &::before {
      background-color: #0066ff;
    }
    &.forecast {
      &::before {
        border-top: 2px dashed #0066ff;
      }
    }
  }
  &.customLegend-revenueObj {
    padding-left: 24px;
    &::before {
      width: 19px;
      height: 14px;
      border-radius: 0;
      top: 50%;
      background: url(${revenueIcon});
    }
  }
  &.customLegend-profit {
    &::before {
      background-color: #ec6e6e;
    }
    &.forecast {
      &::before {
        border-top: 2px dashed #ec6e6e;
      }
    }
  }
  &.customLegend-profitObj {
    padding-left: 24px;
    &::before {
      width: 19px;
      height: 14px;
      border-radius: 0;
      top: 50%;
      background: url(${profitIcon});
    }
  }
  &.customLegend-cac {
    &::before {
      background-color: #0066ff;
    }
    &.forecast {
      &::before {
        border-top: 2px dashed #0066ff;
      }
    }
  }
  &.customLegend-arpu {
    &::before {
      background-color: #ffb800;
    }
    &.forecast {
      &::before {
        border-top: 2px dashed #ffb800;
      }
    }
  }
  &.customLegend-ltv {
    &::before {
      background-color: #ec6e6e;
    }
    &.forecast {
      &::before {
        border-top: 2px dashed #ec6e6e;
      }
    }
  }
  &.customLegend-ue {
    &::before {
      background-color: #eac400;
    }
    &.forecast {
      &::before {
        border-top: 2px dashed #eac400;
      }
    }
  }
  &.customLegend-cr {
    &::before {
      background-color: #61bcbd;
    }
    &.forecast {
      &::before {
        border-top: 2px dashed #61bcbd;
      }
    }
  }
  &.customLegend-pp {
    &::before {
      background-color: #6b6d82;
    }
    &.forecast {
      &::before {
        border-top: 2px dashed #6b6d82;
      }
    }
  }
  &.customLegend-mrr {
    &::before {
      background-color: #d9dade;
    }
    &.forecast {
      &::before {
        border-top: 2px dashed #d9dade;
      }
    }
  }
  &.customLegend-nrr {
    &::before {
      background-color: #5ac064;
    }
    &.forecast {
      &::before {
        border-top: 2px dashed #5ac064;
      }
    }
  }
  &.customLegend-inflow {
    &::before {
      background-color: #0066ff;
    }
    &.second {
      &::before {
        background-color: #0066ff;
      }
    }
    &.transition {
      &::before {
        background-color: #0066ff;
      }
      &.forecast {
        &::before {
          border: 2px dashed #0066ff;
        }
      }
      &.second {
        &::before {
          background-color: #0066ff;
        }
      }
    }
  }
  &.customLegend-effective {
    &::before {
      background-color: #ec6e6e;
    }
    &.second {
      &::before {
        background-color: #e53e66;
      }
    }
    &.transition {
      &::before {
        background-color: #ec6e6e;
      }
      &.forecast {
        &::before {
          border: 2px dashed #ec6e6e;
        }
      }
      &.second {
        &::before {
          background-color: #e53e66;
        }
      }
    }
  }
  &.customLegend-appointment {
    &::before {
      background-color: #4fcd73;
    }
    &.second {
      &::before {
        background-color: #009479;
      }
    }
    &.transition {
      &::before {
        background-color: #4fcd73;
      }
      &.forecast {
        &::before {
          border: 2px dashed #4fcd73;
        }
      }
      &.second {
        &::before {
          background-color: #009479;
        }
      }
    }
  }
  &.funnel {
    &::before {
      height: 10px;
      border-radius: 0;
    }
  }
  &.forecast {
    padding-left: 25px;
    &::before {
      width: 20px;
      height: 1px;
      border-radius: 0;
      background-color: transparent;
    }
    &.transition {
      &::before {
        background-color: transparent;
      }
    }
  }
`;

// Graph
const Graph = styled.div`
  width: 100%;
  height: 272px;
  margin-bottom: 18px;
  padding: 0 10px;
  box-sizing: border-box;
  position: relative;
  z-index: 100;

  .recharts-legend-wrapper {
    top: 1px !important;
    left: 10px !important;
  }
  .recharts-legend-item-text {
    font-size: 12px;
    color: #283f46;
  }
  .recharts-legend-item {
    position: relative;
    svg {
      display: none !important;
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      transform: translateY(-50%);
    }
    &.legend-item-0,
    &.legend-item-2 {
      padding-left: 15px;
      &::before {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        top: 54%;
      }
    }
    &.legend-item-1,
    &.legend-item-3 {
      padding-left: 24px;
      &::before {
        width: 19px;
        height: 14px;
        top: 50%;
      }
    }
    &.legend-item-0 {
      &::before {
        background-color: #0066ff;
      }
    }
    &.legend-item-1 {
      &::before {
        background-image: url(${revenueIcon});
      }
    }
    &.legend-item-2 {
      &::before {
        background-color: #ec6e6e;
      }
    }
    &.legend-item-3 {
      &::before {
        background-image: url(${profitIcon});
      }
    }
  }
  .recharts-surface {
    overflow: visible;
  }
  .recharts-cartesian-axis-tick {
    .recharts-cartesian-axis-tick-value {
      font-size: 10px;
      color: #435878;
    }
  }
`;

const NoData = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #435878;
  margin-bottom: 8px;
  &.main {
    margin-bottom: 8px;
  }
`

// GraphXAxis
const GraphXAxis = styled.div`
  position: relative;
  top: -20px;
  width: 100%;
  &.marketing_funnel {
    max-width: 660px;
    margin: 0 auto;
  }
`;

const GraphXAxisList = styled.ul`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 70px);
  margin-right: 0;
  margin-left: auto;
  font-size: 12px;
  color: #566970;
  &.marketing_funnel {
    justify-content: space-arround;
    min-width: calc(660px - 6%);
    margin: 0 auto;
  }
`;
