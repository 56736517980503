import React, { useCallback, useState } from "react";
import FileSaver from "file-saver";
import {
  updateDownloadGraphStatus,
} from "../../../reducks/download/actions";
import {
  updateDownloadCsvTarget,
} from "../../../reducks/download/actions";
import {
  updateDownloadTabName,
} from "../../../reducks/download/actions";
import { useCurrentPng } from "recharts-to-png";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart
} from "recharts";
import { unitSwitcher } from "../../Util/unitSwitcher";
import { DraftTable } from "./VariantFilterTable";
import unchecked from "../../../images/icons/unckecked.svg";
import checked from "../../../images/icons/checked.svg";
import { yAxisFormatter } from "../../Util/yAxisFormatter";
import { dateFormatter } from "../../Util/DateFormatter";
import { titleSwitcher } from "../../Util/titleSwitcher";
import GraphDataDownload from "../../Parts/GraphDataDownload";

// プラン比較　売上・社数推移　ComposedChart
const CustomTooltip = ({
  active,
  payload,
  target_data_name,
  targetDataType
}: any) => {
  if (active && payload && payload.length) {
    return (
      <StyledCustomTooltip>
        <TooltipHeading>{payload[0].payload.funnel_name}</TooltipHeading>

        {payload.map((payload_item: any) => (
          <TooltipContent key={payload_item.dataKey}>
            <TooltipLabel>{payload_item.name}</TooltipLabel>
            <TooltipData>
              {targetDataType === "sales"
                ? `${unitSwitcher(payload_item.value, target_data_name)}`
                : `${unitSwitcher(payload_item.value, "active_customer")}`}
            </TooltipData>
            <TooltipHR />
          </TooltipContent>
        ))}
      </StyledCustomTooltip>
    );
  }

  return null;
};

// プラン比較　売上・社数推移　StackedChart
const CustomTooltipForStackedChart = ({
  active,
  payload,
  target_data_name,
  targetDataType
}: any) => {
  if (active && payload && payload.length) {
    return (
      <StackedGraphTooltip>
        <StackedGraphTooltipHeading>
          {payload[0].payload.funnel_name}
        </StackedGraphTooltipHeading>

        {payload.map((payload_item: any) => (
          <StackedGraphTooltipContent key={payload_item.dataKey}>
            <StackedGraphTooltipLabel bgcolor={payload_item.fill}>
              {payload_item.name}
            </StackedGraphTooltipLabel>
            <StackedGraphTooltipData>
              {targetDataType === "sales"
                ? `${unitSwitcher(payload_item.value, target_data_name)}`
                : `${unitSwitcher(payload_item.value, "active_customer")}`}
            </StackedGraphTooltipData>
            <StackedGraphTooltipHR />
          </StackedGraphTooltipContent>
        ))}
      </StackedGraphTooltip>
    );
  }

  return null;
};

const graph_color_array = [
  {
    id: 1,
    color_code: "#0050C9"
  },
  {
    id: 2,
    color_code: "#EAA800"
  },
  {
    id: 3,
    color_code: "#E53E66"
  },
  {
    id: 4,
    color_code: "#B7D20D"
  },
  {
    id: 5,
    color_code: "#009479"
  },
  {
    id: 6,
    color_code: "#109CBB"
  },
  {
    id: 7,
    color_code: "#9E00FF"
  }
];

interface VariantFilterGraphAndTableProps {
  target_data_name: string;
  labels_name_array: any;
  filtered_data: any;
  result_span: string;
}

const VariantFilterGraphAndTable: React.FC<VariantFilterGraphAndTableProps> = ({
  target_data_name,
  labels_name_array,
  filtered_data,
  result_span
}) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState(filtered_data);
  const [showPlanAverage, setShowPlanAverage] = React.useState(true);
  const [targetDataType, setTargetDataType] = React.useState("sales");
  const activatedFilter = useSelector((state: any) => state.filter);
  const [labelsNameArray, setLabelsNameArray] =
    React.useState(labels_name_array);

  console.log(
    "labels_name_arraylabels_name_arraylabels_name_array",
    labels_name_array
  );
  console.log("labelsNameArraylabelsNameArraylabelsNameArray", labelsNameArray);

  React.useEffect(() => {
    const names = labels_name_array.map((lnItem: any) => {
      if (targetDataType === "sales") {
        return lnItem.dataKeyNameTotal;
      } else {
        return lnItem.dataKeyNameTotalAccount;
      }
    });

    const filteredDataWithAverage = filtered_data.map((fd: any) => {
      let tempA = 0;
      Object.keys(fd).forEach((key: any) => {
        if (names.includes(key)) {
          tempA += Number(fd[key]);
        }
      });

      return {
        ...fd,
        average: tempA / labels_name_array.length
      };
    });

    setData(filteredDataWithAverage);

    setLabelsNameArray(
      labels_name_array
        .filter(
          (item: any, index: number, self: any) =>
            index === self.findIndex((t: any) => t.nameList === item.nameList)
        )
        .map((od: any, index: number) => {
          if (index < 7) {
            return {
              ...od,
              color: graph_color_array[index].color_code
            };
          } else {
            const calc_index = index % 7;
            return {
              ...od,
              color: graph_color_array[calc_index].color_code
            };
          }
        })
    );
  }, [filtered_data, labels_name_array, targetDataType]);

  // カスタムレジェンドから呼び出す、レジェンドアイテムのクリック関数
  const handleLegendItemClick = (dataKey: any) => {
    const updatedPlanNameArray = labelsNameArray.map((planNameItem: any) => {
      if (dataKey === planNameItem.nameList) {
        return {
          ...planNameItem,
          deactivated: !planNameItem.deactivated
        };
      }
      return planNameItem;
    });
    setLabelsNameArray(updatedPlanNameArray);
  };

  // カスタムレジェンド
  const CustomLegend = (props: any) => {
    return (
      <LegendListContainer>
        <LegendList>
          {labelsNameArray.map((dataItem: any, index: number) => (
            <LegendItem
              key={`LegendItem-${index}`}
              className={dataItem.deactivated ? "deactivated" : ""}
              onClick={() => handleLegendItemClick(dataItem.nameList)}
            >
              <button className="item-total">
                <span style={{ backgroundColor: `${dataItem.color}` }}></span>
                {dataItem.nameList}
              </button>
            </LegendItem>
          ))}
        </LegendList>
      </LegendListContainer>
    );
  };

  // Graph Download Section
  const [getVariantAreaPng, { ref: variantAreaRef }] = useCurrentPng();
  const [getSalesRatioAreaPng, { ref: salesRatioAreaRef }] = useCurrentPng();

  const downloadGraphStatus = useSelector((state: any) => state.download.downloadGraphStatus);
  const handleAreaDownload = useCallback(async (target='') => {
    dispatch(
      updateDownloadGraphStatus(0)
    )
    const png = (target==='sales_ratio') ? await getSalesRatioAreaPng() : await getVariantAreaPng();
    if (png) {
      FileSaver.saveAs(png, "graph.png");
    }
  }, [getVariantAreaPng, getSalesRatioAreaPng, dispatch]);

  if (downloadGraphStatus === 1) {
    handleAreaDownload();
  }

  function handleGraphDataDownloadClick(e:any, target:string) {
    if(e.target.innerText==='グラフ Export'){
        handleAreaDownload(target);
    }
    dispatch(
      updateDownloadCsvTarget("/"+activatedFilter+"/"+targetDataType+"/"+target)
    )
  }

  React.useEffect(() => {
    dispatch(
      updateDownloadTabName(targetDataType)
    )
  }, [targetDataType, dispatch]);

  return (
    <>
      {/* プラン比較：売上・社数推移 */}
      <ContentCard>
        <CardBody>
          <FunnelSummaryGraphTransitionRateLeadTimeHeading>
            <SalesCompanySwitcher>
              <SalesCompanySwitchButton
                onClick={() => setTargetDataType("sales")}
                className={targetDataType === "sales" ? "active" : ""}
              >
                {titleSwitcher(target_data_name)}
              </SalesCompanySwitchButton>
              <SalesCompanySwitchButton
                onClick={() => setTargetDataType("companyCount")}
                className={targetDataType === "companyCount" ? "active" : ""}
              >
                社数
              </SalesCompanySwitchButton>
            </SalesCompanySwitcher>
            <AverageSwitcher>
              <AverageGraphSwitchButton
                onClick={() => setShowPlanAverage(!showPlanAverage)}
              >
                <AverageGraphSwitchButtonCheckBox
                  className={showPlanAverage ? "activated" : ""}
                />
                <AverageGraphSwitchButtonWord>
                  平均
                </AverageGraphSwitchButtonWord>
              </AverageGraphSwitchButton>
            </AverageSwitcher>
          </FunnelSummaryGraphTransitionRateLeadTimeHeading>
          {data.length > 0 ? (
            // 社数データが入るまでスタイル調整
            <FunnelSummaryGraph>
              <ResponsiveContainer>
                <ComposedChart width={730} height={250} data={data} ref={variantAreaRef}>
                  <XAxis
                    dataKey={`result_at.seconds`}
                    tickLine={false}
                    tickSize={10}
                    minTickGap={20}
                    tick={{ fontSize: 10 }}
                    tickFormatter={tick =>
                      dateFormatter(result_span, Number(tick * 1000))
                    }
                  />

                  <YAxis
                    type="number"
                    tickCount={10}
                    interval={0}
                    tickMargin={10}
                    tickLine={false}
                    yAxisId={0}
                    tickFormatter={
                      targetDataType === "sales"
                        ? tick => yAxisFormatter(tick, target_data_name)
                        : tick => yAxisFormatter(tick, "active_customer")
                    }
                  />

                  <Legend
                    verticalAlign={"top"}
                    align={"left"}
                    content={CustomLegend}
                  />

                  <CartesianGrid stroke="#f5f5f5" />

                  {labelsNameArray.map(
                    (pn: any) =>
                      !pn.deactivated && (
                        <Bar
                          name={pn.nameList}
                          dataKey={
                            targetDataType === "sales"
                              ? `${pn.nameList}_${target_data_name}_total`
                              : `${pn.nameList}_${target_data_name}_account_total`
                          }
                          fill={pn.color}
                          yAxisId={0}
                        />
                      )
                  )}

                  {showPlanAverage && (
                    <Line
                      type="linear"
                      name="average"
                      dataKey="average"
                      dot={false}
                      stroke="#0066FF"
                      strokeWidth="5"
                      yAxisId={0}
                    />
                  )}

                  <Tooltip
                    content={
                      <CustomTooltip
                        target_data_name={target_data_name}
                        targetDataType={targetDataType}
                      />
                    }
                    wrapperStyle={{ zIndex: 10000 }}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </FunnelSummaryGraph>
          ) : (
            <NoData>グラフを表示できません</NoData>
          )}

          {/* テーブル */}
          <DraftTable
            data={data}
            planNameArray={labelsNameArray}
            result_span={result_span}
            target_data_name={target_data_name}
            targetDataType={targetDataType}
          />
        </CardBody>
      </ContentCard>

      {/* プラン比較：売上比率 */}
      <ContentCard>
        <CardBody>
          <CardTitleGraphDownloadWrapper>
            <CardHeading>売上比率</CardHeading>
              <div onClick={(e) => {handleGraphDataDownloadClick(e, 'sales_ratio')}}>
                <GraphDataDownload />
              </div>
            </CardTitleGraphDownloadWrapper>
          <FunnelSummaryGraph>
            <ResponsiveContainer>
              <BarChart width={730} height={250} data={data} ref={salesRatioAreaRef}>
                <XAxis
                  dataKey="result_at.seconds"
                  tickLine={false}
                  tickSize={10}
                  minTickGap={20}
                  tick={{ fontSize: 10 }}
                  tickFormatter={tick =>
                    dateFormatter(result_span, Number(tick * 1000))
                  }
                />

                <YAxis
                  type="number"
                  tickCount={10}
                  interval={0}
                  tickMargin={10}
                  tickLine={false}
                  yAxisId={0}
                  tickFormatter={
                    targetDataType === "sales"
                      ? tick => yAxisFormatter(tick, target_data_name)
                      : tick => yAxisFormatter(tick, "active_customer")
                  }
                />
                <CartesianGrid stroke="#f5f5f5" />

                {labelsNameArray.map(
                  (pn: any) =>
                    !pn.deactivated && (
                      <Bar
                        id={pn.nameList}
                        name={pn.nameList}
                        dataKey={
                          targetDataType === "sales"
                            ? `${pn.nameList}_${target_data_name}_total`
                            : `${pn.nameList}_${target_data_name}_account_total`
                        }
                        fill={pn.color}
                        yAxisId={0}
                        stackId="channel_bar"
                      />
                    )
                )}
                <Tooltip
                  content={
                    <CustomTooltipForStackedChart
                      target_data_name={target_data_name}
                      targetDataType={targetDataType}
                    />
                  }
                  cursor={{ fill: "transparent" }}
                  wrapperStyle={{ zIndex: 10000 }}
                />
              </BarChart>
            </ResponsiveContainer>
          </FunnelSummaryGraph>
        </CardBody>
      </ContentCard>
    </>
  );
};
export default VariantFilterGraphAndTable;

// Graph
const FunnelSummaryGraph = styled.div`
  width: 100%;
  height: 340px;
  box-sizing: border-box;

  .recharts-surface {
    position: relative;
    top: -1px;
    overflow: visible;
  }
  .recharts-legend-wrapper {
    top: -10px !important;
    height: 80px !important;
  }
  .recharts-cartesian-axis-tick {
    width: 100px !important;
    .recharts-cartesian-axis-tick-value {
      font-size: 10px;
      color: #435878;
    }
  }
`;
const NoData = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #435878;
  margin-bottom: 8px;
  &.main {
    margin-bottom: 8px;
  }
`;

const FunnelSummaryGraphTransitionRateLeadTimeHeading = styled.h2`
  color: #283f46;
  font-weight: bold;
  font-size: 22px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;

const ContentCard = styled.div`
  margin-bottom: 16px;
  border: 1px solid #e1e8ea;
  box-sizing: border-box;
  background-color: #fff;
`;
// CardBody
const CardBody = styled.div`
  padding: 15px 17px;
  box-sizing: border-box;
  position: relative;
`;

const LegendListContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

const LegendList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding-top: 15px;
`;

const LegendItem = styled.li`
  font-size: 12px;
  line-height: 1.6;
  margin-right: 16px;
  margin-bottom: 10px;
  padding: 6px 8px 6px 30px;
  box-sizing: border-box;
  position: relative;
  border: 1px solid #edf0f1;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
  &.deactivated {
    background-color: #edf0f1;
  }
  button {
    position: relative;
    span {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 2px;
      left: -22px;
      border-radius: 8px;
    }
  }
`;

const StyledCustomTooltip = styled.div`
  background: #fff;
  padding: 8px 9px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid #e2e9f3;
  border-radius: 4px;
  max-width: 200px;
  overflow-y: auto;
  max-height: 300px;
  pointer-events: auto;
`;

// 日付
const TooltipHeading = styled.h3`
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  color: #435878;
  margin-bottom: 5px;
`;

const TooltipContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
// 当月 | 目標
const TooltipLabel = styled.p`
  font-size: 10px;
  color: #435878;
  margin-bottom: 3px;
`;
// データ
const TooltipData = styled.p`
  text-align: right;
  font-size: 10px;
  font-weight: bold;
  color: #435878;
  margin-bottom: 3px;
`;
// 仕切り線
const TooltipHR = styled.hr`
  width: 100%;
  height: 1px;
  color: lightgray;
  margin-bottom: 3px;
`;

const StackedGraphTooltip = styled.div`
  background: #fff;
  padding: 8px 9px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid #e2e9f3;
  border-radius: 4px;
  max-width: 200px;
`;

// 日付
const StackedGraphTooltipHeading = styled.h3`
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  color: #435878;
  margin-bottom: 5px;
`;

const StackedGraphTooltipContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
// 当月 | 目標
const StackedGraphTooltipLabel = styled.p<{ bgcolor: string }>`
  position: relative;
  font-size: 10px;
  color: #435878;
  left: 10px;
  margin-bottom: 3px;
  &::before {
    position: absolute;
    top: 3px;
    left: -8px;
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: ${props => props.bgcolor};
  }
`;
// データ
const StackedGraphTooltipData = styled.p`
  text-align: right;
  font-size: 10px;
  font-weight: bold;
  color: #435878;
  margin-bottom: 3px;
`;
// 仕切り線
const StackedGraphTooltipHR = styled.hr`
  width: 100%;
  height: 1px;
  color: lightgray;
  margin-bottom: 3px;
`;
const CardHeading = styled.h2`
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #2a3385;
  margin: 0 0 12px 0;
`;

const SalesCompanySwitcher = styled.div`
  display: flex;
`;
const SalesCompanySwitchButton = styled.button`
  display: block;
  font-size: 12px;
  text-align: center;
  border: 1px solid #edf0f1;
  color: #6b6d82;
  font-weight: normal;
  padding: 6px 0;
  padding: 6px 10px;
  min-width: 92px;
  &:first-child {
    border-radius: 4px 0 0 4px;
  }
  &:last-child {
    border-radius: 0 4px 4px 0;
  }
  &.active {
    background-color: #0066ff;
    color: #ffffff;
    font-weight: bold;
  }
`;
const AverageSwitcher = styled.div``;

const AverageGraphSwitchButton = styled.button`
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  width: 82px;
  height: 30px;
  border-radius: 4px;
  padding: 0 12px;
  text-align: center;
`;
const AverageGraphSwitchButtonCheckBox = styled.span`
  display: inline-block;
  position: relative;
  width: 12px;
  height: 12px;
  margin-right: 6px;
  vertical-align: middle;
  content: "";
  background-image: url(${unchecked});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  &.activated {
    &::after {
      position: absolute;
      content: "";
      background-image: url(${checked});
      background-size: contain;
      background-position: center;
      width: 12px;
      height: 12px;
      top: 0;
      left: 0;
      background-repeat: no-repeat;
    }
  }
`;
const AverageGraphSwitchButtonWord = styled.span`
  display: inline-block;
  font-size: 10px;
  vertical-align: middle;
`;

const CardTitleGraphDownloadWrapper = styled.div({
  flexGrow: 1,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});
