import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import graphActivatedIcon from "../../images/icons/graph_activated_icon.svg";
import checked from "../../images/icons/checked.svg";
import unckecked from "../../images/icons/unckecked.svg";
import {
  toggleMovingAverageGraphSwitch,
  toggleThisTimeGraphSwitch,
  togglePeriodicAverageGraph
} from "../../reducks/sessions/actions";

interface Props {
  graphType?: string;
}

function GraphSwitcher(props: Props) {
  const dispatch = useDispatch();
  const resultSpan = useSelector(
    (state: any) => state.raw_data.selected_data_config.data_type
  );
  const activatedFilter = useSelector((state: any) => state.filter);

  // グラフの表示切り替え
  const this_time_graph_switch = useSelector(
    (state: any) => state.session.this_time_graph_switch
  );
  const moving_average_graph_switch = useSelector(
    (state: any) => state.session.moving_average_graph_switch
  );
  const periodic_average_graph = useSelector(
    (state: any) => state.session.periodic_average_graph
  );

  return (
    <GraphSwitchButtons className={props.graphType}>
      {activatedFilter === "" && (
        <MovingAverageGraphSwitchButton
          onClick={() =>
            dispatch(
              toggleMovingAverageGraphSwitch(moving_average_graph_switch)
            )
          }
        >
          <MovingAverageGraphSwitchButtonCheckBox
            className={moving_average_graph_switch && "activated"}
          />
          <MovingAverageGraphSwitchButtonWord>
            移動平均
          </MovingAverageGraphSwitchButtonWord>
        </MovingAverageGraphSwitchButton>
      )}
      {activatedFilter === "" && (
        <MovingAverageGraphSwitchButton
          onClick={() =>
            dispatch(togglePeriodicAverageGraph(periodic_average_graph))
          }
        >
          <MovingAverageGraphSwitchButtonCheckBox
            className={periodic_average_graph && "activated"}
          />
          <MovingAverageGraphSwitchButtonWord>
            期間平均
          </MovingAverageGraphSwitchButtonWord>
        </MovingAverageGraphSwitchButton>
      )}

      {/* { activatedFilter === "" &&
      <ThisTimeGraphSwitchButton onClick={() => dispatch(toggleThisTimeGraphSwitch(this_time_graph_switch))}>
        <ThisTimeGraphSwitchButtonCheckBox className={this_time_graph_switch && "activated"} />
        {resultSpan === "monthly" && <ThisTimeGraphSwitchButtonWord>当月</ThisTimeGraphSwitchButtonWord>}
        {resultSpan === "weekly" && <ThisTimeGraphSwitchButtonWord>当週</ThisTimeGraphSwitchButtonWord>}
        {resultSpan === "daily" && <ThisTimeGraphSwitchButtonWord>当日</ThisTimeGraphSwitchButtonWord>}
      </ThisTimeGraphSwitchButton>
      } */}
    </GraphSwitchButtons>
  );
}

export default GraphSwitcher;

// 移動平均・当月グラフの切り替えスイッチ
const GraphSwitchButtons = styled.div`
  position: relative;
  text-align: right;
`;
const MovingAverageGraphSwitchButton = styled.button`
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  width: 82px;
  height: 30px;
  border-radius: 4px;
  margin-right: 10px;
  padding: 2px 12px 5px;
`;
const MovingAverageGraphSwitchButtonCheckBox = styled.span`
  display: inline-block;
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  margin-right: 6px;
  vertical-align: middle;

  content: "";
  background-image: url(${unckecked});
  background-size: contain;
  background-position: center;
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;

  &.activated {
    &::after {
      position: absolute;
      content: "";
      background-image: url(${checked});
      background-size: contain;
      background-position: center;
      width: 12px;
      height: 12px;
      top: 0;
      left: 0;
      background-repeat: no-repeat;
    }
  }
`;
const MovingAverageGraphSwitchButtonWord = styled.span`
  display: inline-block;
  font-size: 10px;
`;

const ThisTimeGraphSwitchButton = styled.button`
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  width: 62px;
  height: 30px;
  border-radius: 4px;
  margin-right: 10px;
  padding: 2px 12px 5px;
`;
const ThisTimeGraphSwitchButtonCheckBox = styled.span`
  display: inline-block;
  position: relative;
  width: 12px;
  height: 12px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  margin-right: 6px;
  vertical-align: middle;
  &.activated {
    &::after {
      position: absolute;
      content: "";
      background-image: url(${graphActivatedIcon});
      background-size: contain;
      background-position: center;
      width: 12px;
      height: 12px;
    }
  }
`;
const ThisTimeGraphSwitchButtonWord = styled.span`
  display: inline-block;
  font-size: 10px;
`;
