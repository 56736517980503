import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { BarChart, Bar, CartesianGrid, Tooltip, XAxis, YAxis, ResponsiveContainer } from "recharts"
import { useLocation } from "react-router-dom"
import styled from "styled-components"


const FunnelDetailBreakdownBarChart = (props: any) => {

  const raw_data_array = useSelector((state: any) => state.raw_data.raw_data_array)
  const target_funnel_name = props.target_funnel_name;


  const dateArray = raw_data_array.map((result: any) => {
    return { date: new Date(result.result_at.seconds * 1000).toLocaleDateString("ja-JP") }
  })

  const funnel = raw_data_array.map((result: any) => result && result.lead_new_count ? result.lead_new_count : [])
  console.log('funnelfunnelfunnel', funnel)

  const funnelSummary = funnel
    .flatMap((fn: any) => fn !== undefined && fn)
    .filter((fnData: any) => fnData.funnel_name === target_funnel_name)
    .map((fnDataData: any) => fnDataData.lead_new_count_sources)
  console.log('funnelSummary', funnelSummary);

  //日毎の名前だけのデータ抽出
  const NameArrays = funnelSummary && funnelSummary.length > 0 ? funnelSummary.map((funnelDatas: any) => funnelDatas.map((data: any) => data.lead_source_name)): [];
  console.log("NameArrays:", NameArrays)

  //日毎の実績だけのデータ抽出
  const TotalArrays = funnelSummary && funnelSummary.length > 0 ? funnelSummary.map((funnelDatas: any) => funnelDatas.map((data: any) => data.lead_new_count_total)): [];
  console.log("TotalArrays:", TotalArrays)

  const mergedArray = NameArrays.map((NameArray: any, m: any) =>
    NameArray.map((name: any, n: any) => {
      return { [name]: TotalArrays[m][n] }
    })
  )
  // console.log('mergedArray', mergedArray)

  const spreadArray = mergedArray.map((arrayData: any) =>
    arrayData.reduce(function (result: any, currentObject: any) {
      for (let key in currentObject) {
        if (currentObject.hasOwnProperty(key)) {
          result[key] = currentObject[key]
        }
      }
      return result
    }, {})
  )

  const graphData = dateArray.map((data:any, index:number) => {
    return {
      ...data, ...spreadArray[index]
    }
  })
  console.log('graphData', graphData)

  // console.log('spreadArray',spreadArray)

  const finalArray = mergedArray.flatMap((ma: any) => ma)
  // console.log('finalArray', finalArray)

  //ページが指定しているファネルのデータのみを1日目のデータから抽出
  const funnelList = 
    raw_data_array 
    && 
    raw_data_array.length > 0
    &&
    raw_data_array[0].lead_new_count ? 
    raw_data_array[0].lead_new_count.filter((user: any) => user.funnel_name === target_funnel_name) 
    : []
  // console.log('funnelList:',funnelList)

  //lead_sourcesをそれぞれオブジェクトとして展開
  const leadSources = funnelList.flatMap((leadSource: any) => leadSource.lead_new_count_sources)
  // console.log('leadSources:',leadSources)

  //各リードソースのnameのみを配列として取得
  const nameArray = leadSources.map((leadSource: any) => {
    return `${leadSource.lead_source_name}`
  })

  const COLORS = ["#109CBB", "#9E00FF", "#B7D20D", "#E53E66"]

  // Tooltipのカスタムスタイリング
  const customWrapperStyle = {
    background: "white",
    width: "178px",
    height: "92px",
    borderRadius: "2px",
    boxShadow: "0 4px 5px 0 rgba(0, 0, 0, .25)",
    fontSize: "12px",
  };

  const customTooltip = (props:any) => {
    //グラフのpayloadを取得
    const parentArray = props.payload;
    // console.log("parentArray:", parentArray)
    // console.log('parentArray',parentArray)

    if (parentArray != null) {

      //取得したpayload(親)の中のpayload(子)を取得
      const childPayload = parentArray.flatMap((childPayload: any) => childPayload.payload);

      //取得した値から[0]番目のみ取得
      const tooltipPayload = childPayload[0];
      // console.log('tooltipPayload',tooltipPayload)
      if (tooltipPayload != null) {
        return (
          <TooltipContent>
            <TooltipDaily>{tooltipPayload.date}</TooltipDaily>
            <DataContainer>
              {
                parentArray.map((payload:any) => {
                  return(
                    <TooltipData>
                      <TooltipLabel>{payload.name}</TooltipLabel>
                      <TooltipVal>{payload.value.toLocaleString()}</TooltipVal>
                    </TooltipData>
                  )
                })
              }
            </DataContainer>
          </TooltipContent>
        )
      }
    return;
    }
    return false;
  }

  return (
    <>
    {
      spreadArray.length > 0 ? (
        <>
          <ResponsiveContainer height={292}>
            <BarChart
              width={968}
              data={graphData}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              ref={props.breakDownBarAreaRef}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis tick={{ fontSize: 10 }} dataKey="date" />
              <YAxis tick={{ fontSize: 10 }} tickCount={10} interval={0} tickMargin={10} tickLine={false} axisLine={false} tickFormatter={tick => tick.toLocaleString()}/>
              <Tooltip content={customTooltip} cursor={{fill: "#0066FF", fillOpacity:0.05}} wrapperStyle={{pointerEvents: 'auto'}}/>
              {nameArray.map((name: string, index: number) => {
                return <Bar 
                        dataKey={name} 
                        stackId="a" 
                        fill={COLORS[index % COLORS.length]} 
                        key={index} 
                      />
              })}
            </BarChart>
          </ResponsiveContainer>
          {/* <GraphXAxis>
            <GraphXAxisList>
              {raw_data_array.flatMap((rd: any) => (
                <GraphXAxisItem>{`${rd.year}.${rd.month}`}</GraphXAxisItem>
              ))}
            </GraphXAxisList>
          </GraphXAxis> */}
        </>
      ):(
        <NoData>グラフを表示できません</NoData>
      )
    }
    </>
  )
}
export default FunnelDetailBreakdownBarChart


// Tooltip
const TooltipContent = styled.div`
  width: 200px;
  padding: 7px 9px 14px 9px;
  border-radius: 2px;
  box-sizing: border-box;
  background-color: #fff;
`;
const TooltipDaily = styled.p`
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  color: #435878;
  margin-bottom: 12px;
`;

const DataContainer = styled.div`
  max-height: 300px;
  overflow-y: scroll;
  padding: 0 16px;
`

const TooltipData = styled.dl`
  display: flex;
  margin-bottom: 12px;
  box-sizing: border-box;
  &:last-child {
    margin-bottom: 0;
  }
`;
const TooltipLabel = styled.td`
  font-size: 10px;
  font-weight: normal;
  color: #435878;
  width: 80px;
`;
const TooltipVal = styled.td`
  font-size: 12px;
  color: #435878;
  text-align: right;
  width: calc(100% - 80px);
`

const NoData = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #435878;
  margin-bottom: 8px;
  &.main {
    margin-bottom: 8px;
  }
`

// GraphXAxis
const GraphXAxis = styled.div`
  position: relative;
  top: -20px;
  width: 100%;
`

const GraphXAxisList = styled.ul`
  display: flex;
  justify-content: space-around;
  width: calc(100% - 76px);
  margin-right: 0;
  margin-left: auto;
  padding-right: 24px;
`

const GraphXAxisItem = styled.li`
  color: #7591bc;
  font-size: 10px;
  position: relative;
  display: block;
  text-align: center;
  padding-bottom: 15px;
  &:hover {
    button {
      display: block;
    }
  }
`