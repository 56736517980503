import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import loadingIcon from "../images/icons/loading.gif";

const LoadingCoverBg = styled.div`
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  z-index: 999999999;
`;

const LoadingIcon = styled.img`
  display: block;
  width: 100px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
`;

function LoadingCover() {
  const isLoading = useSelector((state: any) => state.isLoading);
  return (
    <>
      {isLoading && (
        <LoadingCoverBg>
          <LoadingIcon src={loadingIcon} alt="Loading..." />
        </LoadingCoverBg>
      )}
    </>
  );
}

export default LoadingCover;
