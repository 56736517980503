import rsf from "../../rsf"
import firebase from "firebase"
import { call } from "redux-saga/effects"

const db = firebase.firestore()

function* getResultMonthly(company_id, daily_from_date, daily_to_date) {
  try {
    // const snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${company_id}/result_daily`).where("created_at", ">=", new Date(daily_from_date)).where("created_at", "<=", new Date(daily_to_date)))
    console.log("daily_from_date", daily_from_date)
    console.log("daily_to_date", daily_to_date)
    const snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${company_id}/result_monthly`).where("result_at", ">=", daily_from_date).where("result_at", "<=", daily_to_date))
    // const snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${company_id}/result_daily`))

    // const snapshot = yield call(rsf.firestore.getCollection, `companies/${company_id}/result_daily`)
    let result_monthly_list = []

    snapshot.forEach((result_monthly) => {
      result_monthly_list = [...result_monthly_list, { ...result_monthly.data(), docId: result_monthly.id }]
    })
    console.log("result_monthly_list", result_monthly_list)
    return result_monthly_list
  } catch (e) {
    console.log("error message", e.message)
  }
}

export default getResultMonthly;