import { SHOW_LOADING, HIDE_LOADING } from "./types"
import {RESET_ALL} from "../common/types";

const initialState: boolean = false;

const loadingReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case RESET_ALL:
      return initialState;

    case SHOW_LOADING:
      return true

    case HIDE_LOADING:
      return false

    default:
      return state
  }
}

export default loadingReducer
