import { TOGGLE_MODAL_DISPLAY } from "./types";
import {RESET_ALL} from "../common/types";

const initialState = {
  open: false
};

const landingPageModalReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_ALL:
      return initialState;

    case TOGGLE_MODAL_DISPLAY:
      return {
        open: action.display
      }

    default:
      return state;
  }
};

export default landingPageModalReducer;
