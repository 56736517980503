const LOGIN_REQUESTED = "LOGIN_REQUESTED";
const LOGIN_SUCCEEDED = "LOGIN_SUCCEEDED";
const LOGIN_FAILED = "LOGIN_FAILED";
const LOGIN_FAILED_RESET = "LOGIN_FAILED_RESET";
const LOGOUT_REQUESTED = "LOGOUT_REQUESTED";
const LOGOUT_SUCCEEDED = "LOGOUT_SUCCEEDED";
const LOGOUT_FAILED = "LOGOUT_FAILED";
const SET_PAGE_PATH = "SET_PAGE_PATH";
const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
const TOGGLE_MOVING_AVERAGE_GRAPH_SWITCH = "TOGGLE_MOVING_AVERAGE_GRAPH_SWITCH";
const TOGGLE_THIS_TIME_GRAPH_SWITCH = "TOGGLE_THIS_TIME_GRAPH_SWITCH";
const URL_TO_DATA_NAME_ACTION = "URL_TO_DATA_NAME_ACTION";
const TOGGLE_EXPANDER = "TOGGLE_EXPANDER";
const TOGGLE_TOTAL_SUMMARY = "TOGGLE_TOTAL_SUMMARY";
const TOGGLE_MOVING_AVERAGE_SUMMARY = "TOGGLE_MOVING_AVERAGE_SUMMARY";
const TOGGLE_TOTAL_DETAIL = "TOGGLE_TOTAL_DETAIL";
const TOGGLE_MOVING_AVERAGE_DETAIL = "TOGGLE_MOVING_AVERAGE_DETAIL";

const TOGGLE_FORECAST_REVENUE = "TOGGLE_FORECAST_REVENUE";
const TOGGLE_FORECAST_REVENUE_DETAIL = "TOGGLE_FORECAST_REVENUE_DETAIL";
const TOGGLE_FORECAST_UNIT_COST = "TOGGLE_FORECAST_UNIT_COST";
const TOGGLE_FORECAST_UNIT_COST_DETAIL = "TOGGLE_FORECAST_UNIT_COST_DETAIL";
const TOGGLE_FORECAST_GROSS_PROFIT = "TOGGLE_FORECAST_GROSS_PROFIT";
const TOGGLE_FORECAST_SVG_COST = "TOGGLE_FORECAST_SVG_COST";
const TOGGLE_FORECAST_SVG_COST_DETAIL = "TOGGLE_FORECAST_SVG_COST_DETAIL";
const TOGGLE_FORECAST_OPERATING_PROFIT = "TOGGLE_FORECAST_OPERATING_PROFIT";

const TOGGLE_FORECAST_FUNNEL_TABLE = "TOGGLE_FORECAST_FUNNEL_TABLE";
const SET_DISPLAYED_FORECAST_FUNNEL_RAWS = "SET_DISPLAYED_FORECAST_FUNNEL_RAWS";

const SET_WIDTH = "SET_WIDTH";
const TOGGLE_PERIODIC_AVERAGE_GRAPH = "TOGGLE_PERIODIC_AVERAGE_GRAPH";

export {
  LOGIN_REQUESTED,
  LOGIN_FAILED_RESET,
  LOGIN_SUCCEEDED,
  LOGIN_FAILED,
  LOGOUT_REQUESTED,
  LOGOUT_SUCCEEDED,
  LOGOUT_FAILED,
  SET_PAGE_PATH,
  SET_ACCESS_TOKEN,
  TOGGLE_MOVING_AVERAGE_GRAPH_SWITCH,
  TOGGLE_THIS_TIME_GRAPH_SWITCH,
  URL_TO_DATA_NAME_ACTION,
  TOGGLE_EXPANDER,
  TOGGLE_TOTAL_SUMMARY,
  TOGGLE_MOVING_AVERAGE_SUMMARY,
  TOGGLE_TOTAL_DETAIL,
  TOGGLE_MOVING_AVERAGE_DETAIL,
  TOGGLE_FORECAST_REVENUE,
  TOGGLE_FORECAST_UNIT_COST,
  TOGGLE_FORECAST_GROSS_PROFIT,
  TOGGLE_FORECAST_SVG_COST,
  TOGGLE_FORECAST_OPERATING_PROFIT,
  TOGGLE_FORECAST_REVENUE_DETAIL,
  TOGGLE_FORECAST_UNIT_COST_DETAIL,
  TOGGLE_FORECAST_SVG_COST_DETAIL,
  TOGGLE_FORECAST_FUNNEL_TABLE,
  SET_DISPLAYED_FORECAST_FUNNEL_RAWS,
  SET_WIDTH,
  TOGGLE_PERIODIC_AVERAGE_GRAPH
};
