import React from 'react'
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
  button: {
    padding: '12px 5px',
  },
  menu: {
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  menuItem: {
    fontSize: '12px',
    lineHeight: '18px',
  },
});

const MemberMenu = styled.div`
  position: absolute;
  top: 24px;
  right: 20px;
  transform: translateY(-50%);
`;

const MenuButton = (props:any) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

return(
  <MemberMenu>
    <IconButton 
      className={classes.button} 
      aria-label="more" aria-controls="long-menu" aria-haspopup="true"
      onClick={handleClick}
    >
      <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="2" cy="2" r="2" fill="#789097"/>
        <circle cx="8.6665" cy="2" r="2" fill="#789097"/>
        <circle cx="15.3335" cy="2" r="2" fill="#789097"/>
      </svg>
    </IconButton>

    <Menu 
      className={classes.menu} 
      id="long-menu" anchorEl={anchorEl} 
      keepMounted open={open} 
      onClose={handleClose}
      PaperProps={{
        style: {
          width: '224px'
        },
      }}
    >
      <MenuItem className={classes.menuItem} >{props.value}</MenuItem>
    </Menu>
  </MemberMenu>
)}

export default MenuButton