import rsf from "../../rsf"
import { call, put } from "redux-saga/effects"

// Firestoreからユーザー情報を取得
function* fetchUserFromFirestore(uid) {
  try {
    const snapshot = yield call(rsf.firestore.getDocument, `users/${uid}`)
    const user = snapshot.data()
    return user
  } catch (e) {
    yield put({ type: "USER_FETCH_FAILED", message: e.message })
  }
}

export default fetchUserFromFirestore;