import React from "react";
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { useLocation } from "react-router-dom";

//ConfigNav
const ConfigNav = styled.div`
  width: 240px;
`;
//ConfigNavList
const ConfigNavList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;
//ConfigNavItem
const ConfigNavItem = styled.li`
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  a {
    color: #0066FF;
    text-decoration: none;
    padding: 8px 8px 10px;
    box-sizing: border-box;
    display: block;
    &:hover, &.current {
      font-weight: bold;
      background-color: #fff;
    }
  }
`;

const ConfigNavCard = () => {
  const location = useLocation();
  const pathName = location.pathname;
  const menus = [
    {
      label: '指標設定',
      path: '/config/indicator-setting',
    },
    {
      label: 'メンバー管理',
      path: '/config/member-setting',
    }
    // {
    //   label: '請求管理',
    //   path: '/config/billing-setting'
    // },
    // {
    //   label: 'プラン変更',
    //   path: '/config/plan-setting'
    // }
  ];
  const listItems = menus.map((menu) =>
    <ConfigNavItem>
      <Link className={menu.path === pathName ? 'current' : ''} to={menu.path}>{menu.label}</Link>
    </ConfigNavItem>
  );

  return (
    <ConfigNav>
      <ConfigNavList>
        {listItems}
      </ConfigNavList>
    </ConfigNav>
  );
};

export default ConfigNavCard;
