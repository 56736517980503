const SET_TARGET_FORECAST_REVENUE_PROFIT_ARRAY = "SET_TARGET_FORECAST_REVENUE_PROFIT_ARRAY";
const SET_TARGET_FORECAST_FUNNEL_ARRAY = "SET_TARGET_FORECAST_FUNNEL_ARRAY";
const SET_TARGET_FORECAST_METRICS_ARRAY = "SET_TARGET_FORECAST_METRICS_ARRAY";

const GET_TARGET_FORECAST_REVENUE_PROFIT_DETAIL_REQUEST = "GET_TARGET_FORECAST_REVENUE_PROFIT_DETAIL_REQUEST";
const GET_TARGET_FORECAST_REVENUE_PROFIT_DETAIL_SUCCEEDED = "GET_TARGET_FORECAST_REVENUE_PROFIT_DETAIL_SUCCEEDED";
const GET_TARGET_FORECAST_REVENUE_PROFIT_DETAIL_FAILED = "GET_TARGET_FORECAST_REVENUE_PROFIT_DETAIL_FAILED";

const UPDATE_FORECAST_PL_DATA = "UPDATE_FORECAST_PL_DATA";
const UPDATE_FORECAST_METRICS_DATA = "UPDATE_FORECAST_METRICS_DATA";
const UPDATE_FORECAST_FUNNEL_DATA = "UPDATE_FORECAST_FUNNEL_DATA";

const UPDATE_REVENUE_DETAIL_DATA = "UPDATE_REVENUE_DETAIL_DATA"
const UPDATE_REVENUE_TOTAL_DATA = "UPDATE_REVENUE_TOTAL_DATA"
const UPDATE_REVENUE_TOTAL_FORECAST_DATA = "UPDATE_REVENUE_TOTAL_FORECAST_DATA"
const UPDATE_UNIT_COST_CAT_2_DETAIL_DATA = "UPDATE_UNIT_COST_CAT_2_DETAIL_DATA"
const UPDATE_UNIT_COST_CAT_3_DETAIL_DATA = "UPDATE_UNIT_COST_CAT_3_DETAIL_DATA"
const UPDATE_UNIT_COST_TOTAL_DATA = "UPDATE_UNIT_COST_TOTAL_DATA"
const UPDATE_UNIT_COST_CAT_2_TOTAL_DATA = "UPDATE_UNIT_COST_CAT_2_TOTAL_DATA"
const UPDATE_SVG_COST_CAT_2_DETAIL_DATA = "UPDATE_SVG_COST_CAT_2_DETAIL_DATA"
const UPDATE_SVG_COST_CAT_3_DETAIL_DATA = "UPDATE_SVG_COST_CAT_3_DETAIL_DATA"
const UPDATE_SVG_COST_TOTAL_DATA = "UPDATE_SVG_COST_TOTAL_DATA"
const UPDATE_SVG_COST_CAT_2_TOTAL_DATA = "UPDATE_SVG_COST_CAT_2_TOTAL_DATA"
const UPDATE_PL_GROSS_PROFIT_TOTAL_FORECASTS = 'UPDATE_PL_GROSS_PROFIT_TOTAL_FORECASTS'
const UPDATE_PL_OPERATING_PROFIT_TOTAL_FORECASTS = 'UPDATE_PL_OPERATING_PROFIT_TOTAL_FORECASTS'
const UPDATE_PL_GROSS_PROFIT_TOTAL = 'UPDATE_PL_GROSS_PROFIT_TOTAL'
const UPDATE_PL_OPERATING_PROFIT_TOTAL = 'UPDATE_PL_OPERATING_PROFIT_TOTAL'


// newForecast
const GET_REVENUE_DETAIL_REQUEST = 'GET_REVENUE_DETAIL_REQUEST';
const GET_REVENUE_DETAIL_FAILED = 'GET_REVENUE_DETAIL_FAILED';
const GET_REVENUE_DETAIL_SUCCEEDED = 'GET_REVENUE_DETAIL_SUCCEEDED';

const GET_RESULTS_FOR_TARGET_FORECAST_REQUEST = 'GET_RESULTS_FOR_TARGET_FORECAST_REQUEST';
const GET_RESULTS_FOR_TARGET_FORECAST_SUCCEEDED = 'GET_RESULTS_FOR_TARGET_FORECAST_SUCCEEDED';
const GET_RESULTS_FOR_TARGET_FORECAST_FAILED = 'GET_RESULTS_FOR_TARGET_FORECAST_FAILED';

const UPDATE_NEW_FORECAST_SIMULATED_RESULT = 'UPDATE_NEW_FORECAST_SIMULATED_RESULT';

const SET_FORECAST_FILTER_STATUS = 'SET_FORECAST_FILTER_STATUS';

const SET_FORECAST_FILTER = 'SET_FORECAST_FILTER';
const RESET_FORECAST_FILTER = 'RESET_FORECAST_FILTER';
const GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_FORECASTS = 'GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_FORECASTS';
const GET_RESULTS_FOR_SPAN_COMPARISON_FORECASTS_SUCCEEDED = 'GET_RESULTS_FOR_SPAN_COMPARISON_FORECASTS_SUCCEEDED';
const GET_RESULTS_FOR_SPAN_COMPARISON_FORECASTS_FAILED = 'GET_RESULTS_FOR_SPAN_COMPARISON_FORECASTS_FAILED';

const GET_RESULTS_FOR_TARGET_FORECAST_CUSTOM_REQUEST = 'GET_RESULTS_FOR_TARGET_FORECAST_CUSTOM_REQUEST';

const SET_FORECAST_CUSTOM_SPAN = 'SET_FORECAST_CUSTOM_SPAN';

const GET_INTEGRATED_ARRAY_FORECAST_REQUEST = 'GET_INTEGRATED_ARRAY_FORECAST_REQUEST';
const GET_INTEGRATED_ARRAY_FORECAST_SUCCEEDED = 'GET_INTEGRATED_ARRAY_FORECAST_SUCCEEDED';
const GET_INTEGRATED_ARRAY_FORECAST_FAILED = 'GET_INTEGRATED_ARRAY_FORECAST_FAILED';

const SET_PL_GROSS_PROFIT_OBJECTIVE = 'SET_PL_GROSS_PROFIT_OBJECTIVE'
const SET_PL_OPERATING_PROFIT_OBJECTIVE = 'SET_PL_OPERATING_PROFIT_OBJECTIVE'

const REMOVE_FUTURE_DATA_FROM_INTEGRATED_DATA = 'REMOVE_FUTURE_DATA_FROM_INTEGRATED_DATA'

const HANDLE_UPDATE_REVENUE_DETAIL_TOTAL = 'HANDLE_UPDATE_REVENUE_DETAIL_TOTAL'
const HANDLE_UPDATE_COST_CAT_2 = 'HANDLE_UPDATE_COST_CAT_2'
const HANDLE_UPDATE_COST_CAT_3 = 'HANDLE_UPDATE_COST_CAT_3'



export { 
  SET_TARGET_FORECAST_REVENUE_PROFIT_ARRAY,
  SET_TARGET_FORECAST_FUNNEL_ARRAY,
  SET_TARGET_FORECAST_METRICS_ARRAY,
  GET_TARGET_FORECAST_REVENUE_PROFIT_DETAIL_REQUEST,
  GET_TARGET_FORECAST_REVENUE_PROFIT_DETAIL_SUCCEEDED,
  GET_TARGET_FORECAST_REVENUE_PROFIT_DETAIL_FAILED,
  UPDATE_FORECAST_PL_DATA,
  UPDATE_FORECAST_METRICS_DATA,
  UPDATE_FORECAST_FUNNEL_DATA,
  GET_REVENUE_DETAIL_REQUEST,
  GET_REVENUE_DETAIL_SUCCEEDED,
  GET_REVENUE_DETAIL_FAILED,
  GET_RESULTS_FOR_TARGET_FORECAST_REQUEST,
  GET_RESULTS_FOR_TARGET_FORECAST_SUCCEEDED,
  GET_RESULTS_FOR_TARGET_FORECAST_FAILED,
  UPDATE_NEW_FORECAST_SIMULATED_RESULT,
  SET_FORECAST_FILTER_STATUS,
  SET_FORECAST_FILTER,
  RESET_FORECAST_FILTER,
  GET_RESULTS_FOR_SPAN_COMPARISON_FORECASTS_SUCCEEDED,
  GET_RESULTS_FOR_SPAN_COMPARISON_FORECASTS_FAILED,
  GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_FORECASTS,
  GET_RESULTS_FOR_TARGET_FORECAST_CUSTOM_REQUEST,
  SET_FORECAST_CUSTOM_SPAN,
  GET_INTEGRATED_ARRAY_FORECAST_REQUEST,
  GET_INTEGRATED_ARRAY_FORECAST_SUCCEEDED,
  GET_INTEGRATED_ARRAY_FORECAST_FAILED,
  SET_PL_GROSS_PROFIT_OBJECTIVE,
  SET_PL_OPERATING_PROFIT_OBJECTIVE,
  UPDATE_REVENUE_DETAIL_DATA,
  UPDATE_UNIT_COST_CAT_2_DETAIL_DATA,
  UPDATE_SVG_COST_CAT_2_DETAIL_DATA,
  UPDATE_UNIT_COST_CAT_3_DETAIL_DATA,
  UPDATE_SVG_COST_CAT_3_DETAIL_DATA,
  UPDATE_REVENUE_TOTAL_DATA,
  UPDATE_UNIT_COST_TOTAL_DATA,
  UPDATE_SVG_COST_TOTAL_DATA,
  UPDATE_UNIT_COST_CAT_2_TOTAL_DATA,
  UPDATE_SVG_COST_CAT_2_TOTAL_DATA,
  UPDATE_PL_GROSS_PROFIT_TOTAL_FORECASTS,
  UPDATE_PL_OPERATING_PROFIT_TOTAL_FORECASTS,
  UPDATE_PL_GROSS_PROFIT_TOTAL,
  UPDATE_PL_OPERATING_PROFIT_TOTAL,
  REMOVE_FUTURE_DATA_FROM_INTEGRATED_DATA,
  UPDATE_REVENUE_TOTAL_FORECAST_DATA,
  HANDLE_UPDATE_REVENUE_DETAIL_TOTAL,
  HANDLE_UPDATE_COST_CAT_2,
  HANDLE_UPDATE_COST_CAT_3,
};
