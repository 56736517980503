import React, { useCallback } from "react";
import FileSaver from "file-saver";
import {
  updateDownloadGraphStatus,
} from "../../../reducks/download/actions";
import {
  updateDownloadCsvTarget,
} from "../../../reducks/download/actions";
import { useCurrentPng } from "recharts-to-png";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import { yAxisFormatter } from "../../Util/yAxisFormatter";
import { unitSwitcher } from "../../Util/unitSwitcher";
import GraphDataDownload from "../../Parts/GraphDataDownload";
interface Props {
  funnel_summary_array: any;
  resultSpan: string;
  moving_average_graph_switch: any;
}

const CustomTooltipBar = ({ active, payload, label }: any) => {
  console.log("payload", payload);
  if (active && payload && payload.length) {
    return (
      <BarGraphTooltip>
        <BarGraphTooltipHeading>
          {payload[0].payload.result_at}
        </BarGraphTooltipHeading>

        {payload.map((payload_item: any) => (
          <BarGraphTooltipContent key={payload_item.dataKey}>
            <BarGraphTooltipLabel bgcolor={payload_item.fill}>
              {payload_item.dataKey}
            </BarGraphTooltipLabel>
            <BarGraphTooltipData>
              {`${unitSwitcher(payload_item.value, "lead_count")}`}
            </BarGraphTooltipData>
            <BarGraphTooltipHR />
          </BarGraphTooltipContent>
        ))}
      </BarGraphTooltip>
    );
  }

  return null;
};

export interface TransitionRateLeadTime {
  funnel_name: string;
  lead_transition_rate_total: number;
  lead_transition_rate_objective: number;
  lead_transition_rate_rate: number;
  lead_transition_rate_difference: number;
  lead_transition_rate_last_year_comparison_rate: number;
  lead_time_total: number;
}
export interface ChannelName {
  channel_id: number;
  channel_name: string;
  deactivated: boolean;
  channel_color: string;
}

export interface FunnelSummaryGraphTransitionRateLeadTimeProps {
  transition_rate_lead_time_array: TransitionRateLeadTime[];
  lead_new_count_lead_with_time: any;
  lead_new_count_appointment_with_time: any;
  lead_new_count_opportunity_with_time: any;
  lead_new_count_contract_with_time: any;
  channel_name_array: ChannelName[];
}
const FunnelSummaryGraphTransitionRateLeadTime: React.FC<FunnelSummaryGraphTransitionRateLeadTimeProps> =
  ({
    transition_rate_lead_time_array,
    lead_new_count_lead_with_time,
    lead_new_count_appointment_with_time,
    lead_new_count_opportunity_with_time,
    lead_new_count_contract_with_time,
    channel_name_array
  }) => {
    const dispatch = useDispatch();
    const [targetFunnel, setTargetFunnel] = React.useState("funnel_lead");

    let targetGraphData: any = [];
    switch (targetFunnel) {
      case "funnel_lead":
        targetGraphData = lead_new_count_lead_with_time;
        break;
      case "funnel_appointment":
        targetGraphData = lead_new_count_appointment_with_time;
        break;
      case "funnel_opportunity":
        targetGraphData = lead_new_count_opportunity_with_time;
        break;
      case "funnel_contract":
        targetGraphData = lead_new_count_contract_with_time;
        break;
      default:
        targetGraphData = lead_new_count_lead_with_time;
    }

    // Graph Download Section
    const [getAreaPng, { ref: areaRef }] = useCurrentPng();
    const handleAreaDownload = useCallback(async () => {
      dispatch(
        updateDownloadGraphStatus(0)
      )
      const png = await getAreaPng();
      if (png) {
        FileSaver.saveAs(png, "graph.png");
      }
    }, [getAreaPng, dispatch]);
    function handleGraphDataDownloadClick(e:any, target:string) {
      if(e.target.innerText==='グラフ Export'){
          handleAreaDownload();
      }
      dispatch(
        updateDownloadCsvTarget("/"+target)
      )
    }

    return (
      <div>
        <FunnelSummaryGraphTransitionRateLeadTimeHeadingGraphDownloadWrapper>
          <FunnelSummaryGraphTransitionRateLeadTimeHeading>
            Channel - ファネルごとの推移
          </FunnelSummaryGraphTransitionRateLeadTimeHeading>
          <div onClick={(e) => {handleGraphDataDownloadClick(e, 'transition_funnel/'+targetFunnel)}}>
            <GraphDataDownload />
          </div>
        </FunnelSummaryGraphTransitionRateLeadTimeHeadingGraphDownloadWrapper>
        {targetGraphData.length > 0 ? (
          <FunnelSummaryGraph>
            <TargetFunnelSwitchList>
              <TargetFunnelSwitchItem>
                <TargetFunnelSwitchButton
                  className={targetFunnel === "funnel_lead" ? "active" : ""}
                  onClick={() => {
                    setTargetFunnel("funnel_lead");
                  }}
                >
                  リード獲得
                </TargetFunnelSwitchButton>
              </TargetFunnelSwitchItem>
              <TargetFunnelSwitchItem>
                <TargetFunnelSwitchButton
                  className={
                    targetFunnel === "funnel_appointment" ? "active" : ""
                  }
                  onClick={() => {
                    setTargetFunnel("funnel_appointment");
                  }}
                >
                  アポ獲得
                </TargetFunnelSwitchButton>
              </TargetFunnelSwitchItem>
              <TargetFunnelSwitchItem>
                <TargetFunnelSwitchButton
                  className={
                    targetFunnel === "funnel_opportunity" ? "active" : ""
                  }
                  onClick={() => {
                    setTargetFunnel("funnel_opportunity");
                  }}
                >
                  商談化
                </TargetFunnelSwitchButton>
              </TargetFunnelSwitchItem>
              <TargetFunnelSwitchItem>
                <TargetFunnelSwitchButton
                  className={targetFunnel === "funnel_contract" ? "active" : ""}
                  onClick={() => {
                    setTargetFunnel("funnel_contract");
                  }}
                >
                  受注
                </TargetFunnelSwitchButton>
              </TargetFunnelSwitchItem>
            </TargetFunnelSwitchList>

            <ResponsiveContainer>
              <BarChart style={{padding: "5px"}} width={730} height={250} data={targetGraphData} ref={areaRef}>
                <XAxis
                  dataKey="result_at"
                  tickLine={false}
                  tickSize={10}
                  minTickGap={20}
                  tick={{ fontSize: 10 }}
                />

                <YAxis
                  type="number"
                  tickCount={10}
                  interval={0}
                  tickMargin={10}
                  tickLine={false}
                  yAxisId={0}
                  // tickFormatter={tick => yAxisFormatter(tick, "lead_count")}
                />
                <CartesianGrid stroke="#f5f5f5" />

                {channel_name_array.map((channel_name, index) => (
                  <Bar
                    id={channel_name.channel_id.toString()}
                    name={channel_name.channel_name}
                    dataKey={channel_name.channel_name}
                    fill={channel_name.channel_color}
                    stackId="channel_bar"
                  />
                ))}
                <Tooltip
                  content={<CustomTooltipBar />}
                  cursor={{ fill: "transparent" }}
                />
              </BarChart>
            </ResponsiveContainer>
          </FunnelSummaryGraph>
        ) : (
          <NoData>グラフを表示できません</NoData>
        )}
      </div>
    );
  };
export default FunnelSummaryGraphTransitionRateLeadTime;

// Graph
const FunnelSummaryGraph = styled.div`
  width: 100%;
  height: 400px;
  padding: 0 10px 40px;
  box-sizing: border-box;

  .recharts-surface {
    position: relative;
    top: -1px;
    overflow: visible;
  }
  .recharts-cartesian-axis-tick {
    width: 100px !important;
    .recharts-cartesian-axis-tick-value {
      font-size: 10px;
      color: #435878;
    }
  }
  .recharts-legend-wrapper {
    width: 100% !important;
    height: 60px !important;
    overflow-y: scroll;
  }
`;
const NoData = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #435878;
  margin-bottom: 8px;
  &.main {
    margin-bottom: 8px;
  }
`;

const BarGraphTooltip = styled.div`
  background: #fff;
  padding: 8px 9px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid #e2e9f3;
  border-radius: 4px;
  max-width: 200px;
`;

// 日付
const BarGraphTooltipHeading = styled.h3`
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  color: #435878;
  margin-bottom: 5px;
`;

const BarGraphTooltipContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

// 当月 | 目標
const BarGraphTooltipLabel = styled.p<{ bgcolor: string }>`
  position: relative;
  width: 50%;
  font-size: 10px;
  color: #435878;
  left: 10px;
  margin-bottom: 3px;
  &::before {
    position: absolute;
    top: 3px;
    left: -8px;
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: ${props => props.bgcolor};
  }
`;
// データ
const BarGraphTooltipData = styled.p`
  width: 50%;
  text-align: right;
  font-size: 10px;
  font-weight: bold;
  color: #435878;
  margin-bottom: 3px;
`;
// 仕切り線
const BarGraphTooltipHR = styled.hr`
  width: 100%;
  height: 1px;
  color: lightgray;
  margin-bottom: 3px;
`;

const FunnelSummaryGraphTransitionRateLeadTimeHeading = styled.h2`
  color: #283f46;
  font-weight: bold;
  font-size: 22px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const TargetFunnelSwitchList = styled.ul`
  display: flex;
  margin-bottom: 20px;
`;

const TargetFunnelSwitchButton = styled.button`
  color: #6b6d82;
  padding: 6px 0;
  font-size: 12px;
  display: block;
  width: 100%;
  &.active {
    background-color: #0066ff;
    color: #fff;
    font-weight: 600;
  }
`;

const TargetFunnelSwitchItem = styled.li`
  border: 1px solid #edf0f1;
  border-right: none;
  text-align: center;
  width: 92px;

  &:first-of-type {
    border-radius: 4px 0 0 4px;
    ${TargetFunnelSwitchButton} {
      border-radius: 4px 0 0 4px;
    }
  }
  &:last-of-type {
    border-right: 1px solid #edf0f1;
    border-radius: 0 4px 4px 0;
    ${TargetFunnelSwitchButton} {
      border-radius: 0 4px 4px 0;
    }
  }
`;

const FunnelSummaryGraphTransitionRateLeadTimeHeadingGraphDownloadWrapper = styled.div({
  flexGrow: 1,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});
