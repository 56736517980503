import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, Cell, ZAxis, ResponsiveContainer } from "recharts"
// import { ResultDaily } from "./database"
import { useLocation } from "react-router-dom"
import styled from "styled-components"
import { toggleAchivementCostSelectedNameList, achivementCostSelectedNameListShowAll } from "../../../reducks/funnels/actions"
import { randomColorGenerator } from "../../Util/randomColorGenerator"
import { ContactSupportOutlined } from "@material-ui/icons"

const COLORS = ["#0066FF", "#40AF9B", "#EFBE40", "#EC6E8C"]
const init_colors = ["#E53E66", "#EAA800", "#B7D20D", "#0066FF","#009479","#109CBB","#9E00FF"]

const FunnelDetailDistributionMapAchievementCost = (props: any) => {
  const target_funnel_name = props.target_funnel_name;
  const resultSpan = useSelector((state: any) => state.target_data.resultSpan)

  // =======================================
  // データを取得してresultListに入れるここから

  const raw_data_array = useSelector((state: any) => state.raw_data.raw_data_array)
  console.log('raw_data_array', raw_data_array);

  // データを取得してresultListに入れるここまで
  // =======================================

  //日付のデータを取得
  const dateData = raw_data_array.map((result: any) => {
    return { date: result.result_at }
  })

  // ----- イベントトータルの配列をつくる --------
  const leadCost = raw_data_array.map((result: any) => result && result.lead_cost ? result.lead_cost : [])
  console.log('leadCost', leadCost);
  const leadCostSources = leadCost
    .flatMap((fn: any) => fn !== undefined && fn)
    .filter((fnData: any) => fnData.funnel_name === target_funnel_name)
    .map((fnDataData: any) => fnDataData.lead_cost_sources)
  console.log('leadCostSources', leadCostSources);


  const NameCostTotalArray = leadCostSources
    .flatMap((item: any) => item)
    .map((i: any, index: number) => {
      console.log(i.lead_source_name)
      console.log(leadCostSources.flatMap((item: any) => item)[index].lead_source_name)
      return {
        // lead_source_name: leadCostSources.flatMap((item: any) => item)[index].lead_source_name,
        lead_source_name: i.lead_source_name,
        costTotal: i.lead_cost_total ? i.lead_cost_total : 0,
      }
    })
  console.log('NameCostTotalArray', NameCostTotalArray);
  // -------- イベントトータルの配列を作る　ここまで ---------

  // -------- 成果(success)トータルの配列をつくる --------
  const leadSuccess = raw_data_array.map((result: any) => result && result.lead_success ? result.lead_success : [])
  const leadSuccessSources = leadSuccess
    .flatMap((fn: any) => fn !== undefined && fn)
    .filter((fnData: any) => fnData.funnel_name === target_funnel_name)
    .map((fnDataData: any) => fnDataData.lead_success_sources)
  console.log('leadSuccessSources', leadSuccessSources);

  const NameSuccessTotalArray = leadSuccessSources
    .flatMap((item: any) => item)
    .map((i: any, index: number) => {
      return {
        // lead_source_name: leadSuccessSources.flatMap((item: any) => item)[index].lead_source_name,
        // lead_source_name: i.lead_source_name,
        successTotal: i.lead_success_total ? i.lead_success_total : 0,
      }
    })
  console.log('NameSuccessTotalArray', NameSuccessTotalArray);
  // -------- 成果トータルの配列を作る　ここまで ---------

  // -------- 成果トータルとイベントトータルの比を出す ここから -------------
  // 成果トータルの配列とイベントトータルの配列をくっつける
  const nameCostSucessTotalArray = NameCostTotalArray.map((data: any, index: number) => {
    return { ...data, ...NameSuccessTotalArray[index] }
  })
  console.log('nameCostSucessTotalArray', nameCostSucessTotalArray);
  // 配列の中で比を計算する
  const nameCostSucessTotalRateArray = nameCostSucessTotalArray.map((data: any) => {
    return { 
      ...data,
      costSuccessRate: (data.costTotal === 0 || data.successTotal === 0) ? 0 : data.costTotal / data.successTotal
    }
  })
  console.log('nameCostSucessTotalRateArray', nameCostSucessTotalRateArray);
  // -------- 成果トータルとイベントトータルの比を出す ここまで -------------

  // -------- 名前だけの配列を取得する ここから ----------------
  // 日毎に名前だけとりだす
  const NameArrays = leadCostSources.map((les: any) => les.map((data: any) => data.lead_source_name))
  const newNameArray = NameArrays.flatMap((fn: any) => fn)
  console.log('NameArrays', NameArrays);

  // 名前データに重複があるので１つだけにする
  const uniqueNameArray = newNameArray.filter(function (x: any, i: any, self: any) {
    return self.indexOf(x) === i
  })

  const uniqueNameColorArray = uniqueNameArray.map((item:string ,index:number) => {
    return {
      lead_source_name: item,
      color: randomColorGenerator(index)
    }
  })

  const updatedColors = uniqueNameColorArray.map((color:any, index: number) => {
    if(init_colors[index]) {
      return {
        lead_source_name: color.lead_source_name,
        color: init_colors[index]
      }
    }
    return {
      ...color
    }
  })

  // -------- 名前だけの配列を取得する ここまで ----------------

  // -------- イベントトータルの配列を名前別の配列に変える(グラフ内で使用) -------------
  const arrayByName = uniqueNameArray.map((uniqueName: any) => {
    const filteredNameTotalArray = nameCostSucessTotalRateArray.filter((nta: any, index: number) => {
      return nta.lead_source_name === uniqueName
    })
    return filteredNameTotalArray
  })
  console.log('arrayByName', arrayByName);
  // -------- イベントトータルの配列を名前別の配列に変える ここまで -------------

  // -------- 上で作った配列に日付情報を付与 ここから -------------
  const arraysByNameDate = arrayByName.map((array: any) => {
    const arrayDate =
      array
        .map((data: any, index: number) => {
          return { ...data, ...dateData[index] }
        })
        // .filter((data_to_be_filtered: any) => data_to_be_filtered.costTotal !== 0 && data_to_be_filtered.successTotal !== 0)
    console.log('arrayDate', arrayDate);
    return arrayDate
  })
  console.log('arraysByNameDate', arraysByNameDate);
  // -------- 上で作った配列に日付情報を付与 ここまで -------------

  // -------- 名前（Allも）がクリックされた時の挙動 ここから -------------
  const dispatch = useDispatch()
  const handleNameFilterToggle = (e: any) => {
    dispatch(toggleAchivementCostSelectedNameList(e.target.value))
  }
  const handleNameFilterShowAll = (e: any) => {
    dispatch(achivementCostSelectedNameListShowAll())
  }
  const hiddenNameList = useSelector((state: any) => state.funnels.achievementCostSelectedNameList)
  // -------- 名前（Allも）がクリックされた時の挙動 ここまで -------------

  // -------- カスタムツールチップ ------------------
  const customTooltip = (props: any) => {

    // ツールチップで選択的に表示するために、arrayByNameDateをオブジェクトのlead_source_nameを持つオブジェクトの配列として整形
    const arraysWithNames = arraysByNameDate.map((array: any) => {
      return {
        lead_source_name: array[0].lead_source_name,
        data_array: array
      }
    })

    console.log('arraysWithNames', arraysWithNames) 
    
    //グラフのpayloadを取得
    const parentArray = props.payload
    console.log('props.payload', props.payload)
    if (parentArray != null) {
      //取得したpayload(親)の中のpayload(子)を取得
      const childPayload = parentArray.flatMap((childPayload: any) => childPayload.payload)
      
      //取得した値から[0]番目のみ取得
      const tooltipPayload = childPayload[0]
      console.log('tooltipPayload', tooltipPayload)
      
      if (tooltipPayload != null) {
        // 今ホバーされてるドットのリードソース名
        const hovered_lead_source_name = tooltipPayload.lead_source_name
        console.log(tooltipPayload)
        // ホバーされているドットのデータを、上で定義したpayloadの名前から抜き出す。
        const hoverd_lead_source_object = arraysWithNames.find((data: any) => data.lead_source_name === hovered_lead_source_name)
        console.log('hoverd_lead_source_data', hoverd_lead_source_object)
        // 上記データオブジェクトの中のdata_arrayを合算する。
        const total_data_object = hoverd_lead_source_object.data_array.reduce((sum: any, element:any) => {
          return{
            lead_success_total: sum.lead_success_total + element.successTotal,
            lead_cost_total: sum.lead_cost_total + element.costTotal,
          }
        },{
          //初期値
          lead_success_total: 0,
          lead_cost_total: 0
        })

        console.log('total_data_object', total_data_object)

        return (
          <TooltipContent>
              <TooltipFilterName>{tooltipPayload.lead_source_name}</TooltipFilterName>
              <thead>
                <tr>
                  <td>&nbsp;</td>
                  <TooltipLabel className="heading">成果数</TooltipLabel>
                  <TooltipLabel className="heading">コスト</TooltipLabel>
                </tr>
              </thead>
              <tbody>
                <TooltipRaw className="total">
                  <TooltipLabel className="total">合計</TooltipLabel>
                  <TooltipVal>{total_data_object.lead_success_total.toLocaleString()}</TooltipVal>
                  <TooltipVal>{total_data_object.lead_cost_total.toLocaleString("ja-JP", { style: "currency", currency: "JPY" })}</TooltipVal>
                </TooltipRaw>
                {
                  hoverd_lead_source_object &&
                  hoverd_lead_source_object.data_array.map((data: any) => {
                    console.log('data', data)
                    const yyyy = new Date(data.date.seconds * 1000).getFullYear()
                    const MM = ('0' + (new Date(data.date.seconds * 1000).getMonth() + 1)).slice(-2)
                    const dd = ('0' + (new Date(data.date.seconds * 1000).getDate())).slice(-2)
                    const yyyyMM = `${yyyy}.${MM}`
                    const yyyyMMDD = `${yyyy}.${MM}.${dd}`

                    return (
                      <TooltipRaw style={data.date.seconds === tooltipPayload.date.seconds ? {backgroundColor: 'rgba(57, 101, 255, 0.1)'} : {}}>
                        {/* 日付 */}
                        {
                          resultSpan !== 'monthly' 
                          ? <TooltipDaily>{yyyyMMDD}</TooltipDaily>
                          : <TooltipDaily>{yyyyMM}</TooltipDaily>
                        }
                        {/* 成果 */}
                          <TooltipVal>{data.successTotal.toLocaleString()}</TooltipVal>
                        {/* コスト */}
                          <TooltipVal>{data.costTotal.toLocaleString("ja-JP", { style: "currency", currency: "JPY" })}</TooltipVal>
                      </TooltipRaw>
                    )
                  })
                }
            </tbody>
          </TooltipContent>
        )
      }
      return
    }
    return false
  }
  // -------- カスタムツールチップ ここまで------------------

  return (
    <>
    {
      uniqueNameArray.length > 0 ? (
        <div ref={props.mapAchievementCostAreaRef}>
          <DataFilterList>
            <DataFilterItem className={hiddenNameList.length === 0 ? "selected" : ""} style={{ textAlign: "left" }} onClick={handleNameFilterShowAll}>
              <ColorCircle>●</ColorCircle>All
            </DataFilterItem>
            {uniqueNameArray.map((nameData: any, index: number) => {
              return (
                <DataFilterItem className={hiddenNameList.some((item: any) => item === nameData) ? "selected" : ""} value={nameData} onClick={handleNameFilterToggle}>
                  <ColorCircle style={{ color: updatedColors[index].color }}>●</ColorCircle>
                  {nameData}
                </DataFilterItem>
              )
            })}
          </DataFilterList>
          <ResponsiveContainer height={292}>
            <ScatterChart  width={968} margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
              <XAxis label={{value: '成果数', position: 'insideBottomRight', offset: -5, fontSize: 10, fill: '#435878'}} type="number" tick={{ fontSize: 10 }} dataKey={"successTotal"} name="成果" tickFormatter={tick => tick.toLocaleString()}/>
              <YAxis label={{value: 'コスト', position: 'insideTop', offset: -18, fontSize: 10, fill: '#435878', }} type="number" tick={{ fontSize: 10 }} tickCount={10} interval={0} dataKey={"costTotal"} name="行動" tickMargin={10} tickLine={false} axisLine={false} tickFormatter={tick => tick.toLocaleString()}/>
              <ZAxis range={[250, 250]} />
              <CartesianGrid />
              {arraysByNameDate.map((array: any, index: number) => {
                console.log('array',array)
                if (!hiddenNameList.includes(array[0].lead_source_name)) {
                  if (array) {
                    let updated_array = array.filter((ar_item: any) => (ar_item.costTotal !== 0 || ar_item.successTotal !== 0))
                    return <Scatter data={updated_array} fill={updatedColors[index].color} key={`scac_${index}`} />
                  } else {
                    return <></>
                  }
                } else {
                  return <></>
                }
              })}
              <Tooltip content={customTooltip} wrapperStyle={customWrapperStyle} />
            </ScatterChart>
          </ResponsiveContainer>
        </div>
      ):(
        <NoData>グラフを表示できません</NoData>
      )
    }
    </>
  )
}

export default FunnelDetailDistributionMapAchievementCost

// DataFilterList
const DataFilterList = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0;
  padding: 0 0 16px 32px;
  box-sizing: border-box;
  list-style: none;
`
// DataFilterItem
const DataFilterItem = styled.button`
  font-size: 12px;
  line-height: 18px;
  color: #435878;
  margin-right: 15px;
  margin-bottom: 10px;
  min-width: 96px;
  padding: 7px 8px 7px 8px;
  border: 1px solid #edf0f1;
  border-radius: 2px;
  box-sizing: border-box;
  background-color: #fff;
  text-align: center;
  cursor: pointer;
  &:hover {
    font-weight: bold;
    border: 1px solid #0066ff;
  }

  &.active {
    font-weight: bold;
    border: 1px solid #0066ff;
  }
  &.selected {
   background-color:  #EDF0F1;
  }
`

const ColorCircle = styled.span`
  margin-right: 8px;
  font-size: 16px;
`
// Tooltip
const TooltipContent = styled.div`
  width: auto;
  padding: 7px 9px 9px 9px;
  border-radius: 2px;
  box-sizing: border-box;
  background-color: #fff;
`
const TooltipFilterName = styled.p`
  font-size: 13px;
  font-weight: bold;
  color: #435878;
  margin-bottom: 5px;
`
const TooltipDaily = styled.td`
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  color: #7591BC;
  margin-bottom: 8px;
`
const TooltipRaw = styled.tr`
  td {
    padding: 3px;
  }
  &.total {
    td {
      padding-bottom: 3px;
    }
  }
`

const TooltipLabel = styled.td`
  font-size: 10px;
  font-weight: normal;
  color: #435878;
  width: 80px;
  text-align: right;
  &.total {
    font-weight: bold;
    text-align: left;
  }
  &.heading {
    text-align: center;
  }
`
const TooltipVal = styled.td`
  font-size: 12px;
  color: #435878;
  text-align: right;
`

const NoData = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #435878;
  margin-bottom: 8px;
  &.main {
    margin-bottom: 8px;
  }
`

// Tooltipのカスタムスタイリング
const customWrapperStyle = {
  background: "white",
  width: "auto",
  height: "auto",
  borderRadius: "2px",
  boxShadow: "0 4px 5px 0 rgba(0, 0, 0, .25)",
  zIndex: "99",
}
