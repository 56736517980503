import rsf from "../rsf";
import { call, put, takeLatest } from "redux-saga/effects";
import { UPDATE_BILLING_ACCOUNT_REQUESTED, UPDATE_BILLING_ACCOUNT_SUCCEEDED, UPDATE_BILLING_ACCOUNT_FAILED, GET_BILLING_ACCOUNT_REQUESTED, GET_BILLING_ACCOUNT_SUCCEEDED, GET_BILLING_ACCOUNT_FAILED } from "../reducks/billingAccount/types";

function* getBillingAccount(action: any) {
  try {
    const snapshot = yield call(rsf.firestore.getDocument, `companies/${action.company_id}/billing_account/${action.billingAccountId}`);
    const billingAccount = snapshot.data();
    yield put({ type: GET_BILLING_ACCOUNT_SUCCEEDED, billingAccount: billingAccount });
  } catch (e) {
    yield put({ type: GET_BILLING_ACCOUNT_FAILED, message: e.message });
  }
}


function* updateBillingAccount(action: any) {
  try {
    const doc = yield call(
      rsf.firestore.updateDocument, `companies/${action.company_id}/billing_account/${action.billingAccountId}`,
      {
        firstName: action.firstName,
        firstName_jp: action.firstNameJp,
        lastName: action.lastName,
        lastName_jp: action.lastNameJp,
        position: action.position,
        postal_code: action.postalCode,
        tel: action.tel,
        address_1: action.address1,
        address_2: action.address2,
        email: action.email,
        company_name: action.companyName,
      }
    )
    yield put({ type: UPDATE_BILLING_ACCOUNT_SUCCEEDED, billingAccount: doc });
  } catch (e) {
    yield put({ type: UPDATE_BILLING_ACCOUNT_FAILED, message: e.message });
  }
}


const membersSagas = [
  takeLatest(UPDATE_BILLING_ACCOUNT_REQUESTED, updateBillingAccount),
  takeLatest(GET_BILLING_ACCOUNT_REQUESTED, getBillingAccount)
];

export default membersSagas;
