export const handlePLForecastCostCat3Simulation = (
  input_value: string, 
  result_at: any, 
  target_data_array: any, 
  target_data: string, 
  is_real: boolean,
  is_boundary: boolean,
  cat3_name: string,
  cat2_name: string,
  ) => {
  const target_to_be_changed = target_data_array.find((target_data: any) => target_data.result_at.seconds === result_at);
  const target_index = target_data_array.findIndex((target_data: any) =>target_data.result_at.seconds === result_at);
  const input_value_out_of_comma = input_value.replace(/,/g, '');
  const input_value_out_of_yen = input_value_out_of_comma.replace(/￥/g, '');
  const target_cat1_total = target_data + "_cat_1_total"
  const target_cat2_array = target_data + "_cat_2_array" 
  const target_cat2_total = target_data + "_cat_2_total" 
  const target_cat2_name = target_data + "_cat_2_name" 
  const target_cat3_array = target_data + "_cat_3_array" 
  const target_cat3_total = target_data + "_cat_3_total" 
  const target_cat3_name = target_data + "_cat_3_name" 
  
  let input_value_num: number
  // 単位￥の場合
  if ( input_value.indexOf('￥') !== -1) {
    input_value_num = parseInt(input_value_out_of_yen);
    // 単位無し
  } else {
    input_value_num = Number(input_value)
  }
  // 数字を全部消してしまった場合
  if(input_value_num.toString() === '' || isNaN(input_value_num) ) {
    input_value_num = 0
  }
  
  // カテゴリー3の配列を、入力された新しい値にして更新する
  const new_cost_cat3_array = target_to_be_changed[target_cat2_array]
  //カテゴリー2の名前でフィルタリング
  .filter((cat2_item: any) => cat2_item[target_cat2_name] === cat2_name)
  //カテゴリー3の配列だけを抽出して階層をひとつあげる
  .flatMap((cat2_detail: any) => [...cat2_detail[target_cat3_array]])
  //対象のカテゴリー名を持つものの合計値を上書き
  .map((cat3_item: any) => {
    if(cat3_item[target_cat3_name] !== cat3_name) {
      return cat3_item
    }
    return {
      ...cat3_item,
      [target_cat3_total]:input_value_num
    }
  })

  // 更新されたカテゴリー3の配列の実績を合計し、カテゴリ2の値を計算
  const new_cost_cat2_total = new_cost_cat3_array.reduce((acc: any, cur: any) => acc + cur[target_cat3_total], 0)

  // 既存のカテゴリー2の配列に、新しいカテゴリー2の合計と、新しいカテゴリー３の配列を上書きする。
  const new_cost_cat2_array = target_to_be_changed[target_cat2_array].map((cat2_detail: any) => {
    if(cat2_detail[target_cat2_name] !== cat2_name) {
      return cat2_detail
    }
    return {
      ...cat2_detail,
      [target_cat2_total]: new_cost_cat2_total,
      [target_cat3_array]: new_cost_cat3_array
    }
  })

  // 更新されたカテゴリー2の配列から、new_cost_totalを計算
  const new_cost_total = new_cost_cat2_array.reduce((acc: any, cur: any) => acc + cur[target_cat2_total], 0)
  
  // 売上総合利益を計算 （new_cost_totalが違うので、target_data_keyによって場合わけ）
  const new_gross_profit = 
    target_data === 'unit_cost' 
    ? 
      is_real || is_boundary 
      ? (target_to_be_changed.revenue_total - new_cost_total) 
      : (target_to_be_changed.revenue_total_forecasted - new_cost_total)
    :
      is_real || is_boundary 
      ? (target_to_be_changed.revenue_total - target_to_be_changed.unit_cost_cat_1_total) 
      : (target_to_be_changed.revenue_total_forecasted - target_to_be_changed.unit_cost_cat_1_total)

  // 営業利益を計算 （new_cost_totalが違うので、target_data_keyによって場合わけ）
  const new_operating_profit = 
  target_data === 'unit_cost' ? (new_gross_profit - target_to_be_changed.svg_cost_cat_1_total) : (new_gross_profit - new_cost_total)


  //更新したいオブジェクトを、上二つを更新した新しいオブジェクトとして作成
  const updated_target_data = 
  target_data === 'unit_cost' ?
  //原価
    is_real || is_boundary 
    ? //実測・境界月のデータの時
      {
        ...target_to_be_changed,
        [target_cat1_total]: new_cost_total ,
        [target_cat2_array]: new_cost_cat2_array,
        profit_gross_profit_total: new_gross_profit,
        profit_gross_profit_total_forecasted: new_gross_profit,
        profit_operating_profit_total: new_operating_profit,
        profit_operating_profit_total_forecasted: new_operating_profit
      }
    : //予測データの時
      {
        ...target_to_be_changed,
        [target_cat1_total]: new_cost_total ,
        [target_cat2_array]: new_cost_cat2_array,
        profit_gross_profit_total_forecasted: new_gross_profit,
        profit_operating_profit_total_forecasted: new_operating_profit
      }
  :
  //販売管理費
    is_real || is_boundary 
    ? //実測・境界月のデータの時
      {
        ...target_to_be_changed,
        [target_cat1_total]: new_cost_total ,
        [target_cat2_array]: new_cost_cat2_array,
        profit_operating_profit_total: new_operating_profit,
        profit_operating_profit_total_forecasted: new_operating_profit
        
      }
    : //予測データの時
      {
        ...target_to_be_changed,
        [target_cat1_total]: new_cost_total ,
        [target_cat2_array]: new_cost_cat2_array,
        profit_operating_profit_total_forecasted: new_operating_profit
      }

  return {
    result_at: result_at,
    target_index: target_index,
    updated_target_data: updated_target_data,
  }
};
