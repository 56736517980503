import React, { useCallback } from "react";
import FileSaver from "file-saver";
import {
  updateDownloadGraphStatus,
} from "../../../reducks/download/actions";
import {
  updateDownloadCsvTarget,
} from "../../../reducks/download/actions";
import { useCurrentPng } from "recharts-to-png";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import { yAxisFormatter } from "../../Util/yAxisFormatter";
import { unitSwitcher } from "../../Util/unitSwitcher";
import { AlternateEmail } from "@material-ui/icons";
import GraphDataDownload from "../../Parts/GraphDataDownload";
interface Props {
  funnel_summary_array: any;
  resultSpan: string;
  moving_average_graph_switch: any;
}

const CustomTooltip = ({ active, payload, label }: any) => {
  console.log("payload", payload);
  if (active && payload && payload.length) {
    return (
      <BarGraphTooltip>
        <BarGraphTooltipHeading>
          {payload[0].payload.funnel_name}
        </BarGraphTooltipHeading>

        {payload.map((payload_item: any) => (
          <BarGraphTooltipContent key={payload_item.dataKey}>
            <BarGraphTooltipLabel bgcolor={payload_item.stroke}>
              {payload_item.dataKey}
            </BarGraphTooltipLabel>
            <BarGraphTooltipData>
              {`${unitSwitcher(payload_item.value, "rate")}`}
            </BarGraphTooltipData>
            <BarGraphTooltipHR />
          </BarGraphTooltipContent>
        ))}
      </BarGraphTooltip>
    );
  }

  return null;
};

const LegendListContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

const LegendList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding-top: 15px;
`;

const LegendItem = styled.li`
  font-size: 12px;
  line-height: 1.6;
  margin-right: 16px;
  margin-bottom: 10px;
  padding: 6px 8px 6px 30px;
  box-sizing: border-box;
  position: relative;
  border: 1px solid #edf0f1;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
  &.deactivated {
    background-color: #edf0f1;
  }
  button {
    position: relative;
    span {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 2px;
      left: -22px;
      border-radius: 8px;
    }
  }
`;

export interface LeadNewCountTotalItem {
  channel_name: string;
  lead_new_count_total: string;
}
export interface LeadTransitionRateItem {
  channel_name: string;
  lead_transition_rate_total: string;
}

// export interface FunnelSummaryData {
//   result_at: {
//     seconds: string;
//     nanoseconds: string;
//   };
//   lead_new_count_total_array: LeadNewCountTotalItem[];
//   lead_transition_rate_aray: LeadTransitionRateItem[];
//   funnel_name: string;
// }

export interface ChannelName {
  channel_id: number;
  channel_name: string;
  deactivated: boolean;
  channel_color: string;
}

export interface FunnelSummaryDataProps {
  channel_name_array: ChannelName[];
  data_for_graph_line: any;
}

const FunnelSummaryGraphTransitionRateLeadTime: React.FC<FunnelSummaryDataProps> =
  ({ channel_name_array, data_for_graph_line }) => {
    const dispatch = useDispatch();
    const [lineArray, setLineArray] = React.useState(channel_name_array);

    // カスタムレジェンドから呼び出す、レジェンドアイテムのクリック関数
    const handleLegendItemClick = (dataKey: any) => {
      const updatedLineArray = lineArray.map((lineItem: any) => {
        if (lineItem.channel_name === dataKey) {
          return {
            ...lineItem,
            deactivated: !lineItem.deactivated
          };
        }
        return lineItem;
      });
      setLineArray(updatedLineArray);
    };

    // Graph Download Section
    const [getAreaPng, { ref: areaRef }] = useCurrentPng();
    const handleAreaDownload = useCallback(async () => {
      dispatch(
        updateDownloadGraphStatus(0)
      )
      const png = await getAreaPng();
      if (png) {
        FileSaver.saveAs(png, "graph.png");
      }
    }, [getAreaPng, dispatch]);
    function handleGraphDataDownloadClick(e:any, target:string) {
      if(e.target.innerText==='グラフ Export'){
          handleAreaDownload();
      }
      dispatch(
        updateDownloadCsvTarget("/"+target)
      )
    }

    // カスタムレジェンド
    const CustomLegend = (props: any) => {
      return (
        <LegendListContainer>
          <LegendList>
            {lineArray.map((lineItem: any, index: number) => (
              <LegendItem
                key={`LegendItem-${index}`}
                className={lineItem.deactivated ? "deactivated" : ""}
                onClick={() => handleLegendItemClick(lineItem.channel_name)}
              >
                <button className="item-total">
                  <span
                    style={{ backgroundColor: `${lineItem.channel_color}` }}
                  ></span>
                  {lineItem.channel_name}
                </button>
              </LegendItem>
            ))}
          </LegendList>
        </LegendListContainer>
      );
    };

    // グラフの実体
    return (
      <div>
        <FunnelSummaryGraphTransitionRateLeadTimeHeadingGraphDownloadWrapper>
          <FunnelSummaryGraphTransitionRateLeadTimeHeading>
            Channel - 移行率の推移
          </FunnelSummaryGraphTransitionRateLeadTimeHeading>
          <div onClick={(e) => {handleGraphDataDownloadClick(e, 'migration_rate')}}>
            <GraphDataDownload />
          </div>
        </FunnelSummaryGraphTransitionRateLeadTimeHeadingGraphDownloadWrapper>
        {data_for_graph_line.length > 0 ? (
          <FunnelSummaryGraph>
            <ResponsiveContainer>
              <LineChart width={730} height={250} data={data_for_graph_line} ref={areaRef}>
                <Legend
                  verticalAlign={"top"}
                  align={"left"}
                  content={CustomLegend}
                />
                <XAxis
                  dataKey="funnel_name"
                  tickLine={false}
                  tickSize={10}
                  minTickGap={20}
                  tick={{ fontSize: 10 }}
                />

                <YAxis
                  type="number"
                  tickCount={10}
                  interval={0}
                  tickMargin={10}
                  tickLine={false}
                  yAxisId={0}
                  tickFormatter={tick =>
                    yAxisFormatter(tick, "transition_rate")
                  }
                />
                <CartesianGrid stroke="#f5f5f5" />

                {lineArray.map(
                  channelItem =>
                    !channelItem.deactivated && (
                      <Line
                        id={channelItem.channel_id.toString()}
                        name={channelItem.channel_name}
                        dataKey={channelItem.channel_name}
                        stroke={channelItem.channel_color}
                        yAxisId={0}
                        strokeWidth={3}
                      />
                    )
                )}
                <Tooltip content={<CustomTooltip />} />
              </LineChart>
            </ResponsiveContainer>
          </FunnelSummaryGraph>
        ) : (
          <NoData>グラフを表示できません</NoData>
        )}
      </div>
    );
  };
export default FunnelSummaryGraphTransitionRateLeadTime;

// Graph
const FunnelSummaryGraph = styled.div`
  width: 100%;
  height: 272px;
  padding: 0 10px;
  box-sizing: border-box;

  .recharts-surface {
    position: relative;
    top: -1px;
    overflow: visible;
  }
  .recharts-cartesian-axis-tick {
    width: 100px !important;
    .recharts-cartesian-axis-tick-value {
      font-size: 10px;
      color: #435878;
    }
  }
  .recharts-legend-wrapper {
    width: 100% !important;
    height: 60px !important;
    overflow-y: scroll;
  }
`;
const NoData = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #435878;
  margin-bottom: 8px;
  &.main {
    margin-bottom: 8px;
  }
`;

const BarGraphTooltip = styled.div`
  background: #fff;
  padding: 8px 9px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid #e2e9f3;
  border-radius: 4px;
  max-width: 200px;
`;

// 日付
const BarGraphTooltipHeading = styled.h3`
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  color: #435878;
  margin-bottom: 5px;
`;

const BarGraphTooltipContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

// 当月 | 目標
const BarGraphTooltipLabel = styled.p<{ bgcolor: string }>`
  position: relative;
  width: 50%;
  font-size: 10px;
  color: #435878;
  left: 10px;
  margin-bottom: 3px;
  &::before {
    position: absolute;
    top: 3px;
    left: -8px;
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: ${props => props.bgcolor};
  }
`;
// データ
const BarGraphTooltipData = styled.p`
  width: 50%;
  text-align: right;
  font-size: 10px;
  font-weight: bold;
  color: #435878;
  margin-bottom: 3px;
`;
// 仕切り線
const BarGraphTooltipHR = styled.hr`
  width: 100%;
  height: 1px;
  color: lightgray;
  margin-bottom: 3px;
`;

const FunnelSummaryGraphTransitionRateLeadTimeHeading = styled.h2`
  color: #283f46;
  font-weight: bold;
  font-size: 22px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const FunnelSummaryGraphTransitionRateLeadTimeHeadingGraphDownloadWrapper = styled.div({
  flexGrow: 1,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});
