const ADD_MEMBER_REQUESTED = "ADD_MEMBER_REQUESTED";
const ADD_MEMBER_SUCCEEDED = "ADD_MEMBER_SUCCEEDED";
const ADD_MEMBER_FAILED = "ADD_MEMBER_FAILED";

const UPDATE_MEMBER_REQUESTED = "UPDATE_MEMBER_REQUESTED";
const UPDATE_MEMBER_SUCCEEDED = "UPDATE_MEMBER_SUCCEEDED";
const UPDATE_MEMBER_FAILED = "UPDATE_MEMBER_FAILED";

const GET_MEMBERS_REQUESTED = "GET_MEMBERS_REQUESTED";
const GET_MEMBERS_SUCCEEDED = "GET_MEMBERS_SUCCEEDED";
const GET_MEMBERS_FAILED = "GET_MEMBERS_FAILED";

const FETCH_MEMBER_REQUESTED = "FETCH_MEMBER_REQUESTED";
const FETCH_MEMBER_SUCCEEDED = "FETCH_MEMBER_SUCCEEDED";
const FETCH_MEMBER_FAILED = "FETCH_MEMBER_FAILED";

const DELETE_MEMBER_REQUESTED = "DELETE_MEMBER_REQUESTED";
const DELETE_MEMBER_SUCCEEDED = "DELETE_MEMBER_SUCCEEDED";
const DELETE_MEMBER_FAILED = "DELETE_MEMBER_FAILED";

const GET_AUTH_REQUESTED = "GET_AUTH_REQUESTED";
const GET_AUTH_SUCCEEDED = "GET_AUTH_SUCCEEDED";
const GET_AUTH_FAILED = "GET_AUTH_FAILED";

const TRANSFER_OWNERSHIP_REQUESTED = "TRANSFER_OWNERSHIP_REQUESTED";
const TRANSFER_OWNERSHIP_SUCCEEDED = "TRANSFER_OWNERSHIP_SUCCEEDED";
const TRANSFER_OWNERSHIP_FAILED = "TRANSFER_OWNERSHIP_FAILED";

const TOGGLE_MEMBER_TO_EDIT_POST_STATUS = "TOGGLE_MEMBER_TO_EDIT_POST_STATUS";
const TOGGLE_MEMBER_TO_ADD_POST_STATUS = "TOGGLE_MEMBER_TO_ADD_POST_STATUS";
const TOGGLE_MEMBER_TO_DELETE_POST_STATUS = "TOGGLE_MEMBER_TO_DELETE_POST_STATUS";




export { ADD_MEMBER_REQUESTED, ADD_MEMBER_SUCCEEDED, ADD_MEMBER_FAILED, UPDATE_MEMBER_REQUESTED, UPDATE_MEMBER_SUCCEEDED, UPDATE_MEMBER_FAILED, GET_MEMBERS_REQUESTED, GET_MEMBERS_SUCCEEDED, GET_MEMBERS_FAILED, FETCH_MEMBER_REQUESTED, FETCH_MEMBER_SUCCEEDED, FETCH_MEMBER_FAILED, DELETE_MEMBER_REQUESTED, DELETE_MEMBER_SUCCEEDED, DELETE_MEMBER_FAILED, GET_AUTH_REQUESTED, GET_AUTH_SUCCEEDED, GET_AUTH_FAILED, TRANSFER_OWNERSHIP_REQUESTED, TRANSFER_OWNERSHIP_SUCCEEDED, TRANSFER_OWNERSHIP_FAILED, TOGGLE_MEMBER_TO_EDIT_POST_STATUS, TOGGLE_MEMBER_TO_ADD_POST_STATUS, TOGGLE_MEMBER_TO_DELETE_POST_STATUS };
