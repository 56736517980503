import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { yAxisFormatter } from "../Util/yAxisFormatter";
import { dateFormatter, tooltipDateFormatter } from "../Util/DateFormatter";
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine
} from "recharts";
import graphObjectiveLegendIcon from "../../images/icons/graph_objective_legend.svg";
import graphTotalLegendIcon from "../../images/icons/graph_total_legend.svg";

//フィルターなしLegend
const noneFilterLegend = () => {
  return (
    <ul
      style={{
        display: "flex",
        fontSize: "12px",
        color: "#283f46",
        marginBottom: "16px",
        marginTop: "40px"
      }}
    >
      <LegendItem className="MRR">MRR</LegendItem>
      <LegendItem className="others">その他売上</LegendItem>
      <LegendItem className="MRR比率">MRR比率</LegendItem>
    </ul>
  );
};

function StackedDetailChartRevenueMrr(props: any) {
  const target_data_name = useSelector(
    (state: any) => state.target_data.target_data_name
  );

  const resultSpan = useSelector((state: any) => state.target_data.resultSpan);

  // const target_data = useSelector((state: any) => state.results.target_data);
  const target_data = useSelector(
    (state: any) => state.target_data.target_data
  );
  const key_total = useSelector((state: any) => state.target_data.key_total);

  const key_objective = useSelector(
    (state: any) => state.target_data.key_objective
  );
  const key_moving_average_total = useSelector(
    (state: any) => state.target_data.key_moving_average_total
  );

  const customTooltip = (props: any) => {
    //グラフのpayloadを取得
    const parentArray = props.payload;

    if (parentArray != null) {
      //取得したpayload(親)の中のpayload(子)を取得
      const childPayload = parentArray.flatMap(
        (childPayload: any) => childPayload.payload
      );

      //取得した値から[0]番目のみ取得
      const tooltipPayload = childPayload[0];
      if (tooltipPayload != null) {
        return (
          <TooltipContent>
            <TooltipDate>
              {tooltipDateFormatter(
                resultSpan,
                tooltipPayload.result_at.seconds
              )}
            </TooltipDate>

            {parentArray.map((payload: any) => {
              return (
                <TooltipData>
                  <TooltipLabel>
                    {payload.name.includes("MRR") && (
                      <>
                        MRR
                      </>
                    )}

                    {payload.name.includes("その他売上") && (
                      <>
                        その他売上
                      </>
                    )}

                    {payload.name.includes("比率") && (
                      <>
                        MRR比率
                      </>
                    )}
                  </TooltipLabel>
                  {payload.name === "MRR" ? (
                    <TooltipVal style={{ color: payload.fill }}>
                      {payload.value.toLocaleString("ja-JP", {
                        style: "currency",
                        currency: "JPY"
                      })}
                    </TooltipVal>
                  ) : payload.name === "その他売上" ? (
                    <TooltipVal style={{ color: payload.fill }}>
                      {payload.value.toLocaleString("ja-JP", {
                        style: "currency",
                        currency: "JPY"
                      })}
                    </TooltipVal>
                  ) : payload.name === "比率" ? (
                    <TooltipVal style={{ color: "#109CBB" }}>
                      {Math.round(payload.value * 100 * 10) / 10 + "%"}
                    </TooltipVal>
                  ) : (
                    ""
                  )}

                  {/*  */}
                </TooltipData>
              );
            })}
          </TooltipContent>
        );
      }
      return;
    }
    return false;
  };
  return (
    <div>
      {target_data.length > 0 ? (
        <>
          <Graph>
            <ResponsiveContainer>
              <ComposedChart
                stackOffset="sign"
                width={1000}
                height={400}
                data={target_data}
                barSize="0"
                margin={{ bottom: 24 }}
                ref={props.areaRef}
              >
                <Legend
                  verticalAlign={"top"}
                  align={"left"}
                  width={432}
                  height={32}
                  margin={{ right: 100, top: 60 }}
                  iconSize={12}
                  wrapperStyle={{ fontSize: "12px", color: "#283f46" }}
                />

                <CartesianGrid stroke="#f5f5f5" vertical={false} />
                <XAxis
                  dataKey={"result_at.seconds"}
                  tickFormatter={tick => dateFormatter(resultSpan, tick * 1000)}
                  axisLine={{ stroke: "#f5f5f5" }}
                />
                <ReferenceLine y={0} yAxisId={1} stroke="#35455D" />
                <YAxis
                  yAxisId={0}
                  tickCount={10}
                  interval={0}
                  tickLine={false}
                  tickMargin={10}
                  tickFormatter={tick => yAxisFormatter(tick, target_data_name)}
                  orientation="right"
                />
                <YAxis
                  yAxisId={1}
                  tickCount={10}
                  interval={0}
                  tickLine={false}
                  tickMargin={10}
                  tickFormatter={tick =>
                    yAxisFormatter(tick, "mrr_total_value")
                  }
                />
                <Bar
                  stackId="detail"
                  fill="#0066FF"
                  yAxisId={1}
                  dataKey="revenue_total_mrr"
                  name="MRR"
                />
                <Bar
                  stackId="detail"
                  fill="#004BBB"
                  yAxisId={1}
                  dataKey="revenue_total_other"
                  name="その他売上"
                />
                <Bar
                  stackId="detail"
                  fill="#004BBB"
                  yAxisId={1}
                  dataKey="revenue_mrr_rate"
                  name="比率"
                />

                {/* <Tooltip wrapperStyle={{ display: "none" }} /> */}
                <Tooltip content={customTooltip} />
              </ComposedChart>
            </ResponsiveContainer>
          </Graph>
        </>
      ) : (
        <NoData>グラフを表示できません</NoData>
      )}
    </div>
  );
}

export default StackedDetailChartRevenueMrr;

// Graph
const Graph = styled.div`
  width: 100%;
  height: 272px;
  padding: 0 10px;
  box-sizing: border-box;

  .recharts-legend-wrapper {
    overflow: visible;
    top: 1px !important;
    left: 10px !important;
    font-size: 12px;
    color: #283f46;
  }

  .recharts-surface {
    position: relative;
    top: -2px;
    overflow: visible;
  }
  .recharts-cartesian-axis-tick {
    width: 100px !important;
    .recharts-cartesian-axis-tick-value {
      font-size: 10px;
      color: #435878;
    }
  }
  .recharts-yAxis {
    .recharts-cartesian-axis-line {
      stroke: #f5f5f5;
    }
  }
  .legend-item-2 {
    display: none !important;
  }
`;

const NoData = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #435878;
  margin-bottom: 8px;
  &.main {
    margin-bottom: 8px;
  }
`;

// Tooltipのカスタムスタイリング
const customWrapperStyle = {
  background: "white",
  width: "auto",
  height: "auto",
  borderRadius: "2px",
  boxShadow: "0 4px 5px 0 rgba(0, 0, 0, .25)",
  zIndex: "99",
  // overflow: "hidden",
  padding: "8px 16px",
  boxSizing: "border-box"
};
// Tooltipのカスタムスタイリング
const productComparisonWrapperStyle = {
  background: "white",
  width: "auto",
  height: "auto",
  borderRadius: "2px",
  boxShadow: "0 4px 5px 0 rgba(0, 0, 0, .25)",
  zIndex: "99",
  padding: "8px 16px"
};

// Tooltip
const TooltipContent = styled.div`
  width: 178px;
  padding: 10px 9px 9px 9px;
  background-color: #fff;
`;

const TooltipDate = styled.p`
  font-size: 10px;
  font-weight: bold;
  line-height: 15px;
  margin-left: 13px;
  color: #435878;
  margin-bottom: 10px;
`;

const TooltipData = styled.dl`
  display: flex;
  padding: 5px 15px;
  box-sizing: border-box;
`;
const TooltipLabel = styled.td`
  font-size: 8px;
  font-weight: normal;
  color: #435878;
  width: 80px;
  box-sizing: border-box;
  text-align: left;
`;
const TooltipVal = styled.td`
  font-size: 10px;
  color: #435878;
  text-align: right;
  width: 120px;
  box-sizing: border-box;
`;
const LegendItem = styled.li`
  &.mrr {
 
    }
  }
  &.others {


     
    
  }
`;
