export const titleSwitcher = (target_data_name: string) => {
  switch (target_data_name) {
    case "revenue":
      return "Revenue";
    case "mrr_total_value":
      return "MRR";
    case "unit_economics":
      return "Unit Economics";
    case "cac":
      return "CAC";
    case "payback_period":
      return "Payback Period";
    case "ltv":
      return "LTV";
    case "arpu":
      return "ARPU";
    case "churn_rate":
      return "Churn Rate";
    case "mrr_churn_rate":
      return "MRR Churn Rate";
    case "nrr":
      return "NRR";
    case "grr":
      return "GRR";
    case "quick_ratio":
      return "Quick Ratio";
    case "acv_cumulative":
      return "ACV（累積）";
    case "acv_new":
      return "ACV（新規）";
    case "cpa":
      return "CPA";
    case "conversion_rate":
      return "有料転換率";
    case "client_churned":
      return "Account Churned";
    case "account_churned":
      return "User Churned";
    case "revenue_churned":
      return "MRR Churned";
    case "new_customer":
      return "New Customer";
    case "active_customer":
      return "Active Customer";
    default:
      return "Undefined Data Name";
  }
};
