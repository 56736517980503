import rsf from "../rsf"
import firebase from 'firebase'
import { call, put, takeLatest } from "redux-saga/effects"
import { ADD_COMMENT_REQUESTED, ADD_COMMENT_SUCCEEDED, ADD_COMMENT_FAILED, GET_COMMENTS_REQUESTED, UPDATE_COMMENT_REQUESTED, UPDATE_COMMENT_SUCCEEDED, UPDATE_COMMENT_FAILED, DELETE_COMMENT_REQUESTED, DELETE_COMMENT_SUCCEEDED, DELETE_COMMENT_FAILED, GET_COMMENTS_SUCCEEDED, GET_COMMENTS_FAILED } from "../reducks/comments/types"

const db = firebase.firestore();

function* addComment(action) {
  try {
    // 期間設定
    const start_time = new Date(action.custom_span_start_time.seconds * 1000);
    const end_time = new Date(action.custom_span_end_time.seconds * 1000);
    // 当月
    const thisMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const month = thisMonth.getMonth();

    // 昨月
    const lastMonth = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 1));

    // 翌月
    const nextMonth = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month + 1));

    // 一昨月
    const lastLastMonth = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 2));

    // 6ヶ月前
    const last6Months = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 5));

    // 12ヶ月前
    const last12Months = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 11));


    yield call(
      rsf.firestore.addDocument,
      `companies/${action.company_id}/comments`,
      {
        result_at: firebase.firestore.Timestamp.fromDate(new Date(action.result_at_timestamp * 1000)),
        target_data_name: action.target_data_name,
        user_name: action.user_name,
        result_span: action.selected_data_config_data_type,
        content: action.content,
        updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
      }
    )

    if (action.selected_data_config_data_span === 'custom') {
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${action.company_id}/comments`)
          .where("result_span", "==", action.selected_data_config_data_type)
          .where("result_at", ">=", start_time)
          .where("result_at", "<=", end_time)
          .where("target_data_name", "==", action.target_data_name)
        );
      let comments = []
      let docIds = []
      snapshot.forEach((comment) => {
        comments = [...comments, { ...comment.data(), docId: comment.id }]
        docIds = [...docIds, comment.id] // ドキュメントIDの配列
      })
  
      yield put({ type: ADD_COMMENT_SUCCEEDED, comments: comments })
    } else if (action.selected_data_config_data_span === 'this_month') {
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${action.company_id}/comments`)
          .where("result_span", "==", action.selected_data_config_data_type)
          .where("result_at", ">=", lastMonth)
          .where("result_at", "<=", nextMonth)
          .where("target_data_name", "==", action.target_data_name)
        );

      let comments = []
      let docIds = []
      snapshot.forEach((comment) => {
        comments = [...comments, { ...comment.data(), docId: comment.id }]
        docIds = [...docIds, comment.id] // ドキュメントIDの配列
      })
      yield put({ type: ADD_COMMENT_SUCCEEDED, comments: comments })
    } else if (action.selected_data_config_data_span === 'last_month') {
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${action.company_id}/comments`)
          .where("result_span", "==", action.selected_data_config_data_type)
          .where("result_at", ">=", lastLastMonth)
          .where("result_at", "<=", thisMonth)
          .where("target_data_name", "==", action.target_data_name)
        );

      let comments = []
      let docIds = []
      snapshot.forEach((comment) => {
        comments = [...comments, { ...comment.data(), docId: comment.id }]
        docIds = [...docIds, comment.id] // ドキュメントIDの配列
      })
      yield put({ type: ADD_COMMENT_SUCCEEDED, comments: comments })

    } else if (action.selected_data_config_data_span === 'last_6_months') {
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${action.company_id}/comments`)
          .where("result_span", "==", action.selected_data_config_data_type)
          .where("result_at", ">=", last6Months)
          .where("result_at", "<=", thisMonth)
          .where("target_data_name", "==", action.target_data_name)
        );

      let comments = []
      let docIds = []
      snapshot.forEach((comment) => {
        comments = [...comments, { ...comment.data(), docId: comment.id }]
        docIds = [...docIds, comment.id] // ドキュメントIDの配列
      })
      yield put({ type: ADD_COMMENT_SUCCEEDED, comments: comments })

    } else if (action.selected_data_config_data_span === 'last_12_months') {
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${action.company_id}/comments`)
          .where("result_span", "==", action.selected_data_config_data_type)
          .where("result_at", ">=", last12Months)
          .where("result_at", "<=", thisMonth)
          .where("target_data_name", "==", action.target_data_name)
        );

      let comments = []
      let docIds = []
      snapshot.forEach((comment) => {
        comments = [...comments, { ...comment.data(), docId: comment.id }]
        docIds = [...docIds, comment.id] // ドキュメントIDの配列
      })
      yield put({ type: ADD_COMMENT_SUCCEEDED, comments: comments })
    } else {
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${action.company_id}/comments`)
          .where("result_span", "==", action.selected_data_config_data_type)
          .where("result_at", ">=", start_time)
          .where("result_at", "<=", end_time)
          .where("target_data_name", "==", action.target_data_name)
        );
  
  
      let comments = []
      let docIds = []
      snapshot.forEach((comment) => {
        comments = [...comments, { ...comment.data(), docId: comment.id }]
        docIds = [...docIds, comment.id] // ドキュメントIDの配列
      })
  
      yield put({ type: ADD_COMMENT_SUCCEEDED, comments: comments })
    }
  } catch (e) {
    yield put({ type: ADD_COMMENT_FAILED, message: e.message })
  }
}

function* updateComment(action) {
  // 期間設定
  const start_time = new Date(action.custom_span_start_time.seconds * 1000);
  const end_time = new Date(action.custom_span_end_time.seconds * 1000);
  // 当月
  const thisMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  const month = thisMonth.getMonth();

  // 昨月
  const lastMonth = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 1));

  // 翌月
  const nextMonth = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month + 1));

  // 一昨月
  const lastLastMonth = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 2));

  // 6ヶ月前
  const last6Months = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 5));

  // 12ヶ月前
  const last12Months = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 11));

  try {
    yield call(rsf.firestore.updateDocument, `companies/${action.company_id}/comments/${action.docId}`, {
      user_name: action.user_name,
      content: action.content,
      updated_at: firebase.firestore.Timestamp.fromDate(new Date())
    })
    
    if (action.selected_data_config_data_span === 'custom') {
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${action.company_id}/comments`)
          .where("result_span", "==", action.selected_data_config_data_type)
          .where("result_at", ">=", start_time)
          .where("result_at", "<=", end_time)
          .where("target_data_name", "==", action.target_data_name)
        );
      let comments = []
      let docIds = []
      snapshot.forEach((comment) => {
        comments = [...comments, { ...comment.data(), docId: comment.id }]
        docIds = [...docIds, comment.id] // ドキュメントIDの配列
      })
  
      yield put({ type: UPDATE_COMMENT_SUCCEEDED, comments: comments })
    } else if (action.selected_data_config_data_span === 'this_month') {
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${action.company_id}/comments`)
          .where("result_span", "==", action.selected_data_config_data_type)
          .where("result_at", ">=", lastMonth)
          .where("result_at", "<=", nextMonth)
          .where("target_data_name", "==", action.target_data_name)
        );

      let comments = []
      let docIds = []
      snapshot.forEach((comment) => {
        comments = [...comments, { ...comment.data(), docId: comment.id }]
        docIds = [...docIds, comment.id] // ドキュメントIDの配列
      })
      yield put({ type: UPDATE_COMMENT_SUCCEEDED, comments: comments })
    } else if (action.selected_data_config_data_span === 'last_month') {
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${action.company_id}/comments`)
          .where("result_span", "==", action.selected_data_config_data_type)
          .where("result_at", ">=", lastLastMonth)
          .where("result_at", "<=", thisMonth)
          .where("target_data_name", "==", action.target_data_name)
        );

      let comments = []
      let docIds = []
      snapshot.forEach((comment) => {
        comments = [...comments, { ...comment.data(), docId: comment.id }]
        docIds = [...docIds, comment.id] // ドキュメントIDの配列
      })
      yield put({ type: UPDATE_COMMENT_SUCCEEDED, comments: comments })

    } else if (action.selected_data_config_data_span === 'last_6_months') {
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${action.company_id}/comments`)
          .where("result_span", "==", action.selected_data_config_data_type)
          .where("result_at", ">=", last6Months)
          .where("result_at", "<=", thisMonth)
          .where("target_data_name", "==", action.target_data_name)
        );

      let comments = []
      let docIds = []
      snapshot.forEach((comment) => {
        comments = [...comments, { ...comment.data(), docId: comment.id }]
        docIds = [...docIds, comment.id] // ドキュメントIDの配列
      })
      yield put({ type: UPDATE_COMMENT_SUCCEEDED, comments: comments })

    } else if (action.selected_data_config_data_span === 'last_12_months') {
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${action.company_id}/comments`)
          .where("result_span", "==", action.selected_data_config_data_type)
          .where("result_at", ">=", last12Months)
          .where("result_at", "<=", thisMonth)
          .where("target_data_name", "==", action.target_data_name)
        );

      let comments = []
      let docIds = []
      snapshot.forEach((comment) => {
        comments = [...comments, { ...comment.data(), docId: comment.id }]
        docIds = [...docIds, comment.id] // ドキュメントIDの配列
      })
      yield put({ type: UPDATE_COMMENT_SUCCEEDED, comments: comments })
    } else {
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${action.company_id}/comments`)
          .where("result_span", "==", action.selected_data_config_data_type)
          .where("result_at", ">=", start_time)
          .where("result_at", "<=", end_time)
          .where("target_data_name", "==", action.target_data_name)
        );
  
  
      let comments = []
      let docIds = []
      snapshot.forEach((comment) => {
        comments = [...comments, { ...comment.data(), docId: comment.id }]
        docIds = [...docIds, comment.id] // ドキュメントIDの配列
      })
  
      yield put({ type: UPDATE_COMMENT_SUCCEEDED, comments: comments })
    }

  } catch (e) {
    yield put({ type: UPDATE_COMMENT_FAILED, message: e.message })
  }
}

// コメント削除
function* deleteComment(action) {
  // 期間設定
  const start_time = new Date(action.custom_span_start_time.seconds * 1000);
  const end_time = new Date(action.custom_span_end_time.seconds * 1000);
  // 当月
  const thisMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  const month = thisMonth.getMonth();

  // 昨月
  const lastMonth = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 1));

  // 翌月
  const nextMonth = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month + 1));

  // 一昨月
  const lastLastMonth = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 2));

  // 6ヶ月前
  const last6Months = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 5));

  // 12ヶ月前
  const last12Months = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 11));

  try {
    yield call(rsf.firestore.deleteDocument, `companies/${action.company_id}/comments/${action.docId}`)
    
    if (action.selected_data_config_data_span === 'custom') {
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${action.company_id}/comments`)
          .where("result_span", "==", action.selected_data_config_data_type)
          .where("result_at", ">=", start_time)
          .where("result_at", "<=", end_time)
          .where("target_data_name", "==", action.target_data_name)
        );
      let comments = []
      let docIds = []
      snapshot.forEach((comment) => {
        comments = [...comments, { ...comment.data(), docId: comment.id }]
        docIds = [...docIds, comment.id] // ドキュメントIDの配列
      })
  
      yield put({ type: DELETE_COMMENT_SUCCEEDED, comments: comments })
    } else if (action.selected_data_config_data_span === 'this_month') {
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${action.company_id}/comments`)
          .where("result_span", "==", action.selected_data_config_data_type)
          .where("result_at", ">=", lastMonth)
          .where("result_at", "<=", nextMonth)
          .where("target_data_name", "==", action.target_data_name)
        );

      let comments = []
      let docIds = []
      snapshot.forEach((comment) => {
        comments = [...comments, { ...comment.data(), docId: comment.id }]
        docIds = [...docIds, comment.id] // ドキュメントIDの配列
      })
      yield put({ type: DELETE_COMMENT_SUCCEEDED, comments: comments })
    } else if (action.selected_data_config_data_span === 'last_month') {
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${action.company_id}/comments`)
          .where("result_span", "==", action.selected_data_config_data_type)
          .where("result_at", ">=", lastLastMonth)
          .where("result_at", "<=", thisMonth)
          .where("target_data_name", "==", action.target_data_name)
        );

      let comments = []
      let docIds = []
      snapshot.forEach((comment) => {
        comments = [...comments, { ...comment.data(), docId: comment.id }]
        docIds = [...docIds, comment.id] // ドキュメントIDの配列
      })
      yield put({ type: DELETE_COMMENT_SUCCEEDED, comments: comments })

    } else if (action.selected_data_config_data_span === 'last_6_months') {
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${action.company_id}/comments`)
          .where("result_span", "==", action.selected_data_config_data_type)
          .where("result_at", ">=", last6Months)
          .where("result_at", "<=", thisMonth)
          .where("target_data_name", "==", action.target_data_name)
        );

      let comments = []
      let docIds = []
      snapshot.forEach((comment) => {
        comments = [...comments, { ...comment.data(), docId: comment.id }]
        docIds = [...docIds, comment.id] // ドキュメントIDの配列
      })
      yield put({ type: DELETE_COMMENT_SUCCEEDED, comments: comments })

    } else if (action.selected_data_config_data_span === 'last_12_months') {
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${action.company_id}/comments`)
          .where("result_span", "==", action.selected_data_config_data_type)
          .where("result_at", ">=", last12Months)
          .where("result_at", "<=", thisMonth)
          .where("target_data_name", "==", action.target_data_name)
        );

      let comments = []
      let docIds = []
      snapshot.forEach((comment) => {
        comments = [...comments, { ...comment.data(), docId: comment.id }]
        docIds = [...docIds, comment.id] // ドキュメントIDの配列
      })
      yield put({ type: DELETE_COMMENT_SUCCEEDED, comments: comments })
    } else {
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${action.company_id}/comments`)
          .where("result_span", "==", action.selected_data_config_data_type)
          .where("result_at", ">=", start_time)
          .where("result_at", "<=", end_time)
          .where("target_data_name", "==", action.target_data_name)
        );
  
  
      let comments = []
      let docIds = []
      snapshot.forEach((comment) => {
        comments = [...comments, { ...comment.data(), docId: comment.id }]
        docIds = [...docIds, comment.id] // ドキュメントIDの配列
      })
  
      yield put({ type: DELETE_COMMENT_SUCCEEDED, comments: comments })
    }

  } catch (e) {
    yield put({ type: DELETE_COMMENT_FAILED, message: e.message })
  }
}

// function* getComments(action) {
//   try {
//     console.log('comments action to', action.to);
//     console.log('comments action to firestore timetamp', firebase.firestore.Timestamp.fromDate(action.to));
//     const snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/comments`).where("result_span", "==", action.result_span).where("result_at", ">=", firebase.firestore.Timestamp.fromDate(action.from)).where("result_at", "<=", firebase.firestore.Timestamp.fromDate(action.to)).where("page_path", "==", action.page_path));

//     let comments = []
//     let docIds = []
//     snapshot.forEach((comment) => {
//       comments = [...comments, { ...comment.data(), docId: comment.id }]
//       docIds = [...docIds, comment.id] // ドキュメントIDの配列
//     })
//     yield put({ type: GET_COMMENTS_SUCCEEDED, comments: comments })
//   } catch (e) {
//     yield put({ type: GET_COMMENTS_FAILED, message: e.message })
//   }
// }
function* getComments(action) {
  try {

    // 期間設定
    const start_time = new Date(action.custom_span_start_time.seconds * 1000);
    const end_time = new Date(action.custom_span_end_time.seconds * 1000);
    // 当月
    const thisMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const month = thisMonth.getMonth();

    // 昨月
    const lastMonth = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 1));

    // 翌月
    const nextMonth = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month + 1));

    // 一昨月
    const lastLastMonth = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 2));

    // 6ヶ月前
    const last6Months = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 5));

    // 12ヶ月前
    const last12Months = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 11));


    if (action.selected_data_config_data_span === 'custom') {
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${action.company_id}/comments`)
          .where("result_span", "==", action.selected_data_config_data_type)
          .where("result_at", ">=", start_time)
          .where("result_at", "<=", end_time)
          .where("target_data_name", "==", action.target_data_name)
        );

      let comments = []
      let docIds = []
      snapshot.forEach((comment) => {
        comments = [...comments, { ...comment.data(), docId: comment.id }]
        docIds = [...docIds, comment.id] // ドキュメントIDの配列
      })
      yield put({ type: GET_COMMENTS_SUCCEEDED, comments: comments })

    } else if (action.selected_data_config_data_span === 'this_month') {
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${action.company_id}/comments`)
          .where("result_span", "==", action.selected_data_config_data_type)
          .where("result_at", ">=", lastMonth)
          .where("result_at", "<=", nextMonth)
          .where("target_data_name", "==", action.target_data_name)
        );

      let comments = []
      let docIds = []
      snapshot.forEach((comment) => {
        comments = [...comments, { ...comment.data(), docId: comment.id }]
        docIds = [...docIds, comment.id] // ドキュメントIDの配列
      })
      yield put({ type: GET_COMMENTS_SUCCEEDED, comments: comments })

    } else if (action.selected_data_config_data_span === 'last_month') {
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${action.company_id}/comments`)
          .where("result_span", "==", action.selected_data_config_data_type)
          .where("result_at", ">=", lastLastMonth)
          .where("result_at", "<=", thisMonth)
          .where("target_data_name", "==", action.target_data_name)
        );

      let comments = []
      let docIds = []
      snapshot.forEach((comment) => {
        comments = [...comments, { ...comment.data(), docId: comment.id }]
        docIds = [...docIds, comment.id] // ドキュメントIDの配列
      })
      yield put({ type: GET_COMMENTS_SUCCEEDED, comments: comments })

    } else if (action.selected_data_config_data_span === 'last_6_months') {
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${action.company_id}/comments`)
          .where("result_span", "==", action.selected_data_config_data_type)
          .where("result_at", ">=", last6Months)
          .where("result_at", "<=", thisMonth)
          .where("target_data_name", "==", action.target_data_name)
        );

      let comments = []
      let docIds = []
      snapshot.forEach((comment) => {
        comments = [...comments, { ...comment.data(), docId: comment.id }]
        docIds = [...docIds, comment.id] // ドキュメントIDの配列
      })
      yield put({ type: GET_COMMENTS_SUCCEEDED, comments: comments })

    } else if (action.selected_data_config_data_span === 'last_6_months') {
    } else if (action.selected_data_config_data_span === 'last_12_months') {
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${action.company_id}/comments`)
          .where("result_span", "==", action.selected_data_config_data_type)
          .where("result_at", ">=", last12Months)
          .where("result_at", "<=", thisMonth)
          .where("target_data_name", "==", action.target_data_name)
        );

      let comments = []
      let docIds = []
      snapshot.forEach((comment) => {
        comments = [...comments, { ...comment.data(), docId: comment.id }]
        docIds = [...docIds, comment.id] // ドキュメントIDの配列
      })
      yield put({ type: GET_COMMENTS_SUCCEEDED, comments: comments })

    } else {
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${action.company_id}/comments`)
          .where("result_span", "==", action.selected_data_config_data_type)
          .where("result_at", ">=", start_time)
          .where("result_at", "<=", end_time)
          .where("target_data_name", "==", action.target_data_name)
        );

      let comments = []
      let docIds = []
      snapshot.forEach((comment) => {
        comments = [...comments, { ...comment.data(), docId: comment.id }]
        docIds = [...docIds, comment.id] // ドキュメントIDの配列
      })
      yield put({ type: GET_COMMENTS_SUCCEEDED, comments: comments })
    }

  } catch (e) {
    yield put({ type: GET_COMMENTS_FAILED, message: e.message })
  }
}

const commentsSagas = [takeLatest(ADD_COMMENT_REQUESTED, addComment), takeLatest(UPDATE_COMMENT_REQUESTED, updateComment), takeLatest(GET_COMMENTS_REQUESTED, getComments), takeLatest(DELETE_COMMENT_REQUESTED, deleteComment)]

export default commentsSagas
