import { UPDATE_DOWNLOAD_GRAPH_STATUS, UPDATE_DOWNLOAD_CSV_TARGET, UPDATE_DOWNLOAD_TAB_NAME } from "./types";
import { RESET_ALL } from "../common/types";

const initialState = {
	downloadGraphStatus: 0,
  updateDownloadCsvTarget: '',
  updateDownloadTabName: ''
};

const downloadReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_ALL:
      return initialState;

    case UPDATE_DOWNLOAD_GRAPH_STATUS:
      return {
        ...state,
        downloadGraphStatus : action.downloadGraphStatus
      }

    case UPDATE_DOWNLOAD_CSV_TARGET:
      return {
        ...state,
        updateDownloadCsvTarget : action.updateDownloadCsvTarget
      }

    case UPDATE_DOWNLOAD_TAB_NAME:
    return {
      ...state,
      updateDownloadTabName : action.updateDownloadTabName
    }

    default:
      return state;
  }
};

export default downloadReducer;
