import rsf from "../../rsf"
import firebase from "firebase"
import { call } from "redux-saga/effects"

// ユーザーの所属するcompany情報を取得
function* fetchCompanyFromFirestore(company_id) {
  try {
    const snapshot = yield call(rsf.firestore.getDocument, `companies/${company_id}`)
    const company = snapshot.data()
    return company
  } catch (e) {
    console.log("Company fetch failed", e.message)
  }
}

export default fetchCompanyFromFirestore;