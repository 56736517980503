import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Button,
	createStyles,
	IconButton,
	makeStyles,
} from "@material-ui/core";
import { update_displayType } from "../../reducks/graph/actions";

import SVG_area_icon_active from "../../images/icons/area_icon_active.svg";
import SVG_area_icon from "../../images/icons/area_icon.svg";
import SVG_bar_icon_active from "../../images/icons/bar_icon_active.svg";
import SVG_bar_icon from "../../images/icons/bar_icon.svg";

/*-*-*-*-* GraphSwicher *-*-*-*-*/
interface GraphSwicherProps {}
const GraphSwicher: React.FC<GraphSwicherProps> = (props) => {
	const dispatch = useDispatch();
	const displayType = useSelector((state: any) => state.graph.displayType);
	const classes = makeStyles((theme) =>
		createStyles({
			wrapper: {
				flexGrow: 1,
				display: "flex",
				flexDirection: "row",
				justifyContent: "flex-end",
			},
			btn: {
				padding: theme.spacing(1, 2),
				margin: theme.spacing(0, 1),
			},
		})
	)();

	const handleOnClick = (displayType: "line" | "bar") => {
		dispatch(update_displayType(displayType));
	};

	return (
		<div className={classes.wrapper}>
			<Button
				variant="outlined"
				size="small"
				className={classes.btn}
				onClick={() => handleOnClick("line")}
			>
				<img
					src={displayType === "line" ? SVG_area_icon_active : SVG_area_icon}
					alt=""
				/>
			</Button>
			<Button
				variant="outlined"
				size="small"
				className={classes.btn}
				onClick={() => handleOnClick("bar")}
			>
				<img
					src={displayType === "bar" ? SVG_bar_icon_active : SVG_bar_icon}
					alt=""
				/>
			</Button>
		</div>
	);
};

export default GraphSwicher;
