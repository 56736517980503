import update from "immutability-helper";
import { SET_TARGET_FORECAST_REVENUE_PROFIT_ARRAY, SET_TARGET_FORECAST_FUNNEL_ARRAY, SET_TARGET_FORECAST_METRICS_ARRAY, GET_TARGET_FORECAST_REVENUE_PROFIT_DETAIL_SUCCEEDED, GET_TARGET_FORECAST_REVENUE_PROFIT_DETAIL_FAILED, UPDATE_FORECAST_METRICS_DATA, UPDATE_FORECAST_FUNNEL_DATA, UPDATE_FORECAST_PL_DATA, GET_REVENUE_DETAIL_SUCCEEDED, GET_RESULTS_FOR_TARGET_FORECAST_SUCCEEDED, GET_RESULTS_FOR_TARGET_FORECAST_FAILED, UPDATE_NEW_FORECAST_SIMULATED_RESULT, SET_FORECAST_FILTER, RESET_FORECAST_FILTER, SET_FORECAST_FILTER_STATUS, GET_RESULTS_FOR_SPAN_COMPARISON_FORECASTS_SUCCEEDED, GET_RESULTS_FOR_SPAN_COMPARISON_FORECASTS_FAILED, SET_FORECAST_CUSTOM_SPAN, GET_INTEGRATED_ARRAY_FORECAST_SUCCEEDED, GET_INTEGRATED_ARRAY_FORECAST_FAILED, SET_PL_GROSS_PROFIT_OBJECTIVE, SET_PL_OPERATING_PROFIT_OBJECTIVE,UPDATE_REVENUE_DETAIL_DATA, UPDATE_REVENUE_TOTAL_DATA ,UPDATE_UNIT_COST_CAT_2_DETAIL_DATA, UPDATE_SVG_COST_CAT_2_DETAIL_DATA, UPDATE_UNIT_COST_CAT_3_DETAIL_DATA, UPDATE_SVG_COST_CAT_3_DETAIL_DATA, UPDATE_UNIT_COST_CAT_2_TOTAL_DATA, UPDATE_SVG_COST_CAT_2_TOTAL_DATA, UPDATE_UNIT_COST_TOTAL_DATA, UPDATE_SVG_COST_TOTAL_DATA, UPDATE_PL_GROSS_PROFIT_TOTAL_FORECASTS, UPDATE_PL_OPERATING_PROFIT_TOTAL_FORECASTS, REMOVE_FUTURE_DATA_FROM_INTEGRATED_DATA, UPDATE_REVENUE_TOTAL_FORECAST_DATA, UPDATE_PL_GROSS_PROFIT_TOTAL, UPDATE_PL_OPERATING_PROFIT_TOTAL,HANDLE_UPDATE_REVENUE_DETAIL_TOTAL, HANDLE_UPDATE_COST_CAT_2, HANDLE_UPDATE_COST_CAT_3, } from "./types";
import {RESET_ALL} from "../common/types";
import { forecastErrorEscaper } from "../../components/Util/dataErrorEscaper";

const initialState: any = {
  target_forecast_revenue_profit: [],
  target_forecast_funnel_array: [],
  target_forecast_pl_array: [],
  target_forecast_pl_detail_array: [],
  target_forecast_metrics_array: [],
  target_data_name: "",
  selected_span: "this_month",
  target_data_first_span: [],
  target_data_second_span: [],
  integrated_array: [],
  // 当月
  first_span_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime(),

  // 3ヶ月後
  first_span_end: new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(new Date(new Date().getFullYear(), new Date().getMonth(), 1).getMonth() + 2)).getTime(),

  // 昨年同月
  second_span_start: new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(new Date(new Date().getFullYear(), new Date().getMonth(), 1).getMonth() - 12)).getTime(),

  // 昨年同月から3ヶ月後
  second_span_end: new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(new Date(new Date().getFullYear(), new Date().getMonth(), 1).getMonth() - 10)).getTime(),
  resultSpan: "monthly",
  result_data_type: "",
  result_data_detail_type: "",
  resultMonthly: [],
  resultWeekly: [],
  resultDaily: [],
  detailedResults: [],
  forecastResult: {
    resultMonthly: [],
    resultWeekly: [],
    resultDaily: []
  },
  forecast_filter_status: "reset_forecast",
  // future_forecast_integrated_array: [],
  future_forecast_metrics_array: [],
  future_forecast_funnel_array: [],
  selected_data_config: {
    custom_span_start_time: new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(new Date(new Date().getFullYear(), new Date().getMonth(), 1).getMonth() - 11)).getTime() / 1000, // 12ヶ月前
    // custom_span_start_time: new Date(), // 12ヶ月前
    custom_span_end_time: new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() / 1000, // 今月
    // custom_span_end_time: new Date() // 今月,
    forecast_base_span: 12
  }
};

const targetDataReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_ALL:
      return initialState;

    case SET_TARGET_FORECAST_REVENUE_PROFIT_ARRAY:
      const graphDateList = action.raw_data_array.map((result: any) => {
        let dateInGraph = result.result_at;
        return {
          result_at: dateInGraph
        };
      });

      const graphRevenueList = action.raw_data_array
        .flatMap((rd: any) => rd.revenue)
        .map((rd: any) => {
          return {
            revenue_total: rd.revenue_total,
            revenue_objective: rd.revenue_objective,
            revenue_rate: rd.revenue_rate,
            revenue_difference: rd.revenue_difference,
            revenue_last_year_total: rd.revenue_last_year_total,
            revenue_last_year_objective: rd.revenue_last_year_objective,
            revenue_last_year_comparison_total: rd.revenue_last_year_comparison_total,
            revenue_last_year_comparison_rate: rd.revenue_last_year_comparison_rate
          };
        });

      const graphProfitList = action.raw_data_array
        .flatMap((rd: any) => rd.profit_operating_profit)
        .map((rd: any) => {
          return {
            profit_operating_profit_total: rd.profit_operating_profit_total,
            profit_operating_profit_objective: rd.profit_operating_profit_objective,
            profit_operating_profit_rate: rd.profit_operating_profit_rate,
            profit_operating_profit_difference: rd.profit_operating_profit_difference,
            profit_operating_profit_last_year_total: rd.profit_operating_profit_last_year_total,
            profit_operating_profit_last_year_objective: rd.profit_operating_profit_last_year_objective,
            profit_operating_profit_last_year_comparison_total: rd.profit_operating_profit_last_year_comparison_total,
            profit_operating_profit_last_year_comparison_rate: rd.profit_operating_profit_last_year_comparison_rate
          };
        });

      const dataGrossProfitList = action.raw_data_array
        .flatMap((rd: any) => rd.profit_gross_profit)
        .map((rd: any) => {
          return {
            profit_gross_profit_total: rd.profit_gross_profit_total,
            profit_gross_profit_objective: rd.profit_gross_profit_objective,
            profit_gross_profit_rate: rd.profit_gross_profit_rate,
            profit_gross_profit_difference: rd.profit_gross_profit_difference,
            profit_gross_profit_last_year_total: rd.profit_gross_profit_last_year_total,
            profit_gross_profit_last_year_objective: rd.profit_gross_profit_last_year_objective,
            profit_gross_profit_last_year_comparison_total: rd.profit_gross_profit_last_year_comparison_total,
            profit_gross_profit_last_year_comparison_rate: rd.profit_gross_profit_last_year_comparison_rate
          };
        });

      const dataSvgCostList = action.raw_data_array
        .flatMap((rd: any) => rd.cost_svg_cost)
        .map((rd: any) => {
          return {
            cost_svg_cost_total: rd.cost_svg_cost_total,
            cost_svg_cost_objective: rd.cost_svg_cost_objective,
            cost_svg_cost_rate: rd.cost_svg_cost_rate,
            cost_svg_cost_difference: rd.cost_svg_cost_difference,
            cost_svg_cost_last_year_total: rd.cost_svg_cost_last_year_total,
            cost_svg_cost_last_year_objective: rd.cost_svg_cost_last_year_objective,
            cost_svg_cost_last_year_comparison_total: rd.cost_svg_cost_last_year_comparison_total,
            cost_svg_cost_last_year_comparison_rate: rd.cost_svg_cost_last_year_comparison_rate
          };
        });

      const dataUnitCostList = action.raw_data_array
        .flatMap((rd: any) => rd.cost_unit_cost)
        .map((rd: any) => {
          return {
            cost_unit_cost_total: rd.cost_unit_cost_total,
            cost_unit_cost_objective: rd.cost_unit_cost_objective,
            cost_unit_cost_rate: rd.cost_unit_cost_rate,
            cost_unit_cost_difference: rd.cost_unit_cost_difference,
            cost_unit_cost_last_year_total: rd.cost_unit_cost_last_year_total,
            cost_unit_cost_last_year_objective: rd.cost_unit_cost_last_year_objective,
            cost_unit_cost_last_year_comparison_total: rd.cost_unit_cost_last_year_comparison_total,
            cost_unit_cost_last_year_comparison_rate: rd.cost_unit_cost_last_year_comparison_rate
          };
        });

      const graphDateRevenueList = graphDateList.map((data: any, index: number) => {
        return { ...data, ...graphRevenueList[index] };
      });

      const graphDateRevenueProfitList = graphDateRevenueList.map((data: any, index: number) => {
        return { ...data, ...graphProfitList[index] };
      });

      const target_forecast_pl_array = graphDateRevenueProfitList
        .map((data: any, index: number) => {
          return { ...data, ...dataUnitCostList[index] };
        })
        .map((data: any, index: number) => {
          return { ...data, ...dataSvgCostList[index] };
        })
        .map((data: any, index: number) => {
          return { ...data, ...dataGrossProfitList[index] };
        });

      const plTableData = graphDateRevenueProfitList.concat();

      return {
        ...state,
        target_forecast_revenue_profit: graphDateRevenueProfitList,
        target_forecast_pl_array: target_forecast_pl_array
      };

    case SET_TARGET_FORECAST_METRICS_ARRAY:
      const graphDateListForForecastMetricsArray = action.raw_data_array.map((result: any) => {
        let dateInGraph = result.result_at;
        return {
          result_at: dateInGraph
        };
      });

      const mrr_total_value_array = action.raw_data_array.flatMap((rd: any) => rd.mrr_total_value);
      const revenue_array = action.raw_data_array.flatMap((rd: any) => rd.revenue);
      const arpu_array = action.raw_data_array.flatMap((rd: any) => rd.arpu);
      const unit_economics_array = action.raw_data_array.flatMap((rd: any) => rd.unit_economics);
      const payback_period_array = action.raw_data_array.flatMap((rd: any) => rd.payback_period);
      const churn_rate_array = action.raw_data_array.flatMap((rd: any) => rd.churn_rate);
      const cac_array = action.raw_data_array.flatMap((rd: any) => rd.cac);
      const ltv_array = action.raw_data_array.flatMap((rd: any) => rd.ltv);
      const conversion_rate_array = action.raw_data_array.flatMap((rd: any) => rd.conversion_rate);
      const nrr_array = action.raw_data_array.flatMap((rd: any) => rd.nrr);
      const grr_array = action.raw_data_array.flatMap((rd: any) => rd.grr);
      const quick_ratio_array = action.raw_data_array.flatMap((rd: any) => rd.quick_ratio);
      const acv_cumulative_array = action.raw_data_array.flatMap((rd: any) => rd.acv_cumulative);
      const acv_new_array = action.raw_data_array.flatMap((rd: any) => rd.acv_new);
      const cpa_array = action.raw_data_array.flatMap((rd: any) => rd.cpa);

      const target_forecast_metrics_array = graphDateListForForecastMetricsArray.map((data: any, index: number) => {
        return { ...data, ...mrr_total_value_array[index], ...revenue_array[index], ...arpu_array[index], ...unit_economics_array[index], ...payback_period_array[index], ...churn_rate_array[index], ...cac_array[index], ...ltv_array[index], ...conversion_rate_array[index], ...nrr_array[index], ...grr_array[index], ...quick_ratio_array[index], ...acv_cumulative_array[index], ...acv_new_array[index], ...cpa_array[index] };
      });

      return {
        ...state,
        target_forecast_metrics_array: target_forecast_metrics_array
      };

    // case SET_TARGET_FORECAST_FUNNEL_ARRAY:
    //   const graphDateListForFunnelArray = action.raw_data_array.map((result: any) => {
    //     let dateInGraph = result.result_at;
    //     return {
    //       result_at: dateInGraph
    //     };
    //   });

    //   // result内lead系データに関してのエスケープ処理と配列化処理
    //   const resultEscapeArrayGenerator = (result: any, lc: string, target_key: string) => {
    //     let target_lead_array;
    //     if (result && result[lc] && result[lc].length > 0) {
    //       target_lead_array = result[lc].map((r: any) => r[target_key]);
    //     } else {
    //       target_lead_array = [];
    //     }
    //     return target_lead_array;
    //   }

    //   const target_forecast_funnel_pre_array = action.raw_data_array.map((result: any, index: number) => {
    //     // raw_data_arrayから必要なデータ配列を抜き出し
    //     // ファネル系
    //     // const lead_new_count_total_array = result.lead_new_count.map((rd: any) => rd.lead_new_count_total);
    //     const lead_new_count_total_array = resultEscapeArrayGenerator(result, 'lead_new_count', 'lead_new_count_total')

    //     // const lead_new_count_rate_array = result.lead_new_count.map((rd: any) => rd.lead_new_count_rate);
    //     const lead_new_count_rate_array = resultEscapeArrayGenerator(result, 'lead_new_count', 'lead_new_count_rate')

    //     // const lead_new_count_objective_array = result.lead_new_count.map((rd: any) => rd.lead_new_count_objective);
    //     const lead_new_count_objective_array = resultEscapeArrayGenerator(result, 'lead_new_count', 'lead_new_count_objective')

    //     // const lead_new_count_difference_array = result.lead_new_count.map((rd: any) => rd.lead_new_count_difference);
    //     const lead_new_count_difference_array = resultEscapeArrayGenerator(result, 'lead_new_count', 'lead_new_count_difference')

    //     // const lead_new_count_last_year_comparison_rate_array = result.lead_new_count.map((rd: any) => rd.lead_new_count_last_year_comparison_rate);
    //     const lead_new_count_last_year_comparison_rate_array = resultEscapeArrayGenerator(result, 'lead_new_count', 'lead_new_count_last_year_comparison_rate')

    //     // ファネル移行率系
    //     // const lead_transition_rate_total_array = result.lead_transition_rate.map((rd: any) => rd.lead_transition_rate_total);
    //     const lead_transition_rate_total_array = resultEscapeArrayGenerator(result, 'lead_transition_rate', 'lead_transition_rate_total')

    //     // const lead_transition_rate_rate_array = result.lead_transition_rate.map((rd: any) => rd.lead_transition_rate_rate);
    //     const lead_transition_rate_rate_array = resultEscapeArrayGenerator(result, 'lead_transition_rate', 'lead_transition_rate_rate')

    //     // const lead_transition_rate_objective_array = result.lead_transition_rate.map((rd: any) => rd.lead_transition_rate_objective);
    //     const lead_transition_rate_objective_array = resultEscapeArrayGenerator(result, 'lead_transition_rate', 'lead_transition_rate_objective')

    //     // const lead_transition_rate_difference_array = result.lead_transition_rate.map((rd: any) => rd.lead_transition_rate_difference);
    //     const lead_transition_rate_difference_array = resultEscapeArrayGenerator(result, 'lead_transition_rate', 'lead_transition_rate_difference')

    //     // const lead_transition_rate_last_year_comparison_rate_array = result.lead_transition_rate.map((rd: any) => rd.lead_transition_rate_last_year_comparison_rate);
    //     const lead_transition_rate_last_year_comparison_rate_array = resultEscapeArrayGenerator(result, 'lead_transition_rate', 'lead_transition_rate_last_year_comparison_rate')

    //     // ファネル系キーを作成
    //     const total_key = action.funnel_names.map((name: any) => name + "_total");

    //     const rate_key = action.funnel_names.map((name: any) => name + "_rate");

    //     const objective_key = action.funnel_names.map((name: any) => name + "_objective");

    //     const difference_key = action.funnel_names.map((name: any) => name + "_difference");

    //     const last_year_comparison_rate_key = action.funnel_names.map((name: any) => name + "_last_year_comparison_rate");

    //     // ファネル達成率系キーを作成
    //     const transition_rate_total_key = action.funnel_names.map((name: any) => name + "_transition_rate_total");

    //     const transition_rate_rate_key = action.funnel_names.map((name: any) => name + "_transition_rate_rate");

    //     const transition_rate_objective_key = action.funnel_names.map((name: any) => name + "_transition_rate_objective");

    //     const transition_rate_difference_key = action.funnel_names.map((name: any) => name + "_transition_rate_difference");

    //     const transition_rate_last_year_comparison_rate_key = action.funnel_names.map((name: any) => name + "_transition_rate_last_year_comparison_rate");

    //     // 結合するオブジェクトを作成：ファネル系
    //     const total_objects: any = {};
    //     total_key.forEach((key: any, index: number) => {
    //       total_objects[key] = lead_new_count_total_array[index]
    //     });

    //     const rate_objects: any = {};
    //     rate_key.forEach((key: any, index: number) => {
    //       rate_objects[key] = lead_new_count_rate_array[index];
    //     });

    //     const objective_objects: any = {};
    //     objective_key.forEach((key: any, index: number) => {
    //       objective_objects[key] = lead_new_count_objective_array[index];
    //     });

    //     const difference_objects: any = {};
    //     difference_key.forEach((key: any, index: number) => {
    //       difference_objects[key] = lead_new_count_difference_array[index];
    //     });

    //     const last_year_comparison_rate_objects: any = {};
    //     last_year_comparison_rate_key.forEach((key: any, index: number) => {
    //       last_year_comparison_rate_objects[key] = lead_new_count_last_year_comparison_rate_array[index];
    //     });

    //     // 結合するオブジェクトを作成：ファネル達成率系
    //     const transition_rate_total_objects: any = {};
    //     transition_rate_total_key.forEach((key: any, index: number) => {
    //       transition_rate_total_objects[key] = lead_transition_rate_total_array[index];
    //     });

    //     const transition_rate_rate_objects: any = {};
    //     transition_rate_rate_key.forEach((key: any, index: number) => {
    //       transition_rate_rate_objects[key] = lead_transition_rate_rate_array[index];
    //     });

    //     const transition_rate_objective_objects: any = {};
    //     transition_rate_objective_key.forEach((key: any, index: number) => {
    //       transition_rate_objective_objects[key] = lead_transition_rate_objective_array[index];
    //     });

    //     const transition_rate_difference_objects: any = {};
    //     transition_rate_difference_key.forEach((key: any, index: number) => {
    //       transition_rate_difference_objects[key] = lead_transition_rate_difference_array[index];
    //     });

    //     const transition_rate_last_year_comparison_rate_objects: any = {};
    //     transition_rate_last_year_comparison_rate_key.forEach((key: any, index: number) => {
    //       transition_rate_last_year_comparison_rate_objects[key] = lead_transition_rate_last_year_comparison_rate_array[index];
    //     });

    //     const marketingFunnelsData = { ...total_objects, ...rate_objects, ...objective_objects, ...difference_objects, ...last_year_comparison_rate_objects, ...transition_rate_total_objects, ...transition_rate_rate_objects, ...transition_rate_objective_objects, ...transition_rate_difference_objects, ...transition_rate_last_year_comparison_rate_objects };
    //     return marketingFunnelsData;
    //   });

    //   // const cpa_array = action.raw_data_array.flatMap((rd: any) => rd.cpa)

    //   const target_forecast_funnel_array = graphDateListForFunnelArray.map((data: any, index: number) => {
    //     return { ...data, ...target_forecast_funnel_pre_array[index] };
    //   });
    //   return {
    //     ...state,
    //     target_forecast_funnel_array: target_forecast_funnel_array
    //   };

    case SET_TARGET_FORECAST_FUNNEL_ARRAY:

      // result内lead系データに関してのエスケープ処理と配列化処理
      const escaper = (value: any) => {
        if (value !== null && value !== undefined && value !== NaN && value !== Infinity) {
          return value
        }
        return 0;
      }

      const target_forecast_funnel_array = action.raw_data_array.map((result: any) => {
        const lead_new_count_and_transition_rate_object_array = action.funnel_names.map((name: any) => {
          const total_key = name + "_lead_new_count_total";
          const rate_key = name + "_lead_new_count_rate";
          const objective_key = name + "_lead_new_count_objective";
          const difference_key = name + "_lead_new_count_difference";
          const last_year_comparison_rate_key = name + "_lead_new_count_last_year_comparison_rate";
          const transition_rate_total_key = name + "_transition_rate_total";
          const transition_rate_rate_key = name + "_transition_rate_rate";
          const transition_rate_objective_key = name + "_transition_rate_objective";
          const transition_rate_difference_key = name + "_transition_rate_difference";
          const transition_rate_last_year_comparison_rate_key = name + "_transition_rate_last_year_comparison_rate";
          return {
            [total_key]: result.lead_new_count ? escaper(result.lead_new_count.find((lc: any) => lc.funnel_name === name).lead_new_count_total) : 0,
            [rate_key]: result.lead_new_count ? escaper(result.lead_new_count.find((lc: any) => lc.funnel_name === name).lead_new_count_rate) : 0,
            [objective_key]: result.lead_new_count ? escaper(result.lead_new_count.find((lc: any) => lc.funnel_name === name).lead_new_count_objective) : 0,
            [difference_key]: result.lead_new_count ? escaper(result.lead_new_count.find((lc: any) => lc.funnel_name === name).lead_new_count_difference) : 0,
            [last_year_comparison_rate_key]: result.lead_new_count ? escaper(result.lead_new_count.find((lc: any) => lc.funnel_name === name).lead_new_count_last_year_comparison_rate) : 0,
            [transition_rate_total_key]: result.lead_transition_rate ? escaper(result.lead_transition_rate.find((lc: any) => lc.funnel_name === name).lead_transition_rate_total) : 0,
            [transition_rate_rate_key]: result.lead_transition_rate ? escaper(result.lead_transition_rate.find((lc: any) => lc.funnel_name === name).lead_transition_rate_rate) : 0,
            [transition_rate_objective_key]: result.lead_transition_rate ? escaper(result.lead_transition_rate.find((lc: any) => lc.funnel_name === name).lead_transition_rate_objective) : 0,
            [transition_rate_difference_key]: result.lead_transition_rate ? escaper(result.lead_transition_rate.find((lc: any) => lc.funnel_name === name).lead_transition_rate_difference) : 0,
            [transition_rate_last_year_comparison_rate_key]: result.lead_transition_rate ? escaper(result.lead_transition_rate.find((lc: any) => lc.funnel_name === name).lead_transition_rate_last_year_comparison_rate) : 0,
          }
        });


        const new_obj = lead_new_count_and_transition_rate_object_array.reduce(function(result: any, current: any) {
          return Object.assign(result, current);
        }, {});

        return {
          result_at: result.result_at,
          ...new_obj,
        }
      })
      return {
        ...state,
        target_forecast_funnel_array: target_forecast_funnel_array
      };


    case GET_TARGET_FORECAST_REVENUE_PROFIT_DETAIL_FAILED:
      return state;

    case UPDATE_FORECAST_PL_DATA:
      return {
        ...state,
        integrated_array: state.integrated_array.map((item: any, index: number) => {
          if (index !== action.handleSimulatedObject.target_index) {
            return item;
          }
          return {
            ...item,
            ...action.handleSimulatedObject.updated_target_data
          };
        })
      };

    case UPDATE_FORECAST_METRICS_DATA:
      return {
        ...state,
        target_forecast_metrics_array: state.target_forecast_metrics_array.map((item: any, index: number) => {
          if (index !== action.handleSimulatedObject.target_index) {
            return item;
          }
          return {
            ...item,
            ...action.handleSimulatedObject.updated_target_data
          };
        })
      };

    case UPDATE_FORECAST_FUNNEL_DATA:
      return {
        ...state,
        target_forecast_funnel_array: state.target_forecast_funnel_array.map((item: any, index: number) => {
          if (index !== action.handleSimulatedObject.target_index) {
            return item;
          }
          return {
            ...item,
            ...action.handleSimulatedObject.updated_target_data
          };
        })
      };

    case UPDATE_REVENUE_DETAIL_DATA:
      return{
        ...state,
        integrated_array: state.integrated_array.map((item: any, index: number) => {
          if( item.result_at.seconds !== action.result_at_seconds) {
            return item
          }
          return {
            ...item,
            revenue_detail: item.revenue_detail.map((detail: any, index: number) => {
              if( detail.name !== action.product_name ) {
                return detail
              }
              return {
                ...detail,
                revenue_total: action.updated_data
              }
            })
          }
        })
      }

    case UPDATE_UNIT_COST_CAT_2_DETAIL_DATA:
      return{
        ...state,
        integrated_array: state.integrated_array.map((item: any, index: number) => {
          if( item.result_at.seconds !== action.result_at_seconds) {
            return item
          }
          return {
            ...item,
            unit_cost_cat_2_array: item.unit_cost_cat_2_array.map((cat_2_detail: any, index: number) => {
              if( cat_2_detail.unit_cost_cat_2_name !== action.cat_2_name ) {
                return cat_2_detail
              }
              return {
                ...cat_2_detail,
                unit_cost_cat_2_total: action.updated_data
              }
            })
          }
        })
      }

      case UPDATE_UNIT_COST_CAT_3_DETAIL_DATA:
        return{
          ...state,
          integrated_array: state.integrated_array.map((item: any, index: number) => {
            //日付でデータを上書きする配列を選択
            if( item.result_at.seconds !== action.result_at_seconds) {
              return item
            }
            return {
              ...item,
              unit_cost_cat_2_array: item.unit_cost_cat_2_array.map((cat_2_detail: any, index: number) => {
                if( cat_2_detail.unit_cost_cat_2_name !== action.cat_2_name ) {
                  return cat_2_detail
                }
                //カテゴリー２の名前が同じやつの・・・
                return {
                  ...cat_2_detail,
                  unit_cost_cat_3_array: cat_2_detail.unit_cost_cat_3_array.map((cat_3_detail: any, cat_3_index: number) => {
                    if(cat_3_detail.unit_cost_cat_3_name !== action.cat_3_name) {
                      return cat_3_detail
                    }
                    //同じカテゴリー３の名前を持つトータルを上書きする。
                    return {
                      ...cat_3_detail,
                      unit_cost_cat_3_total: action.updated_data
                    }
                  })
                }
              })
            }
          })
        }

    case UPDATE_SVG_COST_CAT_2_DETAIL_DATA:
      return{
        ...state,
        integrated_array: state.integrated_array.map((item: any, index: number) => {
          if( item.result_at.seconds !== action.result_at_seconds) {
            return item
          }
          return {
            ...item,
            svg_cost_cat_2_array: item.svg_cost_cat_2_array.map((cat_2_detail: any, index: number) => {
              if( cat_2_detail.svg_cost_cat_2_name !== action.cat_2_name ) {
                return cat_2_detail
              }
              return {
                ...cat_2_detail,
                svg_cost_cat_2_total: action.updated_data
              }
            })
          }
        })
      }

    case UPDATE_SVG_COST_CAT_3_DETAIL_DATA:
      return{
        ...state,
        integrated_array: state.integrated_array.map((item: any, index: number) => {
          //日付でデータを上書きする配列を選択
          if( item.result_at.seconds !== action.result_at_seconds) {
            return item
          }
          return {
            ...item,
            svg_cost_cat_2_array: item.svg_cost_cat_2_array.map((cat_2_detail: any, index: number) => {
              if( cat_2_detail.svg_cost_cat_2_name !== action.cat_2_name ) {
                return cat_2_detail
              }
              //カテゴリー２の名前が同じやつの・・・
              return {
                ...cat_2_detail,
                svg_cost_cat_3_array: cat_2_detail.svg_cost_cat_3_array.map((cat_3_detail: any, cat_3_index: number) => {
                  if(cat_3_detail.svg_cost_cat_3_name !== action.cat_3_name) {
                    return cat_3_detail
                  }
                  //同じカテゴリー３の名前を持つトータルを上書きする。
                  return {
                    ...cat_3_detail,
                    svg_cost_cat_3_total: action.updated_data
                  }
                })
              }
            })
          }
        })
      }
    
    case UPDATE_REVENUE_TOTAL_DATA:
      return {
        ...state,
        integrated_array: state.integrated_array.map((item: any, index: number) => {
          if(item.result_at.seconds !== action.result_at_seconds) {
            return item
          }
          return {
            ...item,
            revenue_total: action.new_revenue_total
          }
        })
      }

    case UPDATE_REVENUE_TOTAL_FORECAST_DATA:
      return {
        ...state,
        integrated_array: state.integrated_array.map((item: any, index: number) => {
          if(item.result_at.seconds !== action.result_at_seconds) {
            return item
          }
          return {
            ...item,
            revenue_total_forecasted: action.new_revenue_total
          }
        })
      }

      case HANDLE_UPDATE_COST_CAT_2:
        //コストトータルを更新してから、利益を更新したい
      return {
        ...state,
        integrated_array: state.integrated_array.map((item: any, index: number) => {
          if (index !== action.handleSimulatedObject.target_index) {
            return item;
          }
          return {
            ...item,
            ...action.handleSimulatedObject.updated_target_data
          };
        })
      };

      case HANDLE_UPDATE_COST_CAT_3:
        //コストトータルを更新してから、利益を更新したい
        return {
          ...state,
          integrated_array: state.integrated_array.map((item: any, index: number) => {
            if (index !== action.handleSimulatedObject.target_index) {
              return item;
            }
            return {
              ...item,
              ...action.handleSimulatedObject.updated_target_data
            };
          })
        };

      case UPDATE_UNIT_COST_TOTAL_DATA:
        return {
          ...state,
          integrated_array: state.integrated_array.map((item: any, index: number) => {
            if(item.result_at.seconds !== action.result_at_seconds) {
              return item
            } else {
              const new_cost_total= item.unit_cost_cat_2_array.reduce((acc: any, cur: any) => acc + cur.unit_cost_cat_2_total, 0)
              return {
                ...item,
                unit_cost_cat_1_total: new_cost_total
              }
            }
          })
        }

      case UPDATE_SVG_COST_TOTAL_DATA:
        return {
          ...state,
          integrated_array: state.integrated_array.map((item: any, index: number) => {
            if(item.result_at.seconds !== action.result_at_seconds) {
              return item
            } else {
              //cat_2の合計を出す。
              const new_cost_total= item.svg_cost_cat_2_array.reduce((acc: any, cur: any) => acc + cur.svg_cost_cat_2_total, 0)
              if(new_cost_total === null) {
                return {
                  ...item,
                  // cat_2の合計をcost_totalに入れる
                  cost_svg_cost_total: 0
                }
              } else {
                return {
                  ...item,
                  // cat_2の合計をcost_totalに入れる
                  svg_cost_cat_1_total: new_cost_total
                }
              }
            }
          })
        }



      case UPDATE_SVG_COST_CAT_2_TOTAL_DATA:
        return {
          ...state,
          integrated_array: state.integrated_array.map((item: any, index: number) => {
            if(item.result_at.seconds !== action.result_at_seconds) {
              return item
            }
            return {
              ...item,
              svg_cost_cat_2_array: item.svg_cost_cat_2_array.map((cat_2_item:any) => {
                if(cat_2_item.svg_cost_cat_2_name !== action.cat_2_name) {
                  return cat_2_item
                }

                return {
                  ...cat_2_item,
                  svg_cost_cat_2_total : action.new_cat_2_cost_total
                }
              })
            }
          })
        }

      case UPDATE_UNIT_COST_CAT_2_TOTAL_DATA:
        return {
          ...state,
          integrated_array: state.integrated_array.map((item: any, index: number) => {
            if(item.result_at.seconds !== action.result_at_seconds) {
              return item
            }
            return {
              ...item,
              unit_cost_cat_2_array: item.unit_cost_cat_2_array.map((cat_2_item:any) => {
                if(cat_2_item.unit_cost_cat_2_name !== action.cat_2_name) {
                  return cat_2_item
                }
                return {
                  ...cat_2_item,
                  unit_cost_cat_2_total : action.new_cat_2_cost_total
                }
              })
            }
          })
        }

        case UPDATE_PL_GROSS_PROFIT_TOTAL:
          return {
            ...state,
            integrated_array:state.integrated_array.map((item: any) =>{
              if (item.result_at.seconds !== action.result_at_seconds) {
                return item
              }
              return {
                ...item,
                profit_gross_profit_total: action.calculated_gross_profit
              }
            })
          }

        case UPDATE_PL_GROSS_PROFIT_TOTAL_FORECASTS:
          return {
            ...state,
            integrated_array:state.integrated_array.map((item: any) =>{
              if (item.result_at.seconds !== action.result_at_seconds) {
                return item
              }
              return {
                ...item,
                profit_gross_profit_total_forecasted: action.calculated_gross_profit
              }
            })
          }
    
        case UPDATE_PL_OPERATING_PROFIT_TOTAL:
          return {
            ...state,
            integrated_array:state.integrated_array.map((item: any) =>{
              if (item.result_at.seconds !== action.result_at_seconds) {
                return item
              }
              return {
                ...item,
                profit_operating_profit_total: action.calculated_operating_profit
              }
            })
          }

        case UPDATE_PL_OPERATING_PROFIT_TOTAL_FORECASTS:
          return {
            ...state,
            integrated_array:state.integrated_array.map((item: any) =>{
              if (item.result_at.seconds !== action.result_at_seconds) {
                return item
              }
              return {
                ...item,
                profit_operating_profit_total_forecasted: action.calculated_operating_profit
              }
            })
          }



    case GET_REVENUE_DETAIL_SUCCEEDED:
      return {
        ...state,
        detailedResults: action.results
      };

    case GET_RESULTS_FOR_TARGET_FORECAST_SUCCEEDED:
      const forecast_base_span = action.forecast_base_span;
      const raw_data_array_past = action.raw_data_array_past;

      // =========================================================================
      // 各指標ごとの成長率を出す。前の月の値でそれぞれ割る。

      const zeroEscape = (this_month_data: any, last_month_data: any) => {
        let growth_rate_for_this_and_last_month;
        if (this_month_data === 0 && last_month_data !== 0) {
          growth_rate_for_this_and_last_month = last_month_data / last_month_data;
        } else if (this_month_data !== 0 && last_month_data === 0) {
          growth_rate_for_this_and_last_month = this_month_data / this_month_data;
        } else if (this_month_data === 0 && last_month_data === 0) {
          growth_rate_for_this_and_last_month = 1;
        } else {
          growth_rate_for_this_and_last_month = this_month_data / last_month_data;
        }
        return growth_rate_for_this_and_last_month;
      }

      console.log("heiheihei")

      const growth_rate_array = raw_data_array_past
        .map((item: any, index: number) => {
          if (index === 0) {
            // 前の月と比べるので最初の月はなし。
            return {
              revenue_total: 1,
              revenue_difference: 1,
              revenue_objective: 1,
              revenue_rate: 1,
              revenue_last_year_comparison_rate: 1,

              cost_unit_cost_total: 1,
              cost_unit_cost_difference: 1,
              cost_unit_cost_objective: 1,
              cost_unit_cost_rate: 1,
              cost_unit_cost_last_year_comparison_rate: 1,

              profit_gross_profit_total: 1,
              profit_gross_profit_difference: 1,
              profit_gross_profit_objective: 1,
              profit_gross_profit_rate: 1,
              profit_gross_profit_last_year_comparison_rate: 1,

              cost_svg_cost_total: 1,
              cost_svg_cost_difference: 1,
              cost_svg_cost_objective: 1,
              cost_svg_cost_rate: 1,
              cost_svg_cost_last_year_comparison_rate: 1,

              profit_operating_profit_total: 1,
              profit_operating_profit_difference: 1,
              profit_operating_profit_objective: 1,
              profit_operating_profit_rate: 1,
              profit_operating_profit_last_year_comparison_rate: 1,
            };
          } else {
            const growth_rate_revenue_total = zeroEscape(
              item.revenue.revenue_total,
              raw_data_array_past[index - 1].revenue.revenue_total
            );
            const growth_rate_revenue_difference = zeroEscape(
              item.revenue.revenue_difference,
              raw_data_array_past[index - 1].revenue.revenue_difference
            );
            const growth_rate_revenue_objective = zeroEscape(
              item.revenue.revenue_objective,
              raw_data_array_past[index - 1].revenue.revenue_objective
            );
            const growth_rate_revenue_rate = zeroEscape(
              item.revenue.revenue_rate,
              raw_data_array_past[index - 1].revenue.revenue_rate
            );
            const growth_rate_revenue_last_year_comparison_rate = zeroEscape(
              item.revenue.revenue_last_year_comparison_rate,
              raw_data_array_past[index - 1].revenue.revenue_last_year_comparison_rate
            );
            console.log("ここまでうごく")
            const growth_rate_cost_unit_cost_total = zeroEscape(
              item.cost_unit_cost.cost_unit_cost_total,
              raw_data_array_past[index - 1].cost_unit_cost.cost_unit_cost_total
            );
            const growth_rate_cost_unit_cost_difference = zeroEscape(
              item.cost_unit_cost.cost_unit_cost_difference,
              raw_data_array_past[index - 1].cost_unit_cost.cost_unit_cost_difference
            );
            const growth_rate_cost_unit_cost_objective = zeroEscape(
              item.cost_unit_cost.cost_unit_cost_objective,
              raw_data_array_past[index - 1].cost_unit_cost.cost_unit_cost_objective
            );
            const growth_rate_cost_unit_cost_rate = zeroEscape(
              item.cost_unit_cost.cost_unit_cost_rate,
              raw_data_array_past[index - 1].cost_unit_cost.cost_unit_cost_rate
            );
            const growth_rate_cost_unit_cost_last_year_comparison_rate = zeroEscape(
              item.cost_unit_cost.cost_unit_cost_last_year_comparison_rate,
              raw_data_array_past[index - 1].cost_unit_cost.cost_unit_cost_last_year_comparison_rate
            );

            const growth_rate_profit_gross_profit_total = zeroEscape(
              item.profit_gross_profit.profit_gross_profit_total,
              raw_data_array_past[index - 1].profit_gross_profit.profit_gross_profit_total
            );
            const growth_rate_profit_gross_profit_difference = zeroEscape(
              item.profit_gross_profit.profit_gross_profit_difference,
              raw_data_array_past[index - 1].profit_gross_profit.profit_gross_profit_difference
            );
            const growth_rate_profit_gross_profit_objective = zeroEscape(
              item.profit_gross_profit.profit_gross_profit_objective,
              raw_data_array_past[index - 1].profit_gross_profit.profit_gross_profit_objective
            );
            const growth_rate_profit_gross_profit_rate = zeroEscape(
              item.profit_gross_profit.profit_gross_profit_rate,
              raw_data_array_past[index - 1].profit_gross_profit.profit_gross_profit_rate
            );
            const growth_rate_profit_gross_profit_last_year_comparison_rate = zeroEscape(
              item.profit_gross_profit.profit_gross_profit_last_year_comparison_rate,
              raw_data_array_past[index - 1].profit_gross_profit.profit_gross_profit_last_year_comparison_rate
            );

            const growth_rate_cost_svg_cost_total = zeroEscape(
              item.cost_svg_cost.cost_svg_cost_total,
              raw_data_array_past[index - 1].cost_svg_cost.cost_svg_cost_total
            );
            const growth_rate_cost_svg_cost_difference = zeroEscape(
              item.cost_svg_cost.cost_svg_cost_difference,
              raw_data_array_past[index - 1].cost_svg_cost.cost_svg_cost_difference
            );
            const growth_rate_cost_svg_cost_objective = zeroEscape(
              item.cost_svg_cost.cost_svg_cost_objective,
              raw_data_array_past[index - 1].cost_svg_cost.cost_svg_cost_objective
            );
            const growth_rate_cost_svg_cost_rate = zeroEscape(
              item.cost_svg_cost.cost_svg_cost_rate,
              raw_data_array_past[index - 1].cost_svg_cost.cost_svg_cost_rate
            );
            const growth_rate_cost_svg_cost_last_year_comparison_rate = zeroEscape(
              item.cost_svg_cost.cost_svg_cost_last_year_comparison_rate,
              raw_data_array_past[index - 1].cost_svg_cost.cost_svg_cost_last_year_comparison_rate
            );

            const growth_rate_profit_operating_profit_total = zeroEscape(
              item.profit_operating_profit.profit_operating_profit_total,
              raw_data_array_past[index - 1].profit_operating_profit.profit_operating_profit_total
            );
            const growth_rate_profit_operating_profit_difference = zeroEscape(
              item.profit_operating_profit.profit_operating_profit_difference,
              raw_data_array_past[index - 1].profit_operating_profit.profit_operating_profit_difference
            );
            const growth_rate_profit_operating_profit_objective = zeroEscape(
              item.profit_operating_profit.profit_operating_profit_objective,
              raw_data_array_past[index - 1].profit_operating_profit.profit_operating_profit_objective
            );
            const growth_rate_profit_operating_profit_rate = zeroEscape(
              item.profit_operating_profit.profit_operating_profit_rate,
              raw_data_array_past[index - 1].profit_operating_profit.profit_operating_profit_rate
            );
            const growth_rate_profit_operating_profit_last_year_comparison_rate = zeroEscape(
              item.profit_operating_profit.profit_operating_profit_last_year_comparison_rate,
              raw_data_array_past[index - 1].profit_operating_profit.profit_operating_profit_last_year_comparison_rate
            );
            return {
              revenue_total: growth_rate_revenue_total,
              revenue_difference: growth_rate_revenue_difference,
              revenue_objective: growth_rate_revenue_objective,
              revenue_rate: growth_rate_revenue_rate,
              revenue_last_year_comparison_rate: growth_rate_revenue_last_year_comparison_rate,

              cost_unit_cost_total: growth_rate_cost_unit_cost_total,
              cost_unit_cost_difference: growth_rate_cost_unit_cost_difference,
              cost_unit_cost_objective: growth_rate_cost_unit_cost_objective,
              cost_unit_cost_rate: growth_rate_cost_unit_cost_rate,
              cost_unit_cost_last_year_comparison_rate: growth_rate_cost_unit_cost_last_year_comparison_rate,

              profit_gross_profit_total: growth_rate_profit_gross_profit_total,
              profit_gross_profit_difference: growth_rate_profit_gross_profit_difference,
              profit_gross_profit_objective: growth_rate_profit_gross_profit_objective,
              profit_gross_profit_rate: growth_rate_profit_gross_profit_rate,
              profit_gross_profit_last_year_comparison_rate: growth_rate_profit_gross_profit_last_year_comparison_rate,

              cost_svg_cost_total: growth_rate_cost_svg_cost_total,
              cost_svg_cost_difference: growth_rate_cost_svg_cost_difference,
              cost_svg_cost_objective: growth_rate_cost_svg_cost_objective,
              cost_svg_cost_rate: growth_rate_cost_svg_cost_rate,
              cost_svg_cost_last_year_comparison_rate: growth_rate_cost_svg_cost_last_year_comparison_rate,

              profit_operating_profit_total: growth_rate_profit_operating_profit_total,
              profit_operating_profit_difference: growth_rate_profit_operating_profit_difference,
              profit_operating_profit_objective: growth_rate_profit_operating_profit_objective,
              profit_operating_profit_rate: growth_rate_profit_operating_profit_rate,
              profit_operating_profit_last_year_comparison_rate: growth_rate_profit_operating_profit_last_year_comparison_rate,
            };
          }
        })
        .slice(1, (raw_data_array_past.length - 1));
      // const revenue_total_growth_rate_sum = growth_rate_array.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.revenue_total, 0);
      // const revenue_total_growth_rate = revenue_total_growth_rate_sum / forecast_base_span;
      const revenue_total_growth_rate = growth_rate_array.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.revenue_total, 0) / (forecast_base_span - 1);
      const revenue_difference_growth_rate = growth_rate_array.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.revenue_difference, 0) / (forecast_base_span - 1);
      const revenue_objective_growth_rate = growth_rate_array.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.revenue_objective, 0) / (forecast_base_span - 1);
      const revenue_rate_growth_rate = growth_rate_array.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.revenue_rate, 0) / (forecast_base_span - 1);
      const revenue_last_year_comparison_rate_growth_rate = growth_rate_array.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.revenue_last_year_comparison_rate, 0) / (forecast_base_span - 1);

      const cost_unit_cost_total_growth_rate = growth_rate_array.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.cost_unit_cost_total, 0) / (forecast_base_span - 1);
      const cost_unit_cost_difference_growth_rate = growth_rate_array.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.cost_unit_cost_difference, 0) / (forecast_base_span - 1);

      const cost_unit_cost_objective_growth_rate = growth_rate_array.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.cost_unit_cost_objective, 0) / (forecast_base_span - 1);

      const cost_unit_cost_rate_growth_rate = growth_rate_array.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.cost_unit_cost_rate, 0) / (forecast_base_span - 1);
      const cost_unit_cost_last_year_comparison_rate_growth_rate = growth_rate_array.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.cost_unit_cost_last_year_comparison_rate, 0) / (forecast_base_span - 1);

      const profit_gross_profit_total_growth_rate = growth_rate_array.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.profit_gross_profit_total, 0) / (forecast_base_span - 1);
      const profit_gross_profit_difference_growth_rate = growth_rate_array.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.profit_gross_profit_difference, 0) / (forecast_base_span - 1);
      const profit_gross_profit_objective_growth_rate = growth_rate_array.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.profit_gross_profit_objective, 0) / (forecast_base_span - 1);
      const profit_gross_profit_rate_growth_rate = growth_rate_array.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.profit_gross_profit_rate, 0) / (forecast_base_span - 1);
      const profit_gross_profit_last_year_comparison_rate_growth_rate = growth_rate_array.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.profit_gross_profit_last_year_comparison_rate, 0) / (forecast_base_span - 1);

      const cost_svg_cost_total_growth_rate = growth_rate_array.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.cost_svg_cost_total, 0) / (forecast_base_span - 1);
      const cost_svg_cost_difference_growth_rate = growth_rate_array.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.cost_svg_cost_difference, 0) / (forecast_base_span - 1);
      const cost_svg_cost_objective_growth_rate = growth_rate_array.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.cost_svg_cost_objective, 0) / (forecast_base_span - 1);
      const cost_svg_cost_rate_growth_rate = growth_rate_array.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.cost_svg_cost_rate, 0) / (forecast_base_span - 1);
      const cost_svg_cost_last_year_comparison_rate_growth_rate = growth_rate_array.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.cost_svg_cost_last_year_comparison_rate, 0) / (forecast_base_span - 1);

      const profit_operating_profit_total_growth_rate = growth_rate_array.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.profit_operating_profit_total, 0) / (forecast_base_span - 1);
      const profit_operating_profit_difference_growth_rate = growth_rate_array.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.profit_operating_profit_difference, 0) / (forecast_base_span - 1);
      const profit_operating_profit_objective_growth_rate = growth_rate_array.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.profit_operating_profit_objective, 0) / (forecast_base_span - 1);
      const profit_operating_profit_rate_growth_rate = growth_rate_array.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.profit_operating_profit_rate, 0) / (forecast_base_span - 1);
      const profit_operating_profit_last_year_comparison_rate_growth_rate = growth_rate_array.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.profit_operating_profit_last_year_comparison_rate, 0) / (forecast_base_span - 1);


      // =========================================================================
      // それぞれの指標に成長率をかける
      // 今月の値に対してnヶ月後はの値は成長率のn乗になる
      // 六ヶ月分求めるので、0から6までの数字が入った配列を用意する
      const futureArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

      const forecasted_data_array = futureArray.map((number: number) => {
        const thisMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        const month = thisMonth.getMonth();
        const forecastedMonth = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month + number));

        const revenue_total_forecast_item = raw_data_array_past[raw_data_array_past.length - 1].revenue.revenue_total * revenue_total_growth_rate ** number;
        const revenue_difference_forecast_item = raw_data_array_past[raw_data_array_past.length - 1].revenue.revenue_difference * revenue_difference_growth_rate ** number;
        const revenue_objective_forecast_item = raw_data_array_past[raw_data_array_past.length - 1].revenue.revenue_objective * revenue_objective_growth_rate ** number;
        const revenue_rate_forecast_item = raw_data_array_past[raw_data_array_past.length - 1].revenue.revenue_rate * revenue_rate_growth_rate ** number;
        const revenue_last_year_comparison_rate_forecast_item = raw_data_array_past[raw_data_array_past.length - 1].revenue.revenue_last_year_comparison_rate * revenue_last_year_comparison_rate_growth_rate ** number;
        const cost_unit_cost_total_forecast_item = raw_data_array_past[raw_data_array_past.length - 1].cost_unit_cost.cost_unit_cost_total * cost_unit_cost_total_growth_rate ** number;
        const cost_unit_cost_difference_forecast_item = raw_data_array_past[raw_data_array_past.length - 1].cost_unit_cost.cost_unit_cost_difference * cost_unit_cost_difference_growth_rate ** number;
        const cost_unit_cost_objective_forecast_item = raw_data_array_past[raw_data_array_past.length - 1].cost_unit_cost.cost_unit_cost_objective * cost_unit_cost_objective_growth_rate ** number;
        const cost_unit_cost_rate_forecast_item = raw_data_array_past[raw_data_array_past.length - 1].cost_unit_cost.cost_unit_cost_rate * cost_unit_cost_rate_growth_rate ** number;
        const cost_unit_cost_last_year_comparison_rate_forecast_item = raw_data_array_past[raw_data_array_past.length - 1].cost_unit_cost.cost_unit_cost_last_year_comparison_rate * cost_unit_cost_last_year_comparison_rate_growth_rate ** number;

        const profit_gross_profit_total_forecast_item = raw_data_array_past[raw_data_array_past.length - 1].profit_gross_profit.profit_gross_profit_total * profit_gross_profit_total_growth_rate ** number;
        const profit_gross_profit_difference_forecast_item = raw_data_array_past[raw_data_array_past.length - 1].profit_gross_profit.profit_gross_profit_difference * profit_gross_profit_difference_growth_rate ** number;
        const profit_gross_profit_objective_forecast_item = raw_data_array_past[raw_data_array_past.length - 1].profit_gross_profit.profit_gross_profit_objective * profit_gross_profit_objective_growth_rate ** number;
        const profit_gross_profit_rate_forecast_item = raw_data_array_past[raw_data_array_past.length - 1].profit_gross_profit.profit_gross_profit_rate * profit_gross_profit_rate_growth_rate ** number;
        const profit_gross_profit_last_year_comparison_rate_forecast_item = raw_data_array_past[raw_data_array_past.length - 1].profit_gross_profit.profit_gross_profit_last_year_comparison_rate * profit_gross_profit_last_year_comparison_rate_growth_rate ** number;

        const cost_svg_cost_total_forecast_item = raw_data_array_past[raw_data_array_past.length - 1].cost_svg_cost.cost_svg_cost_total * cost_svg_cost_total_growth_rate ** number;
        const cost_svg_cost_difference_forecast_item = raw_data_array_past[raw_data_array_past.length - 1].cost_svg_cost.cost_svg_cost_difference * cost_svg_cost_difference_growth_rate ** number;
        const cost_svg_cost_objective_forecast_item = raw_data_array_past[raw_data_array_past.length - 1].cost_svg_cost.cost_svg_cost_objective * cost_svg_cost_objective_growth_rate ** number;
        const cost_svg_cost_rate_forecast_item = raw_data_array_past[raw_data_array_past.length - 1].cost_svg_cost.cost_svg_cost_rate * cost_svg_cost_rate_growth_rate ** number;
        const cost_svg_cost_last_year_comparison_rate_forecast_item = raw_data_array_past[raw_data_array_past.length - 1].cost_svg_cost.cost_svg_cost_last_year_comparison_rate * cost_svg_cost_last_year_comparison_rate_growth_rate ** number;

        const profit_operating_profit_total_forecast_item = raw_data_array_past[raw_data_array_past.length - 1].profit_operating_profit.profit_operating_profit_total * profit_operating_profit_total_growth_rate ** number;
        const profit_operating_profit_difference_forecast_item = raw_data_array_past[raw_data_array_past.length - 1].profit_operating_profit.profit_operating_profit_difference * profit_operating_profit_difference_growth_rate ** number;
        const profit_operating_profit_objective_forecast_item = raw_data_array_past[raw_data_array_past.length - 1].profit_operating_profit.profit_operating_profit_objective * profit_operating_profit_objective_growth_rate ** number;
        const profit_operating_profit_rate_forecast_item = raw_data_array_past[raw_data_array_past.length - 1].profit_operating_profit.profit_operating_profit_rate * profit_operating_profit_rate_growth_rate ** number;
        const profit_operating_profit_last_year_comparison_rate_forecast_item = raw_data_array_past[raw_data_array_past.length - 1].profit_operating_profit.profit_operating_profit_last_year_comparison_rate * profit_operating_profit_last_year_comparison_rate_growth_rate ** number;


        if (number === 1) {
          // 実線を翌月までつなげるため、futureArray配列の1番目=翌月には実データのキーに予測データの値を入れる
          return {
            result_date: forecastedMonth, // 日付の型が異なるためデータキーも調整
            result_at: {
              nanoseconds: 0,
              seconds: forecastedMonth.getTime() / 1000
            },
            real_data: false,
            boundary: true, //境目のデータであることを認識させるためのプロパティ
            revenue_total: revenue_total_forecast_item,
            revenue_difference: revenue_difference_forecast_item,
            revenue_objective: revenue_objective_forecast_item,
            revenue_rate: revenue_rate_forecast_item,
            revenue_last_year_comparison_rate: revenue_last_year_comparison_rate_forecast_item,

            cost_unit_cost_total: cost_unit_cost_total_forecast_item,
            cost_unit_cost_difference: cost_unit_cost_difference_forecast_item,
            cost_unit_cost_objective: cost_unit_cost_objective_forecast_item,
            cost_unit_cost_rate: cost_unit_cost_rate_forecast_item,
            cost_unit_cost_last_year_comparison_rate: cost_unit_cost_last_year_comparison_rate_forecast_item,

            profit_gross_profit_total: profit_gross_profit_total_forecast_item,
            profit_gross_profit_difference: profit_gross_profit_difference_forecast_item,
            profit_gross_profit_objective: profit_gross_profit_objective_forecast_item,
            profit_gross_profit_rate: profit_gross_profit_rate_forecast_item,
            profit_gross_profit_last_year_comparison_rate: profit_gross_profit_last_year_comparison_rate_forecast_item,

            cost_svg_cost_total: cost_svg_cost_total_forecast_item,
            cost_svg_cost_difference: cost_svg_cost_difference_forecast_item,
            cost_svg_cost_objective: cost_svg_cost_objective_forecast_item,
            cost_svg_cost_rate: cost_svg_cost_rate_forecast_item,
            cost_svg_cost_last_year_comparison_rate: cost_svg_cost_last_year_comparison_rate_forecast_item,

            profit_operating_profit_total: profit_operating_profit_total_forecast_item,
            profit_operating_profit_difference: profit_operating_profit_difference_forecast_item,
            profit_operating_profit_objective: profit_operating_profit_objective_forecast_item,
            profit_operating_profit_rate: profit_operating_profit_rate_forecast_item,
            profit_operating_profit_last_year_comparison_rate: profit_operating_profit_last_year_comparison_rate_forecast_item,

            revenue_total_forecasted: revenue_total_forecast_item,
            revenue_difference_forecasted: revenue_difference_forecast_item,
            revenue_objective_forecasted: revenue_objective_forecast_item,
            revenue_rate_forecasted: revenue_rate_forecast_item,
            revenue_last_year_comparison_rate_forecasted: revenue_last_year_comparison_rate_forecast_item,

            cost_unit_cost_total_forecasted: cost_unit_cost_total_forecast_item,
            cost_unit_cost_difference_forecasted: cost_unit_cost_difference_forecast_item,
            cost_unit_cost_objective_forecasted: cost_unit_cost_objective_forecast_item,
            cost_unit_cost_rate_forecasted: cost_unit_cost_rate_forecast_item,
            cost_unit_cost_last_year_comparison_rate_forecasted: cost_unit_cost_last_year_comparison_rate_forecast_item,

            profit_gross_profit_total_forecasted: profit_gross_profit_total_forecast_item,
            profit_gross_profit_difference_forecasted: profit_gross_profit_difference_forecast_item,
            profit_gross_profit_objective_forecasted: profit_gross_profit_objective_forecast_item,
            profit_gross_profit_rate_forecasted: profit_gross_profit_rate_forecast_item,
            profit_gross_profit_last_year_comparison_rate_forecasted: profit_gross_profit_last_year_comparison_rate_forecast_item,

            cost_svg_cost_total_forecasted: cost_svg_cost_total_forecast_item,
            cost_svg_cost_difference_forecasted: cost_svg_cost_difference_forecast_item,
            cost_svg_cost_objective_forecasted: cost_svg_cost_objective_forecast_item,
            cost_svg_cost_rate_forecasted: cost_svg_cost_rate_forecast_item,
            cost_svg_cost_last_year_comparison_rate_forecasted: cost_svg_cost_last_year_comparison_rate_forecast_item,

            profit_operating_profit_total_forecasted: profit_operating_profit_total_forecast_item,
            profit_operating_profit_difference_forecasted: profit_operating_profit_difference_forecast_item,
            profit_operating_profit_objective_forecasted: profit_operating_profit_objective_forecast_item,
            profit_operating_profit_rate_forecasted: profit_operating_profit_rate_forecast_item,
            profit_operating_profit_last_year_comparison_rate_forecasted: profit_operating_profit_last_year_comparison_rate_forecast_item
          };
        } else {
          return {
            result_date: forecastedMonth, // 日付の型が異なるためデータキーも調整
            result_at: {
              nanoseconds: 0,
              seconds: forecastedMonth.getTime() / 1000
            },
            real_data: false,
            revenue_total_forecasted: revenue_total_forecast_item,
            revenue_difference_forecasted: revenue_difference_forecast_item,
            revenue_objective_forecasted: revenue_objective_forecast_item,
            revenue_rate_forecasted: revenue_rate_forecast_item,
            revenue_last_year_comparison_rate_forecasted: revenue_last_year_comparison_rate_forecast_item,

            cost_unit_cost_total_forecasted: cost_unit_cost_total_forecast_item,
            cost_unit_cost_difference_forecasted: cost_unit_cost_difference_forecast_item,
            cost_unit_cost_objective_forecasted: cost_unit_cost_objective_forecast_item,
            cost_unit_cost_rate_forecasted: cost_unit_cost_rate_forecast_item,
            cost_unit_cost_last_year_comparison_rate_forecasted: cost_unit_cost_last_year_comparison_rate_forecast_item,

            profit_gross_profit_total_forecasted: profit_gross_profit_total_forecast_item,
            profit_gross_profit_difference_forecasted: profit_gross_profit_difference_forecast_item,
            profit_gross_profit_objective_forecasted: profit_gross_profit_objective_forecast_item,
            profit_gross_profit_rate_forecasted: profit_gross_profit_rate_forecast_item,
            profit_gross_profit_last_year_comparison_rate_forecasted: profit_gross_profit_last_year_comparison_rate_forecast_item,

            cost_svg_cost_total_forecasted: cost_svg_cost_total_forecast_item,
            cost_svg_cost_difference_forecasted: cost_svg_cost_difference_forecast_item,
            cost_svg_cost_objective_forecasted: cost_svg_cost_objective_forecast_item,
            cost_svg_cost_rate_forecasted: cost_svg_cost_rate_forecast_item,
            cost_svg_cost_last_year_comparison_rate_forecasted: cost_svg_cost_last_year_comparison_rate_forecast_item,

            profit_operating_profit_total_forecasted: profit_operating_profit_total_forecast_item,
            profit_operating_profit_difference_forecasted: profit_operating_profit_difference_forecast_item,
            profit_operating_profit_objective_forecasted: profit_operating_profit_objective_forecast_item,
            profit_operating_profit_rate_forecasted: profit_operating_profit_rate_forecast_item,
            profit_operating_profit_last_year_comparison_rate_forecasted: profit_operating_profit_last_year_comparison_rate_forecast_item
          };
        }
      });
      // revenue_detailの未来予測と統合
      const forecasted_data_array_with_revenue_detail = forecasted_data_array.map((fd: any, index: number) => {
        return {
          ...fd,
          ...action.future_revenue_detail[index],
          // revenue_total_table: action.future_revenue_detail[index].revenue_detail.reduce((acc: any, cur: any) => acc + cur.revenue_total, 0),
          revenue_total_forecasted: action.future_revenue_detail[index].revenue_detail.reduce((acc: any, cur: any) => acc + cur.revenue_total, 0),

          cost_unit_cost_total_forecasted: action.future_unit_cost_detail[index].unit_cost_cat_1_total,
          ...action.future_unit_cost_detail[index],
          cost_svg_cost_total_forecasted: action.future_svg_cost_detail[index].svg_cost_cat_1_total,
          ...action.future_svg_cost_detail[index]
        }
      })

      const rawDataRealDateList = action.raw_data_array_real.map((result: any) => {
        let dateInGraph = result.result_at;
        return {
          result_at: dateInGraph,
          real_data: true
        };
      });

      const revenue_results = action.raw_data_array_real
        .flatMap((rd: any) => rd.revenue)
        .map((rd: any) => {
          return {
            revenue_total: rd.revenue_total,
            revenue_objective: rd.revenue_objective,
            revenue_rate: rd.revenue_rate,
            revenue_difference: rd.revenue_difference,
            revenue_last_year_total: rd.revenue_last_year_total,
            revenue_last_year_objective: rd.revenue_last_year_objective,
            revenue_last_year_comparison_total: rd.revenue_last_year_comparison_total,
            revenue_last_year_comparison_rate: rd.revenue_last_year_comparison_rate,
            revenue_total_forecasted: rd.revenue_total,
            revenue_objective_forecasted: rd.revenue_objective,
            revenue_rate_forecasted: rd.revenue_rate,
            revenue_difference_forecasted: rd.revenue_difference,
            revenue_last_year_total_forecasted: rd.revenue_last_year_total,
            revenue_last_year_objective_forecasted: rd.revenue_last_year_objective,
            revenue_last_year_comparison_total_forecasted: rd.revenue_last_year_comparison_total,
            revenue_last_year_comparison_rate_forecasted: rd.revenue_last_year_comparison_rate
          };
        });

        const cost_unit_cost_results = action.raw_data_array_real
        .flatMap((rd: any) => {
          console.log("rd from reducer", rd)
          return rd.cost_unit_cost
        })
        .map((rd: any) => {
          return {
            cost_unit_cost_total: rd.cost_unit_cost_total,
            cost_unit_cost_objective: rd.cost_unit_cost_objective,
            cost_unit_cost_rate: rd.cost_unit_cost_rate,
            cost_unit_cost_difference: rd.cost_unit_cost_difference,
            cost_unit_cost_last_year_total: rd.cost_unit_cost_last_year_total,
            cost_unit_cost_last_year_objective: rd.cost_unit_cost_last_year_objective,
            cost_unit_cost_last_year_comparison_total: rd.cost_unit_cost_last_year_comparison_total,
            cost_unit_cost_last_year_comparison_rate: rd.cost_unit_cost_last_year_comparison_rate,

            cost_unit_cost_total_forecasted: rd.cost_unit_cost_total,
            cost_unit_cost_objective_forecasted: rd.cost_unit_cost_objective,
            cost_unit_cost_rate_forecasted: rd.cost_unit_cost_rate,
            cost_unit_cost_difference_forecasted: rd.cost_unit_cost_difference,
            cost_unit_cost_last_year_total_forecasted: rd.cost_unit_cost_last_year_total,
            cost_unit_cost_last_year_objective_forecasted: rd.cost_unit_cost_last_year_objective,
            cost_unit_cost_last_year_comparison_total_forecasted: rd.cost_unit_cost_last_year_comparison_total,
            cost_unit_cost_last_year_comparison_rate_forecasted: rd.cost_unit_cost_last_year_comparison_rate
          };
        });

      // 売上総利益
      const profit_gross_profits = action.raw_data_array_real
        .flatMap((rd: any) => rd.profit_gross_profit)
        .map((rd: any) => {
          return {
            profit_gross_profit_total: rd.profit_gross_profit_total,
            profit_gross_profit_objective: rd.profit_gross_profit_objective,
            profit_gross_profit_rate: rd.profit_gross_profit_rate,
            profit_gross_profit_difference: rd.profit_gross_profit_difference,
            profit_gross_profit_last_year_total: rd.profit_gross_profit_last_year_total,
            profit_gross_profit_last_year_objective: rd.profit_gross_profit_last_year_objective,
            profit_gross_profit_last_year_comparison_total: rd.profit_gross_profit_last_year_comparison_total,
            profit_gross_profit_last_year_comparison_rate: rd.profit_gross_profit_last_year_comparison_rate,
            profit_gross_profit_total_forecasted: rd.profit_gross_profit_total,
            profit_gross_profit_objective_forecasted: rd.profit_gross_profit_objective,
            profit_gross_profit_rate_forecasted: rd.profit_gross_profit_rate,
            profit_gross_profit_difference_forecasted: rd.profit_gross_profit_difference,
            profit_gross_profit_last_year_total_forecasted: rd.profit_gross_profit_last_year_total,
            profit_gross_profit_last_year_objective_forecasted: rd.profit_gross_profit_last_year_objective,
            profit_gross_profit_last_year_comparison_total_forecasted: rd.profit_gross_profit_last_year_comparison_total,
            profit_gross_profit_last_year_comparison_rate_forecasted: rd.profit_gross_profit_last_year_comparison_rate
          };
        });

        const cost_svg_cost_results = action.raw_data_array_real
        .flatMap((rd: any) => rd.cost_svg_cost)
        .map((rd: any) => {
          return {
            cost_svg_cost_total: rd.cost_svg_cost_total,
            cost_svg_cost_objective: rd.cost_svg_cost_objective,
            cost_svg_cost_rate: rd.cost_svg_cost_rate,
            cost_svg_cost_difference: rd.cost_svg_cost_difference,
            cost_svg_cost_last_year_total: rd.cost_svg_cost_last_year_total,
            cost_svg_cost_last_year_objective: rd.cost_svg_cost_last_year_objective,
            cost_svg_cost_last_year_comparison_total: rd.cost_svg_cost_last_year_comparison_total,
            cost_svg_cost_last_year_comparison_rate: rd.cost_svg_cost_last_year_comparison_rate,

            cost_svg_cost_total_forecasted: rd.cost_svg_cost_total,
            cost_svg_cost_objective_forecasted: rd.cost_svg_cost_objective,
            cost_svg_cost_rate_forecasted: rd.cost_svg_cost_rate,
            cost_svg_cost_difference_forecasted: rd.cost_svg_cost_difference,
            cost_svg_cost_last_year_total_forecasted: rd.cost_svg_cost_last_year_total,
            cost_svg_cost_last_year_objective_forecasted: rd.cost_svg_cost_last_year_objective,
            cost_svg_cost_last_year_comparison_total_forecasted: rd.cost_svg_cost_last_year_comparison_total,
            cost_svg_cost_last_year_comparison_rate_forecasted: rd.cost_svg_cost_last_year_comparison_rate
          };
        });

      // 営業利益
      const profit_operating_profits = action.raw_data_array_real
        .flatMap((rd: any) => rd.profit_operating_profit)
        .map((rd: any) => {
          return {
            profit_operating_profit_total: rd.profit_operating_profit_total,
            profit_operating_profit_objective: rd.profit_operating_profit_objective,
            profit_operating_profit_rate: rd.profit_operating_profit_rate,
            profit_operating_profit_difference: rd.profit_operating_profit_difference,
            profit_operating_profit_last_year_total: rd.profit_operating_profit_last_year_total,
            profit_operating_profit_last_year_objective: rd.profit_operating_profit_last_year_objective,
            profit_operating_profit_last_year_comparison_total: rd.profit_operating_profit_last_year_comparison_total,
            profit_operating_profit_last_year_comparison_rate: rd.profit_operating_profit_last_year_comparison_rate,

            profit_operating_profit_total_forecasted: rd.profit_operating_profit_total,
            profit_operating_profit_objective_forecasted: rd.profit_operating_profit_objective,
            profit_operating_profit_rate_forecasted: rd.profit_operating_profit_rate,
            profit_operating_profit_difference_forecasted: rd.profit_operating_profit_difference,
            profit_operating_profit_last_year_total_forecasted: rd.profit_operating_profit_last_year_total,
            profit_operating_profit_last_year_objective_forecasted: rd.profit_operating_profit_last_year_objective,
            profit_operating_profit_last_year_comparison_total_forecasted: rd.profit_operating_profit_last_year_comparison_total,
            profit_operating_profit_last_year_comparison_rate_forecasted: rd.profit_operating_profit_last_year_comparison_rate
          };
        });

      const real_integrated_array = rawDataRealDateList.map((rawDataRealDate: any, dateIndex: number) => {
        return {
          ...rawDataRealDate,
          ...revenue_results[dateIndex],
          ...profit_gross_profits[dateIndex],
          ...profit_operating_profits[dateIndex],
          ...cost_unit_cost_results[dateIndex],
          ...cost_svg_cost_results[dateIndex]
        };
      })
      const real_integrated_array_without_future = real_integrated_array.filter((real_data: any) => real_data.result_at.seconds * 1000 <= new Date().getTime());

      const merged_integrated_array = real_integrated_array_without_future.concat(forecasted_data_array_with_revenue_detail);

      const merged_merged_integrated_array = merged_integrated_array.map((mia: any, miaindex: number) => {
        return {
          ...mia,
          ...action.integrated_array_forForecast[miaindex]
        }
      })

      return {
        ...state,
        // future_forecast_integrated_array: merged_integrated_array
        integrated_array: merged_merged_integrated_array,
        merged_merged_integrated_array: merged_merged_integrated_array
      };



    case GET_RESULTS_FOR_TARGET_FORECAST_FAILED:
      return state;

    case UPDATE_NEW_FORECAST_SIMULATED_RESULT:
      if (action.resultSpan === "monthly") {
        return update(state, {
          forecastResult: {
            ...state.forecastResult,
            resultMonthly: {
              [action.updated_item_index]: {
                $set: action.updated_object
              }
            }
          }
        });
      }
      break;

    case GET_RESULTS_FOR_SPAN_COMPARISON_FORECASTS_SUCCEEDED:
      // =================================
      // forecastsに必要なデータをresultからぬきだす
      const graphDateListForecast = action.results.map((rd: any) => {
        if (action.spanOrder === "firstSpan") {
          return {
            first_date: rd.result_at
          };
        } else {
          return {
            second_date: rd.result_at
          };
        }
      });

      const graphRevenueListForecast = action.results
        .flatMap((rd: any) => rd.revenue)
        .map((rd: any) => {
          return {
            revenue_total: rd.revenue_total,
            revenue_objective: rd.revenue_objective,
            revenue_difference: rd.revenue_difference,
            revenue_last_year_comparison_total: rd.revenue_last_year_comparison_total,
            revenue_last_year_comparison_rate: rd.revenue_last_year_comparison_rate
          };
        });

      const graphProfitListForecast = action.results
        .flatMap((rd: any) => rd.profit_operating_profit)
        .map((rd: any) => {
          return {
            profit_operating_profit_total: rd.profit_operating_profit_total,
            profit_operating_profit_objective: rd.profit_operating_profit_objective,
            profit_operating_profit_difference: rd.profit_operating_profit_difference,
            profit_operating_profit_last_year_comparison_total: rd.profit_operating_profit_last_year_comparison_total,
            profit_operating_profit_last_year_comparison_rate: rd.profit_operating_profit_last_year_comparison_rate
          };
        });

      const graphDateRevenueListForecast = graphDateListForecast.map((data: any, index: number) => {
        return { ...data, ...graphRevenueListForecast[index] };
      });

      const graphDateRevProfitList = graphDateRevenueListForecast.map((data: any, index: number) => {
        return { ...data, ...graphProfitListForecast[index] };
      });

      const forecasts = graphDateRevProfitList.map((data: any, index: number) => {
        if (action.spanOrder === "firstSpan") {
          return {
            first_forecasts_revenue: data.revenue_total,
            first_forecasts_revenue_objective: data.revenue_objective,
            first_forecasts_revenue_difference: data.revenue_difference,
            first_forecasts_revenue_last_year_comparison_total: data.revenue_last_year_comparison_total,
            first_forecasts_revenue_last_year_comparison_rate: data.revenue_last_year_comparison_rate,
            first_forecasts_profit: data.profit_operating_profit_total,
            first_forecasts_profit_objective: data.profit_operating_profit_objective,
            first_forecasts_profit_difference: data.profit_operating_profit_difference,
            first_forecasts_profit_last_year_comparison_total: data.profit_operating_profit_last_year_comparison_total,
            first_forecasts_profit_last_year_comparison_rate: data.profit_operating_profit_last_year_comparison_rate
          };
        } else {
          return {
            second_forecasts_revenue: data.revenue_total,
            second_forecasts_revenue_objective: data.revenue_objective,
            second_forecasts_revenue_difference: data.revenue_difference,
            second_forecasts_revenue_last_year_comparison_total: data.revenue_last_year_comparison_total,
            second_forecasts_revenue_last_year_comparison_rate: data.revenue_last_year_comparison_rate,
            second_forecasts_profit: data.profit_operating_profit_total,
            second_forecasts_profit_objective: data.profit_operating_profit_objective,
            second_forecasts_profit_difference: data.profit_operating_profit_difference,
            second_forecasts_profit_last_year_comparison_total: data.profit_operating_profit_last_year_comparison_total,
            second_forecasts_profit_last_year_comparison_rate: data.profit_operating_profit_last_year_comparison_rate
          };
        }
      });

      const graphForecastsList = graphDateRevProfitList.map((data: any, index: number) => {
        return { ...data, ...forecasts[index] };
      });
      // forecastsに必要なデータをresultからぬきだす
      // =================================

      if (action.resultSpan === "monthly") {
        // return state
        if (action.spanOrder === "firstSpan") {
          return {
            ...state,
            firstResult: {
              ...state.firstResult,
              resultDaily: graphForecastsList
            }
          };
        } else {
          if (action.spanOrder === "secondSpan") {
            return {
              ...state,
              secondResult: {
                ...state.secondResult,
                resultDaily: graphForecastsList
              }
            };
          }
        }
      } else if (action.resultSpan === "weekly") {
        // return state
        if (action.spanOrder === "firstSpan") {
          return {
            ...state,
            firstResult: {
              ...state.firstResult,
              resultDaily: graphForecastsList
            }
          };
        } else {
          if (action.spanOrder === "secondSpan") {
            return {
              ...state,
              secondResult: {
                ...state.secondResult,
                resultDaily: graphForecastsList
              }
            };
          }
        }
      } else {
        if (action.spanOrder === "firstSpan") {
          return {
            ...state,
            firstResult: {
              ...state.firstResult,
              resultDaily: graphForecastsList
            }
          };
        } else {
          if (action.spanOrder === "secondSpan") {
            return {
              ...state,
              secondResult: {
                ...state.secondResult,
                resultDaily: graphForecastsList
              }
            };
          }
        }
      }
      break;

    case GET_RESULTS_FOR_SPAN_COMPARISON_FORECASTS_FAILED:
      return {
        ...state,
        message: action.message
      };

    case SET_FORECAST_FILTER:
      return {
        ...state,
        forecastFilter: true
      };
    case RESET_FORECAST_FILTER:
      return {
        ...state,
        forecastFilter: false
      };

    case SET_FORECAST_FILTER_STATUS:
      return {
        ...state,
        forecast_filter_status: action.forecast_filter_status
      };

    case SET_FORECAST_CUSTOM_SPAN:
      return {
        ...state,
        selected_data_config: {
          ...state.selected_data_config,
          custom_span_start_time: action.forecast_custom_span_start_time,
          custom_span_end_time: action.forecast_custom_span_end_time,
          forecast_base_span: action.forecast_base_span
        }
      };

    case GET_INTEGRATED_ARRAY_FORECAST_SUCCEEDED:
      // 売上
      const revenue_results_list = action.raw_data_array
        .flatMap((rd: any) => rd.revenue ? rd.revenue : {
          revenue_total: 0,
          revenue_objective: 0,
          revenue_rate: 0,
          revenue_difference: 0,
          revenue_last_year_total: 0,
          revenue_last_year_objective: 0,
          revenue_last_year_comparison_total: 0,
          revenue_last_year_comparison_rate: 0
        })
        .map((rd: any) => {
          return {
            revenue_total: forecastErrorEscaper(rd, "revenue_total"),
            revenue_objective: forecastErrorEscaper(rd, "revenue_objective"),
            revenue_rate: forecastErrorEscaper(rd, "revenue_rate"),
            revenue_difference: forecastErrorEscaper(rd, "revenue_difference"),
            revenue_last_year_total: forecastErrorEscaper(rd, "revenue_last_year_total"),
            revenue_last_year_objective: forecastErrorEscaper(rd, "revenue_last_year_objective"),
            revenue_last_year_comparison_total: forecastErrorEscaper(rd, "revenue_last_year_comparison_total"),
            revenue_last_year_comparison_rate: forecastErrorEscaper(rd, "revenue_last_year_comparison_rate")
          };
        });

      // 売上詳細
      console.log(action.revenue_detail_results)
      const revenue_detail_results = action.revenue_detail_results
        ? action.revenue_detail_results.map((r: any) => r.list) 
        : [];
      console.log("revenue_detail_results reducer", revenue_detail_results)
      // 売上総利益
      const profit_gross_profits_list = action.raw_data_array
        .flatMap((rd: any) => rd.profit_gross_profit ? rd.profit_gross_profit : {
          profit_gross_profit_total: 0,
          profit_gross_profit_objective: 0,
          profit_gross_profit_rate: 0,
          profit_gross_profit_difference: 0,
          profit_gross_profit_last_year_total: 0,
          profit_gross_profit_last_year_objective: 0,
          profit_gross_profit_last_year_comparison_total: 0,
          profit_gross_profit_last_year_comparison_rate: 0
        })
        .map((rd: any) => {
          return {
            profit_gross_profit_total: forecastErrorEscaper(rd, "profit_gross_profit_total"),
            profit_gross_profit_objective: forecastErrorEscaper(rd, "profit_gross_profit_objective"),
            profit_gross_profit_rate: forecastErrorEscaper(rd, "profit_gross_profit_rate"),
            profit_gross_profit_difference: forecastErrorEscaper(rd, "profit_gross_profit_difference"),
            profit_gross_profit_last_year_total: forecastErrorEscaper(rd, "profit_gross_profit_last_year_total"),
            profit_gross_profit_last_year_objective: forecastErrorEscaper(rd, "profit_gross_profit_last_year_objective"),
            profit_gross_profit_last_year_comparison_total: forecastErrorEscaper(rd, "profit_gross_profit_last_year_comparison_total"),
            profit_gross_profit_last_year_comparison_rate: forecastErrorEscaper(rd, "profit_gross_profit_last_year_comparison_rate")
          };
        });

      // 営業利益
      const profit_operating_profits_list = action.raw_data_array
        .flatMap((rd: any) => rd.profit_operating_profit ? rd.profit_operating_profit : {
          profit_operating_profit_total: 0,
          profit_operating_profit_objective: 0,
          profit_operating_profit_rate: 0,
          profit_operating_profit_difference: 0,
          profit_operating_profit_last_year_total: 0,
          profit_operating_profit_last_year_objective: 0,
          profit_operating_profit_last_year_comparison_total: 0,
          profit_operating_profit_last_year_comparison_rate: 0
      })
        .map((rd: any) => {
          return {
            profit_operating_profit_total: forecastErrorEscaper(rd, "profit_operating_profit_total"),
            profit_operating_profit_objective: forecastErrorEscaper(rd, "profit_operating_profit_objective"),
            profit_operating_profit_rate: forecastErrorEscaper(rd, "profit_operating_profit_rate"),
            profit_operating_profit_difference: forecastErrorEscaper(rd, "profit_operating_profit_difference"),
            profit_operating_profit_last_year_total: forecastErrorEscaper(rd, "profit_operating_profit_last_year_total"),
            profit_operating_profit_last_year_objective: forecastErrorEscaper(rd, "profit_operating_profit_last_year_objective"),
            profit_operating_profit_last_year_comparison_total: forecastErrorEscaper(rd, "profit_operating_profit_last_year_comparison_total"),
            profit_operating_profit_last_year_comparison_rate: forecastErrorEscaper(rd, "profit_operating_profit_last_year_comparison_rate")
          };
        });

      // 商品名リスト作成
      const product_name_list = revenue_detail_results.length > 0 ?
      revenue_detail_results[0].map((item: any) => {
        return {
          product_name: item.name
        };
      }) : 
      [{product_name: ""}];

      // unit_costのcategory_2、category_3をオブジェクト化したリスト
      // 原価カテゴリーリスト作成
      const unit_cost_name_list = action.cost_unit_cost_detail_results.map((item: any) => {
        return {
          category_2: item.category_2,
          category_3: item.category_3
        };
      });

      // unit_costのcategory_2のリスト
      // 原価カテゴリーリストから重複のない文字列リスト作成
      const pure_unit_cost_name_list = unit_cost_name_list.filter((element: any, index: any, self: any) => self.findIndex((e: any) => e.category_2 === element.category_2 && e.category_3 === element.category_3) === index);
      const unit_cost_name_big_list = action.cost_unit_cost_detail_results.map((item: any) => {
        return item.category_2;
      });
      const pure_unit_cost_name_big_list = [...new Set(unit_cost_name_big_list)];

      // svg_costのcategory_2、category_3をオブジェクト化したリスト
      // 販売管理費カテゴリーリスト作成
      const svg_cost_name_list = action.cost_svg_cost_detail_results.map((item: any) => {
        return {
          category_2: item.category_2,
          category_3: item.category_3
        };
      });

      // svg_costのcategory_2のリスト
      const svg_cost_name_big_list = action.cost_svg_cost_detail_results.map((item: any) => {
        return item.category_2;
      });
      const pure_svg_cost_name_big_list = [...new Set(svg_cost_name_big_list)];

      // 販売管理費カテゴリーリストから重複のない文字列リスト作成
      const pure_svg_cost_name_list = svg_cost_name_list.filter((element: any, index: any, self: any) => self.findIndex((e: any) => e.category_2 === element.category_2 && e.category_3 === element.category_3) === index);

      // 原価の対象データ
      const cost_unit_cost_detail_results = action.cost_unit_cost_detail_results.filter((r: any) => r.resultSpan === action.selected_data_config_data_type);

      // 販管費の対象データ
      const cost_svg_cost_detail_results = action.cost_svg_cost_detail_results.filter((r: any) => r.resultSpan === action.selected_data_config_data_type);

      const dataSvgCostListToIntegrate = action.raw_data_array
        .flatMap((rd: any) => rd.cost_svg_cost ? rd.cost_svg_cost : {
          cost_svg_cost_total: 0,
          cost_svg_cost_objective: 0,
          cost_svg_cost_rate: 0,
          cost_svg_cost_difference: 0,
          cost_svg_cost_last_year_total: 0,
          cost_svg_cost_last_year_objective: 0,
          cost_svg_cost_last_year_comparison_total: 0,
          cost_svg_cost_last_year_comparison_rate: 0
        })
        .map((rd: any) => {
          return {
            cost_svg_cost_total: forecastErrorEscaper(rd, "cost_svg_cost_total"),
            cost_svg_cost_objective: forecastErrorEscaper(rd, "cost_svg_cost_objective"),
            cost_svg_cost_rate: forecastErrorEscaper(rd, "cost_svg_cost_rate"),
            cost_svg_cost_difference: forecastErrorEscaper(rd, "cost_svg_cost_difference"),
            cost_svg_cost_last_year_total: forecastErrorEscaper(rd, "cost_svg_cost_last_year_total"),
            cost_svg_cost_last_year_objective: forecastErrorEscaper(rd, "cost_svg_cost_last_year_objective"),
            cost_svg_cost_last_year_comparison_total: forecastErrorEscaper(rd, "cost_svg_cost_last_year_comparison_total"),
            cost_svg_cost_last_year_comparison_rate: forecastErrorEscaper(rd, "cost_svg_cost_last_year_comparison_rate")
          };
        });

      const dataUnitCostListToIntegrate = action.raw_data_array
        .flatMap((rd: any) => rd.cost_unit_cost ? rd.cost_unit_cost : {
          cost_unit_cost_total: 0,
          cost_unit_cost_objective: 0,
          cost_unit_cost_rate: 0,
          cost_unit_cost_difference: 0,
          cost_unit_cost_last_year_total: 0,
          cost_unit_cost_last_year_objective: 0,
          cost_unit_cost_last_year_comparison_total: 0,
          cost_unit_cost_last_year_comparison_rate: 0
        })
        .map((rd: any) => {
          return {
            cost_unit_cost_total: forecastErrorEscaper(rd, "cost_unit_cost_total"),
            cost_unit_cost_objective: forecastErrorEscaper(rd, "cost_unit_cost_objective"),
            cost_unit_cost_rate: forecastErrorEscaper(rd, "cost_unit_cost_rate"),
            cost_unit_cost_difference: forecastErrorEscaper(rd, "cost_unit_cost_difference"),
            cost_unit_cost_last_year_total: forecastErrorEscaper(rd, "cost_unit_cost_last_year_total"),
            cost_unit_cost_last_year_objective: forecastErrorEscaper(rd, "cost_unit_cost_last_year_objective"),
            cost_unit_cost_last_year_comparison_total: forecastErrorEscaper(rd, "cost_unit_cost_last_year_comparison_total"),
            cost_unit_cost_last_year_comparison_rate: forecastErrorEscaper(rd, "cost_unit_cost_last_year_comparison_rate")
          };
        });

      const costDateList = action.raw_data_array.map((result: any) => {
        let dateInGraph = result.result_at;
        return {
          result_at: dateInGraph,
          real_data: true
        };
      })
      // 日付ごとのコスト統合データ
      const integrated_array = costDateList.map((costDate: any, costDateIndex: number) => {
        return {
          ...costDate,
          real_data: true,
          ...dataSvgCostListToIntegrate[costDateIndex],
          ...dataUnitCostListToIntegrate[costDateIndex],
          ...revenue_results_list[costDateIndex],

          revenue_detail: revenue_detail_results[costDateIndex] ? revenue_detail_results[costDateIndex] : [],

          ...profit_gross_profits_list[costDateIndex],
          ...profit_operating_profits_list[costDateIndex],

          unit_cost_cat_1_name: "原価",

          unit_cost_cat_1_total: cost_unit_cost_detail_results.filter((unit_cost: any) => unit_cost.result_at.seconds === costDate.result_at.seconds).reduce((acc: any, el: any) => acc + el.cost_unit_cost_total, 0),

          unit_cost_cat_2_array: pure_unit_cost_name_big_list.map((unit_cat_2_name: any) => {
            return {
              unit_cost_cat_2_name: unit_cat_2_name,
              unit_cost_cat_2_total: cost_unit_cost_detail_results.filter((unit_cost: any) => unit_cost.result_at.seconds === costDate.result_at.seconds && unit_cost.category_2 === unit_cat_2_name).reduce((acc: any, el: any) => acc + el.cost_unit_cost_total, 0),
              unit_cost_cat_3_array: pure_unit_cost_name_list
                .filter((pure_unit_cost_name: any) => pure_unit_cost_name.category_2 === unit_cat_2_name)
                .map((unit_cat: any) => {
                  return {
                    unit_cost_cat_3_name: unit_cat.category_3,
                    unit_cost_cat_3_total: cost_unit_cost_detail_results.some((unit_cost: any) => unit_cost.result_at.seconds === costDate.result_at.seconds && unit_cost.category_3 === unit_cat.category_3)
                    ?
                    cost_unit_cost_detail_results.some((unit_cost: any) => unit_cost.result_at.seconds === costDate.result_at.seconds && unit_cost.category_3 === unit_cat.category_3).cost_unit_cost_total
                    :
                    null
                  };
                })
            };
          }),

          svg_cost_cat_1_name: "販管費",

          svg_cost_cat_1_total: cost_svg_cost_detail_results.filter((svg_cost: any) => svg_cost.result_at.seconds === costDate.result_at.seconds).reduce((acc: any, el: any) => acc + el.cost_svg_cost_total, 0),

          svg_cost_cat_2_array: pure_svg_cost_name_big_list.map((svg_cat_2_name: any) => {
            return {
              svg_cost_cat_2_name: svg_cat_2_name,
              svg_cost_cat_2_total: cost_svg_cost_detail_results.filter((svg_cost: any) => svg_cost.result_at.seconds === costDate.result_at.seconds && svg_cost.category_2 === svg_cat_2_name).reduce((acc: any, el: any) => acc + el.cost_svg_cost_total, 0),
              svg_cost_cat_3_array: pure_svg_cost_name_list
                .filter((pure_svg_cost_name: any) => pure_svg_cost_name.category_2 === svg_cat_2_name)
                .map((svg_cat: any) => {
                  return {
                    svg_cost_cat_3_name: svg_cat.category_3,
                    svg_cost_cat_3_total: 
                    cost_svg_cost_detail_results.some((svg_cost: any) => svg_cost.result_at.seconds === costDate.result_at.seconds && svg_cost.category_3 === svg_cat.category_3)
                    ?
                    cost_svg_cost_detail_results.find((svg_cost: any) => svg_cost.result_at.seconds === costDate.result_at.seconds && svg_cost.category_3 === svg_cat.category_3).cost_svg_cost_total
                    :
                    null
                  };
                })
            };
          })
        };
      });

      return {
        ...state,
        revenue_detail_results: revenue_detail_results,
        product_name_list: product_name_list,
        cost_unit_cost_detail_results: cost_unit_cost_detail_results,
        cost_svg_cost_detail_results: cost_svg_cost_detail_results,
        unit_cost_name_list: pure_unit_cost_name_list,
        svg_cost_name_list: pure_svg_cost_name_list,
        svg_cost_name_big_list: pure_svg_cost_name_big_list,
        integrated_array: integrated_array
      };

    case GET_INTEGRATED_ARRAY_FORECAST_FAILED:
      return state;

    case SET_PL_GROSS_PROFIT_OBJECTIVE:
      return {
        ...state,
        integrated_array:state.integrated_array.map((item: any, index: number) =>{
          if (item !== action.index) {
            return item
          }
          return {
            ...item,
            profit_gross_profit_objective: action.calculated_gross_profit
          }
        })
      }

    case SET_PL_OPERATING_PROFIT_OBJECTIVE:
      return {
        ...state,
        integrated_array:state.integrated_array.map((item: any, index: number) =>{
          if (index !== action.index) {
            return item
          }
          return {
            ...item,
            profit_operating_profit_objective: action.calculated_operating_profit
          }
        })
      }
    
    case REMOVE_FUTURE_DATA_FROM_INTEGRATED_DATA:
      return {
        ...state,
        integrated_array: action.integrated_array.filter((result: any) => result.real_data === true)
      }

    default:
      return state;
  }
};

export default targetDataReducer;
