import React, { useCallback, useEffect } from "react";
import FileSaver from "file-saver";
import {
  updateDownloadGraphStatus,
} from "../../../reducks/download/actions";
import {
  updateDownloadCsvTarget,
} from "../../../reducks/download/actions";
import { useCurrentPng } from "recharts-to-png";
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import ForecastSaleCostGraphDefault from './ForecastSaleCostGraphDefault'
import ForecastSaleCostTableDefault from './ForecastSaleCostTableDefault'
import GraphDataDownload from "../../Parts/GraphDataDownload";

export default function ForecastSaleCostIndex() {

  const dispatch = useDispatch();
  const opacity_object = useSelector((state: any) => state.raw_data.opacity_object);
  const raw_data_array = useSelector((state: any) => state.raw_data.raw_data_array);
  const resultSpan = useSelector((state: any) => state.target_data.resultSpan);

  const saleCostGraphData:any = [];
  var salesCostSum:number = 0;
  
  if (raw_data_array && raw_data_array.length) {
    raw_data_array.forEach((result: any) => {
      salesCostSum += result.cost_sales_marketing_cost.cost_sales_marketing_cost_total + result.cost_research_development_cost.cost_research_development_cost_total + result.cost_general_administrative_cost.cost_general_administrative_cost_total;
      saleCostGraphData.push({
        result_at: result.result_at,
        cost_sales_marketing_cost_revenue_rate: result.cost_sales_marketing_cost.cost_sales_marketing_cost_revenue_rate,
        cost_research_development_cost_revenue_rate: result.cost_research_development_cost.cost_research_development_cost_revenue_rate,
        cost_general_administrative_cost_revenue_rate: result.cost_general_administrative_cost.cost_general_administrative_cost_revenue_rate,
      });
    });
  }

  // Graph Download Section
  const [getAreaPng, { ref: areaRef }] = useCurrentPng();
  const handleAreaDownload = useCallback(async (target='') => {
    dispatch(
      updateDownloadGraphStatus(0)
    )
    const png = await getAreaPng();
    if (png) {
      FileSaver.saveAs(png, "graph.png");
    }
  }, [getAreaPng, dispatch]);

  function handleGraphDataDownloadClick(e:any, target:string) {
    if(e.target.innerText==='グラフ Export'){
      handleAreaDownload(target);
    }
    dispatch(
      updateDownloadCsvTarget("/"+target)
    )
  }

  return (
    <ContentCard>
      <CardBody>
        <CardTitleGraphDownloadWrapper>
          <CardTitle>売上対コスト比率</CardTitle>
          <div onClick={(e) => {handleGraphDataDownloadClick(e, 'sales_to_cost')}}>
            <GraphDataDownload />
          </div>
        </CardTitleGraphDownloadWrapper>
        <br />
        {saleCostGraphData.length > 0 && salesCostSum > 0 ? (
          <>
            <ForecastSaleCostGraphDefault opacity_object={opacity_object} saleCostGraphData={saleCostGraphData} resultSpan={resultSpan} areaRef={areaRef}/>
            <ForecastSaleCostTableDefault raw_data_array={raw_data_array} resultSpan={resultSpan} />
          </>
        ) : (
          <NoData>データがありません。</NoData>
        )}
      </CardBody>
    </ContentCard>
  )
}


// ContentCard
const ContentCard = styled.div`
  margin-bottom: 16px;
  border: 1px solid #e1e8ea;
  box-sizing: border-box;
  background-color: #fff;
`;
// CardBody
const CardBody = styled.div`
  padding: 15px 17px;
  box-sizing: border-box;
  position: relative;
`;

// CardTitle
const CardTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #2a3385;
  margin: 0 0 14px 0;
  display : flex;
  flex-direction : row;
  align-items : center;
`;

//NoData
const NoData = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #435878;
  margin-bottom: 85px;
  text-align: center;
`

const CardTitleGraphDownloadWrapper = styled.div({
  flexGrow: 1,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});
