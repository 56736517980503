import rsf from "../../rsf"
import { all, call, put, takeLatest, takeEvery } from "redux-saga/effects"
import firebase from "firebase"
const db = firebase.firestore()


// 指定されたカスタム期間の過去のサマリーデータ
function* getRawDataPastCustom(company_id, forecast_base_span, forecast_custom_span_start_time,
  forecast_custom_span_end_time) {
  try {
    // 当月
    const thisMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const month = thisMonth.getMonth();

    // 12,24,36ヶ月前
    // const forecastBaseStart = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - forecast_base_span - 1));
    const forecastBaseStart = new Date(forecast_custom_span_start_time * 1000);
    const forecastBaseEnd = new Date(forecast_custom_span_end_time * 1000);

    console.log('forecastBaseStart', forecastBaseStart);
    console.log('forecastBaseEnd', forecastBaseEnd);
    let raw_data_array_past = [];
    const snapshot = yield call(
      rsf.firestore.getCollection,
      db.collection(`companies/${company_id}/result_monthly`)
        .where("result_at", ">=", forecastBaseStart)
        .where("result_at", "<=", forecastBaseEnd)
      );

    // 取得後データを整形
    snapshot.forEach(result_monthly => {
      raw_data_array_past = [...raw_data_array_past, { ...result_monthly.data(), docId: result_monthly.id }];
    });

    return raw_data_array_past;
  } catch (e) {
    console.log("error message", e.message);
  }
}


export default getRawDataPastCustom;