import rsf from "../rsf";
import { call, put, takeLatest } from "redux-saga/effects";
// import { fetchFirestoreUser } from "../ducks/api";

// ワーカー Saga: USER_FETCH_REQUESTED Action によって起動する
function* fetchUser(action: any) {
  try {
    const snapshot = yield call(rsf.firestore.getDocument, `users/${action.payload}`);
    const user = snapshot.data();
    yield put({ type: "USER_FETCH_SUCCEEDED", user: user });
  } catch (e) {
    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* fetchUsers() {
  try {
    console.log("Fetching users");
    const snapshot = yield call(rsf.firestore.getCollection, "users");
    let users: Object[] = [];
    snapshot.forEach((user: any) => {
      // users = {
      //   ...users,
      //   [user.id]: user.data()
      // };
      users = [...users, user.data()]
    });
    console.log(users);
    yield put({ type: "USERS_FETCH_SUCCEEDED", payload: users });
  } catch (e) {
    yield put({ type: "USERS_FETCH_FAILED", message: e.message });
  }
}

function* addUser(action: any) {
  try {

    const doc = yield call(
      rsf.firestore.addDocument,
      'users',
      {
        // ID: action.payload.userId,
        name: action.payload.userName,
        email: action.payload.userEmail
        // name: 'test2',
        // email: 'test2@email.com'
      }
    )

    console.log('added user', doc);
    console.log("Fetching users...");
    const snapshot = yield call(rsf.firestore.getCollection, "users");
    let users: Object[] = [];
    snapshot.forEach((user: any) => {
      // users = {
      //   ...users,
      //   [user.id]: user.data()
      // };
      users = [...users, user.data()]
    });
    console.log('fetched users: ', users);
    yield put({ type: "USER_ADD_SUCCEEDED", payload: users });
  } catch (e) {
    yield put({ type: "USER_ADD_FAILED", message: e.message });
  }
}


/*
  USER_FETCH_REQUESTED Action が送出されるたびに fetchUser を起動します。
  ユーザ情報の並列取得にも対応しています。
*/
// function* mySaga() {
//   yield takeEvery("USER_FETCH_REQUESTED", fetchUser);
// }

/*
  代わりに takeLatest を使うこともできます。

  しかし、ユーザ情報の並列取得には対応しません。
  もしレスポンス待ちの状態で USER_FETCH_REQUESTED を受け取った場合、
  待ち状態のリクエストはキャンセルされて最後の1つだけが実行されます。
*/
const usersSagas = [ 
  takeLatest("USER_FETCH_REQUESTED", fetchUser),
  takeLatest("USERS_FETCH_REQUESTED", fetchUsers),
  takeLatest("USER_ADD_REQUESTED", addUser)
];

export default usersSagas;
