import rsf from "../../rsf"
import { all, call, put, takeLatest, takeEvery } from "redux-saga/effects"
import firebase from "firebase"

import { GET_RESULTS_FOR_TARGET_FORECAST_REQUEST, GET_RESULTS_FOR_TARGET_FORECAST_SUCCEEDED, GET_RESULTS_FOR_TARGET_FORECAST_FAILED, GET_INTEGRATED_ARRAY_FORECAST_REQUEST, GET_INTEGRATED_ARRAY_FORECAST_SUCCEEDED,  GET_INTEGRATED_ARRAY_FORECAST_FAILED, SET_FORECAST_CUSTOM_SPAN, GET_RESULTS_FOR_TARGET_FORECAST_CUSTOM_REQUEST } from "../../reducks/targetForecast/types"

const db = firebase.firestore()


// Forecast PL サマリー、詳細データ取得。未来予測をかけていない状態
function* getIntegratedArray(action) {
  try {

    // 期間設定
    const start_time = new Date(action.custom_span_start_time.seconds * 1000);
    const end_time = new Date(action.custom_span_end_time.seconds * 1000);
    // 当月
    const thisMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const month = thisMonth.getMonth();

    // 昨月
    const lastMonth = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 1));

    // 翌月
    const nextMonth = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month + 1));

    // 一昨月
    const lastLastMonth = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 2));
    
    // 3ヶ月前
    const threeMonthAgo = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 3));

    // 6ヶ月前
    const last6Months = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 6));

    // 12ヶ月前
    const last12Months = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 12));

    // コレクショングループでためす
    const targetCollectionGroup = collection_name => {
      if (action.selected_data_config_data_span === 'custom') {
        return db
          .collectionGroup(collection_name)
          .where('company_id', '==', action.company_id)
          .where('result_at', '>=', start_time)
          .where('result_at', '<=', end_time)
          .where('resultSpan', '==', action.selected_data_config_data_type)
          .get();
      } else if (action.selected_data_config_data_span === 'this_month') {
        return db
          .collectionGroup(collection_name)
          .where('company_id', '==', action.company_id)
          .where('result_at', '>=', lastLastMonth)
          .where('result_at', '<=', thisMonth)
          .where('resultSpan', '==', action.selected_data_config_data_type)
          .get();
      } else if (action.selected_data_config_data_span === 'last_month') {
        return db
          .collectionGroup(collection_name)
          .where('company_id', '==', action.company_id)
          .where('result_at', '>=', threeMonthAgo)
          .where('result_at', '<=', lastMonth)
          .where('resultSpan', '==', action.selected_data_config_data_type)
          .get();
      } else if (action.selected_data_config_data_span === 'last_6_months') {
        return db
          .collectionGroup(collection_name)
          .where('company_id', '==', action.company_id)
          .where('result_at', '>=', last6Months)
          .where('result_at', '<=', lastMonth)
          .where('resultSpan', '==', action.selected_data_config_data_type)
          .get();
      } else if (action.selected_data_config_data_span === 'last_12_months') {
        return db
          .collectionGroup(collection_name)
          .where('company_id', '==', action.company_id)
          .where('result_at', '>=', last12Months)
          .where('result_at', '<=', lastMonth)
          .where('resultSpan', '==', action.selected_data_config_data_type)
          .get();
      } else {
        return db
          .collectionGroup(collection_name)
          .where('company_id', '==', action.company_id)
          .where('result_at', '>=', start_time)
          .where('result_at', '<=', end_time)
          .where('resultSpan', '==', action.selected_data_config_data_type)
          .get();
      }
    };
    const revenue_snapshot = yield call(targetCollectionGroup, 'revenue_detail');
    const cost_unit_cost_snapshot = yield call(targetCollectionGroup, 'cost_unit_cost_detail');
    const cost_svg_cost_snapshot = yield call(targetCollectionGroup, 'cost_svg_cost_detail');

    let revenue_detail_results = [];
    let cost_unit_cost_detail_results = [];
    let cost_svg_cost_detail_results = [];
    revenue_snapshot.forEach(doc => {
      revenue_detail_results = [...revenue_detail_results, { ...doc.data(), docId: doc.id }];
      revenue_detail_results = revenue_detail_results.filter((result) => result.filter === 'product' && result.resultSpan === action.selected_data_config_data_type );
      // revenue_detail_results = revenue_detail_results.map((result) => result.list);
    });
    cost_unit_cost_snapshot.forEach(doc => {
      cost_unit_cost_detail_results = [...cost_unit_cost_detail_results, { ...doc.data(), docId: doc.id }];
      cost_unit_cost_detail_results = cost_unit_cost_detail_results.filter((result) => result.category_1 === '原価' );
    });
    cost_svg_cost_snapshot.forEach(doc => {
      cost_svg_cost_detail_results = [...cost_svg_cost_detail_results, { ...doc.data(), docId: doc.id }];
      cost_svg_cost_detail_results = cost_svg_cost_detail_results.filter((result) => result.category_1 === '販管費' );
    });
    console.log("revenue_detail_results", revenue_detail_results )

    // return results;
    yield put({
      type: GET_INTEGRATED_ARRAY_FORECAST_SUCCEEDED,
      revenue_detail_results: revenue_detail_results,
      cost_unit_cost_detail_results: cost_unit_cost_detail_results,
      cost_svg_cost_detail_results: cost_svg_cost_detail_results,
      selected_data_config_data_type: action.selected_data_config_data_type,
      raw_data_array: action.raw_data_array
    })

  } catch (e) {
    yield put({ type: GET_INTEGRATED_ARRAY_FORECAST_FAILED, message: e.message})
  }
}

// const forecastSagas = [takeLatest(GET_INTEGRATED_ARRAY_FORECAST_REQUEST, getIntegratedArray)]

// export default forecastSagas

export default getIntegratedArray;



