import { SET_FILTER } from "./types"
import {RESET_ALL} from "../common/types";

const initialState: string = ""

const filterReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_ALL:
      return initialState;

    case SET_FILTER:
      if (state === action.filter_name) {
        return ""
      } else {
        return action.filter_name
      }

    default:
      return state
  }
}

export default filterReducer
