// import update from "immutability-helper";
import { GET_PRODUCTS_SUCCEEDED, GET_PRODUCTS_FAILED } from "./types"
import {RESET_ALL} from "../common/types";

const initialState: object[] = [
  {
    id: '',
    name: '',
    unit_price: 100
  }
]

const productsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_ALL:
      return initialState;

    case GET_PRODUCTS_SUCCEEDED:
      return action.products

    case GET_PRODUCTS_FAILED:
      console.log('error', action.message);
      return state;

    default:
      return state
  }
}

export default productsReducer
