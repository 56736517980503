import rsf from '../rsf';
import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import firebase from 'firebase';
import {
  GET_RESULTS_REQUESTED,
  GET_RESULTS_SUCCEEDED,
  GET_RESULTS_FAILED,
  GET_RESULTS_REQUESTED_BY_DATE,
  GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON,
  GET_RESULTS_FOR_SPAN_COMPARISON_SUCCEEDED,
  GET_FILTERED_RESULTS_REQUEST,
  GET_FILTERED_RESULTS_SUCCEEDED,
  GET_FILTERED_RESULTS_FAILED,
  GET_FILTERED_FUNNEL_RESULTS_REQUEST,
  GET_FILTERED_FUNNEL_RESULTS_SUCCEEDED,
  GET_FILTERED_FUNNEL_RESULTS_FAILED,

  GET_TARGET_RESULTS_REQUESTED,
  GET_TARGET_RESULTS_SUCCEEDED,
  GET_TARGET_RESULTS_FAILED
} from '../reducks/results/types';

import { SET_MONTHLY_REVENUE } from '../reducks/revenue/monthly/types';
import { SET_WEEKLY_REVENUE } from '../reducks/revenue/weekly/types';
import { SET_DAILY_REVENUE } from '../reducks/revenue/daily/types';
import { SET_MONTHLY_ARPU } from '../reducks/arpu/monthly/types';
import { SET_WEEKLY_ARPU } from '../reducks/arpu/weekly/types';
import { SET_DAILY_ARPU } from '../reducks/arpu/daily/types';

import { HIDE_LOADING } from '../reducks/loading/types';

const db = firebase.firestore();

function* getResults(action) {
  try {
    const result_span = action.result_span;

    if (result_span === 'monthly') {
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db
          .collection(`companies/${action.company_id}/result_monthly`)
          .where(
            'result_at',
            '>=',
            firebase.firestore.Timestamp.fromDate(action.data_from)
          )
          .where(
            'result_at',
            '<=',
            firebase.firestore.Timestamp.fromDate(action.data_to)
          )
      );
      let results = [];

      snapshot.forEach(result => {
        results = [...results, { ...result.data() }];
      });
      yield all([
        put({
          type: GET_RESULTS_SUCCEEDED,
          results: results,
          resultSpan: result_span
        }),
        put({
          type: SET_MONTHLY_REVENUE,
          result_monthly: results
        }),
        put({
          type: SET_MONTHLY_ARPU,
          result_monthly: results
        }),
        put({ type: HIDE_LOADING })
      ]);
    } else if (result_span === 'weekly') {
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db
          .collection(`companies/${action.company_id}/result_weekly`)
          .where(
            'result_at',
            '>=',
            firebase.firestore.Timestamp.fromDate(action.data_from)
          )
          .where(
            'result_at',
            '<=',
            firebase.firestore.Timestamp.fromDate(action.data_to)
          )
      );
      let results = [];

      snapshot.forEach(result => {
        results = [...results, { ...result.data() }];
      });

      yield all([
        put({
          type: GET_RESULTS_SUCCEEDED,
          results: results,
          resultSpan: result_span
        }),
        put({
          type: SET_WEEKLY_REVENUE,
          result_weekly: results
        }),
        put({
          type: SET_WEEKLY_ARPU,
          result_weekly: results
        }),
        put({ type: HIDE_LOADING })
      ]);
    } else {
      console.log('monthly data');
      console.log('company_id', action.company_id);
      console.log('data_from', action.data_from);
      console.log('data_to', action.data_to);
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db
          .collection(`companies/${action.company_id}/result_daily`)
          .where(
            'result_at',
            '>=',
            firebase.firestore.Timestamp.fromDate(action.data_from)
          )
          .where(
            'result_at',
            '<=',
            firebase.firestore.Timestamp.fromDate(action.data_to)
          )
      );
      let results = [];
      snapshot.forEach(result => {
        results = [...results, { ...result.data() }];
      });

      yield all([
        put({
          type: GET_RESULTS_SUCCEEDED,
          results: results,
          resultSpan: result_span
        }),
        put({
          type: SET_DAILY_REVENUE,
          result_daily: results
        }),
        put({
          type: SET_DAILY_ARPU,
          result_daily: results
        }),
        put({ type: HIDE_LOADING })
      ]);
    }
  } catch (e) {
    yield all([
      put({ type: GET_RESULTS_FAILED, message: e.message }),
      put({ type: HIDE_LOADING })
    ]);
  }
}

function* getTargetResults(action) {
  try {
    const result_span = action.result_span;

    if (result_span === 'monthly') {
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db
          .collection(`companies/${action.company_id}/result_monthly`)
          .where(
            'result_at',
            '>=',
            firebase.firestore.Timestamp.fromDate(action.data_from)
          )
          .where(
            'result_at',
            '<=',
            firebase.firestore.Timestamp.fromDate(action.data_to)
          )
      );
      let results = [];

      snapshot.forEach(result => {
        results = [...results, { ...result.data() }];
      });
      yield all([
        put({
          type: GET_TARGET_RESULTS_SUCCEEDED,
          results: results,
          resultSpan: result_span,
          target_data_name: action.target_data_name,
          location: action.location
        }),
        put({ type: HIDE_LOADING })
      ]);
    } else if (result_span === 'weekly') {
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db
          .collection(`companies/${action.company_id}/result_weekly`)
          .where(
            'result_at',
            '>=',
            firebase.firestore.Timestamp.fromDate(action.data_from)
          )
          .where(
            'result_at',
            '<=',
            firebase.firestore.Timestamp.fromDate(action.data_to)
          )
      );
      let results = [];

      snapshot.forEach(result => {
        results = [...results, { ...result.data() }];
      });

      yield all([
        put({
          type: GET_TARGET_RESULTS_SUCCEEDED,
          results: results,
          resultSpan: result_span,
          target_data_name: action.target_data_name,
          location: action.location
        }),
        put({ type: HIDE_LOADING })
      ]);
    } else {
      console.log('monthly data');
      console.log('company_id', action.company_id);
      console.log('data_from', action.data_from);
      console.log('data_to', action.data_to);
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db
          .collection(`companies/${action.company_id}/result_daily`)
          .where(
            'result_at',
            '>=',
            firebase.firestore.Timestamp.fromDate(action.data_from)
          )
          .where(
            'result_at',
            '<=',
            firebase.firestore.Timestamp.fromDate(action.data_to)
          )
      );
      let results = [];
      snapshot.forEach(result => {
        results = [...results, { ...result.data() }];
      });

      yield all([
        put({
          type: GET_TARGET_RESULTS_SUCCEEDED,
          results: results,
          resultSpan: result_span,
          target_data_name: action.target_data_name,
          location: action.location
        }),
        put({ type: HIDE_LOADING })
      ]);
    }
  } catch (e) {
    yield all([
      put({ type: GET_TARGET_RESULTS_FAILED, message: e.message }),
      put({ type: HIDE_LOADING })
    ]);
  }
}

// ======================================================= //
// ページヘッダーのカレンダーからデータを取得する用（月、日切り替え）
// ======================================================= //

function* getResultsByDate(action) {
  console.log('fetching result');
  const start = action.startDate;
  const startMonth = start.getMonth();
  const startYear = start.getFullYear();
  const startDate = start.getDate();
  const startDayAM00 = new Date(startYear, startMonth, startDate); //action.startDateは時間が現在時刻のため0:00にしたい（日次表示表）
  const startDay0 = new Date(startYear, startMonth, 1); //action.startDateは日付が半端なので１日にしたい。(月次表示用)

  const end = action.endDate;

  try {
    const resultSpan = action.resultSpan;
    let snapshot = [];

    if (resultSpan === 'daily') {
      snapshot = yield call(
        rsf.firestore.getCollection,
        db
          .collection(`companies/${action.company_id}/result_daily`)
          .where('dateTime', '>=', startDayAM00)
          .where('dateTime', '<=', end)
      );
    } else if (resultSpan === 'weekly') {
      snapshot = yield call(
        rsf.firestore.getCollection,
        db
          .collection(`companies/${action.company_id}/result_weekly`)
          .where('dateTime', '>=', startDayAM00)
          .where('dateTime', '<=', end)
      );
    } else {
      snapshot = yield call(
        rsf.firestore.getCollection,
        db
          .collection(`companies/${action.company_id}/result_monthly`)
          .where('dateTime', '>=', startDay0)
          .where('dateTime', '<=', end)
      );
    }
    let results = [];

    snapshot.forEach(result => {
      results = [...results, { ...result.data() }];
    });
    console.log('results', results);
    // yield put({ type: GET_RESULTS_SUCCEEDED, results: results, resultSpan: resultSpan })
  } catch (e) {
    yield put({ type: GET_RESULTS_FAILED, message: e.message });
  }
}

// ======================================================= //
// 期間比較ののカレンダーからデータを取得する用（月、日切り替え）
// ======================================================= //
function* getResultsForSpanComparison(action) {
  console.log('fetching result');
  const start = action.startDate;
  const startMonth = start.getMonth();
  const startYear = start.getFullYear();
  const startDate = start.getDate();
  const startDayAM00 = new Date(startYear, startMonth, startDate); //action.startDateは時間が現在時刻のため0:00にしたい（日次表示表）
  const startDay0 = new Date(startYear, startMonth, 1); //action.startDateは日付が半端なので１日にしたい。(月次表示用)

  const end = action.endDate;

  try {
    const resultSpan = action.resultSpan;
    let snapshot = [];

    if (resultSpan === 'daily') {
      snapshot = yield call(
        rsf.firestore.getCollection,
        db
          .collection(`companies/${action.company_id}/result_daily`)
          .where('dateTime', '>=', startDayAM00)
          .where('dateTime', '<=', end)
      );
    } else if (resultSpan === 'weekly') {
      snapshot = yield call(
        rsf.firestore.getCollection,
        db
          .collection(`companies/${action.company_id}/result_weekly`)
          .where('dateTime', '>=', startDayAM00)
          .where('dateTime', '<=', end)
      );
    } else {
      snapshot = yield call(
        rsf.firestore.getCollection,
        db
          .collection(`companies/${action.company_id}/result_monthly`)
          .where('dateTime', '>=', startDay0)
          .where('dateTime', '<=', end)
      );
    }
    let results = [];

    snapshot.forEach(result => {
      results = [...results, { ...result.data() }];
    });
    console.log('results', results);
    yield put({
      type: GET_RESULTS_FOR_SPAN_COMPARISON_SUCCEEDED,
      results: results,
      resultSpan: resultSpan,
      spanOrder: action.spanOrder
    });
    // yield put({ type: GET_RESULTS_SUCCEEDED, results: results, resultSpan: resultSpan })
  } catch (e) {
    yield put({ type: GET_RESULTS_FAILED, message: e.message });
  }
}

// ======================================================= //
// 商品・変動比較フィルターボタンからデータを取得する用
// ======================================================= //
function* getFilteredResults(action) {
  try {
    const result_span = action.resultSpan;
    // let snapshot = []

    const collection_name = action.result_data_type + '_detail';
    console.log('collection_name', collection_name);

    let results = [];

    // コレクショングループでためす
    const newCollectionGroup = collection_name => {
      return db
        .collectionGroup(collection_name)
        .where('company_id', '==', action.company_id)
        .where('result_at', '>=', new Date(action.from.seconds * 1000))
        .where('result_at', '<=', new Date(action.to.seconds * 1000))
        .get();
    };
    const snapshot = yield call(newCollectionGroup, collection_name);

    snapshot.forEach(doc => {
      results = [...results, { ...doc.data(), docId: doc.id }];
    });
    console.log('snapshotttttt', snapshot);
    console.log('resultsssss', results);

    yield all([
      put({
        type: GET_FILTERED_RESULTS_SUCCEEDED,
        results: results,
        result_data_type: action.result_data_type,
        filter_name: action.filter_name,
        from: action.from
      }),
      put({ type: HIDE_LOADING })
    ]);
  } catch (e) {
    yield all([
      put({ type: GET_FILTERED_RESULTS_FAILED, message: e.message }),
      put({ type: HIDE_LOADING })
    ]);
  }
}

// ======================================================= //
// 商品・変動比較フィルターボタンからデータを取得する用
// ======================================================= //
function* getFilteredFunnelResults(action) {
  try {
    const result_span = action.resultSpan;
    // let snapshot = []

    const collection_name = action.result_data_type + '_detail';
    console.log('collection_name', collection_name);

    // if (result_span === "daily") {
    //   // snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${company_id}/result_daily`).where("date", "==", "5"))
    //   snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${company_id}/result_daily`).where("result_at", ">=", new Date(from.seconds * 1000)).where("result_at", "<=", new Date(to.seconds * 1000)))
    // } else if (result_span === "weekly") {
    //   snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${company_id}/result_weekly`).where("result_at", ">=", new Date(from.seconds * 1000)).where("result_at", "<=", new Date(to.seconds * 1000)))
    // } else {
    //   console.log("monthly data")
    //   snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${company_id}/result_monthly`).where("result_at", ">=", new Date(from.seconds * 1000)).where("result_at", "<=", new Date(to.seconds * 1000)))
    // }
    // snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_monthly`).where("result_at", ">=", new Date(action.from.seconds * 1000)).where("result_at", "<=", new Date(action.to.seconds * 1000)))

    // let result_monthly = db.collectionGroup(`companies/${action.company_id}/result_monthly`).where("result_at", ">=", new Date(action.from.seconds * 1000)).where("result_at", "<=", new Date(action.to.seconds * 1000));
    // console.log('result_monthly', result_monthly);

    // result_monthly.get().then(snapshot => {
    //   snapshot.forEach((result) => {
    //     results = [...results, { ...result.data() }]
    //   })
    // })

    let results = [];

    // forEachでためす：https://medium.com/@knt.yamada.800/firestore-%E3%82%B5%E3%83%96%E3%82%B3%E3%83%AC%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3%E3%82%92%E5%90%AB%E3%82%81%E3%81%9F%E3%83%89%E3%82%AD%E3%83%A5%E3%83%A1%E3%83%B3%E3%83%88%E3%83%87%E3%83%BC%E3%82%BF%E3%81%AE%E5%8F%96%E5%BE%97%E6%96%B9%E6%B3%95-fad9c6c4cd61
    // const collection = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_monthly`).where("result_at", ">=", new Date(action.from.seconds * 1000)).where("result_at", "<=", new Date(action.to.seconds * 1000)))
    // collection.forEach(async doc => {
    //   console.log(doc.data());
    //   const subCollection = await doc.ref.collection('revenue_detail').get();
    //   console.log('subCollection', subCollection);

    //   subCollection.forEach(doc => {
    //     // results = [...results, {...doc.data()}]
    //     results.push({...doc.data()})
    //   });
    // });
    // console.log('results results', results);

    // コレクショングループでためす
    const newCollectionGroup = collection_name => {
      return db
        .collectionGroup(collection_name)
        .where('company_id', '==', action.company_id)
        .where('result_at', '>=', new Date(action.from.seconds * 1000))
        .where('result_at', '<=', new Date(action.to.seconds * 1000))
        .get();
    };
    const snapshot = yield call(newCollectionGroup, collection_name);

    snapshot.forEach(doc => {
      results = [...results, { ...doc.data(), docId: doc.id }];
    });
    console.log('snapshotttttt', snapshot);
    console.log('resultsssss', results);

    yield all([
      put({
        type: GET_FILTERED_FUNNEL_RESULTS_SUCCEEDED,
        results: results,
        result_data_type: action.result_data_type,
        filter_name: action.filter_name,
        from: action.from,
        urlFunnelName: action.urlFunnelName
      }),
      put({ type: HIDE_LOADING })
    ]);
  } catch (e) {
    yield all([
      put({ type: GET_FILTERED_FUNNEL_RESULTS_FAILED, message: e.message }),
      put({ type: HIDE_LOADING })
    ]);
  }
}

const resultsSagas = [
  takeLatest(GET_RESULTS_REQUESTED, getResults),
  takeLatest(GET_TARGET_RESULTS_REQUESTED, getTargetResults),
  takeLatest(GET_RESULTS_REQUESTED_BY_DATE, getResultsByDate),
  takeEvery(
    GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON,
    getResultsForSpanComparison
  ),
  takeLatest(GET_FILTERED_RESULTS_REQUEST, getFilteredResults),
  takeLatest(GET_FILTERED_FUNNEL_RESULTS_REQUEST, getFilteredFunnelResults)
];

export default resultsSagas;
