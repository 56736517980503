import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import GlobalNav from "../../Layout/GlobalNav";
import PageHeaderRaw from "../../Layout/PageHeader";
import ConfigNavCard from "../ConfigNavCard";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      backgroundColor: "#F4F4F4"
    },
    configCard: {
      display: "flex",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    }
  })
);

// Container
const Container = styled.div`
  display: flex;
`;

// PlanContent
const PlanContent = styled.div`
  width: 100%;
  height: 100vh;
  padding: 28px 40px;
  box-sizing: border-box;
  background-color: #fff;
`;
// PlanHeading
const PlanHeading = styled.h2`
  font-size: 18px;
  font-weight: bold;
  line-height: 27px;
  color: #283f46;
  margin-bottom: 18px;
`;

const ContentCard = styled.div`
  display: flex;
  justify-content: space-between;
  color: #435878;
  border-bottom: 0.5px solid #F0F0F0;
  padding-bottom:16px;
  margin-bottom: 16px;
  position: relative;
  &:last-of-type {
    border-bottom: none;
  }
`

const ContentHeading = styled.h2`
  font-size: 16px;
  margin: 0;
`

const ContentDetail = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
  font-size: 12px;
  border-radius: 4px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  width: 504px;
  padding: 16px 24px;
  margin: 0;
`

const DetailItem = styled.dl`
 margin: 0;
`

const DetailLabel = styled.dt`
  font-weight: bold;
  margin-bottom: 8px;
`

const DetailData = styled.dd`
  margin: 0;
`

const PlanSetting: React.FC = () => {
  const location = useLocation();
	const pathname = location.pathname;
  // GA計測タグ
	useEffect(() => {
		document.title = 'プラン管理'; // ページタイトルを変更
		window.gtag('config', 'G-YXXD65BEJ0', {
			'page_path': pathname // 任意のパス（あるいはprops.location.pathnameなど）
		});
	});

  const classes = useStyles();

  // Storeからプラン情報を取得
  const plan = useSelector((state: any) => state.plan);

  return (
    <Container>
      <GlobalNav />

      <main className={classes.content}>
        <PageHeaderRaw page_title="管理" />

        <div className={classes.configCard}>
          <ConfigNavCard />

          <PlanContent>
            <PlanHeading>プラン</PlanHeading>

            {/* メインコンテンツ */}
            <div>
              {/* プラン詳細 */}
              <ContentCard className="contentCard">
                <ContentHeading>プラン詳細</ContentHeading>
                <ContentDetail>
                  <DetailItem>
                    <DetailLabel>登録日</DetailLabel>
                    <DetailData>{plan.registered_at}</DetailData>
                  </DetailItem>
                  <DetailItem>
                    <DetailLabel>MRR</DetailLabel>
                    <DetailData>{plan.mrr}</DetailData>
                  </DetailItem>
                  <DetailItem>
                    <DetailLabel>ステータス</DetailLabel>
                    <DetailData>{plan.status}</DetailData>
                  </DetailItem>
                </ContentDetail>
              </ContentCard>
            </div>
          </PlanContent>
        </div>
      </main>
    </Container>
  );
};

export default PlanSetting;
