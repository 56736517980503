import React, { useState, useEffect, useRef } from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove
} from "react-sortable-hoc";
import { exportComponentAsPNG } from 'react-component-export-image';
import {
  updateLayoutRequest,
  getLayoutRequest
} from "../../reducks/grid/gridIndex";
import draggable from "../../../src/images/icons/dragabble.svg";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
//---components---//
import GlobalNav from "../Layout/GlobalNav";
import PageHeader from "../Layout/PageHeader";
import TopGraph from "./TopGraph";
import ThisMonthSummary from "../Parts/ThisMonthSummary";
import TopGraphLabel from "./TopGraphLabel";
//---icons---//
import close from "../../images/icons/close-large.svg";
import { setForecastFilterStatus } from "../../reducks/targetForecast/actions";
//---functions---//
import {
  dataErrorEscaper,
  dataForFunnelErrorEscaper
} from "../Util/dataErrorEscaper";
import addCommentIconOpen from "../../../src/images/icons/add_blue_open.svg";
import addCommentIconClose from "../../../src/images/icons/add_blue_close.svg";

const Top = () => {
  //---selector---//
  const contents = useSelector((state: any) => state.grid.contents);
  const company_id = useSelector((state: any) => state.user.company_id);
  const resultSpan = useSelector((state: any) => state.target_data.resultSpan);
  const selected_data_config_data_type = useSelector(
    (state: any) => state.raw_data.selected_data_config.data_type
  );
  const raw_data_array = useSelector(
    (state: any) => state.raw_data.raw_data_array
  );
  const dispatch = useDispatch();

  const [selectBoxIsShown, setSelectBoxIsShown] = useState(false);

  //---useEffect---//
  useEffect(() => {
    dispatch(getLayoutRequest(company_id));
    dispatch(setForecastFilterStatus("reset_forecast"));
  }, [company_id, dispatch]);

  const [gridContents, setGridContents] = useState(contents);
  console.log("gridContents in TopNew", gridContents);
  useEffect(() => {
    setGridContents(contents);
  }, [contents]);

  // Graph Download Section
  const graphAreaRef = useRef<HTMLDivElement>(null);
  const handleExport = () => {
    exportComponentAsPNG(graphAreaRef);
  };

  const handleSave = () => {
    dispatch(updateLayoutRequest(company_id, gridContents));
    NotificationManager.info(
      "Dashboardが保存されました",
      "Dashboard SAVED",
      1500
    );
  };

  // アイテムの表示切り替え
  const toggleGraph = (target_graph_name: string) => {
    const contentsInitialState = gridContents;

    const contentsNewState = contentsInitialState.map((content: any) => {
      if (content.name === target_graph_name) {
        if (!content.display) {
          content.display = true;
        } else {
          content.display = false;
        }
      }
      return content;
    });
    setGridContents(contentsNewState);
  };

  const resulAtConverter = (graphData: any, dataKey: string) => {
    const resultAt = graphData
      .find((item: any) => item.graphName === dataKey)
      .data.map((data: any) => data.date)
      .slice(-1)[0];
    return resultAt;
  };

  // graphDataを作る。
  const dataConverter = (dataKey: string, dataArray: []) => {
    const newArray = dataArray.map((result: any) => result[dataKey]);
    return newArray;
  };

  // 最終月実績を取得する
  const totalConverter = (graphData: any, dataKey: string) => {
    const total = graphData
      .find((item: any) => item.graphName === dataKey)
      .data.map((data: any) => data[`${dataKey}_total`])
      .slice(-1)[0];
    return total;
  };

  /**
   * 最終月達成率を取得します
   * @param graphData
   * @param dataKey 第2引数のコメント
   */

  const rateConverter = (graphData: any, dataKey: string) => {
    const rate = graphData
      .find((item: any) => item.graphName === dataKey)
      .data.map((data: any) => data[`${dataKey}_rate`])
      .slice(-1)[0];
    return rate;
  };

  const resultDailyFormedForGraph = raw_data_array.map((result: any) => {
    //日付
    const dateInGraph = result.result_at.seconds;
    let dateInGraphToDisplay;
    if (selected_data_config_data_type !== "monthly") {
      dateInGraphToDisplay =
        (new Date(result.result_at.seconds * 1000).getMonth() + 1).toString() +
        "/" +
        new Date(result.result_at.seconds * 1000).getDate().toString();
    } else {
      dateInGraphToDisplay =
        new Date(result.result_at.seconds * 1000).getFullYear().toString() +
        "/" +
        (new Date(result.result_at.seconds * 1000).getMonth() + 1).toString();
    }

    // ARPU(売上総額 / 顧客数)
    const arpuDailyObject = {
      date: dateInGraph,
      arpu_total: dataErrorEscaper(result, "arpu", "total"),
      arpu_objective: dataErrorEscaper(result, "arpu", "objective"),
      arpu_moving_average: dataErrorEscaper(
        result,
        "arpu",
        "moving_average_total"
      ),
      arpu_moving_average_objective: dataErrorEscaper(
        result,
        "arpu",
        "moving_average_objective"
      ),
      arpu_rate: dataErrorEscaper(result, "arpu", "rate"),
      arpu_difference: dataErrorEscaper(result, "arpu", "difference"),
      arpu_last_year_comparison_total: dataErrorEscaper(
        result,
        "arpu",
        "last_year_comparison_total"
      ),
      dateInGraphToDisplay: dateInGraphToDisplay
    };

    // MRR
    const mrrDailyObject = {
      date: dateInGraph,
      MRR_total: dataErrorEscaper(result, "mrr_total_value", "total"),
      MRR_objective: dataErrorEscaper(result, "mrr_total_value", "objective"),
      MRR_moving_average: dataErrorEscaper(
        result,
        "mrr_total_value",
        "moving_average_total"
      ),
      MRR_moving_average_objective: dataErrorEscaper(
        result,
        "mrr_total_value",
        "moving_average_objective"
      ),
      MRR_rate: dataErrorEscaper(result, "mrr_total_value", "rate"),
      MRR_difference: dataErrorEscaper(result, "mrr_total_value", "difference"),
      MRR_last_year_comparison_total: dataErrorEscaper(
        result,
        "mrr_total_value",
        "last_year_comparison_total"
      ),
      dateInGraphToDisplay: dateInGraphToDisplay
    };

    // CAC（cac用合計コスト / 顧客数）
    const cacDailyObject = {
      date: dateInGraph,
      cac_total: dataErrorEscaper(result, "cac", "total"),
      cac_objective: dataErrorEscaper(result, "cac", "objective"),
      cac_moving_average: dataErrorEscaper(
        result,
        "cac",
        "moving_average_total"
      ),
      cac_moving_average_objective: dataErrorEscaper(
        result,
        "cac",
        "moving_average_objective"
      ),
      cac_rate: dataErrorEscaper(result, "cac", "rate"),
      cac_difference: dataErrorEscaper(result, "cac", "difference"),
      cac_last_year_comparison_total: dataErrorEscaper(
        result,
        "cac",
        "last_year_comparison_total"
      ),
      dateInGraphToDisplay: dateInGraphToDisplay
    };

    // Churn Rate 解約率
    const churnRateDailyObject = {
      date: dateInGraph,
      churnRate_total: dataErrorEscaper(result, "churn_rate", "total"),
      churnRate_objective: dataErrorEscaper(result, "churn_rate", "objective"),
      churnRate_moving_average: dataErrorEscaper(
        result,
        "churn_rate",
        "moving_average_total"
      ),
      churnRate_moving_average_objective: dataErrorEscaper(
        result,
        "churn_rate",
        "moving_average_objective"
      ),
      churnRate_rate: dataErrorEscaper(result, "churn_rate", "rate"),
      churnRate_difference: dataErrorEscaper(result, "churn_rate", "differnce"),
      churnRate_last_year_comparison_total: dataErrorEscaper(
        result,
        "churn_rate",
        "last_year_comparison_rate"
      ),
      dateInGraphToDisplay: dateInGraphToDisplay
    };

    // LTV(粗利率 * ARPU / 解約率)
    const LTVDailyObject = {
      date: dateInGraph,
      LTV_total: dataErrorEscaper(result, "ltv", "total"),
      LTV_objective: dataErrorEscaper(result, "ltv", "objective"),
      LTV_moving_average: dataErrorEscaper(
        result,
        "ltv",
        "moving_average_total"
      ),
      LTV_moving_average_objective: dataErrorEscaper(
        result,
        "ltv",
        "moving_average_total"
      ),
      LTV_rate: dataErrorEscaper(result, "ltv", "rate"),
      LTV_difference: dataErrorEscaper(result, "ltv", "difference"),
      LTV_last_year_comparison_total: dataErrorEscaper(
        result,
        "ltv",
        "last_year_comparison_total"
      ),
      dateInGraphToDisplay: dateInGraphToDisplay
    };

    // Payback Period(cac / (arpu * 売上総利益率))
    const PaybackPeriodDailyObject = {
      date: dateInGraph,
      PaybackPeriod_total: dataErrorEscaper(result, "payback_period", "total"),
      PaybackPeriod_objective: dataErrorEscaper(
        result,
        "payback_period",
        "objective"
      ),
      PaybackPeriod_moving_average: dataErrorEscaper(
        result,
        "payback_period",
        "moving_average_total"
      ),
      PaybackPeriod_moving_average_objective: dataErrorEscaper(
        result,
        "payback_period",
        "moving_average_objective"
      ),
      PaybackPeriod_rate: dataErrorEscaper(result, "payback_period", "rate"),
      PaybackPeriod_difference: dataErrorEscaper(
        result,
        "payback_period",
        "defference"
      ),
      PaybackPeriod_last_year_comparison_total: dataErrorEscaper(
        result,
        "payback_period",
        "last_year_comparison_total"
      ),
      dateInGraphToDisplay: dateInGraphToDisplay
    };

    // 有料転換率(有料化顧客 / 無料顧客)
    const conversionRateDailyObject = {
      date: dateInGraph,
      ConversionRate_total: dataErrorEscaper(
        result,
        "conversion_rate",
        "total"
      ),
      ConversionRate_objective: dataErrorEscaper(
        result,
        "conversion_rate",
        "objective"
      ),
      ConversionRate_moving_average: dataErrorEscaper(
        result,
        "conversion_rate",
        ",oving_average_total"
      ),
      ConversionRate_moving_average_objective: dataErrorEscaper(
        result,
        "conversion_rate",
        "moving_average_objecticve"
      ),
      ConversionRate_rate: dataErrorEscaper(result, "conversion_rate", "rate"),
      ConversionRate_difference: dataErrorEscaper(
        result,
        "conversion_rate",
        "difference"
      ),
      ConversionRate_last_year_comparison_total: dataErrorEscaper(
        result,
        "conversion_rate",
        "last_year_comparisdon_total"
      ),
      dateInGraphToDisplay: dateInGraphToDisplay
    };

    // Revenue
    const revenueDailyObject = {
      date: dateInGraph,
      Revenue_total: dataErrorEscaper(result, "revenue", "total"),
      Revenue_objective: dataErrorEscaper(result, "revenue", "objective"),
      Revenue_moving_average: dataErrorEscaper(
        result,
        "revenue",
        "moving_average_total"
      ),
      Revenue_moving_average_objective: dataErrorEscaper(
        result,
        "revenue",
        "moving_average_objective"
      ),
      Revenue_rate: dataErrorEscaper(result, "revenue", "rate"),
      Revenue_difference: dataErrorEscaper(result, "revenue", "difference"),
      Revenue_last_year_comparison_total: dataErrorEscaper(
        result,
        "revenue",
        "last_year_comparison_total"
      ),
      dateInGraphToDisplay: dateInGraphToDisplay
    };

    // UnitEconomics(LTV / CAC)
    const unitEconomicsDailyObject = {
      date: dateInGraph,
      UnitEconomics_total: dataErrorEscaper(result, "unit_economics", "total"),
      UnitEconomics_objective: dataErrorEscaper(
        result,
        "unit_economics",
        "objective"
      ),
      UnitEconomics_moving_average: dataErrorEscaper(
        result,
        "unit_economics",
        "moving_average_total"
      ),
      UnitEconomics_moving_average_objective: dataErrorEscaper(
        result,
        "unit_economics",
        "moving_average_objective"
      ),
      UnitEconomics_rate: dataErrorEscaper(result, "unit_economics", "rate"),
      UnitEconomics_difference: dataErrorEscaper(
        result,
        "unit_economics",
        "difference"
      ),
      UnitEconomics_last_year_comparison_total: dataErrorEscaper(
        result,
        "unit_economics",
        "last_year_comparison_total"
      ),
      dateInGraphToDisplay: dateInGraphToDisplay
    };

    // NRR
    const nrrDailyObject = {
      date: dateInGraph,
      NRR_total: dataErrorEscaper(result, "nrr", "total"),
      NRR_objective: dataErrorEscaper(result, "nrr", "objective"),
      NRR_moving_average: dataErrorEscaper(
        result,
        "nrr",
        "moving_average_total"
      ),
      NRR_moving_average_objective: dataErrorEscaper(
        result,
        "nrr",
        "moving_average_objective"
      ),
      NRR_rate: dataErrorEscaper(result, "nrr", "rate"),
      difference: dataErrorEscaper(result, "nrr", "difference"),
      dateInGraphToDisplay: dateInGraphToDisplay
    };

    // GRR
    const grrDailyObject = {
      date: dateInGraph,
      GRR_total: dataErrorEscaper(result, "grr", "total"),
      GRR_objective: dataErrorEscaper(result, "grr", "objective"),
      GRR_moving_average: dataErrorEscaper(
        result,
        "grr",
        "moving_average_total"
      ),
      GRR_moving_average_objective: dataErrorEscaper(
        result,
        "grr",
        "moving_average_objective"
      ),
      GRR_rate: dataErrorEscaper(result, "grr", "rate"),
      GRR_difference: dataErrorEscaper(result, "grr", "difference"),
      dateInGraphToDisplay: dateInGraphToDisplay
    };

    // ACVCumulative
    const acvCumulativeDailyObject = {
      date: dateInGraph,
      ACVCumulative_total: dataErrorEscaper(result, "acv_cumulative", "total"),
      ACVCumulative_objective: dataErrorEscaper(
        result,
        "acv_cumulative",
        "objective"
      ),
      ACVCumulative_moving_average: dataErrorEscaper(
        result,
        "acv_cumulative",
        "moving_average_total"
      ),
      ACVCumulative_moving_average_objective: dataErrorEscaper(
        result,
        "acv_cumulative",
        "moving_average_objective"
      ),
      ACVCumulative_rate: dataErrorEscaper(result, "acv_cumulative", "rate"),
      ACVCumulative_difference: dataErrorEscaper(
        result,
        "acv_cumulative",
        "difference"
      ),
      dateInGraphToDisplay: dateInGraphToDisplay
    };

    // ACVNew
    const acvNewDailyObject = {
      date: dateInGraph,
      ACVNew_total: dataErrorEscaper(result, "acv_new", "total"),
      ACVNew_objective: dataErrorEscaper(result, "acv_new", "objective"),
      ACVNew_moving_average: dataErrorEscaper(
        result,
        "acv_new",
        "moving_average_total"
      ),
      ACVNew_moving_average_objective: dataErrorEscaper(
        result,
        "acv_new",
        "moving_average_objective"
      ),
      ACVNew_rate: dataErrorEscaper(result, "acv_new", "rate"),
      ACVNew_difference: dataErrorEscaper(result, "acv_new", "difference"),
      dateInGraphToDisplay: dateInGraphToDisplay
    };

    // QuickRatio
    const quickRatioDailyObject = {
      date: dateInGraph,
      QuickRatio_total: dataErrorEscaper(result, "quick_ratio", "total"),
      QuickRatio_objective: dataErrorEscaper(
        result,
        "quick_ratio",
        "objective"
      ),
      QuickRatio_moving_average: dataErrorEscaper(
        result,
        "quick_ratio",
        "moving_average_total"
      ),
      QuickRatio_moving_average_objective: dataErrorEscaper(
        result,
        "quick_ratio",
        "moving_average_objective"
      ),
      QuickRatio_rate: dataErrorEscaper(result, "quick_ratio", "rate"),
      QuickRatio_difference: dataErrorEscaper(
        result,
        "quick_ratio",
        "difference"
      ),
      QuickRatio_last_year_comparison_total: dataErrorEscaper(
        result,
        "quick_ratio",
        "last_year_comparison_total"
      ),
      dateInGraphToDisplay: dateInGraphToDisplay
    };

    // リード獲得
    // const funnelLeadDailyObject = {
    //   date: dateInGraph,
    //   funnelLead_total: dataForFunnelErrorEscaper({
    //     raw_data_result: result,
    //     funnel_name: "リード獲得",
    //     target_key: "lead_new_count_total"
    //   }),
    //   funnelLead_objective: dataForFunnelErrorEscaper({
    //     raw_data_result: result,
    //     funnel_name: "リード獲得",
    //     target_key: "lead_new_count_objective"
    //   }),
    //   funnelLead_moving_average: dataForFunnelErrorEscaper({
    //     raw_data_result: result,
    //     funnel_name: "リード獲得",
    //     target_key: "lead_new_count_moving_average_total"
    //   }),
    //   funnelLead_moving_average_objective: dataForFunnelErrorEscaper({
    //     raw_data_result: result,
    //     funnel_name: "リード獲得",
    //     target_key: "lead_new_count_moving_average_objective"
    //   }),
    //   funnelLead_rate: dataForFunnelErrorEscaper({
    //     raw_data_result: result,
    //     funnel_name: "リード獲得",
    //     target_key: "lead_new_count_rate"
    //   }),
    //   funnelLead_difference: dataForFunnelErrorEscaper({
    //     raw_data_result: result,
    //     funnel_name: "リード獲得",
    //     target_key: "lead_new_count_difference"
    //   }),
    //   funnelLead_last_year_comparison_total: dataForFunnelErrorEscaper({
    //     raw_data_result: result,
    //     funnel_name: "リード獲得",
    //     target_key: "lead_new_count_last_year_comparison_total"
    //   }),
    //   dateInGraphToDisplay: dateInGraphToDisplay
    // };

    // アポ獲得
    // const funnelAppointmentDailyObject = {
    //   date: dateInGraph,
    //   funnelAppointment_total: dataForFunnelErrorEscaper({
    //     raw_data_result: result,
    //     funnel_name: "アポ獲得",
    //     target_key: "lead_new_count_total"
    //   }),
    //   funnelAppointment_objective: dataForFunnelErrorEscaper({
    //     raw_data_result: result,
    //     funnel_name: "アポ獲得",
    //     target_key: "lead_new_count_objective"
    //   }),
    //   funnelAppointment_moving_average: dataForFunnelErrorEscaper({
    //     raw_data_result: result,
    //     funnel_name: "アポ獲得",
    //     target_key: "lead_new_count_moving_average_total"
    //   }),
    //   funnelAppointment_moving_average_objective: dataForFunnelErrorEscaper({
    //     raw_data_result: result,
    //     funnel_name: "アポ獲得",
    //     target_key: "lead_new_count_moving_average_objective"
    //   }),
    //   funnelAppointment_rate: dataForFunnelErrorEscaper({
    //     raw_data_result: result,
    //     funnel_name: "アポ獲得",
    //     target_key: "lead_new_count_rate"
    //   }),
    //   funnelAppointment_difference: dataForFunnelErrorEscaper({
    //     raw_data_result: result,
    //     funnel_name: "アポ獲得",
    //     target_key: "lead_new_count_difference"
    //   }),
    //   funnelAppointment_last_year_comparison_total: dataForFunnelErrorEscaper({
    //     raw_data_result: result,
    //     funnel_name: "アポ獲得",
    //     target_key: "lead_new_count_last_year_comparison_total"
    //   }),
    //   dateInGraphToDisplay: dateInGraphToDisplay
    // };

    // 商談化
    // const funnelOpportunityDailyObject = {
    //   date: dateInGraph,
    //   funnelOpportunity_total: dataForFunnelErrorEscaper({
    //     raw_data_result: result,
    //     funnel_name: "商談化",
    //     target_key: "lead_new_count_total"
    //   }),
    //   funnelOpportunity_objective: dataForFunnelErrorEscaper({
    //     raw_data_result: result,
    //     funnel_name: "商談化",
    //     target_key: "lead_new_count_objective"
    //   }),
    //   funnelOpportunity_moving_average: dataForFunnelErrorEscaper({
    //     raw_data_result: result,
    //     funnel_name: "商談化",
    //     target_key: "lead_new_count_moving_average_total"
    //   }),
    //   funnelOpportunity_moving_average_objective: dataForFunnelErrorEscaper({
    //     raw_data_result: result,
    //     funnel_name: "商談化",
    //     target_key: "lead_new_count_moving_average_objective"
    //   }),
    //   funnelOpportunity_rate: dataForFunnelErrorEscaper({
    //     raw_data_result: result,
    //     funnel_name: "商談化",
    //     target_key: "lead_new_count_rate"
    //   }),
    //   funnelOpportunity_difference: dataForFunnelErrorEscaper({
    //     raw_data_result: result,
    //     funnel_name: "商談化",
    //     target_key: "lead_new_count_difference"
    //   }),
    //   funnelOpportunity_last_year_comparison_total: dataForFunnelErrorEscaper({
    //     raw_data_result: result,
    //     funnel_name: "商談化",
    //     target_key: "lead_new_count_last_year_comparison_total"
    //   }),
    //   dateInGraphToDisplay: dateInGraphToDisplay
    // };

    // 受注
    // const funnelClosedDealDailyObject = {
    //   date: dateInGraph,
    //   funnelClosedDeal_total: dataForFunnelErrorEscaper({
    //     raw_data_result: result,
    //     funnel_name: "受注",
    //     target_key: "lead_new_count_total"
    //   }),
    //   funnelClosedDeal_objective: dataForFunnelErrorEscaper({
    //     raw_data_result: result,
    //     funnel_name: "受注",
    //     target_key: "lead_new_count_objective"
    //   }),
    //   funnelClosedDeal_moving_average: dataForFunnelErrorEscaper({
    //     raw_data_result: result,
    //     funnel_name: "受注",
    //     target_key: "lead_new_count_moving_average_total"
    //   }),
    //   funnelClosedDeal_moving_average_objective: dataForFunnelErrorEscaper({
    //     raw_data_result: result,
    //     funnel_name: "受注",
    //     target_key: "lead_new_count_moving_average_objective"
    //   }),
    //   funnelClosedDeal_rate: dataForFunnelErrorEscaper({
    //     raw_data_result: result,
    //     funnel_name: "受注",
    //     target_key: "lead_new_count_rate"
    //   }),
    //   funnelClosedDeal_difference: dataForFunnelErrorEscaper({
    //     raw_data_result: result,
    //     funnel_name: "受注",
    //     target_key: "lead_new_count_difference"
    //   }),
    //   funnelClosedDeal_last_year_comparison_total: dataForFunnelErrorEscaper({
    //     raw_data_result: result,
    //     funnel_name: "受注",
    //     target_key: "lead_new_count_last_year_comparison_total"
    //   }),
    //   dateInGraphToDisplay: dateInGraphToDisplay
    // };

    return {
      Revenue: revenueDailyObject,
      MRR: mrrDailyObject,
      UnitEconomics: unitEconomicsDailyObject,
      cac: cacDailyObject,
      PaybackPeriod: PaybackPeriodDailyObject,
      LTV: LTVDailyObject,
      arpu: arpuDailyObject,
      churnRate: churnRateDailyObject,
      NRR: nrrDailyObject,
      GRR: grrDailyObject,
      QuickRatio: quickRatioDailyObject,
      ACVCumulative: acvCumulativeDailyObject,
      ACVNew: acvNewDailyObject,
      ConversionRate: conversionRateDailyObject
      // funnelLead: funnelLeadDailyObject,
      // funnelAppointment: funnelAppointmentDailyObject,
      // funnelOpportunity: funnelOpportunityDailyObject,
      // funnelClosedDeal: funnelClosedDealDailyObject
    };
  });
  console.log("resultDailyFormedForGraph", resultDailyFormedForGraph);

  const graphData = gridContents.map((content: any, index: number) => {
    return {
      graphName: content.name,
      data: dataConverter(content.name, resultDailyFormedForGraph)
    };
  });
  console.log("graphData", graphData);

  //並べ替えたあとに動く関数。
  const onSortEnd = ({
    oldIndex,
    newIndex
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    // ここの関数でgridContentsの順番が書き換わる。
    const reorderedGridContents = arrayMove(gridContents, oldIndex, newIndex);
    const reorderedGridContentsWithIndex = reorderedGridContents.map(
      (content: any, index: number) => {
        return {
          ...content,
          index
        };
      }
    );
    setGridContents(reorderedGridContentsWithIndex);
  };

  //並べ替え用の矢印ハンドル
  const Handle = SortableHandle(({ tabIndex }: any) => (
    <HandlerSC tabIndex={tabIndex}>
      <img src={draggable} alt="handle" />
    </HandlerSC>
  ));

  //============================
  //Topコンテンツのリスト
  //============================
  const SortableList = SortableContainer((props: any) => {
    const { ...restProps } = props;
    return (
      <TopContent>
        <GraphWrapper ref={graphAreaRef}>
          {gridContents.map((item: any, index: number) => {
            if (item.display) {
              return (
                <SortableItem
                  key={`item-${item.name}`}
                  index={index}
                  indexNum={index}
                  contentsName={item.name}
                  value={item}
                  {...restProps}
                />
              );
            } else {
              return <></>;
            }
          })}
        </GraphWrapper>
        <>
          <AddGridItemButton
            onClick={() => setSelectBoxIsShown(true)}
            className={selectBoxIsShown ? "close" : "open"}
          />

          {selectBoxIsShown && (
            <>
              <ModalBackGround
                onClick={() => setSelectBoxIsShown(false)}
              ></ModalBackGround>
              <ModalContainer>
                <div style={{ padding: "0 16px" }}>
                  <p
                    style={{
                      fontSize: "10px",
                      fontWeight: "bold",
                      textAlign: "left",
                      color: "#435878",
                      marginBottom: "16px"
                    }}
                  >
                    追加する指標を選択してください
                  </p>
                  <ModalClose onClick={() => setSelectBoxIsShown(false)} />
                </div>
                <ModalSelect>
                  {gridContents.map((gridContent: any, index: number) => (
                    <li key={index}>
                      <label htmlFor={gridContent.name}>
                        {gridContent.name}
                      </label>
                      <Input
                        name={gridContent.name}
                        id={gridContent.name}
                        type="checkbox"
                        size={8}
                        onChange={() => toggleGraph(gridContent.name)}
                        // onClick={graphSwitcher}
                        value={gridContent.name}
                      />
                      <SwitchLabel
                        htmlFor={gridContent.name}
                        className={gridContent.display ? "on" : "off"}
                      ></SwitchLabel>
                    </li>
                  ))}
                </ModalSelect>
              </ModalContainer>
            </>
          )}
        </>
      </TopContent>
    );
  });

  //============================
  //Topコンテンツ
  //============================
  const SortableItem = SortableElement((props: any) => {
    const indexNum = props.indexNum;
    return (
      <GridItem>
        {props.shouldUseDragHandle && <Handle />}
        {/* <CardClose
          className="remove gridRemoveButtonStyle"
          onClick={
            () => toggleGraph(gridContents[indexNum].name)
            // event => removeItem(event, gridContents[indexNum].name)
          }
        /> */}
        <TopGraphLabel contentName={gridContents[indexNum].name} />
        <Sum>
          {
            <ThisMonthSummary
              result_at_seconds={resulAtConverter(
                graphData,
                gridContents[indexNum].name
              )}
              this_month_total={totalConverter(
                graphData,
                gridContents[indexNum].name
              )}
              this_month_rate={rateConverter(
                graphData,
                gridContents[indexNum].name
              )}
              target_data={
                graphData.find(
                  (data: any) => data.graphName === gridContents[indexNum].name
                ).data
              }
              target_data_name={gridContents[indexNum].name}
            />
          }
        </Sum>
        <TopGraph
          graphObj={graphData}
          index={indexNum}
          contents={gridContents}
          resultSpan={resultSpan}
        />
      </GridItem>
    );
  });

  return (
    <>
      <Container>
        <GlobalNav />
        <TopMain>
          <PageHeader page_title="Dashboard" />
          <GraphDownloadLayoutButton onClick={handleExport}>エクスポート</GraphDownloadLayoutButton>
          <SaveLayoutButton onClick={handleSave}>保存</SaveLayoutButton>

          <TopContainer>
            <SortableList
              shouldUseDragHandle={true}
              useDragHandle
              axis="xy"
              contents={gridContents}
              onSortEnd={onSortEnd}
            />
          </TopContainer>
        </TopMain>
      </Container>
    </>
  );
};

export default Top;

// Container
const Container = styled.div`
  display: flex;
`;
const TopContent = styled.div`
  height: 100%;
  padding: 0px 17px 15px 0;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-wrap: wrap;
`;

const GraphWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const TopMain = styled.div`
  width: 100%;
  background-color: #f4f4f4;
  height: 100vh;
  overflow: scroll;
`;

const SaveLayoutButton = styled.button`
  border-radius: 4px;
  background: #0066ff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  color: #ffffff;
  width: 100px;
  padding: 6px;
  text-align: center;
  margin-top: 10px;
  margin-left: 10px;
  font-weight: bold;
  position: absolute;
  right: 41px;
  z-index: 9999;
  &:hover {
    opacity: 0.8;
  }
`;

const GraphDownloadLayoutButton = styled(SaveLayoutButton)`
  width: 108px;
  right: 159px;
`;
//　ボタン、セレクトボックスのスタイル
// ====================================

const CardClose = styled.div`
  width: 24px;
  height: 24px;
  font-size: 24px;
  position: absolute;
  right: 9.5px;
  top: 10px;
  cursor: pointer;
  background: url(${close});
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 99;
  &:hover {
    opacity: 0.6;
  }
`;

const GridItem = styled.div`
  position: relative;
  width: calc(100% / 3 - 10px);
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 0 4 0 rgba(0, 0, 0, 0.1);
  padding: 0 15px;
  box-sizing: border-box;
`;

//　グリッドアイテムのスタイル
// ====================================
const HandlerSC = styled.div`
  width: 20px;
  height: 20px;
  cursor: grab;
  position: absolute;
  top: 12px;
  right: 9.5px;
  z-index: 3;
  & img {
    width: 20px;
    height: 20px;
  }
`;
// ====================================

// GraphLabel
const GraphLabel = styled.p`
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  color: #0066ff;
  padding: 6px 18px 2px 0;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 30px;
  a {
    display: inline-block;
    width: 100%;
    height: 85px;
    position: absolute;
    top: 10px;
    left: 0;
  }
  &:hover {
    opacity: 0.7;
  }
`;

// Sum
const Sum = styled.p`
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  color: #35455d;
  padding: 0 18px 7px 0;
  box-sizing: border-box;
`;

const TopContainer = styled.div`
  padding-top: 50px;
  margin-left: 25px;
  margin-right: 14px;
  background: #f4f4f4;
`;

//　グラフ関連のスタイル
// ====================================

const AddGridItemButton = styled.button`
  display: block;
  background-image: url(${addCommentIconOpen});
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 100px;
  height: 100px;
  margin-left: auto;
  background-size: 78px 78px;
  background-repeat: no-repeat;
  background-position: center;
  &:hover {
    opacity: 0.6;
  }
  &.close {
    background-image: url(${addCommentIconClose});
  }
`;

const ModalBackGround = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(67, 88, 120, 0.3);
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background: #fff;
  height: 600px;
  width: 352px;
  border-radius: 2px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 16px 0;
  box-sizing: border-box;
  text-align: center;
  z-index: 2001;
`;

const ModalClose = styled.button`
  width: 24px;
  height: 24px;
  background: url(${close});
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  right: 8px;
  top: 6px;
`;
const ModalSelect = styled.ul`
  border-top: 1px solid #e2e9f3;
  padding: 10px 0 46px;
  li {
    margin-bottom: 9px;
    padding: 0 17px 0 14px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    &:last-child {
      margin-bottom: 0;
    }
    p {
      font-size: 16px;
    }
  }
`;
const Input = styled.input`
  display: none;
`;
const SwitchLabel = styled.label`
  border: 1px solid #e2e9f3;
  color: #435878;
  background: #ffffff;
  width: 42px;
  height: 21px;
  cursor: pointer;
  border-radius: 40px;
  max-width: 42px;
  width: 100%;
  text-align: center;
  position: relative;

  &::after {
    content: "";
    width: 19px;
    height: 19px;
    border: 1px solid #e2e9f3;
    border-radius: 50px;
    box-sizing: border-box;
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    transition: all 0.25s;
  }
  &.on {
    background: #62e067;
    border: 1px solid #62e067;
    &::after {
      border: 1px solid #fff;
      background-color: #fff;
      transform: translateX(calc(100% + 1.5px));
    }
  }
  &.off {
    color: #ccc;
  }
  &:hover {
    background: rgba(45, 61, 204, 0.08);
  }
`;
