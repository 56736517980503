import React from "react";

//  lib components
import {
  Collapse,
  IconButton,
  TableBody,
  TableHead,
  TableRow
} from "@material-ui/core";

//  styled components
import {
  StyledTable,
  StyledCell,
  StickyCell,
  StickerHeadCell,
  StickyRow,
  StyledTableRow,
  Wrapper,
  SvgImage,
  CaretSvgImage,
  StyledButton,
  CellText,
  CellContainer
} from "./PlanComparisonStyles";

//  assest
import UpArrow from "../../../images/icons/reached_arrow.svg";
import DownArrow from "../../../images/icons/unreached_icon.svg";
import styled from "styled-components";
import { dateFormatter } from "../../Util/DateFormatter";
import { unitSwitcher } from "../../Util/unitSwitcher";

interface DraftTableProps {
  data: any;
  planNameArray: any;
  target_data_name: string;
  result_span: string;
  targetDataType: string;
}
export const DraftTable: React.FC<DraftTableProps> = props => {
  const { data, planNameArray, target_data_name, result_span, targetDataType } = props;

  console.log("data rendered", data);

  //  states
  const [open, setOpen] = React.useState(false);
  const [breakdownOpen, setBreakdownOpen] = React.useState(false);

  //  modules
  // const data = generatedata({
  //   data
  // });
  return (
    <React.Fragment>
      {planNameArray.map(
        (pl: any) =>
          !pl.deactivated && (
            <Wrapper>
              {/* 【 実績 】 目標・実績・達成率 */}
              <StyledTable>
                {/* Head */}
                <TableHead>
                  <TableRow>
                    <StickerHeadCell style={{ zIndex: 1 }} />
                    {data.map((val: any, i: number) => (
                      <StickerHeadCell key={i}>
                        {dateFormatter(
                          result_span,
                          val.result_at.seconds * 1000
                        )}
                      </StickerHeadCell>
                    ))}
                  </TableRow>
                </TableHead>

                {/* Body */}
                <TableBody>
                  {/* label */}
                  <StickyRow>
                    <PlanName color={pl.color}>{pl.nameList}</PlanName>
                  </StickyRow>

                  {/* 実績 */}
                  <StyledTableRow>
                    <StickyCell style={{ fontWeight: "bolder" }}>
                      <CellContainer>
                        <CellText style={{ fontWeight: "bold" }}>実績</CellText>
                        {data.breakdown && (
                          <IconButton
                            size="small"
                            onClick={() => setBreakdownOpen(!breakdownOpen)}
                          >
                            <CaretSvgImage open={breakdownOpen} />
                          </IconButton>
                        )}
                      </CellContainer>
                    </StickyCell>
                    {data.map((val: any, i: any) => {
                      return (
                        <StyledCell key={i} style={{ fontWeight: "bolder" }}>
                          {
                            targetDataType === "sales"
                              ?
                              unitSwitcher(
                                val[`${pl.nameList}_${target_data_name}_total`],
                                target_data_name
                              )
                              :
                              unitSwitcher(
                                val[`${pl.nameList}_${target_data_name}_account_total`],
                                "active_customer"
                              )
                          }
                        </StyledCell>
                      );
                    })}
                  </StyledTableRow>
                </TableBody>
              </StyledTable>

              {/* 【 実績 】　目標・達成率 */}
              <StyledTable>
                <TableBody>
                  {/* 目標 */}
                  <StyledTableRow>
                    <StickyCell style={{ borderTop: "none" }}>
                      <CellText>目標</CellText>
                    </StickyCell>
                    {data.map((val: any, i: number) => (
                      <StyledCell key={i} style={{ borderTop: "none" }}>
                        {
                          targetDataType === "sales"
                            ?
                            unitSwitcher(
                              val[`${pl.nameList}_${target_data_name}_objective`],
                              target_data_name
                            )
                            :
                            unitSwitcher(
                              val[`${pl.nameList}_${target_data_name}_account_objective`],
                              "active_customer"
                            )
                        }
                      </StyledCell>
                    ))}
                  </StyledTableRow>

                  {/* 達成率 */}
                  <StyledTableRow>
                    <StickyCell>
                      <CellText>達成率</CellText>
                    </StickyCell>
                    {
                      targetDataType === "sales"
                        ?
                        data.map((val: any, i: number) => {
                          const up =
                            val[`${pl.nameList}_${target_data_name}_rate`] * 100 >=
                              100
                              ? true
                              : false;
                          return (
                            <StyledCell key={i} up={up}>
                              <SvgImage src={up ? UpArrow : DownArrow} />
                              {unitSwitcher(
                                val[`${pl.nameList}_${target_data_name}_rate`],
                                "rate"
                              )}
                            </StyledCell>
                          );
                        })
                        :
                        data.map((val: any, i: number) => {
                          const up =
                            val[`${pl.nameList}_${target_data_name}_account_rate`] * 100 >=
                              100
                              ? true
                              : false;
                          return (
                            <StyledCell key={i} up={up}>
                              <SvgImage src={up ? UpArrow : DownArrow} />
                              {unitSwitcher(
                                val[`${pl.nameList}_${target_data_name}_account_rate`],
                                "rate"
                              )}
                            </StyledCell>
                          );
                        })
                    }
                  </StyledTableRow>
                </TableBody>
              </StyledTable>

              {/* 【 実績 】 差異・昨対比 */}
              <Collapse in={open}>
                <StyledTable>
                  <TableBody>
                    {/* 差異 */}
                    <StyledTableRow>
                      <StickyCell style={{ borderTop: "none" }}>
                        <CellText>差異</CellText>
                      </StickyCell>

                      {
                        targetDataType === "sales"
                          ?

                          data.map((val: any, i: number) => {
                            const up =
                              val[
                                `${pl.nameList}_${target_data_name}_difference`
                              ] >= 0
                                ? true
                                : false;
                            return (
                              <StyledCell
                                key={i}
                                style={{ borderTop: "none" }}
                                up={up}
                              >
                                {
                                  val[
                                  `${pl.nameList}_${target_data_name}_difference`
                                  ]
                                }
                              </StyledCell>
                            );
                          })
                          :
                          data.map((val: any, i: number) => {
                            const up =
                              val[
                                `${pl.nameList}_${target_data_name}_account_difference`
                              ] >= 0
                                ? true
                                : false;
                            return (
                              <StyledCell
                                key={i}
                                style={{ borderTop: "none" }}
                                up={up}
                              >
                                {
                                  val[
                                  `${pl.nameList}_${target_data_name}_account_difference`
                                  ]
                                }
                              </StyledCell>
                            );
                          })
                      }
                    </StyledTableRow>

                    {/* 昨対比 */}
                    <StyledTableRow>
                      <StickyCell>
                        <CellText>昨対比</CellText>
                      </StickyCell>
                      {
                        targetDataType === "sales"
                          ?

                          data.map((val: any, i: number) => {
                            const up =
                              val[
                                `${pl.nameList}_${target_data_name}_last_year_rate`
                              ] >= 0
                                ? true
                                : false;
                            return (
                              <StyledCell key={i} up={up}>
                                {
                                  val[
                                  `${pl.nameList}_${target_data_name}_last_year_rate`
                                  ]
                                }
                              </StyledCell>
                            );
                          })
                          :
                          data.map((val: any, i: number) => {
                            const up =
                              val[
                                `${pl.nameList}_${target_data_name}_account_last_year_rate`
                              ] >= 0
                                ? true
                                : false;
                            return (
                              <StyledCell key={i} up={up}>
                                {
                                  val[
                                  `${pl.nameList}_${target_data_name}_account_last_year_rate`
                                  ]
                                }
                              </StyledCell>
                            );
                          })
                      }
                    </StyledTableRow>
                  </TableBody>
                </StyledTable>
              </Collapse>

              {/* 【 実績 】 詳細の開閉ボタン */}
              <StyledTable>
                {/* Head */}
                <TableHead>
                  <TableRow>
                    <StickerHeadCell style={{ zIndex: 1 }} />
                    {data.map((val: any, i: number) => (
                      <StickerHeadCell key={i}></StickerHeadCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {/* label */}
                  <StickyRow>
                    <StyledButton
                      onClick={() => setOpen(!open)}
                      style={{
                        justifyContent: "start",
                        minWidth: "unset"
                      }}
                    >
                      詳細
                      <CaretSvgImage open={open} />
                    </StyledButton>
                  </StickyRow>
                </TableBody>
              </StyledTable>
            </Wrapper>
          )
      )}
    </React.Fragment>
  );
};

const PlanName = styled.h4`
  position: relative;
  margin-top: 10px;
  margin-left: 10px;
  &::before {
    content: "";
    position: absolute;
    top: 5px;
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${(props: any) => props.color};
  }
`;
