import { UPDATE_BILLING_ACCOUNT_REQUESTED, GET_BILLING_ACCOUNT_REQUESTED } from "./types";


export const getBillingAccountRequest = (company_id: string, billingAccountId: string) => ({
  type: GET_BILLING_ACCOUNT_REQUESTED,
  company_id: company_id,
  billingAccountId: billingAccountId
})

export const updateBillingAccountRequest = (company_id: string, billingAccountId: string, firstName: string, firstNameJp: string, lastName: string, lastNameJp: string, position: string, postalCode: string, tel: string, email: string, address1: string, address2: string, companyName: string) => ({
  type: UPDATE_BILLING_ACCOUNT_REQUESTED,
  company_id,
  billingAccountId,
  firstName,
  firstNameJp,
  lastName,
  lastNameJp,
  position,
  postalCode,
  tel,
  email,
  address1,
  address2,
  companyName,
})
