import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { getMembersRequest, toggleMemberToEditPostStatus, toggleMemberToAddPostStatus, toggleMemberToDeletePostStatus } from "../../../reducks/members/actions";

const MemberList: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const members = useSelector((state: any) => state.members.members);

  const company_id = useSelector((state: any) => state.user.company_id);
  const post_status_for_member_to_add = useSelector((state: any) => state.members.member_to_add.post_status);
  const post_status_for_member_to_delete = useSelector((state: any) => state.members.member_to_delete.post_status);

  useEffect(() => {
    dispatch(getMembersRequest(company_id));
  }, [company_id, dispatch]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  interface MenuMemberObject {
    docId: string;
    firstName: string;
    lastName: string;
    email: string;
    owner: boolean;
  }
  const [menuMember, setMenuMember] = React.useState<null | MenuMemberObject>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    console.log('event.currentTarget', event.currentTarget);
    setAnchorEl(event.currentTarget);
    setMenuMember(members[index]);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  useEffect(() => {
    dispatch(toggleMemberToEditPostStatus(false));
    dispatch(toggleMemberToAddPostStatus(false));
    dispatch(toggleMemberToDeletePostStatus(false));
  }, [dispatch]);

  return (

    <MemberContent>
      <AddButton>
        <Link to="/config/member-setting/add-member">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clipRule="evenodd" d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM6.66667 2.33333C6.66667 1.78105 7.11438 1.33333 7.66667 1.33333H8.33333C8.88562 1.33333 9.33333 1.78105 9.33333 2.33333V6.66667H13.6667C14.219 6.66667 14.6667 7.11438 14.6667 7.66667V8.33333C14.6667 8.88562 14.219 9.33333 13.6667 9.33333H9.33333V13.6667C9.33333 14.219 8.88562 14.6667 8.33333 14.6667H7.66667C7.11438 14.6667 6.66667 14.219 6.66667 13.6667V9.33333H2.33333C1.78105 9.33333 1.33333 8.88562 1.33333 8.33333V7.66667C1.33333 7.11438 1.78105 6.66667 2.33333 6.66667H6.66667V2.33333Z" fill="#0066FF" />
          </svg>
          メンバー追加
        </Link>
      </AddButton>

      <TableContainer className={classes.containerRoot} component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          {/* テーブルヘッダー */}
          <TableHead className={classes.thead}>
            <TableRow>
              <TableCell className={classes.cell + " " + classes.theadCell + " " + classes.ownerCell}>オーナー</TableCell>
              <TableCell className={classes.cell + " " + classes.theadCell + " " + classes.nameCell} align="left">
                名前
              </TableCell>
              <TableCell className={classes.cell + " " + classes.theadCell} align="left">
                メールアドレス
              </TableCell>
            </TableRow>
          </TableHead>

          {/* テーブルボディ */}
          <TableBody>
            {members && members.length > 0 && members.map((member: any, index: number) => (
              <TableRow key={"member_" + index.toString()}>
                <TableCell className={classes.cell + " " + classes.bodyCell + " " + classes.ownerCell} component="th" scope="member">
                  {member.owner === true ? (
                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.93241 11.2001L1.76399 7.0001L0.374512 8.4001L5.93241 14.0001L17.8422 2.0001L16.4527 0.600098L5.93241 11.2001Z" fill="#0066FF" />
                    </svg>
                  ) : (
                    ""
                  )}
                </TableCell>

                <TableCell className={classes.cell + " " + classes.bodyCell + " " + classes.nameCell} align="left">
                  {member.lastName} {member.firstName}
                </TableCell>

                <TableCell className={classes.cell + " " + classes.bodyCell + " " + classes.emailCell} align="left">
                  {member.email}

                  <MemberMenu>
                    <IconButton className={classes.button} aria-label="more" aria-controls={`long-menu-${index}`} aria-haspopup="true" onClick={(e: any) => handleClick(e, index)}>
                      <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="2" cy="2" r="2" fill="#789097" />
                        <circle cx="8.6665" cy="2" r="2" fill="#789097" />
                        <circle cx="15.3335" cy="2" r="2" fill="#789097" />
                      </svg>
                    </IconButton>
                  </MemberMenu>
                </TableCell>

                <Menu
                  className={classes.menu}
                  classes={{ paper: classes.paper }}
                  id={`long-menu-${index}`}
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: "224px"
                    }
                  }}
                >
                  <MenuItem className={classes.menuItem} key={"menuItem_delete_" + index.toString()}>
                    {
                      menuMember
                      ?
                      <Link className={classes.MenuLink} to={`/config/member-setting/delete-member/${menuMember.docId}`}>
                        削除
                      </Link>
                      :
                      <p>削除</p>
                    }
                  </MenuItem>

                  <MenuItem className={classes.menuItem} key={"menuItem_edit_" + index.toString()}>
                    {
                      menuMember
                      ?
                      <Link className={classes.MenuLink} to={`/config/member-setting/edit-member/${menuMember.docId}`}>
                        名前・メールアドレス編集
                      </Link>
                      :
                      <p>名前・メールアドレス編集</p>
                    }
                  </MenuItem>

                  <MenuItem className={classes.menuItem} key={"menuItem_resetPasswordEmail_" + index.toString()}>
                    <Link className={classes.MenuLink} to="/config/member-setting/reset-password-email">
                      パスワードリセットメール送信
                    </Link>
                  </MenuItem>

                  {/* <MenuItem className={classes.menuItem} key={"menuItem_transferOwner_" + index.toString()}>
                    {
                      menuMember && !menuMember.owner &&
                      <Link className={classes.MenuLink} to={`/config/member-setting/transfer-ownership/${menuMember.docId}`}>
                        オーナーを移管
                      </Link>
                    }
                  </MenuItem> */}
                </Menu>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </MemberContent>
  );
};

export default MemberList;


const useStyles = makeStyles({
  containerRoot: {
    boxShadow: "none"
  },
  table: {
    minWidth: 650
  },
  thead: {
    fontSize: "12px",
    fontWeight: "bold",
    lineHeight: "14px"
  },
  cell: {
    fontSize: "12px",
    lineHeight: "14px",
    borderBottom: "1px solid #EDF0F1",
    "&:nth-child(1)": {
      width: 142
    },
    "&:nth-child(2)": {
      width: 154
    }
  },
  theadCell: {
    fontWeight: "bold",
    color: "#6B6D82"
  },
  bodyCell: {
    color: "#283F46"
  },
  ownerCell: {
    textAlign: "center"
  },
  nameCell: {
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "19px"
  },
  emailCell: {
    position: "relative"
  },
  button: {
    padding: "12px 5px"
  },
  menu: {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
  },
  paper: {
    boxShadow: "0px 3px 6px 1px rgba(0, 0, 0, 0.08)"
  },
  menuItem: {
    fontSize: "12px",
    lineHeight: "18px",
    color: "#435878",
    padding: "10px 16px",
    display: "block",
    "&:hover": {
      backgroundColor: "#F3F6FA"
    }
  },
  MenuLink: {
    fontSize: "12px",
    lineHeight: "18px",
    color: "#435878",
    textDecoration: "none",
    // padding: '0',
    display: "block"
  }
});

const ITEM_HEIGHT = 48;

// MemberContent
const MemberContent = styled.div`
  width: 100%;
  height: 100vh;
  padding: 33px 0;
  box-sizing: border-box;
  background-color: #fff;
`;

// AddButton
const AddButton = styled.p`
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
  color: #0087ae;
  text-align: right;
  width: 100%;
  margin: 0 0 36px 0;
  padding: 0 32px;
  box-sizing: border-box;
  &:hover {
    opacity: 0.6;
  }
  a {
    color: #0066ff;
    text-decoration: none;
    position: relative;
  }
  svg {
    width: 16px;
    height: 23px;
    margin-right: 13px;
    position: absolute;
    top: -3px;
    left: -24px;
  }
`;
// MemberMenu
const MemberMenu = styled.div`
  position: absolute;
  top: 50%;
  right: 32px;
  transform: translateY(-50%);
`;
