import { GET_RAW_DATA_SUCCEEDED, GET_RAW_DATA_FAILED, GET_RAW_DATA_THIS_MONTH_SUCCEEDED, GET_RAW_DATA_THIS_MONTH_FAILED, GET_RAW_DATA_LAST_MONTH_SUCCEEDED, GET_RAW_DATA_LAST_MONTH_FAILED, GET_RAW_DATA_LAST_6_MONTH_SUCCEEDED, GET_RAW_DATA_LAST_6_MONTH_FAILED, GET_RAW_DATA_LAST_12_MONTH_SUCCEEDED, GET_RAW_DATA_LAST_12_MONTH_FAILED, GET_RAW_DATA_CUSTOM_SPAN_SUCCEEDED, GET_RAW_DATA_CUSTOM_SPAN_FAILED, GET_RAW_DATA_WITH_SPAN_AND_TYPE_AND_TIME_SUCCEEDED, GET_RAW_DATA_WITH_SPAN_AND_TYPE_AND_TIME_FAILED, GET_RAW_DATA_FOR_FILTER_SUCCEEDED, GET_RAW_DATA_FOR_FILTER_FAILED, SET_TARGET_LINE_OPACITY, LOGOUT_REQUESTED } from "./types";
import { RESET_ALL } from "../common/types";

interface RawData {
  raw_data_array: any[];
  selected_data_config: SelectedDataConfig;
  filtered_data: any[];
  labels_name_array: any[];
  opacity_object: any;
}
interface SelectedDataConfig {
  data_type: "monthly" | "weekly" | "daily";
  data_span: "this_month" | "last_month" | "last_6_months" | "last_12_months" | "custom";
  custom_span_start_time: any;
  custom_span_end_time: any;
}

const initialState: RawData = {
  raw_data_array: [],
  selected_data_config: {
    data_type: "monthly",
    data_span: "this_month",
    custom_span_start_time: "",
    custom_span_end_time: ""
  },
  filtered_data: [],
  labels_name_array: [],
  opacity_object: {
    ライトプラン_revenue_total: 1,
    スタンダードプラン_revenue_total: 1,
    プロフェッショナルプラン_revenue_total: 1,
    ライトプラン_revenue_objective: 1,
    スタンダードプラン_revenue_objective: 1,
    プロフェッショナルプラン_revenue_objective: 1,
  }
};

const targetDataReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_ALL:
      return initialState;

    case LOGOUT_REQUESTED:
      return {
        ...state,
        selected_data_config: {
          data_type: "monthly",
          data_span: "this_month",
          custom_span_start_time: "",
          custom_span_end_time: ""
        },
      }
    case GET_RAW_DATA_SUCCEEDED:
      return {
        ...state,
        raw_data_array: action.raw_data_array,
        selected_data_config: {
          ...state.selected_data_config,
          custom_span_start_time: action.custom_span_start_time,
          custom_span_end_time: action.custom_span_end_time
        },
      };

    case GET_RAW_DATA_FAILED:
      console.log("error", action.message);
      return state;

    case GET_RAW_DATA_THIS_MONTH_SUCCEEDED:
      return {
        ...state,
        selected_data_config: {
          ...state.selected_data_config,
          data_span: "this_month",
          data_type: action.selected_data_config_data_type
        },
        raw_data_array: action.raw_data_array
      };

    case GET_RAW_DATA_THIS_MONTH_FAILED:
      console.log("error", action.message);
      return state;

    case GET_RAW_DATA_LAST_MONTH_SUCCEEDED:
      return {
        ...state,
        selected_data_config: {
          ...state.selected_data_config,
          data_span: "last_month",
          data_type: action.selected_data_config_data_type
        },
        raw_data_array: action.raw_data_array
      };

    case GET_RAW_DATA_LAST_MONTH_FAILED:
      console.log("error", action.message);
      return state;

    case GET_RAW_DATA_LAST_6_MONTH_SUCCEEDED:
      return {
        ...state,
        selected_data_config: {
          ...state.selected_data_config,
          data_span: "last_6_months",
          data_type: action.selected_data_config_data_type
        },
        raw_data_array: action.raw_data_array
      };

    case GET_RAW_DATA_LAST_6_MONTH_FAILED:
      console.log("error", action.message);
      return state;

    case GET_RAW_DATA_LAST_12_MONTH_SUCCEEDED:
      return {
        ...state,
        selected_data_config: {
          ...state.selected_data_config,
          data_span: "last_12_months",
          data_type: action.selected_data_config_data_type
        },
        raw_data_array: action.raw_data_array
      };

    case GET_RAW_DATA_LAST_12_MONTH_FAILED:
      console.log("error", action.message);
      return state;

    case GET_RAW_DATA_CUSTOM_SPAN_SUCCEEDED:
      return {
        ...state,
        selected_data_config: {
          ...state.selected_data_config,
          data_span: "custom",
          custom_span_start_time: action.custom_span_start_time,
          custom_span_end_time: action.custom_span_end_time,
          data_type: action.selected_data_config_data_type
        },
        raw_data_array: action.raw_data_array
      };

    case GET_RAW_DATA_CUSTOM_SPAN_FAILED:
      console.log("error", action.message);
      return state;

    case GET_RAW_DATA_WITH_SPAN_AND_TYPE_AND_TIME_SUCCEEDED:
      return {
        ...state,
        selected_data_config: {
          ...state.selected_data_config,
          data_type: action.selected_data_config_data_type,
          data_span: action.selected_data_config_data_span,
          custom_span_start_time: action.custom_span_start_time,
          custom_span_end_time: action.custom_span_end_time
        },
        raw_data_array: action.raw_data_array
      };

    case GET_RAW_DATA_WITH_SPAN_AND_TYPE_AND_TIME_FAILED:
      console.log("error", action.message);
      return state;

    case GET_RAW_DATA_FOR_FILTER_SUCCEEDED:
      const key_total = action.target_data_name + '_total';
      const key_objective = action.target_data_name + '_objective';
      const key_rate = action.target_data_name + '_rate';
      const key_difference = action.target_data_name + '_difference';
      const key_moving_average = action.target_data_name + '_moving_average_total';
      const key_moving_average_objective = action.target_data_name + '_moving_average_objective';
      const key_moving_average_rate = action.target_data_name + '_moving_average_rate';
      const key_moving_average_difference = action.target_data_name + '_moving_average_difference';
      const key_last_year_total = action.target_data_name + '_last_year_total';
      const key_last_year_objective = action.target_data_name + '_last_year_objective';
      const key_last_year_difference = action.target_data_name + '_last_year_difference';
      const key_last_year_rate = action.target_data_name + '_last_year_rate';
      const key_last_year_comparison_total = action.target_data_name + '_last_year_comparison_total';
      const key_last_year_comparison_objective = action.target_data_name + '_last_year_comparison_objective';
      const key_last_year_comparison_rate = action.target_data_name + '_last_year_comparison_rate';
      const key_account_total = action.target_data_name + '_account_total';
      const key_account_objective = action.target_data_name + '_account_objective';
      const key_account_rate = action.target_data_name + '_account_rate';
      const key_account_difference = action.target_data_name + '_account_difference';
      const key_account_moving_average = action.target_data_name + '_account_moving_average_total';
      const key_account_moving_average_objective = action.target_data_name + '_account_moving_average_objective';
      const key_account_moving_average_rate = action.target_data_name + '_account_moving_average_rate';
      const key_account_moving_average_difference = action.target_data_name + '_account_moving_average_difference';
      const key_account_last_year_total = action.target_data_name + '_account_last_year_total';
      const key_account_last_year_objective = action.target_data_name + '_account_last_year_objective';
      const key_account_last_year_difference = action.target_data_name + '_account_last_year_difference';
      const key_account_last_year_rate = action.target_data_name + '_account_last_year_rate';
      const key_account_last_year_comparison_total = action.target_data_name + '_account_last_year_comparison_total';
      const key_account_last_year_comparison_objective = action.target_data_name + '_account_last_year_comparison_objective';
      const key_account_last_year_comparison_rate = action.target_data_name + '_account_last_year_comparison_rate';


      const fil_results = action.results.filter((data: any) => data.filter === action.filter_name);

      const up_fl = fil_results.map((item: any) => {
        return {
          result_at: item.result_at,
          list: item.list,
        };
      });
      console.log('up_fl', up_fl);

      const wip = up_fl.map((item: any) => {
        return item.list.map((i: any) => {
          const variantTotalKey = i.name + '_' + key_total;
          const variantObjectiveKey = i.name + '_' + key_objective;
          const variantDifferenceKey = i.name + '_' + key_difference;
          const variantRateKey = i.name + '_' + key_rate;
          const variantLastYearRateKey = i.name + '_' + key_last_year_rate;
          const variantMovingAverage = i.name + '_' + key_moving_average;
          const variantMovingAverageObjective = i.name + '_' + key_moving_average_objective;
          const variantMovingAverageRate = i.name + '_' + key_moving_average_rate;
          const variantAccountTotalKey = i.name + '_' + key_account_total;
          const variantAccountObjectiveKey = i.name + '_' + key_account_objective;
          const variantAccountDifferenceKey = i.name + '_' + key_account_difference;
          const variantAccountRateKey = i.name + '_' + key_account_rate;
          const variantAccountLastYearRateKey = i.name + '_' + key_account_last_year_rate;
          const variantAccountMovingAverage = i.name + '_' + key_account_moving_average;
          const variantAccountMovingAverageObjective = i.name + '_' + key_account_moving_average_objective;
          const variantAccountMovingAverageRate = i.name + '_' + key_account_moving_average_rate;

          return {
            result_at: item.result_at,
            [variantTotalKey]: i[key_total],
            [variantObjectiveKey]: i[key_objective],
            [variantDifferenceKey]: i[key_difference],
            [variantRateKey]: i[key_rate],
            [variantLastYearRateKey]: i[key_last_year_rate],
            [variantMovingAverage]: i[key_moving_average],
            [variantMovingAverageObjective]: i[key_moving_average_objective],
            [variantMovingAverageRate]: i[key_moving_average_rate],
            [variantAccountTotalKey]: i[key_account_total],
            [variantAccountObjectiveKey]: i[key_account_objective],
            [variantAccountDifferenceKey]: i[key_account_difference],
            [variantAccountRateKey]: i[key_account_rate],
            [variantAccountLastYearRateKey]: i[key_account_last_year_rate],
            [variantAccountMovingAverage]: i[key_account_moving_average],
            [variantAccountMovingAverageObjective]: i[key_account_moving_average_objective],
            [variantAccountMovingAverageRate]: i[key_account_moving_average_rate],
          };
        });
      });
      console.log('wip', wip);

      const wwip = wip.map((item: any) => {
        return item.reduce((result: any, current: any) => {
          return Object.assign(result, current);
        }, {});
      });
      console.log('wwip', wwip);

      // グラフ内LineのnameとdataKeyの指定に使用
      const labels_name_array = action.results
        .filter((result: any) => result.result_at.seconds === action.results[0].result_at.seconds)
        .filter((rev_detail: any) => rev_detail.filter === action.filter_name)
        .flatMap((item: any) => item.list)
        .map((p: any) => {
          const nameList = p.name;
          const lineNameTotal = p.name + '：実績';
          const lineNameObjective = p.name + '：目標';
          const dataKeyNameTotal = p.name + '_' + key_total;
          const dataKeyNameObjective = p.name + '_' + key_objective;
          const lineNameDifference = p.name + '：差異';
          const lineNameRate = p.name + '：達成率';
          const dataKeyNameDifference = p.name + '_' + key_difference;
          const dataKeyNameRate = p.name + '_' + key_rate;
          const lineNameLastYearRate = p.name + '：昨年比';
          const lineNameMovingAverage = p.name + '：移動平均';
          const dataKeyNameLastYearRate = p.name + '_' + key_last_year_rate;
          const dataKeyNameMovingAverage = p.name + '_' + key_moving_average;
          const dataKeyNameMovingAverageObjective = p.name + '_' + key_moving_average_objective;
          const dataKeyNameMovingAverageRate = p.name + '_' + key_moving_average_rate;

          const lineNameTotalAccount = p.name + '：社数実績';
          const lineNameObjectiveAccount = p.name + '：社数目標';
          const dataKeyNameTotalAccount = p.name + '_' + key_account_total;
          const dataKeyNameObjectiveAccount = p.name + '_' + key_account_objective;
          const lineNameDifferenceAccount = p.name + '：社数差異';
          const lineNameRateAccount = p.name + '：社数達成率';
          const dataKeyNameDifferenceAccount = p.name + '_' + key_account_difference;
          const dataKeyNameRateAccount = p.name + '_' + key_account_rate;
          const lineNameLastYearRateAccount = p.name + '：社数昨年比';
          const lineNameMovingAverageAccount = p.name + '：社数移動平均';
          const dataKeyNameLastYearRateAccount = p.name + '_' + key_account_last_year_rate;
          const dataKeyNameMovingAverageAccount = p.name + '_' + key_account_moving_average;
          const dataKeyNameMovingAverageObjectiveAccount = p.name + '_' + key_account_moving_average_objective;
          const dataKeyNameMovingAverageRateAccount = p.name + '_' + key_account_moving_average_rate;

          return {
            nameList,
            lineNameTotal,
            lineNameObjective,
            dataKeyNameTotal,
            dataKeyNameObjective,
            lineNameDifference,
            lineNameRate,
            dataKeyNameDifference,
            dataKeyNameRate,
            lineNameLastYearRate,
            lineNameMovingAverage,
            dataKeyNameLastYearRate,
            dataKeyNameMovingAverage,
            dataKeyNameMovingAverageObjective,
            dataKeyNameMovingAverageRate,
            lineNameTotalAccount,
            lineNameObjectiveAccount,
            dataKeyNameTotalAccount,
            dataKeyNameObjectiveAccount,
            lineNameDifferenceAccount,
            lineNameRateAccount,
            dataKeyNameDifferenceAccount,
            dataKeyNameRateAccount,
            lineNameLastYearRateAccount,
            lineNameMovingAverageAccount,
            dataKeyNameLastYearRateAccount,
            dataKeyNameMovingAverageAccount,
            dataKeyNameMovingAverageObjectiveAccount,
            dataKeyNameMovingAverageRateAccount
          };
        });
      console.log('labels_name_array', labels_name_array);

      return {
        ...state,
        // detailedResults: action.results,
        filtered_data: wwip,
        labels_name_array: labels_name_array,
      };

    case GET_RAW_DATA_FOR_FILTER_FAILED:
      console.log("error", action.message);
      return state;

    // Legend用の透明度設定
    case SET_TARGET_LINE_OPACITY:
      return {
        ...state,
        opacity_object: {
          ...state.opacity_object,
          [action.dataKey]: action.opacity
        }
      };

    default:
      return state;
  }
};

export default targetDataReducer;
