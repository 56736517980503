import React from "react";
import styled from "styled-components";

import {
	Button,
	ButtonProps,
	createStyles,
	makeStyles,
	Table,
	TableCell,
	TableCellProps,
	TableProps,
	TableRow,
	TableRowProps,
	Typography,
	TypographyProps,
	withStyles,
} from "@material-ui/core";

import CaretDown from "../../../images/icons/arrow_down.svg";
import CaretUp from "../../../images/icons/arrow_up.svg";

export const Wrapper = styled.div(({ theme }) => {
	return {
		width: "100%",
		overflow: "scroll",
		position: "relative",
		"-ms-overflow-style": "none",
		scrollbarWidth: "none",
		"&::-webkit-scrollbar": { display: "none" },
	};
});

export const StyledTable = withStyles((theme) => {
	return {
		root: {
			tableLayout: "fixed",
			borderCollapse: "collapse",
		},
	};
})((props: TableProps) => <Table {...props} />);

interface StyledCellProps extends TableCellProps {
	up?: boolean;
}
export const StyledCell = (props: StyledCellProps) => {
	const { up } = props;
	const classes = makeStyles((theme) =>
		createStyles({
			root: {
				width: 200,
				verticalAlign: "middle",
				padding: theme.spacing(0.5, 1.5),
				border: "1px solid #e2e9f3",
				textAlign: "right",
				color:
					up === undefined
						? "unset"
						: up
						? "rgb(2, 140, 171)"
						: "rgb(175, 0, 42)",
				"&:nth-last-child(1)": {
					borderRight: "none",
				},
			},
		})
	)();
	return <TableCell {...props} classes={{ root: classes.root }} />;
};

export const StickerHeadCell = withStyles((theme) => {
	return {
		root: {
			width: 200,
			verticalAlign: "middle",
			padding: theme.spacing(0, 1.5),
			position: "sticky",
			top: 0,
			left: 0,
			backgroundColor: "#f3f6fa",
			color: "#435878",
			border: "1px solid #e2e9f3",
			borderTop: "none",
			borderBottom: "none",
			textAlign: "center",
			fontSize: 10.5,
			"&:before": {
				content: "",
				position: "absolute",
				top: -1,
				left: -1,
				width: "100%",
				height: "100%",
			},
			"&:nth-child(1)": {
				borderLeft: "none",
			},
			"&:nth-last-child(1)": {
				borderRight: "none",
			},
		},
	};
})((props: TableCellProps) => <TableCell {...props} />);

export const StickyCell = withStyles((theme) => {
	return {
		root: {
			width: 200,
			verticalAlign: "middle",
			position: "sticky",
			top: 0,
			left: 0,
			color: "#435878",
			backgroundColor: "white",
			border: "1px solid #e2e9f3",
			padding: theme.spacing(1.5, 1.5),

			"&:nth-child(1)": {
				borderLeft: "none",
			},
		},
	};
})((props: TableCellProps & { indent?: number }) => (
	<TableCell
		{...props}
		style={{
			...props.style,
			paddingLeft: props.indent
				? props.indent * 16 + 12
				: props.style?.paddingLeft,
		}}
	/>
));

export const StickyRow: React.FC = (props) => {
	const classes = makeStyles((theme) =>
		createStyles({
			row: {},
			cell: {
				width: 200,
				height: "auto",
				verticalAlign: "middle",
				border: "none",
				position: "sticky",
				top: 0,
				left: 0,
				whiteSpace: "nowrap",
				fontWeight: "bolder",
				padding: theme.spacing(0.5, 2, 0.5, 1.5),
				color: "#435878",
			},
		})
	)();

	return (
		<TableRow classes={{ root: classes.row }}>
			<TableCell classes={{ root: classes.cell }}>
				{props.children}
			</TableCell>
		</TableRow>
	);
};

export const StyledTableRow = withStyles((theme) =>
	createStyles({
		root: {},
	})
)((props: TableRowProps) => <TableRow {...props} />);

export const SvgImage = styled.img(({ theme }) => {
	return {
		marginRight: 4,
		marginLeft: 4,
		fontSize: 10,
	};
});

interface CaretSvgImageProps {
	open: boolean;
}
export const CaretSvgImage = (props: CaretSvgImageProps) => {
	const { open } = props;
	const classes = makeStyles((theme) =>
		createStyles({
			img: { height: 20, width: 20, objectFit: "contain" },
		})
	)();
	return <img src={open ? CaretUp : CaretDown} className={classes.img} />;
};

export const StyledButton = withStyles((theme) =>
	createStyles({
		root: {
			color: "rgba(0, 102, 255, 1)",
			padding: 0,
		},
	})
)((props: ButtonProps) => <Button {...props} />);

export const CellContainer = styled.div(({}) => {
	return {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	};
});

export const CellText = withStyles((theme) =>
	createStyles({
		root: {
			flexGrow: 1,
			fontSize: 14,
		},
	})
)((props: TypographyProps) => <Typography {...props} />);
