import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import UserSupport from './UserSupport'



// GlobalHeaderBlock
const GlobalHeaderBlock = styled.div`
  width: 100%;
  height: 80px;
  padding: 0 24px 0 48px;
  box-sizing: border-box;
  background: rgba(0, 102, 255);
  display: flex;
  justify-content: space-between;
`;
// GlobalHeaderLogo
const GlobalHeaderLogo = styled.p`
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  color: #fff;
  margin: 0;
  padding: 28px 0 27px;
  &:hover {
    opacity: 0.6;
  }
`;

const GlobalHeader = () => {
  return(
    <GlobalHeaderBlock>
      <GlobalHeaderLogo>
        <Link to='/'>&amp;act</Link>
      </GlobalHeaderLogo>
      <UserSupport/>
    </GlobalHeaderBlock>
  );
}

export default GlobalHeader;