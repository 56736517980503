import React, { useEffect } from 'react'
import { useLocation } from "react-router-dom";

function PaymentMethod() {
  const location = useLocation();
	const pathname = location.pathname;
  // GA計測タグ
	useEffect(() => {
		document.title = '支払い方法編集'; // ページタイトルを変更
		window.gtag('config', 'G-YXXD65BEJ0', {
			'page_path': pathname // 任意のパス（あるいはprops.location.pathnameなど）
		});
	});
  return (
    <div>
      
    </div>
  )
}

export default PaymentMethod
