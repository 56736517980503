import rsf from "../rsf";
import firebase from "firebase";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { UPDATE_COMPANY_FROM_TO_REQUESTED, UPDATE_COMPANY_FROM_TO_SUCCEEDED, UPDATE_COMPANY_FROM_TO_FAILED, SET_COMPANY_FROM_TO, UPDATE_MOVING_AVERAGE_REQUESTED, UPDATE_MOVING_AVERAGE_SUCCEEDED, UPDATE_MOVING_AVERAGE_FAILED, UPDATE_WEEK_START_DATE_REQUESTED, UPDATE_WEEK_START_DATE_SUCCEEDED,
  UPDATE_WEEK_START_DATE_FAILED, 
  UPDATE_CLOSING_MONTH_FAILED,
  UPDATE_CLOSING_MONTH_SUCCEEDED,
  UPDATE_CLOSING_MONTH_REQUESTED} from "../reducks/company/types";
import { GET_RESULTS_SUCCEEDED, GET_RESULTS_FAILED } from "../reducks/results/types";
import { GET_RAW_DATA_SUCCEEDED ,GET_RAW_DATA_CUSTOM_SPAN_SUCCEEDED } from "../reducks/rawData/types";
import { GET_RAW_DATA_FOR_FILTER_SUCCEEDED } from "../reducks/rawData/types";
import getCustomFilteredData from "./rawDataSagas/getCustomFilteredData";

const db = firebase.firestore();

function* updateCompanyFromTo(action) {
  try {
    if (action.selected_data_config_data_type === "monthly") {
      const firstDateMonth = new Date((action.from.setDate(1) / 1000) * 1000)
      console.log('firstDateMonth', firstDateMonth)
      const company = yield call(rsf.firestore.updateDocument, `companies/${action.company_id}`, {
        "config.monthly_from_month": firebase.firestore.Timestamp.fromDate(firstDateMonth),
        "config.monthly_to_month": firebase.firestore.Timestamp.fromDate(action.to)
      });
      const snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_monthly`).where("result_at", ">=", firebase.firestore.Timestamp.fromDate(firstDateMonth)).where("result_at", "<=", firebase.firestore.Timestamp.fromDate(action.to)));
      let results = [];

      snapshot.forEach(result => {
        results = [...results, { ...result.data() }];
      });

      let raw_detail_data_array;
      let raw_detail_data_array_filtered;
      if(action.filter_name) {
        raw_detail_data_array = yield* getCustomFilteredData(action.company_id, action.target_data_name, firebase.firestore.Timestamp.fromDate(firstDateMonth), firebase.firestore.Timestamp.fromDate(action.to))
        raw_detail_data_array_filtered = raw_detail_data_array.filter(rdd => rdd.resultSpan === action.selected_data_config_data_type).filter(r => r.filter === action.filter_name)
      }

      if(action.filter_name) {
        yield all([
          put({
            type: SET_COMPANY_FROM_TO,
            resultSpan: action.selected_data_config_data_type,
            from: firebase.firestore.Timestamp.fromDate(firstDateMonth),
            to: firebase.firestore.Timestamp.fromDate(action.to)
          }),
          put({
            type: GET_RAW_DATA_FOR_FILTER_SUCCEEDED,
            // target_detail_data: target_detail_data,
            raw_detail_data_array: raw_detail_data_array,
            raw_detail_data_array_filtered: raw_detail_data_array_filtered,
            filter_name: action.filter_name,
            target_data_name: action.target_data_name,
            results: raw_detail_data_array_filtered
          }),
          put({
            type: GET_RAW_DATA_SUCCEEDED,
            raw_data_array: results,
            custom_span_start_time: firebase.firestore.Timestamp.fromDate(firstDateMonth),
            custom_span_end_time: firebase.firestore.Timestamp.fromDate(action.to)
          })
        ]);
      } else {
        yield all([
          put({
            type: SET_COMPANY_FROM_TO,
            resultSpan: action.selected_data_config_data_type,
            from: firebase.firestore.Timestamp.fromDate(firstDateMonth),
            to: firebase.firestore.Timestamp.fromDate(action.to)
          }),
          put({
            type: GET_RAW_DATA_FOR_FILTER_SUCCEEDED,
            // target_detail_data: target_detail_data,
            raw_detail_data_array: raw_detail_data_array,
            raw_detail_data_array_filtered: raw_detail_data_array_filtered,
            filter_name: action.filter_name,
            target_data_name: action.target_data_name,
            results: raw_detail_data_array_filtered || []
          }),
          put({
            type: GET_RAW_DATA_SUCCEEDED,
            raw_data_array: results,
            custom_span_start_time: firebase.firestore.Timestamp.fromDate(firstDateMonth),
            custom_span_end_time: firebase.firestore.Timestamp.fromDate(action.to)
          })
        ]);
      }
      
    } else if (action.selected_data_config_data_type === "weekly") {
      const company = yield call(rsf.firestore.updateDocument, `companies/${action.company_id}`, {
        "config.weekly_from_date": firebase.firestore.Timestamp.fromDate(action.from),
        "config.weekly_to_date": firebase.firestore.Timestamp.fromDate(action.to)
      });
      const snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_weekly`).where("result_at", ">=", firebase.firestore.Timestamp.fromDate(action.from)).where("result_at", "<=", firebase.firestore.Timestamp.fromDate(action.to)));
      let results = [];

      snapshot.forEach(result => {
        results = [...results, { ...result.data() }];
      });

      yield all([
        put({
          type: SET_COMPANY_FROM_TO,
          resultSpan: action.selected_data_config_data_type,
          from: firebase.firestore.Timestamp.fromDate(action.from),
          to: firebase.firestore.Timestamp.fromDate(action.to)
        }),
        put({
          type: GET_RESULTS_SUCCEEDED,
          results: results,
          resultSpan: action.selected_data_config_data_type
        }),
        put({
          type: GET_RAW_DATA_CUSTOM_SPAN_SUCCEEDED,
          raw_data_array: results,
          custom_span_start_time : firebase.firestore.Timestamp.fromDate(action.from),
          custom_span_end_time : firebase.firestore.Timestamp.fromDate(action.to),
          selected_data_config_data_type : action.selected_data_config_data_type
        }),
      ]);
    } else {
      const company = yield call(rsf.firestore.updateDocument, `companies/${action.company_id}`, {
        "config.daily_from_date": firebase.firestore.Timestamp.fromDate(action.from),
        "config.daily_to_date": firebase.firestore.Timestamp.fromDate(action.to)
      });
      const snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${action.company_id}/result_daily`).where("result_at", ">=", firebase.firestore.Timestamp.fromDate(action.from)).where("result_at", "<=", firebase.firestore.Timestamp.fromDate(action.to)));
      let results = [];

      snapshot.forEach(result => {
        results = [...results, { ...result.data() }];
      });

      yield all([
        put({
          type: SET_COMPANY_FROM_TO,
          resultSpan: action.selected_data_config_data_type,
          from: firebase.firestore.Timestamp.fromDate(action.from),
          to: firebase.firestore.Timestamp.fromDate(action.to)
        }),
        put({
          type: GET_RESULTS_SUCCEEDED,
          results: results,
          resultSpan: action.selected_data_config_data_type
        }),
        put({
          type: GET_RAW_DATA_CUSTOM_SPAN_SUCCEEDED,
          raw_data_array: results,
          custom_span_start_time : firebase.firestore.Timestamp.fromDate(action.from),
          custom_span_end_time : firebase.firestore.Timestamp.fromDate(action.to),
          selected_data_config_data_type : action.selected_data_config_data_type
        }),
      ]);
    }
  } catch (e) {
    yield put({ type: UPDATE_COMPANY_FROM_TO_FAILED, message: e.message });
  }
}


function* updateMovingAverage(action) {
  try {
    yield call(
      rsf.firestore.updateDocument,
      `companies/${action.company_id}`,
      {
        config: {
          ...action.config,
          moving_average: action.moving_average
        }
      }
    )
    const snapshot = yield call(rsf.firestore.getDocument, `companies/${action.company_id}`);
    const company = snapshot.data();
    yield put({ type: UPDATE_MOVING_AVERAGE_SUCCEEDED, moving_average: company.config.moving_average });
  } catch (e) {
    yield put({ type: UPDATE_MOVING_AVERAGE_FAILED, message: e.message });
  }
}


function* updateWeekStartDate(action) {
  try {
    console.log('action.week_start_day', action.weekly_start_day);

    yield call(
      rsf.firestore.updateDocument,
      `companies/${action.company_id}`,
      {
        config: {
          ...action.config,
          weekly_start_day: action.weekly_start_day
        }
      }
    )
    const snapshot = yield call(rsf.firestore.getDocument, `companies/${action.company_id}`);

    const company = snapshot.data();
    yield put({ type: UPDATE_WEEK_START_DATE_SUCCEEDED, weekly_start_day: company.config.weekly_start_day });
  } catch (e) {
    yield put({ type: UPDATE_WEEK_START_DATE_FAILED, message: e.message });
  }
}

function* updateClosingMonthData(action) {
  
  try {
    yield call(
      rsf.firestore.updateDocument,
      `companies/${action.company_id}`,
      {
        closing_month: action.closing_month 
      }
    )
    const snapshot = yield call(rsf.firestore.getDocument, `companies/${action.company_id}`);
    const company = snapshot.data();
    yield put({ type: UPDATE_CLOSING_MONTH_SUCCEEDED, closing_month:company.closing_month  });
  } catch (e) {
    yield put({ type: UPDATE_CLOSING_MONTH_FAILED, message: e.message });
  }
}


const companySagas = [
  takeLatest(UPDATE_COMPANY_FROM_TO_REQUESTED, updateCompanyFromTo),
  takeLatest(UPDATE_MOVING_AVERAGE_REQUESTED, updateMovingAverage),
  takeLatest(UPDATE_WEEK_START_DATE_REQUESTED, updateWeekStartDate),
  takeLatest(UPDATE_CLOSING_MONTH_REQUESTED, updateClosingMonthData),
];

export default companySagas;
