import rsf from "../../rsf";
import { call } from "redux-saga/effects";
import firebase from "firebase";

const db = firebase.firestore();

// cohortデータを取得
function* getRawDataForCohort(company_id, target_month, target_span) {
  try {

    // 期間設定
    const start_time = new Date(target_month);
    const month = start_time.getMonth();
    const end_time = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month + (target_span - 13)));
    console.log('start_time', start_time)
    console.log('end_time', end_time)
    console.log('month', month)

    // データ取得
    const snapshot = yield call(
      rsf.firestore.getCollection,
      db.collection(`companies/${company_id}/result_monthly`)
        .where("result_at", ">=", start_time)
        .where("result_at", "<=", end_time)
      );

    // 取得後データを整形
    let raw_data_array = [];
    snapshot.forEach(result_monthly => {
      raw_data_array = [...raw_data_array, { ...result_monthly.data(), docId: result_monthly.id }];
    });

    return raw_data_array;
  } catch (e) {
    console.log("error message", e.message);
  }
}


export default getRawDataForCohort;
