const TOGGLE_COMMENT_EDIT_WINDOW_DISPLAY = 'TOGGLE_COMMENT_EDIT_WINDOW_DISPLAY'
const TOGGLE_COMMENT_ADD_WINDOW_DISPLAY = 'TOGGLE_COMMENT_ADD_WINDOW_DISPLAY'
const TOGGLE_COMMENTS_WINDOW_DISPLAY = 'TOGGLE_COMMENTS_WINDOW_DISPLAY'
const HANDLE_COMMENT_CONTENT = 'HANDLE_COMMENT_CONTENT'

const ADD_COMMENT_REQUESTED = 'ADD_COMMENT_REQUESTED'
const ADD_COMMENT_SUCCEEDED = 'ADD_COMMENT_SUCCEEDED'
const ADD_COMMENT_FAILED = 'ADD_COMMENT_FAILED'

const GET_COMMENTS_REQUESTED = 'GET_COMMENTS_REQUESTED'
const GET_COMMENTS_SUCCEEDED = 'GET_COMMENTS_SUCCEEDED'
const GET_COMMENTS_FAILED = 'GET_COMMENTS_FAILED'

const UPDATE_COMMENT_REQUESTED = 'UPDATE_COMMENT_REQUESTED'
const UPDATE_COMMENT_SUCCEEDED = 'UPDATE_COMMENT_SUCCEEDED'
const UPDATE_COMMENT_FAILED = 'UPDATE_COMMENT_FAILED'

const DELETE_COMMENT_REQUESTED = 'DELETE_COMMENT_REQUESTED'
const DELETE_COMMENT_SUCCEEDED = 'DELETE_COMMENT_SUCCEEDED'
const DELETE_COMMENT_FAILED = 'DELETE_COMMENT_FAILED'

const CLOSE_COMMENTS_WINDOW = 'CLOSE_COMMENTS_WINDOW';
const CLOSE_COMMENT_EDIT_WINDOW = 'CLOSE_COMMENT_EDIT_WINDOW';
const CLOSE_COMMENT_ADD_WINDOW = 'CLOSE_COMMENT_ADD_WINDOW';

const OPEN_COMMENT_EDIT_WINDOW = 'OPEN_COMMENT_EDIT_WINDOW';
const UPDATE_COMMENT_EDIT_WINDOW_CONTENT = 'UPDATE_COMMENT_EDIT_WINDOW_CONTENT';

export { TOGGLE_COMMENTS_WINDOW_DISPLAY, TOGGLE_COMMENT_EDIT_WINDOW_DISPLAY, TOGGLE_COMMENT_ADD_WINDOW_DISPLAY, HANDLE_COMMENT_CONTENT, ADD_COMMENT_REQUESTED, ADD_COMMENT_SUCCEEDED, ADD_COMMENT_FAILED, GET_COMMENTS_REQUESTED, GET_COMMENTS_SUCCEEDED, GET_COMMENTS_FAILED, UPDATE_COMMENT_REQUESTED, UPDATE_COMMENT_SUCCEEDED, UPDATE_COMMENT_FAILED, DELETE_COMMENT_REQUESTED, DELETE_COMMENT_SUCCEEDED, DELETE_COMMENT_FAILED, CLOSE_COMMENTS_WINDOW, CLOSE_COMMENT_EDIT_WINDOW, CLOSE_COMMENT_ADD_WINDOW, OPEN_COMMENT_EDIT_WINDOW, UPDATE_COMMENT_EDIT_WINDOW_CONTENT }