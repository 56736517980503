import {TOGGLE_COMMENTS_WINDOW_DISPLAY, TOGGLE_COMMENT_EDIT_WINDOW_DISPLAY, TOGGLE_COMMENT_ADD_WINDOW_DISPLAY, HANDLE_COMMENT_CONTENT, ADD_COMMENT_REQUESTED, GET_COMMENTS_REQUESTED, UPDATE_COMMENT_REQUESTED, DELETE_COMMENT_REQUESTED, CLOSE_COMMENTS_WINDOW, CLOSE_COMMENT_EDIT_WINDOW, CLOSE_COMMENT_ADD_WINDOW, OPEN_COMMENT_EDIT_WINDOW, UPDATE_COMMENT_EDIT_WINDOW_CONTENT } from './types'


export const toggleCommentsWindowDisplay = (display: boolean, result_at_timestamp: any) => ({
  type: TOGGLE_COMMENTS_WINDOW_DISPLAY,
  display,
  result_at_timestamp
})

export const toggleCommentEditWindowDisplay = (id: string, resultId: string, isCommentEditWindowDisplay: boolean, content: string) => ({
  type: TOGGLE_COMMENT_EDIT_WINDOW_DISPLAY,
  id,
  resultId,
  isCommentEditWindowDisplay,
  content
})

export const toggleAddCommentWindow = (
  display: boolean,
  result_at_timestamp: any,
  target_data_name: string,
  company_id: string,
  user_name: string,
  selected_data_config_data_type: any,
  selected_data_config_data_span: any,
  custom_span_start_time: any,
  custom_span_end_time: any,
) => ({
  type: TOGGLE_COMMENT_ADD_WINDOW_DISPLAY,
  display,
  result_at_timestamp,
  target_data_name,
  company_id,
  user_name,
  selected_data_config_data_type,
  selected_data_config_data_span,
  custom_span_start_time,
  custom_span_end_time
})

export const handleCommentContent = (content: string) => ({
  type: HANDLE_COMMENT_CONTENT,
  content
})

export const addCommentRequest = (
  result_at_timestamp: number,
  target_data_name: string,
  company_id: string,
  user_name: string,
  selected_data_config_data_type: string,
  selected_data_config_data_span: string,
  custom_span_start_time: any,
  custom_span_end_time: any,
  content: string
) => ({
  type: ADD_COMMENT_REQUESTED,
  result_at_timestamp,
  target_data_name,
  company_id,
  user_name,
  selected_data_config_data_type,
  selected_data_config_data_span,
  custom_span_start_time,
  custom_span_end_time,
  content
})

export const updateCommentRequest = (company_id: string, docId: string, user_name: string, content: string, selected_data_config_data_type: string, custom_span_start_time: Date, custom_span_end_time: Date, target_data_name: string) => ({
  type: UPDATE_COMMENT_REQUESTED,
  company_id,
  docId,
  user_name,
  content,
  selected_data_config_data_type,
  custom_span_start_time,
  custom_span_end_time,
  target_data_name
})

export const deleteCommentRequest = (company_id: string, docId: string, selected_data_config_data_type: string, custom_span_start_time: Date, custom_span_end_time: Date, target_data_name: string) => ({
  type: DELETE_COMMENT_REQUESTED,
  company_id,
  docId,
  selected_data_config_data_type,
  custom_span_start_time,
  custom_span_end_time,
  target_data_name
})

// export const getCommentsRequest = (company_id: string, target_data_name: string, selected_data_config_data_type: string, custom_span_start_time: Date, custom_span_end_time: Date) => ({
//   type: GET_COMMENTS_REQUESTED,
//   company_id,
//   target_data_name,
//   selected_data_config_data_type,
//   custom_span_start_time,
//   custom_span_end_time
// })

export const getCommentsRequest = (
  target_data_name: string,
  company_id: string,
  user_name: string,
  selected_data_config_data_type: string,
  selected_data_config_data_span: string,
  custom_span_start_time: Date,
  custom_span_end_time: Date
  ) => ({
  type: GET_COMMENTS_REQUESTED,
  target_data_name,
  company_id,
  user_name,
  selected_data_config_data_type,
  selected_data_config_data_span,
  custom_span_start_time,
  custom_span_end_time
})


export const closeCommentsWindow = () => ({
  type: CLOSE_COMMENTS_WINDOW
})

export const closeCommentEditWindow = () => ({
  type: CLOSE_COMMENT_EDIT_WINDOW
})

export const closeCommentAddWindow = () => ({
  type: CLOSE_COMMENT_ADD_WINDOW
})

export const openCommentEditWindow = (docId: string, content: string) => ({
  type: OPEN_COMMENT_EDIT_WINDOW,
  docId,
  content
})

export const updateCommentEditWindowContent = (content: string) => ({
  type: UPDATE_COMMENT_EDIT_WINDOW_CONTENT,
  content
})