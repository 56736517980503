import React, { useCallback, useEffect } from "react";
import FileSaver from "file-saver";
import {
  updateDownloadGraphStatus,
} from "../../reducks/download/actions";
import {
  updateDownloadCsvTarget,
} from "../../reducks/download/actions";
import { useCurrentPng } from "recharts-to-png";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import GlobalNav from "../Layout/GlobalNav";
import PageHeader from "../Layout/PageHeader";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import FunnelsSubMenu from "../Layout/FunnelsSubMenu";

import { setPagePath } from "../../reducks/sessions/actions";

import SpanComparisonFilter from "../SpanComparisonFilters/SpanComparisonFilter";

import LoadingCover from "../LoadingCover";

import FunnelSummaryGraphDefault from "./FunnelSummary/FunnelSummaryGraphDefault";
import FunnelSummaryGraphTransitionRateLeadTime from "./FunnelSummary/FunnelSummaryGraphTransitionRateLeadTime";
import FunnelSummaryGraphSpanFilter from "./FunnelSummary/FunnelSummaryGraphSpanFilter";
import { setForecastFilterStatus } from "../../reducks/targetForecast/actions";
import { DraftTable } from "../Table/Draft";
import { FunnelSummaryTransitionRateLeadTimeTable } from "../Table/FunnelSummaryTransitionRateLeadTime";
import FunnelSummaryGraphLineGraph from "./FunnelSummary/FunnelSummaryGraphLineGraph";
import FunnelSummaryGraphStackedBarGraphByFunnel from "./FunnelSummary/FunnelSummaryGraphStackedBarGraphByFunnel";
import FunnelSummaryGraphPiChart from "./FunnelSummary/FunnelSummaryGraphPiChart";
import FunnelSummaryGraphStackedBarGraph from "./FunnelSummary/FunnelSummaryGraphStackedBarGraph";
import FunnelSummaryHeader from "./FunnelSummary/FunnelSummaryHeader";
import GraphDataDownload from "../Parts/GraphDataDownload";

import {
  leadCountDataGenerator,
  leadCountRateDataGenerator,
  transitionRateDataGenerator,
  leadTimeGenerator,
  genFix
} from "../Util/summaryDataGenetator";
export interface LeadNewCountTotalItem {
  channel_name: string;
  lead_new_count_total: string;
}
export interface LeadTransitionRateItem {
  channel_name: string;
  lead_transition_rate_total: string;
}
export interface FunnelSummaryDataItem {
  lead_new_count_total_array: LeadNewCountTotalItem[];
  lead_transition_rate_array: LeadTransitionRateItem[];
  funnel_name: string;
}
export interface FunnelSummaryData {
  result_at: {
    seconds: string;
    nanoseconds: string;
  };
  funnel_summary_data: FunnelSummaryDataItem[];
}

export interface FunnelSummaryDataProps {
  funnel_summary_data_array: FunnelSummaryData[];
}
const FunnelSummary: React.FC = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  dispatch(setPagePath("/funnels/summary"));

  useEffect(() => {
    dispatch(setForecastFilterStatus("reset_forecast"));
  }, [dispatch]);

  const activatedFilter = useSelector((state: any) => state.filter);

  const resultSpan = useSelector((state: any) => state.target_data.resultSpan);
  const resultsMonthly = useSelector(
    (state: any) => state.results.resultMonthly
  );
  const resultsWeekly = useSelector((state: any) => state.results.resultWeekly);
  const resultsDaily = useSelector((state: any) => state.results.resultDaily);

  const moving_average_graph_switch = useSelector(
    (state: any) => state.session.moving_average_graph_switch
  );

  let resultsList: object[] = [];
  if (resultSpan === "monthly") {
    resultsList = resultsMonthly;
  } else if (resultSpan === "weekly") {
    resultsList = resultsWeekly;
  } else {
    resultsList = resultsDaily;
  }
  const raw_data_array = useSelector(
    (state: any) => state.raw_data.raw_data_array
  );

  // ================================================================================================
  const graph_color_array = [
    {
      id: 1,
      color_code: "#0050C9"
    },
    {
      id: 2,
      color_code: "#EAA800"
    },
    {
      id: 3,
      color_code: "#E53E66"
    },
    {
      id: 4,
      color_code: "#B7D20D"
    },
    {
      id: 5,
      color_code: "#009479"
    },
    {
      id: 6,
      color_code: "#109CBB"
    },
    {
      id: 7,
      color_code: "#9E00FF"
    }
  ];

  const funnel_summary_data_array: FunnelSummaryData[] =
    raw_data_array && raw_data_array.length > 0
      ? raw_data_array.map((rd: any) => {
          return {
            result_at: rd.result_at,
            funnel_summary_data: rd.funnel_summary_data
          };
        })
      : [];
  console.log("funnel_summary_data_array", funnel_summary_data_array);

  // 月・週・日ごとの、チャネルごとのリード件数合計: リード獲得
  const lead_new_count_lead_with_time =
    funnel_summary_data_array.length > 0
      ? funnel_summary_data_array
          .map(fsd => {
            return fsd.funnel_summary_data
              .filter(fsdc => fsdc.funnel_name === "リード獲得")
              .map(fsdgc => {
                const spread_target_lead_new_count_total_array =
                  fsdgc.lead_new_count_total_array.reduce(
                    (acc: any, current: any) => {
                      acc[current.channel_name] =
                        (acc[current.channel_name] || 0) +
                        Number(current.lead_new_count_total);
                      return acc;
                    },
                    {}
                  );
                const r_a =
                  fsd.result_at.seconds == null
                    ? "データ不足"
                    : resultSpan === "monthly"
                    ? `${
                        new Date(
                          Number(fsd.result_at.seconds) * 1000
                        ).getFullYear() +
                        "/" +
                        (new Date(
                          Number(fsd.result_at.seconds) * 1000
                        ).getMonth() +
                          1)
                      }`
                    : `${
                        new Date(
                          Number(fsd.result_at.seconds) * 1000
                        ).getFullYear() +
                        "/" +
                        (new Date(
                          Number(fsd.result_at.seconds) * 1000
                        ).getMonth() +
                          1) +
                        "/" +
                        new Date(Number(fsd.result_at.seconds) * 1000).getDate()
                      }`;
                return {
                  result_at: r_a,
                  ...spread_target_lead_new_count_total_array
                };
              });
          })
          .flat()
      : [];
  console.log("lead_new_count_lead_with_time", lead_new_count_lead_with_time);

  // 月・週・日ごとの、チャネルごとのリード件数合計: アポ獲得
  const lead_new_count_appointment_with_time = funnel_summary_data_array
    .map(fsd => {
      return fsd.funnel_summary_data
        .filter(fsdc => fsdc.funnel_name === "アポ獲得")
        .map(fsdgc => {
          const spread_target_lead_new_count_total_array =
            fsdgc.lead_new_count_total_array.reduce(
              (acc: any, current: any) => {
                acc[current.channel_name] =
                  (acc[current.channel_name] || 0) +
                  Number(current.lead_new_count_total);
                return acc;
              },
              {}
            );
          const r_a =
            fsd.result_at.seconds == null
              ? "データ不足"
              : resultSpan === "monthly"
              ? `${
                  new Date(Number(fsd.result_at.seconds) * 1000).getFullYear() +
                  "/" +
                  (new Date(Number(fsd.result_at.seconds) * 1000).getMonth() +
                    1)
                }`
              : `${
                  new Date(Number(fsd.result_at.seconds) * 1000).getFullYear() +
                  "/" +
                  (new Date(Number(fsd.result_at.seconds) * 1000).getMonth() +
                    1) +
                  "/" +
                  new Date(Number(fsd.result_at.seconds) * 1000).getDate()
                }`;
          return {
            result_at: r_a,
            ...spread_target_lead_new_count_total_array
          };
        });
    })
    .flat();
  console.log(
    "lead_new_count_appointment_with_time",
    lead_new_count_appointment_with_time
  );

  // 月・週・日ごとの、チャネルごとのリード件数合計: 商談化
  const lead_new_count_opportunity_with_time = funnel_summary_data_array
    .map(fsd => {
      return fsd.funnel_summary_data
        .filter(fsdc => fsdc.funnel_name === "商談化")
        .map(fsdgc => {
          const spread_target_lead_new_count_total_array =
            fsdgc.lead_new_count_total_array.reduce(
              (acc: any, current: any) => {
                acc[current.channel_name] =
                  (acc[current.channel_name] || 0) +
                  Number(current.lead_new_count_total);
                return acc;
              },
              {}
            );
          const r_a =
            fsd.result_at.seconds == null
              ? "データ不足"
              : resultSpan === "monthly"
              ? `${
                  new Date(Number(fsd.result_at.seconds) * 1000).getFullYear() +
                  "/" +
                  (new Date(Number(fsd.result_at.seconds) * 1000).getMonth() +
                    1)
                }`
              : `${
                  new Date(Number(fsd.result_at.seconds) * 1000).getFullYear() +
                  "/" +
                  (new Date(Number(fsd.result_at.seconds) * 1000).getMonth() +
                    1) +
                  "/" +
                  new Date(Number(fsd.result_at.seconds) * 1000).getDate()
                }`;
          return {
            result_at: r_a,
            ...spread_target_lead_new_count_total_array
          };
        });
    })
    .flat();
  console.log(
    "lead_new_count_opportunity_with_time",
    lead_new_count_opportunity_with_time
  );

  // 月・週・日ごとの、チャネルごとのリード件数合計: 受注
  const lead_new_count_contract_with_time = funnel_summary_data_array
    .map(fsd => {
      return fsd.funnel_summary_data
        .filter(fsdc => fsdc.funnel_name === "受注")
        .map(fsdgc => {
          const spread_target_lead_new_count_total_array =
            fsdgc.lead_new_count_total_array.reduce(
              (acc: any, current: any) => {
                acc[current.channel_name] =
                  (acc[current.channel_name] || 0) +
                  Number(current.lead_new_count_total);
                return acc;
              },
              {}
            );
          const r_a =
            fsd.result_at.seconds == null
              ? "データ不足"
              : resultSpan === "monthly"
              ? `${
                  new Date(Number(fsd.result_at.seconds) * 1000).getFullYear() +
                  "/" +
                  (new Date(Number(fsd.result_at.seconds) * 1000).getMonth() +
                    1)
                }`
              : `${
                  new Date(Number(fsd.result_at.seconds) * 1000).getFullYear() +
                  "/" +
                  (new Date(Number(fsd.result_at.seconds) * 1000).getMonth() +
                    1) +
                  "/" +
                  new Date(Number(fsd.result_at.seconds) * 1000).getDate()
                }`;
          return {
            result_at: r_a,
            ...spread_target_lead_new_count_total_array
          };
        });
    })
    .flat();
  console.log(
    "lead_new_count_contract_with_time",
    lead_new_count_contract_with_time
  );

  const all_funnel_summary_data = funnel_summary_data_array.flatMap(
    fsd => fsd.funnel_summary_data
  );

  // ================================================================================================
  // 件数の推移用途
  // ファネル毎のすべての件数配列
  const lead_new_count_total_array = all_funnel_summary_data.flatMap(
    fsd => fsd.lead_new_count_total_array
  );
  // ファネル毎のすべての件数配列から抽出した、重複のないチャネル名
  const channel_array_lead_new_count_total = Array.from(
    new Set(lead_new_count_total_array.flatMap(ltr => ltr.channel_name))
  ).map((cn: string, index: number) => {
    return {
      channel_id: index + 1,
      channel_name: cn,
      deactivated: false
    };
  });

  // カラーコード情報を付与した、ファネル毎のすべての件数配列から抽出した重複のないチャネル名配列
  const channel_array_lead_new_count_total_with_color =
    channel_array_lead_new_count_total.map((channel_name, index) => {
      if (index < 7) {
        return {
          ...channel_name,
          channel_color: graph_color_array[index].color_code
        };
      } else {
        const calc_index = index % 7;
        return {
          ...channel_name,
          channel_color: graph_color_array[calc_index].color_code
        };
      }
    });

  // 「リード獲得」の件数配列
  const lead_new_count_total_array_lead = all_funnel_summary_data
    .filter(fsd => fsd.funnel_name === "リード獲得")
    .flatMap(fsd => fsd.lead_new_count_total_array);

  // channel_nameごとの合計件数(パターンdefault)：「リード獲得」
  const sum_lead_new_count_total_array_lead =
    lead_new_count_total_array_lead.reduce((acc: any, current: any) => {
      acc[current.channel_name] =
        (acc[current.channel_name] || 0) + Number(current.lead_new_count_total);
      return acc;
    }, {});
  console.log(
    "sum_lead_new_count_total_array_lead",
    sum_lead_new_count_total_array_lead
  );

  // channel_nameごとの合計件数(パターン2配列return)：「リード獲得」
  const sum_lead_new_count_total_array_lead_array =
    lead_new_count_total_array_lead.reduce((acc: any, current: any) => {
      let existItem = acc.find(
        (item: any) => item.channel_name === current.channel_name
      );
      if (existItem) {
        existItem.lead_new_count_total += Number(current.lead_new_count_total);
        return acc;
      }
      acc.push({
        channel_name: current.channel_name,
        lead_new_count_total: Number(current.lead_new_count_total)
      });
      return acc;
    }, []);
  console.log(
    "sum_lead_new_count_total_array_lead_array",
    sum_lead_new_count_total_array_lead_array
  );

  // 「アポ獲得」の件数配列
  const lead_new_count_total_array_appointment = all_funnel_summary_data
    .filter(fsd => fsd.funnel_name === "アポ獲得")
    .flatMap(fsd => fsd.lead_new_count_total_array);

  // channel_nameごとの合計件数：「アポ獲得」
  const sum_lead_new_count_total_array_appointment =
    lead_new_count_total_array_appointment.reduce((acc: any, current: any) => {
      acc[current.channel_name] =
        (acc[current.channel_name] || 0) + Number(current.lead_new_count_total);
      return acc;
    }, {});
  console.log(
    "sum_lead_new_count_total_array_appointment",
    sum_lead_new_count_total_array_appointment
  );
  // channel_nameごとの合計件数(パターン2配列return)：「アポ獲得」
  const sum_lead_new_count_total_array_appointment_array =
    lead_new_count_total_array_appointment.reduce((acc: any, current: any) => {
      let existItem = acc.find(
        (item: any) => item.channel_name === current.channel_name
      );
      if (existItem) {
        existItem.lead_new_count_total += Number(current.lead_new_count_total);
        return acc;
      }
      acc.push({
        channel_name: current.channel_name,
        lead_new_count_total: Number(current.lead_new_count_total)
      });
      return acc;
    }, []);
  console.log(
    "sum_lead_new_count_total_array_appointment_array",
    sum_lead_new_count_total_array_appointment_array
  );

  // 「商談化」の件数配列
  const lead_new_count_total_array_opportunity = all_funnel_summary_data
    .filter(fsd => fsd.funnel_name === "商談化")
    .flatMap(fsd => fsd.lead_new_count_total_array);

  // channel_nameごとの合計件数：「商談化」
  const sum_lead_new_count_total_array_opportunity =
    lead_new_count_total_array_opportunity.reduce((acc: any, current: any) => {
      acc[current.channel_name] =
        (acc[current.channel_name] || 0) + Number(current.lead_new_count_total);
      return acc;
    }, {});
  console.log(
    "sum_lead_new_count_total_array_opportunity",
    sum_lead_new_count_total_array_opportunity
  );
  // channel_nameごとの合計件数(パターン2配列return)：「商談化」
  const sum_lead_new_count_total_array_opportunity_array =
    lead_new_count_total_array_opportunity.reduce((acc: any, current: any) => {
      let existItem = acc.find(
        (item: any) => item.channel_name === current.channel_name
      );
      if (existItem) {
        existItem.lead_new_count_total += Number(current.lead_new_count_total);
        return acc;
      }
      acc.push({
        channel_name: current.channel_name,
        lead_new_count_total: Number(current.lead_new_count_total)
      });
      return acc;
    }, []);
  console.log(
    "sum_lead_new_count_total_array_opportunity_array",
    sum_lead_new_count_total_array_opportunity_array
  );

  // 「受注」の件数配列
  const lead_new_count_total_array_contract = all_funnel_summary_data
    .filter(fsd => fsd.funnel_name === "受注")
    .flatMap(fsd => fsd.lead_new_count_total_array);

  // channel_nameごとの合計件数：「受注」
  const sum_lead_new_count_total_array_contract =
    lead_new_count_total_array_contract.reduce((acc: any, current: any) => {
      acc[current.channel_name] =
        (acc[current.channel_name] || 0) + Number(current.lead_new_count_total);
      return acc;
    }, {});
  console.log(
    "sum_lead_new_count_total_array_contract",
    sum_lead_new_count_total_array_contract
  );
  // channel_nameごとの合計件数(パターン2配列return)：「受注」
  const sum_lead_new_count_total_array_contract_array =
    lead_new_count_total_array_contract.reduce((acc: any, current: any) => {
      let existItem = acc.find(
        (item: any) => item.channel_name === current.channel_name
      );
      if (existItem) {
        existItem.lead_new_count_total += Number(current.lead_new_count_total);
        return acc;
      }
      acc.push({
        channel_name: current.channel_name,
        lead_new_count_total: Number(current.lead_new_count_total)
      });
      return acc;
    }, []);
  console.log(
    "sum_lead_new_count_total_array_contract_array",
    sum_lead_new_count_total_array_contract_array
  );

  const sum_lead_new_count_total_array_all = [
    {
      ...sum_lead_new_count_total_array_lead,
      funnel_name: "リード獲得"
    },
    {
      ...sum_lead_new_count_total_array_appointment,
      funnel_name: "アポ獲得"
    },
    {
      ...sum_lead_new_count_total_array_opportunity,
      funnel_name: "商談化"
    },
    {
      ...sum_lead_new_count_total_array_contract,
      funnel_name: "受注"
    }
  ];

  // ================================================================================================
  // 移行率の推移グラフ用途

  // ファネル毎のすべての移行率配列
  const lead_transition_rate_array = all_funnel_summary_data.flatMap(
    fsd => fsd.lead_transition_rate_array
  );

  // ファネル毎のすべての移行率配列から抽出した、重複のないチャネル名
  const channel_array_lead_transition = Array.from(
    new Set(lead_transition_rate_array.flatMap(ltr => ltr.channel_name))
  ).map((cn: string, index: number) => {
    return {
      channel_id: index + 1,
      channel_name: cn,
      deactivated: false
    };
  });

  // カラーコード情報を付与した、ファネル毎のすべての移行率配列から抽出した重複のないチャネル名配列
  const channel_array_lead_transition_with_color =
    channel_array_lead_transition.map((channel_name, index) => {
      if (index < 7) {
        return {
          ...channel_name,
          channel_color: graph_color_array[index].color_code
        };
      } else {
        const calc_index = index % 7;
        return {
          ...channel_name,
          channel_color: graph_color_array[calc_index].color_code
        };
      }
    });

  // 「リード獲得・アポ獲得」の移行率配列
  const lead_transition_rate_array_lead_appointment = all_funnel_summary_data
    .filter(fsd => fsd.funnel_name === "アポ獲得")
    .flatMap(fsd => fsd.lead_transition_rate_array);

  // ================================================================================================
  // channel_nameごとの合計値
  // const sum_lead_transition_rate_array_lead_appointment =
  //   lead_transition_rate_array_lead_appointment.reduce((acc: any, current: any) => {
  //     acc[current.channel_name] =
  //       (acc[current.channel_name] || 0) + Number(current.lead_transition_rate_total);
  //     return acc;
  //   }, {});
  // console.log(
  //   "sum_lead_transition_rate_array_lead_appointment",
  //   sum_lead_transition_rate_array_lead_appointment
  // );
  // ================================================================================================

  // ================================================================================================

  // ================================================================================================

  // 「アポ獲得・商談化」の移行率配列
  const lead_transition_rate_array_appointment_opportunity =
    all_funnel_summary_data
      .filter(fsd => fsd.funnel_name === "商談化")
      .flatMap(fsd => fsd.lead_transition_rate_array);

  // 「商談化・受注」の移行率配列
  const lead_transition_rate_array_opportunity_contract =
    all_funnel_summary_data
      .filter(fsd => fsd.funnel_name === "受注")
      .flatMap(fsd => fsd.lead_transition_rate_array);

  // ファネルごとの移行率配列を1つの配列にまとめる
  const array_by_funnel_transition_rate = [
    lead_transition_rate_array_lead_appointment,
    lead_transition_rate_array_appointment_opportunity,
    lead_transition_rate_array_opportunity_contract
  ];

  // ファネルごとの各チャンネル名ごとの移行率平均オブジェクトの配列
  const data_for_graph_line = array_by_funnel_transition_rate.map(
    (abf: any, index: number) => {
      // channel_nameごとの平均値
      let sums = {} as any,
        results = {} as any,
        channel_name;
      for (let i = 0; i < abf.length; i++) {
        channel_name = abf[i].channel_name;
        if (!(channel_name in sums)) {
          sums[channel_name] = 0;
          // counts[channel_name] = 0;
        }
        sums[channel_name] += Number(abf[i].lead_transition_rate_total);
        // counts[channel_name]++;
      }

      for (channel_name in sums) {
        // results.push({ channel_name: channel_name, value: sums[channel_name] / counts[channel_name] });
        results = {
          ...results,
          [channel_name]: sums[channel_name] / funnel_summary_data_array.length
        };
      }
      if (index === 0) {
        return {
          ...results,
          funnel_name: "リード獲得・\nアポ獲得"
        };
      } else if (index === 1) {
        return {
          ...results,
          funnel_name: "アポ獲得・商談化"
        };
      } else if (index === 2) {
        return {
          ...results,
          funnel_name: "商談化・受注"
        };
      } else {
        return {
          ...results
        };
      }
    }
  );

  // const funnelDatas = raw_data_array.map((result: any) => result.lead_new_count).flat();
  const funnelDatas =
    raw_data_array && raw_data_array.length > 0
      ? raw_data_array.flatMap((result: any) => {
          return result.lead_new_count
            ? result.lead_new_count
            : result.lead_new_count;
        })
      : [];
  console.log("funnelDatas", funnelDatas);

  // リードタイム計算
  const leadTimesRaw = raw_data_array
    .map((result: any) => {
      if (result && result.lead_time) {
        return result.lead_time;
      }
      return [];
    })
    .flat();

  // ファネル名
  const funnel_master_list = useSelector(
    (state: any) => state.funnels.funnel_master_list
  );
  const funnel_names_array =
    funnel_master_list && funnel_master_list.length > 0
      ? funnel_master_list.map((funnel_master: any) => funnel_master.name)
      : [];
  console.log("funnel_names_array", funnel_names_array);
  const lead_time_data_summary =
    funnel_names_array && funnel_names_array.length > 0
      ? funnel_names_array.map((funnel_name: any) => {
          return {
            funnel_name: funnel_name,
            lead_time_total:
              leadTimesRaw.reduce(
                (acc: any, cur: any) => acc + cur.lead_time_total,
                0
              ) / raw_data_array.length
          };
        })
      : [];
  console.log("leadTimesRaw", leadTimesRaw);
  console.log("lead_time_data_summary", lead_time_data_summary);

  const leadDatas = funnelDatas.reduce((result: any, current: any) => {
    if (result !== undefined && current !== undefined) {
      const element = result.find(
        (p: any) => p.funnel_name === current.funnel_name
      );
      if (element) {
        element.lead_new_count_total += current.lead_new_count_total;
        element.lead_new_count_objective += current.lead_new_count_objective;
        element.lead_new_count_difference += current.lead_new_count_difference;
        element.lead_new_count_moving_average_total +=
          current.lead_new_count_moving_average_total;
        element.lead_new_count_moving_average_objective +=
          current.lead_new_count_moving_average_objective;
        element.lead_new_count_moving_average_difference +=
          current.lead_new_count_moving_average_difference;
        element.lead_new_count_last_year_comparison_rate +=
          current.lead_new_count_last_year_comparison_rate;
      } else {
        result.push({
          funnel_name: current.funnel_name,
          lead_new_count_total: current.lead_new_count_total,
          lead_new_count_objective: current.lead_new_count_objective,
          lead_new_count_diff:
            current.lead_new_count_total - current.lead_new_count_objective,
          lead_new_count_rate:
            current.lead_new_count_total / current.lead_new_count_objective,
          lead_new_count_moving_average_total:
            current.lead_new_count_moving_average_total,
          lead_new_count_moving_average_objective:
            current.lead_new_count_moving_average_objective,
          lead_new_count_moving_average_diff:
            current.lead_new_count_moving_average_total -
            current.lead_new_count_moving_average_objective,
          lead_new_count_moving_average_rate:
            current.lead_new_count_moving_average_total /
            current.lead_new_count_moving_average_objective,
          lead_new_count_last_year_comparison_rate:
            current.lead_new_count_last_year_comparison_rate
        });
        return result;
      }
    }
    return result;
  }, []);
  console.log("leadDatas", leadDatas);
  const updated_lead_datas = leadDatas.map((lead_data: any) => {
    return {
      funnel_name: lead_data.funnel_name,
      lead_new_count_diff:
        isNaN(lead_data.lead_new_count_diff) ||
        lead_data.lead_new_count_diff === Infinity ||
        lead_data.lead_new_count_diff === undefined
          ? 0
          : lead_data.lead_new_count_diff,
      lead_new_count_difference:
        lead_data.lead_new_count_total - lead_data.lead_new_count_objective,
      lead_new_count_moving_average_diff:
        isNaN(lead_data.lead_new_count_moving_average_diff) ||
        lead_data.lead_new_count_moving_average_diff === Infinity ||
        lead_data.lead_new_count_moving_average_diff === undefined
          ? 0
          : lead_data.lead_new_count_moving_average_diff,
      lead_new_count_moving_average_difference:
        lead_data.lead_new_count_moving_average_total -
        lead_data.lead_new_count_moving_average_objective,
      lead_new_count_moving_average_objective:
        isNaN(lead_data.lead_new_count_moving_average_objective) ||
        lead_data.lead_new_count_moving_average_objective === Infinity ||
        lead_data.lead_new_count_moving_average_objective === undefined
          ? 0
          : lead_data.lead_new_count_moving_average_objective,
      lead_new_count_moving_average_rate:
        isNaN(lead_data.lead_new_count_moving_average_rate) ||
        lead_data.lead_new_count_moving_average_rate === Infinity ||
        lead_data.lead_new_count_moving_average_rate === undefined
          ? 0
          : lead_data.lead_new_count_moving_average_rate,
      lead_new_count_moving_average_total:
        isNaN(lead_data.lead_new_count_moving_average_total) ||
        lead_data.lead_new_count_moving_average_total === Infinity ||
        lead_data.lead_new_count_moving_average_total === undefined
          ? 0
          : lead_data.lead_new_count_moving_average_total,
      lead_new_count_objective:
        isNaN(lead_data.lead_new_count_objective) ||
        lead_data.lead_new_count_objective === Infinity ||
        lead_data.lead_new_count_objective === undefined
          ? 0
          : lead_data.lead_new_count_objective,
      lead_new_count_rate:
        isNaN(lead_data.lead_new_count_rate) ||
        lead_data.lead_new_count_rate === Infinity ||
        lead_data.lead_new_count_rate === undefined
          ? 0
          : lead_data.lead_new_count_rate,
      lead_new_count_total:
        isNaN(lead_data.lead_new_count_total) ||
        lead_data.lead_new_count_total === Infinity ||
        lead_data.lead_new_count_total === undefined
          ? 0
          : lead_data.lead_new_count_total,
      lead_new_count_last_year_comparison_rate:
        isNaN(
          lead_data.lead_new_count_last_year_comparison_rate /
            raw_data_array.length
        ) ||
        lead_data.lead_new_count_last_year_comparison_rate /
          raw_data_array.length ===
          Infinity ||
        lead_data.lead_new_count_last_year_comparison_rate /
          raw_data_array.length ===
          undefined
          ? 0
          : lead_data.lead_new_count_last_year_comparison_rate /
            raw_data_array.length
    };
  });

  // const TransitionDatas = resultsList.map((result: any) => result.lead_transition_rate).flat();

  const transitionDatas = raw_data_array
    .map((result: any) => result.lead_transition_rate)
    .flat();
  console.log("transitionDatas", transitionDatas);

  // 移行率のデータ
  const transitionRates =
    transitionDatas && transitionDatas.length > 0
      ? transitionDatas.reduce((result: any, current: any) => {
          if (result !== undefined && current !== undefined) {
            const element = result.find(
              (p: any) => p.funnel_name === current.funnel_name
            );
            if (element) {
              element.lead_transition_rate_moving_average_objective +=
                current.lead_transition_rate_moving_average_objective;
              element.lead_transition_rate_moving_average_total +=
                current.lead_transition_rate_moving_average_total;
              element.lead_transition_rate_objective +=
                current.lead_transition_rate_objective;
              element.lead_transition_rate_total +=
                current.lead_transition_rate_total;
              element.lead_transition_rate_rate +=
                current.lead_transition_rate_rate;
            } else {
              result.push({
                funnel_name: current.funnel_name,
                lead_transition_rate_moving_average_objective:
                  current.lead_transition_rate_moving_average_objective,
                lead_transition_rate_moving_average_total:
                  current.lead_transition_rate_moving_average_total,
                lead_transition_rate_objective:
                  current.lead_transition_rate_objective,
                lead_transition_rate_total: current.lead_transition_rate_total,
                lead_transition_rate_rate: current.lead_transition_rate_rate
              });
            }
          }
          return result;
        }, [])
      : [];
  console.log("transitionRates", transitionRates);

  const rateAverage = transitionRates.map((data: any, index: number) => {
    let transitionRateAverage = Math.round(
      data.lead_transition_rate_total / transitionRates.length
    );
    let transitionRateObjectiveAverage = Math.round(
      data.lead_transition_rate_objective / transitionRates.length
    );
    let transitionRateDiff =
      transitionRateAverage - transitionRateObjectiveAverage;
    let transitionRateRate =
      transitionRateAverage / transitionRateObjectiveAverage;
    transitionRateRate =
      isNaN(transitionRateRate) ||
      transitionRateRate === Infinity ||
      transitionRateRate === undefined
        ? 0
        : transitionRateRate;
    return {
      transition_rate_average: transitionRateAverage,
      transition_rate_objective_average: transitionRateObjectiveAverage,
      transition_rate_diff: transitionRateDiff,
      transition_rate_rate: transitionRateRate
    };
  });

  console.log("rateAverage", rateAverage);

  const leadTransitionDatas = updated_lead_datas.map(
    (data: any, index: number) => {
      return {
        ...data,
        ...transitionRates[index]
      };
    }
  );

  const funnel_summary_array = leadTransitionDatas.map(
    (data: any, index: number) => {
      return {
        ...data,
        ...rateAverage[index],
        ...lead_time_data_summary[index] // lead_timeの集計データをあわせる
      };
    }
  );

  console.log("funnel_summary_array", funnel_summary_array);

  // =========================================================================================================================================================================================================
  const funnel_summary_array_revised = funnel_names_array.map(
    (name: string, index: number) => {
      if (index === 0) {
        return {
          funnel_name: name,
          lead_new_count_total: genFix(
            leadCountDataGenerator(raw_data_array, name, "lead_new_count_total")
          ),
          lead_new_count_objective: genFix(
            leadCountDataGenerator(
              raw_data_array,
              name,
              "lead_new_count_objective"
            )
          ),
          lead_new_count_rate: genFix(
            leadCountRateDataGenerator(
              raw_data_array,
              name,
              "lead_new_count_rate"
            )
          ),
          lead_new_count_difference: genFix(
            leadCountDataGenerator(
              raw_data_array,
              name,
              "lead_new_count_difference"
            )
          ),
          lead_new_count_last_year_comparison_rate: genFix(
            leadCountDataGenerator(
              raw_data_array,
              name,
              "lead_new_count_last_year_comparison_rate"
            )
          ),
          lead_new_count_moving_average_total: genFix(
            leadCountDataGenerator(
              raw_data_array,
              name,
              "lead_new_count_moving_average_total"
            )
          ),
          lead_new_count_moving_average_objective: genFix(
            leadCountDataGenerator(
              raw_data_array,
              name,
              "lead_new_count_moving_average_objective"
            )
          ),
          lead_new_count_moving_average_rate: genFix(
            leadCountRateDataGenerator(
              raw_data_array,
              name,
              "lead_new_count_moving_average_rate"
            )
          ),
          lead_new_count_moving_average_difference: genFix(
            leadCountDataGenerator(
              raw_data_array,
              name,
              "lead_new_count_moving_average_difference"
            )
          ),
          lead_new_count_moving_average_last_year_comparison_rate: genFix(
            leadCountDataGenerator(
              raw_data_array,
              name,
              "lead_new_count_moving_average_last_year_comparison_rate"
            )
          ),
          lead_time_total: genFix(leadTimeGenerator(raw_data_array, name))
        };
      }
      return {
        funnel_name: name,
        lead_new_count_total: genFix(
          leadCountDataGenerator(raw_data_array, name, "lead_new_count_total")
        ),
        lead_new_count_objective: genFix(
          leadCountDataGenerator(
            raw_data_array,
            name,
            "lead_new_count_objective"
          )
        ),
        lead_new_count_rate: genFix(
          leadCountRateDataGenerator(
            raw_data_array,
            name,
            "lead_new_count_rate"
          )
        ),
        lead_new_count_difference: genFix(
          leadCountDataGenerator(
            raw_data_array,
            name,
            "lead_new_count_difference"
          )
        ),
        lead_new_count_last_year_comparison_rate: genFix(
          leadCountDataGenerator(
            raw_data_array,
            name,
            "lead_new_count_last_year_comparison_rate"
          )
        ),
        lead_new_count_moving_average_total: genFix(
          leadCountDataGenerator(
            raw_data_array,
            name,
            "lead_new_count_moving_average_total"
          )
        ),
        lead_new_count_moving_average_objective: genFix(
          leadCountDataGenerator(
            raw_data_array,
            name,
            "lead_new_count_moving_average_objective"
          )
        ),
        lead_new_count_moving_average_rate: genFix(
          leadCountRateDataGenerator(
            raw_data_array,
            name,
            "lead_new_count_moving_average_rate"
          )
        ),
        lead_new_count_moving_average_difference: genFix(
          leadCountDataGenerator(
            raw_data_array,
            name,
            "lead_new_count_moving_average_difference"
          )
        ),
        lead_new_count_moving_average_last_year_comparison_rate: genFix(
          leadCountDataGenerator(
            raw_data_array,
            name,
            "lead_new_count_moving_average_last_year_comparison_rate"
          )
        ),
        lead_transition_rate_total: genFix(
          transitionRateDataGenerator(
            raw_data_array,
            name,
            "lead_transition_rate_total"
          )
        ),
        lead_transition_rate_objective: genFix(
          transitionRateDataGenerator(
            raw_data_array,
            name,
            "lead_transition_rate_objective"
          )
        ),
        lead_transition_rate_rate: genFix(
          transitionRateDataGenerator(
            raw_data_array,
            name,
            "lead_transition_rate_rate"
          )
        ),
        lead_transition_rate_difference: genFix(
          transitionRateDataGenerator(
            raw_data_array,
            name,
            "lead_transition_rate_difference"
          )
        ),
        lead_transition_rate_last_year_comparison_rate: genFix(
          transitionRateDataGenerator(
            raw_data_array,
            name,
            "lead_transition_rate_last_year_comparison_rate"
          )
        ),
        lead_transition_rate_moving_average_total: genFix(
          transitionRateDataGenerator(
            raw_data_array,
            name,
            "lead_transition_rate_moving_average_total"
          )
        ),
        lead_transition_rate_moving_average_objective: genFix(
          transitionRateDataGenerator(
            raw_data_array,
            name,
            "lead_transition_rate_moving_average_objective"
          )
        ),
        lead_transition_rate_moving_average_rate: genFix(
          transitionRateDataGenerator(
            raw_data_array,
            name,
            "lead_transition_rate_moving_average_rate"
          )
        ),
        lead_transition_rate_moving_average_difference: genFix(
          transitionRateDataGenerator(
            raw_data_array,
            name,
            "lead_transition_rate_moving_average_difference"
          )
        ),
        lead_transition_rate_moving_average_last_year_comparison_rate: genFix(
          transitionRateDataGenerator(
            raw_data_array,
            name,
            "lead_transition_rate_moving_average_last_year_comparison_rate"
          )
        ),
        lead_time_total: genFix(leadTimeGenerator(raw_data_array, name))
      };
    }
  );
  console.log("funnel_summary_array_revised", funnel_summary_array_revised);
  // =========================================================================================================================================================================================================

  // ======================
  // 期間比較フィルター
  const dateSpanComparisonFilterDisplay = useSelector(
    (state: any) => state.spanComparisonCalender.dateSpanComparisonFilterDisplay
  );

  const first_results_daily = useSelector(
    (state: any) => state.funnels.firstResult.resultDaily
  );
  const second_results_daily = useSelector(
    (state: any) => state.funnels.secondResult.resultDaily
  );

  // 2つの期間のデータを1つのオブジェクトにする
  const spanComparisonData = first_results_daily.map(
    (data: any, index: number) => {
      return { ...data, ...second_results_daily[index] };
    }
  );

  // 期間比較フィルターここまで
  // ======================

  // useEffect(() => {
  //   dispatch(fetchFunnelsSummary(raw_data_array))
  // },[dispatch, raw_data_array])

  // 移行率・リードタイム用データ
  const transition_rate_lead_time_array = funnel_summary_array_revised
    .filter((item: any) => item.funnel_name !== "リード獲得")
    .map((filteredItem: any) => {
      if (filteredItem.funnel_name === "アポ獲得") {
        return {
          funnel_name: "リード獲得・アポ獲得",
          lead_transition_rate_total: filteredItem.lead_transition_rate_total,
          lead_transition_rate_objective:
            filteredItem.lead_transition_rate_objective,
          lead_transition_rate_rate: filteredItem.lead_transition_rate_rate,
          lead_transition_rate_difference:
            filteredItem.lead_transition_rate_difference,
          lead_transition_rate_last_year_comparison_rate:
            filteredItem.lead_transition_rate_last_year_comparison_rate,
          lead_time_total: filteredItem.lead_time_total
        };
      } else if (filteredItem.funnel_name === "商談化") {
        return {
          funnel_name: "アポ獲得・商談化",
          lead_transition_rate_total: filteredItem.lead_transition_rate_total,
          lead_transition_rate_objective:
            filteredItem.lead_transition_rate_objective,
          lead_transition_rate_rate: filteredItem.lead_transition_rate_rate,
          lead_transition_rate_difference:
            filteredItem.lead_transition_rate_difference,
          lead_transition_rate_last_year_comparison_rate:
            filteredItem.lead_transition_rate_last_year_comparison_rate,
          lead_time_total: filteredItem.lead_time_total
        };
      } else {
        return {
          funnel_name: "商談化・受注",
          lead_transition_rate_total: filteredItem.lead_transition_rate_total,
          lead_transition_rate_objective:
            filteredItem.lead_transition_rate_objective,
          lead_transition_rate_rate: filteredItem.lead_transition_rate_rate,
          lead_transition_rate_difference:
            filteredItem.lead_transition_rate_difference,
          lead_transition_rate_last_year_comparison_rate:
            filteredItem.lead_transition_rate_last_year_comparison_rate,
          lead_time_total: filteredItem.lead_time_total
        };
      }
    });

  // Graph Download Section
  const [getAreaPng, { ref: areaRef }] = useCurrentPng();
  const downloadGraphStatus = useSelector((state: any) => state.download.downloadGraphStatus);
  const handleAreaDownload = useCallback(async () => {
    dispatch(
      updateDownloadGraphStatus(0)
    )
    const png = await getAreaPng();
    if (png) {
      FileSaver.saveAs(png, "graph.png");
    }
  }, [getAreaPng, dispatch]);

  if (downloadGraphStatus === 1) {
    handleAreaDownload();
  }

  console.log(
    "transition_rate_lead_time_array",
    transition_rate_lead_time_array
  );

  function handleGraphDataDownloadClick(e:any) {
    dispatch(
      updateDownloadCsvTarget("")
    )
  }

  // ==========================================================================================
  // コンポーネント
  return (
    <Container>
      <GlobalNav />

      <main className={classes.content} style={{ width: "100%" }}>
        <PageHeader page_title="ファネル" />

        <div className={classes.globalCard}>
          <div className={classes.nav}>
            <FunnelsSubMenu />
          </div>

          <FunnelsContent>
            <LoadingCover />
            <ContentCard>
              
              <CardTitleGraphDownloadWrapper  style={{ padding: "20px 20px 0" }}>
                <CardTitle>Funnel</CardTitle> 
                <div onClick={handleGraphDataDownloadClick}>
                  <GraphDataDownload />
                </div>
              </CardTitleGraphDownloadWrapper>

              <FunnelSummaryHeader
                funnel_summary_array={funnel_summary_array_revised}
              />

              {/* サマリー */}
              <CardBody>
                {/* 期間比較フィルターの期間選択パネル表示非表示切り替え */}
                {activatedFilter === "期間比較" && (
                  <SpanComparisonFilter page_is="funnelSummary" />
                )}

                {/* ========================================================================== */}
                {/* グラフ */}

                {/* フィルター非適用時 */}
                {activatedFilter === "" && (
                  <FunnelSummaryGraphDefault
                    areaRef={areaRef}
                    funnel_summary_array={funnel_summary_array_revised}
                    resultSpan={resultSpan}
                    moving_average_graph_switch={moving_average_graph_switch}
                  />
                )}

                {/* 期間比較フィルター適用時 */}
                {activatedFilter === "期間比較" && (
                  <FunnelSummaryGraphSpanFilter
                    spanComparisonData={spanComparisonData}
                  />
                )}

                {/* グラフここまで */}
                {/* ========================================================================== */}

                {/* ========================================================================== */}
                {/* データテーブル */}
                {/* 比較フィルター非適用時のみデータテーブル表示 */}
                {activatedFilter === "" &&
                  funnel_summary_array_revised.length > 0 && (
                    <DraftTable
                      dataType={"funnel"}
                      prefix="revenue"
                      funnelData={funnel_summary_array_revised}
                    />
                  )}
                {/* データテーブルここまで */}
                {/* ========================================================================== */}
              </CardBody>
            </ContentCard>

            {/* 移行率・リードタイム */}
            <ContentCard>
              <CardBody>
                {/* ========================================================================== */}
                {/* グラフ */}
                <FunnelSummaryGraphTransitionRateLeadTime
                  transition_rate_lead_time_array={
                    transition_rate_lead_time_array
                  }
                />

                {/* グラフここまで */}
                {/* ========================================================================== */}

                {/* ========================================================================== */}
                {/* データテーブル */}
                {/* 比較フィルター非適用時のみデータテーブル表示 */}
                {activatedFilter === "" &&
                  transition_rate_lead_time_array.length > 0 && (
                    <FunnelSummaryTransitionRateLeadTimeTable
                      transition_rate_lead_time_array={
                        transition_rate_lead_time_array
                      }
                    />
                  )}
                {/* データテーブルここまで */}
                {/* ========================================================================== */}
              </CardBody>
            </ContentCard>

            {/* Channel - 移行率の推移 */}
            <ContentCard>
              <CardBody>
                {/* ========================================================================== */}
                {/* グラフ */}
                <FunnelSummaryGraphLineGraph
                  channel_name_array={channel_array_lead_transition_with_color}
                  data_for_graph_line={data_for_graph_line}
                />

                {/* グラフここまで */}
                {/* ========================================================================== */}
              </CardBody>
            </ContentCard>

            {/* Channel - 件数の推移 */}
            <ContentCard>
              <CardBody>
                {/* ========================================================================== */}
                {/* グラフ */}
                <FunnelSummaryGraphStackedBarGraph
                  transition_rate_lead_time_array={
                    transition_rate_lead_time_array
                  }
                  channel_name_array={
                    channel_array_lead_new_count_total_with_color
                  }
                  sum_lead_new_count_total_array_all={
                    sum_lead_new_count_total_array_all
                  }
                />

                {/* グラフここまで */}
                {/* ========================================================================== */}
              </CardBody>
            </ContentCard>

            {/* Channel - 内訳 */}
            <ContentCard>
              <CardBody>
                {/* ========================================================================== */}
                {/* グラフ */}
                <FunnelSummaryGraphPiChart
                  transition_rate_lead_time_array={
                    transition_rate_lead_time_array
                  }
                  sum_lead_new_count_total_array_lead_array={
                    sum_lead_new_count_total_array_lead_array
                  }
                  sum_lead_new_count_total_array_appointment_array={
                    sum_lead_new_count_total_array_appointment_array
                  }
                  sum_lead_new_count_total_array_opportunity_array={
                    sum_lead_new_count_total_array_opportunity_array
                  }
                  sum_lead_new_count_total_array_contract_array={
                    sum_lead_new_count_total_array_contract_array
                  }
                  graph_color_array={graph_color_array}
                />

                {/* グラフここまで */}
                {/* ========================================================================== */}
              </CardBody>
            </ContentCard>

            {/* Channel - ファネルごとの推移 */}
            <ContentCard>
              <CardBody>
                {/* ========================================================================== */}
                {/* グラフ */}
                <FunnelSummaryGraphStackedBarGraphByFunnel
                  transition_rate_lead_time_array={
                    transition_rate_lead_time_array
                  }
                  lead_new_count_lead_with_time={lead_new_count_lead_with_time}
                  lead_new_count_appointment_with_time={
                    lead_new_count_appointment_with_time
                  }
                  lead_new_count_opportunity_with_time={
                    lead_new_count_opportunity_with_time
                  }
                  lead_new_count_contract_with_time={
                    lead_new_count_contract_with_time
                  }
                  channel_name_array={
                    channel_array_lead_new_count_total_with_color
                  }
                />

                {/* グラフここまで */}
                {/* ========================================================================== */}
              </CardBody>
            </ContentCard>
          </FunnelsContent>
        </div>
      </main>
    </Container>
  );
};

export default FunnelSummary;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      backgroundColor: "#F4F4F4"
    },
    globalCard: {
      display: "flex",
      justifyContent: "space-between",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    },
    nav: {
      width: 140
    },
    cell: {
      fontWeight: "normal"
    }
  })
);

const CardTitleGraphDownloadWrapper = styled.div({
  flexGrow: 1,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

// Container
const Container = styled.div`
  display: flex;
`;
// FunnelsContent
const FunnelsContent = styled.div`
  width: calc(100% - 140px);
  height: 100%;
  padding: 15px 17px;
  box-sizing: border-box;
`;
// ContentCard
const ContentCard = styled.div`
  margin-bottom: 16px;
  border: 1px solid #e1e8ea;
  box-sizing: border-box;
  background-color: #fff;
`;
// CardBody
const CardBody = styled.div`
  padding: 15px 17px;
  box-sizing: border-box;
  position: relative;
`;

// CardTitle
const CardTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #2a3385;
  margin: 0 0 24px 0;
`;
// DateSelect
const DateSelect = styled.div`
  position: absolute;
  top: 10px;
  right: 15px;
`;
