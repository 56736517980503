// resultデータから重要指標データの配列へ変換
function resultToMetrics(raw_results, target_data_name) {
  console.log('target_data_name', target_data_name);
  const result_at = 'result_at'
  const key_total = target_data_name + "_total"
  const key_objective = target_data_name + "_objective"
  const key_rate = target_data_name + "_rate"
  const key_difference = target_data_name + "_difference"
  const key_moving_average = target_data_name + "_moving_average_total"
  const key_moving_average_objective = target_data_name + "_moving_average_objective"
  const key_moving_average_rate = target_data_name + "_moving_average_rate"
  const key_moving_average_difference = target_data_name + "_moving_average_difference"
  const key_last_year_total = target_data_name + "_last_year_total"
  const key_last_year_objective = target_data_name + "_last_year_objective"
  const key_last_year_difference = target_data_name + "_last_year_difference"
  const key_last_year_rate = target_data_name + "_last_year_rate"
  const key_last_year_comparison_total = target_data_name + "_last_year_comparison_total"
  const key_last_year_comparison_objective = target_data_name + "_last_year_comparison_objective"
  const key_last_year_comparison_rate = target_data_name + "_last_year_comparison_rate"

  return raw_results
          .map((result) => {
            return {
              ...result[target_data_name],
              result_at: result.result_at,
              doc_id: result.doc_id
            }
          })
          .map((r) => {
            return {
              second_result_at:r[result_at],
              second_key_total: r[key_total],
              second_key_objective: r[key_objective],
              second_key_difference: r[key_difference],
              second_key_rate: r[key_rate],
              second_key_last_year_total: r[key_last_year_total],
              second_key_last_year_objective: r[key_last_year_objective],
              second_key_moving_average: r[key_moving_average],
              second_key_moving_average_objective: r[key_moving_average_objective],
              second_key_moving_average_difference: r[key_moving_average_difference],
              second_key_moving_average_rate: r[key_moving_average_rate],
              second_key_last_year_comparison_total: r[key_last_year_comparison_total],
              second_key_last_year_comparison_objective: r[key_last_year_comparison_objective],
              second_key_last_year_comparison_rate: r[key_last_year_comparison_rate],
            }
          });
}



// resultデータから重要指標データの配列へ変換
function resultToForecasts(raw_results, target_data_name) {
  console.log('target_data_name', target_data_name);

  // forecastsに必要なデータをresultからぬきだす
  const graphDateList = raw_results.map(rd => {
    return {
      second_result_at: rd.result_at,
    };
  });
  console.log('graphDateList with second_result_at', graphDateList);

  const revenueList = raw_results
    .flatMap(rd => rd.revenue)
    .map(rd => {
      return {
        revenue_total: rd.revenue_total,
        revenue_objective: rd.revenue_objective,
        revenue_difference: rd.revenue_difference,
        revenue_moving_average: rd.revenue_moving_average_total,
        revenue_last_year_comparison_total: rd.revenue_last_year_comparison_total,
        revenue_last_year_comparison_rate: rd.revenue_last_year_comparison_rate,
      };
    });
  console.log('revenueList with second_result_at', revenueList);

  const operationProfitList = raw_results
    .flatMap(rd => rd.profit_operating_profit)
    .map(rd => {
      return {
        profit_operating_profit_total: rd.profit_operating_profit_total,
        profit_operating_profit_objective: rd.profit_operating_profit_objective,
        profit_operating_profit_difference: rd.profit_operating_profit_difference,
        profit_operating_profit_moving_average: rd.profit_operating_profit_moving_average_total,
        profit_operating_profit_last_year_comparison_total: rd.profit_operating_profit_last_year_comparison_total,
        profit_operating_profit_last_year_comparison_rate: rd.profit_operating_profit_last_year_comparison_rate,
      };
    });
  console.log('operationProfitList with second_result_at', operationProfitList);

  const grossProfitList = raw_results
    .flatMap(rd => rd.profit_gross_profit)
    .map(rd => {
      return {
        profit_gross_profit_total: rd.profit_gross_profit_total,
        profit_gross_profit_objective: rd.profit_gross_profit_objective,
        profit_gross_profit_difference: rd.profit_gross_profit_difference,
        profit_gross_profit_moving_average: rd.profit_gross_profit_moving_average_total,
        profit_gross_profit_last_year_comparison_total: rd.profit_gross_profit_last_year_comparison_total,
        profit_gross_profit_last_year_comparison_rate: rd.profit_gross_profit_last_year_comparison_rate,
      };
    });
    console.log('grossProfitList with second_result_at', grossProfitList);

  const svgCostList = raw_results
    .flatMap(rd => rd.cost_svg_cost)
    .map(rd => {
      return {
        cost_svg_cost_total: rd.cost_svg_cost_total,
        cost_svg_cost_objective: rd.cost_svg_cost_objective,
        cost_svg_cost_difference: rd.cost_svg_cost_difference,
        cost_svg_cost_moving_average: rd.cost_svg_cost_moving_average_total,
        cost_svg_cost_last_year_comparison_total: rd.cost_svg_cost_last_year_comparison_total,
        cost_svg_cost_last_year_comparison_rate: rd.cost_svg_cost_last_year_comparison_rate,
      };
    });
    console.log('svgCostList with second_result_at', svgCostList);

  const unitCostList = raw_results
    .flatMap(rd => rd.cost_unit_cost)
    .map(rd => {
      return {
        cost_unit_cost_total: rd.cost_unit_cost_total,
        cost_unit_cost_objective: rd.cost_unit_cost_objective,
        cost_unit_cost_difference: rd.cost_unit_cost_difference,
        cost_unit_cost_moving_average: rd.cost_unit_cost_moving_average_total,
        cost_unit_cost_last_year_comparison_total: rd.cost_unit_cost_last_year_comparison_total,
        cost_unit_cost_last_year_comparison_rate: rd.cost_unit_cost_last_year_comparison_rate,
      };
    });

    console.log('unitCostList with second_result_at', unitCostList);

  const plSummaryDataList = graphDateList.map((data, index) => {
    return { 
      ...data,
      ...revenueList[index], 
      ...operationProfitList[index],
      ...grossProfitList[index],
      ...unitCostList[index],
      ...svgCostList[index]};
  });
  console.log('plSummaryDataList with second_result_at', plSummaryDataList);


  const forecasts = plSummaryDataList.map((data, index) => {
    return {
      second_forecasts_revenue: data.revenue_total,
      second_forecasts_revenue_objective: data.revenue_objective,
      second_forecasts_revenue_difference: data.revenue_difference,
      second_forecasts_revenue_moving_average: data.revenue_moving_average,
      second_forecasts_revenue_last_year_comparison_total: data.revenue_last_year_comparison_total,
      second_forecasts_revenue_last_year_comparison_rate: data.revenue_last_year_comparison_rate,
      second_forecasts_profit: data.profit_operating_profit_total,
      second_forecasts_profit_objective: data.profit_operating_profit_objective,
      second_forecasts_profit_difference: data.profit_operating_profit_difference,
      second_forecasts_profit_moving_average: data.profit_operating_profit_moving_average,
      second_forecasts_profit_last_year_comparison_total: data.profit_operating_profit_last_year_comparison_total,
      second_forecasts_profit_last_year_comparison_rate: data.profit_operating_profit_last_year_comparison_rate,
      second_forecasts_gross_profit: data.profit_gross_profit_total,
      second_forecasts_gross_profit_objective: data.profit_gross_profit_objective,
      second_forecasts_gross_profit_difference: data.profit_gross_profit_difference,
      second_forecasts_gross_profit_moving_average: data.profit_gross_profit_moving_average,
      second_forecasts_gross_profit_last_year_comparison_total: data.profit_gross_profit_last_year_comparison_total,
      second_forecasts_gross_profit_last_year_comparison_rate: data.profit_gross_profit_last_year_comparison_rate,
      second_forecasts_unit_cost: data.cost_unit_cost_total,
      second_forecasts_unit_cost_objective: data.cost_unit_cost_objective,
      second_forecasts_unit_cost_difference: data.cost_unit_cost_difference,
      second_forecasts_unit_cost_moving_average: data.cost_unit_cost_moving_average,
      second_forecasts_unit_cost_last_year_comparison_total: data.cost_unit_cost_last_year_comparison_total,
      second_forecasts_unit_cost_last_year_comparison_rate: data.cost_unit_cost_last_year_comparison_rate,
      second_forecasts_svg_cost: data.cost_svg_cost_total,
      second_forecasts_svg_cost_objective: data.cost_svg_cost_objective,
      second_forecasts_svg_cost_difference: data.cost_svg_cost_difference,
      second_forecasts_svg_cost_moving_average: data.cost_svg_cost_moving_average,
      second_forecasts_svg_cost_last_year_comparison_total: data.cost_svg_cost_last_year_comparison_total,
      second_forecasts_svg_cost_last_year_comparison_rate: data.cost_svg_cost_last_year_comparison_rate,
    };
  });
  console.log('forecasts with second_result_at', forecasts);


  const graphForecastsList = plSummaryDataList.map((data, index) => {
    return { ...data, ...forecasts[index] };
  });

  console.log('graphForecastsList secondspan======', graphForecastsList);

  return graphForecastsList

}


function getTargetDataForSecondSpan(raw_data_array, pathname) {
  console.log('action.raw_data_array in second span', raw_data_array);
  let target_data_name;
  switch(pathname) {
    case "/metrics/revenue":
      target_data_name = "revenue";
      break;
    case "/metrics/mrr":
      target_data_name = "mrr_total_value";
      break;
    case "/metrics/arpu":
      target_data_name = "arpu";
      break;
    case "/metrics/unit-economics":
      target_data_name = "unit_economics";
      break;
    case "/metrics/payback-period":
      target_data_name = "payback_period";
      break;
    case "/metrics/churn-rate":
      target_data_name = "churn_rate";
      break;
    case "/metrics/mrr-churn-rate":
      target_data_name = "mrr_churn_rate";
      break;
    case "/metrics/cac":
      target_data_name = "cac";
      break;
    case "/metrics/ltv":
      target_data_name = "ltv";
      break;
    case "/metrics/conversion_rate":
      target_data_name = "conversion_rate";
      break;
    case "/metrics/nrr":
      target_data_name = "nrr";
      break;
    case "/metrics/quick-ratio":
      target_data_name = "quick_ratio";
      break;
    case "/metrics/acv-cumulative":
      target_data_name = "acv_cumulative";
      break;
    case "/metrics/acv-new":
      target_data_name = "acv_new";
      break;
    case "/metrics/cpa":
      target_data_name = "cpa";
      break;
    case "/metrics/client-churned":
      target_data_name = "client_churned";
      break;
    case "/metrics/account-churnd":
      target_data_name = "account_churnd";
      break;
    case "/metrics/revenue-churnd":
      target_data_name = "revenue_churnd";
      break;
    case "/metrics/new-customer":
      target_data_name = "new_customer";
      break;
    case "/metrics/active-customer":
      target_data_name = "active_customer";
      break;
    case "/forecasts":
      target_data_name = "forecasts";
      break;
    default:
      target_data_name = "revenue";
  }
  console.log('target_data_name in second span', target_data_name);
  let target_data;
  console.log('target_data in second span before set', target_data);
  switch(target_data_name) {
    case "forecasts":
      target_data = resultToForecasts(raw_data_array, target_data_name)
      break;
    default:
      target_data = resultToMetrics(raw_data_array, target_data_name)
  }
  console.log('target_data in second span after set', target_data);

  try {
    return target_data;
  } catch (e) {
    console.log("error message", e.message);
  }
}

export default getTargetDataForSecondSpan;
