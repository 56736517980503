import { all, call, put, takeLatest } from "redux-saga/effects";
import { HIDE_LOADING } from "../reducks/loading/types";
import {
  SET_DATA_KEYS,
  START_TARGET_DATA_SEQUENCE,
  SET_TARGET_DATA_SUCCEEDED,
  SET_TARGET_DATA_FAILED,
  GET_TARGET_DATA_FOR_SPAN_COMPARISON_REQUEST,
  GET_TARGET_DATA_FOR_SPAN_COMPARISON_SUCCEEDED,
  GET_TARGET_DATA_FOR_SPAN_COMPARISON_FAILED
} from "../reducks/targetData/types";
import getRawDataForSpanComparison from "./targetDataSagas/getRawDataForSpanComparison";
import getTargetDataForFirstSpan from "./targetDataSagas/getTargetDataForFirstSpan";
import getTargetDataForSecondSpan from "./targetDataSagas/getTargetDataForSecondSpan";

// resultデータから重要指標データの配列へ変換
function resultToMetrics(raw_results: any, target_data_name: string) {
  console.log("target_data_name", target_data_name);
  return raw_results.map((result: any) => {
    return {
      ...result[target_data_name],
      result_at: result.result_at,
      doc_id: result.doc_id
    };
  });
}

// resultデータからMRRデータの配列へ変換
function resultToMrr(raw_results: any, target_data_name: string) {
  return raw_results.map((result: any) => {
    return {
      ...result.mrr_total_value,
      ...result.mrr_churn_value,
      ...result.mrr_contraction_value,
      ...result.mrr_expansion_value,
      ...result.mrr_existing_value,
      ...result.mrr_new_value,
      ...result.mrr_crossselling_value,
      ...result.mrr_upselling_value,
      result_at: result.result_at,
      doc_id: result.doc_id
    };
  });
}

function resultToNrr(raw_results: any, target_data_name: string) {
  return raw_results.map((result: any) => {
    return {
      ...result[target_data_name],
      ...result.mrr_churn_value,
      ...result.mrr_contraction_value,
      ...result.mrr_expansion_value,
      ...result.mrr_existing_value,
      ...result.mrr_crossselling_value,
      ...result.mrr_upselling_value,
      result_at: result.result_at,
      doc_id: result.doc_id
    };
  });
}

function resultToGrr(raw_results: any, target_data_name: string) {
  return raw_results.map((result: any) => {
    return {
      ...result[target_data_name],
      ...result.mrr_churn_value,
      ...result.mrr_contraction_value,
      ...result.mrr_existing_value,
      result_at: result.result_at,
      doc_id: result.doc_id
    };
  });
}

function* setTargetDataSequence(action: any) {
  let target_data_name: string;
  console.log("action.location", action.location);
  switch (action.location) {
    case "/metrics/revenue":
      target_data_name = "revenue";
      break;
    case "/metrics/mrr":
      target_data_name = "mrr_total_value";
      break;
    case "/metrics/arpu":
      target_data_name = "arpu";
      break;
    case "/metrics/unit-economics":
      target_data_name = "unit_economics";
      break;
    case "/metrics/payback-period":
      target_data_name = "payback_period";
      break;
    case "/metrics/churn-rate":
      target_data_name = "churn_rate";
      break;
    case "/metrics/mrr-churn-rate":
      target_data_name = "mrr_churn_rate";
      break;
    case "/metrics/cac":
      target_data_name = "cac";
      break;
    case "/metrics/ltv":
      target_data_name = "ltv";
      break;
    case "/metrics/conversion_rate":
      target_data_name = "conversion_rate";
      break;
    case "/metrics/nrr":
      target_data_name = "nrr";
      break;
    case "/metrics/grr":
      target_data_name = "grr";
      break;
    case "/metrics/quick-ratio":
      target_data_name = "quick_ratio";
      break;
    case "/metrics/acv-cumulative":
      target_data_name = "acv_cumulative";
      break;
    case "/metrics/acv-new":
      target_data_name = "acv_new";
      break;
    case "/metrics/cpa":
      target_data_name = "cpa";
      break;
    case "/funnels/client-churned":
      target_data_name = "client_churned";
      break;
    case "/funnels/account-churned":
      target_data_name = "account_churned";
      break;
    case "/funnels/revenue-churned":
      target_data_name = "revenue_churned";
      break;
    case "/funnels/new-customer":
      target_data_name = "new_customer";
      break;
    case "/funnels/active-customer":
      target_data_name = "active_customer";
      break;
    case "/forecasts":
      target_data_name = "revenue";
      break;
    default:
      target_data_name = "revenue";
  }

  let target_data: any;
  switch (target_data_name) {
    case "mrr_total_value":
      target_data = resultToMrr(action.results, target_data_name);
      break;
    case "nrr":
      target_data = resultToNrr(action.results, target_data_name);
      break;
    case "grr":
      target_data = resultToGrr(action.results, target_data_name);
      break;
    default:
      target_data = resultToMetrics(action.results, target_data_name);
  }

  try {
    yield all([
      put({
        type: SET_DATA_KEYS,
        location: action.location
      }),
      put({
        type: SET_TARGET_DATA_SUCCEEDED,
        target_data: target_data,
        resultSpan: action.resultSpan
      })
    ]);
  } catch (e) {
    yield all([
      put({
        type: HIDE_LOADING
      }),
      put({
        type: SET_TARGET_DATA_FAILED,
        message: e.message
      })
    ]);
  }
}

function* getTargetDataForSpanComparison(action: any) {
  try {
    console.log("action.first_span_start", new Date(action.first_span_start));
    console.log("action.first_span_end", new Date(action.first_span_end));
    console.log("action.second_span_start", new Date(action.second_span_start));
    console.log("action.second_span_end", new Date(action.second_span_end));
    const raw_data_first_span = yield* getRawDataForSpanComparison(
      action.company_id,
      action.selected_data_config_data_type,
      action.first_span_start,
      action.first_span_end
    );
    const raw_data_second_span = yield* getRawDataForSpanComparison(
      action.company_id,
      action.selected_data_config_data_type,
      action.second_span_start,
      action.second_span_end
    );
    console.log("raw_data_first_span", raw_data_first_span);
    console.log("raw_data_second_span", raw_data_second_span);

    // targetData側へraw_dataを送る
    const target_data_first_span = getTargetDataForFirstSpan(
      raw_data_first_span,
      action.pathname
    );
    const target_data_second_span = getTargetDataForSecondSpan(
      raw_data_second_span,
      action.pathname
    );
    console.log("target_data_first_span", target_data_first_span);
    console.log("target_data_second_span", target_data_second_span);
    const target_data_span_comparison = target_data_first_span.map(
      (data: any, index: number) => {
        return { ...data, ...target_data_second_span[index] };
      }
    );

    yield all([
      put({
        type: GET_TARGET_DATA_FOR_SPAN_COMPARISON_SUCCEEDED,
        first_span_start: action.first_span_start,
        first_span_end: action.first_span_end,
        second_span_start: action.second_span_start,
        second_span_end: action.second_span_end,
        target_data_first_span: target_data_first_span,
        target_data_second_span: target_data_second_span,
        target_data_span_comparison: target_data_span_comparison
      })
    ]);
  } catch (e) {
    yield all([
      put({
        type: HIDE_LOADING
      }),
      put({
        type: GET_TARGET_DATA_FOR_SPAN_COMPARISON_FAILED,
        message: e.message
      })
    ]);
  }
}

const targetDataSagas = [
  takeLatest(START_TARGET_DATA_SEQUENCE, setTargetDataSequence),
  takeLatest(
    GET_TARGET_DATA_FOR_SPAN_COMPARISON_REQUEST,
    getTargetDataForSpanComparison
  )
];

export default targetDataSagas;
