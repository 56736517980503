import rsf from "../../rsf";
import { call } from "redux-saga/effects";
import firebase from "firebase";

const db = firebase.firestore();

// 昨月、当月、翌月の３ヶ月分データ取得
function* getRawDataThisMonth(company_id, selected_data_config_data_type) {
  try {
    // 当月
    const thisMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const month = thisMonth.getMonth();

    // 昨月
    const lastMonth = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 1));

    // 翌月
    const nextMonth = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month + 1));

    // 先々月
    const monthBeforeLast = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 2));

    if (selected_data_config_data_type === "monthly") {
      // データ取得
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${company_id}/result_monthly`)
          .where("result_at", ">=", monthBeforeLast)
          .where("result_at", "<=", thisMonth)
        );

      // 取得後データを整形
      let raw_data_array = [];
      snapshot.forEach(result_monthly => {
        raw_data_array = [...raw_data_array, { ...result_monthly.data(), docId: result_monthly.id }];
      });

      return raw_data_array;
    } else if (selected_data_config_data_type === "weekly") {
      // データ取得
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${company_id}/result_weekly`)
          .where("result_at", ">=", monthBeforeLast)
          .where("result_at", "<=", thisMonth)
        );

      // 取得後データを整形
      let raw_data_array = [];
      snapshot.forEach(result_monthly => {
        raw_data_array = [...raw_data_array, { ...result_monthly.data(), docId: result_monthly.id }];
      });

      return raw_data_array;
    } else {
      // データ取得
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${company_id}/result_daily`)
        .where("result_at", ">=", monthBeforeLast)
        .where("result_at", "<=", thisMonth)
      );

      // 取得後データを整形
      let raw_data_array = [];
      snapshot.forEach(result_monthly => {
        raw_data_array = [...raw_data_array, { ...result_monthly.data(), docId: result_monthly.id }];
      });

      return raw_data_array;
    }
  } catch (e) {
    console.log("error message", e.message);
  }
}


export default getRawDataThisMonth;