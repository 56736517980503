// import update from "immutability-helper";
import { GET_INQUIRIES_SUCCEEDED, GET_INQUIRIES_FAILED } from "./types"
import {RESET_ALL} from "../common/types";

const initialState: any = [];

const inquiryReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_ALL:
      return initialState;

    case GET_INQUIRIES_SUCCEEDED:
      // return state;
      console.log('GET_INQUIRIES_SUCCEEDED', action.result)
      return [...state, ...action.result]

    case GET_INQUIRIES_FAILED:
      console.log('error', action.message);
      return [...state]

    default:
      return state
  }
}

export default inquiryReducer
