
import {RESET_ALL} from "../common/types";

const initialState = {
  doc_id: '',
  billing_account_id: '',
  company_id: '',
  email: '',
  name: '',
  salesforce_api_key: '',
  salesforce_secret_key: '',
  error: '',
  globalNavOpened : false,
};

const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_ALL:
      return initialState;

    case "USER_FETCH_SUCCEEDED":
      return {
        ...state,
        ...action.user
      };
    case "USER_FETCH_FAILED":
      return {
        ...state,
        message: action.message
      };
    case "CHANGE_GROBAL_NAV_OPENED":
      return {
        ...state,
        globalNavOpened : action.payload.opened
      }
    default:
      return state;
  }
};

export default userReducer;
