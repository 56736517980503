import { call } from "redux-saga/effects"
import authApiPost from './authApiPost'

function* fetchAccessToken(salesforce_api_key, salesforce_secret_key) {
  try {
    const response = yield call(authApiPost(salesforce_api_key, salesforce_secret_key))
    return response.data()
  } catch(e) {
    console.log('error', e.message);
    return e.message
  }

}

export default fetchAccessToken;