// 引数：過去のrevenueの配列。個数は左上、12ヶ月、24ヶ月、36ヶ月、カスタム期間のいずれか
const getFutureRevenueDetail = (
  revenue_detail_results_past: any,
  forecast_base_span: number // 現在は過去5ヶ月分データのみのため、5で指定
) => {
  // const forecast_base_span = 5;
  console.log('forecast_base_span', forecast_base_span);

  const flat_revenue_detail_map = revenue_detail_results_past.flatMap((result: any) => result.list); // detail部分のみ抜き出し


  const revenue_detail_results_forForecast = revenue_detail_results_past.map((r: any) => r.list); // 名前リストをつくるためdetail部分のみ抜き出し

  // 商品名リスト作成
  const product_name_list_forForecast = revenue_detail_results_forForecast[0].map((item: any) => {
    return {
      product_name: item.name
    };
  });

  // 商品名ごとの売上データ配列を作成
  const revenue_array_by_name = product_name_list_forForecast.map((frd: any) => {
    return flat_revenue_detail_map.filter((r: any) => frd.product_name === r.name)
  })

  const product_name_and_revenue_total_sum = revenue_array_by_name.map((rev_array: any) => {
    return {
      product_name: rev_array[0].name,
      growth_rate:
        rev_array
          .map((item: any, index: number) => {
            if (index === 0) {
              // 前の月と比べるので最初の月はなし。
              return {
                revenue_total: 0,
              };
            } else {
              if (
                item.revenue_total === 0 &&
                rev_array[index - 1].revenue_total !== 0
              ) {
                // 当月の値に0が入っている場合
                return {
                  revenue_total:
                    rev_array[index - 1].revenue_total /
                    rev_array[index - 1].revenue_total, // 0でないほうの値を入れる
                };
              } else if (
                item.revenue_total !== 0 &&
                rev_array[index - 1].revenue_total === 0
              ) {
                // 昨月の値に0が入っている場合
                return {
                  revenue_total: item.revenue_total / item.revenue_total, // 0でないほうの値を入れる
                };
              } else if (
                item.revenue_total === 0 &&
                rev_array[index - 1].revenue_total === 0
              ) {
                // 当月の値と昨月の値両方に0が入っている場合
                return {
                  revenue_total: 1, // 100%成長（成長なし）
                };
              } else {
                return {
                  // 0でない値が入っている場合（想定どおりの場合）
                  revenue_total:
                    item.revenue_total / rev_array[index - 1].revenue_total,
                };
              }
            }
          })
          // .slice(1, rev_array.length - 1) //最初の月は除外する
          .reduce((accumulator: number, currentValue: any) => accumulator + currentValue.revenue_total, 0) /(forecast_base_span - 1),
      revenue_total_sum: rev_array.reduce((acc: any, cur: any) => acc + cur.revenue_total,0),
    };
  })

  // 将来データのもとを作成
  const futureArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  // 将来データの配列を作成
  const future_revenue_detail = futureArray.map((number: number) => {
    const thisMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1); //当月

    return {
      revenue_detail: revenue_detail_results_past[revenue_detail_results_past.length - 1].list.map((detail_data: any) => {
        const target_rev_total = product_name_and_revenue_total_sum.find((s: any) => detail_data.name === s.product_name);
        return {
          ...detail_data,
          revenue_total: detail_data.revenue_total * target_rev_total.growth_rate ** number
        }
      }),
    }
  })

  return future_revenue_detail

};

export default getFutureRevenueDetail;
