import axios from "axios";

const submitToServer = async (formData: FormData, endpoint: string) => {
  const result = await axios.post(
    process.env.REACT_APP_UPLOAD_CSV_ENDPOINT + `${endpoint}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
        // 'Authorization': `Bearer ${token}`
      }
    }
  );
  return result;
};

/**
 * 
 * @param formData
 * @param endpoint
 * @param token
 * @returns
 */
export const requestJsonToApi = async (formData: any, endpoint: string, token: string) => {
  const result = await axios.post(
    process.env.REACT_APP_API_HOST + `${endpoint}`,
    formData,
    {
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      }
    }
  );
  return result;
};

export default submitToServer;