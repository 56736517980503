import React from "react";
import { unitSwitcherForSaleCost } from "../../Util/unitSwitcher";
import styled from "styled-components";
import open_arrow from "../../../images/icons/arrow_down.svg";
import close_arrow from "../../../images/icons/arrow_up.svg";

function ForecastSaleCostTableDefault(props: any) {

  return (
    <Wrapper>
      <DataTableSC id="table_width">
        <DataHeader>
          <p style={{ width: "150px" }}>&nbsp;</p>
            {
              // dailyかweeklyだったら一日単位で表示
              props.resultSpan !== "monthly" &&
              props.raw_data_array &&
              props.raw_data_array.length > 0 &&
              props.raw_data_array.map((result: any) => (
                  <p>
                    {result.result_at.seconds == null ? "データ不足" : `${new Date(result.result_at.seconds * 1000).getFullYear()}`}/{`${new Date(result.result_at.seconds * 1000).getMonth() + 1}`}/{`${new Date(result.result_at.seconds * 1000).getDate()}`}
                  </p>
                ))
            }
            {
              // monthlyだったら月まで表示
              props.resultSpan === "monthly" &&
              props.raw_data_array &&
              props.raw_data_array.length > 0 &&
              props.raw_data_array.map((result: any) => (
                  <p>
                    {result.result_at.seconds == null ? "データ不足" : `${new Date(result.result_at.seconds * 1000).getFullYear()}`}/{`${new Date(result.result_at.seconds * 1000).getMonth() + 1}`}
                  </p>
                ))
            }
        </DataHeader>
        <DataBody style={{ display: "flex" }}>
          <DataTitleSC>
            <TitleItemSC>
                <SummaryListSC>
                  <SummaryItemSC className="label customTableBody-sm">Sales & Marketing比率</SummaryItemSC>
                  <SummaryItemSC className="label customTableBody-rd">Research & Development比率</SummaryItemSC>
                  <SummaryItemSC className="label customTableBody-ga">General & Administrative比率</SummaryItemSC>
                </SummaryListSC>
            </TitleItemSC>
          </DataTitleSC>

          <DataContents style={{ display: "flex" }}>
            {props.raw_data_array.map((result: any) => {
              return (
                <DataContent style={{ height: "auto" }} className="DataContent">
                  <InnerList>
                      <SummaryListSC>
                        <SummaryItemSC>
                          {result && unitSwitcherForSaleCost(result.cost_sales_marketing_cost.cost_sales_marketing_cost_total, 'sale_cost_total')} ({result && unitSwitcherForSaleCost(result.cost_sales_marketing_cost.cost_sales_marketing_cost_revenue_rate, 'rate')})
                        </SummaryItemSC>
                        <SummaryItemSC>
                          {result && unitSwitcherForSaleCost(result.cost_research_development_cost.cost_research_development_cost_total, 'sale_cost_total')} ({result && unitSwitcherForSaleCost(result.cost_research_development_cost.cost_research_development_cost_revenue_rate, 'rate')})
                        </SummaryItemSC>
                        <SummaryItemSC>
                          {result && unitSwitcherForSaleCost(result.cost_general_administrative_cost.cost_general_administrative_cost_total, 'sale_cost_total')} ({result && unitSwitcherForSaleCost(result.cost_general_administrative_cost.cost_general_administrative_cost_revenue_rate, 'rate')})
                        </SummaryItemSC>
                      </SummaryListSC>
                  </InnerList>
                </DataContent>
              );
            })}
          </DataContents>
        </DataBody>
      </DataTableSC>
    </Wrapper>
  );
}

export default ForecastSaleCostTableDefault;

// DataTable
const DataTableSC = styled.div`
  margin-bottom: 27px;
  border-bottom: 1px solid #e2e9f3;
  width: max-content;
  min-width: 100%;
`;
// DataHeader
const DataHeader = styled.div`
  width: 100%;
  box-sizing: border-box;
  color: #435878;
  display: flex;
  font-size: 10px;
  line-height: 15px;

  p {
    min-width: 120px;
    width: 100%;
    text-align: center;
    padding: 5px 0;
    border-right: 1px solid #e2e9f3;
    background-color: #f3f6fa;
    color: #555;
    font-size: 10px;
    &:first-child {
      width: 150px;
      position: absolute;
      background-color: #f3f6fa;
      min-width: 208px;
      position: -webkit-sticky;
      position: sticky;
      left: 0px;
      z-index: 500;
      overflow: auto;
    }
    &:last-child {
      border-right: none;
    }
  }
`;
// DataBody
const DataBody = styled.div`
  width: 100%;
  align-items: flex-start;
`;
// DataTitle
const DataTitleSC = styled.ul`
  color: #435878;
  min-width: 208px;
  border-right: 1px solid #e2e9f3;
  background-color: #fff;
  position: sticky;
  left: 0px;
  z-index: 500;
  overflow: auto;
`;

const TitleItemSC = styled.li`
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  width: 100%;
  input {
    display: none;
  }
  h4 {
    height: 40px;
    padding: 12px 0 12px 20px;
    border-bottom: 1px solid #e2e9f3;
    &.opened {
      &::after {
        content: "";
        display: inline-block;
        height: 13px;
        width: 18px;
        position: absolute;
        right: 10px;
        background-image: url(${close_arrow});
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    &.closed {
      &::after {
        content: "";
        display: inline-block;
        height: 13px;
        width: 18px;
        position: absolute;
        right: 10px;
        background-image: url(${open_arrow});
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    label {
      width: 100%;
    }
  }
`;

const SummaryListSC = styled.ul`

`;

const SummaryItemSC = styled.li`
    height: 40px;
    font-size: 12px;
    font-weight: normal;
    line-height: 18px;
    border-bottom: 1px solid #e2e9f3;
    padding: 12px 10px 12px;
    width: 100%;
    text-align: right;
    &.label {
      text-align: left;
      padding: 12px 10px 12px 20px;
    }
    &.reached {
      color: #028cab;
    }
    &.unreached {
      color: #af002a;
    }
    &.equal {
      color: #35455d;
    }
    &::before {
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: block;
      position: absolute;
      left: 3px;
      transform: translateY(-50%);
    }
    &.customTableBody-sm::before {
      background-color: #E53E66;
      top: 21px;
    }
    &.customTableBody-rd::before {
      top: 61px;
      background-color: #F8B200;
    }
    &.customTableBody-ga::before {
      top: 101px;
      background-color: #B7D20D;
    }
  }
`;

// DataContents
const DataContents = styled.div`
  width: calc(100% - 150px);
`;
// DataContent
const DataContent = styled.ul`
  font-size: 12px;
  min-width: 200px;
  width: 100%;
  border-right: 1px solid #e2e9f3;
  &:last-child {
    border-right: none;
  }
  &.activeDotShown {
    background-color: rgba(0, 102, 255, 0.05);
  }
`;

const InnerList = styled.li`
`;

const Wrapper = styled.div({
  scrollbarWidth: "none",
	"&::-webkit-scrollbar": { display: "none" },
  "-ms-overflow-style": "none",
  width: "flex",
  overflow: "scroll",
  position: "relative"
});