import React, {useEffect,useState} from "react"
import { useSelector, useDispatch } from "react-redux"
import styled from "styled-components"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import GlobalNav from "../../Layout/GlobalNav"
import PageHeaderRaw from "../../Layout/PageHeader"
import ConfigNavCard from "../ConfigNavCard"
import { updateMovingAverageRequest, updateWeekStartDateRequest, updateClosingMonthRequested } from "../../../reducks/company/actions"
import selectArrow from "../../../images/icons/arrow_down.svg"
import {setForecastFilterStatus} from '../../../reducks/targetForecast/actions';
import { useLocation } from "react-router-dom";
import { requestJsonToApi } from "../../../dataUploadToServer";


const IndicatorSetting = () => {
  const location = useLocation();
	const pathname = location.pathname;
  // GA計測タグ
	useEffect(() => {
		document.title = '各種設定'; // ページタイトルを変更
		window.gtag('config', 'G-YXXD65BEJ0', {
			'page_path': pathname // 任意のパス（あるいはprops.location.pathnameなど）
		});
	});

  const classes = useStyles()
  const dispatch = useDispatch()

  const company_id = useSelector((state: any) => state.user.company_id)

  const config = useSelector((state: any) => state.company.config);

  const movingAverage = useSelector((state: any) => state.company.config.moving_average)

  const weekStartDate = useSelector((state: any) => state.company.config.weekly_start_day)

  const closedMonth = useSelector((state: any) => state.company.closing_month) 

  const [LTVMethod, setLTVMethod] = useState("includeGrossMargin")

  const handleChange = (e: React.FormEvent<HTMLSelectElement>) => {
    e.preventDefault()
    dispatch(updateMovingAverageRequest(e.currentTarget.value, config, company_id))
  }

  const accessToken = useSelector(
    (state: any) => state.session.access_token
  );

  const closingSettlementMonthDataChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const closingMonth = e.target.value
    const formData = {closing_month: closingMonth}
    await requestJsonToApi(formData, "/app/closing_month", accessToken);
    dispatch(updateClosingMonthRequested(closingMonth, company_id))
  }

  const handleLTVDataChange = (e: React.FormEvent<HTMLInputElement>) => {
    setLTVMethod(e.currentTarget.value)
  }

  const handleWeekStartDateChange = (e: React.FormEvent<HTMLSelectElement>) => {
    e.preventDefault()
    dispatch(updateWeekStartDateRequest(e.currentTarget.value, config, company_id))
  }

  useEffect(() => {
    dispatch(setForecastFilterStatus('reset_forecast'));
  },[])


  return (
    <Container>
      <GlobalNav />
      <main className={classes.content}>
        <PageHeaderRaw page_title="管理" />

        <div className={classes.configCard}>
          <ConfigNavCard />
          <IndicatorContent>
            <IndicatorContentBlock>
              <IndicatorHeading>移動平均設定</IndicatorHeading>

              {/* <FormControl variant="outlined" className={classes.formControl} onChange={handleChange}>
                <Select
                  native
                  value={movingAverage}
                  label="Age"
                  inputProps={{
                    name: "movingAverage",
                    id: "moving-average"
                  }}
                >
                  <option value={'3ヶ月'}>3か月</option>
                  <option value={'6ヶ月'}>6か月</option>
                  <option value={'9ヶ月'}>9か月</option>
                  <option value={'12ヶ月'}>12か月</option>
                </Select>
              </FormControl> */}
              <SpanSelect id="moving-average" name="movingAverage" onChange={handleChange} value={movingAverage}>
                <option value={"3ヶ月"}>3か月</option>
                {/* <option value={"6ヶ月"}>6か月</option>
                <option value={"9ヶ月"}>9か月</option>
                <option value={"12ヶ月"}>12か月</option> */}
              </SpanSelect>
            </IndicatorContentBlock>

            {/* <IndicatorContentBlock>
              <IndicatorHeading>週始まり設定</IndicatorHeading> */}

              {/* <FormControl variant="outlined" className={classes.formControl} onChange={handleChange}>
                <Select
                  native
                  value={movingAverage}
                  label="Age"
                  inputProps={{
                    name: "movingAverage",
                    id: "moving-average"
                  }}
                >
                  <option value={'3ヶ月'}>3ヶ月</option>
                  <option value={'6ヶ月'}>6ヶ月</option>
                  <option value={'9ヶ月'}>9ヶ月</option>
                  <option value={'12ヶ月'}>12ヶ月</option>
                </Select>
              </FormControl> */}
              {/* <SpanSelect id="week-start-date" name="weekStartDate" onChange={handleWeekStartDateChange} value={weekStartDate}>
                <option value={"Mon"}>月曜日</option>
                <option value={"Tue"}>火曜日</option>
                <option value={"Wed"}>水曜日</option>
                <option value={"Thu"}>木曜日</option>
                <option value={"Fri"}>金曜日</option>
                <option value={"Sat"}>土曜日</option>
                <option value={"Sun"}>日曜日</option>
              </SpanSelect>
            </IndicatorContentBlock> */}

           <IndicatorContentBlock>
              <IndicatorHeading>決算月</IndicatorHeading>
              <SpanSelect id="closing-month" name="closingMonth" onChange={closingSettlementMonthDataChange} value={closedMonth}>
                <option value={"01"}>1月</option>
                <option value={"02"}>2月</option>
                <option value={"03"}>3月</option>
                <option value={"04"}>4月</option>
                <option value={"05"}>5月</option>
                <option value={"06"}>6月</option>
                <option value={"07"}>7月</option>
                <option value={"08"}>8月</option>
                <option value={"09"}>9月</option>
                <option value={"10"}>10月</option>
                <option value={"11"}>11月</option>
                <option value={"12"}>12月</option>
              </SpanSelect>
            </IndicatorContentBlock>

            <IndicatorContentBlock>
              <IndicatorHeading>LTVの計算方式</IndicatorHeading>
                <RadioButtonLabel>
                  <RadioButtonInput
                    type="radio"
                    id="include-gross-margin"
                    name= "includeGrossMargin"
                    value="includeGrossMargin"
                    checked={LTVMethod === 'includeGrossMargin'}
                    onChange={handleLTVDataChange}
                    />
                    計算に粗利率を含める
                 </RadioButtonLabel>

                {/* <RadioButtonLabel>
                  <RadioButtonInput
                    type="radio"
                    onChange={handleLTVDataChange}
                    id="do-not-include-gross-margin"
                    name= "doNotIncludeGrossMargin"
                    value="doNotIncludeGrossMargin"
                    checked={LTVMethod === 'doNotIncludeGrossMargin'}
                    />
                    計算に粗利率を含めない
                 </RadioButtonLabel> */}
            </IndicatorContentBlock>

          </IndicatorContent>
        </div>
      </main>
    </Container>
  )
}

export default IndicatorSetting

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      backgroundColor: "#F4F4F4",
    },
    configCard: {
      display: "flex",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  })
)

// Container
const Container = styled.div`
  display: flex;
`

// IndicatorContent
const IndicatorContent = styled.div`
  width: 100%;
  height: 100vh;
  padding: 33px 32px;
  box-sizing: border-box;
  background-color: #fff;
  select {
    padding: 14.5px 14px;
  }
  legend {
    display: none;
  }
  svg {
    path {
      fill: #ccc;
    }
  }
  fieldset {
    color: #283f46;
    border: 1px solid #e1e8ea;
    top: 0;
  }
`
// IndicatorHeading
const IndicatorHeading = styled.h3`
  font-size: 14px;
  font-weight: normal;
  line-height: 21px;
  color: #35455d;
  margin: 0 0 13px 0;
  font-weight: bold;
`

const RadioButtonLabel = styled.h3`
  font-size: 14px;
  font-weight: normal;
  color: #35455d;
  margin: 0 0px 10px 0;
  &:hover {
    opacity: 0.6;
  }
`

const RadioButtonInput = styled.input`
  margin: 0 8px 2px 0;

`

const IndicatorContentBlock = styled.div`
  margin-bottom: 30px;
`

//spanSelect
const SpanSelect = styled.select`
  width: 296px;
  height: 48px;
  border: 1px solid #e2e9f3;
  border-radius: 4px;
  font-size: 14px;
  line-height: 21px;
  padding: 13px 14px 2px 0;
  background-image: url(${selectArrow});
  background-repeat: no-repeat;
  background-position: center right 20px;
  background-size: 24px 24px;
  appearance: none;
  outline: none;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`