export const ADD_ITEM_INTO_GRID = "ADD_ITEM_INTO_GRID"
export const REMOVE_ITEM_FROM_GRID = "REMOVE_ITEM_FROM_GRID"
export const SAVE_LAYOUT = "SAVE_LAYOUT"
export const UPDATE_LAYOUT_REQUEST = "UPDATE_LAYOUT_REQUEST"
export const GET_LAYOUT_REQUESTED = "GET_LAYOUT_REQUESTED"

export const UPDATE_LAYOUT_SUCCEEDED = "UPDATE_LAYOUT_SUCCEEDED"
export const UPDATE_LAYOUT_FAILED = "UPDATE_LAYOUT_FAILED"
export const GET_LAYOUT_SUCCEEDED = "GET_LAYOUT_SUCCEEDED"
export const GET_LAYOUT_FAILED = "GET_LAYOUT_FAILED"

export const TOGGLE_GRAPH = "TOGGLE_GRAPH"
