import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector , useDispatch  } from "react-redux";
import styled from "styled-components";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import GlobalNav from "../Layout/GlobalNav";
import PageHeaderRaw from "../Layout/PageHeaderNoCarendar";
import UploadNavCard from "./UploadNavCard";
import { useLocation } from "react-router-dom";
import downArrow from "../../images/icons/downArrow.svg";
import axios from "axios";
import { NotificationManager } from "react-notifications";

import { updateSpreadsheetSync } from "../../reducks/company/actions"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      backgroundColor: "#F4F4F4"
    },
    configCard: {
      display: "flex",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    }
  })
);

let currentUploadPage: string;
let endpoint: string;

const UploadPage: React.FC = () => {
  const location = useLocation();
  const pathName = location.pathname;
  const classes = useStyles();
  const { reset } = useForm();
  const message = useSelector((state: any) => state.data.message);
  const [selectedFile, setSelectedFile] = useState("");
  const handlePathChange = () => {
    reset();
    setSelectedFile("");
  };

  const salesforce_api_key = useSelector(
    (state: any) => state.session.salesforce_api_key
  );
  const salesforce_secret_key = useSelector(
    (state: any) => state.session.salesforce_secret_key
  );
  const company_id = useSelector((state: any) => state.user.company_id)

  const dispatch = useDispatch()
  const importTime = useSelector((state: any) => state.company.config.google_spreadsheet_import_time)
  const isImportMaster = useSelector((state: any) => state.company.config.google_spreadsheet_import_master)
  const isImportActual = useSelector((state: any) => state.company.config.google_spreadsheet_import_actual)

  // GA計測タグ
  useEffect(() => {
    document.title = "同期設定"; // ページタイトルを変更
    window.gtag("config", "G-YXXD65BEJ0", {
      page_path: pathName // 任意のパス（あるいはprops.location.pathnameなど）
    });
  });

  const access_token = useSelector((state: any) => state.session.access_token);

  // 同期設定のON/OFF
  //マスター・目標データ
  const [spreadsheetSync, setSpreadsheetSync] = useState<boolean>(isImportMaster);
  //実績データ
  const [spreadsheetSyncResultsData, setSpreadsheetSyncResultsData] =
    useState<boolean>(isImportActual);

  // 同期設定時間
  const [spreadsheetSyncTime, setSpreadsheetSyncTime] =
    useState<string>(importTime);

  const handleSubmit = async () => {
    const result = await axios.put(
      process.env.REACT_APP_API_HOST + `/app/setting/google_spread_sheet`,
      {
        salesforce_api_key: salesforce_api_key,
        salesforce_api_secret: salesforce_secret_key,
        time: spreadsheetSyncTime,
        is_import_master: spreadsheetSync ? true : false,
        is_import_actual: spreadsheetSyncResultsData ? true : false
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`
        }
      }
    ).then(response => {
      if (response.data.status === 1) {
        NotificationManager.success("保存しました");
      } else if (response.data.status !== 1) {
        NotificationManager.error("保存に失敗しました");
      }
      dispatch(updateSpreadsheetSync(spreadsheetSyncTime, spreadsheetSync, spreadsheetSyncResultsData, company_id))

    })
    .catch(err => {
      NotificationManager.error("システムエラーが発生しました");
    });

    return result;
  };

  return (
    <Container>
      <GlobalNav />

      <main className={classes.content}>
        <PageHeaderRaw page_title="データ" />

        <div className={classes.configCard}>
          <UploadNavCard handlePathChange={handlePathChange} />

          <DataContent>
            <DataTitle>{currentUploadPage}</DataTitle>
            <ErrorMessage>{message}</ErrorMessage>

            <DataContentBody>
              <DataContentItem>
                <ContentHeading>Spreadsheetの同期設定</ContentHeading>
                <ContentDesc>
                  通知設定をONにした場合、実績データのみ児童で同期、取込します。マスターと目標データは各設定画面より「今すぐ更新」ボタンより更新してください。
                </ContentDesc>

                <ContentBody>
                  <SyncRadioLabel>マスター・目標データ</SyncRadioLabel>
                  <SyncRadioBtn onClick={() => setSpreadsheetSync(true)}>
                    <RadioIndicator
                      className={`indicator ${spreadsheetSync && "active"}`}
                    ></RadioIndicator>
                    <RadioLabel>ON</RadioLabel>
                  </SyncRadioBtn>

                  <SyncRadioBtn onClick={() => setSpreadsheetSync(false)}>
                    <RadioIndicator
                      className={`${!spreadsheetSync && "active"}`}
                    ></RadioIndicator>
                    <RadioLabel>OFF</RadioLabel>
                  </SyncRadioBtn>

                  <SyncRadioLabel>実績データ</SyncRadioLabel>
                  <SyncRadioBtn
                    onClick={() => setSpreadsheetSyncResultsData(true)}
                  >
                    <RadioIndicator
                      className={`indicator ${
                        spreadsheetSyncResultsData && "active"
                      }`}
                    ></RadioIndicator>
                    <RadioLabel>ON</RadioLabel>
                  </SyncRadioBtn>

                  <SyncRadioBtn
                    onClick={() => setSpreadsheetSyncResultsData(false)}
                  >
                    <RadioIndicator
                      className={`${!spreadsheetSyncResultsData && "active"}`}
                    ></RadioIndicator>
                    <RadioLabel>OFF</RadioLabel>
                  </SyncRadioBtn>
                </ContentBody>
              </DataContentItem>

              <DataContentItem>
                <ContentHeading>Spreadsheetの開始時間設定</ContentHeading>

                <ContentBody>
                  <SelectWrapper>
                    <Select
                      value={spreadsheetSyncTime}
                      onChange={e => setSpreadsheetSyncTime(e.target.value)}
                    >
                      <option value="00:00">AM 00:00</option>
                      <option value="01:00">AM 01:00</option>
                      <option value="02:00">AM 02:00</option>
                      <option value="03:00">AM 03:00</option>
                      <option value="04:00">AM 04:00</option>
                      <option value="05:00">AM 05:00</option>
                      <option value="06:00">AM 06:00</option>
                      <option value="07:00">AM 07:00</option>
                      <option value="08:00">AM 08:00</option>
                      <option value="09:00">AM 09:00</option>
                      <option value="10:00">AM 10:00</option>
                      <option value="11:00">AM 11:00</option>
                      <option value="12:00">AM 12:00</option>
                      <option value="13:00">PM 01:00</option>
                      <option value="14:00">PM 02:00</option>
                      <option value="15:00">PM 03:00</option>
                      <option value="16:00">PM 04:00</option>
                      <option value="17:00">PM 05:00</option>
                      <option value="18:00">PM 06:00</option>
                      <option value="19:00">PM 07:00</option>
                      <option value="20:00">PM 08:00</option>
                      <option value="21:00">PM 09:00</option>
                      <option value="22:00">PM 10:00</option>
                      <option value="23:00">PM 11:00</option>
                    </Select>
                  </SelectWrapper>
                </ContentBody>
              </DataContentItem>

              <DataContentItem>
                <ContentBody>
                  <SubmitBtn onClick={handleSubmit}>保存する</SubmitBtn>
                </ContentBody>
              </DataContentItem>
            </DataContentBody>
          </DataContent>
        </div>
      </main>
    </Container>
  );
};

export default UploadPage;

const ErrorMessage = styled.p`
  margin-bottom: 10px;
  color: red;
`;

// Container
const Container = styled.div`
  display: flex;
`;

// DataContent
const DataContent = styled.div`
  width: 100%;
  height: auto;
  padding: 33px 40px;
  box-sizing: border-box;
  background-color: #fff;
`;

// DataTitle
const DataTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  line-height: 27px;
  margin: 0 0 28px 0;
`;

// Button
const Button = styled.button`
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
  color: #fff;
  text-align: center;
  width: 152px;
  height: 36px;
  padding: 8px 10px 10px 10px;
  display: inline-block;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  appearance: none;
  outline: none;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
  &.send {
    background-color: #0066ff;
  }
  &:hover {
    opacity: 0.6;
  }
`;

const ErrorText = styled.p`
  font-size: 12px;
  color: #af002a;
  top: 50px;
`;

const DataContentBody = styled.div``;

const DataContentItem = styled.div`
  margin-top: 28px;
`;

const ContentHeading = styled.h2`
  font-size: 12px;
  font-weight: 600;
  color: #435878;
`;
const ContentDesc = styled.p`
  font-size: 10px;
  font-weight: 400;
  color: #435878;
  margin-top: 2px;
`;

const ContentBody = styled.div`
  margin-top: 13px;
`;

const SyncRadioLabel = styled.p`
  font-size: 14px;
  color: #797979;
  margin-top: 20px;
`;

const SyncRadioBtn = styled.button`
  display: block;
  width: auto;
  margin-right: auto;
  cursor: pointer;
  margin-top: 10px;
  &:first-child {
    margin-top: 0;
  }
`;

const RadioIndicator = styled.span`
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #0066ff;
  border-radius: 8px;
  margin-right: 11px;
  vertical-align: text-bottom;
  &.active {
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      content: "";
      width: 8px;
      height: 8px;
      background: #0066ff;
      border-radius: 4px;
    }
  }
`;
const RadioLabel = styled.label`
  display: inline-block;
  font-size: 14px;
  color: #797979;
  cursor: pointer;
`;

const SubmitBtn = styled.button`
  display: block;
  width: auto;
  margin-right: 0;
  margin-left: auto;
  background-color: #0066ff;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  font-size: 14px;
  padding: 8px 16px;
  &:hover {
    opacity: 0.6;
  }
`;

const SelectWrapper = styled.div`
  position: relative;
  display: inline-block;
  &::before {
    position: absolute;
    top: 12px;
    right: 14px;
    content: "";
    width: 12px;
    height: 7px;
    background-image: url(${downArrow});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 0;
  }
`;
const Select = styled.select`
  position: relative;
  border: 1px solid #e2e9f3;
  border-radius: 2px;
  padding: 8px 38px 6px 11px;
  color: #435878;
  cursor: pointer;
  background: transparent;
  font-size: 12px;
  z-index: 1;
`;
