import React, { useEffect, useCallback, useRef } from "react";
import FileSaver from "file-saver";
import {
  updateDownloadGraphStatus,
} from "../../reducks/download/actions";
import {
  updateDownloadCsvTarget,
} from "../../reducks/download/actions";
import { useCurrentPng } from "recharts-to-png";
import { exportComponentAsPNG } from 'react-component-export-image';
import { useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import GlobalNav from "../Layout/GlobalNav";
import PageHeader from "../Layout/PageHeader";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import FunnelsSubMenu from "../Layout/FunnelsSubMenu";
import { FunnelDetailTable } from "../Table/FunnelDetail";
import FunnelDetailGraphDefault from "./FunnelDetail/FunnelDetailGraphDefault";
import FunnelDetailGraphSpanFilter from "./FunnelDetail/FunnelDetailGraphSpanFilter";
import FunnelDetailBreakdownBarChart from "./FunnelDetail/FunnelDetailBreakdownBarChart";
import FunnelDetailDistributionMapActionAchievement from "./FunnelDetail/FunnelDetailDistributionMapActionAchievement";
import FunnelDetailDistributionMapAchievementCost from "./FunnelDetail/FunnelDetailDistributionMapAchievementCost";
import FunnelDetailBreakdownPieChart from "./FunnelDetail/FunnelDetailBreakdownPieChart";

import reached_arrow from "../../images/icons/reached_arrow.svg";
import unreached_arrow from "../../images/icons/unreached_icon.svg";
import { setForecastFilterStatus } from "../../reducks/targetForecast/actions";

//  eto
import DataFilters from "../Parts/DataFilters";
import DataGraph from "../Parts/DataGraph";
import SpanComparisonFilter from "../SpanComparisonFilters/SpanComparisonFilter";
import { titleSwitcher } from "../Util/titleSwitcher";
import { startTargetDataSequence } from "../../reducks/targetData/actions";
import GraphSwitcher from "../Parts/GraphSwitcher";
import GraphSwitcherAverage from "../Graph/GraphSwitcher";
import { DraftTable } from "../Table/Draft";

import FunnelDetailHeader from "./FunnelDetail/FunnelDetailHeader";
import MetricsHeader from "../Metrics/MetricsHeader";
import { setFilter } from "../../reducks/filter/actions";
import GraphDataDownload from "../Parts/GraphDataDownload";
import ValiantPieChart from "../Metrics/ValiantPieChart";
import VariantFilterGraphAndTable from "../Metrics/VariantFilter/VariantFilterGraphAndTable";
import PlanComparisonGraphAndTable from "../Metrics/PlanComparison/PlanComparisonGraphAndTable";
import SpanComparisonTable from "../Metrics/SpanComparison/SpanComparisonTable";

interface ParamTypes {
  tokenName: string;
}

function FunnelDetail() {
  const classes = useStyles();
  const { tokenName } = useParams<ParamTypes>();
  console.log("tokenName", tokenName);
  const location = useLocation();
  const pathname = location.pathname;
  const dispatch = useDispatch();
  const activatedFilter = useSelector((state: any) => state.filter);
  
  useEffect(() => {
    dispatch(setForecastFilterStatus("reset_forecast"));
  }, [dispatch]);
  // '/funnes/~~~'の~~~部分だけを抜き出し
  const target_funnel_name = pathname.slice(9);
  console.log("target_funnel_name", target_funnel_name);

  const raw_data_array = useSelector(
    (state: any) => state.raw_data.raw_data_array
  );

  //  ============================================
  // ページに合わせたグラフ描写用データを取得。ここから
  const graphDateList = raw_data_array.map((result: any) => {
    let dateInGraph = result.result_at;
    return {
      date: dateInGraph
    };
  });

  const graphFunnelList = raw_data_array
    .flatMap((gfl: any) =>
      gfl.lead_new_count !== undefined
        ? gfl.lead_new_count
        : gfl.lead_new_count !== undefined
        ? gfl.lead_new_count
        : []
    )
    .filter((gfl: any) => {
      if (gfl.funnel_name !== undefined) {
        return gfl.funnel_name === target_funnel_name;
      }
      return [];
    });

  const graphDatas = graphDateList.map((data: any, index: number) => {
    return { ...data, ...graphFunnelList[index] };
  });
  // const lead_source_array = graphDatas.map((data:any) => )
  console.log("graphDatas", graphDatas);
  const name_array =
    graphDatas.length > 0 && graphDatas[0].lead_new_count_sources
      ? graphDatas[0].lead_new_count_sources.map((lcs: any) => {
          return {
            name: lcs.lead_source_name
          };
        })
      : [];
  console.log("name_array", name_array);

  const first_results_daily = useSelector(
    (state: any) => state.funnels.firstResult.resultDaily
  );
  const second_results_daily = useSelector(
    (state: any) => state.funnels.secondResult.resultDaily
  );

  // 2つの期間のデータを1つのオブジェクトにする
  const spanComparisonData = first_results_daily.map(
    (data: any, index: number) => {
      return { ...data, ...second_results_daily[index] };
    }
  );
  const resultSpan = useSelector((state: any) => state.target_data.resultSpan);

  //  eto
  const churnPathList = [
    "/funnels/client-churned",
    "/funnels/account-churned",
    "/funnels/revenue-churned"
  ];
  const customerPathList = [
    "/funnels/new-customer",
    "/funnels/active-customer"
  ];
  const noFunnelDetailPaths = [...churnPathList, ...customerPathList];
  const target_data_name = useSelector(
    (state: any) => state.target_data.target_data_name
  );
  const page_title = titleSwitcher(target_data_name);
  const target_data = useSelector(
    (state: any) => state.target_data.target_data
  );
  const key_total = useSelector((state: any) => state.target_data.key_total);
  const key_rate = useSelector((state: any) => state.target_data.key_rate);
  const key_difference = useSelector(
    (state: any) => state.target_data.key_difference
  );
  const selected_data_config_data_type = useSelector(
    (state: any) => state.raw_data.selected_data_config.data_type
  );
  const displayType = useSelector((state: any) => state.graph.displayType);
  // グラフの表示切り替え
  const this_time_graph_switch = useSelector(
    (state: any) => state.session.this_time_graph_switch
  );
  const moving_average_graph_switch = useSelector(
    (state: any) => state.session.moving_average_graph_switch
  );
  const periodic_average_graph = useSelector(
    (state: any) => state.session.periodic_average_graph
  ); //periodic_average_graph

  useEffect(() => {
    dispatch(
      startTargetDataSequence(
        location.pathname,
        raw_data_array,
        selected_data_config_data_type
      )
    );
  }, [dispatch, location, raw_data_array, selected_data_config_data_type]);

  // GA計測タグ
  useEffect(() => {
    document.title = "ファネル詳細: " + page_title; // ページタイトルを変更
    window.gtag("config", "G-YXXD65BEJ0", {
      page_path: pathname // 任意のパス（あるいはprops.location.pathnameなど）
    });
  });

  let sum_target_data_total = 0;

  graphDatas.forEach((td: any) => {
    if (
      td.lead_new_count_total === undefined ||
      td.lead_new_count_total === null ||
      isNaN(td.lead_new_count_total)
    ) {
      return (td.lead_new_count_total = 0);
    }

    sum_target_data_total += td.lead_new_count_total;
    console.log("What is td", td.lead_new_count_total);
  });
  // wait for target_data is loaded and set in redux store
  const period_average_sum =
    graphDatas && sum_target_data_total / graphDatas.length;

  console.log("funnel period_average_sum", period_average_sum);

  console.log("pathnamepathnamepathnamepathname", pathname);
  const key_last_time_comparison_rate = useSelector(
    (state: any) => state.target_data.key_last_time_comparison_rate
  );
  const key_last_time_difference = useSelector(
    (state: any) => state.target_data.key_last_time_difference
  );
  const key_objective = useSelector(
    (state: any) => state.target_data.key_objective
  );
  const labels_name_array = useSelector(
    (state: any) => state.raw_data.labels_name_array
  );
  const filtered_data = useSelector(
    (state: any) => state.raw_data.filtered_data
  );

  // Graph Download Section
  const valiantPieRef = useRef<HTMLDivElement>(null);
  const mapAchievementCostAreaRef = useRef<HTMLDivElement>(null);
  const mapActionAreaRef = useRef<HTMLDivElement>(null);
  const breakoutPieAreaRef = useRef<HTMLDivElement>(null);
  const [getDefaultGraphAreaPng, { ref: defaultGraphAreaRef }] = useCurrentPng();
  const [getBreakDownBarAreaPng, { ref: breakDownBarAreaRef }] = useCurrentPng();
  const downloadGraphStatus = useSelector((state: any) => state.download.downloadGraphStatus);
  const handleAreaDownload = useCallback(async (target='') => {
    dispatch(
      updateDownloadGraphStatus(0)
    )
    let png;
    if(target==='breakdown_bar_chart'){
      png = await getBreakDownBarAreaPng();
    }else if(target==='map_achievement_cost'){
      exportComponentAsPNG(mapAchievementCostAreaRef);
    }else if(target==='breakout_pie_chart'){
      exportComponentAsPNG(breakoutPieAreaRef);
    }else if(target==='map_action'){
      exportComponentAsPNG(mapActionAreaRef);
    }else if(target==='valiant_pie'){
      exportComponentAsPNG(valiantPieRef);
    }else{
      png = await getDefaultGraphAreaPng();
    }
    if (png) {
      FileSaver.saveAs(png, "graph.png");
    }
  }, [getDefaultGraphAreaPng, getBreakDownBarAreaPng, dispatch]);

  if (downloadGraphStatus === 1 && !(churnPathList.includes(pathname) || customerPathList.includes(pathname))) {
    handleAreaDownload();
  }

  function handleGraphDataDownloadClick(e:any, target:string) {
    if(e.target.innerText==='グラフ Export'){
      handleAreaDownload(target);
    }
    if(target==='valiant_pie'){
      let filter = (activatedFilter==='product')?'プラン比較':activatedFilter;
      dispatch(
        updateDownloadCsvTarget("/"+filter+"/"+target)
      )
    }else{
      dispatch(
        updateDownloadCsvTarget("/"+target)
      )
    }
  }

  // URLが変わるたびにファネルページであればfilterを初期化
  useEffect(() => {
    if (!noFunnelDetailPaths.includes(pathname)) dispatch(setFilter(""));
  }, [dispatch, noFunnelDetailPaths, pathname]);

  return (
    <Container>
      <GlobalNav />

      <main className={classes.content}>
        <PageHeader page_title="ファネル" />

        <div className={classes.globalCard}>
          <div className={classes.nav}>
            <FunnelsSubMenu />
          </div>

          <FunnelsContent>
            {churnPathList.includes(pathname) ||
            customerPathList.includes(pathname) ? (
              <ScrollContent>
                {/* フィルターリスト */}
                <DataFilters />

                {(activatedFilter === "" || activatedFilter === "期間比較") && (
                  <ContentCard>
                    {/* 当月実績 */}
                    {target_data.length > 0 && activatedFilter === "" && (
                      <MetricsHeader
                        resultSpan={resultSpan}
                        target_data={target_data}
                        target_data_name={target_data_name}
                        key_total={key_total}
                        key_rate={key_rate}
                        key_difference={key_difference}
                        key_last_time_comparison_rate={
                          key_last_time_comparison_rate
                        }
                        key_last_time_difference={key_last_time_difference}
                        key_objective={key_objective}
                      />
                    )}

                    <CardBody>
                      {/* 期間比較フィルター */}
                      {activatedFilter === "期間比較" && (
                        <SpanComparisonFilter page_is="revenue" />
                      )}

                      {/* グラフ */}
                      <DataGraph result_data_type={target_data_name} />

                      {/* データテーブル */}
                      {activatedFilter === "" && (
                        <DraftTable
                          dataType="metrics"
                          prefix={target_data_name}
                          targetData={target_data}
                        />
                      )}
                      {activatedFilter === "期間比較" && (
                        <SpanComparisonTable resultSpan={resultSpan} />
                      )}
                    </CardBody>
                  </ContentCard>
                )}
                {activatedFilter === "product" && (
                  <PlanComparisonGraphAndTable
                    target_data_name={target_data_name}
                    labels_name_array={labels_name_array}
                    filtered_data={filtered_data}
                    result_span={resultSpan}
                  />
                )}

                {activatedFilter !== "" &&
                  activatedFilter !== "期間比較" &&
                  activatedFilter !== "product" && (
                    <VariantFilterGraphAndTable
                      target_data_name={target_data_name}
                      labels_name_array={labels_name_array}
                      filtered_data={filtered_data}
                      result_span={resultSpan}
                    />
                  )}

                {activatedFilter !== "" && activatedFilter !== "期間比較" && (
                  <ContentCard>
                    <CardBody>
                      <CardTitleGraphDownloadWrapper>
                        <CardTitle>{page_title}:プラン比較</CardTitle>
                          <div onClick={(e) => {handleGraphDataDownloadClick(e, 'valiant_pie')}}>
                            <GraphDataDownload />
                          </div>
                      </CardTitleGraphDownloadWrapper>
                      <ValiantPieChart valiantPieRef={valiantPieRef}></ValiantPieChart>
                    </CardBody>
                  </ContentCard>
                )}

                {/* Cohort */}
                {/* {pathname === "/funnels/client-churned" && (
									<>
										<ContentCard style={{ marginTop: 16 }}>
											<CohortHeader />
											<CohortGraph data_name="client_churned" />
										</ContentCard>
									</>
								)}

								{pathname === "/funnels/revenue-churned" && (
									<>
									<ContentCard style={{ marginTop: 16 }}>
										<CohortHeader />
										<CohortGraph data_name="revenue_churned" />
									</ContentCard>
									</>
								)} */}
              </ScrollContent>
            ) : (
              <ScrollContent>
                <ContentCard>
                  {/* フィルターリスト */}
                  {/* <FunnelFilterList /> */}
                  {/* タイトル */}
                  <CardTitle
                    className="main"
                    style={{ padding: "20px 20px 10px" }}
                  >
                    {target_funnel_name}
                    <GraphSwitcher />
                    <div onClick={(e) => {handleGraphDataDownloadClick(e, '')}}>
                      <GraphDataDownload />
                    </div>
                  </CardTitle>

                  {/* ファネル詳細ヘッダー */}
                  {graphDatas.length > 0 &&
                  graphDatas[graphDatas.length - 1].lead_new_count_total !==
                    undefined ? (
                    activatedFilter === "" && (
                      <FunnelDetailHeader
                        resultSpan={resultSpan}
                        graphDatas={graphDatas}
                        target_funnel_name={target_funnel_name}
                        key_total={key_total}
                        key_rate={key_rate}
                        key_difference={key_difference}
                      />
                    )
                  ) : (
                    <NoData>データが不足しています</NoData>
                  )}

                  <CardBody>
                    {activatedFilter === "" && (
                      <>
                        {/* 移動平均、当月データグラフ表示切り替えスイッチ */}
                        <GraphSwitcherAverage graphType="FunnelDetail" />

                        <FunnelDetailGraphDefault
                          defaultGraphAreaRef={defaultGraphAreaRef}
                          target_funnel_name={target_funnel_name}
                          raw_data_array={raw_data_array}
                          graphDatas={graphDatas}
                          resultSpan={resultSpan}
                          displayType={displayType}
                          this_time_graph_switch={this_time_graph_switch}
                          moving_average_graph_switch={
                            moving_average_graph_switch
                          }
                          periodic_average_graph={periodic_average_graph}
                          period_average_sum={period_average_sum}
                        />
                      </>
                    )}
                    {activatedFilter === "期間比較" && (
                      <FunnelDetailGraphSpanFilter
                        spanComparisonData={spanComparisonData}
                      />
                    )}
                    {activatedFilter === "" && (
                      <FunnelDetailTable
                        target_funnel_name={target_funnel_name}
                        graphData={graphDatas}
                      />
                    )}
                  </CardBody>
                </ContentCard>
                <ContentCard>
                  <CardBody>
                    <CardTitleGraphDownloadWrapper>
                      <CardTitle>{target_funnel_name}-Breakdown</CardTitle>
                      <div onClick={(e) => {handleGraphDataDownloadClick(e, 'breakdown_bar_chart')}}>
                        <GraphDataDownload />
                      </div>
                    </CardTitleGraphDownloadWrapper>
                    <FunnelDetailBreakdownBarChart
                      target_funnel_name={target_funnel_name}
                      breakDownBarAreaRef={breakDownBarAreaRef}
                    />
                  </CardBody>
                </ContentCard>
                {target_funnel_name !== "リード獲得" && (
                  <ContentCard>
                    <CardBody>
                      <CardTitleGraphDownloadWrapper>
                        <CardTitle>
                          {target_funnel_name}-行動/成果分布図
                        </CardTitle>
                        <div onClick={(e) => {handleGraphDataDownloadClick(e, 'map_action')}}>
                          <GraphDataDownload />
                        </div>
                      </CardTitleGraphDownloadWrapper>
                      <FunnelDetailDistributionMapActionAchievement
                        target_funnel_name={target_funnel_name}
                        mapActionAreaRef={mapActionAreaRef}
                      />
                    </CardBody>
                  </ContentCard>
                )}
                <ContentCard>
                  <CardBody>
                    <CardTitleGraphDownloadWrapper>
                      <CardTitle>
                        {target_funnel_name}
                        -成果/コスト分布図
                      </CardTitle>
                      <div onClick={(e) => {handleGraphDataDownloadClick(e, 'map_achievement_cost')}}>
                        <GraphDataDownload />
                      </div>
                    </CardTitleGraphDownloadWrapper>
                    <FunnelDetailDistributionMapAchievementCost
                      target_funnel_name={target_funnel_name}
                      mapAchievementCostAreaRef={mapAchievementCostAreaRef}
                    />
                  </CardBody>
                </ContentCard>
                <ContentCard>
                  <CardBody>
                    <CardTitleGraphDownloadWrapper>
                      <CardTitle>{target_funnel_name}-Breakout</CardTitle>
                      <div onClick={(e) => {handleGraphDataDownloadClick(e, 'breakout_pie_chart')}}>
                        <GraphDataDownload />
                      </div>
                    </CardTitleGraphDownloadWrapper>
                    <FunnelDetailBreakdownPieChart
                      target_funnel_name={target_funnel_name}
                      breakoutPieAreaRef={breakoutPieAreaRef}
                    />
                  </CardBody>
                </ContentCard>
              </ScrollContent>
            )}
          </FunnelsContent>
        </div>
      </main>
    </Container>
  );
}

export default FunnelDetail;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      backgroundColor: "#F4F4F4",
      overflow: "hidden"
    },
    globalCard: {
      display: "flex",
      justifyContent: "space-between",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    },
    nav: {
      width: 140
    },
    cell: {
      fontWeight: "normal"
    }
  })
);

// Container
const Container = styled.div`
  display: flex;
  min-width: 1200px;
`;
// FunnelsContent
const FunnelsContent = styled.div`
  width: calc(100% - 140px);
  height: 100%;
  padding: 15px 17px;
  box-sizing: border-box;
`;

//ScrollContent {
const ScrollContent = styled.div``;

// ContentCard
const ContentCard = styled.div`
  margin-bottom: 16px;
  border: 1px solid #e1e8ea;
  box-sizing: border-box;
  background-color: #fff;
`;
// CardBody
const CardBody = styled.div`
  padding: 15px 17px;
  box-sizing: border-box;
  position: relative;
`;

// CardTitle
const CardTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #2a3385;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  &.main {
    margin-bottom: 8px;
  }
`;
// ThisMonthTotal
const ThisMonthTotal = styled.p`
  font-size: 24px;
  font-weight: bold;
  line-height: 36px;
  margin-bottom: 32px;
  color: #435878;
  &.reached {
    color: #028cab;
  }
  &.unreached {
    color: #af002a;
  }
  &.equal {
    color: #35455d;
  }
  span {
    display: inline-block;
    font-size: 12px;
    font-weight: normal;
    position: relative;
    margin-left: 10px;
    top: -4px;
    &.reached {
      color: #028cab;
      &::before {
        content: "";
        display: inline-block;
        height: 18px;
        width: 24px;
        top: 5px;
        position: relative;
        background-image: url(${reached_arrow});
        background-repeat: no-repeat;
      }
    }
    &.unreached {
      color: #af002a;
      &::before {
        content: "";
        display: inline-block;
        height: 18px;
        width: 24px;
        top: 5px;
        position: relative;
        background-image: url(${unreached_arrow});
        background-repeat: no-repeat;
      }
    }
    &.equal {
      color: #35455d;
      &::before {
        content: "";
        display: inline-block;
        height: 18px;
        width: 20px;
      }
    }
  }
`;

const NoData = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #435878;
  margin-bottom: 8px;
  &.main {
    margin-bottom: 8px;
  }
`;

const CardTitleGraphDownloadWrapper = styled.div({
  flexGrow: 1,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});
