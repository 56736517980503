import React from "react";
import CommentsWindow from "./CommentsWindow"
import CommentAddWindow from "./CommentAddWindow"
import CommentEditWindow from "./ComentEditWindow"

export default function CommentModule() {
  return (
    <>
      {/* コメントリスト */}
      <CommentsWindow />

      {/* コメント追加ウィンドウ */}
      <CommentAddWindow />

      {/* コメント編集ウィンドウ */}
      <CommentEditWindow />
    </>
  );
}

