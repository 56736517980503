import {unitSwitcher} from "./unitSwitcher"

const simulationValidation = (parent_object:any,  divident: number, divisor: number) => {
  return(
    parent_object &&
    divident === 0 ? "0%" :
    divisor === 0 && divident === 0 ? "0%" :
    (divident / divisor) === Infinity ? "0%" :
    (divident / divisor) !== undefined ? unitSwitcher((divident / divisor), "rate")
    :"NoData"
  )
}

export default simulationValidation