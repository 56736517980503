import React from "react";
import styled from "styled-components";
import { updateCommentRequest, deleteCommentRequest, closeCommentEditWindow, updateCommentEditWindowContent } from "../../reducks/comments/actions";
import closeBtnSmall from "../../images/icons/close_large.svg";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

const EditCommentWindow = (props: any) => {
  const dispatch = useDispatch();

  const commentEditWindow = useSelector((state: any) => state.comments.commentEditWindow);
  const company_id = useSelector((state: any) => state.user.company_id);

  // const [content, setContent] = useState('')
  const user_name = useSelector((state: any) => state.user.name);
  const company = useSelector((state:any)=> state.company)

  const target_data_name = useSelector((state:any)=> state.target_data.target_data_name)

  const selected_data_config_data_type = useSelector((state: any) => state.raw_data.selected_data_config.data_type)
  const selected_data_config_data_span = useSelector((state: any) => state.raw_data.selected_data_config.data_span)
  const custom_span_start_time = useSelector((state: any) => state.raw_data.selected_data_config.custom_span_start_time)
  const custom_span_end_time = useSelector((state: any) => state.raw_data.selected_data_config.custom_span_end_time)


  const handleDelete = (e: any) => {
    e.preventDefault();
    dispatch(deleteCommentRequest(company_id, commentEditWindow.docId, selected_data_config_data_type, custom_span_start_time, custom_span_end_time, target_data_name))
  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    dispatch(updateCommentRequest(company_id, commentEditWindow.docId, user_name, commentEditWindow.content, selected_data_config_data_type, custom_span_start_time, custom_span_end_time, target_data_name))
  };


  const { register, errors } = useForm();

  return (
    <EditCommentWindowSC className={commentEditWindow.display && "active"}>

      {/* コメントセクション */}
      <CommentContainer>
        <CommentHeader>
          <Close onClick={() => dispatch(closeCommentEditWindow())} />
        </CommentHeader>
        <form style={{ position: "relative" }}>
          <InputBox
            name="comment"
            id="comment"
            cols={30}
            rows={10}
            ref={register({ required: true })}
            // value={openedEditWindow.content}
            value={commentEditWindow.content}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => dispatch(updateCommentEditWindowContent(e.target.value))}
          ></InputBox>

          {errors.comment && <Error>コメントが入力されていません</Error>}

          <BtnWrapper>
            <DeleteBtn onClick={handleDelete}>削除</DeleteBtn>
            <AddBtn onClick={handleUpdate}>更新</AddBtn>
          </BtnWrapper>
        </form>
      </CommentContainer>
    </EditCommentWindowSC>
  );
};

export default EditCommentWindow;

const EditCommentWindowSC = styled.div`
  display: none;
  position: absolute;
  top: 50px;
  right: 0;
  left: 0;
  width: 216px;
  border-radius: 2px;
  background-color: #fff;
  padding: 8px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
  margin: 0 auto;
  z-index: 3;
  &.active {
    display: block;
  }
`;

const CommentContainer = styled.div``;

const Close = styled.button`
  display: inline-block;
  background-image: url(${closeBtnSmall});
  background-size: cover;
  position: relative;
  width: 16px;
  height: 16px;
`;
const CommentHeader = styled.div`
  position: relative;
  margin-bottom: 8px;
  text-align: right;
`;
const InputBox = styled.textarea`
  width: 200px;
  height: 105px;
  font-size: 10px;
  color: #35455d;
  resize: none;
  border: 1px solid #c3cedf;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 8px;
`;

const BtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AddBtn = styled.button`
  width: 96px;
  height: 24px;
  background: #0066ff;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  color: #fff;
  display: block;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  text-align: center;
  margin-left: auto;
  margin-right: 0px;
`;
const DeleteBtn = styled.button`
  width: 96px;
  height: 24px;
  background: #fff;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  color: #7591bc;
  display: block;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  text-align: center;
`;

const Error = styled.p`
  color: #af002a;
  font-size: 8px;
  position: relative;
  bottom: 8px;
`;
