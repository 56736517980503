import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { toggleLandingPageModal } from '../../reducks/landingPageModal/actions'

// GlobalHeaderSupport
const GlobalHeaderSupport = styled.div`
  padding: 16px 0;
  dl {
    margin: 0;
    display: flex;
    align-items: center;
  }
  dt, dd {
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    color: #fff;
    margin: 0;
  }
  dt {
    margin-right: 48px;
  }
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
  }
  li {
    text-align: center;
    width: 240px;
    height: 48px;
    margin-right: 24px;
    padding: 11px 0;
    border: 2px solid #fff;
    box-sizing: border-box;
    border-radius: 10px;
    cursor: pointer;
    transition: all .6s;
    &:nth-child(1) {
      padding: 0;
      background-color: #0769FC;
      &:hover {
        opacity: .6;
      }
    }
    &:nth-child(2) {
      margin-right: 0;
      background-color: #AF002A;
      &:hover {
        opacity: .6;
      }
    }
    a {
      padding: 11px 0;
      box-sizing: border-box;
      display: block;
    }
  }
`;

const UserSupport = () => {
  const dispatch = useDispatch();
  const lp_open = useSelector((state: any) => state.landingPageModal.open);
  const clickModalOpen = () => {
    dispatch(toggleLandingPageModal(!lp_open))
  }
  
  return(
    <GlobalHeaderSupport>
      <dl>
        <dt>ユーザーサポート</dt>
        <dd>
          <ul>
            <li><Link to="/signin">ログイン</Link></li>
            <li onClick={clickModalOpen}>資料請求・お問い合わせ</li>
          </ul>
        </dd>
      </dl>
    </GlobalHeaderSupport>
  );
}

export default UserSupport;