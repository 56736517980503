import React, { Component } from "react";
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import styled from "styled-components";
import graphObjectiveLegendIcon from "../../images/icons/graph_objective_legend.svg";
import graphTotalLegendIcon from "../../images/icons/graph_total_legend.svg";
import movingAverageLegendIcon from "../../images/icons/moving_average_legend.svg";
import {
  decimalPointChecker,
  decimalPointCheckerForPercent
} from "../Util/unitSwitcher";
import CommentModule from "../Comment/CommentModule";
import CommentTick from "../Comment/CommentTick";
import { yAxisFormatter } from "../Util/yAxisFormatter";

//フィルターなしLegend
const noneFilterLegend = (data: {
  this_time_graph_switch: boolean;
  moving_average_graph_switch: boolean;
  periodic_average_graph: boolean;
}) => {
  return (
    <ul
      style={{
        display: "flex",
        fontSize: "12px",
        color: "#283f46",
        marginBottom: "16px"
      }}
    >
      <LegendItem className="total">実績</LegendItem>
      <LegendItem className="objective">目標</LegendItem>
      {data.moving_average_graph_switch && (
        <LegendItem className="movingAverage">移動平均</LegendItem>
      )}
    </ul>
  );
};

const getBarGraphTooltipData = (targetDataName:string , value:number ) => {
  if(targetDataName.match('churn_rate')){
      return decimalPointCheckerForPercent(value);
  }
  return decimalPointChecker(value)
 }

const CustomTooltipLine = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <BarGraphTooltip>
        <BarGraphTooltipDate>
          {new Date(
            payload[0] && payload[0].payload.result_at.seconds * 1000
          ).getFullYear()}
          .
          {new Date(
            payload[0] && payload[0].payload.result_at.seconds * 1000
          ).getMonth() + 1}
        </BarGraphTooltipDate>
        <BarGraphTooltipLabel>当月</BarGraphTooltipLabel>
        <BarGraphTooltipData>{`${getBarGraphTooltipData(
          payload[0].dataKey,
          payload[0] ? payload[0].value : 0
        )}`}</BarGraphTooltipData>

        <BarGraphTooltipHR />

        <BarGraphTooltipLabel>目標</BarGraphTooltipLabel>
        <BarGraphTooltipData>{`${getBarGraphTooltipData(
          payload[1].dataKey,
          payload[1] ? payload[1].value : 0
        )}`}</BarGraphTooltipData>

        {payload[2] && (
          <>
            <BarGraphTooltipHR />
            <BarGraphTooltipLabel>移動平均</BarGraphTooltipLabel>
            <BarGraphTooltipData>{`${getBarGraphTooltipData(
              payload[2].dataKey,
              payload[2] ? payload[2].value : 0
            )}`}</BarGraphTooltipData>
          </>
        )}
      </BarGraphTooltip>
    );
  }
  return null;
};

const CustomTooltipBar = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <BarGraphTooltip>
        <BarGraphTooltipDate>
          {new Date(
            payload[0] && payload[0].payload.result_at.seconds * 1000
          ).getFullYear()}
          .
          {new Date(
            payload[0] && payload[0].payload.result_at.seconds * 1000
          ).getMonth() + 1}
        </BarGraphTooltipDate>
        <BarGraphTooltipLabel>当月</BarGraphTooltipLabel>
        <BarGraphTooltipData>{`${getBarGraphTooltipData(
          payload[0].dataKey,
          payload[0] ? payload[0].value : 0
        )}`}</BarGraphTooltipData>

        <BarGraphTooltipHR />

        <BarGraphTooltipLabel>目標</BarGraphTooltipLabel>
        <BarGraphTooltipData>{`${getBarGraphTooltipData(
          payload[1].dataKey,
          payload[1] ? payload[1].value : 0
        )}`}</BarGraphTooltipData>

        {payload[2] && (
          <>
            <BarGraphTooltipHR />
            <BarGraphTooltipLabel>移動平均</BarGraphTooltipLabel>
            <BarGraphTooltipData>{`${getBarGraphTooltipData(
              payload[2].dataKey,
              payload[2] ? payload[2].value : 0
            )}`}</BarGraphTooltipData>
          </>
        )}
      </BarGraphTooltip>
    );
  }

  return null;
};

interface Props {
  activatedFilter: any;
  this_time_graph_switch: any;
  moving_average_graph_switch: any;
  periodic_average_graph: any; //periodic_average_graph
  target_data_name: any;
  key_total: any;
  key_objective: any;
  key_moving_average: any;
  key_moving_average_objective: any;
  target_data: any;
  period_average_sum: any;
  resultSpan: string;
  selected_span: string;
  comments: any;
  displayType: "line" | "bar";
}

interface State {}

export default class GraphDefault extends Component<Props, State> {
  area: any;
  tooltip: HTMLElement | null;
  point: any;

  constructor(props: any) {
    super(props);

    this.area = null;
    this.tooltip = null;
    this.point = null;
  }

  render() {
    const displayType = this.props.displayType;

    return (
      <>
        {this.props.target_data.length > 0 ? (
          <>
            <Graph>
              <ResponsiveContainer>
                <ComposedChart
                  data={this.props.target_data}
                  width={1000}
                  height={400}
                  margin={{ left: 75, bottom: 24 }}
                  barSize={20}
                  barGap={5}
                >
                  {displayType === "line" ? (
                    <Legend
                      verticalAlign="top"
                      align="left"
                      content={noneFilterLegend({
                        this_time_graph_switch:
                          this.props.this_time_graph_switch,
                        moving_average_graph_switch:
                          this.props.moving_average_graph_switch,
                        periodic_average_graph:
                          this.props.periodic_average_graph
                      })}
                    />
                  ) : (
                    <Legend
                      verticalAlign={"top"}
                      align={"left"}
                      width={432}
                      height={32}
                      margin={{ right: 100 }}
                      iconSize={12}
                      wrapperStyle={{ fontSize: "12px", color: "#283f46" }}
                    />
                  )}

                  <CartesianGrid stroke="#f5f5f5" vertical={false} />

                  {/*-*-*-*-* Bar *-*-*-*-*/}
                  {displayType === "bar" && (
                    <Bar
                      name="実績"
                      dataKey={this.props.key_total}
                      fill="#0066FF"
                    />
                  )}
                  {/* [ 目標 ] 達成率 => 実績 ( Bar ) */}
                  {displayType === "bar" && (
                    <Bar
                      name="目標"
                      dataKey={this.props.key_objective}
                      fill="#99C2FF"
                    />
                  )}

                  {/*-*-*-*-* Area *-*-*-*-*/}
                  {/* [ 実績 ] 達成率 => 実績 ( Area ) */}
                  {displayType === "line" && (
                    <Area
                      type="linear"
                      name="実績"
                      dataKey={this.props.key_total}
                      dot={false}
                      fill="#0066FF"
                      fillOpacity="0.1"
                      stroke="none"
                    />
                  )}

                  {/*-*-*-*-* Line *-*-*-*-*/}
                  {/* [ 実績 ] 達成率 => 実績 ( Line ) */}
                  {displayType === "line" &&
                    !this.props.moving_average_graph_switch &&
                    !this.props.periodic_average_graph && (
                      <Line
                        type="linear"
                        name="実績"
                        dataKey={this.props.key_total}
                        dot={false}
                        stroke="#0066FF"
                        strokeWidth="3"
                      />
                    )}

                  {/*-*-*-*-* Line *-*-*-*-*/}
                  {/* [ 実績 ] 達成率 => 目標 */}
                  {displayType === "line" && (
                    <Line
                      type="linear"
                      name="目標"
                      dataKey={this.props.key_objective}
                      dot={false}
                      stroke="#99c2ff"
                      strokeWidth="3"
                    />
                  )}

                  {/* [ 移動平均 ] 達成率 => 実績 */}
                  {this.props.moving_average_graph_switch && (
                    <Line
                      type="linear"
                      name="移動平均"
                      dataKey={this.props.key_moving_average}
                      dot={false}
                      stroke="#0066FF"
                      strokeWidth="5"
                      strokeDasharray="5 5"
                    />
                  )}

                  {/*-*-*-*-* 期間平均線 *-*-*-*-*/}
                  {/* periodic_average_graph */}
                  {this.props.periodic_average_graph && (
                    <ReferenceLine
                      y={this.props.period_average_sum}
                      stroke="#0066FF"
                      strokeWidth={5}
                    />
                  )}

                  <XAxis
                    dataKey={"result_at.seconds"}
                    tick={
                      <CommentTick
                        comments={this.props.comments}
                        target_data_name={this.props.target_data_name}
                      />
                    }
                    scale={displayType === "line" ? "point" : "auto"}
                    tickLine={false}
                    padding={
                      this.props.resultSpan === "monthly"
                        ? this.props.selected_span === "this_month"
                          ? { left: 0, right: -5 }
                          : this.props.selected_span === "last_month"
                          ? { left: 0, right: -5 }
                          : this.props.selected_span === "last_6_months"
                          ? { left: 2, right: -5 }
                          : this.props.selected_span === "last_12_months"
                          ? { left: 0, right: 0 }
                          : { left: 0, right: 0 }
                        : this.props.resultSpan === "weekly"
                        ? this.props.selected_span === "this_month"
                          ? { left: 0, right: -5 }
                          : this.props.selected_span === "last_month"
                          ? { left: 0, right: -5 }
                          : this.props.selected_span === "last_6_months"
                          ? { left: 0, right: 0 }
                          : this.props.selected_span === "last_12_months"
                          ? { left: 0, right: 0 }
                          : { left: 0, right: 0 }
                        : this.props.resultSpan === "daily"
                        ? this.props.selected_span === "this_month"
                          ? { left: 0, right: -5 }
                          : this.props.selected_span === "last_month"
                          ? { left: 0, right: 0 }
                          : this.props.selected_span === "last_6_months"
                          ? { left: 0, right: 0 }
                          : this.props.selected_span === "last_12_months"
                          ? { left: 0, right: 0 }
                          : { left: 0, right: 0 }
                        : { left: 0, right: 0 }
                    }
                  />

                  <YAxis
                    tickCount={10}
                    interval={0}
                    tickLine={false}
                    tickMargin={10}
                    tickFormatter={tick =>
                      yAxisFormatter(tick, this.props.target_data_name)
                    }
                  />

                  {displayType === "line" ? (
                    <Tooltip content={<CustomTooltipLine />} />
                  ) : (
                    <Tooltip content={<CustomTooltipBar />} />
                  )}
                </ComposedChart>
              </ResponsiveContainer>

              <UiChartTooltip
                className="ui-chart-tooltip"
                ref={ref => (this.tooltip = ref)}
              >
                <div className="tooltip-date"></div>
                <div className="toltip-databox">
                  <div className="tooltip-data-key"></div>
                  <div className="tooltip-data-value"></div>
                </div>
              </UiChartTooltip>
            </Graph>

            <CommentModule />
          </>
        ) : (
          <NoData>グラフを表示できません</NoData>
        )}
      </>
    );
  }
}

// Graph
const Graph = styled.div`
  width: 100%;
  height: 272px;
  padding: 0 10px;
  box-sizing: border-box;

  .recharts-tooltip-wrapper {
    max-width: 200px;
  }

  .recharts-legend-wrapper {
    overflow: visible;
    top: -20px !important;
    left: 0px !important;
    font-size: 12px;
    color: #283f46;
  }

  .recharts-surface {
    position: relative;
    top: -2px;
    overflow: visible;
  }
  .recharts-cartesian-axis-tick {
    width: 100px !important;
    .recharts-cartesian-axis-tick-value {
      font-size: 10px;
      color: #435878;
    }
  }
  .recharts-yAxis {
    .recharts-cartesian-axis-line {
      stroke: #f5f5f5;
    }
  }
`;

//customLegend
const LegendItem = styled.li`
  margin-right: 16px;
  &.total {
    &::before {
      content: "";
      display: inline-block;
      width: 19px;
      height: 14px;
      background-image: url(${graphTotalLegendIcon});
      background-size: cover;
      background-position: center;
      position: relative;
      top: 2px;
      left: -5px;
    }
  }
  &.objective {
    &::before {
      content: "";
      display: inline-block;
      width: 19px;
      height: 14px;
      background-image: url(${graphObjectiveLegendIcon});
      background-size: cover;
      background-position: center;
      position: relative;
      top: 2px;
      left: -5px;
    }
  }
  &.movingAverage {
    position: relative;
    top: 3px;
    left: 18px;
    &::before {
      content: "";
      display: inline-block;
      width: 19px;
      height: 9px;
      background-image: url(${movingAverageLegendIcon});
      background-size: cover;
      background-position: center;
      position: absolute;
      top: 2px;
      left: -22px;
    }
  }
`;

const UiChartTooltip = styled.div`
  opacity: 0;
  position: absolute;
  z-index: -1;
  max-width: 150px;
  width: auto;
  min-height: 48px;
  height: auto;
  background: white;
  border: 1px solid #e2e9f3;
  border-radius: 2px;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.25);
  padding: 8px 9px;
  .tooltip-date {
    font-size: 10px;
    font-weight: bold;
    color: #7591bc;
    margin-bottom: 5px;
  }
  .toltip-databox {
    display: flex;
    justify-content: space-between;
  }
  .tooltip-data-key {
    font-size: 10px;
    color: #435878;
    width: 50%;
    margin-right: 8px;
    white-space: nowrap;
  }
  .tooltip-data-value {
    font-size: 10px;
    font-weight: bold;
    color: #435878;
  }
`;

const NoData = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #435878;
  margin-bottom: 8px;
  &.main {
    margin-bottom: 8px;
  }
`;

const BarGraphTooltip = styled.div`
  background: #fff;
  padding: 8px 9px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid #e2e9f3;
  border-radius: 4px;
`;

// 日付
const BarGraphTooltipDate = styled.p`
  width: 100%;
  font-size: 10px;
  font-weight: bold;
  color: #7591bc;
  margin-bottom: 3px;
`;

// 当月 | 目標
const BarGraphTooltipLabel = styled.p`
  width: 30%;
  font-size: 10px;
  color: #435878;
  margin-bottom: 3px;
`;
// データ
const BarGraphTooltipData = styled.p`
  width: 70%;
  text-align: right;
  font-size: 10px;
  font-weight: bold;
  color: #435878;
  margin-bottom: 3px;
`;
// 仕切り線
const BarGraphTooltipHR = styled.hr`
  width: 100%;
  height: 1px;
  color: lightgray;
  margin-bottom: 3px;
`;
