import submitToServer from "../dataUploadToServer";
import { call, put, takeLatest } from "redux-saga/effects";
import { UPLOAD_DATA_REQUESTED, UPLOAD_DATA_SUCCEEDED, UPLOAD_DATA_FAILED } from "../reducks/data/types";

function* uploadData(action: any) {
  try {

    console.log('formData', action.formData);
    // const result = yield call(submitToServer, action.formData, action.endPoint, action.token);
    const result = yield call(submitToServer, action.formData, action.endpoint);
    console.log('upload data result', result);
    yield put({ type: UPLOAD_DATA_SUCCEEDED, result: result });
  } catch (e) {
    console.log('upload data result error', e);
    console.log('upload data result error response', e.response);
    yield put({ type: UPLOAD_DATA_FAILED, error_response: e.response });
  }
}

const dataSagas = [
  takeLatest(UPLOAD_DATA_REQUESTED, uploadData),
];

export default dataSagas;
