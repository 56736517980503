import * as React from "react";
import * as H from "history";
import { useLocation } from "react-router-dom"

//  components
import LoginHeader from "../Layout/LogInHeader";

//  materials
import {
	Button,
	Container,
	Link,
	Typography,
	TextField,
} from "@material-ui/core";

//  styles
import * as useStyles from "./styles";

interface ForgetPasswordProps {
	history: H.History;
}
const ForgetPassword: React.FC<ForgetPasswordProps> = (props) => {
	const location = useLocation();
  const pathName = location.pathname
	// GA計測タグ
  React.useEffect(() => {
    document.title = "パスワード再設定"; // ページタイトルを変更
    window.gtag('config', 'G-YXXD65BEJ0', {
      'page_path': pathName // 任意のパス（あるいはprops.location.pathnameなど）
    });
  });

	/*-*-*-*-* properties *-*-*-*-*/
	const { history } = props;
	//  styles
	const classes = useStyles.ForgetPassword({});

	/*-*-*-*-* render *-*-*-*-*/
	return (
		<div className={classes.ForgetPassword}>
			<LoginHeader />
			<Container className={classes.Container} maxWidth="md">
				{/*-*-*-*-* label *-*-*-*-*/}
				<div className={classes.label}>
					<Typography className={classes["label-Typography"]}>
						パスワードを忘れた方はこちら
					</Typography>
				</div>

				{/*-*-*-*-* form *-*-*-*-*/}
				<Container maxWidth="sm" className={classes.form}>
					<TextField
						variant="outlined"
						label="メールアドレス"
						placeholder="メールアドレスを入力してください。"
                        className={classes["form-input"]}
                        type="email"
					/>
				</Container>

				{/*-*-*-*-* submit *-*-*-*-*/}
				<div className={classes.submit}>
					<Button variant="contained" className={classes["submit-btn"]}>
						送信
					</Button>
				</div>

				{/*-*-*-*-* signin *-*-*-*-*/}
				<div className={classes.signin}>
					<Link href="/signin" className={classes["signin-link"]}>
						ログインページに戻る
					</Link>
				</div>
			</Container>
		</div>
	);
};

export default ForgetPassword;
