import rsf from "../../rsf"
import { all, call, put, takeLatest, takeEvery } from "redux-saga/effects"
import firebase from "firebase"

import { GET_RESULTS_FOR_TARGET_FORECAST_REQUEST, GET_RESULTS_FOR_TARGET_FORECAST_SUCCEEDED, GET_RESULTS_FOR_TARGET_FORECAST_FAILED, GET_INTEGRATED_ARRAY_FORECAST_REQUEST, GET_INTEGRATED_ARRAY_FORECAST_SUCCEEDED,  GET_INTEGRATED_ARRAY_FORECAST_FAILED, SET_FORECAST_CUSTOM_SPAN, GET_RESULTS_FOR_TARGET_FORECAST_CUSTOM_REQUEST } from "../../reducks/targetForecast/types"
import { HIDE_LOADING } from '../../reducks/loading/types'


const db = firebase.firestore()


//　右上カレンダーで指定された期間のサマリーデータ
function* getRawDataReal(company_id, selected_data_config_data_span, custom_span_start_time, custom_span_end_time ) {
  try {
    // 期間設定
    const start_time = new Date(custom_span_start_time.seconds * 1000);
    const end_time = new Date(custom_span_end_time.seconds * 1000);
    // 当月
    const thisMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const month = thisMonth.getMonth();

    // 昨月
    const lastMonth = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 1));

    // 翌月
    const nextMonth = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month + 1));

    // 一昨月
    const lastLastMonth = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 2));

    // 6ヶ月前
    const last6Months = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 5));

    // 12ヶ月前
    const last12Months = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 11));

    // コレクショングループでためす
    let raw_data_array_real = [];
    if (selected_data_config_data_span === 'custom') {
      // データ取得
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${company_id}/result_monthly`)
          .where("result_at", ">=", start_time)
          .where("result_at", "<=", end_time)
        );

      // 取得後データを整形
      snapshot.forEach(result_monthly => {
        raw_data_array_real = [...raw_data_array_real, { ...result_monthly.data(), docId: result_monthly.id }];
      });

      return raw_data_array_real;
    } else if (selected_data_config_data_span === 'this_month') {
      // データ取得
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${company_id}/result_monthly`)
          .where("result_at", ">=", lastMonth)
          .where("result_at", "<=", nextMonth)
        );

      // 取得後データを整形
      snapshot.forEach(result_monthly => {
        raw_data_array_real = [...raw_data_array_real, { ...result_monthly.data(), docId: result_monthly.id }];
      });
      return raw_data_array_real
    } else if (selected_data_config_data_span === 'last_month') {
      // データ取得
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${company_id}/result_monthly`)
          .where("result_at", ">=", lastLastMonth)
          .where("result_at", "<=", thisMonth)
        );

      // 取得後データを整形
      snapshot.forEach(result_monthly => {
        raw_data_array_real = [...raw_data_array_real, { ...result_monthly.data(), docId: result_monthly.id }];
      });
      return raw_data_array_real
    } else if (selected_data_config_data_span === 'last_6_months') {
      // データ取得
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${company_id}/result_monthly`)
          .where("result_at", ">=", last6Months)
          .where("result_at", "<=", thisMonth)
        );

      // 取得後データを整形
      snapshot.forEach(result_monthly => {
        raw_data_array_real = [...raw_data_array_real, { ...result_monthly.data(), docId: result_monthly.id }];
      });
      return raw_data_array_real
    } else if (selected_data_config_data_span === 'last_12_months') {
      // データ取得
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${company_id}/result_monthly`)
          .where("result_at", ">=", last12Months)
          .where("result_at", "<=", thisMonth)
        );

      // 取得後データを整形
      snapshot.forEach(result_monthly => {
        raw_data_array_real = [...raw_data_array_real, { ...result_monthly.data(), docId: result_monthly.id }];
      });
      return raw_data_array_real
    } else {
      // データ取得
      const snapshot = yield call(
        rsf.firestore.getCollection,
        db.collection(`companies/${company_id}/result_monthly`)
          .where("result_at", ">=", start_time)
          .where("result_at", "<=", end_time)
        );

      // 取得後データを整形
      snapshot.forEach(result_monthly => {
        raw_data_array_real = [...raw_data_array_real, { ...result_monthly.data(), docId: result_monthly.id }];
      });

      return raw_data_array_real;
    }


  } catch (e) {
    console.log("error message", e.message);
  }
}


export default getRawDataReal;
