import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
//import TextField from '@material-ui/core/TextField';
import { useSelector, useDispatch } from "react-redux";

import "date-fns";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import format from "date-fns/format";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
// import { getResultsRequestForNewForecastForCustom } from '../../../reducks/newForecast/actions';
import { getResultsForTargetForecastCustomRequest } from "../../../reducks/targetForecast/actions";

import jaLocale from "date-fns/locale/ja";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap"
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200
    }
  })
);

class ExtendedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date: any) {
    return format(date, "MMM", { locale: this.locale });
  }
}

const DateSpanSelectorEnd = (props: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const resultSpan = useSelector((state: any) => state.target_data.resultSpan);
  const company_id = useSelector((state: any) => state.user.company_id);

  const selected_data_config_data_span = useSelector(
    (state: any) => state.raw_data.selected_data_config.data_span
  );
  const selected_data_config_data_type = useSelector(
    (state: any) => state.raw_data.selected_data_config.data_type
  );
  const custom_span_start_time = useSelector(
    (state: any) => state.raw_data.selected_data_config.custom_span_start_time
  );
  const custom_span_end_time = useSelector(
    (state: any) => state.raw_data.selected_data_config.custom_span_end_time
  );

  const forecast_custom_span_start_time = useSelector(
    (state: any) =>
      state.target_forecast.selected_data_config.custom_span_start_time
  );
  const forecast_custom_span_end_time = useSelector(
    (state: any) =>
      state.target_forecast.selected_data_config.custom_span_end_time
  );

  // カスタム期間変更によるデータ取得
  const handleDateChange = (date: Date | null) => {
    if (forecast_custom_span_start_time !== "" && date != null) {
      if (new Date(forecast_custom_span_start_time * 1000) <= date) {
        console.log(
          "forecast_custom_span_start_time",
          forecast_custom_span_start_time
        );
        console.log(
          "forecast_custom_span_end_time",
          forecast_custom_span_end_time
        );
        console.log(
          "forecast_base_span from",
          date.getFullYear() * 12 + date.getMonth()
        );
        console.log(
          "forecast_base_span to",
          new Date(forecast_custom_span_start_time * 1000).getFullYear() * 12 +
            new Date(forecast_custom_span_start_time * 1000).getMonth()
        );

        const forecast_base_span_from =
          date.getFullYear() * 12 + date.getMonth();
        const forecast_base_span_to =
          new Date(forecast_custom_span_start_time * 1000).getFullYear() * 12 +
          new Date(forecast_custom_span_start_time * 1000).getMonth();
        const forecast_base_span =
          forecast_base_span_from - forecast_base_span_to;
        console.log("forecast_base_span", forecast_base_span);

        dispatch(
          getResultsForTargetForecastCustomRequest(
            company_id,
            // 未来予測カスタム期間の差異の月数
            forecast_base_span,
            selected_data_config_data_span,
            selected_data_config_data_type,

            custom_span_start_time,
            custom_span_end_time,

            forecast_custom_span_start_time,
            Math.round(date.getTime() / 1000)
          )
        );
      } else {
        alert("開始日より後の日付を選択してください");
        return false;
      }
    }
  };

  // ===================================================== //
  //　週次表示時に特定の曜日を非表示にする
  const weekStartDay = useSelector(
    (state: any) => state.company.config.weekly_start_day
  );

  let disableWeekDays;

  if (weekStartDay === "Mon") {
    disableWeekDays = (date: any) => {
      //0以外
      return (
        date.getDay() === 1 ||
        date.getDay() === 2 ||
        date.getDay() === 3 ||
        date.getDay() === 4 ||
        date.getDay() === 5 ||
        date.getDay() === 6
      );
    };
  } else if (weekStartDay === "Tue") {
    disableWeekDays = (date: any) => {
      //1以外
      return (
        date.getDay() === 0 ||
        date.getDay() === 2 ||
        date.getDay() === 3 ||
        date.getDay() === 4 ||
        date.getDay() === 5 ||
        date.getDay() === 6
      );
    };
  } else if (weekStartDay === "Wed") {
    disableWeekDays = (date: any) => {
      //2以外
      return (
        date.getDay() === 0 ||
        date.getDay() === 1 ||
        date.getDay() === 3 ||
        date.getDay() === 4 ||
        date.getDay() === 5 ||
        date.getDay() === 6
      );
    };
  } else if (weekStartDay === "Thu") {
    disableWeekDays = (date: any) => {
      //3以外
      return (
        date.getDay() === 0 ||
        date.getDay() === 1 ||
        date.getDay() === 2 ||
        date.getDay() === 4 ||
        date.getDay() === 5 ||
        date.getDay() === 6
      );
    };
  } else if (weekStartDay === "Fri") {
    disableWeekDays = (date: any) => {
      //4以外
      return (
        date.getDay() === 0 ||
        date.getDay() === 1 ||
        date.getDay() === 2 ||
        date.getDay() === 3 ||
        date.getDay() === 5 ||
        date.getDay() === 6
      );
    };
  } else if (weekStartDay === "Sat") {
    disableWeekDays = (date: any) => {
      //5以外
      return (
        date.getDay() === 0 ||
        date.getDay() === 1 ||
        date.getDay() === 2 ||
        date.getDay() === 3 ||
        date.getDay() === 4 ||
        date.getDay() === 6
      );
    };
  } else if (weekStartDay === "Sun") {
    disableWeekDays = (date: any) => {
      //6以外
      return (
        date.getDay() === 0 ||
        date.getDay() === 1 ||
        date.getDay() === 2 ||
        date.getDay() === 3 ||
        date.getDay() === 4 ||
        date.getDay() === 5
      );
    };
  }
  //　週次表示時に特定の曜日を非表示にする
  // ===================================================== //

  return (
    <>
      <form
        style={{ width: resultSpan === "monthly" ? "100px" : "" }}
        className={classes.container}
        noValidate
      >
        <MuiPickersUtilsProvider utils={ExtendedUtils} locale={jaLocale}>
          <Grid container justify="space-around">
            <DatePicker
              views={["month"]}
              variant="inline"
              format="yyyy/MM"
              margin="normal"
              id="date-picker-inline"
              value={new Date(forecast_custom_span_end_time * 1000)}
              onChange={handleDateChange}
              autoOk={true}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </form>
    </>
  );
};

export default DateSpanSelectorEnd;
