import { Prefix, ForecastData } from "./types";

export const generateTableData = (params: {
  dataType: "business";
  forecastData?: ForecastData[];
}): {
  head: { value: string }[];
  body: {
    earning: {
      actual: { value: number; label: string }[];
      breakdown: { name: string; value: number; label: string }[][];
      target: { value: number; label: string }[];
      achievement: { value: number; label: string }[];
      difference: { value: number; label: string }[];
      comparison: { value: number; label: string }[];
    };
    cost: {
      actual: { value: number; label: string }[];
      breakdown: { name: string; value: number; label: string }[][];
      target: { value: number; label: string }[];
      achievement: { value: number; label: string }[];
      difference: { value: number; label: string }[];
      comparison: { value: number; label: string }[];
    };
    grossProfit: {
      actual: { value: number; label: string }[];
      target: { value: number; label: string }[];
      achievement: { value: number; label: string }[];
      difference: { value: number; label: string }[];
      comparison: { value: number; label: string }[];
    };
    managementCost: {
      actual: { value: number; label: string }[];
      breakdown: { name: string; value: number; label: string }[][];
      target: { value: number; label: string }[];
      achievement: { value: number; label: string }[];
      difference: { value: number; label: string }[];
      comparison: { value: number; label: string }[];
    };
    operatingProfit: {
      actual: { value: number; label: string }[];
      target: { value: number; label: string }[];
      achievement: { value: number; label: string }[];
      difference: { value: number; label: string }[];
      comparison: { value: number; label: string }[];
    };
  };
} => {
  const { dataType, forecastData } = params;

  //  事業計画 - PL
  if (dataType === "business" && forecastData) {
    const tableHead =
      forecastData.map(val => {
        const year = new Date(val.result_at.seconds * 1000).getFullYear();
        const month = new Date(val.result_at.seconds * 1000).getMonth() + 1;
        return {
          value: `${year}/${month}`
        };
      }) || [];

    //  売上
    const earning = () => {
      const actual = forecastData.map(val => {
        const key: keyof ForecastData = "revenue_total";
        const value = val[key] || 0;
        return { value, label: getLabelWithUnit(value, "revenue") };
      });

      const breakdown = () => {
        const array = new Array(forecastData[0]?.["revenue_detail"].length || 0).fill("");
        array.forEach((val, i) => {
          array[i] = forecastData.map(val => {
            const value = val["revenue_detail"][i].revenue_total || 0;
            return {
              name: val["revenue_detail"][i].name,
              value,
              label: getLabelWithUnit(value, "revenue")
            };
          });
        });

        return array;
      };

      const target = forecastData.map(val => {
        const key: keyof ForecastData = "revenue_objective";
        const value = val[key] || 0;
        return { value, label: getLabelWithUnit(value, "revenue") };
      });

      const achievement = forecastData.map(val => {
        const key: keyof ForecastData = "revenue_rate";
        const value = val[key] || 0;
        return {
          value,
          label: getLabelWithUnit(value, "revenue", {
            isPercent: true
          })
        };
      });

      const difference = forecastData.map(val => {
        const key: keyof ForecastData = "revenue_difference";
        const value = val[key] || 0;
        return { value, label: getLabelWithUnit(value, "revenue") };
      });

      //  昨対比
      const comparison = forecastData.map(val => {
        const key: keyof ForecastData = "revenue_last_year_comparison_rate";
        const value = val[key] || 0;
        return {
          value,
          label: getLabelWithUnit(value, "revenue", {
            isPercent: true
          })
        };
      });

      return {
        actual,
        breakdown: breakdown(),
        target,
        achievement,
        difference,
        comparison
      };
    };

    //  原価
    const cost = () => {
      //  実績
      const actual = forecastData.map(val => {
        const key: keyof ForecastData = "cost_unit_cost_total";
        const value = val[key] || 0;
        return { value, label: getLabelWithUnit(value, "revenue") };
      });

      //  内訳
      const breakdown = () => {
        const array = new Array(forecastData[0]?.["unit_cost_cat_2_array"].length || 0).fill("");
        array.forEach((val, i) => {
          array[i] = forecastData.map(val => {
            const value = val["unit_cost_cat_2_array"][i]["unit_cost_cat_2_total"] || 0;
            return {
              name: val["unit_cost_cat_2_array"][i]["unit_cost_cat_2_name"],
              value,
              label: getLabelWithUnit(value, "revenue")
            };
          });
        });

        return array;
      };

      //  目標
      const target = forecastData.map(val => {
        const key: keyof ForecastData = "cost_unit_cost_objective";
        const value = val[key] || 0;
        return { value, label: getLabelWithUnit(value, "revenue") };
      });

      //  達成率
      const achievement = forecastData.map(val => {
        const key: keyof ForecastData = "cost_unit_cost_rate";
        const value = val[key] || 0;
        return {
          value,
          label: getLabelWithUnit(value, "revenue", {
            isPercent: true
          })
        };
      });

      //  差異
      const difference = forecastData.map(val => {
        const key: keyof ForecastData = "cost_unit_cost_difference";
        const value = val[key] || 0;
        return { value, label: getLabelWithUnit(value, "revenue") };
      });

      //  昨対比
      const comparison = forecastData.map(val => {
        const key: keyof ForecastData = "cost_unit_cost_last_year_comparison_rate";
        const value = val[key] || 0;
        return {
          value,
          label: getLabelWithUnit(value, "revenue", {
            isPercent: true
          })
        };
      });

      return {
        actual,
        breakdown: breakdown(),
        target,
        achievement,
        difference,
        comparison
      };
    };

    //  売上総利益
    const grossProfit = () => {
      //  実績
      const actual = forecastData.map(val => {
        const key: keyof ForecastData = "profit_gross_profit_total";
        const value = val[key] || 0;
        return { value, label: getLabelWithUnit(value, "revenue") };
      });

      //  目標
      const target = forecastData.map(val => {
        const key: keyof ForecastData = "profit_gross_profit_objective";
        const value = val[key] || 0;
        return { value, label: getLabelWithUnit(value, "revenue") };
      });

      //  達成率
      const achievement = forecastData.map(val => {
        const key: keyof ForecastData = "profit_gross_profit_rate";
        const value = val[key] || 0;
        return {
          value,
          label: getLabelWithUnit(value, "revenue", {
            isPercent: true
          })
        };
      });

      //  差異
      const difference = forecastData.map(val => {
        const key: keyof ForecastData = "profit_gross_profit_difference";
        const value = val[key] || 0;
        return { value, label: getLabelWithUnit(value, "revenue") };
      });

      //  昨対比
      const comparison = forecastData.map(val => {
        const key: keyof ForecastData = "profit_gross_profit_last_year_comparison_rate";
        const value = val[key] || 0;
        return {
          value,
          label: getLabelWithUnit(value, "revenue", {
            isPercent: true
          })
        };
      });

      return {
        actual,
        target,
        achievement,
        difference,
        comparison
      };
    };

    //  販売管理費
    const managementCost = () => {
      //  実績
      const actual = forecastData.map(val => {
        const key: keyof ForecastData = "cost_svg_cost_total";
        const value = val[key] || 0;
        return { value, label: getLabelWithUnit(value, "revenue") };
      });

      //  内訳
      const breakdown = () => {
        const array = new Array(forecastData[0]?.["svg_cost_cat_2_array"].length || 0).fill("");
        array.forEach((val, i) => {
          array[i] = forecastData.map(val => {
            const value = val["svg_cost_cat_2_array"][i]["svg_cost_cat_2_total"] || 0;
            return {
              name: val["svg_cost_cat_2_array"][i]["svg_cost_cat_2_name"],
              value,
              label: getLabelWithUnit(value, "revenue")
            };
          });
        });

        return array;
      };

      //  目標
      const target = forecastData.map(val => {
        const key: keyof ForecastData = "cost_svg_cost_objective";
        const value = val[key] || 0;
        return { value, label: getLabelWithUnit(value, "revenue") };
      });

      //  達成率
      const achievement = forecastData.map(val => {
        const key: keyof ForecastData = "cost_svg_cost_rate";
        const value = val[key] || 0;
        return {
          value,
          label: getLabelWithUnit(value, "revenue", {
            isPercent: true
          })
        };
      });

      //  差異
      const difference = forecastData.map(val => {
        const key: keyof ForecastData = "cost_svg_cost_difference";
        const value = val[key] || 0;
        return { value, label: getLabelWithUnit(value, "revenue") };
      });

      //  昨対比
      const comparison = forecastData.map(val => {
        const key: keyof ForecastData = "cost_svg_cost_last_year_comparison_rate";
        const value = val[key] || 0;
        return {
          value,
          label: getLabelWithUnit(value, "revenue", {
            isPercent: true
          })
        };
      });

      return {
        actual,
        breakdown: breakdown(),
        target,
        achievement,
        difference,
        comparison
      };
    };

    /** 営業利益 */
    // const profit = forecastData.map((val) => {
    // 	const key: keyof ForecastData = "profit_operating_profit_total";
    // 	const value = val[key] || 0;
    // 	return { value, label: getLabelWithUnit(value, "revenue") };
    // });

    // 営業利益
    const operatingProfit = () => {
      //  実績
      const actual = forecastData.map(val => {
        const key: keyof ForecastData = "profit_operating_profit_total";
        const value = val[key] || 0;
        return { value, label: getLabelWithUnit(value, "revenue") };
      });

      //  目標
      const target = forecastData.map(val => {
        const key: keyof ForecastData = "profit_operating_profit_objective";
        const value = val[key] || 0;
        return { value, label: getLabelWithUnit(value, "revenue") };
      });

      //  達成率
      const achievement = forecastData.map(val => {
        const key: keyof ForecastData = "profit_operating_profit_rate";
        const value = val[key] || 0;
        return {
          value,
          label: getLabelWithUnit(value, "revenue", {
            isPercent: true
          })
        };
      });

      //  差異
      const difference = forecastData.map(val => {
        const key: keyof ForecastData = "profit_operating_profit_difference";
        const value = val[key] || 0;
        return { value, label: getLabelWithUnit(value, "revenue") };
      });

      //  昨対比
      const comparison = forecastData.map(val => {
        const key: keyof ForecastData = "profit_operating_profit_last_year_comparison_rate";
        const value = val[key] || 0;
        return {
          value,
          label: getLabelWithUnit(value, "revenue", {
            isPercent: true
          })
        };
      });

      return {
        actual,
        target,
        achievement,
        difference,
        comparison
      };
    };

    return {
      head: tableHead,
      body: {
        earning: earning(),
        cost: cost(),
        grossProfit: grossProfit(),
        managementCost: managementCost(),
        operatingProfit: operatingProfit()
      }
    };
  } else {
    return {
      head: [],
      body: {
        earning: {
          actual: [],
          breakdown: [],
          target: [],
          achievement: [],
          difference: [],
          comparison: []
        },
        cost: {
          actual: [],
          breakdown: [],
          target: [],
          achievement: [],
          difference: [],
          comparison: []
        },
        grossProfit: {
          actual: [],
          target: [],
          achievement: [],
          difference: [],
          comparison: []
        },
        managementCost: {
          actual: [],
          breakdown: [],
          target: [],
          achievement: [],
          difference: [],
          comparison: []
        },
        operatingProfit: {
          actual: [],
          target: [],
          achievement: [],
          difference: [],
          comparison: []
        }
      }
    };
  }
};

const getLabelWithUnit = (val: number, prefix?: Prefix, options?: { isPercent?: boolean }): string => {
  const isPercent = options?.isPercent;
  if (isPercent) return (val * 100)?.toFixed(1) + "%";

  switch (prefix) {
    case "revenue":
    case "mrr_total_value":
    case "cac":
    case "ltv":
    case "arpu":
    case "acv_cumulative":
    case "acv_new":
    case "cpa":
    case "revenue_churned":
      return `¥ ${Number(val.toFixed(0)).toLocaleString()}`;
    case "unit_economics":
    case "quick_ratio":
      return `${val.toFixed(1)}`;
    case "payback_period":
      return `${val.toFixed(1)} month`;
    case "churn_rate":
    case "grr":
    case "nrr":
      return `${(val * 100).toFixed(1)} %`;
    case "client_churned":
    case "new_customer":
    case "active_customer":
      return `${val.toFixed(0)} 社`;

    default:
      return `${val.toFixed(0)}`;
  }
};
