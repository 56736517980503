import React from "react";
import styled from "styled-components";
import { colorSwitcherRate } from "../../Util/colorSwitcher";
import { unitSwitcher } from "../../Util/unitSwitcher";
import reached_arrow from "../../../images/icons/reached_arrow.svg";
import unreached_arrow from "../../../images/icons/unreached_icon.svg";

interface FunnelSummaryHeaderProps {
  funnel_summary_array: any;
}

const FunnelSummaryHeader: React.FC<FunnelSummaryHeaderProps> = props => {
  const { funnel_summary_array } = props;

  const funnel_name_array = ["リード獲得", "アポ獲得", "商談化", "受注"];

  return (
    <FunnelSummaryHeaderSC>
      {/* ファネル名ごとのサマリー表示 */}

      {funnel_name_array.map(fn => (
        <FunnelSummaryHeaderItem>
          <SummaryContent>
            <SummaryLabel>{fn}</SummaryLabel>

            {/* 直近の実績データ */}
            <FunnelSummaryHeaderItemHeading
              className={
                funnel_summary_array.some((f: any) => f.funnel_name === fn) &&
                colorSwitcherRate(
                  "lead_count",
                  funnel_summary_array.find((f: any) => f.funnel_name === fn)
                    .lead_new_count_total
                )
              }
            >
              {funnel_summary_array.some((f: any) => f.funnel_name === fn)
                ? unitSwitcher(
                    funnel_summary_array.find((f: any) => f.funnel_name === fn)
                      .lead_new_count_total,
                    "lead_count"
                  )
                : "データが不足しています"}
            </FunnelSummaryHeaderItemHeading>

            {/* 目標比セクション */}
            <FunnelSummaryHeaderItemDetail>
              {/* ラベル */}
              <FunnelSummaryHeaderItemDetailLabel
                className={
                  funnel_summary_array.some((f: any) => f.funnel_name === fn) &&
                  colorSwitcherRate(
                    "lead_count",
                    funnel_summary_array.find((f: any) => f.funnel_name === fn)
                      .lead_new_count_rate
                  )
                }
              >
                目標比
              </FunnelSummaryHeaderItemDetailLabel>
              {/* 目標比 割合 */}
              <FunnelSummaryHeaderItemDetailPercentage
                className={
                  funnel_summary_array.some((f: any) => f.funnel_name === fn) &&
                  colorSwitcherRate(
                    "lead_count",
                    funnel_summary_array.find((f: any) => f.funnel_name === fn)
                      .lead_new_count_rate
                  )
                }
              >
                {funnel_summary_array.some((f: any) => f.funnel_name === fn)
                  ? unitSwitcher(
                      funnel_summary_array.find(
                        (f: any) => f.funnel_name === fn
                      ).lead_new_count_rate,
                      "lead_count_rate"
                    )
                  : "データが不足しています"}
              </FunnelSummaryHeaderItemDetailPercentage>

              {/* 目標比 差異 */}
              <FunnelSummaryHeaderItemDetailDifference
                className={
                  funnel_summary_array.some((f: any) => f.funnel_name === fn) &&
                  colorSwitcherRate(
                    "lead_count",
                    funnel_summary_array.find((f: any) => f.funnel_name === fn)
                      .lead_new_count_difference
                  )
                }
              >
                （
                {funnel_summary_array.some((f: any) => f.funnel_name === fn)
                  ? unitSwitcher(
                      funnel_summary_array.find(
                        (f: any) => f.funnel_name === fn
                      ).lead_new_count_difference,
                      "lead_count"
                    )
                  : "データが不足しています"}
                ）
              </FunnelSummaryHeaderItemDetailDifference>
            </FunnelSummaryHeaderItemDetail>
          </SummaryContent>
        </FunnelSummaryHeaderItem>
      ))}
    </FunnelSummaryHeaderSC>
  );
};

export default FunnelSummaryHeader;

const FunnelSummaryHeaderSC = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  background: #f4f9ff;
  padding: 25px 0;
`;

const FunnelSummaryHeaderItem = styled.div`
  /* width: calc(25% - 3px); */
  width: 25%;
  background-color: #f4f9ff;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e2e9f3;
  &:last-child {
    border-right: none;
  }
`;

const SummaryContent = styled.div`
  display: flex;
  flex-direction: column;
  &:first-of-type {
    /* margin-right: 16px; */
  }
  align-items: center;
`;

const SummaryLabel = styled.p`
  color: #666666;
  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
  align-items: center;
  margin-bottom: 14px;
`;

// FunnelSummaryHeaderItemHeading
const FunnelSummaryHeaderItemHeading = styled.p`
  font-weight: 600;
  font-size: 32px;
  color: #35455d;
  margin-bottom: 14px;
  /* &.reached {
    color: #10b6db;
  }
  &.unreached {
    color: #da1948;
  }
  &.equal {
    color: #35455d;
  } */
`;

const FunnelSummaryHeaderItemDetail = styled.div`
  text-align: center;
`;
const FunnelSummaryHeaderItemDetailLabel = styled.span`
  font-weight: 600;
  color: #666666;
  font-size: 12px;
  font-weight: 600;
  margin-right: 8px;
  &.reached {
    margin-right: 26px;
  }
  &.unreached {
    margin-right: 26px;
  }
  &.equal {
  }
`;
const FunnelSummaryHeaderItemDetailPercentage = styled.span`
  position: relative;
  font-weight: 600;
  color: #35455d;
  font-size: 12px;
  font-weight: 600;
  margin-right: 5px;

  &::before {
    position: absolute;
    top: 2.5px;
    left: -14px;
    content: "";
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 12px;
    height: 11px;
  }

  &.reached {
    color: #10b6db;
    &::before {
      background-image: url(${reached_arrow});
    }
  }
  &.unreached {
    color: #da1948;
    &::before {
      background-image: url(${unreached_arrow});
    }
  }
  &.equal {
    color: #35455d;
  }
`;

const FunnelSummaryHeaderItemDetailDifference = styled.span`
  font-weight: 600;
  color: #35455d;
  font-size: 12px;
  font-weight: 600;
  &.reached {
    color: #10b6db;
  }
  &.unreached {
    color: #da1948;
  }
  &.equal {
    color: #35455d;
  }
`;
