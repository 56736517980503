const START_TARGET_DATA_SEQUENCE = "START_TARGET_DATA_SEQUENCE";
const SET_TARGET_DATA_SUCCEEDED = "SET_TARGET_DATA_SUCCEEDED";
const SET_TARGET_DATA_FAILED = "SET_TARGET_DATA_FAILED";

const UPDATE_TARGET_DATA = "UPDATE_TARGET_DATA";
const SET_DATA_KEYS = "SET_DATA_KEYS";

const SELECT_SPAN = "SELECT_SPAN";

const GET_TARGET_DATA_FOR_SPAN_COMPARISON_REQUEST = "GET_TARGET_DATA_FOR_SPAN_COMPARISON_REQUEST";
const GET_TARGET_DATA_FOR_SPAN_COMPARISON_SUCCEEDED = "GET_TARGET_DATA_FOR_SPAN_COMPARISON_SUCCEEDED";
const GET_TARGET_DATA_FOR_SPAN_COMPARISON_FAILED = "GET_TARGET_DATA_FOR_SPAN_COMPARISON_FAILED";

const GET_TARGET_DATA_FOR_VARIANT_FILTER_REQUEST = "GET_TARGET_DATA_FOR_VARIANT_FILTER_REQUEST";
const GET_TARGET_DATA_FOR_VARIANT_FILTER_SUCCEEDED = "GET_TARGET_DATA_FOR_VARIANT_FILTER_SUCCEEDED";
const GET_TARGET_DATA_FOR_VARIANT_FILTER_FAILED = "GET_TARGET_DATA_FOR_VARIANT_FILTER_FAILED";

const CLEAR_TARGET_DATA_FOR_SPAN_COMPARISON = "CLEAR_TARGET_DATA_FOR_SPAN_COMPARISON";

const LOGOUT_REQUESTED = "LOGOUT_REQUESTED";

export { START_TARGET_DATA_SEQUENCE, SET_TARGET_DATA_SUCCEEDED, SET_TARGET_DATA_FAILED, UPDATE_TARGET_DATA, SET_DATA_KEYS, SELECT_SPAN, GET_TARGET_DATA_FOR_SPAN_COMPARISON_REQUEST, GET_TARGET_DATA_FOR_SPAN_COMPARISON_SUCCEEDED, GET_TARGET_DATA_FOR_SPAN_COMPARISON_FAILED, GET_TARGET_DATA_FOR_VARIANT_FILTER_REQUEST, GET_TARGET_DATA_FOR_VARIANT_FILTER_SUCCEEDED, GET_TARGET_DATA_FOR_VARIANT_FILTER_FAILED, CLEAR_TARGET_DATA_FOR_SPAN_COMPARISON, LOGOUT_REQUESTED };
