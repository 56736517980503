// import update from "immutability-helper";
import { UPDATE_DISPALY_TYPE } from "./types";
import * as GraphActinos from "./actions";
import { RESET_ALL } from "../common/types";

interface StateProps {
	displayType: "line" | "bar";
}
const initialState: StateProps = {
	displayType: "bar",
};

const reducer = (state: StateProps = initialState, action: GraphActinos.ActionProps) => {
	switch (action.type) {
    case UPDATE_DISPALY_TYPE:
      return {
        ...state,
        displayType : action.payload.displayType
      }

		default:
			return state;
	}
};

export default reducer;
