import rsf from "../../rsf"
import firebase from "firebase"
import { call } from "redux-saga/effects"

const db = firebase.firestore()
function* fetchComments(company_id, resultSpan, from, to) {
  try {
    console.log("fetching comments from :", from)
    console.log("fetching comments to :", to)
    const snapshot = yield call(rsf.firestore.getCollection, db.collection(`companies/${company_id}/comments`).where("result_span", "==", resultSpan).where("result_at", ">=", firebase.firestore.Timestamp.fromDate(from)).where("result_at", "<=", firebase.firestore.Timestamp.fromDate(to)))

    let comments = []
    let docIds = []
    snapshot.forEach((comment) => {
      comments = [...comments, { ...comment.data(), docId: comment.id }]
      docIds = [...docIds, comment.id] // ドキュメントIDの配列
    })
    console.log('fetched comments', comments);
    return comments
  } catch (e) {
    console.log("error", e.message)
  }
}

export default fetchComments;