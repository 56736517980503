const GET_COHORT_REQUESTED = 'GET_COHORT_REQUESTED'
const GET_COHORT_SUCCEEDED = 'GET_COHORT_SUCCEEDED'
const GET_COHORT_FAILED = 'GET_COHORT_FAILED'

const UPDATE_BASIC_VALUE = 'UPDATE_BASIC_VALUE';
const UPDATE_SELECT_TYPE = "UPDATE_SELECT_TYPE";
const UPDATE_CHURN_RATE_MEDIAN = "UPDATE_CHURN_RATE_MEDIAN";

const SET_START_MONTH = 'SET_START_MONTH'
const SET_TARGET_MONTH = 'SET_TARGET_MONTH'
const SET_TARGET_SPAN = 'SET_TARGET_SPAN'
const SET_BASIC_VALUES = "SET_BASIC_VALUES"
const SET_CHURN_RATE = "SET_CHURN_RATE"
const SET_CLIENT_CHURNED = "SET_CLIENT_CHURNED"
const SET_REVENUE_CHURNED = "SET_REVENUE_CHURNED"


const GET_FILTERED_COHORT_REQUEDSTED = 'GET_FILTERED_COHORT_REQUEDSTED'
const GET_FILTERED_COHORT_SUCCEEDED = 'GET_FILTERED_COHORT_SUCCEEDED'
const GET_FILTERED_COHORT_FAILED = 'GET_FILTERED_COHORT_FAILED'


export { GET_COHORT_REQUESTED, GET_COHORT_SUCCEEDED, GET_COHORT_FAILED, UPDATE_BASIC_VALUE, UPDATE_SELECT_TYPE ,UPDATE_CHURN_RATE_MEDIAN, SET_TARGET_SPAN, SET_START_MONTH, SET_TARGET_MONTH,SET_BASIC_VALUES, GET_FILTERED_COHORT_REQUEDSTED, GET_FILTERED_COHORT_SUCCEEDED, GET_FILTERED_COHORT_FAILED,SET_CLIENT_CHURNED,SET_REVENUE_CHURNED,SET_CHURN_RATE }