import React, { useState, useEffect } from "react"
import { Link, useHistory } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import GlobalNav from "../../Layout/GlobalNav"
import PageHeaderRaw from "../../Layout/PageHeader"
import ConfigNavCard from "../ConfigNavCard"

import { addMemberRequest } from "../../../reducks/members/actions"
import { useLocation } from "react-router-dom";

const AddMember: React.FC = () => {

  const location = useLocation();
	const pathname = location.pathname;
  // GA計測タグ
	useEffect(() => {
		document.title = 'メンバー追加'; // ページタイトルを変更
		window.gtag('config', 'G-YXXD65BEJ0', {
			'page_path': pathname // 任意のパス（あるいはprops.location.pathnameなど）
		});
	});

  const classes = useStyles()
  let history = useHistory()

  const [lastName, setLastName] = useState("")
  const [firstName, setFirstName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const company_id = useSelector((state: any) => state.user.company_id)
  const post_status = useSelector((state: any) => state.members.member_to_add.post_status)
  const error = useSelector((state: any) => state.members.member_to_add.error)

  const dispatch = useDispatch()

  const { register, handleSubmit, errors } = useForm()

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value)
  }
  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value)
  }
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  const handleAddMember = () => {
    dispatch(addMemberRequest(firstName, lastName, email, password, company_id))
    // history.push("/config/member-setting")
  }

  return (
    <>
      { post_status ?
      history.push("/config/member-setting")
      :
    <Container>
      <GlobalNav />

      <main className={classes.content}>
        <PageHeaderRaw page_title="管理" />

        <div className={classes.configCard}>
          <ConfigNavCard />

          <MemberContent>
            <MemberTitle>メンバーを追加する</MemberTitle>
            <p>{error ? error : ''}</p>

            <Form onSubmit={handleSubmit(handleAddMember)}>
              <Line>
                <label htmlFor="lastName">
                  <span>【必須】</span>姓
                </label>
                <input type="text" name="lastName" ref={register({ required: true })} onChange={handleLastNameChange} />
                {errors.lastName && <ErrorText>必須項目です</ErrorText>}
              </Line>

              <Line>
                <label htmlFor="firstName">
                  <span>【必須】</span>名
                </label>
                <input type="text" name="firstName" ref={register({ required: true })} onChange={handleFirstNameChange} />
                {errors.firstName && <ErrorText>必須項目です</ErrorText>}
              </Line>

              <Line>
                <label htmlFor="email">
                  <span>【必須】</span>メールアドレス
                </label>
                <input type="email" name="email" ref={register({ required: true })} onChange={handleEmailChange} />
                {errors.email && <ErrorText>必須項目です</ErrorText>}
              </Line>

              <Line>
                <label htmlFor="password">
                  <span>【必須】</span>パスワード
                </label>
                <input type="password" name="password" ref={register({ required: true })} onChange={handlePasswordChange} />
                {errors.password && <ErrorText>必須項目です</ErrorText>}
              </Line>

              <ButtonBlock>
                <Button className="cancel">
                  <Link to="/config/member-setting">キャンセル</Link>
                </Button>
                <Button className="send" type="submit">
                  追加
                </Button>
              </ButtonBlock>
            </Form>
          </MemberContent>
        </div>
      </main>
    </Container>
    }
    </>
  )
}

export default AddMember

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      backgroundColor: "#F4F4F4",
    },
    configCard: {
      display: "flex",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  })
)

// Container
const Container = styled.div`
  display: flex;
`

// MemberContent
const MemberContent = styled.div`
  width: 100%;
  height: 100vh;
  padding: 33px 40px;
  box-sizing: border-box;
  background-color: #fff;
`

// MemberTitle
const MemberTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  line-height: 27px;
  margin: 0 0 28px 0;
`

// Form
const Form = styled.form`
  max-width: 570px;
  width: 100%;
`

// Line
const Line = styled.div`
  margin: 0 0 24px 0;
  padding-left: 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  label {
    color: #435878;
    margin-right: 34px;
    display: inline-block;

    span {
      font-size: 14px;
      font-weight: bold;
      color: #af002a;
    }
  }
  input {
    font-size: 14px;
    line-height: 21px;
    color: #283f46;
    width: 100%;
    max-width: 296px;
    border: 1px solid #c3cedf;
    border-radius: 4px;
    padding: 12px 21px;
    box-sizing: border-box;
  }
`

// ButtonBlock
const ButtonBlock = styled.div`
  max-width: 570px;
  width: 100%;
  padding-top: 30px;
  display: flex;
  justify-content: flex-end;
`
// Button
const Button = styled.button`
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
  color: #fff;
  text-align: center;
  max-width: 152px;
  width: 100%;
  height: 36px;
  padding: 9px 10px 10px 10px;
  display: inline-block;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  appearance: none;
  outline: none;
  a {
    color: #435878;
    text-decoration: none;
    display: block;
  }
  &.send {
    background-color: #0066ff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
  }
  &:hover {
    opacity: 0.6;
  }
`

const ErrorText = styled.p`
  font-size: 12px;
  color: #af002a;
  position: absolute;
  top: 50px;
  left: 288px;
`
