import { SET_COMPANY, SET_COMPANY_FROM_TO, UPDATE_MOVING_AVERAGE_SUCCEEDED,
  UPDATE_MOVING_AVERAGE_FAILED, UPDATE_WEEK_START_DATE_SUCCEEDED, UPDATE_WEEK_START_DATE_FAILED,
UPDATE_CLOSING_MONTH_SUCCEEDED, UPDATE_CLOSING_MONTH_FAILED, UPDATE_SPREADSHEET_SYNC } from "./types"
import {RESET_ALL} from "../common/types";

const initialState = {
  config: {
    monthly_from_month: "",
    monthly_to_month: "",
    weekly_from_date: "",
    weekly_to_date: "",
    daily_from_date: "",
    daily_to_date: "",
    weekly_start_day: "Mon",
    moving_average: "3ヶ月",
    google_spreadsheet_import_time: "05:00",
    google_spreadsheet_import_master: false,
    google_spreadsheet_import_actual: false,
  },
  closing_month:"03",
}

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_ALL:
      return initialState;

    case SET_COMPANY:
      return { ...action.company }

    case SET_COMPANY_FROM_TO:
      if (action.resultSpan === "monthly") {
        return {
          ...state,
          config: {
            ...state.config,
            monthly_from_month: action.from,
            monthly_to_month: action.to,
          },
        }
      } else if (action.resultSpan === "weekly") {
        return {
          ...state,
          config: {
            ...state.config,
            weekly_from_date: action.from,
            weekly_to_date: action.to,
          },
        }
      } else {
        return {
          ...state,
          config: {
            ...state.config,
            daily_from_date: action.from,
            daily_to_date: action.to,
          },
        }
      }

    case UPDATE_MOVING_AVERAGE_SUCCEEDED:
      return {
        ...state,
        config: {
          ...state.config,
          moving_average: action.moving_average
        }
      }

    case UPDATE_MOVING_AVERAGE_FAILED:
      return state;

    case UPDATE_WEEK_START_DATE_SUCCEEDED:
      return {
        ...state,
        config: {
          ...state.config,
          weekly_start_day: action.weekly_start_day
        }
      };

    case UPDATE_WEEK_START_DATE_FAILED:
      return state;
    
    case UPDATE_CLOSING_MONTH_SUCCEEDED:
      return {
        ...state,
        closing_month: action.closing_month,
      };
      
    case UPDATE_CLOSING_MONTH_FAILED:
      return state;

    case UPDATE_SPREADSHEET_SYNC:
      return {
        ...state,
        config: {
          ...state.config,
          google_spreadsheet_import_time: action.google_spreadsheet_import_time,
          google_spreadsheet_import_master: action.google_spreadsheet_import_master,
          google_spreadsheet_import_actual: action.google_spreadsheet_import_actual,
        }
      }

    default:
      return state
  }
}

export default companyReducer
