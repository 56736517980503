import { unitSwitcher } from "../../Util/unitSwitcher";
import { FunnelData, GraphData } from "./types";

export const generateTableData = (params: {
  target_funnel_name: string;
  funnelData?: FunnelData[];
  graphData?: GraphData[];
}): {
  head: { value: string }[];
  body: {
    target: { value: number; label: string }[];
    actual: { value: number; label: string }[];
    achievement: { value: number; label: string }[];
    difference: { value: number; label: string }[];
    comparison: { value: number; label: string }[];
    movingTarget: { value: number; label: string }[];
    movingActual: { value: number; label: string }[];
    movingAchievement: { value: number; label: string }[];
    movingDifference: { value: number; label: string }[];
    movingComparison: { value: number; label: string }[];
    transitionRate?: { value: number; label: string }[];
    movingTransitionRate?: { value: number; label: string }[];
    leadTime?: { value: number; label: string }[];
    breakdown?: {
      name: string;
      data: { value: number; label: string }[];
    }[];
    averageBreakdown?: {
      name: string;
      data: { value: number; label: string }[];
    }[];
  };
} => {
  const { graphData } = params;
  //  ファネル - リード獲得 , アポ獲得 , 商談化 , 受注
  if (graphData) {
    const tableHead =
      graphData.map(val => {
        const year = new Date(val.date.seconds * 1000).getFullYear();
        const month = new Date(val.date.seconds * 1000).getMonth() + 1;
        return {
          value: `${year}/${month}`
        };
      }) || [];

    // 実績 - 目標
    const target =
      graphData.map(val => {
        const key = `lead_new_count_objective` as keyof GraphData;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value)
        };
      }) || [];

    // 実績 - 実績
    const actual =
      graphData.map(val => {
        const key = `lead_new_count_total` as keyof GraphData;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value)
        };
      }) || [];

    // 実績 - 達成率
    const achievement =
      graphData.map(val => {
        const key = `lead_new_count_rate` as keyof GraphData;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value, { isPercent: true })
        };
      }) || [];

    // 実績 - 差異
    const difference =
      graphData.map(val => {
        const key = `lead_new_count_difference` as keyof GraphData;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value)
        };
      }) || [];

    // 実績 - 昨対比
    const comparison =
      graphData.map(val => {
        const key =
          `lead_new_count_last_year_comparison_rate` as keyof GraphData;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value, { isPercent: true })
        };
      }) || [];

    // 移動平均 - 目標
    const movingTarget =
      graphData.map(val => {
        const key = `lead_new_count_moving_average_objective`;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value)
        };
      }) || [];

    // 移動平均 - 実績
    const movingActual =
      graphData.map(val => {
        const key = `lead_new_count_moving_average_total`;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value)
        };
      }) || [];

    // 移動平均 - 達成率
    const movingAchievement =
      graphData.map(val => {
        const key = `lead_new_count_moving_average_rate`;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value, { isPercent: true })
        };
      }) || [];

    // 移動平均 - 差異
    const movingDifference =
      graphData.map(val => {
        const key = `lead_new_count_moving_average_difference`;
        const value = val[key] as number;
        return {
          value,
          label: getLabelWithUnit(value)
        };
      }) || [];

    //  移動平均 - 昨対比
    const movingComparison =
      graphData.map(val => {
        return { value: 0, label: "No Data" };
      }) || [];

    return {
      head: tableHead,
      body: {
        target,
        actual,
        achievement,
        difference,
        comparison,
        movingTarget,
        movingActual,
        movingAchievement,
        movingDifference,
        movingComparison
      }
    };
  } else {
    return {
      head: [],
      body: {
        target: [],
        actual: [],
        achievement: [],
        difference: [],
        comparison: [],
        movingTarget: [],
        movingActual: [],
        movingAchievement: [],
        movingDifference: [],
        movingComparison: []
      }
    };
  }
};

const getLabelWithUnit = (
  val: number,
  options?: { isPercent?: boolean }
): string => {
  const isPercent = options?.isPercent;
  if (isPercent) return (val * 100)?.toFixed(1) + "%";

  return unitSwitcher(val, "lead_count");
};
