import React from "react";
import { useSelector, useDispatch } from "react-redux";
import CommentBalloon from "./CommentBalloon";
import styled from "styled-components";
import balloonWhite from "../../images/icons/comment_white.svg";
import balloonRed from "../../images/icons/comment_red.svg";
import addCommentIcon from '../../images/icons/add_blue.svg';
import { toggleAddCommentWindow, toggleCommentsWindowDisplay } from '../../reducks/comments/actions';

export default function CommentTick(props: any) {
  const dispatch = useDispatch();
  const resultSpan = useSelector((state: any) => state.target_data.resultSpan);
  const { x, y, stroke, payload, comments, target_data_name } = props;
  const target_comments = (comments && comments.length > 0) ? comments.filter((comment: any) => comment.result_at.seconds === payload.value) : [];

  const user_name = useSelector((state: any) => state.user.name)
  const company_id = useSelector((state: any) => state.user.company_id)
  const selected_data_config_data_type = useSelector((state: any) => state.raw_data.selected_data_config.data_type)
  const selected_data_config_data_span = useSelector((state: any) => state.raw_data.selected_data_config.data_span)
  const custom_span_start_time = useSelector((state: any) => state.raw_data.selected_data_config.custom_span_start_time)
  const custom_span_end_time = useSelector((state: any) => state.raw_data.selected_data_config.custom_span_end_time)

  const commentAddWindowDispaly = useSelector((state: any) => state.comments.commentAddWindow.display)

  const commentsWindowDisplayed = useSelector((state: any) => state.comments.commentsWindow.display)

  // 月次の場合は月まで、週次・日次の場合は日まで表示
  const dt = resultSpan !== "monthly" ? new Date(payload.value * 1000).getFullYear() + "/" + (new Date(payload.value * 1000).getMonth() + 1) + "/" + new Date(payload.value * 1000).getDate() : new Date(payload.value * 1000).getFullYear() + "/" + (new Date(payload.value * 1000).getMonth() + 1);

  const handleAddCommentIconClick = () => {
    dispatch(
      toggleAddCommentWindow(
        commentAddWindowDispaly,
        payload.value,
        target_data_name,
        company_id,
        user_name,
        selected_data_config_data_type,
        selected_data_config_data_span,
        custom_span_start_time,
        custom_span_end_time,
      )
    )
  }

  return (
    <>
      {target_comments.length > 0 ? (
        <StyledSVG
          transform={`translate(${x + 20},${y})`}
        >
          <text x={0} y={0} dy={10} textAnchor="end" fontSize="10px" fill="#435878">
            {dt}
          </text>
          <image x={-35} y={-32} href={balloonWhite} className="StyledBalloonWhite"></image>
          <image x={-35} y={-32} href={balloonRed} className="StyledBalloonRed" onClick={() => dispatch(toggleCommentsWindowDisplay(commentsWindowDisplayed, payload.value))}></image>
          <StyledText x={-22} y={-13} className="StyledText" onClick={() => dispatch(toggleCommentsWindowDisplay(commentsWindowDisplayed, payload.value))}>
            {target_comments.length}
          </StyledText>
          <image
            x={-27}
            y={12}
            href={addCommentIcon}
            className="StyledAddCommentIcon"
            onClick={handleAddCommentIconClick}
          ></image>
        </StyledSVG>
      ) : (
        <>
          <StyledSVG
            transform={`translate(${x + 20},${y})`}
          >
            <text x={0} y={0} dy={10} textAnchor="end" fontSize="10px" fill="#435878">
              {dt}
            </text>
            <image
              x={-24}
              y={12}
              href={addCommentIcon}
              className="StyledAddCommentIcon"
              onClick={handleAddCommentIconClick}
            ></image>
          </StyledSVG>
        </>
      )}
    </>
  );
}

const StyledSVG = styled.g`
  cursor: pointer;

  position: relative;

  & .StyledBalloonRed {
    display: none;
    cursor: pointer;
  }

  & .StyledBalloonWhite {
    display: block;
  }

  & .StyledAddCommentIcon {
    display: none;
    cursor: pointer;
  }

  &:hover {
    & .StyledText {
      fill: #fff;
    }
    & .StyledBalloonRed {
      display: block;
    }
    & .StyledBalloonWhite {
      display: none;
    }
    & .StyledAddCommentIcon {
      display: block;
    }
  }
`;

const StyledText = styled.text`
  position: relative;
  width: 32px;
  height: 32px;
  fill: #cd0031;
  display: block;
  font-size: 10px;
  font-weight: bold;
  z-index: 1;
  &::after {
    content: "";
    position: absolute;
    width: 32px;
    height: 32px;
    background-size: cover;
    background-position: center;
    background-image: url(${balloonWhite});
  }
`;

const StyledBalloonRed = styled.image`
  display: none;
`;
const StyledBalloonWhite = styled.image`
  display: none;
`;

const Balloon = styled.button`
  &.commentBalloon {
    display: block;
    background-image: url(${balloonWhite});
    background-size: cover;
    position: absolute;
    top: -35px;
    right: 0;
    left: 0;
    width: 32px;
    height: 32px;
    margin: 0 auto;
    text-align: center;
    z-index: 2;
    /* 数字 */
    span {
      color: #af002a;
      font-size: 10px;
      font-weight: bold;
      position: relative;
      top: -3px;
    }

    /* hover時 */
    &:hover {
      background-image: url(${balloonRed});
      span {
        color: #ffffff;
      }
    }
  }
`;
