//  lib components
import { TableBody, TableHead, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import color from "../../style/color";
import { unitSwitcher } from "../../Util/unitSwitcher";
import { dateFormatter } from "../../Util/DateFormatter";
//  styled components
import {
  CellContainer,
  CellText,
  StickerHeadCell,
  StickyCell,
  StickyRow,
  StyledCell,
  StyledTable,
  StyledTableRow,
  Wrapper
} from "./SpanComparisonStyles";

interface SpanComparisonTableProps {
  resultSpan: string;
}

const SpanComparisonTable: React.FC<SpanComparisonTableProps> = ({
  resultSpan
}) => {
  const target_data_name = useSelector(
    (state: any) => state.target_data.target_data_name
  );

  // const target_data = useSelector((state: any) => state.results.target_data);
  const target_data = useSelector(
    (state: any) => state.target_data.target_data
  );

  let spanComparisonData: [];
  const target_data_first_span = useSelector(
    (state: any) => state.target_data.target_data_first_span
  );
  const first_span_start = useSelector(
    (state: any) => state.target_data.first_span_start
  );
  const first_span_end = useSelector(
    (state: any) => state.target_data.first_span_end
  );
  const target_data_second_span = useSelector(
    (state: any) => state.target_data.target_data_second_span
  );
  const second_span_start = useSelector(
    (state: any) => state.target_data.second_span_start
  );
  const second_span_end = useSelector(
    (state: any) => state.target_data.second_span_end
  );

  spanComparisonData = target_data_first_span.map(
    (data: any, index: number) => {
      return { ...data, ...target_data_second_span[index] };
    }
  );

  const data_divider = ([...array], size: number) => {
    return spanComparisonData.reduce(
      (acc: any, value: number, index: number) =>
        index % size ? acc : [...acc, array.slice(index, index + size)],
      []
    );
  };

  const [width, setWidth] = useState(window.innerWidth);

  const getWindowSize = () => {
    setWidth(window.innerWidth);
    return width;
  };

  window.onresize = getWindowSize;

  useEffect(() => {
    setWidth(window.innerWidth);
  }, [width]);

  const table_width = width - 630;
  const table_body_width = table_width - 200;
  const cell_quantity = Math.floor(table_body_width / 120);
  const divided_data = data_divider(
    spanComparisonData,
    spanComparisonData.length
  );
  console.log("divided_data", divided_data);

  return (
    <React.Fragment>
      <Wrapper>
        {/* 【 実績 】 目標・実績・達成率 */}
        <StyledTable>
          {/* Head */}
          <TableHead>
            <TableRow>
              <StickerHeadCell style={{ zIndex: 1 }} />
              {spanComparisonData.map((val: any, i: number) => (
                <StickerHeadCell key={`i_${i}`}>
                  {dateFormatter(
                    resultSpan,
                    val.first_result_at.seconds * 1000
                  )}
                </StickerHeadCell>
              ))}
            </TableRow>
          </TableHead>

          {/* Body */}
          <TableBody>
            {/* label */}
            <StickyRow>
              {/* <PlanName color={divided_data.color}>{divided_data.name}</PlanName> */}
            </StickyRow>

            {/* 期間1 実績 */}
            <StyledTableRow>
              <StickyCell style={{ fontWeight: "bolder" }}>
                <CellContainer>
                  <CellText style={{ fontWeight: "bold" }}>
                    <Bullet span="first">期間1</Bullet>
                  </CellText>
                </CellContainer>
              </StickyCell>
              {spanComparisonData.map((d: any, ind: number) => (
                <StyledCell key={ind} style={{ fontWeight: "bolder" }}>
                  {d && unitSwitcher(d.first_key_total, target_data_name)}
                </StyledCell>
              ))}
            </StyledTableRow>

            {/* 期間2 実績 */}
            <StyledTableRow>
              <StickyCell style={{ fontWeight: "bolder" }}>
                <CellContainer>
                  <CellText style={{ fontWeight: "bold" }}>
                    <Bullet span="second">期間2</Bullet>
                  </CellText>
                </CellContainer>
              </StickyCell>
              {spanComparisonData.map((d: any, ind: number) => (
                <StyledCell key={ind} style={{ fontWeight: "bolder" }}>
                  {d && unitSwitcher(d.second_key_total, target_data_name)}
                </StyledCell>
              ))}
            </StyledTableRow>
          </TableBody>
        </StyledTable>
      </Wrapper>
    </React.Fragment>
  );
};

export default SpanComparisonTable;

type BulletType = {
  span: "first" | "second";
};
const Bullet = styled.span<BulletType>`
  &::before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 8px;
    border-radius: 50%;
    background-color: ${props =>
      props.span === "first" ? "#0066FF" : "#E53E66"};
  }
`;
