import { forecastErrorEscaper } from "../../components/Util/dataErrorEscaper";

const targetForecastIntegratedArrayDetailReal = (revenue_detail_results: any, cost_unit_cost_detail_results: any, cost_svg_cost_detail_results: any, selected_data_config_data_type: string, raw_data_array_real: any) => {
  const revenue_detail_results_forForecast = revenue_detail_results.map((r: any) => r.list);
  // unit_costのcategory_2、category_3をオブジェクト化したリスト
  // 原価カテゴリーリスト作成
  const unit_cost_name_list_forForecast = cost_unit_cost_detail_results.map((item: any) => {
    return {
      category_2: item.category_2,
      category_3: item.category_3
    };
  });

  // unit_costのcategory_2のリスト
  // 原価カテゴリーリストから重複のない文字列リスト作成
  const pure_unit_cost_name_list_forForecast = 
    unit_cost_name_list_forForecast.filter((element: any, index: any, self: any) => 
    self.findIndex((e: any) => 
      e.category_2 === element.category_2 
      && 
      e.category_3 === element.category_3
    ) === index);

  const unit_cost_name_big_list_forForecast = 
    cost_unit_cost_detail_results.map((item: any) => item.category_2);

  const pure_unit_cost_name_big_list_forForecast = [
    ...new Set(unit_cost_name_big_list_forForecast)
  ];

  // svg_costのcategory_2、category_3をオブジェクト化したリスト
  // 販売管理費カテゴリーリスト作成
  const svg_cost_name_list_forForecast = cost_svg_cost_detail_results.map((item: any) => {
    return {
      category_2: item.category_2,
      category_3: item.category_3
    };
  });

  // svg_costのcategory_2のリスト
  const svg_cost_name_big_list_forForecast = cost_svg_cost_detail_results.map((item: any) => item.category_2);
  const pure_svg_cost_name_big_list_forForecast = [
    ...new Set(svg_cost_name_big_list_forForecast)
  ];

  // 販売管理費カテゴリーリストから重複のない文字列リスト作成
  const pure_svg_cost_name_list_forForecast = 
    svg_cost_name_list_forForecast.filter((element: any, index: any, self: any) => 
      self.findIndex((e: any) => 
        e.category_2 === element.category_2 
        && 
        e.category_3 === element.category_3
      ) === index);

  // 原価の対象データ
  const cost_unit_cost_detail_results_forForecast = cost_unit_cost_detail_results.filter((r: any) => r.resultSpan === selected_data_config_data_type);

  // 販管費の対象データ
  const cost_svg_cost_detail_results_forForecast = cost_svg_cost_detail_results.filter((r: any) => r.resultSpan === selected_data_config_data_type);

  const dataSvgCostListToIntegrate_forForecast = raw_data_array_real
    .flatMap((rd: any) => 
      rd.cost_svg_cost ? rd.cost_svg_cost 
      : 
      {
        cost_svg_cost: {
          cost_svg_cost_total: 0,
          cost_svg_cost_objective: 0,
          cost_svg_cost_rate: 0,
          cost_svg_cost_difference: 0,
          cost_svg_cost_last_year_total: 0,
          cost_svg_cost_last_year_objective: 0,
          cost_svg_cost_last_year_comparison_total: 0,
          cost_svg_cost_last_year_comparison_rate: 0,
        }
      })
    .map((rd: any) => {
      // console.log("rd", rd)
      return {
        cost_svg_cost_total: forecastErrorEscaper(rd, "cost_svg_cost_total") ,
        cost_svg_cost_objective: forecastErrorEscaper(rd, "cost_svg_cost_objective") ,
        cost_svg_cost_rate: forecastErrorEscaper(rd, "cost_svg_cost_rate") ,
        cost_svg_cost_difference: forecastErrorEscaper(rd, "cost_svg_cost_difference") ,
        cost_svg_cost_last_year_total: forecastErrorEscaper(rd, "cost_svg_cost_last_year_total") ,
        cost_svg_cost_last_year_objective: forecastErrorEscaper(rd, "cost_svg_cost_last_year_objective") ,
        cost_svg_cost_last_year_comparison_total: forecastErrorEscaper(rd, "cost_svg_cost_last_year_comparison_total") ,
        cost_svg_cost_last_year_comparison_rate: forecastErrorEscaper(rd, "cost_svg_cost_last_year_comparison_rate")
      };
    });
  const dataUnitCostListToIntegrate_forForecast = raw_data_array_real
    .flatMap((rd: any) => rd.cost_unit_cost 
    ?
    rd.cost_unit_cost
    :
    {
      cost_unit_cost: {
        cost_unit_cost_total: 0,
        cost_unit_cost_objective: 0,
        cost_unit_cost_rate: 0,
        cost_unit_cost_difference: 0,
        cost_unit_cost_last_year_total: 0,
        cost_unit_cost_last_year_objective: 0,
        cost_unit_cost_last_year_comparison_total: 0,
        cost_unit_cost_last_year_comparison_rate: 0,
      }
    }
    )
    .map((rd: any) => {
      return {
        cost_unit_cost_total: forecastErrorEscaper(rd, "cost_unit_cost_total"),
        cost_unit_cost_objective: forecastErrorEscaper(rd, "cost_unit_cost_objective"),
        cost_unit_cost_rate: forecastErrorEscaper(rd, "cost_unit_cost_rate"),
        cost_unit_cost_difference: forecastErrorEscaper(rd, "cost_unit_cost_difference"),
        cost_unit_cost_last_year_total: forecastErrorEscaper(rd, "cost_unit_cost_last_year_total"),
        cost_unit_cost_last_year_objective: forecastErrorEscaper(rd, "cost_unit_cost_last_year_objective"),
        cost_unit_cost_last_year_comparison_total: forecastErrorEscaper(rd, "cost_unit_cost_last_year_comparison_total"),
        cost_unit_cost_last_year_comparison_rate: forecastErrorEscaper(rd, "cost_unit_cost_last_year_comparison_rate")
      };
    });

  const costDateList_forForecast = raw_data_array_real.map((result: any) => {
    let dateInGraph = result.result_at;
    return {
      result_at: dateInGraph,
      real_data: true
    };
  });
  // 日付ごとのコスト統合データ
  const integrated_array_forForecast = costDateList_forForecast.map((costDate: any, costDateIndex: number) => {
    return {
      ...costDate,
      real_data: true,
      ...dataSvgCostListToIntegrate_forForecast[costDateIndex],
      ...dataUnitCostListToIntegrate_forForecast[costDateIndex],

      revenue_detail: revenue_detail_results_forForecast[costDateIndex],

      unit_cost_cat_1_name: "原価",

      unit_cost_cat_1_total: 
        cost_unit_cost_detail_results_forForecast
        .filter((unit_cost: any) => unit_cost.result_at.seconds === costDate.result_at.seconds)
        .reduce((acc: any, el: any) => acc + el.cost_unit_cost_total, 0),

      unit_cost_cat_2_array: 
        pure_unit_cost_name_big_list_forForecast.map((unit_cat_2_name: any) => {
        return {
          unit_cost_cat_2_name: unit_cat_2_name,
          
          unit_cost_cat_2_total: 
            cost_unit_cost_detail_results_forForecast
            .filter((unit_cost: any) => {
              return unit_cost.result_at.seconds === costDate.result_at.seconds && unit_cost.category_2 === unit_cat_2_name
            })
            .reduce((acc: any, el: any) => acc + el.cost_unit_cost_total, 0),

          unit_cost_cat_3_array: 
            pure_unit_cost_name_list_forForecast
            .filter((pure_unit_cost_name: any) => pure_unit_cost_name.category_2 === unit_cat_2_name)
            .map((unit_cat: any) => {
              return {
                unit_cost_cat_3_name: unit_cat.category_3,
                unit_cost_cat_3_total: 
                  cost_unit_cost_detail_results_forForecast
                  .some((unit_cost: any) => 
                    unit_cost.result_at.seconds === costDate.result_at.seconds 
                    && 
                    unit_cost.category_3 === unit_cat.category_3
                  )
                  ?
                  cost_unit_cost_detail_results_forForecast
                  .filter((unit_cost: any) => 
                    unit_cost.result_at.seconds === costDate.result_at.seconds 
                    && 
                    unit_cost.category_3 === unit_cat.category_3
                  )[0].cost_unit_cost_total
                  :
                  null
              };
            })
        };
      }),

      svg_cost_cat_1_name: "販管費",

      svg_cost_cat_1_total: cost_svg_cost_detail_results_forForecast.filter((svg_cost: any) => svg_cost.result_at.seconds === costDate.result_at.seconds).reduce((acc: any, el: any) => acc + el.cost_svg_cost_total, 0),

      svg_cost_cat_2_array: pure_svg_cost_name_big_list_forForecast.map((svg_cat_2_name: any) => {
        return {
          svg_cost_cat_2_name: svg_cat_2_name,
          svg_cost_cat_2_total: 
            cost_svg_cost_detail_results_forForecast
            .some((svg_cost: any) => 
              svg_cost.result_at.seconds === costDate.result_at.seconds 
              && 
              svg_cost.category_2 === svg_cat_2_name
            )?
            cost_svg_cost_detail_results_forForecast
            .filter((svg_cost: any) => 
              svg_cost.result_at.seconds === costDate.result_at.seconds 
              && 
              svg_cost.category_2 === svg_cat_2_name
            ).reduce((acc: any, el: any) => acc + el.cost_svg_cost_total, 0)
            :
            null,
          svg_cost_cat_3_array: 
            pure_svg_cost_name_list_forForecast
            .filter((pure_svg_cost_name: any) => pure_svg_cost_name.category_2 === svg_cat_2_name)
            .map((svg_cat: any) => {
              return {
                svg_cost_cat_3_name: svg_cat.category_3,
                svg_cost_cat_3_total: 
                  cost_svg_cost_detail_results_forForecast
                  .some((svg_cost: any) => 
                    svg_cost.result_at.seconds === costDate.result_at.seconds 
                    && 
                    svg_cost.category_3 === svg_cat.category_3
                  )
                  ?
                    cost_svg_cost_detail_results_forForecast
                    .filter((svg_cost: any) => 
                      svg_cost.result_at.seconds === costDate.result_at.seconds 
                      && 
                      svg_cost.category_3 === svg_cat.category_3
                    )[0].cost_svg_cost_total
                  :
                  null
              };
            })
        };
      })
    };
  });
  return integrated_array_forForecast
}

export default targetForecastIntegratedArrayDetailReal;