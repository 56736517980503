import React from "react";
import styled from "styled-components";
import FirstSpanComparisonCalenderStart from "./FirstSpanComparisonCalenderStart";
import FirstSpanComparisonCalenderEnd from "./FirstSpanComparisonCalenderEnd";
import SecondSpanComparisonCalenderStart from "./SecondSpanComparisonCalenderStart";
import SecondSpanComparisonCalenderEnd from "./SecondSpanComparisonCalenderEnd";

function SpanComparisonFilter(props: any) {
  return (
    <SpanComparisonContainer>
      <SpanComparisonItem>
        <Selector>
          <FirstSpanComparisonCalenderStart />
          <Span>-</Span>
          <FirstSpanComparisonCalenderEnd />
        </Selector>
      </SpanComparisonItem>

      <SpanVS>
        <p>VS</p>
      </SpanVS>

      <SpanComparisonItem>
        <Selector>
          <SecondSpanComparisonCalenderStart />
          <Span>-</Span>
          <SecondSpanComparisonCalenderEnd />
        </Selector>
      </SpanComparisonItem>
    </SpanComparisonContainer>
  );
}

export default SpanComparisonFilter;

const SpanComparisonContainer = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

const SpanComparisonItem = styled.div`
  width: 360px;
`;

const SpanVS = styled.div`
  width: 40px;
  text-align: center;
  padding-top: 10px;
  font-size: 12px;
  color: #555555;
`;

const Selector = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);

  .makeStyles-container-9 {
    margin-top: 10px;
  }

  .MuiInput-underline {
    &::before,
    &::after {
      content: none;
    }
  }
  .MuiFormControl-marginNormal {
    margin: 0;
  }
  .MuiInputBase-input {
    letter-spacing: 1px;
  }
  .MuiSvgIcon-root {
    fill: #c4c4c4;
  }
`;

const Span = styled.span`
  padding: 3px 20px 7px 10px;
`;
