import React, { Component } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine
} from "recharts";
import revenueIcon from "../../../images/icons/graph_icon_revenue.svg";
import profitIcon from "../../../images/icons/graph_icon_profit.svg";
import legendIcon from "../../../images/icons/area_legend_icon.svg";
import CommentTick from "../../Comment/CommentTick";
import CommentModule from "../../Comment/CommentModule";
import { yAxisFormatter } from "../../Util/yAxisFormatter";
import ForecastPLGraphSwitcher from "../../Graph/ForecastPLGraphSwitcher";

// ==================================================
// Legend

const customLegend = (data: { moving_average_graph_switch: boolean }) => {
  return (
    <ul
      style={{
        display: "flex",
        fontSize: "12px",
        color: "#283f46",
        marginLeft: "16px"
      }}
    >
      <LegendItem className="line">売上</LegendItem>
      <LegendItem className="dots">売上目標</LegendItem>
      <LegendItem className="line movingAverage">営業利益</LegendItem>
      <LegendItem className="dots movingAverage">営業利益目標</LegendItem>
      {/* <LegendItem className="dots">予測</LegendItem> */}
    </ul>
  );
};
// ==================================================

interface Props {
  areaRef: any;
  integrated_array: any;
  comments: any;
  displayType: "line" | "bar";
  moving_average_graph_switch: any;
}

interface State {}

export default class ForecastPLGraphDefault extends Component<Props, State> {
  area: any;
  tooltip: HTMLElement | null;
  point: any;

  constructor(props: any) {
    super(props);

    this.area = null;
    this.tooltip = null;
    this.point = null;
  }

  showToolTip = (e: any) => {
    if (this.tooltip !== null) {
      console.log("this.tooltip", this.tooltip);
      console.log("showTooltip e:", e);

      this.tooltip.style.left = `${(e.cx + 50).toString()}px`;
      this.tooltip.style.top = `${(e.cy - 80).toString()}px`;
      // 最後のデータだった場合は、dotの左隣に出力
      if (this.props.integrated_array.length - 1 === e.index) {
        console.log("last data");
        console.log("last data x", `${(e.cx - 150).toString()}px`);
        this.tooltip.style.left = `${(e.cx - 170).toString()}px`;
      }
      this.tooltip.style.opacity = "1";
      console.log("this.tooltip.childNodes", this.tooltip.childNodes);
      const tooltipDate = this.tooltip.childNodes[0] as HTMLElement;
      const tooltipDataName = this.tooltip.childNodes[1] as HTMLElement;
      const tooltipDataKey_total = this.tooltip.childNodes[2]
        .childNodes[0] as HTMLElement;
      const tooltipDataKey_obj = this.tooltip.childNodes[3]
        .childNodes[0] as HTMLElement;
      const tooltipDataValue_total = this.tooltip.childNodes[2]
        .childNodes[1] as HTMLElement;
      const tooltipDataValue_obj = this.tooltip.childNodes[3]
        .childNodes[1] as HTMLElement;

      //ホバーしたラインのデータキーによって、日付を出し分け
      tooltipDate.innerHTML =
        new Date(e.payload.result_at.seconds * 1000).getFullYear() +
        "/" +
        (new Date(e.payload.result_at.seconds * 1000).getMonth() + 1);

      //キー名出し分け
      if (e.dataKey.includes("revenue")) {
        tooltipDataName.innerHTML = "売上";
      } else if (e.dataKey.includes("profit")) {
        tooltipDataName.innerHTML = "営業利益";
      }

      //実績/目標の出し分け
      tooltipDataKey_total.innerHTML = "実績";
      tooltipDataKey_obj.innerHTML = "目標";

      //値の出しわけ
      // if(e.value === null || e.value === undefined) {
      //   tooltipDataValue_total.innerHTML = "<span>NoData</span>"
      // } else {
      //   tooltipDataValue_total.innerHTML = e.value.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })
      // }
      const dataKey = e.dataKey;
      console.log(dataKey);

      if (
        e.payload.revenue_total === null ||
        e.payload.revenue_total === undefined ||
        e.payload.profit_operating_profit_total === null ||
        e.payload.profit_operating_profit_total === undefined
      ) {
        tooltipDataValue_total.innerHTML = "<span>NoData</span>";
      } else if (dataKey.includes("revenue")) {
        tooltipDataValue_total.innerHTML =
          e.payload.revenue_total.toLocaleString("ja-JP", {
            style: "currency",
            currency: "JPY"
          });
      } else {
        tooltipDataValue_total.innerHTML =
          e.payload.profit_operating_profit_total.toLocaleString("ja-JP", {
            style: "currency",
            currency: "JPY"
          });
      }

      if (
        e.payload.revenue_objective === null ||
        e.payload.revenue_objective === undefined ||
        e.payload.profit_operating_profit_objective === null ||
        e.payload.profit_operating_profit_objective === undefined
      ) {
        tooltipDataValue_obj.innerHTML = "<span>NoData</span>";
      } else if (dataKey.includes("revenue")) {
        tooltipDataValue_obj.innerHTML =
          e.payload.revenue_objective.toLocaleString("ja-JP", {
            style: "currency",
            currency: "JPY"
          });
      } else {
        tooltipDataValue_obj.innerHTML =
          e.payload.profit_operating_profit_objective.toLocaleString("ja-JP", {
            style: "currency",
            currency: "JPY"
          });
      }

      this.tooltip.style.zIndex = "99999";
    } else {
      console.log("showTooltip e is null:", e);
      return false;
    }
  };
  hideTooltip = (e: any) => {
    if (this.tooltip !== null) {
      console.log("this.tooltip", this.tooltip);
      console.log("hideTooltip e:", e);
      this.tooltip.style.opacity = "0";
      // document.getElementsByClassName('DataContent')[e.index].classList.remove('activeDotShown');
      // this.tooltip.style.zIndex = "-1";
    } else {
      console.log("hideTooltip e is null:", e);
      return false;
    }
  };

  render() {
    const { displayType } = this.props;
    return (
      <>
        <ForecastPLGraphSwitcher />

        {this.props.integrated_array.length > 0 ? (
          <Graph>
            <ResponsiveContainer>
              <ComposedChart
                width={500}
                height={400}
                data={this.props.integrated_array}
                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                barSize={20}
                barGap={5}
                ref={this.props.areaRef}
              >
                {displayType === "line" ? (
                  <Legend
                    iconSize={20}
                    verticalAlign="top"
                    align="left"
                    margin={{ bottom: 5 }}
                    content={customLegend({
                      moving_average_graph_switch:
                        this.props.moving_average_graph_switch
                    })}
                  />
                ) : (
                  <Legend
                    verticalAlign={"top"}
                    align={"left"}
                    width={432}
                    height={32}
                    margin={{ right: 100 }}
                    iconSize={12}
                    wrapperStyle={{ fontSize: "12px", color: "#283f46" }}
                  />
                )}

                <CartesianGrid vertical={false} stroke="#f5f5f5" />

                <XAxis
                  scale={displayType === "bar" ? undefined : "point"}
                  dataKey={"result_at.seconds"}
                  tick={
                    <CommentTick
                      comments={this.props.comments}
                      target_data_name="PL"
                    />
                  }
                  tickLine={false}
                  padding={{ left: 5, right: 5 }}
                  axisLine={{ stroke: "#f5f5f5" }}
                />
                {/* <YAxis tickCount={10} interval={0} tickLine={false} axisLine={false} tickMargin={10} tickFormatter={tick => yAxisFormatter(tick, 'revenue')} /> */}
                <YAxis
                  tickCount={10}
                  interval={0}
                  tickLine={false}
                  axisLine={false}
                  tickMargin={10}
                  tickFormatter={tick => yAxisFormatter(tick, "revenue")}
                />
                {/* <Tooltip content={customTooltip} /> */}
                <Tooltip wrapperStyle={{ display: "none" }} />

                <ReferenceLine y={0} stroke="#35455D" />

                {/*-*-*-*-* Bar *-*-*-*-*/}
                {displayType === "bar" && (
                  <Bar
                    name="営業利益"
                    dataKey="profit_operating_profit_total"
                    fill="#0066FF"
                  />
                )}
                {displayType === "bar" && (
                  <Bar name="売上" dataKey="revenue_total" fill="#99c2ff" />
                )}

                {/*-*-*-*-* Area *-*-*-*-*/}
                {displayType === "line" && (
                  <Area
                    name="営業利益目標"
                    type="linear"
                    dataKey="profit_operating_profit_total"
                    stroke="transparent"
                    fill="#0066FF"
                    fillOpacity="0.35"
                  />
                )}
                {displayType === "line" && (
                  <Area
                    name="売上目標"
                    type="linear"
                    dataKey="revenue_total"
                    stroke="transparent"
                    fill="#99c2ff"
                    fillOpacity="0.35"
                  />
                )}

                {/*-*-*-*-* Line *-*-*-*-*/}
                {displayType === "line" && (
                  <Line
                    name="営業利益"
                    type="linear"
                    dot={false}
                    dataKey="profit_operating_profit_total"
                    stroke="#0066FF"
                    strokeWidth="3"
                    activeDot={{
                      onMouseEnter: this.showToolTip,
                      onMouseLeave: this.hideTooltip
                    }}
                  />
                )}
                {displayType === "line" && (
                  <Line
                    name="売上"
                    type="linear"
                    dot={false}
                    dataKey="revenue_total"
                    stroke="#99c2ff"
                    strokeWidth="3"
                    activeDot={{
                      onMouseEnter: this.showToolTip,
                      onMouseLeave: this.hideTooltip
                    }}
                  />
                )}

                {/* <Line
                  type="linear"
                  name="営業利益目標"
                  dataKey="profit_operating_profit_objective"
                  dot={false}
                  stroke="#0066FF"
                  strokeWidth="3"
                  strokeDasharray="5 5"
                  activeDot={
                    displayType === "bar" && {
                      onMouseEnter: this.showToolTip,
                      onMouseLeave: this.hideTooltip
                    }
                  }
                /> */}
                {/* [ 移動平均 ] 達成率 => 実績 */}
                {this.props.moving_average_graph_switch && (
                  <Line
                    type="linear"
                    name="営業利益目標"
                    dataKey="profit_operating_profit_objective"
                    dot={false}
                    stroke="#0066FF"
                    strokeWidth="3"
                    strokeDasharray="5 5"
                    activeDot={{
                      onMouseEnter: this.showToolTip,
                      onMouseLeave: this.hideTooltip
                    }}
                  />
                )}

                {this.props.moving_average_graph_switch && (
                  <Line
                    type="linear"
                    name="売上目標"
                    dataKey="revenue_objective"
                    dot={false}
                    stroke="#99c2ff"
                    strokeWidth="3"
                    strokeDasharray="5 5"
                    activeDot={{
                      onMouseEnter: this.showToolTip,
                      onMouseLeave: this.hideTooltip
                    }}
                  />
                )}

                {/* <Line
                  name="売上目標"
                  type="linear"
                  dot={false}
                  dataKey="revenue_objective"
                  stroke="#99c2ff"
                  strokeWidth="3"
                  strokeDasharray="5 5"
                  activeDot={
                    displayType === "bar" && {
                      onMouseEnter: this.showToolTip,
                      onMouseLeave: this.hideTooltip
                    }
                  }
                /> */}
              </ComposedChart>
            </ResponsiveContainer>

            <UiChartTooltip
              className="ui-chart-tooltip"
              ref={ref => (this.tooltip = ref)}
            >
              <div className="tooltip-date"></div>
              <div className="tooltip-data-name"></div>
              <div className="toltip-databox">
                <div className="tooltip-data-key"></div>
                <div className="tooltip-data-value"></div>
              </div>
              <div className="toltip-databox">
                <div className="tooltip-data-key"></div>
                <div className="tooltip-data-value"></div>
              </div>
            </UiChartTooltip>

            <CommentModule />
          </Graph>
        ) : (
          <NoData>グラフを表示できません</NoData>
        )}
      </>
    );
  }
}

// Graph ※ src/components/Graph/GraphDefault.tsx コピペ
const Graph = styled.div`
  width: 100%;
  height: 272px;
  padding: 0 10px;
  box-sizing: border-box;

  .recharts-legend-wrapper {
    overflow: visible;
    top: 1px !important;
    left: 10px !important;
    font-size: 12px;
    color: #283f46;
  }

  .recharts-surface {
    position: relative;
    top: -2px;
    overflow: visible;
  }
  .recharts-cartesian-axis-tick {
    width: 100px !important;
    .recharts-cartesian-axis-tick-value {
      font-size: 10px;
      color: #435878;
    }
  }
  .recharts-yAxis {
    .recharts-cartesian-axis-line {
      stroke: #f5f5f5;
    }
  }
`;

const NoData = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #435878;
  margin-bottom: 8px;
  &.main {
    margin-bottom: 8px;
  }
`;

// GraphXAxis
const GraphXAxis = styled.div`
  position: relative;
  top: -20px;
  width: 100%;
  &.marketing_funnel {
    max-width: 660px;
    margin: 0 auto;
  }
`;

const UiChartTooltip = styled.div`
  opacity: 0;
  position: absolute;
  z-index: -1;
  min-width: 178px;
  width: auto;
  min-height: 48px;
  height: auto;
  background: white;
  border: 1px solid #e2e9f3;
  border-radius: 2px;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.25);
  padding: 8px 9px;
  .tooltip-date {
    font-size: 13px;
    font-weight: bold;
    color: #435878;
    margin-bottom: 10px;
  }
  .tooltip-data-name {
    font-size: 12px;
    font-weight: bold;
    color: #7591bc;
    margin-bottom: 10px;
  }
  .toltip-databox {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .tooltip-data-key {
    font-size: 10px;
    color: #435878;
    width: 50%;
    white-space: nowrap;
  }
  .tooltip-data-value {
    font-size: 12px;
    font-weight: bold;
    color: #435878;
    span {
      color: #af002a;
    }
  }
`;

//customLegend ※ src/components/Graph/GraphDefault.tsx コピペ
const LegendItem = styled.li`
  margin-right: 16px;
  &.line {
    &::before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 3px;
      background-color: #0065db;
      border-radius: 1.5px;
      margin-right: 8px;
      position: relative;
      bottom: 3px;
    }
    &.movingAverage {
      &::before {
        background-color: #0066ff;
      }
    }
  }
  &.dots {
    &::before {
      content: "•••";
      display: inline-block;
      width: 16px;
      height: 3px;
      color: #0065db;
      font-weight: bold;
      margin-right: 8px;
      position: relative;
      bottom: 1px;
    }
    &.movingAverage {
      &::before {
        color: #0066ff;
      }
    }
  }
  // &.dots {
  //   &::before {
  //     content: "•••";content
  //     display: inline-block;display
  //     width: 16px;width
  //     height: 3px;height
  //     color:#C4C4C4;color
  //     font-weight: bold;font-weight
  //     margin-right: 8px;margin-right
  //     position: relative;position
  //     bottom: 1px;bottom
  //   }
  // }
  // &.area {
  //   position: relative;position
  //   padding-left: 24px;padding-left
  //   &::before {
  //     content: "";content
  //     display: block;display
  //     position: absolute;position
  //     left: 0;left
  //     -webkit-transform: translateY(-50%);-webkit-transform
  //     -ms-transform: translateY(-50%);-ms-transform
  //     transform: translateY(-50%);transform
  //     width: 19px;width
  //     height: 14px;height
  //     top: 50%;top
  //     background-image: url(${legendIcon});background-image
  //     background-color: transparent;background-color
  //   }
  // }
`;
