function newCustomersAverageGenerator (raw_data_for_cohort: any, target_data: string, target_span: number) {
  let new_data_array: any;
  switch(target_data) {
    case 'churn_rate':
      new_data_array = raw_data_for_cohort.map((r: any) => {
        return {
          result_at: r.result_at,
          new_data: r.new_customer.new_customer_total
        }
      });
      break;
    case 'revenue_churned':
      new_data_array = raw_data_for_cohort.map((r: any) => {
        return {
          result_at: r.result_at,
          new_data: r.revenue.revenue_total
        }
      });
      break;
    case 'client_churned':
      new_data_array = raw_data_for_cohort.map((r: any) => {
        return {
          result_at: r.result_at,
          new_data: r.new_customer.new_customer_total
        }
      });
      break;
    default:
      new_data_array = raw_data_for_cohort.map((r: any) => {
        return {
          result_at: r.result_at,
          new_data: r.new_customer.new_customer_total
        }
      });
  }

  // ここからchurn_rate_raw_dataを使いたい形に整形
  const new_customers_average = 
  new_data_array.reduce((acc:any, cur:any) => acc + cur.new_data,0) / new_data_array.length
  

  return new_customers_average;
}
export default newCustomersAverageGenerator;