const GET_RESULTS_REQUEST_FOR_NEW_METRICS_FORECAST = 'GET_RESULTS_REQUEST_FOR_NEW_METRICS_METRICS_FORECAST';
const GET_RESULTS_REQUEST_FOR_NEW_METRICS_FORECAST_SUCCEEDED = 'GET_RESULTS_REQUEST_FOR_NEW_METRICS_FORECAST_SUCCEEDED';
const GET_RESULTS_REQUEST_FOR_NEW_METRICS_FORECAST_FAILED = 'GET_RESULTS_REQUEST_FOR_NEW_METRICS_FORECAST_FAILED';

const UPDATE_NEW_METRICS_FORECAST_SIMULATED_RESULT = 'UPDATE_NEW_METRICS_FORECAST_SIMULATED_RESULT';

const SET_METRICS_FORECAST_FILTER_STATUS = 'SET_METRICS_FORECAST_FILTER_STATUS';

const SET_METRICS_FORECAST_FILTER = 'SET_METRICS_FORECAST_FILTER';
const RESET_METRICS_FORECAST_FILTER = 'RESET_METRICS_FORECAST_FILTER';

const GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_METRICS_FORECASTS = 'GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_METRICS_FORECASTS';
const GET_RESULTS_FOR_SPAN_COMPARISON_METRICS_FORECASTS_SUCCEEDED = 'GET_RESULTS_FOR_SPAN_COMPARISON_METRICS_FORECASTS_SUCCEEDED';
const GET_RESULTS_FOR_SPAN_COMPARISON_METRICS_FORECASTS_FAILED = 'GET_RESULTS_FOR_SPAN_COMPARISON_METRICS_FORECASTS_FAILED';

export { GET_RESULTS_REQUEST_FOR_NEW_METRICS_FORECAST, GET_RESULTS_REQUEST_FOR_NEW_METRICS_FORECAST_SUCCEEDED, GET_RESULTS_REQUEST_FOR_NEW_METRICS_FORECAST_FAILED, UPDATE_NEW_METRICS_FORECAST_SIMULATED_RESULT, SET_METRICS_FORECAST_FILTER_STATUS, SET_METRICS_FORECAST_FILTER, RESET_METRICS_FORECAST_FILTER, GET_RESULTS_REQUESTED_FOR_SPAN_COMPARISON_METRICS_FORECASTS, GET_RESULTS_FOR_SPAN_COMPARISON_METRICS_FORECASTS_SUCCEEDED, GET_RESULTS_FOR_SPAN_COMPARISON_METRICS_FORECASTS_FAILED };
