import rsf from "../../rsf";
import { call } from "redux-saga/effects";
import firebase from "firebase";
import logger from "redux-logger";

const db = firebase.firestore();

// 過去6ヶ月分データ取得
function* getFilteredData(company_id, target_data_name, timespan) {
  try {

   let endMonth
   let startMonth
    if(timespan === 6 || timespan === 12 || timespan === 3) {
      console.log('6 or 12 or 3')
      // 当月
      endMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      const month = endMonth.getMonth();

      // nヶ月前
      startMonth = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - (timespan -1)));
    } else if (timespan === 1) {
      // 当月
      const thisMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      const month = thisMonth.getMonth();
      // 昨月
      startMonth = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month - 1));
      // 翌月
      endMonth = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMonth(month + 1));
    } 
     

    // コレクション名を設定
    const collection_name = target_data_name + '_detail';
    console.log('collection_name', collection_name);

    console.log('last6Month', startMonth)
    console.log('thisMonth', endMonth)


    // コレクショングループでためす
    const targetCollectionGroup = collection_name => {
      return db
        .collectionGroup(collection_name)
        .where('company_id', '==', company_id)
        .where('result_at', '>=', startMonth)
        .where('result_at', '<=', endMonth)
        .get();
    };
    const snapshot = yield call(targetCollectionGroup, collection_name);

    let results = [];
    snapshot.forEach(doc => {
      results = [...results, { ...doc.data(), docId: doc.id }];
    });

    return results;

  } catch (e) {
    console.log("error message", e.message);
  }
}


export default getFilteredData;
