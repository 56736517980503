import React from "react";
import styled from "styled-components";
import {
  funnelColorSwitcherRate,
  funnelColorSwitcherDif,
  colorSwitcherObjTotal
} from "../../Util/colorSwitcher";
import { unitSwitcher } from "../../Util/unitSwitcher";
import reached_arrow from "../../../images/icons/reached_arrow.svg";
import unreached_arrow from "../../../images/icons/unreached_icon.svg";

interface FunnelDetailHeaderProps {
  resultSpan: string;
  graphDatas: any;
  target_funnel_name: string;
  key_total: string;
  key_rate: string;
  key_difference: string;
}

const FunnelDetailHeader: React.FC<FunnelDetailHeaderProps> = props => {
  const {
    resultSpan,
    graphDatas,
    target_funnel_name,
    key_total,
    key_rate,
    key_difference
  } = props;

  let sum_graphDatas_total = 0;

  graphDatas.forEach((td: any) => {
    if (
      td.lead_new_count_total === undefined ||
      td.lead_new_count_total === null ||
      isNaN(td.lead_new_count_total)
    ) {
      return (td.lead_new_count_total = 0);
    }

    sum_graphDatas_total += td.lead_new_count_total;
  });

  const period_average_sum =
    graphDatas && sum_graphDatas_total / graphDatas.length;

  return (
    <FunnelDetailHeaderSC>
      {/* 直近の実績 */}
      <FunnelDetailHeaderItem>
        <SummaryContent>
          <SummaryLabel>
            {
              // dailyかweeklyだったら一日単位で表示
              resultSpan !== "monthly" &&
                graphDatas[graphDatas.length - 1] &&
                `
              ${new Date(
                graphDatas[graphDatas.length - 1].date.seconds * 1000
              ).getFullYear()}.
              ${
                new Date(
                  graphDatas[graphDatas.length - 1].date.seconds * 1000
                ).getMonth() + 1
              }

            `
            }
            {
              // monthlyだったら月まで表示
              resultSpan === "monthly" &&
                graphDatas[graphDatas.length - 1] &&
                `
              ${new Date(
                graphDatas[graphDatas.length - 1].date.seconds * 1000
              ).getFullYear()}.
              ${
                new Date(
                  graphDatas[graphDatas.length - 1].date.seconds * 1000
                ).getMonth() + 1
              }
            `
            }
          </SummaryLabel>

          {/* 直近の実績データ */}
          <FunnelDetailHeaderItemHeading
            className={
              graphDatas.length > 0 &&
              graphDatas[graphDatas.length - 1] &&
              graphDatas[graphDatas.length - 1].lead_new_count_total !==
                undefined
                ? colorSwitcherObjTotal(
                    graphDatas[graphDatas.length - 1].lead_new_count_objective,
                    graphDatas[graphDatas.length - 1].lead_new_count_total
                  )
                : "equal"
            }
          >
            {graphDatas.length > 0 &&
            graphDatas[graphDatas.length - 1].lead_new_count_total !== undefined
              ? unitSwitcher(
                  graphDatas[graphDatas.length - 1].lead_new_count_total,
                  "lead_count"
                )
              : "データが不足しています"}
          </FunnelDetailHeaderItemHeading>

          {/* 前月比セクション */}
          <FunnelDetailHeaderItemDetail>
            {/* ラベル */}
            <FunnelDetailHeaderItemDetailLabel
              className={
                graphDatas.length > 0 &&
                graphDatas[graphDatas.length - 1] &&
                graphDatas[graphDatas.length - 1]
                  .lead_new_count_last_time_comparison_rate !== undefined
                  ? funnelColorSwitcherRate(
                      graphDatas[graphDatas.length - 1]
                        .lead_new_count_last_time_comparison_rate
                    )
                  : "equal"
              }
            >
              前月比
            </FunnelDetailHeaderItemDetailLabel>
            {/* 前月比 割合 */}
            <FunnelDetailHeaderItemDetailPercentage
              className={
                graphDatas.length > 0 &&
                graphDatas[graphDatas.length - 1] &&
                graphDatas[graphDatas.length - 1]
                  .lead_new_count_last_time_comparison_rate !== undefined
                  ? funnelColorSwitcherRate(
                      graphDatas[graphDatas.length - 1]
                        .lead_new_count_last_time_comparison_rate
                    )
                  : "equal"
              }
            >
              {graphDatas.length > 0 &&
              graphDatas[graphDatas.length - 1]
                .lead_new_count_last_time_comparison_rate !== undefined
                ? unitSwitcher(
                    graphDatas[graphDatas.length - 1]
                      .lead_new_count_last_time_comparison_rate,
                    "lead_count_rate"
                  )
                : "NoData"}
            </FunnelDetailHeaderItemDetailPercentage>

            {/* 前月比 差異 */}
            <FunnelDetailHeaderItemDetailDifference
              className={
                graphDatas.length > 0 &&
                graphDatas[graphDatas.length - 1] &&
                graphDatas[graphDatas.length - 1]
                  .lead_new_count_last_time_difference !== undefined
                  ? funnelColorSwitcherDif(
                      graphDatas[graphDatas.length - 1]
                        .lead_new_count_last_time_difference
                    )
                  : "equal"
              }
            >
              （
              {graphDatas.length > 0 &&
              graphDatas[graphDatas.length - 1]
                .lead_new_count_last_time_difference !== undefined
                ? unitSwitcher(
                    graphDatas[graphDatas.length - 1]
                      .lead_new_count_last_time_difference,
                    "lead_count"
                  )
                : "NoData"}
              ）
            </FunnelDetailHeaderItemDetailDifference>
          </FunnelDetailHeaderItemDetail>
        </SummaryContent>
      </FunnelDetailHeaderItem>

      {/* 直近の目標比 */}
      <FunnelDetailHeaderItem>
        <SummaryContent>
          {/* ラベル */}
          <SummaryLabel>目標比</SummaryLabel>

          {/* 目標比 */}
          <FunnelDetailHeaderItemHeading
            className={
              graphDatas.length > 0 &&
              graphDatas[graphDatas.length - 1] &&
              graphDatas[graphDatas.length - 1].lead_new_count_difference !==
                undefined
                ? funnelColorSwitcherRate(
                    graphDatas[graphDatas.length - 1].lead_new_count_difference
                  )
                : "equal"
            }
          >
            {graphDatas.length > 0 &&
            graphDatas[graphDatas.length - 1].lead_new_count_difference !==
              undefined
              ? unitSwitcher(
                  graphDatas[graphDatas.length - 1].lead_new_count_difference,
                  "lead_count"
                )
              : "NoData"}
          </FunnelDetailHeaderItemHeading>

          {/* 目標比率 */}
          <FunnelDetailHeaderItemDetail>
            {/* 前月比 割合 */}
            <FunnelDetailHeaderItemDetailPercentage
              className={
                graphDatas[graphDatas.length - 1] &&
                graphDatas[graphDatas.length - 1].lead_new_count_rate !==
                  undefined
                  ? funnelColorSwitcherRate(
                      graphDatas[graphDatas.length - 1].lead_new_count_rate
                    )
                  : "equal"
              }
            >
              {graphDatas.length > 0 &&
              graphDatas[graphDatas.length - 1].lead_new_count_rate !==
                undefined
                ? unitSwitcher(
                    graphDatas[graphDatas.length - 1].lead_new_count_rate,
                    "lead_count_rate"
                  )
                : "NoData"}
            </FunnelDetailHeaderItemDetailPercentage>
          </FunnelDetailHeaderItemDetail>
        </SummaryContent>
      </FunnelDetailHeaderItem>

      {/* 期間合計 */}
      <FunnelDetailHeaderItem>
        <SummaryContent>
          <SummaryLabel>期間合計</SummaryLabel>

          {/* 期間合計 */}
          <FunnelDetailHeaderItemHeading
            className={
              graphDatas[graphDatas.length - 1] &&
              graphDatas[graphDatas.length - 1].lead_new_count_rate !==
                undefined
                ? funnelColorSwitcherRate(sum_graphDatas_total)
                : "equal"
            }
          >
            {graphDatas[graphDatas.length - 1] &&
              unitSwitcher(sum_graphDatas_total, "lead_count")}
          </FunnelDetailHeaderItemHeading>

          <FunnelDetailHeaderItemDetail>
            {/* ラベル */}
            <FunnelDetailHeaderItemDetailLabel
              className={
                graphDatas[graphDatas.length - 1] &&
                graphDatas[graphDatas.length - 1].lead_new_count_rate !==
                  undefined
                  ? funnelColorSwitcherRate(
                      graphDatas[graphDatas.length - 1].lead_new_count_rate
                    )
                  : "equal"
              }
            >
              目標達成率
            </FunnelDetailHeaderItemDetailLabel>
            {/* 目標達成率 割合 */}
            <FunnelDetailHeaderItemDetailPercentage
              className={
                graphDatas[graphDatas.length - 1] &&
                graphDatas[graphDatas.length - 1].lead_new_count_rate !==
                  undefined
                  ? funnelColorSwitcherRate(
                      graphDatas[graphDatas.length - 1].lead_new_count_rate
                    )
                  : "equal"
              }
            >
              {graphDatas.length > 0 &&
              graphDatas[graphDatas.length - 1].lead_new_count_rate !==
                undefined
                ? unitSwitcher(
                    graphDatas[graphDatas.length - 1].lead_new_count_rate,
                    "rate"
                  )
                : "NoData"}
            </FunnelDetailHeaderItemDetailPercentage>
          </FunnelDetailHeaderItemDetail>
        </SummaryContent>
      </FunnelDetailHeaderItem>
    </FunnelDetailHeaderSC>
  );
};

export default FunnelDetailHeader;

const FunnelDetailHeaderSC = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  background: #f4f9ff;
  padding: 25px 0;
`;

const FunnelDetailHeaderItem = styled.div`
  width: calc(33.333% - 2px);
  background-color: #f4f9ff;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e2e9f3;
  &:last-child {
    border-right: none;
  }
`;

const SummaryContent = styled.div`
  display: flex;
  flex-direction: column;
  &:first-of-type {
    margin-right: 16px;
  }
  align-items: center;
`;

const SummaryLabel = styled.p`
  color: #666666;
  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
  align-items: center;
  margin-bottom: 14px;
`;

// FunnelDetailHeaderItemHeading
const FunnelDetailHeaderItemHeading = styled.p`
  font-weight: 600;
  font-size: 32px;
  color: #35455d;
  margin-bottom: 14px;
  /* &.reached {
    color: #10b6db;
  }
  &.unreached {
    color: #da1948;
  }
  &.equal {
    color: #35455d;
  } */
`;

const FunnelDetailHeaderItemDetail = styled.div`
  text-align: center;
`;
const FunnelDetailHeaderItemDetailLabel = styled.span`
  font-weight: 600;
  color: #666666;
  font-size: 12px;
  font-weight: 600;
  margin-right: 8px;
  &.reached {
    margin-right: 26px;
  }
  &.unreached {
    margin-right: 26px;
  }
  &.equal {
  }
`;
const FunnelDetailHeaderItemDetailPercentage = styled.span`
  position: relative;
  font-weight: 600;
  color: #35455d;
  font-size: 12px;
  font-weight: 600;

  &::before {
    position: absolute;
    top: 2.5px;
    left: -14px;
    content: "";
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 12px;
    height: 11px;
  }

  &.reached {
    color: #10b6db;
    &::before {
      background-image: url(${reached_arrow});
    }
  }
  &.unreached {
    color: #da1948;
    &::before {
      background-image: url(${unreached_arrow});
    }
  }
  &.equal {
    color: #35455d;
  }
`;

const FunnelDetailHeaderItemDetailDifference = styled.span`
  font-weight: 600;
  color: #35455d;
  font-size: 12px;
  font-weight: 600;
  &.reached {
    color: #10b6db;
  }
  &.unreached {
    color: #da1948;
  }
  &.equal {
    color: #35455d;
  }
`;
