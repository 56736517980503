import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginRequest, setWidth } from "../../reducks/sessions/actions";
import Styled from "styled-components";
import LoginHeader from "../Layout/LogInHeader";
import { useForm, SubmitHandler } from "react-hook-form";
import LoadingCover from "../LoadingCover";
import { showLoading } from "../../reducks/loading/actions";
import {loginFailedReset} from "../../reducks/sessions/actions";
import { useLocation } from "react-router-dom"

type FormValues = {
  password: string;
  company_id: string;
};

const SignIn: React.FC = () => {
  const rootRef = React.useRef<HTMLDivElement>(null);
  const location = useLocation();
  const pathName = location.pathname
  const [company_id, setcompany_id] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const session_error_message = useSelector((state: any) => state.session.message);
  const { register, handleSubmit, errors } = useForm<FormValues>();

  const handlecompany_idChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setcompany_id(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleSignIn: SubmitHandler<FormValues> = () => {
    // e.preventDefault();
    dispatch(loginRequest(company_id, password));
    dispatch(showLoading());
    dispatch(setWidth(window.innerWidth))
    // console.log(data)
  };

  React.useEffect(() => {
    dispatch(loginFailedReset())
  },[rootRef])

  // GA計測タグ
  useEffect(() => {
    document.title = "サインイン"; // ページタイトルを変更
    window.gtag('config', 'G-YXXD65BEJ0', {
      'page_path': pathName // 任意のパス（あるいはprops.location.pathnameなど）
    });
  });

  return (
    <>
      <LoginHeader />
      <LoadingCover />
      <SignInContainer ref={rootRef}>
        <SignInForm onSubmit={handleSubmit(handleSignIn)}>
          <SignInHeading>ログイン</SignInHeading>
          {session_error_message && <ErrorText>{session_error_message}</ErrorText>}
          <FormItem>
            <dt>
              <InputLabel htmlFor="company_id">メールアドレス</InputLabel>
            </dt>
            <dd>
              <SignInInput
                id="company_id"
                name="company_id"
                type="text"
                onChange={handlecompany_idChange}
                ref={register({
                  required: true
                })}
              />
              {errors.company_id?.type === "required" && <ErrorText>未入力エラー</ErrorText>}
              {errors.company_id?.type === "pattern" && <ErrorText>形式エラー</ErrorText>}
            </dd>
          </FormItem>
          <FormItem>
            <dt>
              <InputLabel htmlFor="password">パスワード</InputLabel>
            </dt>
            <dd>
              <SignInInput
                id="password"
                name="password"
                type="password"
                onChange={handlePasswordChange}
                ref={register({
                  required: true
                })}
              />
              {errors.password?.type === "required" && <ErrorText>未入力エラー</ErrorText>}
              {errors.password?.type === "pattern" && <ErrorText>形式エラー</ErrorText>}
            </dd>
          </FormItem>
          <div></div>
          <ForgetPasswordLink href="/forget-password">パスワードを忘れた方はこちら</ForgetPasswordLink>
          <LoginButton type="submit">ログイン</LoginButton>
        </SignInForm>
      </SignInContainer>
    </>
  );
};
export default SignIn;

const SignInContainer = Styled.div`
  width: 100%;
  height: calc(100vh - 80px); 
  background: #F2F6FB;
  display: flex;
  justify-content: center;
`;

const SignInForm = Styled.form`
  padding-top: 56px;
`;

const SignInHeading = Styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: #283F46;
  line-height: 36px;
  margin: 0 0 40px 0;
  position: relative;
  left: -200px;
`;

const InputLabel = Styled.label`
  width: 120px;
  font-size: 14px;
  line-height: 21px;
  color: #435878;
  text-align: right;
  margin-right: 32px;
`;

const FormItem = Styled.dl`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 120px;
  margin: 0 0 16px 0;
`;

const SignInInput = Styled.input`
  display: block;
  width: 296px;
  height: 48px;
  padding: 0 15px;
  border: 1px solid #B5B5B5;
  border-radius: 4px;
  background: #fff;
  box-sizing: border-box;
  outline: none;
`;

const ForgetPasswordLink = Styled.a`
  display: block;
  font-size: 16px;
  line-height: 17px;
  color: #0066FF;
  margin-bottom: 24px;
  text-align: right;
  text-decoration: none;
  padding: 8px 120px 0px 0px;
`;

const LoginButton = Styled.button`
  display: block;
  width: 296px;
  height: 36px;
  margin: 0 auto;
  background-color: #0066FF;
  border-radius: 4px;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  line-height: 19px;
  border: none;
  outline: none;
  cursor: pointer;
  text-align: center;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
`;

const ErrorText = Styled.p`
  color: #AF002A;
  font-size: 12px;
  margin-left: 16px;
`;
