import { SET_START_DATE, SET_END_DATE, SET_DATE_FOR_FORECAST } from "./types"
import {RESET_ALL} from "../common/types";

const today = new Date()
const now = new Date()
const nextMonth = new Date(now.setMonth(now.getMonth() + 1))

const initialState: object = {
  startDate: today,
  endDate: nextMonth,
}

const calendarReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_ALL:
      return initialState;

    case SET_START_DATE:
      return {
        ...state,
        startDate: action.date,
      }

    case SET_END_DATE:
      return {
        ...state,
        endDate: action.date,
      }

    // case SET_DATE_FOR_FORECAST:
    //   return {
    //     startDate: lastMonth,
    //     endDate: today,
    //   }
    default:
      return state
  }
}

export default calendarReducer
