// import update from "immutability-helper";
import { SET_LEADS } from "./types"
import {RESET_ALL} from "../common/types";

const initialState: object = {
  resultSpan: "monthly",
  resultMonthly: [],
  resultWeekly: [],
  resultDaily: [],
}

const leadsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_ALL:
      return initialState;

    case SET_LEADS:
      // console.log("results daily: ", action)
      if (action.resultSpan === "monthly") {
        return {
          ...state,
          resultSpan: "monthly",
          resultMonthly: action.results,
        }
      } else if (action.resultSpan === "weekly") {
        return {
          ...state,
          resultSpan: "weekly",
          resultWeekly: action.results,
        }
      } else {
        return {
          ...state,
          resultSpan: "daily",
          resultDaily: action.results,
        }
      }

    default:
      return state
  }
}

export default leadsReducer
