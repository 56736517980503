import firebase from "firebase";
import { all, call, put, takeLatest } from "redux-saga/effects";
import getRawDataThisMonth from "./rawDataSagas/getRawDataThisMonth";
import getRawDataLastMonth from "./rawDataSagas/getRawDataLastMonth";
import getRawDataLast6Month from "./rawDataSagas/getRawDataLast6Month";
import getRawDataLast12Month from "./rawDataSagas/getRawDataLast12Month";
import getRawDataCustomSpan from "./rawDataSagas/getRawDataCustomSpan";
import getTargetDataSequenceResult from "./rawDataSagas/getTargetDataSequenceResult";
import getRawDetailData from "./rawDataSagas/getRawDetailData";
import getFilteredData from "./rawDataSagas/getFilteredData";
import getCustomFilteredData from "./rawDataSagas/getCustomFilteredData";
import getTargetDetailData from "./rawDataSagas/getTargetDetailData";
import { GET_RAW_DATA_THIS_MONTH_REQUEST, GET_RAW_DATA_THIS_MONTH_SUCCEEDED, GET_RAW_DATA_THIS_MONTH_FAILED, GET_RAW_DATA_LAST_MONTH_REQUEST, GET_RAW_DATA_LAST_MONTH_SUCCEEDED, GET_RAW_DATA_LAST_MONTH_FAILED, GET_RAW_DATA_LAST_6_MONTH_REQUEST, GET_RAW_DATA_LAST_6_MONTH_SUCCEEDED, GET_RAW_DATA_LAST_6_MONTH_FAILED, GET_RAW_DATA_LAST_12_MONTH_REQUEST, GET_RAW_DATA_LAST_12_MONTH_SUCCEEDED, GET_RAW_DATA_LAST_12_MONTH_FAILED, GET_RAW_DATA_CUSTOM_SPAN_REQUEST, GET_RAW_DATA_CUSTOM_SPAN_SUCCEEDED, GET_RAW_DATA_CUSTOM_SPAN_FAILED, GET_RAW_DATA_WITH_SPAN_AND_TYPE_AND_TIME_REQUEST, GET_RAW_DATA_WITH_SPAN_AND_TYPE_AND_TIME_SUCCEEDED, GET_RAW_DATA_WITH_SPAN_AND_TYPE_AND_TIME_FAILED, GET_RAW_DATA_FOR_FILTER_REQUEST, GET_RAW_DATA_FOR_FILTER_SUCCEEDED, GET_RAW_DATA_FOR_FILTER_FAILED } from "../reducks/rawData/types";

import { SET_DATA_KEYS, START_TARGET_DATA_SEQUENCE, SET_TARGET_DATA_SUCCEEDED, SET_TARGET_DATA_FAILED, UPDATE_TARGET_DATA } from "../reducks/targetData/types";

import { HIDE_LOADING, SET_LOADING } from "../reducks/loading/types";
import { SET_FILTER } from "../reducks/filter/types";
import costReducer from "../reducks/cost/reducers";

// ページヘッダー内期間選択による当月、昨月、翌月の3ヶ月間データの取得
function* getRawDataThisMonthSaga(action) {
  try {
    console.log('action.company_id', action.company_id);
    console.log('action.selected_data_config_data_type', action.selected_data_config_data_type);
    const raw_data_array = yield* getRawDataThisMonth(action.company_id, action.selected_data_config_data_type);
    console.log('raw_data_array', raw_data_array);
    console.log('action.pathname', action.pathname);
    // targetData側へraw_dataを送る
    const target_data = getTargetDataSequenceResult(raw_data_array, action.pathname)
    console.log('target_data after get target data', target_data);

    // フィルター時用のdetailデータを取得
    let raw_detail_data_array;
    let raw_detail_data_array_filtered;
    if(action.filter_name) {
      raw_detail_data_array = yield* getFilteredData(action.company_id, action.target_data_name, action.timespan)
      raw_detail_data_array_filtered = raw_detail_data_array.filter(rdd => rdd.resultSpan === action.selected_data_config_data_type).filter(r => r.filter === action.filter_name)
    }

    if(action.filter_name) {
      yield all([
        put({
          type: GET_RAW_DATA_THIS_MONTH_SUCCEEDED,
          raw_data_array: raw_data_array,
          selected_data_config_data_type: action.selected_data_config_data_type
        }),
        put({
          type: GET_RAW_DATA_FOR_FILTER_SUCCEEDED,
          // target_detail_data: target_detail_data,
          raw_detail_data_array: raw_detail_data_array,
          raw_detail_data_array_filtered: raw_detail_data_array_filtered,
          filter_name: action.filter_name,
          target_data_name: action.target_data_name,
          results: raw_detail_data_array_filtered
        }),
        put({
          type: SET_DATA_KEYS,
          location: action.pathname
        }),
        put({
          type: SET_TARGET_DATA_SUCCEEDED,
          target_data: target_data,
          resultSpan: action.selected_data_config_data_span
        }),
        put({
          type: HIDE_LOADING
        })
      ]);
    } else {
      yield all([
        put({
          type: GET_RAW_DATA_THIS_MONTH_SUCCEEDED,
          raw_data_array: raw_data_array,
          selected_data_config_data_type: action.selected_data_config_data_type
        }),
        put({
          type: SET_DATA_KEYS,
          location: action.pathname
        }),
        put({
          type: SET_TARGET_DATA_SUCCEEDED,
          target_data: target_data,
          resultSpan: action.selected_data_config_data_span
        }),
        put({
          type: HIDE_LOADING
        })
      ]);
    }
  } catch (e) {
    yield all([
      put({
        type: HIDE_LOADING
      }),
      put({
        type: GET_RAW_DATA_THIS_MONTH_FAILED,
        message: e.message
      })
    ]);
  }
}


// ページヘッダー内期間選択による昨月、一昨月、当月の3ヶ月間データの取得
function* getRawDataLastMonthSaga(action) {
  try {
    console.log('action.company_id', action.company_id);
    console.log('action.selected_data_config_data_type', action.selected_data_config_data_type);

    const raw_data_array = yield* getRawDataLastMonth(action.company_id, action.selected_data_config_data_type);
    console.log('raw_data_array', raw_data_array);
    // targetData側へraw_dataを送る
    const target_data = getTargetDataSequenceResult(raw_data_array, action.pathname)

    // フィルター時用のdetailデータを取得
    let raw_detail_data_array;
    let raw_detail_data_array_filtered;
    if(action.filter_name) {
      raw_detail_data_array = yield* getFilteredData(action.company_id, action.target_data_name, action.timespan)
      raw_detail_data_array_filtered = raw_detail_data_array.filter(rdd => rdd.resultSpan === action.selected_data_config_data_type).filter(r => r.filter === action.filter_name)
    }

    if(action.filter_name) {
      yield all([
        put({
          type: GET_RAW_DATA_LAST_MONTH_SUCCEEDED,
          raw_data_array: raw_data_array,
          selected_data_config_data_type: action.selected_data_config_data_type
        }),
        put({
          type: GET_RAW_DATA_FOR_FILTER_SUCCEEDED,
          // target_detail_data: target_detail_data,
          raw_detail_data_array: raw_detail_data_array,
          raw_detail_data_array_filtered: raw_detail_data_array_filtered,
          filter_name: action.filter_name,
          target_data_name: action.target_data_name,
          results: raw_detail_data_array_filtered
        }),
        put({
          type: SET_DATA_KEYS,
          location: action.pathname
        }),
        put({
          type: SET_TARGET_DATA_SUCCEEDED,
          target_data: target_data,
          resultSpan: action.selected_data_config_data_span
        }),
        put({
          type: HIDE_LOADING
        })
      ]);
    } else {
      yield all([
        put({
          type: GET_RAW_DATA_LAST_MONTH_SUCCEEDED,
          raw_data_array: raw_data_array,
          selected_data_config_data_type: action.selected_data_config_data_type
        }),
        put({
          type: SET_DATA_KEYS,
          location: action.pathname
        }),
        put({
          type: SET_TARGET_DATA_SUCCEEDED,
          target_data: target_data,
          resultSpan: action.selected_data_config_data_span
        }),
        put({
          type: HIDE_LOADING
        })
      ]);
    }
    
    
  } catch (e) {
    yield all([
      put({
        type: HIDE_LOADING
      }),
      put({
        type: GET_RAW_DATA_LAST_MONTH_FAILED,
        message: e.message
      })
    ]);
  }
}


// ページヘッダー内期間選択による当月を含む過去6ヶ月間データの取得
function* getRawDataLast6MonthSaga(action) {
  try {
    console.log('action.company_id', action.company_id);
    console.log('action.selected_data_config_data_type', action.selected_data_config_data_type);

    const raw_data_array = yield* getRawDataLast6Month(action.company_id, action.selected_data_config_data_type);
    // targetData側へraw_dataを送る
    const target_data = getTargetDataSequenceResult(raw_data_array, action.pathname)

    // フィルター時用のdetailデータを取得
    let raw_detail_data_array;
    let raw_detail_data_array_filtered;
    if(action.filter_name) {
      raw_detail_data_array = yield* getFilteredData(action.company_id, action.target_data_name, action.timespan)
      raw_detail_data_array_filtered = raw_detail_data_array.filter(rdd => rdd.resultSpan === action.selected_data_config_data_type).filter(r => r.filter === action.filter_name)
    }
    
    if(action.filter_name) {
      yield all([
        put({
          type: GET_RAW_DATA_LAST_6_MONTH_SUCCEEDED,
          raw_data_array: raw_data_array,
          selected_data_config_data_type: action.selected_data_config_data_type
        }),
        put({
          type: GET_RAW_DATA_FOR_FILTER_SUCCEEDED,
          // target_detail_data: target_detail_data,
          raw_detail_data_array: raw_detail_data_array,
          raw_detail_data_array_filtered: raw_detail_data_array_filtered,
          filter_name: action.filter_name,
          target_data_name: action.target_data_name,
          results: raw_detail_data_array_filtered
        }),
        put({
          type: SET_DATA_KEYS,
          location: action.pathname
        }),
        put({
          type: SET_TARGET_DATA_SUCCEEDED,
          target_data: target_data,
          resultSpan: action.selected_data_config_data_span
        }),
        put({
          type: HIDE_LOADING
        })
      ]);
    } else {
      console.log('not filtered')
      yield all([
        put({
          type: GET_RAW_DATA_LAST_6_MONTH_SUCCEEDED,
          raw_data_array: raw_data_array,
          selected_data_config_data_type: action.selected_data_config_data_type
        }),
        put({
          type: SET_DATA_KEYS,
          location: action.pathname
        }),
        put({
          type: SET_TARGET_DATA_SUCCEEDED,
          target_data: target_data,
          resultSpan: action.selected_data_config_data_span
        }),
        put({
          type: HIDE_LOADING
        })
      ]);
    }
    
  } catch (e) {
    yield all([
      put({
        type: HIDE_LOADING
      }),
      put({
        type: GET_RAW_DATA_LAST_6_MONTH_FAILED,
        message: e.message
      })
    ]);
  }
}

// ページヘッダー内期間選択による当月を含む過去12ヶ月間データの取得
function* getRawDataLast12MonthSaga(action) {
  try {
    console.log('action.company_id', action.company_id);
    console.log('action.selected_data_config_data_type', action.selected_data_config_data_type);

    const raw_data_array = yield* getRawDataLast12Month(action.company_id, action.selected_data_config_data_type);
    console.log('raw_data_array', raw_data_array);
    // targetData側へraw_dataを送る
    const target_data = getTargetDataSequenceResult(raw_data_array, action.pathname)

    // フィルター時用のdetailデータを取得
    let raw_detail_data_array;
    let raw_detail_data_array_filtered;
    if(action.filter_name) {
      raw_detail_data_array = yield* getFilteredData(action.company_id, action.target_data_name, action.timespan)
      raw_detail_data_array_filtered = raw_detail_data_array.filter(rdd => rdd.resultSpan === action.selected_data_config_data_type).filter(r => r.filter === action.filter_name)
    }

    if(action.filter_name) {
      yield all([
        put({
          type: GET_RAW_DATA_LAST_12_MONTH_SUCCEEDED,
          raw_data_array: raw_data_array,
          selected_data_config_data_type: action.selected_data_config_data_type
        }),
        put({
          type: GET_RAW_DATA_FOR_FILTER_SUCCEEDED,
          // target_detail_data: target_detail_data,
          raw_detail_data_array: raw_detail_data_array,
          raw_detail_data_array_filtered: raw_detail_data_array_filtered,
          filter_name: action.filter_name,
          target_data_name: action.target_data_name,
          results: raw_detail_data_array_filtered
        }),
        put({
          type: SET_DATA_KEYS,
          location: action.pathname
        }),
        put({
          type: SET_TARGET_DATA_SUCCEEDED,
          target_data: target_data,
          resultSpan: action.selected_data_config_data_span
        }),
        put({
          type: HIDE_LOADING
        })
      ]);
    } else {
      yield all([
        put({
          type: GET_RAW_DATA_LAST_12_MONTH_SUCCEEDED,
          raw_data_array: raw_data_array,
          selected_data_config_data_type: action.selected_data_config_data_type
        }),
        put({
          type: SET_DATA_KEYS,
          location: action.pathname
        }),
        put({
          type: SET_TARGET_DATA_SUCCEEDED,
          target_data: target_data,
          resultSpan: action.selected_data_config_data_span
        }),
        put({
          type: HIDE_LOADING
        })
      ]);
    }

    
  } catch (e) {
    yield all([
      put({
        type: HIDE_LOADING
      }),
      put({
        type: GET_RAW_DATA_LAST_12_MONTH_FAILED,
        message: e.message
      })
    ]);
  }
}

// ページヘッダー内期間選択によるカスタム期間データの取得
function* getRawDataCustomSpanSaga(action) {
  try {
    console.log('action.company_id', action.company_id);
    console.log('action.selected_data_config_data_type', action.selected_data_config_data_type);
    console.log('action.custom_span_start_time', action.custom_span_start_time)

    const raw_data_array = yield* getRawDataCustomSpan(action.company_id, action.selected_data_config_data_type, action.custom_span_start_time, action.custom_span_end_time);
    console.log('raw_data_arrayraw_data_arrayraw_data_array', raw_data_array);
    // targetData側へraw_dataを送る
    const target_data = getTargetDataSequenceResult(raw_data_array, action.pathname)
    console.log('target_data', target_data)
    // フィルター時用のdetailデータを取得
    let raw_detail_data_array;
    let raw_detail_data_array_filtered;
    if(action.filter_name) {
      raw_detail_data_array = yield* getCustomFilteredData(action.company_id, action.target_data_name, action.custom_span_start_time, action.custom_span_end_time)
      raw_detail_data_array_filtered = raw_detail_data_array.filter(rdd => rdd.resultSpan === action.selected_data_config_data_type).filter(r => r.filter === action.filter_name)
    }

    if(action.filter_name) {
      yield all([
        put({
          type: GET_RAW_DATA_CUSTOM_SPAN_SUCCEEDED,
          raw_data_array: raw_data_array,
          custom_span_start_time: action.custom_span_start_time,
          custom_span_end_time: action.custom_span_end_time,
          selected_data_config_data_type: action.selected_data_config_data_type
        }),
        put({
          type: GET_RAW_DATA_FOR_FILTER_SUCCEEDED,
          // target_detail_data: target_detail_data,
          raw_detail_data_array: raw_detail_data_array,
          raw_detail_data_array_filtered: raw_detail_data_array_filtered,
          filter_name: action.filter_name,
          target_data_name: action.target_data_name,
          results: raw_detail_data_array_filtered
        }),
        put({
          type: SET_DATA_KEYS,
          location: action.pathname
        }),
        put({
          type: SET_TARGET_DATA_SUCCEEDED,
          target_data: target_data,
          resultSpan: action.selected_data_config_data_span
        }),
        put({
          type: HIDE_LOADING
        })
      ]);
    } else {
      yield all([
        put({
          type: GET_RAW_DATA_CUSTOM_SPAN_SUCCEEDED,
          raw_data_array: raw_data_array,
          custom_span_start_time: action.custom_span_start_time,
          custom_span_end_time: action.custom_span_end_time,
          selected_data_config_data_type: action.selected_data_config_data_type
        }),
        put({
          type: SET_DATA_KEYS,
          location: action.pathname
        }),
        put({
          type: SET_TARGET_DATA_SUCCEEDED,
          target_data: target_data,
          resultSpan: action.selected_data_config_data_span
        }),
        put({
          type: HIDE_LOADING
        })
      ]);
    }
    
  } catch (e) {
    yield all([
      put({
        type: HIDE_LOADING
      }),
      put({
        type: GET_RAW_DATA_CUSTOM_SPAN_FAILED,
        message: e.message
      })
    ]);
  }
}

// ページヘッダー内、データ種類と範囲指定によるデータの取得
function* getRawDataWithSpanAndTypeAndTimeSaga(action) {
  switch(action.selected_data_config_data_span) {
    // データ範囲が当月を含む3ヶ月の場合
    case 'this_month':
      try {
        console.log('action.company_id', action.company_id);
        console.log('action.selected_data_config_data_type', action.selected_data_config_data_type);
        const raw_data_array = yield* getRawDataThisMonth(action.company_id, action.selected_data_config_data_type);
        console.log('raw_data_array', raw_data_array);

        // targetData側へraw_dataを送る
        const target_data = getTargetDataSequenceResult(raw_data_array, action.pathname)

        yield all([
          put({
            type: GET_RAW_DATA_WITH_SPAN_AND_TYPE_AND_TIME_SUCCEEDED,
            raw_data_array: raw_data_array,
            selected_data_config_data_type: action.selected_data_config_data_type,
            selected_data_config_data_span: action.selected_data_config_data_span,
            custom_span_start_time: action.selected_data_custom_span_start_time,
            custom_span_end_time: action.selected_data_custom_span_end_time,
          }),
          put({
            type: SET_DATA_KEYS,
            location: action.pathname
          }),
          put({
            type: SET_TARGET_DATA_SUCCEEDED,
            target_data: target_data,
            resultSpan: action.selected_data_config_data_span
          }),
          put({
            type: HIDE_LOADING
          })
        ]);
      } catch (e) {
        yield all([
          put({
            type: HIDE_LOADING
          }),
          put({
            type: GET_RAW_DATA_WITH_SPAN_AND_TYPE_AND_TIME_FAILED,
            message: e.message
          })
        ]);
      }
      break;

    // データ範囲が昨月を含む3ヶ月の場合
    case 'last_month':
      try {
        console.log('action.company_id', action.company_id);
        console.log('action.selected_data_config_data_type', action.selected_data_config_data_type);

        const raw_data_array = yield* getRawDataLastMonth(action.company_id, action.selected_data_config_data_type);
        console.log('raw_data_array', raw_data_array);

        // targetData側へraw_dataを送る
        const target_data = getTargetDataSequenceResult(raw_data_array, action.pathname)
        yield all([
          put({
            type: GET_RAW_DATA_WITH_SPAN_AND_TYPE_AND_TIME_SUCCEEDED,
            raw_data_array: raw_data_array,
            selected_data_config_data_type: action.selected_data_config_data_type,
            selected_data_config_data_span: action.selected_data_config_data_span,
            custom_span_start_time: action.selected_data_custom_span_start_time,
            custom_span_end_time: action.selected_data_custom_span_end_time,
          }),
          put({
            type: SET_DATA_KEYS,
            location: action.pathname
          }),
          put({
            type: SET_TARGET_DATA_SUCCEEDED,
            target_data: target_data,
            resultSpan: action.selected_data_config_data_span
          }),
          put({
            type: HIDE_LOADING
          })
        ]);
      } catch (e) {
        yield all([
          put({
            type: HIDE_LOADING
          }),
          put({
            type: GET_RAW_DATA_WITH_SPAN_AND_TYPE_AND_TIME_FAILED,
            message: e.message
          })
        ]);
      }
      break;

    // データ範囲が過去6ヶ月の場合
    case 'last_6_months':
      try {
        console.log('action.company_id', action.company_id);
        console.log('action.selected_data_config_data_type', action.selected_data_config_data_type);

        const raw_data_array = yield* getRawDataLast6Month(action.company_id, action.selected_data_config_data_type);
        console.log('raw_data_array', raw_data_array);

        // targetData側へraw_dataを送る
        const target_data = getTargetDataSequenceResult(raw_data_array, action.pathname)

        yield all([
          put({
            type: GET_RAW_DATA_WITH_SPAN_AND_TYPE_AND_TIME_SUCCEEDED,
            raw_data_array: raw_data_array,
            selected_data_config_data_type: action.selected_data_config_data_type,
            selected_data_config_data_span: action.selected_data_config_data_span,
            custom_span_start_time: action.selected_data_custom_span_start_time,
            custom_span_end_time: action.selected_data_custom_span_end_time,
          }),
          put({
            type: SET_DATA_KEYS,
            location: action.pathname
          }),
          put({
            type: SET_TARGET_DATA_SUCCEEDED,
            target_data: target_data,
            resultSpan: action.selected_data_config_data_span
          }),
          put({
            type: HIDE_LOADING
          })
        ]);
      } catch (e) {
        yield all([
          put({
            type: HIDE_LOADING
          }),
          put({
            type: GET_RAW_DATA_WITH_SPAN_AND_TYPE_AND_TIME_FAILED,
            message: e.message
          })
        ]);
      }
      break;

    // データ範囲が過去12ヶ月の場合
    case 'last_12_months':
      try {
        console.log('action.company_id', action.company_id);
        console.log('action.selected_data_config_data_type', action.selected_data_config_data_type);

        const raw_data_array = yield* getRawDataLast12Month(action.company_id, action.selected_data_config_data_type);
        console.log('raw_data_array', raw_data_array);

        // targetData側へraw_dataを送る
        const target_data = getTargetDataSequenceResult(raw_data_array, action.pathname)
        yield all([
          put({
            type: GET_RAW_DATA_WITH_SPAN_AND_TYPE_AND_TIME_SUCCEEDED,
            raw_data_array: raw_data_array,
            selected_data_config_data_type: action.selected_data_config_data_type,
            selected_data_config_data_span: action.selected_data_config_data_span,
            custom_span_start_time: action.selected_data_custom_span_start_time,
            custom_span_end_time: action.selected_data_custom_span_end_time,
          }),
          put({
            type: SET_DATA_KEYS,
            location: action.pathname
          }),
          put({
            type: SET_TARGET_DATA_SUCCEEDED,
            target_data: target_data,
            resultSpan: action.selected_data_config_data_span
          }),
          put({
            type: HIDE_LOADING
          })
        ]);
      } catch (e) {
        yield all([
          put({
            type: HIDE_LOADING
          }),
          put({
            type: GET_RAW_DATA_WITH_SPAN_AND_TYPE_AND_TIME_FAILED,
            message: e.message
          })
        ]);
      }
      break;

    case 'custom':
      try {
        console.log('action.company_id', action.company_id);
        console.log('action.selected_data_config_data_type', action.selected_data_config_data_type);

        const raw_data_array = yield* getRawDataCustomSpan(action.company_id, action.selected_data_config_data_type, action.selected_data_custom_span_start_time, action.selected_data_custom_span_end_time);
        console.log('raw_data_array', raw_data_array);

        // targetData側へraw_dataを送る
        const target_data = getTargetDataSequenceResult(raw_data_array, action.pathname)
        yield all([
          put({
            type: GET_RAW_DATA_WITH_SPAN_AND_TYPE_AND_TIME_SUCCEEDED,
            raw_data_array: raw_data_array,
            selected_data_config_data_type: action.selected_data_config_data_type,
            selected_data_config_data_span: action.selected_data_config_data_span,
            custom_span_start_time: action.selected_data_custom_span_start_time,
            custom_span_end_time: action.selected_data_custom_span_end_time,
          }),
          put({
            type: SET_DATA_KEYS,
            location: action.pathname
          }),
          put({
            type: SET_TARGET_DATA_SUCCEEDED,
            target_data: target_data,
            resultSpan: action.selected_data_config_data_span
          }),
          put({
            type: HIDE_LOADING
          })
        ]);
      } catch (e) {
        yield all([
          put({
            type: GET_RAW_DATA_WITH_SPAN_AND_TYPE_AND_TIME_FAILED,
            message: e.message
          }),
          put({
            type: HIDE_LOADING
          })
        ]);
      }
      break;

    default:
      break;
  }
}


// 変動・プラン比較フィルターをかけた際の詳細データ取得
function* getRawDataForFilter(action) {
  try {
    let raw_detail_data_array
    let raw_detail_data_array_filtered
    if(action.selected_data_config_data_span === 'this_month') {
      raw_detail_data_array = yield* getFilteredData(action.company_id, action.target_data_name, 1)
      raw_detail_data_array_filtered = raw_detail_data_array.filter(rdd => rdd.resultSpan === action.selected_data_config_data_type).filter(r => r.filter === action.filter_name)

    } else if (action.selected_data_config_data_span === 'last_month') {
      raw_detail_data_array = yield* getFilteredData(action.company_id, action.target_data_name, 3)
      raw_detail_data_array_filtered = raw_detail_data_array.filter(rdd => rdd.resultSpan === action.selected_data_config_data_type).filter(r => r.filter === action.filter_name)

    } else if (action.selected_data_config_data_span === 'last_6_months') {
      console.log('last_6_months')
      raw_detail_data_array = yield* getFilteredData(action.company_id, action.target_data_name, 6)
      raw_detail_data_array_filtered = raw_detail_data_array.filter(rdd => rdd.resultSpan === action.selected_data_config_data_type).filter(r => r.filter === action.filter_name)
      
    } else if (action.selected_data_config_data_span === 'last_12_months') {
      raw_detail_data_array = yield* getFilteredData(action.company_id, action.target_data_name, 12)
      raw_detail_data_array_filtered = raw_detail_data_array.filter(rdd => rdd.resultSpan === action.selected_data_config_data_type).filter(r => r.filter === action.filter_name)
  　　 // カスタム
    } else {
      raw_detail_data_array = yield* getCustomFilteredData(action.company_id, action.target_data_name, action.custom_span_start_time, action.custom_span_end_time)
      raw_detail_data_array_filtered = raw_detail_data_array.filter(rdd => rdd.resultSpan === action.selected_data_config_data_type).filter(r => r.filter === action.filter_name)
    }
    yield all([
      put({
        type: GET_RAW_DATA_FOR_FILTER_SUCCEEDED,
        // target_detail_data: target_detail_data,
        raw_detail_data_array: raw_detail_data_array,
        raw_detail_data_array_filtered: raw_detail_data_array_filtered,
        filter_name: action.filter_name,
        target_data_name: action.target_data_name,
        results: raw_detail_data_array_filtered
      }),
      put({
        type: SET_FILTER,
        filter_name: action.filter_name
      }),
      put({
        type: HIDE_LOADING
      })
    ]);
  } catch (e) {
    yield all([
      put({
        type: HIDE_LOADING
      }),
      put({
        type: GET_RAW_DATA_FOR_FILTER_FAILED,
        message: e.message
      })
    ]);
  }
}


const rawDataSagas = [takeLatest(GET_RAW_DATA_THIS_MONTH_REQUEST, getRawDataThisMonthSaga), takeLatest(GET_RAW_DATA_LAST_MONTH_REQUEST, getRawDataLastMonthSaga), takeLatest(GET_RAW_DATA_LAST_6_MONTH_REQUEST, getRawDataLast6MonthSaga), takeLatest(GET_RAW_DATA_LAST_12_MONTH_REQUEST, getRawDataLast12MonthSaga), takeLatest(GET_RAW_DATA_CUSTOM_SPAN_REQUEST, getRawDataCustomSpanSaga), takeLatest(GET_RAW_DATA_WITH_SPAN_AND_TYPE_AND_TIME_REQUEST, getRawDataWithSpanAndTypeAndTimeSaga), takeLatest(GET_RAW_DATA_FOR_FILTER_REQUEST, getRawDataForFilter)];

export default rawDataSagas;
